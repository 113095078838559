.ladder_onboarding_beneficiaries__ViewBeneficiaries-styles__view-beneficiaries-container {
  max-width: 600px;
}

.ladder_onboarding_beneficiaries__ViewBeneficiaries-styles__edit-button {
  right: 0px;
}

.ladder_onboarding_beneficiaries__ViewBeneficiaries-styles__beneficiaries-list {
  width: 100%;
  margin-top: 15.0px;
}

.ladder_onboarding_beneficiaries__ViewBeneficiaries-styles__beneficiary {
  width: 100%;
  border-bottom: 1px solid #D0D0D0;
  padding-bottom: 30.0px;
  margin-top: 30.0px;
}

.ladder_onboarding_beneficiaries__ViewBeneficiaries-styles__beneficiary:first-child {
  margin-top: 0;
}

.ladder_onboarding_beneficiaries__ViewBeneficiaries-styles__flex {
  display: flex;
  width: 100%;
}
.tile-a .ladder_widget_ui_v2__call-to-action-styles__root {
  font-size: 12px;
  line-height: 15px;
}

.tile-b .ladder_widget_ui_v2__call-to-action-styles__root {
  font-size: 12px;
  line-height: 15px;
}

.portrait .ladder_widget_ui_v2__call-to-action-styles__root {
  font-size: 16px;
  line-height: 26px;
}

.landscape .ladder_widget_ui_v2__call-to-action-styles__root {
  font-size: 16px;
  line-height: 26px;
}

.panel .ladder_widget_ui_v2__call-to-action-styles__root {
  font-size: 16px;
  line-height: 26px;
}

.tile-b .ladder_widget_ui_v2__call-to-action-styles__root {
  margin-bottom: 20px;
}

.portrait .ladder_widget_ui_v2__call-to-action-styles__root {
  flex-grow: 32;
}

.panel .ladder_widget_ui_v2__call-to-action-styles__root {
  flex-grow: 32;
}

.landscape .ladder_widget_ui_v2__call-to-action-styles__root {
  flex-grow: 46;
}
.ladder_views_api__use-cases-styles__root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ladder_views_api__use-cases-styles__row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 16px;
}

.ladder_views_api__use-cases-styles__row > div:not(:last-child) {
  padding-right: 15.0px;
}
.ladder_views_api__code-window-styles__root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 30.0px;
  width: 100%;
  margin-bottom: 20px;
}

.ladder_views_api__code-window-styles__window {
  max-width: 100%;
  background-color: #282828;
  border-radius: 10px;
  height: fit-content;
}

.ladder_views_api__code-window-styles__title-bar {
  background-color: #1e1e1e;
  border-radius: 10px 10px 0px 0px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ladder_views_api__code-window-styles__title-text {
  font-family: SF Pro Text, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: rgba(235, 235, 245, 0.6);;
}

.ladder_views_api__code-window-styles__code {
  font-family: SF Mono, Cascadia Mono, monospace;
  overflow-x: scroll;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
  padding: 10px 20px 20px 20px;
}

.ladder_views_api__code-window-styles__arrow-container {
  display: flex;
  align-items: center;
  padding-left: 20px;
  width: 100%;
}

.ladder_views_api__code-window-styles__arrow {
  transform: matrix(-0.14, -0.99, -0.99, 0.14, 0, 0);;
  max-width: 69px;
}

.ladder_views_api__code-window-styles__thats-all {
  max-width: 80%;
}

@media (max-width: 499px) {

  
  
  .ladder_views_api__code-window-styles__arrow {
    max-width: 50px;
  }
  
  .ladder_views_api__code-window-styles__code {
    font-size: 12px;
    line-height: 15px;
  }
  
  .ladder_views_api__code-window-styles__title-bar {
    position: sticky;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_views_api__code-window-styles__arrow {
    max-width: 50px;
  }
  
  .ladder_views_api__code-window-styles__code {
    font-size: 12px;
    line-height: 15px;
  }
  
  .ladder_views_api__code-window-styles__title-bar {
    position: sticky;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_views_api__code-window-styles__root {
    padding-right: unset;
  }

}
.ladder_about__three-things-cards-styles__three-things-cards {
  display: flex;
}

.ladder_about__three-things-cards-styles__ttc-image {
  height: 171px;
  width: auto;
}

@media (min-width: 1025px) {

  
  
  .ladder_about__three-things-cards-styles__three-things-cards {
    justify-content: space-between;
    position: relative;
    top: 72px;
  }
  
  .ladder_about__three-things-cards-styles__container {
    position: relative;
    top: -72px;
    align-self: center;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_about__three-things-cards-styles__three-things-cards {
    flex-direction: column;
    align-items: center;
  }
  
  .ladder_about__three-things-cards-styles__ttc-image {
    max-height: 171px;
    max-width: 180px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_about__three-things-cards-styles__three-things-cards {
    flex-direction: column;
    align-items: center;
  }
  
  .ladder_about__three-things-cards-styles__ttc-image {
    max-height: 171px;
    max-width: 180px;
  }

}
.ladder_agents_dashboard_contacts__contact-detail-page-header-field-styles__section-styling {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  min-width: 11em;
}

.ladder_agents_dashboard_contacts__contact-detail-page-header-field-styles__title-and-children {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
@media (max-width: 499px) {

  
  
  .ladder_account_next_cards_refer_link__right-img-styles__image {
    display: none;
  }

}

.ladder_account_next_cards_refer_link__right-img-styles__image {
  object-fit: cover;
  min-width: 150px;
  max-width: 230px;
  margin-left: 0;
  margin-top: 0;
}
.ladder_view_schedule__SpitKit-styles__root {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  padding: 10.0px;
}

.ladder_view_schedule__SpitKit-styles__address-wrapper {
  padding-bottom: 10px;
}

.ladder_view_schedule__SpitKit-styles__technician-img {
  max-height: 270px;
}

.ladder_view_schedule__SpitKit-styles__text-align {
  text-align: center;
}

@media (max-width: 499px) {

  
  
  .ladder_view_schedule__SpitKit-styles__technician-img {
    height: calc(16vh -  16px );
    margin-bottom: 20px;
  }

}

.ladder_view_schedule__SpitKit-styles__page-root {
  display: flex;
  flex-direction: column;
}

.ladder_view_schedule__SpitKit-styles__top {
  padding-top: 16px;
}

@media (max-width: 499px) {

  
  
  .ladder_view_schedule__SpitKit-styles__page-root {
    min-height: calc(100vh - 60px);
  }

}
.ladder_account_next_views_gift__slide-toggleable-styles__root {
  display: flex;
  flex-direction: column;
  border-left: 1px solid #000000;
  border-right: 1px solid #000000;
  border-bottom: 1px solid #000000;
}
.ladder_advisor_only_standalone_quoter__input-row-styles__root {
  display: flex;
  flex-direction: row;
  text-align: left;
  box-sizing: border-box;
  width: 100%;
  justify-content: space-between;
}

.ladder_advisor_only_standalone_quoter__input-row-styles__child {
  width: 47%;
}
.ladder_ux_slider__slider-styles__wrapper {
  padding: 0 2px;
  box-sizing: border-box;
  position: relative;
  line-height: 0px;
}

.ladder_ux_slider__slider-styles__root-fixed-height {
  height: 20px;
}

.ladder_ux_slider__slider-styles__root {
  -webkit-appearance: none;
  width: 100%;
  padding: 0px;
  border-radius: 13px;
  margin: 2px 0;
}

.ladder_ux_slider__slider-styles__root-firefox {
  z-index: 1;
  position: relative;
}

.ladder_ux_slider__slider-styles__not-rungs::-webkit-slider-runnable-track {
  width: 100%;
  height: 9px;
  cursor: pointer;
  box-shadow: inset rgba(0,0,0,0.2) 0 1px 1px;
  border-radius: 4px;
  border: none;
}

.ladder_ux_slider__slider-styles__not-rungs::-ms-tooltip {
  display: none;
}

.ladder_ux_slider__slider-styles__not-rungs::-ms-track {
  width: 100%;
  height: 9px;
  cursor: pointer;
  box-shadow: inset rgba(0,0,0,0.2) 0 1px 1px;
  color: transparent;
  background: transparent;
  border-color: transparent;
  border-width: 9px 0;
}

.ladder_ux_slider__slider-styles__not-rungs::-ms-fill-lower {
  border: none;
  border-radius: 4px;
  background-color: #CF967E;
}

.ladder_ux_slider__slider-styles__not-rungs::-ms-fill-upper {
  border: none;
  border-radius: 4px;
  background-color: #D0D0D0;
}

.ladder_ux_slider__slider-styles__not-rungs::-webkit-slider-thumb {
  width: 25px;
  border-width: 1px;
  border-color: #D0D0D0;
  height: 25px;
  -webkit-appearance: none;
  outline: none;
  border-style: solid;
  margin-top: -8px;
  cursor: pointer;
  background-color: #FFFFFF;
  border-radius: 13px;
}

.ladder_ux_slider__slider-styles__not-rungs::-moz-range-thumb {
  width: 25px;
  border-width: 1px;
  border-color: #D0D0D0;
  height: 25px;
  -webkit-appearance: none;
  outline: none;
  border-style: solid;
  margin-top: -8px;
  cursor: pointer;
  background-color: #FFFFFF;
  border-radius: 13px;
}

.ladder_ux_slider__slider-styles__not-rungs::-ms-thumb {
  width: 25px;
  border-width: 1px;
  border-color: #D0D0D0;
  height: 25px;
  -webkit-appearance: none;
  outline: none;
  border-style: solid;
  margin-top: 0px;
  cursor: pointer;
  background-color: #FFFFFF;
  border-radius: 13px;
}

.ladder_ux_slider__slider-styles__rungs::-webkit-slider-runnable-track {
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: pointer;
}

.ladder_ux_slider__slider-styles__rungs::-ms-tooltip {
  display: none;
}

.ladder_ux_slider__slider-styles__rungs::-ms-track {
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: pointer;
  color: transparent;
  background: transparent;
  border-color: transparent;
  border-width: 10px 0;
}

.ladder_ux_slider__slider-styles__rungs::-ms-fill-lower {
  background-color: #000000;
}

.ladder_ux_slider__slider-styles__rungs::-ms-fill-upper {
  background-color: #D0D0D0;
}

.ladder_ux_slider__slider-styles__rungs::-webkit-slider-thumb {
  width: 21px;
  border-width: 0px;
  height: 40px;
  -webkit-appearance: none;
  outline: none;
  margin-top: -10px;
  cursor: pointer;
  box-shadow: none;
  background-color: #000000;
  border-radius: 50%;
  background: radial-gradient(circle at center, black 10px, transparent 10.5px);
}

.ladder_ux_slider__slider-styles__rungs::-moz-range-thumb {
  width: 21px;
  border-width: 0px;
  height: 40px;
  -webkit-appearance: none;
  outline: none;
  margin-top: -10px;
  cursor: pointer;
  box-shadow: none;
  background-color: #000000;
  border-radius: 50%;
  background: radial-gradient(circle at center, black 10px, transparent 10.5px);
}

.ladder_ux_slider__slider-styles__rungs::-ms-thumb {
  width: 21px;
  border-width: 0px;
  height: 40px;
  -webkit-appearance: none;
  outline: none;
  margin-top: 0px;
  cursor: pointer;
  box-shadow: none;
  background-color: #000000;
  border-radius: 50%;
  background: radial-gradient(circle at center, black 10px, transparent 10.5px);
}

.ladder_ux_slider__slider-styles__name-your-price-desktop::-webkit-slider-runnable-track {
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: pointer;
}

.ladder_ux_slider__slider-styles__name-your-price-desktop::-ms-tooltip {
  display: none;
}

.ladder_ux_slider__slider-styles__name-your-price-desktop::-ms-track {
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: pointer;
  color: transparent;
  background: transparent;
  border-color: transparent;
  border-width: 10px 0;
}

.ladder_ux_slider__slider-styles__name-your-price-desktop::-ms-fill-lower {
  background-color: #000000;
}

.ladder_ux_slider__slider-styles__name-your-price-desktop::-ms-fill-upper {
  background-color: #D0D0D0;
}

.ladder_ux_slider__slider-styles__name-your-price-desktop::-webkit-slider-thumb {
  width: 57px;
  border-width: 0px;
  height: 57px;
  -webkit-appearance: none;
  outline: none;
  margin-top: -17px;
  cursor: pointer;
  box-shadow: 0 0 0 5px white;
  background-color: #000000;
  border-radius: 50%;
  background: radial-gradient(circle at center, #7A8A8C 57px, white 70px);
}

.ladder_ux_slider__slider-styles__name-your-price-desktop::-moz-range-thumb {
  width: 57px;
  border-width: 0px;
  height: 57px;
  -webkit-appearance: none;
  outline: none;
  margin-top: -17px;
  cursor: pointer;
  box-shadow: 0 0 0 5px white;
  background-color: #000000;
  border-radius: 50%;
  background: radial-gradient(circle at center, #7A8A8C 57px, white 70px);
}

.ladder_ux_slider__slider-styles__name-your-price-desktop::-ms-thumb {
  width: 57px;
  border-width: 0px;
  height: 57px;
  -webkit-appearance: none;
  outline: none;
  margin-top: 0px;
  cursor: pointer;
  box-shadow: 0 0 0 5px white;
  background-color: #000000;
  border-radius: 50%;
  background: radial-gradient(circle at center, #7A8A8C 57px, white 70px);
}

.ladder_ux_slider__slider-styles__name-your-price-mobile::-webkit-slider-runnable-track {
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: pointer;
}

.ladder_ux_slider__slider-styles__name-your-price-mobile::-ms-tooltip {
  display: none;
}

.ladder_ux_slider__slider-styles__name-your-price-mobile::-ms-track {
  width: 100%;
  height: 100%;
  z-index: 2;
  cursor: pointer;
  color: transparent;
  background: transparent;
  border-color: transparent;
  border-width: 10px 0;
}

.ladder_ux_slider__slider-styles__name-your-price-mobile::-ms-fill-lower {
  background-color: #000000;
}

.ladder_ux_slider__slider-styles__name-your-price-mobile::-ms-fill-upper {
  background-color: #D0D0D0;
}

.ladder_ux_slider__slider-styles__name-your-price-mobile::-webkit-slider-thumb {
  width: 30px;
  border-width: 0px;
  height: 30px;
  -webkit-appearance: none;
  outline: none;
  margin-top: -6px;
  cursor: pointer;
  box-shadow: 0 0 0 2.5px white;
  background-color: #000000;
  border-radius: 50%;
  background: radial-gradient(circle at center, #7A8A8C 30px, white 70px);
}

.ladder_ux_slider__slider-styles__name-your-price-mobile::-moz-range-thumb {
  width: 30px;
  border-width: 0px;
  height: 30px;
  -webkit-appearance: none;
  outline: none;
  margin-top: -6px;
  cursor: pointer;
  box-shadow: 0 0 0 2.5px white;
  background-color: #000000;
  border-radius: 50%;
  background: radial-gradient(circle at center, #7A8A8C 30px, white 70px);
}

.ladder_ux_slider__slider-styles__name-your-price-mobile::-ms-thumb {
  width: 30px;
  border-width: 0px;
  height: 30px;
  -webkit-appearance: none;
  outline: none;
  margin-top: 0px;
  cursor: pointer;
  box-shadow: 0 0 0 2.5px white;
  background-color: #000000;
  border-radius: 50%;
  background: radial-gradient(circle at center, #7A8A8C 30px, white 70px);
}

.ladder_ux_slider__slider-styles__bubble-wrapper {
  width: 100%;
  top: 0px;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  touch-action: none;
  position: absolute;
  z-index: 1;
  padding-right: 3.5px;
  display: flex;
  pointer-events: none;
}

.ladder_ux_slider__slider-styles__bubble {
  height: 7px;
  width: 7px;
  border-radius: 50%;
  display: inline;
  background-color: #000000;
}

.ladder_ux_slider__slider-styles__name-your-price-desktop-bubble {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: inline;
  background-color: #464F50;
}

.ladder_ux_slider__slider-styles__name-your-price-mobile-bubble {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline;
  background-color: #464F50;
}

.ladder_ux_slider__slider-styles__gray {
  background-color: #D0D0D0;
}

.ladder_ux_slider__slider-styles__container {
  height: 14px;
  position: absolute;
  width: 100%;
  display: flex;
}

.ladder_ux_slider__slider-styles__container-wrapper {
  width: 100%;
  height: 100%;
  align-items: center;
  touch-action: none;
  position: absolute;
  z-index: 1;
  padding-right: 3.5px;
  display: flex;
  pointer-events: none;
}

.ladder_ux_slider__slider-styles__mini-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-left: -1px;
}

.ladder_ux_slider__slider-styles__mini-container:first-of-type {
  justify-content: flex-start;
}

.ladder_ux_slider__slider-styles__mini-container:last-of-type {
  justify-content: flex-end;
}

.ladder_ux_slider__slider-styles__left-border {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.ladder_ux_slider__slider-styles__right-border {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.ladder_ux_slider__slider-styles__single-value {
  width: 10.0px;
  height: 10.0px;
  border-radius: 50%;
}

.ladder_ux_slider__slider-styles__light-green {
  background-color: #DBE6DB;
}

.ladder_ux_slider__slider-styles__dark-green {
  background-color: #749475;
}

.ladder_ux_slider__slider-styles__half-dark {
  background: linear-gradient(90deg, #749475 0 50%, #DBE6DB 50% 100%);
}

.ladder_ux_slider__slider-styles__cape-cod {
  background-color: #464F50;
}

.ladder_ux_slider__slider-styles__transparent {
  background-color: transparent;
}
@media (max-width: 499px) {

  
  
  .ladder_form_nearly_perfect_quote__NPQBundlesModal-styles__full-width-bundles-scroller {
    margin: 0 -30px;
  }

}
.ladder_apply_smart_discovery_health_conditions__smart-discovery-styles__question-page-num {
  line-height: 18px;
  width: 100%;
  max-width: 600px;
  font-family: Montserrat;
  align-items: center;
  flex-direction: column;
  font-size: 12px;
  display: flex;
  color: #BDBDBD;
}
.ladder_agents_dashboard_contacts__decline-reasons-card-styles__card {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 24px;
  border-radius: 24px;
  background-color: #F7DDC9;
}

.ladder_agents_dashboard_contacts__decline-reasons-card-styles__share-decision-link {
  margin-top: 22px;
}
.ladder_ux_logos__creditkarma-logo-styles__icon {
  height: 20.0px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_logos__creditkarma-logo-styles__icon {
    height: 16px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_logos__creditkarma-logo-styles__icon {
    height: 16px;
  }

}
.ladder_ny_form_quick_estimate__quick-estimate-gender-styles__row {
  display: flex;
  flex-direction: column;
  text-align: center;
  flex-grow: 1;
  margin-bottom: 10.0px;
}

> *:not(:last-child) {
  margin-right: 10.0px;
}
.tile-a .ladder_widget_ui_v2__get-started-button-styles__root {
  flex-grow: 1;
  margin-left: 10.0px;
}

.tile-b .ladder_widget_ui_v2__get-started-button-styles__root {
  flex-grow: 1;
  margin-left: 10.0px;
}

.landscape .ladder_widget_ui_v2__get-started-button-styles__root {
  width: 80.7%;
  max-width: 555px;
}

.portrait .ladder_widget_ui_v2__get-started-button-styles__root {
  flex-grow: 27;
  max-width: 270px;
  margin-left: 15.0px;
}

.panel .ladder_widget_ui_v2__get-started-button-styles__root {
  flex-grow: 27;
}

.ladder_widget_ui_v2__get-started-button-styles__on-ladder-text {
  margin: 10px;
  justify-content: center;
  display: flex;
}
.ladder_account_next_nav_nav_item__rungs-nav-item-styles__\% {
  display: flex;
  align-items: center;
  cursor: pointer;
}

@media (max-width: 499px) {

  
  
  .ladder_account_next_nav_nav_item__rungs-nav-item-styles__\% {
    height: 50px;
    justify-content: space-between;
    font-size: 14px;
    line-height: 17px;
  }
  
  .ladder_account_next_nav_nav_item__rungs-nav-item-styles__indent-1 {
    padding-left: 1em;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_account_next_nav_nav_item__rungs-nav-item-styles__\% {
    height: 50px;
    line-height: 20px;
  }
  
  .ladder_account_next_nav_nav_item__rungs-nav-item-styles__selected {
    background-color: #D8BCAF;
  }
  
  .ladder_account_next_nav_nav_item__rungs-nav-item-styles__has-selected-child {
    background-color: #E6D5CD;
  }
  
  .ladder_account_next_nav_nav_item__rungs-nav-item-styles__indent-0 {
    width: 100%;
    padding-left: 16.25%;
  }
  
  .ladder_account_next_nav_nav_item__rungs-nav-item-styles__indent-1 {
    width: 85%;
    padding-left: 10%;
  }
  
  .ladder_account_next_nav_nav_item__rungs-nav-item-styles__hover-bold:hover {
    font-weight: 600;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account_next_nav_nav_item__rungs-nav-item-styles__\% {
    height: 50px;
    line-height: 20px;
  }
  
  .ladder_account_next_nav_nav_item__rungs-nav-item-styles__selected {
    background-color: #D8BCAF;
  }
  
  .ladder_account_next_nav_nav_item__rungs-nav-item-styles__has-selected-child {
    background-color: #E6D5CD;
  }
  
  .ladder_account_next_nav_nav_item__rungs-nav-item-styles__indent-0 {
    width: 100%;
    padding-left: 16.25%;
  }
  
  .ladder_account_next_nav_nav_item__rungs-nav-item-styles__indent-1 {
    width: 85%;
    padding-left: 10%;
  }
  
  .ladder_account_next_nav_nav_item__rungs-nav-item-styles__hover-bold:hover {
    font-weight: 600;
  }

}

.ladder_account_next_nav_nav_item__rungs-nav-item-styles__notification-dot {
  margin-left: auto;
  margin-right: 10%;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: #CB3A3A;
}
.ladder_nearly_perfect_quote_review_modal__NPQReviewModal-styles__field {
  margin: 10px 0 22.5px;
}

.ladder_nearly_perfect_quote_review_modal__NPQReviewModal-styles__content-wrapper {
  padding: 20px 10%;
}

.ladder_nearly_perfect_quote_review_modal__NPQReviewModal-styles__content-wrapper.ladder_nearly_perfect_quote_review_modal__NPQReviewModal-styles__no-top-padding {
  padding-top: 0;
}

.ladder_nearly_perfect_quote_review_modal__NPQReviewModal-styles__content-wrapper.ladder_nearly_perfect_quote_review_modal__NPQReviewModal-styles__no-side-padding {
  padding-left: 0;
  padding-right: 0;
}
.ladder_ux_panels__story-card-styles__story {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  flex-grow: 2;
}

.ladder_ux_panels__story-card-styles__person {
  margin: 32px 0 0 0;
}

.ladder_ux_panels__story-card-styles__xs .ladder_ux_panels__story-card-styles__person {
  margin-top: 16px;
}
.ladder_onboarding_accepted_offer__CongratulationsText-styles__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 250px;
  margin: 30.0px 0;
}

.ladder_onboarding_accepted_offer__CongratulationsText-styles__middle-text {

}

@media (max-width: 499px) {

  
  
  .ladder_onboarding_accepted_offer__CongratulationsText-styles__content {
    margin: auto;
    padding-top: 30.0px;
    width: 90%;
    justify-content: space-around;
  }
  
  .ladder_onboarding_accepted_offer__CongratulationsText-styles__content .ladder_onboarding_accepted_offer__CongratulationsText-styles__middle-text {
    margin: 30.0px 0;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_onboarding_accepted_offer__CongratulationsText-styles__content {
    margin: auto;
    padding-top: 30.0px;
    width: 90%;
    justify-content: space-around;
  }
  
  .ladder_onboarding_accepted_offer__CongratulationsText-styles__content .ladder_onboarding_accepted_offer__CongratulationsText-styles__middle-text {
    margin: 30.0px 0;
  }

}
.ladder_ux_logos__pti-logo-styles__icon {
  height: 44.8px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_logos__pti-logo-styles__icon {
    height: 38.4px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_logos__pti-logo-styles__icon {
    height: 28.8px;
  }

}
.ladder_text__paragraph-styles__root {
  margin-bottom: 16px;
}
.ladder_account_next_views_beneficiaries_view__view-beneficiary-fields-styles__fields {
  display: flex;
  flex-wrap: wrap;
}

.ladder_account_next_views_beneficiaries_view__view-beneficiary-fields-styles__field {
  flex-basis: 100%;
  overflow-wrap: anywhere;
  margin-bottom: 30px;
}

@media (min-width: 1025px) {

  
  
  .ladder_account_next_views_beneficiaries_view__view-beneficiary-fields-styles__field {
    flex-basis: calc(50% - 8px);
  }
  
  .ladder_account_next_views_beneficiaries_view__view-beneficiary-fields-styles__field:nth-child(odd) {
    margin-right: 16px;
  }

}
.ladder_widget_text__text-inner-styles__medium-gray {
  color: #707070;
}

.ladder_widget_text__text-inner-styles__red {
  color: #FE4747;
}

.ladder_widget_text__text-inner-styles__black {
  color: #000000;
}

.ladder_widget_text__text-inner-styles__lato {
  font-family: Lato, sans-serif;
}

.ladder_widget_text__text-inner-styles__uppercase {
  text-transform: uppercase;
}

.ladder_widget_text__text-inner-styles__close {
  letter-spacing: 0.0em;
}

.ladder_widget_text__text-inner-styles__far {
  letter-spacing: 0.25em;
}

.ladder_widget_text__text-inner-styles__light {
  font-weight: 300;
}

.ladder_widget_text__text-inner-styles__bold {
  font-weight: 900;
}

.ladder_widget_text__text-inner-styles__xs {
  font-size: 11px;
}

.ladder_widget_text__text-inner-styles__s {
  font-size: 13px;
}

.ladder_widget_text__text-inner-styles__m {
  font-size: 14px;
  line-height: 19px;
}

.ladder_widget_text__text-inner-styles__ml {
  font-size: 18px;
}

.ladder_widget_text__text-inner-styles__l {
  font-size: 24px;
  line-height: 29px;
}

.ladder_widget_text__text-inner-styles__xl {
  font-size: 36px;
}

.ladder_widget_text__text-inner-styles__xxl {
  font-size: 72px;
}
.ladder_savings_steps__question-styles__wrapper {
  background-color: #E7EEEF;
  text-align: left;
  padding: 25px;
}

.ladder_savings_steps__question-styles__title {
  margin-bottom: 20px;
}

@media (min-width: 1025px) {

  
  
  .ladder_savings_steps__question-styles__wrapper {
    min-height: 300px;
    width: 32%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_savings_steps__question-styles__wrapper {
    min-height: 300px;
    width: 32%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_savings_steps__question-styles__wrapper {
    margin-bottom: 20px;
    padding: 20px;
  }
  
  .ladder_savings_steps__question-styles__expand-icon {
    width: 22px;
    height: 15px;
    stroke: #000000;
    stroke-width: 3px;
  }

}
.ladder_agents_dashboard__contacts-page-styles__contacts-page-logo-and-text {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

.ladder_agents_dashboard__contacts-page-styles__contacts-page-background-icon {
  width: 100%;
  height: auto;
}

.ladder_agents_dashboard__contacts-page-styles__contacts-page-background-text {
  width: 60%;
  text-align: center;
}

.ladder_agents_dashboard__contacts-page-styles__contact-cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.ladder_agents_dashboard__contacts-page-styles__contacts-and-add-contact-button {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 15px;
  width: 100%;
}

.ladder_agents_dashboard__contacts-page-styles__welcome {
  margin-bottom: 30px;
}

.ladder_agents_dashboard__contacts-page-styles__button-and-link-container {
  display: flex;
  width: 750px;
  margin-bottom: 30px;
  justify-content: space-between;
  align-items: center;
}

.ladder_agents_dashboard__contacts-page-styles__plus-button-container {
  display: flex;
  align-items: center;
}

.ladder_agents_dashboard__contacts-page-styles__plus-button-container>*:first-child {
  margin-right: 20px;
}

@media (min-width: 1025px) {

  
  
  .ladder_agents_dashboard__contacts-page-styles__contact-cards {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 750px;
    overflow: auto;
    justify-content: space-between;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard__contacts-page-styles__plus-button-container {
    margin-bottom: 20px;
  }
  
  .ladder_agents_dashboard__contacts-page-styles__button-and-link-container {
    flex-direction: column;
    max-width: 400px;
    width: 100%;
    align-items: unset;
  }
  
  .ladder_agents_dashboard__contacts-page-styles__contact-cards {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    height: 100%;
    width: 100%;
    overflow: auto;
    align-items: center;
    justify-content: space-between;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_dashboard__contacts-page-styles__plus-button-container {
    margin-bottom: 20px;
  }
  
  .ladder_agents_dashboard__contacts-page-styles__button-and-link-container {
    flex-direction: column;
    max-width: 400px;
    width: 100%;
    align-items: unset;
  }
  
  .ladder_agents_dashboard__contacts-page-styles__contact-cards {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    height: 100%;
    width: 100%;
    overflow: auto;
    align-items: center;
    justify-content: space-between;
  }

}
.ladder_requestion_post_sign_questions__re-questioning-submission-success-styles__success-root {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.ladder_requestion_post_sign_questions__re-questioning-submission-success-styles__success-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  gap: 10px;
}

.ladder_requestion_post_sign_questions__re-questioning-submission-success-styles__winner-svg {
  width: 300px;
  height: 300px;
  margin: 30px 0px;
}

.ladder_requestion_post_sign_questions__re-questioning-submission-success-styles__account-button {
  width: 400px;
}

@media (max-width: 499px) {

  
  
  .ladder_requestion_post_sign_questions__re-questioning-submission-success-styles__success-inner {
    width: 90%;
    margin-bottom: -50px;
  }
  
  .ladder_requestion_post_sign_questions__re-questioning-submission-success-styles__success-header {
    width: 100%;
  }

}
.ladder_about__our-leaders-styles__container {
  display: flex;
  flex-direction: column;
  margin-top: 162px;
}

.ladder_about__our-leaders-styles__rows {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ladder_about__our-leaders-styles__photos {
  width: 100%;
  margin-top: 52px;
  display: flex;
  flex-direction: column;
}

.ladder_about__our-leaders-styles__picture-container {
  position: relative;
  width: 356px;
  height: 356px;
}

.ladder_about__our-leaders-styles__picture-background {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.ladder_about__our-leaders-styles__photo {
  position: absolute;
  cursor: pointer;
  transition: 0.21s;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.ladder_about__our-leaders-styles__photo:hover {
  opacity: 0;
}

.ladder_about__our-leaders-styles__title {
  align-self: center;
}

.ladder_about__our-leaders-styles__name {
  margin-top: 20px;
}

.ladder_about__our-leaders-styles__leader {
  display: flex;
  flex-basis: 20%;
  flex-direction: column;
  max-width: 90%;
  width: 400px;
  margin: 60px 5.0px 0 5.0px;
  align-items: center;
}

.ladder_about__our-leaders-styles__role {
  margin-top: 5.0px;
}

.ladder_about__our-leaders-styles__label {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 499px) {

  
  
  .ladder_about__our-leaders-styles__container {
    margin-top: 56px;
    width: 100%;
  }
  
  .ladder_about__our-leaders-styles__rows {
    justify-content: center;
  }
  
  .ladder_about__our-leaders-styles__leader {
    flex-basis: 100%;
  }
  
  .ladder_about__our-leaders-styles__name {
    margin-top: 30px;
  }
  
  .ladder_about__our-leaders-styles__photo {
  
  }
  
  .ladder_about__our-leaders-styles__photo:hover {
    opacity: unset;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_about__our-leaders-styles__container {
    margin-top: 56px;
    width: 100%;
  }
  
  .ladder_about__our-leaders-styles__rows {
    justify-content: center;
  }
  
  .ladder_about__our-leaders-styles__leader {
    flex-basis: 100%;
  }
  
  .ladder_about__our-leaders-styles__name {
    margin-top: 30px;
  }
  
  .ladder_about__our-leaders-styles__photo {
  
  }
  
  .ladder_about__our-leaders-styles__photo:hover {
    opacity: unset;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_about__our-leaders-styles__picture-container {
    width: 300px;
    height: 300px;
  }

}
.ladder_apply_current_page_ui__bottom-navigation-styles__captioned-group-header {
  max-width: 600px;
  width: 100%;
}

.ladder_apply_current_page_ui__bottom-navigation-styles__bottom-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  width: 100%;
}

.ladder_apply_current_page_ui__bottom-navigation-styles__button-wrapper {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  width: 200px;
}

.ladder_apply_current_page_ui__bottom-navigation-styles__paid-partner {
  margin-top: 20px;
}

.ladder_apply_current_page_ui__bottom-navigation-styles__button-sizer {
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 1025px) {

  
  
  .ladder_apply_current_page_ui__bottom-navigation-styles__button-sizer>button {
    width: 60%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_apply_current_page_ui__bottom-navigation-styles__button-sizer>button {
    width: 60%;
  }

}

.ladder_apply_current_page_ui__bottom-navigation-styles__skip-to-billing {
  width: 100%;
  max-width: 600px;
  text-align: left;
}

@media (min-width: 1025px) {

  
  
  .ladder_apply_current_page_ui__bottom-navigation-styles__paid-partner {
    margin-top: 25.0px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_apply_current_page_ui__bottom-navigation-styles__button-info-panel-cta-fixed-bottom {
    padding: 0;
  }
  
  .ladder_apply_current_page_ui__bottom-navigation-styles__skip-to-billing {
    text-align: center;
  }

}

.box-fields .ladder_apply_current_page_ui__bottom-navigation-styles__bottom-back-link {
  margin-top: 25.0px;
}

.box-fields .ladder_apply_current_page_ui__bottom-navigation-styles__bottom-back-link a {
  line-height: 100%;
}

.ladder_apply_current_page_ui__bottom-navigation-styles__arrow {
  padding: 0 2px 2px 5.0px;
}
.ladder_onboarding_spouse_connect_2__control-dots-wrapper-desktop-styles__control-dots-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #FFFFFF;
  justify-content: center;
  align-items: center;
}
.ladder_form_days_traveling__Root-styles__input {
  width: 100px;
}

@media (max-width: 499px) {

  
  
  .ladder_form_days_traveling__Root-styles__input {
    width: 50px;
  }

}
.ladder_views_decision_main__agent-assisted-app-decline-styles__page-root {
  width: 100%;
  background-color: #F7F2EF;
}

.ladder_views_decision_main__agent-assisted-app-decline-styles__container {
  display: flex;
  gap: 100px;
  width: 100%;
  max-width: 1001px;
  margin: 0 auto;
  padding: 180px 0 80px 0;
}

.ladder_views_decision_main__agent-assisted-app-decline-styles__text-container {
  display: flex;
  flex-direction: column;
  max-width: 514px;
  gap: 32px;
}

@media (min-width: 1025px) {

  
  
  .ladder_views_decision_main__agent-assisted-app-decline-styles__page-root {
    min-height: 700px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_views_decision_main__agent-assisted-app-decline-styles__container {
    flex-direction: column;
    padding: 30px 0;
    width: 90%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_views_decision_main__agent-assisted-app-decline-styles__container {
    flex-direction: column;
    padding: 30px 0;
    width: 90%;
  }

}
.ladder_aimcor_form__update-form-styles__caption {
  margin: 25.0px 0;
}

@media (max-width: 499px) {

  
  
  .ladder_aimcor_form__update-form-styles__caption {
    margin: 20px 0;
  }

}
.ladder_agents_agent_assisted_app_views_decision__manual-underwriting-panel-styles__container {
  display: flex;
  flex-direction: column;
  max-width: 514px;
  gap: 32px;
}
body.modal-open {
  position: fixed;
  overflow: visible;
  width: 100%;
}

.ladder_ux_modal__modal-inner-styles__box {
  width: 90%;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
  text-align: left;
  position: relative;
  overflow: auto;
  background-color: #FFFFFF;
  box-sizing: border-box;
  display: flex;
  max-height: 55vh;
}

.ladder_ux_modal__modal-inner-styles__rounded-corners {
  border-radius: 24px;
}

.ladder_ux_modal__modal-inner-styles__centered {
  align-items: center;
}

.ladder_ux_modal__modal-inner-styles__force-height.ladder_ux_modal__modal-inner-styles__box {
  height: 55vh;
}

.ladder_ux_modal__modal-inner-styles__box-padding {
  padding: 32px 0;
}

.ladder_ux_modal__modal-inner-styles__off-white {
  background-color: #F8F8F8;
}

.ladder_ux_modal__modal-inner-styles__width-s {
  max-width: 300px;
}

.ladder_ux_modal__modal-inner-styles__width-m {
  max-width: 550px;
}

.ladder_ux_modal__modal-inner-styles__width-mml {
  max-width: 650px;
}

.ladder_ux_modal__modal-inner-styles__width-ml {
  max-width: 800px;
}

.ladder_ux_modal__modal-inner-styles__width-l {
  max-width: 850px;
}

.ladder_ux_modal__modal-inner-styles__width-xl {
  max-width: 1050px;
}

.ladder_ux_modal__modal-inner-styles__width-xxl {
  max-width: 1250px;
}

.ladder_ux_modal__modal-inner-styles__height-s {
  max-height: 35vh;
}

.ladder_ux_modal__modal-inner-styles__force-height.ladder_ux_modal__modal-inner-styles__height-s {
  height: 35vh;
  max-height: 315.000000px;
}

.ladder_ux_modal__modal-inner-styles__height-m {
  max-height: 50vh;
}

.ladder_ux_modal__modal-inner-styles__force-height.ladder_ux_modal__modal-inner-styles__height-m {
  height: 50vh;
  max-height: 450.000000px;
}

.ladder_ux_modal__modal-inner-styles__height-l {
  max-height: 75vh;
}

.ladder_ux_modal__modal-inner-styles__force-height.ladder_ux_modal__modal-inner-styles__height-l {
  height: 75vh;
  max-height: 675.000000px;
}

.ladder_ux_modal__modal-inner-styles__height-lxl {
  max-height: 85vh;
}

.ladder_ux_modal__modal-inner-styles__height-llxl {
  max-height: 90vh;
}

.ladder_ux_modal__modal-inner-styles__height-xl {
  max-height: 95vh;
}

.ladder_ux_modal__modal-inner-styles__force-height.ladder_ux_modal__modal-inner-styles__height-xl {
  height: 95vh;
  max-height: 855.000000px;
}

.ladder_ux_modal__modal-inner-styles__full-page {
  align-self: flex-end;
  height: calc(100vh - 100px);
  max-height: calc(100% - 100px);
  width: 100%;
  max-width: 100%;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_modal__modal-inner-styles__full-page {
    max-height: calc(100% - 60px);
  }
  
  .ladder_ux_modal__modal-inner-styles__full-page .ladder_ux_modal__modal-inner-styles__full-page-content {
    height: 100%;
    width: 100%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_modal__modal-inner-styles__full-page {
    max-height: calc(100% - 60px);
  }
  
  .ladder_ux_modal__modal-inner-styles__full-page .ladder_ux_modal__modal-inner-styles__full-page-content {
    height: 100%;
    width: 100%;
  }

}

.ladder_ux_modal__modal-inner-styles__x-button-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  padding: 14px 20px 0 0;
  z-index: 14;
}

.ladder_ux_modal__modal-inner-styles__x-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #FFFFFF;
}

.ladder_ux_modal__modal-inner-styles__x-icon {
  height: 14px;
  width: 14px;
}

.ladder_ux_modal__modal-inner-styles__content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
}

.ladder_ux_modal__modal-inner-styles__header {
  width: 100%;
  position: relative;
}

.ladder_ux_modal__modal-inner-styles__header .ladder_ux_modal__modal-inner-styles__header-text {
  line-height: normal;
}

.ladder_ux_modal__modal-inner-styles__no-header-text {
  height: 1em;
}

.ladder_ux_modal__modal-inner-styles__header-padding {
  padding: 20px 60px 20px 30px;
}

.ladder_ux_modal__modal-inner-styles__text {
  display: inline-block;
  text-align: left;
}

.ladder_ux_modal__modal-inner-styles__body {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.ladder_ux_modal__modal-inner-styles__body-scroll {
  overflow-y: auto;
}

.ladder_ux_modal__modal-inner-styles__horizontally-centered {
  align-items: center;
}

.ladder_ux_modal__modal-inner-styles__body-padding {
  padding: 0 30px 30px 30px;
}

.ladder_ux_modal__modal-inner-styles__no-template {
  align-items: center;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_modal__modal-inner-styles__body-padding {
    padding-right: 30px;
  }

}

.ladder_ux_modal__modal-inner-styles__cta-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media (min-width: 1025px) {

  
  
  .ladder_ux_modal__modal-inner-styles__cta-wrapper {
    padding-bottom: 30px;
    gap: 20px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_modal__modal-inner-styles__cta-wrapper {
    padding-bottom: 30px;
    gap: 20px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_modal__modal-inner-styles__cta-wrapper {
    padding-bottom: 40px;
    gap: 10px;
  }

}
.ladder_account_next_step__cutting-room-floor-card-styles__box-n {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  border: 1px solid #D0D0D0;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
}

.ladder_account_next_step__cutting-room-floor-card-styles__box-no-dismiss {
  padding: 0 30.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_account_next_step__cutting-room-floor-card-styles__copy-margin-no-dismiss {
    margin-top: 30.0px;
  }

}
.ladder_financial_advisors_home__allianz-and-fsl-title-styles__\% {
  min-width: 41%;
}
.ladder_form_id_verification_modal__root-styles__root {
  padding: 15.0px 0 0 0;
}
.ladder_ny_form_quick_estimate__quick-estimate-tobacco-styles__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10.0px;
}
.ladder_app_common__app-card-styles__root {
  width: 100%;
  max-width: 600px;
  min-height: 192px;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.ladder_app_common__app-card-styles__bottom-banner {
  flex-direction: column-reverse;
}

.ladder_app_common__app-card-styles__max-width-l {
  max-width: 630px;
}

.ladder_app_common__app-card-styles__max-width-geico-smart-sort {
  max-width: 750px;
}

.ladder_app_common__app-card-styles__max-width-xl {
  max-width: 800px;
}

.ladder_app_common__app-card-styles__max-width-none {
  max-width: none;
}

.ladder_app_common__app-card-styles__min-height-none {
  min-height: auto;
}

.ladder_app_common__app-card-styles__top-padding-default {
  padding-top: 56px;
}

.ladder_app_common__app-card-styles__top-padding-qs {
  padding-top: 20px;
}

@media (max-width: 499px) {

  
  
  .ladder_app_common__app-card-styles__top-padding-default {
    padding-top: 32px;
  }
  
  .ladder_app_common__app-card-styles__top-padding-qs {
    padding-top: 25px;
  }

}
.ladder_agents_dashboard_quote__client-self-apply-checkbox-styles__explain-self-apply {
  margin-top: 10.0px;
}
.ladder_agents_dashboard_inputs__shared-input-field-styles__input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  background-color: transparent;
}

.ladder_agents_dashboard_inputs__shared-input-field-styles__border-error {
  border-color: #FE4747;
}

.ladder_agents_dashboard_inputs__shared-input-field-styles__input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ladder_agents_dashboard_inputs__shared-input-field-styles__input-container.ladder_agents_dashboard_inputs__shared-input-field-styles__clip-ellipse {
  clip-path: ellipse(farthest-side farthest-side at left);
}

.ladder_agents_dashboard_inputs__shared-input-field-styles__input {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ladder_agents_dashboard_inputs__shared-input-field-styles__input.ladder_agents_dashboard_inputs__shared-input-field-styles__clip-ellipse {
  clip-path: ellipse(farthest-side farthest-side at left);
}

.ladder_agents_dashboard_inputs__shared-input-field-styles__input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.ladder_agents_dashboard_inputs__shared-input-field-styles__select {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 1025px) {

  
  
  .ladder_agents_dashboard_inputs__shared-input-field-styles__quoter-input-wrapper {
    display: flex;
    align-items: center;
    height: 70px;
    width: 100%;
    padding: 1em;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 24px;
  }
  
  .ladder_agents_dashboard_inputs__shared-input-field-styles__quoter-input-wrapper.ladder_agents_dashboard_inputs__shared-input-field-styles__height-50px {
    height: 50px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard_inputs__shared-input-field-styles__quoter-input-wrapper {
    display: flex;
    align-items: center;
    height: 70px;
    width: 100%;
    padding: 1em;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 24px;
  }
  
  .ladder_agents_dashboard_inputs__shared-input-field-styles__quoter-input-wrapper.ladder_agents_dashboard_inputs__shared-input-field-styles__height-50px {
    height: 50px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_dashboard_inputs__shared-input-field-styles__quoter-input-wrapper {
    display: flex;
    align-items: center;
    height: 70px;
    width: 100%;
    padding: 1em;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 24px;
  }
  
  .ladder_agents_dashboard_inputs__shared-input-field-styles__quoter-input-wrapper.ladder_agents_dashboard_inputs__shared-input-field-styles__height-50px {
    height: 50px;
  }

}
.ladder_financial_advisors_home__how-it-works-item-styles__\% {
  display: flex;
  max-width: 384px;
}

.ladder_financial_advisors_home__how-it-works-item-styles__copy {
  margin-left: 16px;
}

@media (max-width: 499px) {

  
  
  .ladder_financial_advisors_home__how-it-works-item-styles__\% {
    margin-top: 48px;
  }
  
  .ladder_financial_advisors_home__how-it-works-item-styles__copy {
    display: flex;
    align-items: center;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_financial_advisors_home__how-it-works-item-styles__\% {
    margin-top: 48px;
  }
  
  .ladder_financial_advisors_home__how-it-works-item-styles__copy {
    display: flex;
    align-items: center;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_financial_advisors_home__how-it-works-item-styles__\% {
    max-width: 500px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_financial_advisors_home__how-it-works-item-styles__\% {
    max-width: 256px;
  }

}
.ladder_apply_question_description__prompt-message-styles__prompt-top {
  margin-top: 15px;
}
.ladder_agents_dashboard_nav__nav-bar-item-styles__nav-bar-icon {
  display: flex;
  cursor: pointer;
  justify-content: center;
}

.ladder_agents_dashboard_nav__nav-bar-item-styles__new-label {
  position: absolute;
  border: 1px solid #336FED;
  border-radius: 6px;
  color: #336FED;
  display: flex;
  align-items: center;
  gap: 2px;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard_nav__nav-bar-item-styles__nav-bar-icon-container {
    display: flex;
    height: 100%;
    justify-content: center;
  }
  
  .ladder_agents_dashboard_nav__nav-bar-item-styles__nav-bar-item-container {
    display: flex;
    margin-top: unset;
    margin-bottom: unset;
    flex-direction: row;
  }
  
  .ladder_agents_dashboard_nav__nav-bar-item-styles__nav-bar-item-name-icon {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  
  .ladder_agents_dashboard_nav__nav-bar-item-styles__padding {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .ladder_agents_dashboard_nav__nav-bar-item-styles__new-label {
    top: 4px;
    padding: 2px;
    display: none;
  }
  
  .ladder_agents_dashboard_nav__nav-bar-item-styles__new-label>div {
    font-size: 8px;
  }
  
  .ladder_agents_dashboard_nav__nav-bar-item-styles__horizontal-selected-item-bar {
    width: 27px;
    height: 4px;
    position: absolute;
    bottom: 0;
    border-radius: 19px;
    background-color: #336FED;
  }
  
  .ladder_agents_dashboard_nav__nav-bar-item-styles__horizontal-unselected-item-bar {
    width: 27px;
    height: 4px;
    position: absolute;
    bottom: 0;
    border-radius: 19px;
    background-color: transparent;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_dashboard_nav__nav-bar-item-styles__nav-bar-icon-container {
    display: flex;
    height: 100%;
    justify-content: center;
  }
  
  .ladder_agents_dashboard_nav__nav-bar-item-styles__nav-bar-item-container {
    display: flex;
    margin-top: unset;
    margin-bottom: unset;
    flex-direction: row;
  }
  
  .ladder_agents_dashboard_nav__nav-bar-item-styles__nav-bar-item-name-icon {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  
  .ladder_agents_dashboard_nav__nav-bar-item-styles__padding {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .ladder_agents_dashboard_nav__nav-bar-item-styles__new-label {
    top: 4px;
    padding: 2px;
    display: none;
  }
  
  .ladder_agents_dashboard_nav__nav-bar-item-styles__new-label>div {
    font-size: 8px;
  }
  
  .ladder_agents_dashboard_nav__nav-bar-item-styles__horizontal-selected-item-bar {
    width: 27px;
    height: 4px;
    position: absolute;
    bottom: 0;
    border-radius: 19px;
    background-color: #336FED;
  }
  
  .ladder_agents_dashboard_nav__nav-bar-item-styles__horizontal-unselected-item-bar {
    width: 27px;
    height: 4px;
    position: absolute;
    bottom: 0;
    border-radius: 19px;
    background-color: transparent;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_agents_dashboard_nav__nav-bar-item-styles__nav-bar-icon-container {
    display: flex;
    flex: 1;
    width: 100%;
    align-items: center;
  }
  
  .ladder_agents_dashboard_nav__nav-bar-item-styles__nav-bar-item-container {
    display: flex;
    flex-direction: row;
    gap: 3em;
    margin-top: 2em;
    margin-bottom: 1em;
    margin-right: 6em;
  }
  
  .ladder_agents_dashboard_nav__nav-bar-item-styles__nav-bar-item-name-icon {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    gap: 1em;
    justify-content: left;
    align-items: center;
  }
  
  .ladder_agents_dashboard_nav__nav-bar-item-styles__padding {
    position: relative;
  }
  
  .ladder_agents_dashboard_nav__nav-bar-item-styles__new-label {
    top: -21px;
    left: calc(1em + 30px);
    padding: 5px;
  }
  
  .ladder_agents_dashboard_nav__nav-bar-item-styles__new-label>div {
    font-size: 10px;
  }
  
  .ladder_agents_dashboard_nav__nav-bar-item-styles__vertical-selected-item-bar {
    left: 0;
    width: 5px;
    height: 41px;
    border-radius: 19px;
    background-color: #336FED;
  }
  
  .ladder_agents_dashboard_nav__nav-bar-item-styles__vertical-unselected-item-bar {
    left: 0;
    width: 5px;
    height: 41px;
    border-radius: 19px;
    background-color: transparent;
  }

}

.ladder_agents_dashboard_nav__nav-bar-item-styles__pointer-cursor {
  cursor: pointer;
}

.ladder_agents_dashboard_nav__nav-bar-item-styles__icon-and-text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1em;
}
@media (min-width: 1025px) {

  
  
  .ladder_guide_common__bottom-spacer-styles__spacer {
    height: 128px;
    width: 100%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_guide_common__bottom-spacer-styles__spacer {
    height: 82px;
    width: 100%;
  }

}
.ladder_ux_modal__message-modal-styles__content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.ladder_ux_modal__message-modal-styles__button {
  text-align: center;
  padding-bottom: 30px;
}

.ladder_ux_modal__message-modal-styles__button-2-text {
  padding: 16px;
  display: block;
}

.ladder_ux_modal__message-modal-styles__small-caps {
  font-size: 75%;
  letter-spacing: 0.12em;
  font-weight: 400;
  text-transform: uppercase;
}

@media (min-width: 1025px) {

  
  
  .ladder_ux_modal__message-modal-styles__content {
    margin: 32px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_modal__message-modal-styles__content {
    margin: 32px;
  }

}
.ladder_requestion_review_ui__ReQReviewModal-styles__header-container {
  display: flex;
  flex-grow: 1;
  justify-content: left;
  align-items: center;
  gap: 8.0px;
}

.ladder_requestion_review_ui__ReQReviewModal-styles__text-container {
  margin-bottom: 24.0px;
}
.ladder_account_next_nav__RungsNav-styles__\% {
  background-color: #F7F2EF;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 10px;
  padding-left: 15px;
}

@media (max-width: 499px) {

  
  
  .ladder_account_next_nav__RungsNav-styles__\% {
    padding: 0;
    align-items: flex-start;
  }
  
  
  
  .ladder_account_next_nav__RungsNav-styles__\%>*:first-child {
    padding: 20px 0 20px 30.0px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_account_next_nav__RungsNav-styles__\% {
    max-width: 400px;
    flex: 1 1 400px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account_next_nav__RungsNav-styles__\% {
    flex: 1 1 300px;
    max-width: 300px;
  }

}
.ladder_onboarding_referrals__squiggly-styles__squiggly-container {
  margin: 15.0px auto;
  display: block;
}

@media (max-width: 499px) {

  
  
  .ladder_onboarding_referrals__squiggly-styles__squiggly-container {
    margin: 20px auto;
    transform: scaleY(1.2);
  }

}
.ladder_trustage_upper_funnel_routing__question-icons-panel-styles__wrapper {
  display: flex;
}

.ladder_trustage_upper_funnel_routing__question-icons-panel-styles__question {
  display: flex;
  flex-grow: 1;
}

.ladder_trustage_upper_funnel_routing__question-icons-panel-styles__question:first-of-type {
  flex-grow: 0;
}

.ladder_trustage_upper_funnel_routing__question-icons-panel-styles__icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.ladder_trustage_upper_funnel_routing__question-icons-panel-styles__icon {
  background-color: #5D5D5D;
  border-radius: 50%;
}

.ladder_trustage_upper_funnel_routing__question-icons-panel-styles__copy {
  display: flex;
  white-space: pre;
}

.ladder_trustage_upper_funnel_routing__question-icons-panel-styles__dashed {
  border-style: dashed;
  border-color: #5D5D5D;
}

@media (min-width: 1025px) {

  
  
  .ladder_trustage_upper_funnel_routing__question-icons-panel-styles__wrapper {
    width: 100%;
  }
  
  .ladder_trustage_upper_funnel_routing__question-icons-panel-styles__icon-wrapper {
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  
  .ladder_trustage_upper_funnel_routing__question-icons-panel-styles__icon {
    padding: 10px;
  }
  
  .ladder_trustage_upper_funnel_routing__question-icons-panel-styles__icon.ladder_trustage_upper_funnel_routing__question-icons-panel-styles__health {
    padding: 5px;
  }
  
  .ladder_trustage_upper_funnel_routing__question-icons-panel-styles__icon-dimensions {
    height: 46px;
    width: 46px;
  }
  
  .ladder_trustage_upper_funnel_routing__question-icons-panel-styles__copy {
    justify-content: center;
    padding-top: 10px;
  }
  
  .ladder_trustage_upper_funnel_routing__question-icons-panel-styles__dashed {
    border-bottom-width: 2px;
    height: 25%;
    flex-grow: 1;
    margin: 0 5px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_trustage_upper_funnel_routing__question-icons-panel-styles__wrapper {
    width: 100%;
  }
  
  .ladder_trustage_upper_funnel_routing__question-icons-panel-styles__icon-wrapper {
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  
  .ladder_trustage_upper_funnel_routing__question-icons-panel-styles__icon {
    padding: 10px;
  }
  
  .ladder_trustage_upper_funnel_routing__question-icons-panel-styles__icon.ladder_trustage_upper_funnel_routing__question-icons-panel-styles__health {
    padding: 5px;
  }
  
  .ladder_trustage_upper_funnel_routing__question-icons-panel-styles__icon-dimensions {
    height: 46px;
    width: 46px;
  }
  
  .ladder_trustage_upper_funnel_routing__question-icons-panel-styles__copy {
    justify-content: center;
    padding-top: 10px;
  }
  
  .ladder_trustage_upper_funnel_routing__question-icons-panel-styles__dashed {
    border-bottom-width: 2px;
    height: 25%;
    flex-grow: 1;
    margin: 0 5px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_trustage_upper_funnel_routing__question-icons-panel-styles__wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .ladder_trustage_upper_funnel_routing__question-icons-panel-styles__question {
    flex-direction: column;
  }
  
  .ladder_trustage_upper_funnel_routing__question-icons-panel-styles__icon-wrapper {
    flex-direction: row;
  }
  
  .ladder_trustage_upper_funnel_routing__question-icons-panel-styles__icon-dimensions {
    height: 21px;
    width: 21px;
  }
  
  .ladder_trustage_upper_funnel_routing__question-icons-panel-styles__icon {
    padding: 5px;
  }
  
  .ladder_trustage_upper_funnel_routing__question-icons-panel-styles__icon.ladder_trustage_upper_funnel_routing__question-icons-panel-styles__health {
    padding: 3px;
  }
  
  .ladder_trustage_upper_funnel_routing__question-icons-panel-styles__copy {
    padding-left: 10px;
  }
  
  .ladder_trustage_upper_funnel_routing__question-icons-panel-styles__dashed {
    border-right-width: 2px;
    height: 40px;
    width: 25%;
    margin: 5px 0;
  }

}
.ladder_agents_dashboard_quote__quoter-and-app-button-styles__section {
  background-color: #FFFFFF;
  border-radius: 36px;
  box-sizing: border-box;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
}

.ladder_agents_dashboard_quote__quoter-and-app-button-styles__quoter-copy {
  margin: 10.0px 0 30.0px 0;
}

.ladder_agents_dashboard_quote__quoter-and-app-button-styles__title-row {
  display: flex;
  justify-content: space-between;
}

@media (min-width: 1025px) {

  
  
  .ladder_agents_dashboard_quote__quoter-and-app-button-styles__quoter-and-app-button {
    display: flex;
    align-items: flex-start;
  }
  
  .ladder_agents_dashboard_quote__quoter-and-app-button-styles__section {
    padding: 32px 40px;
    max-width: 834px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard_quote__quoter-and-app-button-styles__section {
    padding: 20px;
    margin-bottom: 10.0px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_dashboard_quote__quoter-and-app-button-styles__section {
    padding: 20px;
    margin-bottom: 10.0px;
  }

}
.ladder_savings_common__steps-process-styles__steps-wrapper {
  display: flex;
  justify-content: space-between;
}

.ladder_savings_common__steps-process-styles__step {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 150px;
  text-align: center;
}

.ladder_savings_common__steps-process-styles__icon {
  height: 50.0px;
  fill: #975C46;
}

@media (min-width: 1025px) {

  
  
  .ladder_savings_common__steps-process-styles__title {
    margin: 20px 0;
  }
  
  .ladder_savings_common__steps-process-styles__step {
    margin-top: 20px;
    text-align: center;
  }
  
  .ladder_savings_common__steps-process-styles__middle {
    flex-grow: 1;
  }
  
  .ladder_savings_common__steps-process-styles__middle .ladder_savings_common__steps-process-styles__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .ladder_savings_common__steps-process-styles__middle .ladder_savings_common__steps-process-styles__desc {
    width: 80%;
  }
  
  .ladder_savings_common__steps-process-styles__middle .ladder_savings_common__steps-process-styles__arrow {
    width: 30%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_savings_common__steps-process-styles__title {
    margin: 20px 0;
  }
  
  .ladder_savings_common__steps-process-styles__step {
    margin-top: 20px;
    text-align: center;
  }
  
  .ladder_savings_common__steps-process-styles__middle {
    flex-grow: 1;
  }
  
  .ladder_savings_common__steps-process-styles__middle .ladder_savings_common__steps-process-styles__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .ladder_savings_common__steps-process-styles__middle .ladder_savings_common__steps-process-styles__desc {
    width: 80%;
  }
  
  .ladder_savings_common__steps-process-styles__middle .ladder_savings_common__steps-process-styles__arrow {
    width: 30%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_savings_common__steps-process-styles__steps-wrapper {
    flex-direction: column;
  }
  
  .ladder_savings_common__steps-process-styles__title {
    white-space: pre;
  }
  
  .ladder_savings_common__steps-process-styles__savings-steps-title {
    margin: 20px 0 20px 0;
  }
  
  .ladder_savings_common__steps-process-styles__arrow {
    margin: 10.0px 0;
  }

}
.ladder_ux_carousel__control-arrow-inline-styles__control-arrow {
  display: flex;
  width: 30px;
  border-radius: 50%;
  padding: 8px;
  margin-top: 10px;
}

.ladder_ux_carousel__control-arrow-inline-styles__control-arrow:hover {
  cursor: pointer;
}

.ladder_ux_carousel__control-arrow-inline-styles__control-arrow .ladder_ux_carousel__control-arrow-inline-styles__svg-enabled {
  fill: #707070;
}

.ladder_ux_carousel__control-arrow-inline-styles__control-arrow .ladder_ux_carousel__control-arrow-inline-styles__svg-disabled {
  fill: #D0D0D0;
}
.ladder_landing_pages_trustage__main-text-styles__root {
  display: inline-block;
  z-index: 1;
  width: 100%;
  height: auto;
}

.ladder_landing_pages_trustage__main-text-styles__so-good-campaign-wrapper {
  display: block;
  max-width: 800px;
}

.ladder_landing_pages_trustage__main-text-styles__superscript {
  vertical-align: super;
  font-size: 0.7em;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_landing_pages_trustage__main-text-styles__so-good-campaign-wrapper {
    font-size: 40px;
    line-height: 50px;
    letter-spacing: -0.3px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_landing_pages_trustage__main-text-styles__root {
    line-height: 40px;
  }
  
  .ladder_landing_pages_trustage__main-text-styles__so-good-campaign-wrapper {
    display: block;
  }

}
.ladder_ux_modal__TwoColModal-styles__relative {
  width: 80%;
  position: relative;
}

.ladder_ux_modal__TwoColModal-styles__fixed {
  width: 24%;
  right: 3%;
  top: 110px;
  height: 100%;
  align-items: flex-start;
  position: fixed;
  display: flex;
  bottom: 0px;
  padding-top: 80px;
}

.ladder_ux_modal__TwoColModal-styles__v-centered {
  padding-top: 0px;
  top: 0px;
  align-items: center;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_modal__TwoColModal-styles__fixed {
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 14;
    transform: none;
  }

}
.ladder_form_upper_funnel_routing_highlight_media_alpha__highlight-mediaalpha-body-styles__main-container {
  max-width: 630px;
  margin-left: auto;
  display: block;
  margin-right: auto;
}

.ladder_form_upper_funnel_routing_highlight_media_alpha__highlight-mediaalpha-body-styles__button-container {
  padding-bottom: 40px;
}

.ladder_form_upper_funnel_routing_highlight_media_alpha__highlight-mediaalpha-body-styles__button {
  width: 300px;
  justify-content: center;
  font-family: Lato, sans-serif;
  height: 70px;
  user-select: none;
  align-items: center;
  outline: none;
  position: relative;
  cursor: pointer;
  font-weight: 700;
  font-size: 13px;
  background-color: #000000;
  display: inline-flex;
  color: #FFFFFF;
}

.ladder_form_upper_funnel_routing_highlight_media_alpha__highlight-mediaalpha-body-styles__img {
  max-width: 230px;
}

.ladder_form_upper_funnel_routing_highlight_media_alpha__highlight-mediaalpha-body-styles__text-padding-top {
  padding-top: 15px;
}

.ladder_form_upper_funnel_routing_highlight_media_alpha__highlight-mediaalpha-body-styles__good-fit-text {
  padding: 15px 0;
}

.ladder_form_upper_funnel_routing_highlight_media_alpha__highlight-mediaalpha-body-styles__text-padding-top-and-bottom {
  padding-top: 5px;
  padding-bottom: 20px;
}

@media (max-width: 499px) {

  
  
  .ladder_form_upper_funnel_routing_highlight_media_alpha__highlight-mediaalpha-body-styles__good-fit-text {
    padding-top: 15px;
    padding-bottom: 5px;
  }
  
  .ladder_form_upper_funnel_routing_highlight_media_alpha__highlight-mediaalpha-body-styles__text-padding-top-and-bottom {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  
  .ladder_form_upper_funnel_routing_highlight_media_alpha__highlight-mediaalpha-body-styles__center-url-text {
    text-align: center;
  }
  
  .ladder_form_upper_funnel_routing_highlight_media_alpha__highlight-mediaalpha-body-styles__img {
    max-width: 160px;
  }
  
  .ladder_form_upper_funnel_routing_highlight_media_alpha__highlight-mediaalpha-body-styles__button {
    width: 330px;
    justify-content: center;
    font-family: Lato, sans-serif;
    height: 70px;
    user-select: none;
    align-items: center;
    outline: none;
    position: relative;
    cursor: pointer;
    font-weight: 700;
    font-size: 13px;
    background-color: #000000;
    display: inline-flex;
    color: #FFFFFF;
  }
  
  .ladder_form_upper_funnel_routing_highlight_media_alpha__highlight-mediaalpha-body-styles__main-container {
    max-width: 330px;
    margin-left: auto;
    display: block;
    margin-right: auto;
  }

}
.ladder_content__api-terms-page-styles__date {
  margin-top: 32px;
}
.ladder_agents_dashboard_pre_screener__start-quote-button-styles__button-container {
  max-width: 270px;
  width: 100%;
}

.ladder_agents_dashboard_pre_screener__start-quote-button-styles__button {
  border-radius: 24px;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
}
.ladder_quick_start_panels__homepage-lottie-animation-static-image-replacement-panel-styles__root {
  display: none;
}

@media (max-width: 499px) {

  
  
  .ladder_quick_start_panels__homepage-lottie-animation-static-image-replacement-panel-styles__root {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  
  .ladder_quick_start_panels__homepage-lottie-animation-static-image-replacement-panel-styles__panel-1 {
    display: flex;
    height: 200px;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: #FFFFFF;
  }
  
  .ladder_quick_start_panels__homepage-lottie-animation-static-image-replacement-panel-styles__panel-1 .ladder_quick_start_panels__homepage-lottie-animation-static-image-replacement-panel-styles__text {
    width: 90%;
    text-align: center;
  }
  
  .ladder_quick_start_panels__homepage-lottie-animation-static-image-replacement-panel-styles__panel-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F7F2EF;
  }
  
  .ladder_quick_start_panels__homepage-lottie-animation-static-image-replacement-panel-styles__panel-2 .ladder_quick_start_panels__homepage-lottie-animation-static-image-replacement-panel-styles__panel-2-number {
    width: 90%;
    color: #975C46;
    margin: 32px 0 0 0;
  }
  
  .ladder_quick_start_panels__homepage-lottie-animation-static-image-replacement-panel-styles__panel-2 .ladder_quick_start_panels__homepage-lottie-animation-static-image-replacement-panel-styles__panel-2-text {
    width: 90%;
    margin: 0 0 32px 0;
  }
  
  .ladder_quick_start_panels__homepage-lottie-animation-static-image-replacement-panel-styles__panel-2 .ladder_quick_start_panels__homepage-lottie-animation-static-image-replacement-panel-styles__panel-2-box {
    width: 55%;
    height: auto;
    margin: 16px 0 64px 0;
  }
  
  .ladder_quick_start_panels__homepage-lottie-animation-static-image-replacement-panel-styles__panel-2 .ladder_quick_start_panels__homepage-lottie-animation-static-image-replacement-panel-styles__panel-2-image {
    width: 100%;
    height: auto;
  }
  
  .ladder_quick_start_panels__homepage-lottie-animation-static-image-replacement-panel-styles__panel-3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #DBE6DB;
  }
  
  .ladder_quick_start_panels__homepage-lottie-animation-static-image-replacement-panel-styles__panel-3 .ladder_quick_start_panels__homepage-lottie-animation-static-image-replacement-panel-styles__panel-3-number {
    width: 90%;
    color: #975C46;
    margin: 32px 0 0 0;
  }
  
  .ladder_quick_start_panels__homepage-lottie-animation-static-image-replacement-panel-styles__panel-3 .ladder_quick_start_panels__homepage-lottie-animation-static-image-replacement-panel-styles__panel-3-text {
    width: 90%;
    margin: 0 0 32px 0;
  }
  
  .ladder_quick_start_panels__homepage-lottie-animation-static-image-replacement-panel-styles__panel-3 .ladder_quick_start_panels__homepage-lottie-animation-static-image-replacement-panel-styles__panel-3-box {
    width: 55%;
    height: auto;
    margin: 16px 0 64px 0;
  }
  
  .ladder_quick_start_panels__homepage-lottie-animation-static-image-replacement-panel-styles__panel-3 .ladder_quick_start_panels__homepage-lottie-animation-static-image-replacement-panel-styles__panel-3-image {
    width: 100%;
    height: auto;
  }
  
  .ladder_quick_start_panels__homepage-lottie-animation-static-image-replacement-panel-styles__panel-4 {
    display: flex;
    flex-direction: column;
    height: 650px;
    position: relative;
    align-items: center;
    overflow: hidden;
    background-color: #E7EEEF;
  }
  
  .ladder_quick_start_panels__homepage-lottie-animation-static-image-replacement-panel-styles__panel-4 .ladder_quick_start_panels__homepage-lottie-animation-static-image-replacement-panel-styles__panel-4-button {
    width: 90%;
    margin-top: 16px;
  }
  
  .ladder_quick_start_panels__homepage-lottie-animation-static-image-replacement-panel-styles__panel-4 .ladder_quick_start_panels__homepage-lottie-animation-static-image-replacement-panel-styles__panel-4-number {
    width: 90%;
    color: #464F50;
    margin: 32px 0 0 0;
  }
  
  .ladder_quick_start_panels__homepage-lottie-animation-static-image-replacement-panel-styles__panel-4 .ladder_quick_start_panels__homepage-lottie-animation-static-image-replacement-panel-styles__panel-4-text {
    width: 90%;
    margin: 0 0 32px 0;
  }
  
  .ladder_quick_start_panels__homepage-lottie-animation-static-image-replacement-panel-styles__panel-4 .ladder_quick_start_panels__homepage-lottie-animation-static-image-replacement-panel-styles__panel-4-box {
    position: absolute;
    height: auto;
    width: 140%;
    max-width: 600px;
    min-width: 500px;
    bottom: 0;
    margin: 16px 0 0 0;
  }
  
  .ladder_quick_start_panels__homepage-lottie-animation-static-image-replacement-panel-styles__panel-4 .ladder_quick_start_panels__homepage-lottie-animation-static-image-replacement-panel-styles__panel-4-image {
    width: 100%;
    height: auto;
  }

}
.ladder_careers_values__carousel-styles__root {
  width: 100%;
  height: 100%;
}

.ladder_careers_values__carousel-styles__content {
  display: flex;
  margin: auto;
  align-items: center;
  height: 100%;
}

.ladder_careers_values__carousel-styles__content-side-buttons {
  flex-direction: row;
}

.ladder_careers_values__carousel-styles__content-bottom-buttons {
  flex-direction: column;
}

.ladder_careers_values__carousel-styles__check-width {
  height: 0px;
  width: 100vw;
  visibility: hidden;
}

.ladder_careers_values__carousel-styles__view {
  height: 100%;
  width: 100%;
  margin: auto;
  flex-grow: 1;
  overflow: hidden;
}

.ladder_careers_values__carousel-styles__bottom-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.ladder_careers_values__carousel-styles__bottom-buttons-container>* {
  margin: 22px 6px 0 6px;
}
.ladder_widget_ui_v2__panel-back-styles__panel {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.ladder_widget_ui_v2__panel-back-styles__left-panel {
  width: 50%;
  height: 100%;
  padding: 16px;
  align-item: center;
  display: flex;
  flex-direction: column;
}

.ladder_widget_ui_v2__panel-back-styles__header {
  margin-top: 42px;
  margin-bottom: 42px;
  text-align: center;
}

.ladder_widget_ui_v2__panel-back-styles__instructions {
  margin-left: 36px;
}

.ladder_widget_ui_v2__panel-back-styles__text {
  margin-top: 8px;
}

.ladder_widget_ui_v2__panel-back-styles__right-panel {
  width: 50%;
  height: 100%;
}

.ladder_widget_ui_v2__panel-back-styles__logo {
  width: 141px;
  flex-grow: 0;
  margin: 18px 0px 0px 18px;
}

.ladder_widget_ui_v2__panel-back-styles__button {
  flex-grow: 100;
  display: flex;
  align-items: flex-end;
}
.ladder_account_shared_components__rungs-document-entry-styles__root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-basis: 100%;
  border: 1px solid #707070;
  padding: 20px;
  margin: 20px 0;
  text-align: left;
}

@media (max-width: 499px) {

  
  
  .ladder_account_shared_components__rungs-document-entry-styles__root {
    padding: 20px 20px 20px 0;
    flex-direction: column;
    align-items: start;
  }
  
  .ladder_account_shared_components__rungs-document-entry-styles__root>* {
    margin: 10.0px 0;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_account_shared_components__rungs-document-entry-styles__full-width {
    width: 100%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account_shared_components__rungs-document-entry-styles__full-width {
    width: 100%;
  }

}
.ladder_nearly_perfect_quote_bundles__bundle-button-styles__root {
  display: flex;
  border: solid 1px#7A8A8C;
  border-radius: 9px;
  margin: 10px 10px 0 0;
}

.ladder_nearly_perfect_quote_bundles__bundle-button-styles__main {
  border-right: solid 1px#7A8A8C;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
  padding: 10px 5px;
  cursor: pointer;
}

.ladder_nearly_perfect_quote_bundles__bundle-button-styles__main:hover {
  background-color: #E7EEEF;
}

.ladder_nearly_perfect_quote_bundles__bundle-button-styles__selected {
  background-color: #E7EEEF;
}

.ladder_nearly_perfect_quote_bundles__bundle-button-styles__loading {
  opacity: 0.5;
}

.ladder_nearly_perfect_quote_bundles__bundle-button-styles__disabled {
  cursor: default;
  pointer-events: none;
}

.ladder_nearly_perfect_quote_bundles__bundle-button-styles__disabled:hover {
  background-color: auto;
}

.ladder_nearly_perfect_quote_bundles__bundle-button-styles__icon-container {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0px 3px;
  cursor: pointer;
}

.ladder_nearly_perfect_quote_bundles__bundle-button-styles__spinner {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 10px;
  overflow: hidden;
}

@media (max-width: 499px) {

  
  
  .ladder_nearly_perfect_quote_bundles__bundle-button-styles__main {
    text-align: left;
    padding: 0 5px;
  }
  
  .ladder_nearly_perfect_quote_bundles__bundle-button-styles__main >div {
    margin: 10px 5px;
  }
  
  .ladder_nearly_perfect_quote_bundles__bundle-button-styles__icon {
    height: 20px;
    width: 20px;
  }

}

.ladder_nearly_perfect_quote_bundles__bundle-button-styles__show-details .ladder_nearly_perfect_quote_bundles__bundle-button-styles__main {
  text-align: left;
  padding: 0 5px;
}

.ladder_nearly_perfect_quote_bundles__bundle-button-styles__show-details .ladder_nearly_perfect_quote_bundles__bundle-button-styles__main >div {
  margin: 10px 5px;
}

.ladder_nearly_perfect_quote_bundles__bundle-button-styles__show-details .ladder_nearly_perfect_quote_bundles__bundle-button-styles__icon {
  height: 20px;
  width: 20px;
}
.ladder_ny_form_reg60__reg60-field-styles__radio-wrapper {
  max-width: 460px;
  margin: 13px auto 20px 0;
}
.ladder_ux_google__google-section-styles__google-section {
  text-align: left;
  display: flex;
  flex-direction: column;
}

.ladder_ux_google__google-section-styles__google-stars {
  height: 24px;
  margin: 5px 0;
}

.ladder_ux_google__google-section-styles__google-score {
  height: 14px;
}

.ladder_ux_google__google-section-styles__s .ladder_ux_google__google-section-styles__google-logo {
  height: 42px;
  width: 126px;
}

.ladder_ux_google__google-section-styles__s .ladder_ux_google__google-section-styles__google-stars {
  height: 24px;
  width: 126px;
  margin: 5px 0;
}

.ladder_ux_google__google-section-styles__s .ladder_ux_google__google-section-styles__google-score {
  height: 14px;
  width: 128px;
}

.ladder_ux_google__google-section-styles__l .ladder_ux_google__google-section-styles__google-logo {
  height: 55px;
  width: 166px;
}

.ladder_ux_google__google-section-styles__l .ladder_ux_google__google-section-styles__google-stars {
  height: 29px;
  width: 166px;
}

.ladder_ux_google__google-section-styles__l .ladder_ux_google__google-section-styles__google-score {
  height: 18px;
  width: 166px;
}
.ladder_work_home__top-panel-cta-styles__root {
  position: relative;
}

.ladder_work_home__top-panel-cta-styles__button-container {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
}

.ladder_work_home__top-panel-cta-styles__background-images {
  display: flex;
  justify-content: space-between;
  padding: 0 30.0px;
}

.ladder_work_home__top-panel-cta-styles__just-height {
  height: 150px;
}

.ladder_work_home__top-panel-cta-styles__image-container {
  max-width: 30%;
}

.ladder_work_home__top-panel-cta-styles__image-container .ladder_work_home__top-panel-cta-styles__image {
  width: 100%;
  height: auto;
}

@media (max-width: 499px) {

  
  
  .ladder_work_home__top-panel-cta-styles__just-height {
    height: 75px;
  }
  
  .ladder_work_home__top-panel-cta-styles__button-container {
    position: relative;
    margin: 0 auto 30.0px;
    width: 90%;
  }
  
  .ladder_work_home__top-panel-cta-styles__background-images {
    padding: 20px 10.0px;
  }
  
  .ladder_work_home__top-panel-cta-styles__image-container {
    max-width: 42%;
  }

}
.ladder_ux_spinner__spinner-styles__root {
  height: 32px;
  width: 32px;
  border-color: transparent;
  border-bottom-color: #D0D0D0;
  border-radius: 50%;
  border-style: solid;
  border-width: 3px;
  animation: spin 1.1s infinite linear;
}
.ladder_agents_home__title-styles__root {
  display: flex;
  position: relative;
  background: #CADDDF;
  flex-direction: row;
  width: 100%;
  z-index: 1;
  overflow: hidden;
}

.ladder_agents_home__title-styles__container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.ladder_agents_home__title-styles__text-container {
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.ladder_agents_home__title-styles__image-container {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.ladder_agents_home__title-styles__image {
  max-width: 100%;
  height: auto;
  z-index: 0;
  align-self: flex-end;
}

.ladder_agents_home__title-styles__text {
  margin: 30.0px 0 60px 0;
  width: 100%;
}

.ladder_agents_home__title-styles__button {
  display: block;
  width: 50%;
}

.ladder_agents_home__title-styles__links {
  display: flex;
  flex-direction: column;
}

.ladder_agents_home__title-styles__links .ladder_agents_home__title-styles__log-in {
  margin: 30.0px 0;
}

@media (min-width: 1025px) {

  
  
  .ladder_agents_home__title-styles__text-container {
    max-width: 650px;
    min-width: 300px;
    width: 40%;
    margin: 60px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_agents_home__title-styles__container {
    flex-direction: column;
    margin: 36px auto 0;
  }
  
  .ladder_agents_home__title-styles__text-container {
    margin: 20px;
  }
  
  .ladder_agents_home__title-styles__image-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .ladder_agents_home__title-styles__image {
    position: relative;
    width: 80%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_home__title-styles__container {
    flex-direction: column;
    margin: 36px auto 0;
  }
  
  .ladder_agents_home__title-styles__text-container {
    margin: 20px;
  }
  
  .ladder_agents_home__title-styles__image-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .ladder_agents_home__title-styles__image {
    position: relative;
    width: 80%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_agents_home__title-styles__root {
    min-height: 660px;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .ladder_agents_home__title-styles__container {
    width: 90%;
  }
  
  .ladder_agents_home__title-styles__image-container {
    width: 164%;
    transform: translateX(-16%);
  }
  
  .ladder_agents_home__title-styles__text-container {
    max-width: 90%;
  }
  
  .ladder_agents_home__title-styles__button {
    width: 100%;
  }

}
@media (min-width: 1025px) {

  
  
  .ladder_about__how-we-do-it-styles__container {
    margin-top: 167px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_about__how-we-do-it-styles__container {
    margin-top: 70px;
  }
  
  .ladder_about__how-we-do-it-styles__copy {
    margin-top: 26px;
    margin-bottom: 50.0px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_about__how-we-do-it-styles__container {
    margin-top: 70px;
  }
  
  .ladder_about__how-we-do-it-styles__copy {
    margin-top: 26px;
    margin-bottom: 50.0px;
  }

}
@media (min-width: 1025px) {

  
  
  .ladder_apply_account_created_notification__AccountCreatedNotification-styles__fix-height {
    height: 100px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_apply_account_created_notification__AccountCreatedNotification-styles__fix-height {
    height: 100px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_apply_account_created_notification__AccountCreatedNotification-styles__message-width {
    width: 100%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_apply_account_created_notification__AccountCreatedNotification-styles__message-width {
    width: 90%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_apply_account_created_notification__AccountCreatedNotification-styles__message-width {
    width: 90%;
  }

}
.ladder_server_error__header-styles__root {
  height: 100px;
  width: 100%;
}

.ladder_server_error__header-styles__header {
  top: 0;
  left: 0;
  right: 0;
  align-items: center;
  height: 100px;
  background-color: #FFFFFF;
}

.ladder_server_error__header-styles__logo-container {
  height: 100%;
  align-items: center;
  display: flex;
  padding: 0 130px;
}
.ladder_ux_modal__slide-up-modal-styles__root {
  width: 100%;
  right: 0;
  justify-content: center;
  height: 100%;
  align-items: center;
  position: fixed;
  z-index: 14;
  display: flex;
  bottom: 0;
  pointer-events: none;
  transition: background 250ms;
  background: rgba(0,0,0,0);
  max-height: -webkit-fill-available;
  left: 0;
}

.ladder_ux_modal__slide-up-modal-styles__root >div {
  max-height: 90%;
  display: flex;
}

.ladder_ux_modal__slide-up-modal-styles__root.ladder_ux_modal__slide-up-modal-styles__full-page >div {
  max-height: calc(100% - 60px);
}

.ladder_ux_modal__slide-up-modal-styles__open {
  background: rgba(0, 0, 0, 0.8);
  pointer-events: auto;
}

.ladder_ux_modal__slide-up-modal-styles__drawer {
  border-radius: 10px 10px 0 0;
  width: 100%;
}

.ladder_ux_modal__slide-up-modal-styles__drawer >div {
  border-radius: 10px 10px 0 0;
}
.ladder_trustage_upper_funnel_routing__back-arrow-styles__wrapper {
  margin-bottom: 20px;
}
.ladder_form_common__error-message-styles__root {
  margin-top: 4px;
  min-height: 16px;
}

.ladder_form_common__error-message-styles__hidden {
  visibility: hidden;
}

.ladder_form_common__error-message-styles__should-disappear {
  display: none;
}
.ladder_quick_start_panels__homepage-animation-video-panel-styles__root {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 750px;
}

.ladder_quick_start_panels__homepage-animation-video-panel-styles__panel {
  display: flex;
  justify-content: space-evenly;
  width: 90%;
  max-width: 1000px;
}

.ladder_quick_start_panels__homepage-animation-video-panel-styles__video-wrapper {
  display: flex;
  position: relative;
  width: 245px;
  height: 465px;
}

.ladder_quick_start_panels__homepage-animation-video-panel-styles__video-frame {
  position: absolute;
  top: 3px;
  left: 5px;
  width: 239px;
  height: 467px;
  z-index: 2;
}

.ladder_quick_start_panels__homepage-animation-video-panel-styles__video {
  display: flex;
  position: absolute;
  width: 250px;
  height: 472px;
  z-index: 1;
}

.ladder_quick_start_panels__homepage-animation-video-panel-styles__right-subpanel {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: 32px 0 0 64px;
}

.ladder_quick_start_panels__homepage-animation-video-panel-styles__tell-us-about-yourself {

}

.ladder_quick_start_panels__homepage-animation-video-panel-styles__text {
  display: flex;
  width: 100%;
  white-space: pre;
  margin: 32px 0 48px 0;
}

.ladder_quick_start_panels__homepage-animation-video-panel-styles__top-bar-wrapper {
  display: flex;
  width: 100%;
  height: 0.3vw;
  margin: 32px 0;
  justify-content: space-between;
}

.ladder_quick_start_panels__homepage-animation-video-panel-styles__gray-bar-wrapper {
  height: 100%;
  margin: 16px 0.5% 0 0.5%;
  flex-direction: row;
  position: relative;
  z-index: 1;
  flex-grow: 1;
  cursor: pointer;
  background-color: #F1F1F1;
  display: flex;
}

.ladder_quick_start_panels__homepage-animation-video-panel-styles__gray-bar-wrapper:first-child {
  margin-right: 0.5%;
}

.ladder_quick_start_panels__homepage-animation-video-panel-styles__gray-bar-wrapper:last-child {
  margin-left: 0.5%;
}

.ladder_quick_start_panels__homepage-animation-video-panel-styles__current-bar {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  width: 0%;
  background-color: #1A1A1A;
}

.ladder_quick_start_panels__homepage-animation-video-panel-styles__current-bar.ladder_quick_start_panels__homepage-animation-video-panel-styles__dark-green {
  background-color: #91AF92;
}

.ladder_quick_start_panels__homepage-animation-video-panel-styles__current-bar.ladder_quick_start_panels__homepage-animation-video-panel-styles__phoenix {
  background-color: #975C46;
}

.ladder_quick_start_panels__homepage-animation-video-panel-styles__current-bar.ladder_quick_start_panels__homepage-animation-video-panel-styles__opal {
  background-color: #AFC5C8;
}

.ladder_quick_start_panels__homepage-animation-video-panel-styles__current-bar.ladder_quick_start_panels__homepage-animation-video-panel-styles__first-transition {
  transition: width 6s linear;
}

.ladder_quick_start_panels__homepage-animation-video-panel-styles__current-bar.ladder_quick_start_panels__homepage-animation-video-panel-styles__second-transition {
  transition: width 5s linear;
}

.ladder_quick_start_panels__homepage-animation-video-panel-styles__current-bar.ladder_quick_start_panels__homepage-animation-video-panel-styles__selected {
  width: 100%;
}

.ladder_quick_start_panels__homepage-animation-video-panel-styles__current-bar.ladder_quick_start_panels__homepage-animation-video-panel-styles__unselected {
  transition: width 0s;
}

.ladder_quick_start_panels__homepage-animation-video-panel-styles__current-bar.ladder_quick_start_panels__homepage-animation-video-panel-styles__last-transition {
  transition: width 8s linear;
}

.ladder_quick_start_panels__homepage-animation-video-panel-styles__mobile-button {
  display: none;
}

@media (min-width: 1025px) {

  
  
  .ladder_quick_start_panels__homepage-animation-video-panel-styles__desktop-button {
    display: flex;
    max-width: 250px;
    margin: 16px 0;
  }
  
  .ladder_quick_start_panels__homepage-animation-video-panel-styles__mobile-button {
    display: none;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_quick_start_panels__homepage-animation-video-panel-styles__root {
    height: auto;
  }
  
  .ladder_quick_start_panels__homepage-animation-video-panel-styles__panel {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    height: auto;
  }
  
  .ladder_quick_start_panels__homepage-animation-video-panel-styles__right-subpanel {
    width: 100%;
    align-items: center;
    margin: 48px 0 0 0;
  }
  
  .ladder_quick_start_panels__homepage-animation-video-panel-styles__top-bar-wrapper {
    height: 5px;
    margin: 0 0 32px 0;
  }
  
  .ladder_quick_start_panels__homepage-animation-video-panel-styles__text {
    height: 75px;
    margin: 0;
    text-align: start;
    white-space: pre-wrap;
  }
  
  .ladder_quick_start_panels__homepage-animation-video-panel-styles__desktop-button {
    display: none;
  }
  
  .ladder_quick_start_panels__homepage-animation-video-panel-styles__mobile-button {
    display: flex;
    width: 100%;
    margin: 48px 0;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_quick_start_panels__homepage-animation-video-panel-styles__root {
    height: auto;
  }
  
  .ladder_quick_start_panels__homepage-animation-video-panel-styles__panel {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    height: auto;
  }
  
  .ladder_quick_start_panels__homepage-animation-video-panel-styles__right-subpanel {
    width: 100%;
    align-items: center;
    margin: 48px 0 0 0;
  }
  
  .ladder_quick_start_panels__homepage-animation-video-panel-styles__top-bar-wrapper {
    height: 5px;
    margin: 0 0 32px 0;
  }
  
  .ladder_quick_start_panels__homepage-animation-video-panel-styles__text {
    height: 75px;
    margin: 0;
    text-align: start;
    white-space: pre-wrap;
  }
  
  .ladder_quick_start_panels__homepage-animation-video-panel-styles__desktop-button {
    display: none;
  }
  
  .ladder_quick_start_panels__homepage-animation-video-panel-styles__mobile-button {
    display: flex;
    width: 100%;
    margin: 48px 0;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_quick_start_panels__homepage-animation-video-panel-styles__mobile-button {
    max-width: 250px;
  }

}
.ladder_ux_three_steps_section__three-steps-section-item-styles__root {
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
  text-align: center;
  flex-grow: 1;
  position: relative;
  padding: 32px;
}

.ladder_ux_three_steps_section__three-steps-section-item-styles__root img {
  display: block;
}

.ladder_ux_three_steps_section__three-steps-section-item-styles__root svg {
  display: block;
  margin: 0 auto;
}

.ladder_ux_three_steps_section__three-steps-section-item-styles__description-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ladder_ux_three_steps_section__three-steps-section-item-styles__description-margin-top {
  margin-top: 20.0px;
}

.ladder_ux_three_steps_section__three-steps-section-item-styles__info-expander-container {
  padding-left: 4.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_three_steps_section__three-steps-section-item-styles__root {
    flex-basis: 100%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_three_steps_section__three-steps-section-item-styles__root {
    flex-basis: 100%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_three_steps_section__three-steps-section-item-styles__padding {
    padding: 16px 32px 16px 32px;
  }

}
.ladder_agents_pass_the_application_header__banner-desktop-styles__outer-container {
  flex-grow: 1;
  height: 100%;
  display: flex;
  align-items: center;
}

.ladder_agents_pass_the_application_header__banner-desktop-styles__vertical-bar {
  margin-left: 32px;
  width: 0px;
  height: 67%;
  border-left: 1px solid white;
}

.ladder_agents_pass_the_application_header__banner-desktop-styles__text-container {
  margin-left: 32px;
  display: flex;
  height: 55%;
  flex-direction: column;
  justify-content: space-around;
}
.ladder_form_upper_funnel_routing_highlight_media_alpha__carrier-descriptions-styles__description-container {
  padding-bottom: 40px;
}

.ladder_form_upper_funnel_routing_highlight_media_alpha__carrier-descriptions-styles__description-bullets {
  display: flex;
  padding-top: 18px;
  flex-direction: row;
}

.ladder_form_upper_funnel_routing_highlight_media_alpha__carrier-descriptions-styles__description-text {
  padding-top: 5px;
  padding-left: 16px;
}

.ladder_form_upper_funnel_routing_highlight_media_alpha__carrier-descriptions-styles__icon {
  margin-top: 5px;
  min-width: 35px;
  max-width: 35px;
  min-height: 35px;
  max-height: 35px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_form_upper_funnel_routing_highlight_media_alpha__carrier-descriptions-styles__icon {
    margin-top: 5px;
    min-width: 25px;
    max-width: 25px;
    min-height: 25px;
    max-height: 25px;
  }
  
  .ladder_form_upper_funnel_routing_highlight_media_alpha__carrier-descriptions-styles__description-text {
    padding-top: 2px;
    padding-left: 12px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_form_upper_funnel_routing_highlight_media_alpha__carrier-descriptions-styles__icon {
    margin-top: 5px;
    min-width: 25px;
    max-width: 25px;
    min-height: 25px;
    max-height: 25px;
  }
  
  .ladder_form_upper_funnel_routing_highlight_media_alpha__carrier-descriptions-styles__description-text {
    padding-top: 2px;
    padding-left: 12px;
  }

}
.ladder_view_schedule__SchedulerCTA-styles__root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 10.0px;
}

.ladder_view_schedule__SchedulerCTA-styles__address-wrapper {
  padding-bottom: 10px;
}
.ladder_financial_advisors_dashboard__referral-pill-styles__pill {
  padding: 5.0px 10.0px;
  margin: 10.0px 5.0px;
  border-radius: 1em;
  background-color: #D0D0D0;
  display: inline-block;
}
.ladder_agents_dashboard_pre_screener__answer-yes-or-no-styles__container {
  display: flex;
  flex-direction: row;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard_pre_screener__answer-yes-or-no-styles__container {
    width: 100%;
    gap: 5px;
    justify-content: space-between;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_dashboard_pre_screener__answer-yes-or-no-styles__container {
    width: 100%;
    gap: 5px;
    justify-content: space-between;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_agents_dashboard_pre_screener__answer-yes-or-no-styles__container {
    width: 442px;
    gap: 24px;
  }

}
.ladder_ux_checkbox__rungs-checkbox-styles__wrapper {
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  padding: 17px 21.0px 17px 1px;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.ladder_ux_checkbox__rungs-checkbox-styles__wrapper.ladder_ux_checkbox__rungs-checkbox-styles__force-one-line-height {
  padding-top: 0;
  padding-bottom: 0;
  height: 64px;
}

.ladder_ux_checkbox__rungs-checkbox-styles__wrapper .ladder_ux_checkbox__rungs-checkbox-styles__label {
  flex-grow: 1;
}

.ladder_ux_checkbox__rungs-checkbox-styles__scrollable-app-wrapper {
  background-color: #FFFFFF;
}

.ladder_ux_checkbox__rungs-checkbox-styles__checkbox-checked {
  border-width: 2.0px;
  padding: 16px 20.0px 16px 0px;
}

.ladder_ux_checkbox__rungs-checkbox-styles__checkbox-error {
  border-width: 2.0px;
  border-color: #CB3A3A;
  padding: 16px 20.0px 16px 0px;
}

.ladder_ux_checkbox__rungs-checkbox-styles__borderless {
  border-width: 0px;
  padding: 8.0px 20.0px 8.0px 0;
}

.ladder_ux_checkbox__rungs-checkbox-styles__borderless .ladder_ux_checkbox__rungs-checkbox-styles__checkbox-wrapper {
  margin-left: 0;
}

.ladder_ux_checkbox__rungs-checkbox-styles__position-top {
  align-items: flex-start;
}

.ladder_ux_checkbox__rungs-checkbox-styles__max-width {
  max-width: 500px;
}

.ladder_ux_checkbox__rungs-checkbox-styles__checkbox-wrapper {
  min-width: 20.0px;
  width: 20.0px;
  border-width: 1px;
  justify-content: center;
  height: 20.0px;
  margin: 0 20.0px;
  align-items: center;
  border-style: solid;
  position: relative;
  display: flex;
}

.ladder_ux_checkbox__rungs-checkbox-styles__checkbox-wrapper.ladder_ux_checkbox__rungs-checkbox-styles__rounded {
  border-radius: 3px;
}

.ladder_ux_checkbox__rungs-checkbox-styles__checkmark {
  position: absolute;
  height: 75%;
  width: 75%;
  pointer-events: none;
}

.ladder_ux_checkbox__rungs-checkbox-styles__checkbox {
  height: 100%;
  width: 100%;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  cursor: pointer;
}

.ladder_ux_checkbox__rungs-checkbox-styles__disabled {
  cursor: default;
}
.ladder_agents_agent_assisted_app_views_app_status__questions-contact-us-box-styles__container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(min(75%, 796px));
  padding: 20px;
  border-radius: 8px;
  background-color: #F7F2EF;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_agent_assisted_app_views_app_status__questions-contact-us-box-styles__container {
    width: calc(min(100%, 796px));
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_agent_assisted_app_views_app_status__questions-contact-us-box-styles__container {
    width: calc(min(100%, 796px));
  }

}

.ladder_agents_agent_assisted_app_views_app_status__questions-contact-us-box-styles__icon-container {
  margin-right: 8px;
}

.ladder_agents_agent_assisted_app_views_app_status__questions-contact-us-box-styles__icon {
  height: auto;
  max-width: 24px;
}
.ladder_account_next_views_gift__gift-selected-styles__selected-gift-container {
  border: 1px solid gray;
  padding: 60px 30px;
  margin-top: 40px;
  display: flex;
}

.ladder_account_next_views_gift__gift-selected-styles__gift-selected-header {
  display: flex;
  justify-content: space-between;
}

.ladder_account_next_views_gift__gift-selected-styles__left-pad {
  padding-left: 15px;
}

.ladder_account_next_views_gift__gift-selected-styles__selected-gift-image-column {
  flex: 45%;
  margin-top: auto;
  margin-bottom: auto;
}

.ladder_account_next_views_gift__gift-selected-styles__selected-gift-text-column {
  flex: 55%;
}
.ladder_account__policy-entry-styles__root {
  display: flex;
  align-items: center;
  padding: 20px 0;
  text-align: left;
  width: 100%;
  justify-content: space-between;
}

@media (max-width: 499px) {

  
  
  .ladder_account__policy-entry-styles__root {
    padding: 0;
  }
  
  .ladder_account__policy-entry-styles__root>* {
    margin: 10.0px 0;
    width: 50%;
  }

}
@media (min-width: 1025px) {

  
  
  .ladder_about__press-inquiries-styles__container {
    margin-top: 84px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_about__press-inquiries-styles__container {
    margin-top: 76px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_about__press-inquiries-styles__container {
    margin-top: 76px;
  }

}

.ladder_about__press-inquiries-styles__\% {
  text-align: center;
}
.ladder_apply_question_description__question-description-styles__root {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  align-items: center;
  text-align: center;
}

.ladder_apply_question_description__question-description-styles__left-align {
  text-align: left;
  align-items: flex-start;
}

.ladder_apply_question_description__question-description-styles__quick-start {
  padding-top: 0;
}

@media (max-width: 499px) {

  
  
  .ladder_apply_question_description__question-description-styles__root {
    padding: 10.0px 0;
  }

}
.ladder_agents_agent_assisted_app_views_app_status__status-panel-body-styles__container {
  margin-top: 34px;
}
.ladder_financial_advisors_laddering__GetStarted-styles__root {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  background-color: #E7EEEF;
  padding: 60px 5vw 260px 5vw;
}

.ladder_financial_advisors_laddering__GetStarted-styles__signup-text {
  max-width: 650px;
  margin-left: 5vw;
}

.ladder_financial_advisors_laddering__GetStarted-styles__image {
  position: absolute;
  bottom: 0;
  right: 0;
}

@media (max-width: 499px) {

  
  
  .ladder_financial_advisors_laddering__GetStarted-styles__root {
    padding: 0;
    flex-direction: column;
  }
  
  .ladder_financial_advisors_laddering__GetStarted-styles__signup-text {
    margin: 30.0px 0 0 0;
    max-width: none;
  }
  
  .ladder_financial_advisors_laddering__GetStarted-styles__image {
    position: inherit;
    display: inherit;
    height: 400px;
    width: inherit;
    margin-left: auto;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_financial_advisors_laddering__GetStarted-styles__root {
    padding: 0;
    flex-direction: column;
  }
  
  .ladder_financial_advisors_laddering__GetStarted-styles__signup-text {
    margin: 30.0px 0 0 0;
    max-width: none;
  }
  
  .ladder_financial_advisors_laddering__GetStarted-styles__image {
    position: inherit;
    display: inherit;
    height: 400px;
    width: inherit;
    margin-left: auto;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_financial_advisors_laddering__GetStarted-styles__image {
    background-size: cover !important;
    background-position: center bottom;
  }
  
  .ladder_financial_advisors_laddering__GetStarted-styles__signup-text {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

}
.ladder_agents_dashboard_quote__quote-page-styles__quoter-rules {
  margin: 15.0px 0 30.0px 0;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard_quote__quote-page-styles__quote-page {
    padding: 0 20px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_dashboard_quote__quote-page-styles__quote-page {
    padding: 0 20px;
  }

}
.ladder_ny_form_quick_estimate__root-styles__single-page-group {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 499px) {

  
  
  .ladder_ny_form_quick_estimate__root-styles__single-page-group {
    width: 95%;
  }
  
  
  
  .box-fields .ladder_ny_form_quick_estimate__root-styles__single-page-group {
    width: 100%;
  }

}

.box-fields .ladder_ny_form_quick_estimate__root-styles__single-page-group {
  width: 60%;
}
.ladder_account__RungsPaymentMethod-styles__payment-content>* {
  padding-bottom: 20px;
}

.ladder_account__RungsPaymentMethod-styles__inline-div {
  display: flex;
}
.ladder_ux_animate__pop-in-fade-out-styles-short__enterActive {
  opacity: 0;
  transition: opacity 500ms ease-in;
  transition-delay: 800ms;
}

.ladder_ux_animate__pop-in-fade-out-styles-short__appearActive {
  opacity: 0;
  transition: opacity 500ms ease-in;
  transition-delay: 800ms;
}

.ladder_ux_animate__pop-in-fade-out-styles-short__enterDone {
  opacity: 0;
}

.ladder_ux_animate__pop-in-fade-out-styles-short__appearDone {
  opacity: 0;
}
.ladder_views_next__top-banner-styles__top-banner-shared {
  min-height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
}

.ladder_views_next__top-banner-styles__top-banner {
  text-align: center;
  background-color: #CFDCDE;
  justify-content: center;
  padding: 0 5.0px;
}

.ladder_views_next__top-banner-styles__top-banner-ck {
  flex-direction: column;
}

.ladder_views_next__top-banner-styles__rungs-top-banner-ck {
  flex-direction: column;
  height: auto;
  background-color: #C8E4CE;
}

.ladder_views_next__top-banner-styles__top-banner-sequoia {
  flex-direction: column;
  height: auto;
  background-color: #183944;
}

.ladder_views_next__top-banner-styles__partner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.ladder_views_next__top-banner-styles__welcome-text {
  margin: 0 10.0px;
}
.ladder_calculator_rebrand__page-two-styles__root {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #FFFFFF;
  align-items: center;
}

.ladder_calculator_rebrand__page-two-styles__content-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 100%;
  margin: 60px 0;
}

.ladder_calculator_rebrand__page-two-styles__step-two {
  color: #5D5D5D;
}

.ladder_calculator_rebrand__page-two-styles__title {
  margin: 20px 0 0 0;
}

.ladder_calculator_rebrand__page-two-styles__header {
  margin: 0 0 22.5px 0;
}

.ladder_calculator_rebrand__page-two-styles__button-wrapper {
  display: flex;
  max-width: 80%;
  justify-content: space-between;
  margin: 30.0px 0 0 0;
}

.ladder_calculator_rebrand__page-two-styles__button-next {
  margin-left: 10.0px;
  flex-grow: 2;
}

@media (max-width: 499px) {

  
  
  .ladder_calculator_rebrand__page-two-styles__title {
    font-size: 20px;
  }
  
  .ladder_calculator_rebrand__page-two-styles__content-wrapper {
    width: 90%;
    margin: 30.0px 0 20px 0;
  }
  
  .ladder_calculator_rebrand__page-two-styles__button-wrapper {
    max-width: 100%;
    flex-direction: column-reverse;
  }
  
  .ladder_calculator_rebrand__page-two-styles__button-next {
    margin: 20px 0;
  }

}
.ladder_markdown_components__bullet-list-styles__root {
  list-style-type: disc;
  color: #4C4C4E;
  padding-left: 32px;
}
.ladder_financial_advisors_laddering__easy-for-them-styles__root {
  display: flex;
  width: 100%;
  background-color: #E7EEEF;
  jusitfy-content: center;
  align-items: center;
  padding: 130.0px 0;
}

.ladder_financial_advisors_laddering__easy-for-them-styles__container {
  display: flex;
  flex-direction: row;
  width: 80%;
  max-width: 1400px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
}

.ladder_financial_advisors_laddering__easy-for-them-styles__action-block {
  flex-basis: 60%;
}

.ladder_financial_advisors_laddering__easy-for-them-styles__image-container {
  max-width: 30%;
  flex-basis: 50%;
}

@media (max-width: 499px) {

  
  
  .ladder_financial_advisors_laddering__easy-for-them-styles__root {
    padding: 60px 0;
  }
  
  .ladder_financial_advisors_laddering__easy-for-them-styles__action-block {
    flex-basis: 100%;
  }
  
  .ladder_financial_advisors_laddering__easy-for-them-styles__image-container {
    display: none;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_financial_advisors_laddering__easy-for-them-styles__root {
    padding: 60px 0;
  }
  
  .ladder_financial_advisors_laddering__easy-for-them-styles__action-block {
    flex-basis: 100%;
  }
  
  .ladder_financial_advisors_laddering__easy-for-them-styles__image-container {
    display: none;
  }

}
.ladder_app_review__field-root-styles__root {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  max-width: 600px;
  width: 100%;
  padding: 10.0px;
  align-items: flex-start;
}

.ladder_app_review__field-root-styles__root-edit {
  cursor: pointer;
}

.ladder_app_review__field-root-styles__root-edit:hover {
  background-color: #F4F4F4;
}

.ladder_app_review__field-root-styles__expandable-review:first-of-type {
  margin-top: 0;
}

.ladder_app_review__field-root-styles__disabled {
  pointer-events: none;
}

@media (min-width: 1025px) {

  
  
  .ladder_app_review__field-root-styles__expandable-review:first-of-type {
    margin-top: 5px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_app_review__field-root-styles__root {
    padding: 5.0px 8px;
  }

}
@media (min-width: 1025px) {

  
  
  .ladder_about__maybe-columns-styles__\% {
    display: flex;
  }
  
  .ladder_about__maybe-columns-styles__\%>*:first-child {
    flex: 0 0 414px;
  }

}
.ladder_prudential_views__prudential-diversion-ui-styles__wrapper {
  position: fixed;
  top: 100px;
  left: 0;
  width: 100%;
  background-color: #F7F2EF;
  display: flex;
  justify-content: center;
}

.ladder_prudential_views__prudential-diversion-ui-styles__wrapper.ladder_prudential_views__prudential-diversion-ui-styles__themed {
  background-color: white;
}

.ladder_prudential_views__prudential-diversion-ui-styles__wrapper.ladder_prudential_views__prudential-diversion-ui-styles__relative {
  position: relative;
  top: 0;
}

@media (min-width: 1025px) {

  
  
  .ladder_prudential_views__prudential-diversion-ui-styles__wrapper {
    height: calc(100% - 100px);
    top: 100px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_prudential_views__prudential-diversion-ui-styles__wrapper {
    height: calc(100% - 60px);
    top: 60px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_prudential_views__prudential-diversion-ui-styles__wrapper {
    height: calc(100% - 60px);
    top: 60px;
  }

}

.ladder_prudential_views__prudential-diversion-ui-styles__content-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  overflow-y: scroll;
}

.ladder_prudential_views__prudential-diversion-ui-styles__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: fit-content;
  max-width: 600px;
}

@media (max-width: 499px) {

  
  
  .ladder_prudential_views__prudential-diversion-ui-styles__content {
    padding: 0 30px;
  }

}

.ladder_prudential_views__prudential-diversion-ui-styles__header {
  margin: 60px 0 30px 0;
}

@media (max-width: 499px) {

  
  
  .ladder_prudential_views__prudential-diversion-ui-styles__header {
    margin: 20px 0;
  }

}

.ladder_prudential_views__prudential-diversion-ui-styles__apply {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 35px;
  background-color: #FFFFFF;
  border-radius: 10px;
  margin: 25px 0;
}

.ladder_prudential_views__prudential-diversion-ui-styles__logo {
  height: 50px;
  align-self: flex-start;
}

.ladder_prudential_views__prudential-diversion-ui-styles__apply-button {
  border-radius: 10px;
  margin: 25px 0 10px 0;
}

@media (max-width: 499px) {

  
  
  .ladder_prudential_views__prudential-diversion-ui-styles__apply {
    padding: 30px;
  }
  
  .ladder_prudential_views__prudential-diversion-ui-styles__logo {
    height: 30px;
  }

}

.ladder_prudential_views__prudential-diversion-ui-styles__why {
  border: solid 1px;
  border-color: #749475;
  border-radius: 10px;
  padding: 25px 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 30px;
}

.ladder_prudential_views__prudential-diversion-ui-styles__why-bullet {
  display: flex;
  gap: 15px;
}

.ladder_prudential_views__prudential-diversion-ui-styles__checkmark {
  min-height: 30px;
  min-width: 30px;
  height: 30px;
  width: 30px;
}

@media (max-width: 499px) {

  
  
  .ladder_prudential_views__prudential-diversion-ui-styles__why {
    padding: 25px;
    gap: 20px;
  }
  
  .ladder_prudential_views__prudential-diversion-ui-styles__checkmark {
    min-height: 25px;
    min-width: 25px;
    height: 25px;
    width: 25px;
  }

}

.ladder_prudential_views__prudential-diversion-ui-styles__legal {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin: 50px 0;
}

@media (max-width: 499px) {

  
  
  .ladder_prudential_views__prudential-diversion-ui-styles__legal {
    margin: 30px 0;
  }

}

.ladder_prudential_views__prudential-diversion-ui-styles__themed .ladder_prudential_views__prudential-diversion-ui-styles__wrapper {
  background-color: #FFFFFF;
}

.ladder_prudential_views__prudential-diversion-ui-styles__themed .ladder_prudential_views__prudential-diversion-ui-styles__apply {
  background-color: none;
  border: solid 1px;
}

.ladder_prudential_views__prudential-diversion-ui-styles__themed .ladder_prudential_views__prudential-diversion-ui-styles__why {
  border: none;
  background-color: #F1F1F1;
}
.ladder_views_decision_common__accept-buttons-styles__root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.ladder_views_decision_common__accept-buttons-styles__button-wrapper {
  max-width: 352px;
  padding: 22.5px 0 20px;
}

.ladder_views_decision_common__accept-buttons-styles__reduced-padding {
  padding: 10.0px 0 20px;
}

@media (max-width: 499px) {

  
  
  .ladder_views_decision_common__accept-buttons-styles__button-wrapper {
    max-width: unset;
    width: 100%;
    padding: 30.0px 0 22.5px;
  }

}

.ladder_views_decision_common__accept-buttons-styles__full-width .ladder_views_decision_common__accept-buttons-styles__button-wrapper {
  max-width: unset;
  width: 100%;
  padding: 30.0px 0 22.5px;
}
.ladder_ny_form_blood_pressure__root-styles__root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.ladder_ny_form_blood_pressure__root-styles__slash-container {
  box-sizing: border-box;
  flex-basis: 10%;
  text-align: center;
}
.ladder_account_next_views_secondary_addressee_edit__half-width-styles__container {
  display: flex;
}

.ladder_account_next_views_secondary_addressee_edit__half-width-styles__half {
  width: 50%;
}

.ladder_account_next_views_secondary_addressee_edit__half-width-styles__half:first-child {
  padding-right: 6px;
}

.ladder_account_next_views_secondary_addressee_edit__half-width-styles__half:last-child {
  padding-left: 6px;
}
.ladder_form_group__root-styles__option {
  display: flex;
  width: 100%;
  padding: 10.0px 0;
}
.ladder_financial_advisors_home__satisfied-clients-button-styles__button {
  margin-top: 85px;
  width: 100%;
}

@media (min-width: 1025px) {

  
  
  .ladder_financial_advisors_home__satisfied-clients-button-styles__button {
    width: 258px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_financial_advisors_home__satisfied-clients-button-styles__button {
    width: 258px;
  }

}
.ladder_guide_common__sticky-wrapper-styles__wrapper {
  width: 100%;
  position: relative;
}
.ladder_advisor_only_standalone_quoter__input-column-styles__root {
  display: flex;
  flex-direction: column;
  text-align: left;
  box-sizing: border-box;
  width: 100%;
  padding: 5.0px 0;
}

.ladder_advisor_only_standalone_quoter__input-column-styles__child {
  padding: 15.0px 0;
}
@media (max-width: 499px) {

  
  
  .ladder_guide_life_insurance_guide__Root-styles__mobile-margin-top {
    margin-top: 60px;
  }

}
.ladder_ux_logos__powered-by-logo-styles__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ladder_ux_logos__powered-by-logo-styles__wrapper .ladder_ux_logos__powered-by-logo-styles__powered-by {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}

.ladder_ux_logos__powered-by-logo-styles__wrapper .ladder_ux_logos__powered-by-logo-styles__no-margin {
  margin-top: 0;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_logos__powered-by-logo-styles__powered-by {
    align-items: flex-end;
  }

}
.ladder_widget_ui_v2__cost-per-month-styles__root {
  align-self: center;
}

.tile-a .ladder_widget_ui_v2__cost-per-month-styles__root {
  margin-top: 10.0px;
}

.tile-b .ladder_widget_ui_v2__cost-per-month-styles__root {
  margin-top: 10.0px;
}

.portrait .ladder_widget_ui_v2__cost-per-month-styles__root {
  flex-grow: 93;
}

.landscape .ladder_widget_ui_v2__cost-per-month-styles__root {
  flex-grow: 1;
}

.ladder_widget_ui_v2__cost-per-month-styles__premium {
  font-size: 64px;
  line-height: 72px;
  letter-spacing: -0.6px;
}

.tile-a .ladder_widget_ui_v2__cost-per-month-styles__premium {
  font-size: 40px;
  line-height: 60px;
  letter-spacing: -0.3px;
}
.ladder_guide_common__colored-table-styles__root {
  width: 100%;
  table-layout: fixed;
  text-align: left;
}

.ladder_guide_common__colored-table-styles__rows tr:nth-child(odd) {
  background-color: #EFEAE1;
}

.ladder_guide_common__colored-table-styles__rows tr:nth-child(even) {
  background-color: #F7F2EF;
}
.ladder_account_next_views_referrals__Root-styles__\% {
  margin-bottom: 30.0px;
}
.ladder_account_next_cards_progress_bar__Root-styles__\% {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.ladder_views_decision_hurdle__billing-styles__root {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 100%;
  padding-bottom: 80px;
}

@media (max-width: 499px) {

  
  
  .ladder_views_decision_hurdle__billing-styles__root {
    padding: 0px 10px 40px;
  }

}
@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard_quote__quoter-rules-styles__quoter-rules {
    max-width: 335px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_dashboard_quote__quoter-rules-styles__quoter-rules {
    max-width: 335px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_agents_dashboard_quote__quoter-rules-styles__quoter-rules {
    max-width: 1100px;
  }

}
@media (max-width: 499px) {

  
  
  .ladder_account_next_nav_nav_item__user-coverage-summary\*-styles__\% {
    display: none;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_account_next_nav_nav_item__user-coverage-summary\*-styles__\% {
    height: 102px;
    display: flex;
    flex-direction: column;
    padding-left: 32px;
    cursor: pointer;
    justify-content: center;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account_next_nav_nav_item__user-coverage-summary\*-styles__\% {
    height: 102px;
    display: flex;
    flex-direction: column;
    padding-left: 32px;
    cursor: pointer;
    justify-content: center;
  }

}
.ladder_work_home__great-for-employees-section-styles__root {
  background: #F7F2EF;
  width: 100%;
  margin-top: -1px;
}

.ladder_work_home__great-for-employees-section-styles__text-bottom-wrapper {
  padding: 0 20px;
}

@media (max-width: 499px) {

  
  
  .ladder_work_home__great-for-employees-section-styles__button-wrapper {
    width: 90%;
  }

}
.ladder_claims_naic_redirect__root-styles__\% {
  width: 100%;
}
.ladder_onboarding_shared_components__normal-panel-2-styles__\% {
  max-width: 590.0px;
}

.ladder_onboarding_shared_components__normal-panel-2-styles__body {
  margin-top: 12.0px;
}

.ladder_onboarding_shared_components__normal-panel-2-styles__buttons {
  margin-top: 50.0px;
}

.ladder_onboarding_shared_components__normal-panel-2-styles__button {
  margin-top: 20px;
}

@media (max-width: 499px) {

  
  
  .ladder_onboarding_shared_components__normal-panel-2-styles__button:last-child {
    margin-top: 15.0px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_onboarding_shared_components__normal-panel-2-styles__button:last-child {
    margin-left: 20px;
  }
  
  .ladder_onboarding_shared_components__normal-panel-2-styles__buttons {
    display: flex;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_onboarding_shared_components__normal-panel-2-styles__button:last-child {
    margin-left: 20px;
  }
  
  .ladder_onboarding_shared_components__normal-panel-2-styles__buttons {
    display: flex;
  }

}
.ladder_nearly_perfect_quote_exchange__exchange-modal-ui-styles__infinite-spacer {
  flex-grow: 1;
}

.ladder_nearly_perfect_quote_exchange__exchange-modal-ui-styles__spinner {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 282px;
}
.ladder_ny_form_review__multiselect-styles__root {
  display: flex;
  align-items: center;
}

.ladder_ny_form_review__multiselect-styles__multiselect-root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ladder_ny_form_review__multiselect-styles__option-root {
  display: flex;
  text-align: left;
  padding: 5.0px 0;
}

.ladder_ny_form_review__multiselect-styles__option {
  display: flex;
  cursor: pointer;
}

.ladder_ny_form_review__multiselect-styles__checkbox-container {
  padding-top: 3px;
  padding-right: 10.0px;
}

.ladder_ny_form_review__multiselect-styles__info-expander-container {
  padding-top: 3px;
  padding-left: 10.0px;
}

.ladder_ny_form_review__multiselect-styles__display-lines {
  display: flex;
  flex-direction: column;
}

.ladder_ny_form_review__multiselect-styles__hidden {
  visibility: hidden;
}

@media (max-width: 499px) {

  
  
  .ladder_ny_form_review__multiselect-styles__checkbox-container {
    padding-top: 0;
  }
  
  .ladder_ny_form_review__multiselect-styles__info-expander-container {
    padding-top: 0;
  }

}
.ladder_account_next_modals__PaymentRetryLowBalanceWarning-styles__buttons-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.ladder_ux_policy_card__policy-card-styles__pad-top {
  padding-top: 4.0px;
}

.ladder_ux_policy_card__policy-card-styles__title {
  position: relative;
  display: flex;
  align-items: center;
}
.ladder_agents_agent_assisted_app_views_app_status__progress-panel-styles__container {
  display: flex;
  flex-direction: column;
  max-width: 482px;
  gap: 20px;
  justify-content: space-between;
  height: 100%;
}
.ladder_trustage_upper_funnel_routing__trustpilot-panel-styles__wrapper {
  display: flex;
  background-color: #E7EEEF;
  border: solid 2px;
  border-color: #464F50;
  border-radius: 10.0px;
  padding: 30px 40px;
}

.ladder_trustage_upper_funnel_routing__trustpilot-panel-styles__themed-wrapper {
  background-color: #F1F1F1;
  border-width: 0;
}

.ladder_trustage_upper_funnel_routing__trustpilot-panel-styles__logo-and-rating {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.ladder_trustage_upper_funnel_routing__trustpilot-panel-styles__logo {
  width: fit-content;
  margin-bottom: 20px;
}

.ladder_trustage_upper_funnel_routing__trustpilot-panel-styles__trustpilot-stars {
  display: flex;
  margin-bottom: 10.0px;
}

.ladder_trustage_upper_funnel_routing__trustpilot-panel-styles__trustpilot-stars>* {
  height: 18px;
  margin-right: 10.0px;
}

.ladder_trustage_upper_funnel_routing__trustpilot-panel-styles__button-wrapper {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 499px) {

  
  
  .ladder_trustage_upper_funnel_routing__trustpilot-panel-styles__wrapper {
    flex-direction: column;
    padding: 30.0px 15.0px;
  }
  
  .ladder_trustage_upper_funnel_routing__trustpilot-panel-styles__logo-and-rating {
    margin-bottom: 30.0px;
  }
  
  .ladder_trustage_upper_funnel_routing__trustpilot-panel-styles__logo {
    margin-bottom: 30.0px;
  }

}
.ladder_onboarding_shared_components__normal-panel-1-styles__body {
  margin-top: 15.0px;
}

.ladder_onboarding_shared_components__normal-panel-1-styles__image {
  display: none;
}

.ladder_onboarding_shared_components__normal-panel-1-styles__large-image {
  display: none;
}

@media (min-width: 1025px) {

  
  
  .ladder_onboarding_shared_components__normal-panel-1-styles__\% {
    width: 500px;
  }
  
  .ladder_onboarding_shared_components__normal-panel-1-styles__body {
    margin-top: 30.0px;
  }
  
  .ladder_onboarding_shared_components__normal-panel-1-styles__image {
    display: block;
    width: 336px;
    margin-top: 30.0px;
    margin-left: 85.0px;
  }
  
  .ladder_onboarding_shared_components__normal-panel-1-styles__image img {
    max-width: 100%;
  }
  
  .ladder_onboarding_shared_components__normal-panel-1-styles__large-image {
    display: block;
    margin-top: -175px;
    margin-left: -80px;
  }
  
  .ladder_onboarding_shared_components__normal-panel-1-styles__large-image img {
    max-width: 100%;
  }

}
.ladder_account_next_views_beneficiaries_edit__half-width-styles__container {
  display: flex;
}

.ladder_account_next_views_beneficiaries_edit__half-width-styles__half {
  width: 50%;
}

.ladder_account_next_views_beneficiaries_edit__half-width-styles__half:first-child {
  padding-right: 6px;
}

.ladder_account_next_views_beneficiaries_edit__half-width-styles__half:last-child {
  padding-left: 6px;
}
.ladder_quick_start__quotes-styles__root {
  display: flex;
  width: 100%;
  background-color: #FFFFFF;
  justify-content: center;
}

.ladder_quick_start__quotes-styles__container {
  display: flex;
  flex-direction: row;
  width: 90%;
  max-width: 1400px;
  overflow: hidden;
  position: relative;
  margin: 60px 0;
  background-color: #EFEAE1;
}

.ladder_quick_start__quotes-styles__image-container {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
}

.ladder_quick_start__quotes-styles__image {
  max-width: 30%;
  align-self: flex-end;
  margin-right: 20px;
}

.ladder_quick_start__quotes-styles__action-box {
  width: 50%;
  margin: 60px 60px;
}

.ladder_quick_start__quotes-styles__text {
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  margin: 20px 0 30.0px 0;
}

@media (max-width: 499px) {

  
  
  .ladder_quick_start__quotes-styles__image {
    max-width: 75%;
    margin-right: 0;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_quick_start__quotes-styles__image {
    max-width: 50%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_quick_start__quotes-styles__container {
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
  
  .ladder_quick_start__quotes-styles__action-box {
    margin: 30.0px 0;
    width: 90%;
  }
  
  .ladder_quick_start__quotes-styles__image-container {
    position: unset;
    bottom: unset;
    right: unset;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_quick_start__quotes-styles__container {
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
  
  .ladder_quick_start__quotes-styles__action-box {
    margin: 30.0px 0;
    width: 90%;
  }
  
  .ladder_quick_start__quotes-styles__image-container {
    position: unset;
    bottom: unset;
    right: unset;
  }

}
.ladder_adjust_offer__quote-entry-styles__root {
  box-sizing: border-box;
}

.ladder_adjust_offer__quote-entry-styles__inner {
  width: 100%;
  box-sizing: border-box;
}

.ladder_adjust_offer__quote-entry-styles__inner-container {
  display: flex;
  align-items: center;
}

.ladder_adjust_offer__quote-entry-styles__premium-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ladder_adjust_offer__quote-entry-styles__asterisk-container {
  padding: 20px 0;
  text-align: center;
}

@media (min-width: 1025px) {

  
  
  .ladder_adjust_offer__quote-entry-styles__root {
    width: 20%;
    max-width: 200px;
    padding: 10.0px;
  }
  
  .ladder_adjust_offer__quote-entry-styles__inner {
    padding: 20px 0;
    border: 1px solid #707070;
    cursor: pointer;
  }
  
  .ladder_adjust_offer__quote-entry-styles__inner-container {
    flex-direction: column;
  }
  
  .ladder_adjust_offer__quote-entry-styles__inner-border {
    border: 2px solid #FFFFFF;
  }
  
  .ladder_adjust_offer__quote-entry-styles__term-wrapper {
    padding-bottom: 10.0px;
  }
  
  .ladder_adjust_offer__quote-entry-styles__premium-wrapper {
    padding: 5.0px 0;
  }
  
  .ladder_adjust_offer__quote-entry-styles__selected {
    border: 3px solid #D1A98F;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_adjust_offer__quote-entry-styles__root {
    width: 20%;
    max-width: 200px;
    padding: 10.0px;
  }
  
  .ladder_adjust_offer__quote-entry-styles__inner {
    padding: 20px 0;
    border: 1px solid #707070;
    cursor: pointer;
  }
  
  .ladder_adjust_offer__quote-entry-styles__inner-container {
    flex-direction: column;
  }
  
  .ladder_adjust_offer__quote-entry-styles__inner-border {
    border: 2px solid #FFFFFF;
  }
  
  .ladder_adjust_offer__quote-entry-styles__term-wrapper {
    padding-bottom: 10.0px;
  }
  
  .ladder_adjust_offer__quote-entry-styles__premium-wrapper {
    padding: 5.0px 0;
  }
  
  .ladder_adjust_offer__quote-entry-styles__selected {
    border: 3px solid #D1A98F;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_adjust_offer__quote-entry-styles__root {
    width: 100%;
  }
  
  .ladder_adjust_offer__quote-entry-styles__inner {
    border-top: 1px solid #FFFFFF;
    border-bottom: 1px solid #D0D0D0;
  }
  
  .ladder_adjust_offer__quote-entry-styles__quote-with-price-inner {
    padding: 0 20px 0 30.0px;
  }
  
  .ladder_adjust_offer__quote-entry-styles__quote-without-price-inner {
    padding: 15.0px 0;
    justify-content: center !important;
  }
  
  .ladder_adjust_offer__quote-entry-styles__inner-container {
    justify-content: space-between;
  }
  
  .ladder_adjust_offer__quote-entry-styles__inner-border {
    border-top: 2px solid #FFFFFF;
    border-right: 3px solid #FFFFFF;
    border-bottom: 2px solid #FFFFFF;
    border-left: 3px solid #FFFFFF;
  }
  
  .ladder_adjust_offer__quote-entry-styles__term-wrapper {
    padding: 10.0px 20px;
  }
  
  .ladder_adjust_offer__quote-entry-styles__premium-wrapper {
    padding: 10.0px 20px;
  }
  
  .ladder_adjust_offer__quote-entry-styles__border-top {
    border-top: 1px solid #D0D0D0;
  }
  
  .ladder_adjust_offer__quote-entry-styles__selected {
    border: 3px solid #D1A98F;
  }

}
.ladder_app_review__gray-bar-styles__root {
  border-top: 1px #D0D0D0 solid;
  width: 98%;
  margin: 20px 0 20px 5.0px;
  height: 1px;
}

@media (max-width: 499px) {

  
  
  .ladder_app_review__gray-bar-styles__root {
    width: 100%;
    margin: 0 0 0 5.0px;
  }

}
.ladder_financial_advisors_home__allianz-fine-print-styles__\% {
  margin-top: 30px;
}
.ladder_agents_agent_assisted_app_views_review__review-styles__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  padding: 10.0px;
}

.ladder_agents_agent_assisted_app_views_review__review-styles__root-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.ladder_agents_agent_assisted_app_views_review__review-styles__highlighted {
  border-left: 8.0px solid #D1A98F;
  background-color: #F7F2EF;
  width: 100%;
  padding-left: 30.0px;
  padding-right: 30.0px;
}

.ladder_agents_agent_assisted_app_views_review__review-styles__text {
  width: 100%;
  margin: 5.0px 0;
}

.ladder_agents_agent_assisted_app_views_review__review-styles__tooltip-container {
  display: flex;
  flex-direction: row;
  margin: 30.0px;
}

.ladder_agents_agent_assisted_app_views_review__review-styles__expandable-review-container {
  width: 100%;
  max-width: 800px;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_agent_assisted_app_views_review__review-styles__container {
    width: 90%;
  }
  
  .ladder_agents_agent_assisted_app_views_review__review-styles__full-width {
    width: 100%;
    padding: 8px 0;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_agent_assisted_app_views_review__review-styles__container {
    width: 90%;
  }
  
  .ladder_agents_agent_assisted_app_views_review__review-styles__full-width {
    width: 100%;
    padding: 8px 0;
  }

}
.ladder_account_next_views_beneficiaries_view__ViewBeneficiaries-styles__buttons {
  display: flex;
}

.ladder_account_next_views_beneficiaries_view__ViewBeneficiaries-styles__add-margins-to-children {
  display: flex;
  flex-direction: column;
}

.ladder_account_next_views_beneficiaries_view__ViewBeneficiaries-styles__add-margins-to-children>* {
  margin: 15.0px 10.0px;
}

.ladder_account_next_views_beneficiaries_view__ViewBeneficiaries-styles__icon {
  width: 20.0px;
  height: 20.0px;
  padding: 0 0 0 5.0px;
}

@media (min-width: 1025px) {

  
  
  .ladder_account_next_views_beneficiaries_view__ViewBeneficiaries-styles__buttons>*:first-child {
    margin-right: 22.5px;
  }
  
  .ladder_account_next_views_beneficiaries_view__ViewBeneficiaries-styles__buttons {
    margin-top: 40px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account_next_views_beneficiaries_view__ViewBeneficiaries-styles__buttons>*:first-child {
    margin-right: 22.5px;
  }
  
  .ladder_account_next_views_beneficiaries_view__ViewBeneficiaries-styles__buttons {
    margin-top: 40px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_account_next_views_beneficiaries_view__ViewBeneficiaries-styles__buttons {
    flex-direction: column;
    align-items: center;
    margin-top: 22.5px;
  }
  
  .ladder_account_next_views_beneficiaries_view__ViewBeneficiaries-styles__buttons>*:first-child {
    margin-bottom: 20px;
  }

}
.ladder_account_next_views_profile_edit__edit-personal-details-fields-display-styles__field {
  margin: 10px 0;
}

@media (min-width: 1025px) {

  
  
  .ladder_account_next_views_profile_edit__edit-personal-details-fields-display-styles__fields {
    display: flex;
    flex-direction: row;
  }
  
  .ladder_account_next_views_profile_edit__edit-personal-details-fields-display-styles__maybe-column {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  
  .ladder_account_next_views_profile_edit__edit-personal-details-fields-display-styles__maybe-column:first-of-type {
    padding-right: 15px;
  }
  
  .ladder_account_next_views_profile_edit__edit-personal-details-fields-display-styles__maybe-column:last-of-type {
    padding-left: 15px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_account_next_views_profile_edit__edit-personal-details-fields-display-styles__maybe-column {
    display: contents;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account_next_views_profile_edit__edit-personal-details-fields-display-styles__maybe-column {
    display: contents;
  }

}
.ladder_views_payment__bank-form-styles__root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 720px;
}

.ladder_views_payment__bank-form-styles__redesign-root {
  width: 100%;
  border-width: 2px;
  max-width: 550px;
  border-color: #D0D0D0;
  align-items: flex-start;
  flex-direction: column;
  border-style: solid;
  margin-top: 30.0px;
  display: flex;
}

.ladder_views_payment__bank-form-styles__no-border-box {
  border-color: unset;
  border-width: unset;
  border-style: unset;
}

.ladder_views_payment__bank-form-styles__radio-root {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 20px;
}

.ladder_views_payment__bank-form-styles__auth-ach-form {
  margin-left: unset;
}

.ladder_views_payment__bank-form-styles__form-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.ladder_views_payment__bank-form-styles__icon {
  width: 100%;
  height: 100%;
}

.ladder_views_payment__bank-form-styles__icon-card {
  height: 40.0px;
}

.ladder_views_payment__bank-form-styles__credit-card-svg {
  width: 52px;
  height: 52px;
}

.ladder_views_payment__bank-form-styles__different-bank {
  color: black;
  letter-spacing: 1.6px;
}

.ladder_views_payment__bank-form-styles__icon-gpay {
  height: 24.0px;
}

.ladder_views_payment__bank-form-styles__button-wrapper {
  box-sizing: border-box;
  width: calc(100% / 3);
  padding: 10.0px;
}

.ladder_views_payment__bank-form-styles__apple-pay-button {
  display: block;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-style: white-outline;
  height: 48.0px;
  cursor: pointer;
  width: 100%;
}

.ladder_views_payment__bank-form-styles__card-label {
  display: flex;
  align-items: center;
}

.ladder_views_payment__bank-form-styles__update-payment-info-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100px;
  margin: 20px 0;
}

.ladder_views_payment__bank-form-styles__update-payment-info-text {
  display: flex;
  align-items: center;
}

.ladder_views_payment__bank-form-styles__bank-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
}

.ladder_views_payment__bank-form-styles__apple-pay-google-pay-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-height: 72px;
}

.ladder_views_payment__bank-form-styles__apple-pay-google-pay-logo {
  width: 60px;
  margin-right: 12px;
}

.ladder_views_payment__bank-form-styles__bank-name-text {
  margin: 0 10.0px;
}

.ladder_views_payment__bank-form-styles__credit-card-text {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.ladder_views_payment__bank-form-styles__credit-card-number {
  margin: 10.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_views_payment__bank-form-styles__redesign-root {
    max-height: unset;
  }
  
  .ladder_views_payment__bank-form-styles__button-wrapper {
    width: 50%;
  }
  
  .ladder_views_payment__bank-form-styles__credit-card {
    font-size: 11px;
    text-indent: 0.25em;
  }

}
.ladder_widget_ui_v1__form-group-styles__root {
  display: flex;
  flex-direction: column;
  width: 205px;
}
.ladder_agents_dashboard_contacts__contact-card-styles__contact-status-container {
  width: 35%;
}

@media (min-width: 1025px) {

  
  
  .ladder_agents_dashboard_contacts__contact-card-styles__contact-card-shadow-container {
    width: 100%;
    justify-content: center;
    margin: 0.5em 0em;
    align-items: center;
    padding: 1.5em;
    flex-direction: column;
    moz-border-radius: 30px;
    box-shadow: 0px 5px 10px rgba(51,46,84,.05);
    background-color: #FFFFFF;
    border-radius: 28px;
    display: flex;
    webkit-border-radius: 30px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard_contacts__contact-card-styles__contact-card-shadow-container {
    width: 100%;
    justify-content: center;
    margin: 0.5em 0em;
    align-items: center;
    padding: 1em 1.5em;
    flex-direction: column;
    moz-border-radius: 30px;
    box-shadow: 0px 5px 10px rgba(51,46,84,.05);
    background-color: #FFFFFF;
    border-radius: 28px;
    display: flex;
    webkit-border-radius: 30px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_dashboard_contacts__contact-card-styles__contact-card-shadow-container {
    width: 100%;
    justify-content: center;
    margin: 0.5em 0em;
    align-items: center;
    padding: 1em 1.5em;
    flex-direction: column;
    moz-border-radius: 30px;
    box-shadow: 0px 5px 10px rgba(51,46,84,.05);
    background-color: #FFFFFF;
    border-radius: 28px;
    display: flex;
    webkit-border-radius: 30px;
  }

}

.ladder_agents_dashboard_contacts__contact-card-styles__contact-card-shadow-container-expanded {
  height: 100%;
  gap: 15px;
  justify-content: space-between;
}

.ladder_agents_dashboard_contacts__contact-card-styles__contact-card {
  display: flex;
  flex-direction: row;
  height: auto;
  gap: 2em;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.ladder_agents_dashboard_contacts__contact-card-styles__contact-status-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  text-align: end;
}

.ladder_agents_dashboard_contacts__contact-card-styles__contact-status-text {
  display: flex;
  justify-content: center;
}

.ladder_agents_dashboard_contacts__contact-card-styles__pointer-cursor {
  cursor: pointer;
}
.ladder_requestion_post_sign_questions__post-sign-drq-questions-styles__root {
  display: flex;
  justify-content: center;
}

.ladder_requestion_post_sign_questions__post-sign-drq-questions-styles__questions {
  width: 55%;
  max-width: 700px;
}

.ladder_requestion_post_sign_questions__post-sign-drq-questions-styles__button-row {
  display: flex;
  justify-content: center;
}

.ladder_requestion_post_sign_questions__post-sign-drq-questions-styles__button-container {
  display: flex;
  gap: 10px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_requestion_post_sign_questions__post-sign-drq-questions-styles__questions {
    width: 75%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_requestion_post_sign_questions__post-sign-drq-questions-styles__questions {
    width: 90%;
  }
  
  .ladder_requestion_post_sign_questions__post-sign-drq-questions-styles__button-container {
    flex-direction: column-reverse;
    width: 100%;
  }

}
.ladder_onboarding_shared_components__spouse-invite-success-styles__\% {
  width: 175px;
  top: calc(50vh - 88px);
  justify-content: center;
  height: 175px;
  align-items: center;
  flex-direction: column;
  position: fixed;
  z-index: 100;
  background-color: #475665;
  border-radius: 15px;
  display: flex;
  left: calc(50vw - 88px);
}
.ladder_megaphone_landing__park-panel-styles__root {
  width: 90%;
  max-width: 940px;
  height: 400px;
  margin-bottom: 30.0px;
  background: url(../img/park.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 100%;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_megaphone_landing__park-panel-styles__root {
    height: 300px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_megaphone_landing__park-panel-styles__root {
    height: 200px;
  }

}
.ladder_ux_panels__homepage-animation-video-panel-styles__root {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 750px;
}

.ladder_ux_panels__homepage-animation-video-panel-styles__panel {
  display: flex;
  justify-content: space-evenly;
  width: 90%;
  max-width: 1000px;
}

.ladder_ux_panels__homepage-animation-video-panel-styles__video-wrapper {
  display: flex;
  position: relative;
  width: 245px;
  height: 465px;
}

.ladder_ux_panels__homepage-animation-video-panel-styles__video-frame {
  position: absolute;
  top: 3px;
  left: 5px;
  width: 239px;
  height: 467px;
  z-index: 2;
}

.ladder_ux_panels__homepage-animation-video-panel-styles__video {
  display: flex;
  position: absolute;
  width: 250px;
  height: 472px;
  z-index: 1;
}

.ladder_ux_panels__homepage-animation-video-panel-styles__right-subpanel {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: 32px 0 0 64px;
}

.ladder_ux_panels__homepage-animation-video-panel-styles__tell-us-about-yourself {

}

.ladder_ux_panels__homepage-animation-video-panel-styles__text {
  display: flex;
  width: 100%;
  white-space: pre;
  margin: 32px 0 48px 0;
}

.ladder_ux_panels__homepage-animation-video-panel-styles__top-bar-wrapper {
  display: flex;
  width: 100%;
  height: 0.3vw;
  margin: 32px 0;
  justify-content: space-between;
}

.ladder_ux_panels__homepage-animation-video-panel-styles__gray-bar-wrapper {
  height: 100%;
  margin: 16px 0.5% 0 0.5%;
  flex-direction: row;
  position: relative;
  z-index: 1;
  flex-grow: 1;
  cursor: pointer;
  background-color: #F1F1F1;
  display: flex;
}

.ladder_ux_panels__homepage-animation-video-panel-styles__gray-bar-wrapper:first-child {
  margin-right: 0.5%;
}

.ladder_ux_panels__homepage-animation-video-panel-styles__gray-bar-wrapper:last-child {
  margin-left: 0.5%;
}

.ladder_ux_panels__homepage-animation-video-panel-styles__current-bar {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  width: 0%;
  background-color: #1A1A1A;
}

.ladder_ux_panels__homepage-animation-video-panel-styles__current-bar.ladder_ux_panels__homepage-animation-video-panel-styles__dark-green {
  background-color: #91AF92;
}

.ladder_ux_panels__homepage-animation-video-panel-styles__current-bar.ladder_ux_panels__homepage-animation-video-panel-styles__phoenix {
  background-color: #975C46;
}

.ladder_ux_panels__homepage-animation-video-panel-styles__current-bar.ladder_ux_panels__homepage-animation-video-panel-styles__opal {
  background-color: #AFC5C8;
}

.ladder_ux_panels__homepage-animation-video-panel-styles__current-bar.ladder_ux_panels__homepage-animation-video-panel-styles__first-transition {
  transition: width 6.039s linear;
}

.ladder_ux_panels__homepage-animation-video-panel-styles__current-bar.ladder_ux_panels__homepage-animation-video-panel-styles__second-transition {
  transition: width 5.673s linear;
}

.ladder_ux_panels__homepage-animation-video-panel-styles__current-bar.ladder_ux_panels__homepage-animation-video-panel-styles__selected {
  width: 100%;
}

.ladder_ux_panels__homepage-animation-video-panel-styles__current-bar.ladder_ux_panels__homepage-animation-video-panel-styles__unselected {
  transition: width 0s;
}

.ladder_ux_panels__homepage-animation-video-panel-styles__current-bar.ladder_ux_panels__homepage-animation-video-panel-styles__last-transition {
  transition: width 8s linear;
}

.ladder_ux_panels__homepage-animation-video-panel-styles__mobile-button {
  display: none;
}

@media (min-width: 1025px) {

  
  
  .ladder_ux_panels__homepage-animation-video-panel-styles__desktop-button {
    display: flex;
    max-width: 250px;
    margin: 16px 0;
  }
  
  .ladder_ux_panels__homepage-animation-video-panel-styles__mobile-button {
    display: none;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_panels__homepage-animation-video-panel-styles__root {
    height: auto;
  }
  
  .ladder_ux_panels__homepage-animation-video-panel-styles__panel {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    height: auto;
  }
  
  .ladder_ux_panels__homepage-animation-video-panel-styles__right-subpanel {
    width: 100%;
    align-items: center;
    margin: 48px 0 0 0;
  }
  
  .ladder_ux_panels__homepage-animation-video-panel-styles__top-bar-wrapper {
    height: 5px;
    margin: 0 0 32px 0;
  }
  
  .ladder_ux_panels__homepage-animation-video-panel-styles__text {
    height: 75px;
    margin: 0;
    text-align: start;
    white-space: pre-wrap;
  }
  
  .ladder_ux_panels__homepage-animation-video-panel-styles__desktop-button {
    display: none;
  }
  
  .ladder_ux_panels__homepage-animation-video-panel-styles__mobile-button {
    display: flex;
    width: 100%;
    margin: 48px 0;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_panels__homepage-animation-video-panel-styles__root {
    height: auto;
  }
  
  .ladder_ux_panels__homepage-animation-video-panel-styles__panel {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    height: auto;
  }
  
  .ladder_ux_panels__homepage-animation-video-panel-styles__right-subpanel {
    width: 100%;
    align-items: center;
    margin: 48px 0 0 0;
  }
  
  .ladder_ux_panels__homepage-animation-video-panel-styles__top-bar-wrapper {
    height: 5px;
    margin: 0 0 32px 0;
  }
  
  .ladder_ux_panels__homepage-animation-video-panel-styles__text {
    height: 75px;
    margin: 0;
    text-align: start;
    white-space: pre-wrap;
  }
  
  .ladder_ux_panels__homepage-animation-video-panel-styles__desktop-button {
    display: none;
  }
  
  .ladder_ux_panels__homepage-animation-video-panel-styles__mobile-button {
    display: flex;
    width: 100%;
    margin: 48px 0;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_panels__homepage-animation-video-panel-styles__mobile-button {
    max-width: 250px;
  }

}
.ladder_agents_dashboard_contacts__add-contact-modal-styles__add-contact-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  justify-content: space-around;
  padding-bottom: 10px;
  padding-top: 10px;
}

.ladder_agents_dashboard_contacts__add-contact-modal-styles__quoter-button-text {
  font-size: 15px;
}

.ladder_agents_dashboard_contacts__add-contact-modal-styles__button {
  margin-top: 2em;
  width: 100%;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard_contacts__add-contact-modal-styles__add-contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_dashboard_contacts__add-contact-modal-styles__add-contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_agents_dashboard_contacts__add-contact-modal-styles__add-contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 600px;
  }

}
.ladder_account_ladder_up__policy-box-styles__root {
  box-sizing: border-box;
  width: 100%;
}

.ladder_account_ladder_up__policy-box-styles__inner {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #707070;
}

.ladder_account_ladder_up__policy-box-styles__inner-container {
  display: flex;
  align-items: center;
  padding: 16px;
}

.ladder_account_ladder_up__policy-box-styles__asterisk-container {
  padding: 20px 0;
  text-align: center;
}

.ladder_account_ladder_up__policy-box-styles__selected {
  border: 3px solid #FE4747;
}

.ladder_account_ladder_up__policy-box-styles__selected.ladder_account_ladder_up__policy-box-styles__rungs {
  border: 3px solid #D1A98F;
}

.ladder_account_ladder_up__policy-box-styles__per-month {
  text-align: center;
  align-self: center;
  padding: 0px 8px;
}

.ladder_account_ladder_up__policy-box-styles__face-until {
  align-self: flex-end;
  color: black;
}

@media (min-width: 1025px) {

  
  
  .ladder_account_ladder_up__policy-box-styles__root {
    min-width: 225px;
    max-width: 225px;
    margin: 20px 20px 0 20px;
  }
  
  .ladder_account_ladder_up__policy-box-styles__inner {
    cursor: pointer;
  }
  
  .ladder_account_ladder_up__policy-box-styles__inner-container {
    flex-direction: column;
  }
  
  .ladder_account_ladder_up__policy-box-styles__inner-border {
    border: 2px solid #FFFFFF;
  }
  
  .ladder_account_ladder_up__policy-box-styles__term-wrapper {
    padding-bottom: 10.0px;
    text-align: center;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account_ladder_up__policy-box-styles__root {
    min-width: 225px;
    max-width: 225px;
    margin: 20px 20px 0 20px;
  }
  
  .ladder_account_ladder_up__policy-box-styles__inner {
    cursor: pointer;
  }
  
  .ladder_account_ladder_up__policy-box-styles__inner-container {
    flex-direction: column;
  }
  
  .ladder_account_ladder_up__policy-box-styles__inner-border {
    border: 2px solid #FFFFFF;
  }
  
  .ladder_account_ladder_up__policy-box-styles__term-wrapper {
    padding-bottom: 10.0px;
    text-align: center;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_account_ladder_up__policy-box-styles__root {
    margin: 8px;
  }
  
  .ladder_account_ladder_up__policy-box-styles__inner-container {
    justify-content: space-between;
  }
  
  .ladder_account_ladder_up__policy-box-styles__inner-border {
    border-top: 2px solid #FFFFFF;
    border-right: 3px solid #FFFFFF;
    border-bottom: 2px solid #FFFFFF;
    border-left: 3px solid #FFFFFF;
  }
  
  .ladder_account_ladder_up__policy-box-styles__first-policy {
    border-top: 1px solid #D0D0D0;
  }
  
  .ladder_account_ladder_up__policy-box-styles__quote-container {
    display: flex;
    justify-content: flex-start;
  }

}
.ladder_megaphone_refer__icon-link-styles__root {
  width: 50px;
  height: 50px;
}

.ladder_megaphone_refer__icon-link-styles__root >img {
  width: 100%;
  height: 100%;
}

.ladder_megaphone_refer__icon-link-styles__root >svg {
  width: 100%;
  height: 100%;
}
.ladder_ux_form_groups__address-input-group-styles__root {
  padding: 12.0px 0;
}

.ladder_ux_form_groups__address-input-group-styles__help-text {
  font-weight: normal;
}

.ladder_ux_form_groups__address-input-group-styles__field {
  padding: 4.0px 0;
}

.ladder_ux_form_groups__address-input-group-styles__line2 {
  padding-top: 4.0px;
}

.ladder_ux_form_groups__address-input-group-styles__city-state-zip-container {
  display: flex;
  padding: 4.0px 0;
  box-sizing: border-box;
}

.ladder_ux_form_groups__address-input-group-styles__city {
  margin-right: 4.0px;
  width: 40%;
}

.ladder_ux_form_groups__address-input-group-styles__state {
  margin-right: 4.0px;
  width: 30%;
}

.ladder_ux_form_groups__address-input-group-styles__zip {
  width: 30%;
}
.ladder_markdoc_core__table-of-contents-styles__root {
  display: flex;
  overflow: scroll;
  height: 100%;
  width: 10%;
  min-width: 225px;
  padding: 1em;
  flex-direction: column;
}
.ladder_calculator_rebrand__page-summary-styles__root {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #FFFFFF;
  align-items: center;
}

.ladder_calculator_rebrand__page-summary-styles__content-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 100%;
  margin: 60px 0;
}

.ladder_calculator_rebrand__page-summary-styles__estimated-coverage-need {
  display: flex;
  flex-direction: column;
  margin: 0 0 30.0px 0;
}

.ladder_calculator_rebrand__page-summary-styles__breakdown {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}

.ladder_calculator_rebrand__page-summary-styles__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 22.5px 0;
}

.ladder_calculator_rebrand__page-summary-styles__total-need {
  display: flex;
  flex-direction: column;
  margin: 22.5px 0;
}

.ladder_calculator_rebrand__page-summary-styles__hr {
  width: 100%;
  margin: 22.5px 0;
}

.ladder_calculator_rebrand__page-summary-styles__button {
  display: flex;
  width: 45%;
}

.ladder_calculator_rebrand__page-summary-styles__coverage-amount {
  margin: 30.0px 0;
}

.ladder_calculator_rebrand__page-summary-styles__button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 499px) {

  
  
  .ladder_calculator_rebrand__page-summary-styles__content-wrapper {
    width: 90%;
    margin: 30.0px 0 20px 0;
  }
  
  .ladder_calculator_rebrand__page-summary-styles__button-container {
    flex-direction: column;
    width: 100%;
    row-gap: 20px;
  }
  
  .ladder_calculator_rebrand__page-summary-styles__button {
    width: 100%;
    margin: 0;
  }

}
.ladder_account_policy__beneficiaries-card-styles__bottom-border {
  border-bottom: 1px solid #D0D0D0;
}
.ladder_agents_common__input-column-styles__root {
  display: flex;
  flex-direction: column;
  text-align: left;
  box-sizing: border-box;
  padding: 5.0px 0;
}

.ladder_agents_common__input-column-styles__child-no-padding {
  padding: 0;
}
@keyframes go-green-fill {

31% {
    fill: #5ACD4F;
  }
  
69% {
    fill: #5ACD4F;
  }

}

.ladder_savings_steps_dory__price-badge-styles__price-badge {
  animation-delay: inherit;
  animation-duration: inherit;
  animation-timing-function: inherit;
}

.ladder_savings_steps_dory__price-badge-styles__price-badge path {
  animation-delay: inherit;
  animation-duration: inherit;
  animation-timing-function: inherit;
}

.ladder_savings_steps_dory__price-badge-styles__price-badge .star {
  animation-name: step-icon-spin;
  transform-origin: center;
}

.ladder_savings_steps_dory__price-badge-styles__price-badge .dollar {
  animation-name: go-green-fill;
}
.ladder_agents_dashboard_pre_screener__fit-check-outcome-styles__outcome {
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;
  flex-direction: column;
  gap: 16px;
  padding: 16px 24px;
  border-radius: 24px;
}

.ladder_agents_dashboard_pre_screener__fit-check-outcome-styles__red {
  background-color: #FDF0F1;
}

.ladder_agents_dashboard_pre_screener__fit-check-outcome-styles__yellow {
  background-color: rgba(255, 199, 110, 0.1);
}

.ladder_agents_dashboard_pre_screener__fit-check-outcome-styles__green {
  background-color: #E5F6F5;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard_pre_screener__fit-check-outcome-styles__outcome {
    padding: 8px;
  }

}
.ladder_markdown_components__underline-styles__root {
  text-decoration: underline;
}
.ladder_ny_form_review__multiselect-bare-styles__root {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.ladder_ny_form_review__multiselect-bare-styles__answer-root {
  display: flex;
  align-items: center;
  padding-left: 30.0px;
}

.ladder_ny_form_review__multiselect-bare-styles__display-lines {
  display: flex;
  flex-direction: column;
}

.ladder_ny_form_review__multiselect-bare-styles__hidden {
  visibility: hidden;
}
.ladder_onboarding_spouse_connections__referral-cta-text-styles__cta-2 {
  margin-top: 10.0px;
}
.ladder_gift_promo__header-panel-styles__root {
  display: flex;
  width: 100%;
  height: 800px;
  overflow: hidden;
  background-color: #D9D9D9;
}

.ladder_gift_promo__header-panel-styles__top-container {
  display: flex;
  position: relative;
  max-width: 1600px;
  margin: 0 auto;
  height: auto;
}

.ladder_gift_promo__header-panel-styles__title-container {
  display: flex;
  flex-direction: column;
  margin: 9% 0% 5% 3vw;
  width: 38%;
  height: auto;
  z-index: 1;
}

.ladder_gift_promo__header-panel-styles__image-container {
  display: flex;
  position: absolute;
  top: 100px;
  max-width: 1000px;
  width: 60%;
  right: 0px;
}

.ladder_gift_promo__header-panel-styles__image {
  width: 100%;
  height: auto;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_gift_promo__header-panel-styles__root {
    position: relative;
  }
  
  .ladder_gift_promo__header-panel-styles__top-container {
    flex-direction: column;
    position: absolute;
    margin: unset;
    align-items: center;
  }
  
  .ladder_gift_promo__header-panel-styles__title-container {
    margin: 10% auto 0%;
    max-width: 750px;
    width: 90%;
  }
  
  .ladder_gift_promo__header-panel-styles__image-container {
    margin-top: 10.0px;
    position: static;
    top: unset;
    align-self: center;
    width: 80%;
    right: unset;
  }
  
  .ladder_gift_promo__header-panel-styles__image {
    width: 100%;
    height: auto;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_gift_promo__header-panel-styles__root {
    position: relative;
  }
  
  .ladder_gift_promo__header-panel-styles__top-container {
    flex-direction: column;
    position: absolute;
    margin: unset;
    align-items: center;
  }
  
  .ladder_gift_promo__header-panel-styles__title-container {
    margin: 10% auto 0%;
    max-width: 750px;
    width: 90%;
  }
  
  .ladder_gift_promo__header-panel-styles__image-container {
    margin-top: 10.0px;
    position: static;
    top: unset;
    align-self: center;
    width: 80%;
    right: unset;
  }
  
  .ladder_gift_promo__header-panel-styles__image {
    width: 100%;
    height: auto;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_gift_promo__header-panel-styles__root {
    height: 700px;
  }
  
  .ladder_gift_promo__header-panel-styles__image-container {
    width: 100%;
  }

}
.ladder_quick_start_panels__rebrand-you-are-in-control-styles__root {
  display: flex;
  width: 100%;
  height: auto;
  background-color: #EFEAE1;
  justify-content: center;
  padding-bottom: 64px;
}

.ladder_quick_start_panels__rebrand-you-are-in-control-styles__root.ladder_quick_start_panels__rebrand-you-are-in-control-styles__homepage-ui {
  padding: 48px 0;
}

.ladder_quick_start_panels__rebrand-you-are-in-control-styles__heading {
  margin: 8.0px 0;
}

.ladder_quick_start_panels__rebrand-you-are-in-control-styles__container {
  display: flex;
  justify-content: space-between;
  max-width: 1400px;
  width: 80%;
  margin: 32px 0 32px 0;
}

.ladder_quick_start_panels__rebrand-you-are-in-control-styles__image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ladder_quick_start_panels__rebrand-you-are-in-control-styles__image {
  max-width: 550px;
  width: 90%;
}

.ladder_quick_start_panels__rebrand-you-are-in-control-styles__action-box {
  flex-basis: 50%;
  margin-top: 5%;
  max-width: 600px;
  margin-bottom: 32px;
}

.ladder_quick_start_panels__rebrand-you-are-in-control-styles__text {
  margin: 8.0px 0 64px 0;
}

.ladder_quick_start_panels__rebrand-you-are-in-control-styles__image-text {
  display: flex;
  margin-top: 32px;
  justify-content: center;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_quick_start_panels__rebrand-you-are-in-control-styles__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 16px 0 32px 0;
  }
  
  .ladder_quick_start_panels__rebrand-you-are-in-control-styles__image {
    height: auto;
    max-width: 60%;
  }
  
  .ladder_quick_start_panels__rebrand-you-are-in-control-styles__action-box {
    flex-direction: column;
    flex-basis: unset;
    padding-right: 0;
    max-width: 80%;
    margin: 0 0 64px 0;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_quick_start_panels__rebrand-you-are-in-control-styles__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 16px 0 32px 0;
  }
  
  .ladder_quick_start_panels__rebrand-you-are-in-control-styles__image {
    height: auto;
    max-width: 60%;
  }
  
  .ladder_quick_start_panels__rebrand-you-are-in-control-styles__action-box {
    flex-direction: column;
    flex-basis: unset;
    padding-right: 0;
    max-width: 80%;
    margin: 0 0 64px 0;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_quick_start_panels__rebrand-you-are-in-control-styles__text {
    margin: 16px auto;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_quick_start_panels__rebrand-you-are-in-control-styles__action-box {
    max-width: 90%;
  }
  
  .ladder_quick_start_panels__rebrand-you-are-in-control-styles__text {
    margin: 32px 0;
  }
  
  .ladder_quick_start_panels__rebrand-you-are-in-control-styles__image {
    width: 90%;
    max-width: unset;
  }
  
  .ladder_quick_start_panels__rebrand-you-are-in-control-styles__image-text {
    max-width: 90%;
    text-align: left;
  }

}
.ladder_agents_dashboard__share-page-menu-styles__share-page-menu-items {
  display: flex;
  flex-direction: row;
  gap: 50px;
  justify-content: center;
}

.ladder_agents_dashboard__share-page-menu-styles__share-page-menu-item {
  cursor: pointer;
}

.ladder_agents_dashboard__share-page-menu-styles__selected-menu-item {
  border-bottom: 3px solid blue;
}
.ladder_views_api__text-column-styles__header-text {
  margin-bottom: 24.0px;
}

.ladder_views_api__text-column-styles__text {
  margin-bottom: 24.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_views_api__text-column-styles__text {
    margin-bottom: unset;
  }

}
.ladder_guide_types_of_life_insurance__perm-vs-term-panel-styles__container {
  width: 100%;
  background-color: #EBE3D7;
  position: relative;
}

.ladder_guide_types_of_life_insurance__perm-vs-term-panel-styles__image {
  width: 50%;
  position: absolute;
  left: 360px;
  bottom: 0;
}

.ladder_guide_types_of_life_insurance__perm-vs-term-panel-styles__markdown-container {
  position: relative;
}

@media (min-width: 1025px) {

  
  
  .ladder_guide_types_of_life_insurance__perm-vs-term-panel-styles__container {
    padding-bottom: 52%;
  }
  
  .ladder_guide_types_of_life_insurance__perm-vs-term-panel-styles__image {
    left: -200px;
    width: 85%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_guide_types_of_life_insurance__perm-vs-term-panel-styles__container {
    margin-top: 60px;
    padding-top: 60px;
  }
  
  .ladder_guide_types_of_life_insurance__perm-vs-term-panel-styles__image {
    bottom: 0;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_guide_types_of_life_insurance__perm-vs-term-panel-styles__container {
    margin-top: 60px;
    padding-top: 60px;
  }
  
  .ladder_guide_types_of_life_insurance__perm-vs-term-panel-styles__image {
    bottom: 0;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_guide_types_of_life_insurance__perm-vs-term-panel-styles__container {
    padding-bottom: 31%;
  }
  
  .ladder_guide_types_of_life_insurance__perm-vs-term-panel-styles__image {
    left: 2%;
    width: 50%;
    position: absolute;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_guide_types_of_life_insurance__perm-vs-term-panel-styles__container {
    margin-top: 30.0px;
    padding-top: 30.0px;
    padding-bottom: 64%;
  }
  
  .ladder_guide_types_of_life_insurance__perm-vs-term-panel-styles__image {
    width: 100%;
    position: absolute;
    left: -4%;
    bottom: 0;
  }

}
.ladder_views_decision_common__amendment-agreement-modal-styles__modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.ladder_onboarding_spouse_connect_2__non-mobile-spacer-styles__root {
  display: flex;
  height: 20px;
  width: 20px;
}

@media (max-width: 499px) {

  
  
  .ladder_onboarding_spouse_connect_2__non-mobile-spacer-styles__root {
    display: none;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_onboarding_spouse_connect_2__non-mobile-spacer-styles__root {
    display: none;
  }

}
.ladder_agents_dashboard_quote__price-range-styles__container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.ladder_agents_dashboard_quote__price-range-styles__prices-row {
  display: flex;
}
.ladder_markdoc_common__list-comp-styles__ordered-root {
  list-style: decimal;
}

.ladder_markdoc_common__list-comp-styles__unordered-root {
  list-style: disc;
}

.ladder_markdoc_common__list-comp-styles__base-list {
  margin-left: 20px;
}
.ladder_views_decision_payment__payment-root-styles__header {
  width: 100%;
  max-width: 550px;
}

.ladder_views_decision_payment__payment-root-styles__wide-header {
  width: 100%;
  max-width: 800px;
}
.ladder_markdown_components__row-in-column-styles__root {
  display: flex;
  flex-direction: column;
}
.ladder_savings_steps__root-styles__root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #E7EEEF;
}
.ladder_agents_dashboard_quote__quoter-styles__quoter-button-text {
  font-size: 15px;
}

.ladder_agents_dashboard_quote__quoter-styles__columns {

}

.ladder_agents_dashboard_quote__quoter-styles__column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
}

.ladder_agents_dashboard_quote__quoter-styles__share-quote-form-container {
  display: flex;
  flex-direction: column;
}

@media (min-width: 1025px) {

  
  
  .ladder_agents_dashboard_quote__quoter-styles__share-quote-form-container {
    gap: 24px;
  }
  
  .ladder_agents_dashboard_quote__quoter-styles__columns {
    display: flex;
    gap: 24px;
  }
  
  .ladder_agents_dashboard_quote__quoter-styles__column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    width: 365px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard_quote__quoter-styles__share-quote-form-container {
    gap: 16px;
  }
  
  .ladder_agents_dashboard_quote__quoter-styles__mobile-margin-top {
    margin-top: 16px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_dashboard_quote__quoter-styles__share-quote-form-container {
    gap: 16px;
  }
  
  .ladder_agents_dashboard_quote__quoter-styles__mobile-margin-top {
    margin-top: 16px;
  }

}
.ladder_cover_me_asap_views_resource_center__resource-center-question-expander-styles__content-wrapper {
  width: 100%;
  text-align: left;
}

.ladder_cover_me_asap_views_resource_center__resource-center-question-expander-styles__question-and-expand {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0px;
  padding: 0px;
}

.ladder_cover_me_asap_views_resource_center__resource-center-question-expander-styles__question-and-expand:hover {
  cursor: pointer;
}

.ladder_cover_me_asap_views_resource_center__resource-center-question-expander-styles__info-icon {
  flex-shrink: 0;
  flex-grow: 0;
}
.ladder_guide_common__wrapper-styles__align-text div {
  text-align: left;
}
.ladder_guide_common__separating-line-styles__separating-line-container {
  width: 100%;
}

.ladder_guide_common__separating-line-styles__separating-line-inner-container {
  max-width: 700px;
  margin: auto;
  width: 90%;
  margin-top: 60px;
}

.ladder_guide_common__separating-line-styles__separating-line {
  border-bottom: 1.5px solid black;
  width: 50px;
}
.ladder_agents_dashboard__rider-info-bubble-styles__icon {
  display: flex;
  cursor: pointer;
  justify-content: center;
}

.ladder_agents_dashboard__rider-info-bubble-styles__rider-bubble {
  display: flex;
  flex-direction: column;
  height: auto;
  gap: 0.5em;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.ladder_agents_dashboard__rider-info-bubble-styles__rider-links {
  display: flex;
  align-items: center;
}

.ladder_agents_dashboard__rider-info-bubble-styles__rider-bubble-shadow-container {
  width: 100%;
  gap: 20px;
  justify-content: center;
  margin: 0.5em 0em;
  align-items: center;
  padding: 1em 1.5em;
  flex-direction: row;
  moz-border-radius: 30px;
  background-color: #F7DDC9;
  border-radius: 28px;
  display: flex;
  webkit-border-radius: 30px;
}

@media (min-width: 1025px) {

  
  
  .ladder_agents_dashboard__rider-info-bubble-styles__rider-bubble-container {
    width: 750px;
    padding-bottom: 40px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard__rider-info-bubble-styles__rider-bubble-container {
    width: 100%;
    padding-bottom: 40px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_dashboard__rider-info-bubble-styles__rider-bubble-container {
    width: 100%;
    padding-bottom: 40px;
  }

}
.ladder_gift_header__giftbox-styles__icon {
  display: flex;
  align-items: flex-end;
  height: 22.5px;
  padding-bottom: 1px;
}

.ladder_gift_header__giftbox-styles__icon svg {
  height: 23.5px;
}
.ladder_claims__root-styles__\% {
  width: 100%;
}
.ladder_ux_homepage_core__social-proof-panel-styles__root {
  background-color: #EFD8B4;
  display: flex;
  align-items: center;
  width: 100%;
  height: 320px;
  padding-bottom: 51px;
}

.ladder_ux_homepage_core__social-proof-panel-styles__image {
  width: 630px;
  height: 375px;
  position: relative;
  margin-left: 250px;
}

.ladder_ux_homepage_core__social-proof-panel-styles__spacer {
  padding-left: 10px;
}

.ladder_ux_homepage_core__social-proof-panel-styles__social-proof {

}

@media (max-width: 499px) {

  
  
  .ladder_ux_homepage_core__social-proof-panel-styles__root {
    flex-direction: column-reverse;
    height: auto;
    padding-top: 16px;
    padding-bottom: 0;
  }
  
  .ladder_ux_homepage_core__social-proof-panel-styles__social-proof {
    margin-top: 16px;
    display: grid;
    justify-items: center;
  }
  
  .ladder_ux_homepage_core__social-proof-panel-styles__image {
    width: 100%;
    height: 100%;
    margin: 0 0 -2px 0;
    padding: 0 8.0px;
  }

}
.ladder_views_decision_common__gradient-panel-styles__wrapper {
  width: 100%;
}
.ladder_views_decision_main__refuse-offer-are-you-sure-modal-styles__modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.ladder_agents_quote_landing__root-styles__root {
  background-color: #F7F2EF;
  height: 100vh;
}

.ladder_agents_quote_landing__root-styles__speed {
  display: none;
}
.ladder_form_section_milestone__scrollable-left-panel-styles__left-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ladder_form_section_milestone__scrollable-left-panel-styles__section {
  display: flex;
  flex-direction: column;
  width: 600px;
  margin-top: 5vw;
}

.ladder_form_section_milestone__scrollable-left-panel-styles__subtext {
  margin: 20px 0 30.0px 0;
  line-height: 30px;
}

.ladder_form_section_milestone__scrollable-left-panel-styles__milestone-container {
  display: flex;
  flex-direction: column;
  height: 30vh;
}

.ladder_form_section_milestone__scrollable-left-panel-styles__row {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  margin-bottom: 30.0px;
}

.ladder_form_section_milestone__scrollable-left-panel-styles__button {
  display: flex;
  margin-top: 30.0px;
  max-width: 250px;
}

.ladder_form_section_milestone__scrollable-left-panel-styles__icon {
  width: 30px;
  height: 30px;
}

.ladder_form_section_milestone__scrollable-left-panel-styles__mini-sprout-animation-control {
  animation: mini-sprout-animation 600ms 1 forwards;
  animation-delay: 500ms;
  opacity: 0;
}

@keyframes mini-sprout-animation {

0% {
    opacity: 1;
    height: 0px;
    width: 0px;
  }
  
30% {
    opacity: 1;
    height: 33px;
    width: 33px;
    animation-timing-function: ease-in;
  }
  
60% {
    opacity: 1;
    height: 27px;
    width: 27px;
    animation-timing-function: ease-out;
  }
  
80% {
    opacity: 1;
    height: 31px;
    width: 31px;
    animation-timing-function: ease-in;
  }
  
95% {
    opacity: 1;
    height: 29px;
    width: 29px;
    animation-timing-function: ease-out;
  }
  
100% {
    opacity: 1;
    height: 30px;
    width: 30px;
    animation-timing-function: ease-in;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_form_section_milestone__scrollable-left-panel-styles__left-panel {
    width: 100%;
    flex-basis: unset;
  }
  
  .ladder_form_section_milestone__scrollable-left-panel-styles__section {
    max-width: 600px;
    width: 90%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_form_section_milestone__scrollable-left-panel-styles__left-panel {
    width: 100%;
    flex-basis: unset;
  }
  
  .ladder_form_section_milestone__scrollable-left-panel-styles__section {
    max-width: 600px;
    width: 90%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_form_section_milestone__scrollable-left-panel-styles__left-panel {
    justify-content: flex-start;
  }
  
  .ladder_form_section_milestone__scrollable-left-panel-styles__section {
    height: auto;
    margin-top: unset;
  }
  
  .ladder_form_section_milestone__scrollable-left-panel-styles__text {
    margin: 20px 0 0 0;
  }
  
  .ladder_form_section_milestone__scrollable-left-panel-styles__subtext {
    margin: 10.0px 0 20px 0;
  }
  
  .ladder_form_section_milestone__scrollable-left-panel-styles__milestone-container {
    height: auto;
  }
  
  .ladder_form_section_milestone__scrollable-left-panel-styles__button {
    max-width: 100%;
  }

}
.ladder_financial_advisors_home__HowItWorksPanel-styles__\% {
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.ladder_ux_form_groups__date-input-group-styles__root {
  padding: 12.0px 0;
}

.ladder_ux_form_groups__date-input-group-styles__field {
  padding: 4.0px 0;
}

.ladder_ux_form_groups__date-input-group-styles__month-day-year-container {
  display: flex;
  box-sizing: border-box;
}

.ladder_ux_form_groups__date-input-group-styles__month {
  margin-right: 4.0px;
  width: 35%;
}

.ladder_ux_form_groups__date-input-group-styles__day {
  margin-right: 4.0px;
  width: 35%;
}

.ladder_ux_form_groups__date-input-group-styles__year {
  width: 30%;
}
.ladder_views_decision_common__signable-amendment-modal-styles__modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 100%;
  max-width: 580px;
}

.ladder_views_decision_common__signable-amendment-modal-styles__amendment-line {
  padding: 10.0px 0;
  text-align: left;
  width: 100%;
}

.ladder_views_decision_common__signable-amendment-modal-styles__title {
  padding: 30.0px 0;
  text-align: left;
}

.ladder_views_decision_common__signable-amendment-modal-styles__form {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 30px 0px;
}

.ladder_views_decision_common__signable-amendment-modal-styles__input-container {
  flex-grow: 1;
}

.ladder_views_decision_common__signable-amendment-modal-styles__input-container:first-child {
  margin-right: 12px;
}

.ladder_views_decision_common__signable-amendment-modal-styles__label {
  display: block;
  text-align: left;
}

.ladder_views_decision_common__signable-amendment-modal-styles__input {
  width: 100%;
}

.ladder_views_decision_common__signable-amendment-modal-styles__amendment-version {
  width: 100%;
  margin-top: 30px;
  text-align: left;
}

@media (max-width: 499px) {

  
  
  .ladder_views_decision_common__signable-amendment-modal-styles__form {
    flex-direction: column;
  }
  
  .ladder_views_decision_common__signable-amendment-modal-styles__agreement-container {
    padding-bottom: 30.0px;
  }
  
  .ladder_views_decision_common__signable-amendment-modal-styles__input-container {
    width: 100%;
    max-width: none;
  }
  
  .ladder_views_decision_common__signable-amendment-modal-styles__input-container:first-child {
    margin-right: 0;
  }

}
.ladder_ux_homepage_faq__homepage-faq-styles__root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 64px 0 128px 0;
  background-color: #AFC5C8;
}

.ladder_ux_homepage_faq__homepage-faq-styles__wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  max-width: 1400px;
  width: 100%;
}

.ladder_ux_homepage_faq__homepage-faq-styles__title {
  display: flex;
  text-align: center;
  justify-content: center;
  width: 80%;
  margin: 64px 0 32px 0;
}

.ladder_ux_homepage_faq__homepage-faq-styles__expand-card {
  display: flex;
  flex-direction: column;
  background-color: #E7EEEF;
  padding: 32px 32px;
  margin: 32px;
  width: 380px;
  height: 392px;
}

.ladder_ux_homepage_faq__homepage-faq-styles__mobile-expand-card {
  display: none;
  width: 80%;
}

.ladder_ux_homepage_faq__homepage-faq-styles__display {
  display: flex;
}

.ladder_ux_homepage_faq__homepage-faq-styles__text {
  font-size: 15px;
  line-height: 30px;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_homepage_faq__homepage-faq-styles__mobile-expand-card {
    display: flex;
  }
  
  .ladder_ux_homepage_faq__homepage-faq-styles__expand-card {
    display: none;
  }
  
  .ladder_ux_homepage_faq__homepage-faq-styles__root {
    padding: 0 0 64px 0;
  }
  
  .ladder_ux_homepage_faq__homepage-faq-styles__text-wrapper {
    margin: 0 16px;
  }

}
.ladder_header__header-styles__root {
  height: 100px;
  flex-grow: 0;
  z-index: 10;
}

.ladder_header__header-styles__flex {
  display: flex;
}

.ladder_header__header-styles__header {
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  height: 100px;
  transition: background-color 0.5s;
  background-color: #FFFFFF;
  box-sizing: border-box;
}

.ladder_header__header-styles__header.ladder_header__header-styles__top-banner {
  position: relative;
}

.ladder_header__header-styles__header.ladder_header__header-styles__transparent-background {
  background-color: transparent;
}

.ladder_header__header-styles__header.ladder_header__header-styles__hide {
  background-color: transparent;
  transition: background-color 250ms ease-in;
}

.ladder_header__header-styles__header.ladder_header__header-styles__max-width-1440 {
  max-width: 1440px;
  width: 100%;
  margin: auto;
}

.ladder_header__header-styles__hamburger {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 60px;
}

.ladder_header__header-styles__hamburger-img {
  height: 27px;
  cursor: pointer;
}

.ladder_header__header-styles__native-close {
  cursor: pointer;
  height: 20px;
}

.ladder_header__header-styles__hamburger-hidden {
  display: none;
}

.ladder_header__header-styles__rungs-dark {
  background-color: #AFC5C8;
  color: #FFFFFF;
  border-bottom: none;
}

.ladder_header__header-styles__dark {
  background-color: #475665;
  color: #FFFFFF;
  border-bottom: none;
}

.ladder_header__header-styles__bg-color-agents-blue {
  background-color: #336FED;
}

.ladder_header__header-styles__bg-color-spring-wood {
  background-color: #F7F2EF;
}

.ladder_header__header-styles__bg-color-themed {
  background-color: var(--tb);
}

.ladder_header__header-styles__sticky {
  position: fixed;
  border-bottom: 1px solid #E5E5E5;
}

.ladder_header__header-styles__no-bottom-border {
  border-width: 0;
}

.ladder_header__header-styles__z-index-low {
  z-index: 1;
}

.ladder_header__header-styles__container {
  align-items: center;
  display: flex;
  min-width: 0;
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.ladder_header__header-styles__container.ladder_header__header-styles__hide {
  opacity: 0;
  visibility: hidden;
  transition: initial;
}

.ladder_header__header-styles__flex-container {
  height: 100%;
  justify-content: space-between;
}

.ladder_header__header-styles__quarter-width {
  width: 25%;
}

.ladder_header__header-styles__center-text {
  display: flex;
  justify-content: center;
  width: 100%;
}

.ladder_header__header-styles__nav-and-hamburger-container {
  justify-content: flex-end;
}

@media (min-width: 1025px) {

  
  
  .ladder_header__header-styles__hamburger {
    display: none;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_header__header-styles__hamburger {
    display: none;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_header__header-styles__flex-container {
    padding: 0 60px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_header__header-styles__root {
    height: 60px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_header__header-styles__root {
    height: 60px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_header__header-styles__root {
    user-select: none;
  }
  
  .ladder_header__header-styles__header-underlay {
    position: fixed;
    top: -60px;
    z-index: 9;
    height: 60px;
    background: #FFFFFF;
    width: 100%;
  }
  
  .ladder_header__header-styles__header {
    z-index: 10;
    height: 60px;
  }
  
  .ladder_header__header-styles__hamburger {
    margin-left: 22.5px;
  }
  
  .ladder_header__header-styles__hamburger-img {
    width: 28px;
    height: 18px;
  }
  
  .ladder_header__header-styles__hamburger-cta {
    position: relative;
  }
  
  .ladder_header__header-styles__hamburger-cta:after {
    content: '';
    width: 10px;
    height: 10px;
    position: absolute;
    top: -7px;
    right: -8px;
    border-radius: 5px;
    background: #FE4747;
  }
  
  
  
  .ladder_header__header-styles__hamburger-cta-rungs:after {
    content: '';
    background: #D1A98F;
  }
  
  .ladder_header__header-styles__flex-container {
    padding: 0 15.0px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_header__header-styles__root {
    user-select: none;
  }
  
  .ladder_header__header-styles__header-underlay {
    position: fixed;
    top: -60px;
    z-index: 9;
    height: 60px;
    background: #FFFFFF;
    width: 100%;
  }
  
  .ladder_header__header-styles__header {
    z-index: 10;
    height: 60px;
  }
  
  .ladder_header__header-styles__hamburger {
    margin-left: 22.5px;
  }
  
  .ladder_header__header-styles__hamburger-img {
    width: 28px;
    height: 18px;
  }
  
  .ladder_header__header-styles__hamburger-cta {
    position: relative;
  }
  
  .ladder_header__header-styles__hamburger-cta:after {
    content: '';
    width: 10px;
    height: 10px;
    position: absolute;
    top: -7px;
    right: -8px;
    border-radius: 5px;
    background: #FE4747;
  }
  
  
  
  .ladder_header__header-styles__hamburger-cta-rungs:after {
    content: '';
    background: #D1A98F;
  }
  
  .ladder_header__header-styles__flex-container {
    padding: 0 15.0px;
  }

}

.ladder_header__header-styles__hide-element {
  display: none;
}
.ladder_view_schedule__calendar-styles__root {
  text-align: center;
  padding: 10.0px;
  color: #FE4747;
  background-color: #FFFFFF;
  display: none;
  flex-direction: column;
}

.ladder_view_schedule__calendar-styles__active {
  display: flex;
}

.ladder_view_schedule__calendar-styles__day-header {
  width: 50px;
  height: 25px;
}

.ladder_view_schedule__calendar-styles__arrow {
  height: 34px;
  width: 50px;
}

.ladder_view_schedule__calendar-styles__not-hidden {
  margin: auto;
}

.ladder_view_schedule__calendar-styles__hidden {
  visibility: hidden;
  margin: auto;
}

.ladder_view_schedule__calendar-styles__calendar {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 20px;
  width: 100%;
}

.ladder_view_schedule__calendar-styles__center-arrow {
  display: flex;
  justify-content: space-around;
}

.ladder_view_schedule__calendar-styles__week {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0;
}

.ladder_view_schedule__calendar-styles__month-header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.ladder_view_schedule__calendar-styles__arrow-wrapper {
  width: 48px;
}

.ladder_view_schedule__calendar-styles__month-arrow {
  height: 100%;
}

.ladder_view_schedule__calendar-styles__month-arrow-selectable {
  cursor: pointer;
}

.ladder_view_schedule__calendar-styles__guaranteed {
  line-height: 6px !important;
  visibility: hidden;
}

.ladder_view_schedule__calendar-styles__show-guaranteed {
  visibility: visible;
}

@media (min-width: 1025px) {

  
  
  .ladder_view_schedule__calendar-styles__week-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  
  .ladder_view_schedule__calendar-styles__selectable:hover {
    background-color: #D0D0D0;
    cursor: pointer;
  }
  
  .ladder_view_schedule__calendar-styles__selectable:hover .ladder_view_schedule__calendar-styles__guaranteed {
    color: #000000 !important;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_view_schedule__calendar-styles__week-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  
  .ladder_view_schedule__calendar-styles__selectable:hover {
    background-color: #D0D0D0;
    cursor: pointer;
  }
  
  .ladder_view_schedule__calendar-styles__selectable:hover .ladder_view_schedule__calendar-styles__guaranteed {
    color: #000000 !important;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_view_schedule__calendar-styles__root {
    padding: 10.0px 0;
  }
  
  .ladder_view_schedule__calendar-styles__week-container {
    display: flex;
  }
  
  .ladder_view_schedule__calendar-styles__mobile-calendar {
    display: flex;
    overflow-x: scroll;
    padding: 10.0px 0;
    margin: 0 auto;
    width: 100vw;
  }
  
  .ladder_view_schedule__calendar-styles__fixed-slot-container {
    position: relative;
  }
  
  .ladder_view_schedule__calendar-styles__fixed-slot {
    width: 11.111%;
    right: 0;
    top: 10.0px;
    height: 60px;
    margin: 0 auto;
    border: 2px solid;
    padding: 0;
    position: absolute;
    left: 0;
  }
  
  .ladder_view_schedule__calendar-styles__fixed-slot-border {
    border-color: #FE4747;
  }
  
  .ladder_view_schedule__calendar-styles__rungs-fixed-slot-border {
    border-color: #D1A98F;
  }

}
.ladder_ux_banner__dismissable-banner-styles__wrapper {
  display: flex;
  align-items: center;
}

.ladder_ux_banner__dismissable-banner-styles__dismiss-button {
  width: 16px;
  height: 16px;
  margin: 8.0px;
  cursor: pointer;
}

.ladder_ux_banner__dismissable-banner-styles__content {
  flex-grow: 1;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_banner__dismissable-banner-styles__wrapper {
    align-items: flex-start;
  }

}
.ladder_ux_panels__ads-landing-page-header-panel-styles__root {
  display: flex;
  background: #EFEAE1;
  width: 100%;
  justify-content: center;
  z-index: 1;
  overflow: hidden;
}

.ladder_ux_panels__ads-landing-page-header-panel-styles__container {
  display: flex;
  flex-direction: column;
  max-width: 1250px;
}

.ladder_ux_panels__ads-landing-page-header-panel-styles__family-container {
  display: flex;
  background-color: #AFC5C8;
}

.ladder_ux_panels__ads-landing-page-header-panel-styles__text-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.ladder_ux_panels__ads-landing-page-header-panel-styles__awards-container {
  display: flex;
  border-radius: 0 0 24px 24px;
  background-color: #F7F2EF;
}

.ladder_ux_panels__ads-landing-page-header-panel-styles__family-container {
  border-radius: 24px 24px 0 0;
}

.ladder_ux_panels__ads-landing-page-header-panel-styles__award-image {
  width: auto;
}

.ladder_ux_panels__ads-landing-page-header-panel-styles__button-container {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

@media (min-width: 1025px) {

  
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__container {
    width: 85%;
    margin-top: 50px;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__family-container {
    justify-content: space-between;
    height: 500px;
    overflow: hidden;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__button-container {
    width: 80%;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__cta-container-desktop {
    display: flex;
    min-width: 400px;
    max-width: 575px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__cta-container-mobile-and-tablet {
    display: none;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__text-container {
    width: 80%;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__awards-container {
    height: 150px;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__happy-people-image-desktop {
    display: block;
    object-fit: contain;
    object-position: bottom;
    min-width: 650px;
    width: 100%;
    height: 500px;
    margin-right: -1px;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__happy-people-image-mobile-and-tablet {
    display: none;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__award-display-desktop {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px 0 20px;
    width: 100%;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__award-display-mobile-and-tablet {
    display: none;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__award-image {
    height: 30px;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__award-text {
    max-width: 210px;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__award-split {
    border-left: 2px solid #AFC5C8;
    height: 50px;
    margin: 0 24px 0 24px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__root {
    gap: 20px;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__container {
    justify-content: center;
    width: 100%;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__family-container {
    justify-content: flex-end;
    height: auto;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__cta-container-desktop {
    display: none;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__cta-container-mobile-and-tablet {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 250px;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__button-full-width-class {
    width: 100%;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__happy-people-image-desktop {
    display: none;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__happy-people-image-mobile-and-tablet {
    display: block;
    object-fit: contain;
    object-position: bottom;
    width: 100%;
    height: auto;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__award-display-desktop {
    display: none;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__award-display-mobile-and-tablet {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 0 0 20px 20px;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__award-text {
  
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__award-split {
    display: none;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__text-container {
    width: 100%;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__button-container {
    width: 100%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__root {
    gap: 20px;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__container {
    justify-content: center;
    width: 100%;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__family-container {
    justify-content: flex-end;
    height: auto;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__cta-container-desktop {
    display: none;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__cta-container-mobile-and-tablet {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 250px;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__button-full-width-class {
    width: 100%;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__happy-people-image-desktop {
    display: none;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__happy-people-image-mobile-and-tablet {
    display: block;
    object-fit: contain;
    object-position: bottom;
    width: 100%;
    height: auto;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__award-display-desktop {
    display: none;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__award-display-mobile-and-tablet {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 0 0 20px 20px;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__award-text {
  
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__award-split {
    display: none;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__text-container {
    width: 100%;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__button-container {
    width: 100%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__root {
    padding: 50px 30px;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__awards-container {
    flex-direction: column;
    padding-top: 20px;
    height: auto;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__award-display-mobile-and-tablet {
    margin: 0 0 20px 30px;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__award-image {
    height: 20px;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__cta-container-mobile-and-tablet {
    max-width: 40%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__root {
    flex-direction: column;
    padding: 15px 30px;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__awards-container {
    flex-direction: column;
    padding-top: 15px;
    height: auto;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__award-display-mobile-and-tablet {
    margin: 0 25px 15px 25px;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__award-image {
    height: 17px;
  }

}

@media (min-width: 500px) and (max-width: 715px) {

  .ladder_ux_panels__ads-landing-page-header-panel-styles__root {
    flex-direction: column;
    padding: 15px 30px;
  }
  
  .ladder_ux_panels__ads-landing-page-header-panel-styles__cta-container-mobile-and-tablet {
    max-width: 100%;
  }

}
.ladder_widget_ui_v2__coverage-and-term-styles__root {
  align-self: center;
}

.portrait .ladder_widget_ui_v2__coverage-and-term-styles__root {
  flex-grow: 28;
}

.panel .ladder_widget_ui_v2__coverage-and-term-styles__root {
  flex-grow: 28;
}

.landscape .ladder_widget_ui_v2__coverage-and-term-styles__root {
  flex-grow: 5;
}
.ladder_form_upper_funnel_routing__npq-details-styles__wrapper {
  border: solid 1px;
  border-color: #D9D9D9;
  border-radius: 5.0px;
  padding: 30.0px;
  max-width: 600px;
}

.ladder_form_upper_funnel_routing__npq-details-styles__wrapper-padding {

}

.ladder_form_upper_funnel_routing__npq-details-styles__row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15.0px;
}

.ladder_form_upper_funnel_routing__npq-details-styles__bottom-row {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  border-top: solid 0.5px;
  border-color: inherit;
}

@media (min-width: 1025px) {

  
  
  .ladder_form_upper_funnel_routing__npq-details-styles__wrapper {
    min-width: 430px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_form_upper_funnel_routing__npq-details-styles__wrapper {
    border-left-width: 0;
    border-right-width: 0;
    border-radius: unset;
    padding: 30.0px 0;
    margin: 10.0px 0 20px 0;
  }
  
  .ladder_form_upper_funnel_routing__npq-details-styles__wrapper-padding {
    padding: 0 30.0px;
  }
  
  .ladder_form_upper_funnel_routing__npq-details-styles__row:last-of-type {
    margin-bottom: 0;
  }

}
.ladder_views_decision_optional_tic__root-styles__root {
  text-align: center;
}

.ladder_views_decision_optional_tic__root-styles__container {
  margin: 0 auto;
  padding: 0 16px;
  text-align: left;
  max-width: 600px;
}

.ladder_views_decision_optional_tic__root-styles__link {
  cursor: pointer;
}

.ladder_views_decision_optional_tic__root-styles__button {
  margin-top: 16px;
}
.ladder_guide_common__table-cell-styles__table-cell {
  padding: 16px;
}
.ladder_gift_promo__panel-body-styles__text-container {
  display: flex;
  flex-direction: column;
  max-width: 610px;
  width: 60%;
  margin: 60px;
}

.ladder_gift_promo__panel-body-styles__body-text {
  margin: 25.0px 0 50.0px 0;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_gift_promo__panel-body-styles__text-container {
    width: 90%;
    margin: unset;
  }
  
  .ladder_gift_promo__panel-body-styles__body-text {
    margin: 20px 0 30.0px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_gift_promo__panel-body-styles__text-container {
    width: 90%;
    margin: unset;
  }
  
  .ladder_gift_promo__panel-body-styles__body-text {
    margin: 20px 0 30.0px;
  }

}
.ladder_name_your_price_views__price-or-coverage-button-styles__price-or-coverage-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48%;
  height: 45px;
  border-radius: 10.025px;
}

.ladder_name_your_price_views__price-or-coverage-button-styles__price-or-coverage-button:hover {
  cursor: pointer;
}

.ladder_name_your_price_views__price-or-coverage-button-styles__selected-button {
  background-color: #FFFFFF;
}
.ladder_guide_common__list-item-styles__align-left div {
  align-items: flex-start;
  padding-top: 0;
  padding-bottom: 0;
  color: #000000;
}
.ladder_account_next__AccountRoute-styles__\% {
  display: flex;
  justify-content: center;
  width: 100%;
}

@media (min-width: 1025px) {

  
  
  .ladder_account_next__AccountRoute-styles__\% {
    min-height: 800px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_account_next__AccountRoute-styles__\% {
    flex-direction: column;
  }

}
.ladder_ux_trustpilot__trustpilot-divider-white-styles__root {
  margin-left: 15px;
  margin-right: 0px;
  display: flex;
  border-color: white;
  border-width: 1px;
  border-style: solid;
  opacity: 0.5;
}
.ladder_account_next_views_billing__Root-styles__faqs-margins {
  margin-top: 20px;
}
.ladder_app_review__question-styles__root {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
}

.ladder_app_review__question-styles__anchor-skip-header {
  scroll-margin-top: 99px;
}

@media (max-width: 499px) {

  
  
  .ladder_app_review__question-styles__anchor-skip-header {
    scroll-margin-top: 60px;
    scroll-snap-margin-top: 60px;
  }

}
.ladder_financial_advisors_home__flexible-coverage-learn-more-button-styles__\% {
  margin-top: 20px;
}

@media (min-width: 1025px) {

  
  
  .ladder_financial_advisors_home__flexible-coverage-learn-more-button-styles__\% {
    margin-top: 30px;
  }

}
.ladder_savings_steps_dory__root-styles__wrapper {
  position: fixed;
  top: 60px;
  left: 0;
  height: calc(100% - 60px);
  padding-top: 20px;
  background: linear-gradient(rgba(220,220,220,0.1) 0, white 2% 100%);
  z-index: 14;
}

.ladder_savings_steps_dory__root-styles__content {
  width: 100%;
  padding: 0 5% 0 5%;
}

.ladder_savings_steps_dory__root-styles__rolling-in {
  animation-name: move-down;
  animation-duration: 1200ms;
}

@keyframes move-right {

0% {
    transform: translateX(-160px);
    opacity: 0;
  }
  
1% {
    transform: translateX(-170px);
    opacity: 1;
  }
  
100% {
    transform: translateX(0);
  }

}

.ladder_savings_steps_dory__root-styles__skater {
  margin-top: 20px;
  width: 150px;
  height: auto;
}

.ladder_savings_steps_dory__root-styles__skater.ladder_savings_steps_dory__root-styles__animated {
  animation-name: move-right;
  animation-duration: 1000ms;
  animation-fill-mode: backwards;
  animation-delay: 1750.0ms;
  animation-timing-function: ease-out;
}

@keyframes steps-up {

0% {
    transform: translateY(30%);
    opacity: 0;
  }
  
100% {
    transform: translateY(0);
    opacity: 1;
  }

}

.ladder_savings_steps_dory__root-styles__steps {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ladder_savings_steps_dory__root-styles__steps.ladder_savings_steps_dory__root-styles__animated {
  animation-name: steps-up;
  animation-duration: 1000ms;
  animation-fill-mode: backwards;
  animation-delay: 1750.0ms;
  animation-timing-function: ease-in-out;
}

@keyframes buttons-up {

0% {
    transform: translateY(120px);
    opacity: 0;
  }
  
100% {
    transform: translateY(0);
    opacity: 1;
  }

}

.ladder_savings_steps_dory__root-styles__buttons {
  width: 100vw;
  justify-content: center;
  padding: 20px 9% 10.0px 9%;
  flex-direction: column;
  position: fixed;
  margin-top: 10.0px;
  box-shadow: 0 -5px 15px rgba(27,27,27,0.1);
  display: flex;
  bottom: 0;
  clip-path: inset(-20px 0 0);
  left: 0;
}

.ladder_savings_steps_dory__root-styles__buttons.ladder_savings_steps_dory__root-styles__animated {
  animation: buttons-up;
  animation-duration: 1000ms;
  animation-fill-mode: backwards;
  animation-delay: 1750.0ms;
  animation-timing-function: ease-in-out;
}

.ladder_savings_steps_dory__root-styles__buttons .ladder_savings_steps_dory__root-styles__no-thanks {
  margin-top: 10.0px;
}
.ladder_ux_panels__home-image-bcd-styles__image {
  width: 100%;
  right: 0;
  max-width: 1500px;
  height: auto;
  position: absolute;
  object-fit: contain;
  object-position: right;
  bottom: 0;
  max-height: 100%;
}

.ladder_ux_panels__home-image-bcd-styles__image-b-desktop {
  right: -40px;
}

.ladder_ux_panels__home-image-bcd-styles__image-2022-restyle {
  max-height: 690px;
  min-height: 570px;
  right: -330px;
  object-position: left;
}

.ladder_ux_panels__home-image-bcd-styles__image-desktop {
  display: block;
}

.ladder_ux_panels__home-image-bcd-styles__image-mobile {
  display: none;
}

.ladder_ux_panels__home-image-bcd-styles__image-container {
  z-index: -1;
}

.ladder_ux_panels__home-image-bcd-styles__button-bottom {
  display: none;
}

.ladder_ux_panels__home-image-bcd-styles__button-subtitle {
  display: none;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_panels__home-image-bcd-styles__image {
    width: 85%;
    min-width: 1024px;
  }
  
  .ladder_ux_panels__home-image-bcd-styles__image-b-desktop {
    right: unset;
    left: 100px;
  }
  
  .ladder_ux_panels__home-image-bcd-styles__image-c-tablet {
    right: -200px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_panels__home-image-bcd-styles__image-container-cd {
    margin-top: 300px;
    min-height: 850px;
  }
  
  .ladder_ux_panels__home-image-bcd-styles__image {
    width: 100%;
    display: flex;
    right: 0%;
    max-height: 90%;
  }
  
  .ladder_ux_panels__home-image-bcd-styles__image-desktop {
    display: none;
  }
  
  .ladder_ux_panels__home-image-bcd-styles__image-mobile {
    display: block;
  }
  
  .ladder_ux_panels__home-image-bcd-styles__image-container-2022-restyle {
    padding-left: 150px;
  }
  
  .ladder_ux_panels__home-image-bcd-styles__image-2022-restyle {
    max-height: 75%;
    object-position: right;
  }
  
  .ladder_ux_panels__home-image-bcd-styles__button-bottom {
    display: block;
    position: absolute;
    bottom: 80px;
    width: 90%;
    margin-left: 5%;
  }
  
  .ladder_ux_panels__home-image-bcd-styles__button-bottom-b {
    bottom: 20px;
  }
  
  .ladder_ux_panels__home-image-bcd-styles__button-bottom-b-subtitle {
    bottom: 60px;
  }
  
  .ladder_ux_panels__home-image-bcd-styles__button-subtitle {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    padding-bottom: 20px;
  }
  
  .ladder_ux_panels__home-image-bcd-styles__button-subtitle-text {
    z-index: 1;
  }

}
.ladder_onboarding_referrals__numbered-steps-styles__steps-container {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}
.ladder_ny_form_days_traveling__root-styles__input {
  width: 100px;
}

@media (max-width: 499px) {

  
  
  .ladder_ny_form_days_traveling__root-styles__input {
    width: 50px;
  }

}
.ladder_ny_form_name__root-styles__root {
  display: flex;
  flex-direction: column;
}

.ladder_ny_form_name__root-styles__first-middle {
  display: flex;
  flex-grow: 1;
}

.ladder_ny_form_name__root-styles__first {
  flex-grow: 1;
  margin-right: 10.0px;
}

.ladder_ny_form_name__root-styles__middle {
  flex-grow: 1;
}
.ladder_ux_swipeable__scrollable-loop-view-styles__input-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  position: relative;
}

.ladder_ux_swipeable__scrollable-loop-view-styles__loop-block {
  display: flex;
  flex-direction: row;
  height: 100%;
  position: absolute;
}

.ladder_ux_swipeable__scrollable-loop-view-styles__transition {
  transition: transform 0.3s linear;
}
.ladder_apply_current_page_ui__fallback-buttons-container-styles__button-wrapper {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  width: 200px;
}
.ladder_megaphone_signup__signup-input-styles__root {
  margin: 10.0px;
  flex-grow: 1;
}

.ladder_megaphone_signup__signup-input-styles__not-full-width {
  flex-grow: 0;
  width: 70%;
}

@media (max-width: 499px) {

  
  
  .ladder_megaphone_signup__signup-input-styles__not-full-width {
    flex-grow: 1;
    width: 100%;
  }
  
  .ladder_megaphone_signup__signup-input-styles__root {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
  }

}
.ladder_ux_chat__messenger-launcher-ui-styles__tab {
  right: 0px;
  border-width: 1px;
  border-color: rgba(255, 255, 255, 0.66);
  -webkit-box-shadow: 0 -1px 4px 2px rgba(0, 0, 0, 0.15);
  margin-right: 16px;
  border-style: solid solid none solid;
  position: fixed;
  z-index: 4;
  cursor: pointer;
  box-shadow: 0 -1px 4px 2px rgba(0, 0, 0, 0.15);
  background-color: #5D5D5D;
  border-radius: 8px 8px 0 0;
  display: flex;
  bottom: 0px;
}

.ladder_ux_chat__messenger-launcher-ui-styles__fixed-cta-app {
  bottom: 80px;
}

.ladder_ux_chat__messenger-launcher-ui-styles__fixed-cta-offer {
  bottom: 84px;
}

.ladder_ux_chat__messenger-launcher-ui-styles__fixed-cta-review {
  bottom: 78.5px;
}

.ladder_ux_chat__messenger-launcher-ui-styles__fixed-cta-onboarding-beneficiaries {
  bottom: 141px;
}

.ladder_ux_chat__messenger-launcher-ui-styles__icon {
  margin: 10px 0px 10px 12px;
  height: 20px;
}

.ladder_ux_chat__messenger-launcher-ui-styles__icon-large {
  display: flex;
  margin: 12px 16px 12px 16px;
}

.ladder_ux_chat__messenger-launcher-ui-styles__text {
  margin: 10px 12px 10px 8px;
}
.ladder_aimcor_transfer__error-message-styles__error {
  padding: 20px 0;
}
.ladder_agents_home__what-makes-ladder-different-styles__wrapper {
  display: flex;
  flex-direction: row;
  width: 90%;
  background-color: #E7EEEF;
}

.ladder_agents_home__what-makes-ladder-different-styles__image-container {
  display: flex;
  flex-direction: column;
}

.ladder_agents_home__what-makes-ladder-different-styles__image {
  width: 100%;
  height: auto;
  align-self: flex-end;
}

@media (min-width: 1025px) {

  
  
  .ladder_agents_home__what-makes-ladder-different-styles__image-container {
    width: 50%;
  }
  
  .ladder_agents_home__what-makes-ladder-different-styles__text {
    width: 50%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_agents_home__what-makes-ladder-different-styles__wrapper {
    flex-direction: column;
  }
  
  .ladder_agents_home__what-makes-ladder-different-styles__image-container {
    flex-direction: row;
  }
  
  .ladder_agents_home__what-makes-ladder-different-styles__image {
    align-self: flex-start;
    height: 100%;
    max-height: 300px;
    width: auto;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_home__what-makes-ladder-different-styles__wrapper {
    flex-direction: column;
  }
  
  .ladder_agents_home__what-makes-ladder-different-styles__image-container {
    flex-direction: row;
  }
  
  .ladder_agents_home__what-makes-ladder-different-styles__image {
    align-self: flex-start;
    height: 100%;
    max-height: 300px;
    width: auto;
  }

}
.ladder_adjust_offer__quotes-styles__root {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

@media (max-width: 499px) {

  
  
  .ladder_adjust_offer__quotes-styles__root {
    flex-direction: column;
    align-items: center;
  }

}
.ladder_agents_agent_assisted_app_views_decision__share-decision-button-styles__container {
  display: flex;
  max-width: 268px;
  margin-top: 30px;
}
.ladder_financial_advisors_home__top-panel-dashboard-link-styles__\% {
  padding: 0 5.5% 50px 5.5%;
}

@media (min-width: 1025px) {

  
  
  .ladder_financial_advisors_home__top-panel-dashboard-link-styles__\% {
    padding: 0;
    width: 555px;
  }

}
.ladder_cover_me_asap_views_in_funnel_survey__mediaalpha-ad-container-styles__container {
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  border: 1px solid #000000;
  width: 100%;
  padding: 15px 0 20px 0;
  max-height: 150px;
  gap: 15px;
}

.ladder_cover_me_asap_views_in_funnel_survey__mediaalpha-ad-container-styles__view {
  width: 60%;
  justify-content: center;
  height: 35px;
  align-items: center;
  padding: 2.5px 15.0px;
  align-self: center;
  margin-top: auto;
  background-color: #000000;
  border-radius: 7px;
  display: flex;
  color: #FFFFFF;
}

.ladder_cover_me_asap_views_in_funnel_survey__mediaalpha-ad-container-styles__view:hover {
  background-color: #5D5D5D;
}

.ladder_cover_me_asap_views_in_funnel_survey__mediaalpha-ad-container-styles__view svg {
  margin-left: 5.0px;
}

.ladder_cover_me_asap_views_in_funnel_survey__mediaalpha-ad-container-styles__view path {
  fill: #FFFFFF;
}

.ladder_cover_me_asap_views_in_funnel_survey__mediaalpha-ad-container-styles__img {
  width: 125px;
  margin-top: auto;
  align-self: center;
}

@media (max-width: 499px) {

  
  
  .ladder_cover_me_asap_views_in_funnel_survey__mediaalpha-ad-container-styles__img {
    width: 175px;
  }
  
  .ladder_cover_me_asap_views_in_funnel_survey__mediaalpha-ad-container-styles__container {
    max-height: 200px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_cover_me_asap_views_in_funnel_survey__mediaalpha-ad-container-styles__img {
    width: 175px;
  }
  
  .ladder_cover_me_asap_views_in_funnel_survey__mediaalpha-ad-container-styles__container {
    max-height: 200px;
  }

}
@media (max-width: 499px) {

  
  
  .ladder_account_next_cards_gift_page_link__right-img-styles__image {
    display: none;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account_next_cards_gift_page_link__right-img-styles__image {
    display: none;
  }

}

.ladder_account_next_cards_gift_page_link__right-img-styles__image {
  max-height: 100%;
}
.ladder_widget_ui_v2__widget-back-buttons-styles__root {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 10px;
}

.ladder_widget_ui_v2__widget-back-buttons-styles__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.tile-a .ladder_widget_ui_v2__widget-back-buttons-styles__root {
  margin-top: 10.0px;
}

.tile-b .ladder_widget_ui_v2__widget-back-buttons-styles__root {
  margin-top: 10.0px;
}

.portrait .ladder_widget_ui_v2__widget-back-buttons-styles__root {
  flex-grow: 15;
  justify-content: center;
  align-items: flex-start;
  margin-left: 15.0px;
  margin-right: 15.0px;
}

.panel .ladder_widget_ui_v2__widget-back-buttons-styles__root {
  flex-grow: 15;
  justify-content: center;
  align-items: flex-start;
  margin-left: 15.0px;
  margin-right: 15.0px;
}

.landscape .ladder_widget_ui_v2__widget-back-buttons-styles__root {
  flex-grow: 117;
}
.ladder_ux_animate__slide-down-styles__root {
  height: 0;
  transition-property: none;
  transition-timing-function: ease-in-out;
}

.ladder_ux_animate__slide-down-styles__up {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.ladder_ux_animate__slide-down-styles__very-short {
  transition-duration: 100ms;
}

.ladder_ux_animate__slide-down-styles__short {
  transition-duration: 250ms;
}

.ladder_ux_animate__slide-down-styles__normal {
  transition-duration: 500ms;
}

.ladder_ux_animate__slide-down-styles__long {
  transition-duration: 1s;
}

.ladder_ux_animate__slide-down-styles__linear {
  transition-timing-function: linear;
}

.ladder_ux_animate__slide-down-styles__closed {
  display: none;
}

.ladder_ux_animate__slide-down-styles__transitioning {
  overflow-y: hidden;
}
.ladder_calculator_native_calculator_share__ladder-app-store-badge-styles__app-store-image {
  display: block;
  max-width: 110px;
  width: auto;
  height: auto;
}
.ladder_form_date__dob-styles__height-s {
  height: auto;
}

.ladder_form_date__dob-styles__full-width {
  width: 100%;
}

@media (min-width: 1025px) {

  
  
  .ladder_form_date__dob-styles__width-full {
    width: 100%;
  }
  
  .ladder_form_date__dob-styles__dob-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_form_date__dob-styles__width-full {
    width: 295px;
  }
  
  .ladder_form_date__dob-styles__dob-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_form_date__dob-styles__width-full {
    width: 295px;
  }
  
  .ladder_form_date__dob-styles__dob-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

}
.ladder_form_common__icon-subtext-styles__icon-subtext-container {
  display: flex;
  align-items: center;
  margin-top: 15.0px;
}

.ladder_form_common__icon-subtext-styles__icon-subtext-text {
  margin-left: 10.0px;
}
.ladder_loading_page__loading-page-styles__fake-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  overflow: hidden;
  background-color: #FFFFFF;
}

.ladder_loading_page__loading-page-styles__fake-wrapper .ladder_loading_page__loading-page-styles__shine {
  position: absolute;
  transform: rotate(30deg);
  top: -40px;
  left: -100%;
  width: 100px;
  height: 125%;
  background: rgba(255, 255, 255, 0.5);
  filter: blur(5px);
}

.ladder_loading_page__loading-page-styles__fake-wrapper .ladder_loading_page__loading-page-styles__shine.ladder_loading_page__loading-page-styles__animated {
  animation: move-across 3s linear infinite;
}

.ladder_loading_page__loading-page-styles__fake-wrapper .ladder_loading_page__loading-page-styles__fake-progress-bar {
  width: 80%;
  height: 40px;
  margin: 20px;
}

.ladder_loading_page__loading-page-styles__fake-wrapper .ladder_loading_page__loading-page-styles__background {
  background-color: #F4F4F4;
}

.ladder_loading_page__loading-page-styles__fake-wrapper .ladder_loading_page__loading-page-styles__rungs-background {
  background-color: #DFE3E8;
}

.ladder_loading_page__loading-page-styles__fake-wrapper .ladder_loading_page__loading-page-styles__progress-bar-question-spacer {
  margin-bottom: 20px;
}

.ladder_loading_page__loading-page-styles__fake-wrapper .ladder_loading_page__loading-page-styles__fake-app-question-line-1 {
  width: 50%;
  height: 50px;
  margin-bottom: 10px;
}

.ladder_loading_page__loading-page-styles__fake-wrapper .ladder_loading_page__loading-page-styles__fake-app-question-line-2 {
  width: 20%;
  height: 50px;
  margin-bottom: 10px;
}

.ladder_loading_page__loading-page-styles__fake-wrapper .ladder_loading_page__loading-page-styles__answer-spacer {
  margin-bottom: 30px;
}

.ladder_loading_page__loading-page-styles__fake-wrapper .ladder_loading_page__loading-page-styles__fake-answer-wrapper {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 60%;
}

.ladder_loading_page__loading-page-styles__fake-wrapper .ladder_loading_page__loading-page-styles__fake-answer-line {
  height: 30px;
  width: 80%;
  margin-bottom: 10px;
}
.ladder_ux_animate__fade-replace-styles__very-short-height {
  transition: height 100ms ease-in-out;
}

.ladder_ux_animate__fade-replace-styles__short-height {
  transition: height 250ms ease-in-out;
}

.ladder_ux_animate__fade-replace-styles__normal-height {
  transition: height 500ms ease-in-out;
}

.ladder_ux_animate__fade-replace-styles__long-height {
  transition: height 1000ms ease-in-out;
}
.ladder_account_shared_components__ElevatedInfoCard-styles__button {
  margin-top: 30px;
}
.ladder_agents_agent_assisted_app_views_app_status__panel-with-label-styles__panel {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ladder_agents_agent_assisted_app_views_app_status__panel-with-label-styles__label {
  margin-bottom: 20px;
}
.ladder_lux_markdown_components__bullet-list-styles__root {
  list-style-type: disc;
  color: #707070;
  padding-left: 32px;
}
.ladder_savings_banner__banner-styles__root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #DBE6DB;
  position: relative;
  padding: 10.0px;
}

.ladder_savings_banner__banner-styles__top-row {
  display: flex;
  align-items: center;
}

.ladder_savings_banner__banner-styles__top-row .ladder_savings_banner__banner-styles__rocket {
  height: 20px;
}

.ladder_savings_banner__banner-styles__bottom-row {
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-flow: wrap;
  text-align: center;
}

.ladder_savings_banner__banner-styles__details-link {
  white-space: pre;
}

.ladder_savings_banner__banner-styles__dismiss-button {
  width: 20px;
  height: 100%;
  margin: 0 10.0px;
  cursor: pointer;
  position: absolute;
  right: 0;
}

.ladder_savings_banner__banner-styles__details {
  list-style: disc;
}

.ladder_savings_banner__banner-styles__details li {
  margin-top: 10.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_savings_banner__banner-styles__bottom-row {
    white-space: pre-wrap;
  }
  
  .ladder_savings_banner__banner-styles__learn-more-link {
    display: block;
    margin-top: 20px;
  }
  
  .ladder_savings_banner__banner-styles__learn-how-link {
    margin-bottom: 5.0px;
  }

}
.ladder_megaphone_signup__signup-form-styles__root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
}

.ladder_megaphone_signup__signup-form-styles__full-width.ladder_megaphone_signup__signup-form-styles__root {
  width: 100%;
}

.ladder_megaphone_signup__signup-form-styles__row {
  display: flex;
  justify-content: center;
  width: 100%;
}

@media (max-width: 499px) {

  
  
  .ladder_megaphone_signup__signup-form-styles__root {
    width: 100%;
  }
  
  .ladder_megaphone_signup__signup-form-styles__vertical-mobile {
    flex-direction: column;
    align-items: center;
  }

}
.ladder_apply_current_page__current-page-styles__root {
  flex-grow: 1;
}

.ladder_apply_current_page__current-page-styles__mystic-background {
  background-color: #E7EEEF;
}

.ladder_apply_current_page__current-page-styles__spacer {
  flex-grow: 1;
}

@media (max-width: 499px) {

  
  
  .ladder_apply_current_page__current-page-styles__root {
    display: flex;
    flex-direction: column;
  }

}

.ladder_apply_current_page__current-page-styles__plus-symbol {
  padding: 0 15.0px;
}

.ladder_apply_current_page__current-page-styles__quick-start-two-col-wrapper {
  display: flex;
  flex-direction: row-reverse;
}

@media (min-width: 1025px) {

  
  
  .ladder_apply_current_page__current-page-styles__two-col-main {
    width: 55%;
  }
  
  .ladder_apply_current_page__current-page-styles__quick-start-blurb {
    width: 90%;
    padding-bottom: 100px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_apply_current_page__current-page-styles__quick-start-two-col-wrapper {
    flex-direction: column-reverse;
  }
  
  .ladder_apply_current_page__current-page-styles__quick-start-blurb {
    width: 100%;
  }

}
.ladder_form_group__scrollable-question-style__question {
  width: 100%;
}
.ladder_beneficiary_form_modify_percentage__modify-percentage-view\*-styles__root {
  padding: 0 60px 20px;
}

.ladder_beneficiary_form_modify_percentage__modify-percentage-view\*-styles__evenly {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 10.0px;
  border-bottom: 1px solid #D0D0D0;
}

.ladder_beneficiary_form_modify_percentage__modify-percentage-view\*-styles__evenly>* {
  margin-left: 10.0px;
}

.ladder_beneficiary_form_modify_percentage__modify-percentage-view\*-styles__pad-inner {
  padding-left: 20px;
}

.ladder_beneficiary_form_modify_percentage__modify-percentage-view\*-styles__margin-left-auto {
  margin-left: auto;
}

@media (max-width: 499px) {

  
  
  .ladder_beneficiary_form_modify_percentage__modify-percentage-view\*-styles__root {
    padding: 0 0 20px;
  }

}
.ladder_guide_common__sidebar-spacer-styles__space-for-sidebar {
  margin: auto;
  width: 100%;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_guide_common__sidebar-spacer-styles__center-contents {
    justify-content: center;
    display: flex;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_guide_common__sidebar-spacer-styles__space-for-sidebar {
    padding-left: 450px;
  }
  
  .ladder_guide_common__sidebar-spacer-styles__smaller-space-for-sidebar {
    padding-left: 380px;
  }
  
  .ladder_guide_common__sidebar-spacer-styles__center-contents {
    justify-content: center;
    display: flex;
  }
  
  .ladder_guide_common__sidebar-spacer-styles__right-padding {
    padding-right: 62px;
  }

}
.ladder_megaphone_faqs__faqs-styles__paragraph {
  margin-bottom: 20px;
}
.ladder_form_group__scrollable-section-styles__section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 32px;
}

.ladder_form_group__scrollable-section-styles__section-header-divider {
  border-top: 1px dashed #BDBDBD;
}

.ladder_form_group__scrollable-section-styles__plus-minus-icon {
  width: 22.5px;
  height: 22.5px;
  margin-left: 20px;
  justify-self: end;
}

.ladder_form_group__scrollable-section-styles__close-section-link {
  display: flex;
  justify-content: center;
  text-decoration: underline;
}

.ladder_form_group__scrollable-section-styles__section-bg {
  background-color: #F4F4F4;
  margin: 0 -9999rem;
  padding: 0 9999rem;
  padding-bottom: 32px;
}

.ladder_form_group__scrollable-section-styles__reflexive-questions {
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  gap: 32px;
}

@media (max-width: 499px) {

  
  
  .ladder_form_group__scrollable-section-styles__section-header {
    padding-top: 24px;
  }
  
  .ladder_form_group__scrollable-section-styles__section-bg {
    padding-bottom: 24px;
  }
  
  .ladder_form_group__scrollable-section-styles__reflexive-questions {
    margin-top: 24px;
    gap: 24px;
  }

}
@media (min-width: 1025px) {

  
  
  .ladder_guide_common__life-insurance-guide-nav-column-styles__space-for-sidebar {
    padding-left: 450px;
  }
  
  .ladder_guide_common__life-insurance-guide-nav-column-styles__space-for-menu {
    padding-left: 380px;
  }
  
  .ladder_guide_common__life-insurance-guide-nav-column-styles__space-remaining-after-menu {
    padding-left: 70px;
  }

}
.ladder_cover_me_asap_views_resource_center__ResourceCenter-styles__content-wrapper {
  padding: 20px 7% 30px 7%;
}

.ladder_cover_me_asap_views_resource_center__ResourceCenter-styles__tiles {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.ladder_cover_me_asap_views_resource_center__ResourceCenter-styles__line-break {
  display: flex;
  border-bottom: 1px solid #D9D9D9;
  margin: 15px 0px 15px 0px;
}

.ladder_cover_me_asap_views_resource_center__ResourceCenter-styles__header {
  padding: 20px 0px 20px 7%;
  border-bottom: 1px solid #D9D9D9;
}

.ladder_cover_me_asap_views_resource_center__ResourceCenter-styles__footer-container {
  display: flex;
  justify-content: center;
  width: 100%;
  border-top: 1px solid #D9D9D9;
  position: sticky;
  bottom: 0px;
  background-color: #FFFFFF;
}

.ladder_cover_me_asap_views_resource_center__ResourceCenter-styles__footer-button {
  width: 86%;
  margin: 30px 0px 30px 0px;
}

.ladder_cover_me_asap_views_resource_center__ResourceCenter-styles__section-title {
  margin: 20px 0px 20px 0px;
}

.ladder_cover_me_asap_views_resource_center__ResourceCenter-styles__section-subtitle {
  margin: 20px 0px 10px 0px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_cover_me_asap_views_resource_center__ResourceCenter-styles__tiles {
    flex-direction: column;
  }
  
  .ladder_cover_me_asap_views_resource_center__ResourceCenter-styles__footer-button {
    margin: 10px 0px 10px 0px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_cover_me_asap_views_resource_center__ResourceCenter-styles__tiles {
    flex-direction: column;
  }
  
  .ladder_cover_me_asap_views_resource_center__ResourceCenter-styles__footer-button {
    margin: 10px 0px 10px 0px;
  }

}
.ladder_laddering_page__upside-of-laddering-up-styles__root {
  display: flex;
  height: 700px;
  overflow: hidden;
  justify-content: center;
  width: 100%;
  background-color: #E7EEEF;
}

.ladder_laddering_page__upside-of-laddering-up-styles__top-container {
  display: flex;
  width: 100%;
  max-width: 2100px;
  position: relative;
}

.ladder_laddering_page__upside-of-laddering-up-styles__text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  left: 10vw;
  top: 10vh;
  height: auto;
  width: 600px;
}

.ladder_laddering_page__upside-of-laddering-up-styles__icon {
  display: flex;
  width: 22px;
  height: 22px;
}

.ladder_laddering_page__upside-of-laddering-up-styles__sentence-container {
  display: flex;
  height: 60px;
  flex-direction: row;
  align-items: baseline;
  margin: 5.0px 0;
}

.ladder_laddering_page__upside-of-laddering-up-styles__sentence {
  display: flex;
  margin-left: 20px;
}

.ladder_laddering_page__upside-of-laddering-up-styles__image {
  display: flex;
  position: absolute;
  right: 2vw;
  bottom: 5vh;
  max-width: 1191px;
  max-height: 450px;
  width: 80vw;
  height: auto;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_laddering_page__upside-of-laddering-up-styles__root {
    flex-direction: column;
    height: auto;
  }
  
  .ladder_laddering_page__upside-of-laddering-up-styles__top-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: unset;
  }
  
  .ladder_laddering_page__upside-of-laddering-up-styles__text {
    display: flex;
    align-self: center;
    margin: 130.0px 0;
    position: unset;
    max-width: 580px;
    width: 90%;
    left: unset;
    top: unset;
  }
  
  .ladder_laddering_page__upside-of-laddering-up-styles__image {
    width: auto;
    right: unset;
    max-width: 480px;
    height: auto;
    margin: unset;
    align-self: center;
    position: unset;
    bottom: unset;
    max-height: unset;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_laddering_page__upside-of-laddering-up-styles__root {
    flex-direction: column;
    height: auto;
  }
  
  .ladder_laddering_page__upside-of-laddering-up-styles__top-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: unset;
  }
  
  .ladder_laddering_page__upside-of-laddering-up-styles__text {
    display: flex;
    align-self: center;
    margin: 130.0px 0;
    position: unset;
    max-width: 580px;
    width: 90%;
    left: unset;
    top: unset;
  }
  
  .ladder_laddering_page__upside-of-laddering-up-styles__image {
    width: auto;
    right: unset;
    max-width: 480px;
    height: auto;
    margin: unset;
    align-self: center;
    position: unset;
    bottom: unset;
    max-height: unset;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_laddering_page__upside-of-laddering-up-styles__text {
    margin: 60px 0;
  }
  
  .ladder_laddering_page__upside-of-laddering-up-styles__image {
    max-width: 80%;
    margin: 0 auto;
  }

}
.ladder_requestion_review_ui__re-question-banner-styles__inner-banner-container {
  max-width: 600px;
  padding-left: 6%;
  padding-right: 6%;
}

.ladder_requestion_review_ui__re-question-banner-styles__header-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.ladder_requestion_review_ui__re-question-banner-styles__entire-banner {
  display: flex;
  justify-content: center;
  background-color: #FBF1F1;
  padding-top: 18px;
  padding-bottom: 18px;
}

@media (min-width: 1025px) {

  
  
  .ladder_requestion_review_ui__re-question-banner-styles__header-container {
    margin-bottom: 5px;
  }

}
.ladder_oss_licenses_view__root-styles__root {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.ladder_oss_licenses_view__root-styles__inner {
  max-width: 80%;
}

.ladder_oss_licenses_view__root-styles__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.ladder_oss_licenses_view__root-styles__entry {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
}

.ladder_oss_licenses_view__root-styles__licenses {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.ladder_account_next_views_messages__response-form-styles__\% {
  min-height: 200px;
}
.ladder_guide_life_insurance_cost__expert-panel-styles__container {
  width: 100%;
  position: relative;
  background-color: #F7F2EF;
}

.ladder_guide_life_insurance_cost__expert-panel-styles__bottom-text-wrapper {
  padding: 16px 0px;
}

.ladder_guide_life_insurance_cost__expert-panel-styles__heading {
  margin-bottom: 20px;
}

.ladder_guide_life_insurance_cost__expert-panel-styles__button-container {
  margin-top: 30.0px;
  width: 70%;
}

.ladder_guide_life_insurance_cost__expert-panel-styles__link-container {
  margin-top: 30.0px;
  position: relative;
  z-index: 1;
}

@media (min-width: 1025px) {

  
  
  .ladder_guide_life_insurance_cost__expert-panel-styles__markdown-container {
    position: absolute;
    top: 26%;
    left: 128px;
    width: 40%;
  }
  
  .ladder_guide_life_insurance_cost__expert-panel-styles__image {
    width: 100%;
  }
  
  .ladder_guide_life_insurance_cost__expert-panel-styles__container {
    min-height: 665px;
    display: flex;
    align-items: flex-end;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_guide_life_insurance_cost__expert-panel-styles__container {
    min-height: 545px;
    display: flex;
    align-items: center;
  }
  
  .ladder_guide_life_insurance_cost__expert-panel-styles__markdown-container {
    width: 55%;
    padding-left: 5%;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  
  .ladder_guide_life_insurance_cost__expert-panel-styles__image {
    width: 90%;
    position: absolute;
    bottom: 0;
    right: 0;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_guide_life_insurance_cost__expert-panel-styles__container {
    padding-bottom: 64%;
    padding-top: 60px;
  }
  
  .ladder_guide_life_insurance_cost__expert-panel-styles__markdown-container {
    width: 90%;
  }
  
  .ladder_guide_life_insurance_cost__expert-panel-styles__image {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  
  .ladder_guide_life_insurance_cost__expert-panel-styles__mobile-container {
    display: flex;
    justify-content: center;
  }

}
.ladder_ux_containers__vertical-styles__root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.ladder_ux_trustpilot__trustpilot-condensed-section-styles__trust-pilot-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 16px;
}

.ladder_ux_trustpilot__trustpilot-condensed-section-styles__trustpilot {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.ladder_ux_trustpilot__trustpilot-condensed-section-styles__trustpilot-logo {
  width: 110px;
}

.ladder_ux_trustpilot__trustpilot-condensed-section-styles__trustpilot-logo-green-black {
  width: 110px;
  margin-right: 10px;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_trustpilot__trustpilot-condensed-section-styles__trustpilot-logo {
    min-width: 80px;
    width: 100%;
    max-width: 100px;
  }
  
  .ladder_ux_trustpilot__trustpilot-condensed-section-styles__trustpilot-logo-green-black {
    width: 80px;
  }

}
.ladder_views_decision_common__rungs-offer-card-styles__wrapper {
  background-color: #FFFFFF;
}

.ladder_views_decision_common__rungs-offer-card-styles__section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.ladder_views_decision_common__rungs-offer-card-styles__header {
  padding: 5px;
  border-bottom: 1px solid #F1F1F1;
  box-sizing: border-box;
}

.ladder_views_decision_common__rungs-offer-card-styles__price-banner {
  background-color: #DBE6DB;
  border-radius: 10px;
  padding: 5.0px 10.0px;
}

.ladder_views_decision_common__rungs-offer-card-styles__rider-banner {
  background-color: #F7DDC9;
  border-radius: 10px;
  padding: 5.0px 10.0px;
}

.ladder_views_decision_common__rungs-offer-card-styles__content {
  padding: 10%;
}

.ladder_views_decision_common__rungs-offer-card-styles__content .ladder_views_decision_common__rungs-offer-card-styles__section {
  margin-bottom: 20px;
  text-align: center;
}

@media (max-width: 499px) {

  
  
  .ladder_views_decision_common__rungs-offer-card-styles__wrapper {
    width: 100%;
    margin: 0;
  }

}
.ladder_apply_ny_current_page__prompt-banner-styles__root {
  padding: 20px 5% 0 5%;
  width: 100%;
  display: flex;
  justify-content: center;
}
.ladder_app_in_app_billing__bank-status-success-design-exp-styles__root {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
}

.ladder_app_in_app_billing__bank-status-success-design-exp-styles__title {
  margin: 0 0 22.5px 10.0px;
}

.ladder_app_in_app_billing__bank-status-success-design-exp-styles__subtitle {
  margin: 0 0 20px 10.0px;
}

.ladder_app_in_app_billing__bank-status-success-design-exp-styles__reminder-text {
  margin: 20px 0 20px 10.0px;
}

.ladder_app_in_app_billing__bank-status-success-design-exp-styles__text {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin: 0 0 20px 0;
}
.ladder_account_ladder_up__view-laddering-up-inner-styles__root {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}

.ladder_account_ladder_up__view-laddering-up-inner-styles__quotes-container {
  width: 90%;
}

.ladder_account_ladder_up__view-laddering-up-inner-styles__total-coverage-price-text {
  padding: 10px;
  display: flex;
}

.ladder_account_ladder_up__view-laddering-up-inner-styles__center {
  text-align: center;
}

.ladder_account_ladder_up__view-laddering-up-inner-styles__label {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 20px;
  margin-bottom: 20px;
}

.ladder_account_ladder_up__view-laddering-up-inner-styles__dropdown {
  width: 350px;
  padding: 20px;
  color: darker-gray;
}

.ladder_account_ladder_up__view-laddering-up-inner-styles__info-expander-container {
  padding: 3px 0px 0px 6px;
}

@media (max-width: 499px) {

  
  
  .ladder_account_ladder_up__view-laddering-up-inner-styles__root {
    flex-direction: column;
    align-items: center;
  }
  
  .ladder_account_ladder_up__view-laddering-up-inner-styles__dropdown {
    width: 100%;
  }

}
.ladder_ux_alert_card__application-rebrand-alert-card-styles__box-n {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  border: 1px solid #D0D0D0;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
}

.ladder_ux_alert_card__application-rebrand-alert-card-styles__box-dismissable {
  padding: 4.0px 4.0px;
}

.ladder_ux_alert_card__application-rebrand-alert-card-styles__box-no-dismiss {
  padding: 0 32px;
}

.ladder_ux_alert_card__application-rebrand-alert-card-styles__rungs {
  background-color: #f4e5d3;
  border: none;
  text-align: left;
  padding: 0 16px;
  color: #000000;
}

.ladder_ux_alert_card__application-rebrand-alert-card-styles__error {
  color: #FE4747;
  border-color: #FE4747;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_alert_card__application-rebrand-alert-card-styles__copy-margin-dismissable {
    margin-top: 16px;
  }
  
  .ladder_ux_alert_card__application-rebrand-alert-card-styles__copy-margin-no-dismiss {
    margin-top: 0;
  }
  
  .ladder_ux_alert_card__application-rebrand-alert-card-styles__copy-squeeze {
    margin-left: 8.0px;
    right: 8.0px;
  }

}

.ladder_ux_alert_card__application-rebrand-alert-card-styles__new-alert-card {
  width: 100%;
  border-width: 3px;
  max-width: 600px;
  margin-right: auto;
  padding: 20.0px 32px;
  text-align: left;
  border-style: solid;
  position: relative;
  border-bottom-style: none;
  margin-bottom: 30px;
  border-right-style: none;
  margin-left: auto;
  display: flex;
  border-top-style: none;
}

.ladder_ux_alert_card__application-rebrand-alert-card-styles__new-alert-card.ladder_ux_alert_card__application-rebrand-alert-card-styles__animated {
  animation: move-up 0.45s ease-in;
}

.ladder_ux_alert_card__application-rebrand-alert-card-styles__no-copy-top-margin {
  margin-top: 0px;
}

.ladder_ux_alert_card__application-rebrand-alert-card-styles__no-bottom-margin {
  margin-bottom: 0px;
}
.ladder_ux_radio_input__rungs-radio-option-styles__wrapper {
  border-style: solid;
  border-width: 1px;
  padding-right: 20.0px;
  min-height: 64px;
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.ladder_ux_radio_input__rungs-radio-option-styles__no-border-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.ladder_ux_radio_input__rungs-radio-option-styles__scrollable-app-wrapper {
  background-color: #FFFFFF;
}

.ladder_ux_radio_input__rungs-radio-option-styles__disabled {
  cursor: default;
}

.ladder_ux_radio_input__rungs-radio-option-styles__radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

.ladder_ux_radio_input__rungs-radio-option-styles__radio-wrapper {
  min-width: 16px;
  width: 16px;
  border-width: 0.02rem;
  justify-content: center;
  height: 16px;
  margin: 0 20.0px;
  align-items: center;
  border-style: solid;
  position: relative;
  border-radius: 50%;
  box-sizing: content-box;
  display: flex;
}

.ladder_ux_radio_input__rungs-radio-option-styles__radio-wrapper input:invalid {
  box-shadow: none;
}

.ladder_ux_radio_input__rungs-radio-option-styles__radio-wrapper-selected {
  border-width: 2.0px;
}

.ladder_ux_radio_input__rungs-radio-option-styles__radio-selected {

}

.ladder_ux_radio_input__rungs-radio-option-styles__radio-dot {
  border-radius: 50%;
  height: 50%;
  width: 50%;
  position: absolute;
}

.ladder_ux_radio_input__rungs-radio-option-styles__offset-focused-border {
  margin-left: -1px;
}
.ladder_form_banners__banner-template-styles__wrapper {
  display: flex;
  align-self: center;
  gap: 24px;
  width: 100%;
  max-width: 600px;
  background-color: #F7F2EF;
  border-radius: 10px;
  padding: 32px;
}

.ladder_form_banners__banner-template-styles__wrapper.ladder_form_banners__banner-template-styles__top {
  margin-bottom: 32px;
}

.ladder_form_banners__banner-template-styles__wrapper.ladder_form_banners__banner-template-styles__after-question {
  margin: 32px 0;
}

.ladder_form_banners__banner-template-styles__wrapper.ladder_form_banners__banner-template-styles__bottom {
  margin-top: 32px;
}

.ladder_form_banners__banner-template-styles__icon-wrapper {
  height: 32px;
  width: 32px;
  min-height: 30px;
  min-width: 30px;
}

.ladder_form_banners__banner-template-styles__icon-wrapper path {
  fill: #000000;
}

@media (max-width: 499px) {

  
  
  .ladder_form_banners__banner-template-styles__wrapper {
    padding: 16px;
  }
  
  .ladder_form_banners__banner-template-styles__wrapper.ladder_form_banners__banner-template-styles__top {
    margin-bottom: 24px;
  }
  
  .ladder_form_banners__banner-template-styles__wrapper.ladder_form_banners__banner-template-styles__after-question {
    margin: 24px 0;
  }
  
  .ladder_form_banners__banner-template-styles__wrapper.ladder_form_banners__banner-template-styles__bottom {
    margin-top: 24px;
  }

}
.ladder_affiliates__phone-video-styles__wrapper {
  position: relative;
}

.ladder_affiliates__phone-video-styles__phone {
  max-width: 400px;
  height: 560px;
}

.ladder_affiliates__phone-video-styles__video {
  right: 20%;
  top: 7%;
  height: 82%;
  border-bottom-right-radius: 10% 5%;
  position: absolute;
  border-bottom-left-radius: 10% 5%;
  border-top-right-radius: 10% 5%;
  overflow: hidden;
  border-top-left-radius: 10% 5%;
  left: 12%;
}
@media (min-width: 1025px) {

  
  
  .ladder_about__family-photo-styles__container {
    margin-top: 85px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_about__family-photo-styles__container {
    margin-top: 75px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_about__family-photo-styles__container {
    margin-top: 75px;
  }

}

.ladder_about__family-photo-styles__photo-with-label {
  display: flex;
  flex-direction: column;
  min-width: 270px;
}

.ladder_about__family-photo-styles__family-photo {
  width: 100%;
  height: auto;
}

.ladder_about__family-photo-styles__thats-jamie-container {
  display: flex;
  justify-content: flex-end;
  margin-right: 6%;
}

.ladder_about__family-photo-styles__thats-jamie-svg {
  display: flex;
  width: 400px;
  max-width: 80%;
}

@media (min-width: 1025px) {

  
  
  .ladder_about__family-photo-styles__thats-jamie-container {
    margin-top: 20px;
    margin-right: 9%;
  }
  
  .ladder_about__family-photo-styles__photo-with-label {
    margin-top: 90px;
  }

}
.ladder_ux_form__multi-select-styles__border-container {
  border: 1px solid #D0D0D0;
  display: flex;
  position: relative;
}

.ladder_ux_form__multi-select-styles__border-container::after {
  background-position: center;
  right: 0;
  border-left: 1px solid #D0D0D0;
  top: 0;
  background-repeat: no-repeat;
  font-family: Montserrat;
  align-items: center;
  padding: 1rem;
  speak: none;
  position: absolute;
  font-weight: bold;
  content: '';
  background-size: 12px 8px;
  background-color: #FFFFFF;
  display: flex;
  bottom: 0;
  pointer-events: none;
  background-image: url(../img/down-arrow.svg);
  color: #707070;
}

.ladder_ux_form__multi-select-styles__border-container:hover {
  color: #707070;
  border-color: #CF967E;
}

.ladder_ux_form__multi-select-styles__border-container:hover::after {
  color: #707070;
  border-color: #CF967E;
}

.ladder_ux_form__multi-select-styles__border-container.ladder_ux_form__multi-select-styles__disabled:hover {
  border-color: #D0D0D0;
}

.ladder_ux_form__multi-select-styles__border-container.ladder_ux_form__multi-select-styles__disabled:hover::after {
  border-color: #D0D0D0;
}

.ladder_ux_form__multi-select-styles__border-container [disabled] {
  background: #F4F4F4;
  border-radius: 0;
  color: gray;
  border-color: transparent;
}

.ladder_ux_form__multi-select-styles__border-container .ladder_ux_form__multi-select-styles__rungs>select {
  background-color: transparent;
}

.ladder_ux_form__multi-select-styles__border-container [disabled] {
  background-color: transparent;
}

.ladder_ux_form__multi-select-styles__border-container-text-input {
  box-sizing: border-box;
  height: 48px;
}

.ladder_ux_form__multi-select-styles__active {
  color: #000000;
  border-color: #CF967E;
  border-width: 2.0px;
}

.ladder_ux_form__multi-select-styles__active::after {
  color: #707070;
  border-color: #CF967E;
}

.ladder_ux_form__multi-select-styles__border-error {
  border-color: #FE4747;
}

.ladder_ux_form__multi-select-styles__border-error::after {
  border-right-color: #FE4747;
  color: #FE4747;
}

.ladder_ux_form__multi-select-styles__full-width {
  width: 100%;
}

.ladder_ux_form__multi-select-styles__full-height {
  height: 100%;
}

.ladder_ux_form__multi-select-styles__text-family-lato {
  font-family: Lato, sans-serif;
}

.ladder_ux_form__multi-select-styles__text-family-work-sans {
  font-family: Work Sans, sans-serif;
}

.ladder_ux_form__multi-select-styles__text-family-montserrat {
  font-family: Montserrat, sans-serif;
}

.ladder_ux_form__multi-select-styles__text-family-tt-norms {
  font-family: TTNorms, sans-serif;
}

.ladder_ux_form__multi-select-styles__text-family-larsseit {
  font-family: Larsseit, sans-serif;
}

.ladder_ux_form__multi-select-styles__text-family-sailec {
  font-family: Sailec, sans-serif;
}

.ladder_ux_form__multi-select-styles__root {
  appearance: none;
  max-width: 100%;
  height: 46px;
  -ms-appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: none;
  vertical-align: middle;
  padding: 0 0 0 0.5em;
  outline: 0;
  font-weight: 300;
  font-size: inherit;
  background-color: #FFFFFF;
  flex: 1;
  color: #000000;
}

.ladder_ux_form__multi-select-styles__root::-ms-expand {
  display: none;
}

.ladder_ux_form__multi-select-styles__showing-placeholder {
  color: #707070;
}

.ladder_ux_form__multi-select-styles__showing-placeholder.ladder_ux_form__multi-select-styles__placeholder-error {
  color: #CB3A3A;
}

.ladder_ux_form__multi-select-styles__root-text-input {
  height: auto;
}

.ladder_ux_form__multi-select-styles__height-s {
  height: 40px !important;
  font-size: 12px;
}

.ladder_ux_form__multi-select-styles__height-m {
  height: 48px !important;
  font-size: 14px;
}

.ladder_ux_form__multi-select-styles__multi-select>div.ladder_ux_form__multi-select-styles__multi-select-dropdown {
  width: calc(100% + 4px);
  border-width: 2px;
  top: 52px;
  border-style: solid;
  position: absolute;
  z-index: 101;
  background-color: #FFFFFF;
  padding-left: 0px;
  left: -2px;
  border-top-style: none;
}

.ladder_ux_form__multi-select-styles__multi-select-option {
  border-bottom-style: solid;
  text-transform: capitalize;
  border-bottom-width: 1px;
  padding: 0 20px;
}

.ladder_ux_form__multi-select-styles__multi-select-option:first-child {
  border-top-style: solid;
  border-top-width: 1px;
}

.ladder_ux_form__multi-select-styles__multi-select-overlay {
  background-color: transparent;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: block;
  z-index: 100;
}

@media (min-width: 1025px) {

  
  
  .ladder_ux_form__multi-select-styles__multi-select.ladder_ux_form__multi-select-styles__multi-select-border-container.ladder_ux_form__multi-select-styles__rungs {
    height: 100%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_form__multi-select-styles__multi-select.ladder_ux_form__multi-select-styles__multi-select-border-container.ladder_ux_form__multi-select-styles__rungs {
    height: 100%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_form__multi-select-styles__multi-select.ladder_ux_form__multi-select-styles__multi-select-border-container.ladder_ux_form__multi-select-styles__rungs {
    height: 56px;
    margin-top: 12px;
  }

}

.ladder_ux_form__multi-select-styles__border-container.ladder_ux_form__multi-select-styles__rungs.ladder_ux_form__multi-select-styles__multi-select-focused {

}

.ladder_ux_form__multi-select-styles__border-container.ladder_ux_form__multi-select-styles__rungs.ladder_ux_form__multi-select-styles__multi-select-focused:after {
  content: '\00A0\00A0';
  font-family: Arial;
  background-size: 100% 100%;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 10 10' xmlns='http://www.w3.org/2000/svg'%3E%3Ctext x='0' y='10' fill=' %23707070 ' font-family='Arial'%3E▾%3C/text%3E%3C/svg%3E");
  background-origin: content-box;
  transform: rotate(180deg);
  border: none;
}

.ladder_ux_form__multi-select-styles__multi-select-accept-button {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 11px;
}

.ladder_ux_form__multi-select-styles__multi-select-placeholder-text {
  text-transform: capitalize;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ladder_ux_form__multi-select-styles__pointer {
  cursor: pointer;
}

.ladder_ux_form__multi-select-styles__border-container.ladder_ux_form__multi-select-styles__rungs {
  height: 64px;
  align-items: center;
}

.ladder_ux_form__multi-select-styles__border-container.ladder_ux_form__multi-select-styles__rungs:after {
  content: '\00A0\00A0';
  font-family: Arial;
  background-size: 100% 100%;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 10 10' xmlns='http://www.w3.org/2000/svg'%3E%3Ctext x='0' y='10' fill=' %23707070 ' font-family='Arial'%3E▾%3C/text%3E%3C/svg%3E");
  background-origin: content-box;
  border: none;
}

.ladder_ux_form__multi-select-styles__border-container.ladder_ux_form__multi-select-styles__rungs>* {
  padding-left: 12.0px;
}

.ladder_ux_form__multi-select-styles__border-container.ladder_ux_form__multi-select-styles__rungs select {
  height: 100%;
}

.ladder_ux_form__multi-select-styles__border-container.ladder_ux_form__multi-select-styles__rungs .ladder_ux_form__multi-select-styles__floating-placeholder-wrapper {
  padding-right: 0;
  width: calc(100% - 25px);
  display: flex;
  align-items: center;
}

.ladder_ux_form__multi-select-styles__border-container.ladder_ux_form__multi-select-styles__rungs .ladder_ux_form__multi-select-styles__floating-placeholder-wrapper-isfloating {
  display: block;
}

.ladder_ux_form__multi-select-styles__border-container.ladder_ux_form__multi-select-styles__rungs .ladder_ux_form__multi-select-styles__floating-placeholder-wrapper>* {
  top: 0;
}

.ladder_ux_form__multi-select-styles__border-container.ladder_ux_form__multi-select-styles__rungs .ladder_ux_form__multi-select-styles__floating-placeholder-wrapper-isfloating>* {
  top: 8px;
}

.ladder_ux_form__multi-select-styles__border-container.ladder_ux_form__multi-select-styles__rungs.ladder_ux_form__multi-select-styles__height-m .ladder_ux_form__multi-select-styles__floating-placeholder-wrapper-isfloating>* {
  top: 0;
}

.ladder_ux_form__multi-select-styles__border-container.ladder_ux_form__multi-select-styles__rungs.ladder_ux_form__multi-select-styles__height-s .ladder_ux_form__multi-select-styles__floating-placeholder-wrapper-isfloating>* {
  top: 0;
}

.ladder_ux_form__multi-select-styles__border-error.ladder_ux_form__multi-select-styles__rungs:after {
  color: initial;
}

.box-fields .ladder_ux_form__multi-select-styles__border-container {
  border-radius: 2.0px;
  overflow: hidden;
}

.box-fields .ladder_ux_form__multi-select-styles__border-container select {
  border-radius: 0;
  padding-left: 14px;
}

.box-fields .ladder_ux_form__multi-select-styles__border-container::after {
  border: none;
  background-size: 8px 5px;
}
.ladder_requestion_review_ui__HypertensionReQBanner-styles__inner-banner-container {
  max-width: 600px;
  padding-left: 6%;
  padding-right: 6%;
}

.ladder_requestion_review_ui__HypertensionReQBanner-styles__header-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.ladder_requestion_review_ui__HypertensionReQBanner-styles__entire-banner {
  display: flex;
  justify-content: center;
}

@media (min-width: 1025px) {

  
  
  .ladder_requestion_review_ui__HypertensionReQBanner-styles__header-container {
    margin-bottom: 5px;
  }

}
.ladder_account_next_views_beneficiaries_edit__edit-beneficiary-buttons-styles__buttons {
  margin-top: 40px;
  display: flex;
}

@media (max-width: 499px) {

  
  
  .ladder_account_next_views_beneficiaries_edit__edit-beneficiary-buttons-styles__buttons {
    margin-top: 15.0px;
  }

}

.ladder_account_next_views_beneficiaries_edit__edit-beneficiary-buttons-styles__buttons>*:first-child {
  margin-right: 20px;
}

.ladder_account_next_views_beneficiaries_edit__edit-beneficiary-buttons-styles__save-button {
  max-width: 101px;
}

.ladder_account_next_views_beneficiaries_edit__edit-beneficiary-buttons-styles__cancel-button {
  max-width: 117px;
}
@media (max-width: 499px) {

  
  
  .ladder_ux_typography__deprecated-min-props-allowed-styles__body-1 {
    font-size: 15px;
    line-height: 30px;
  }
  
  .ladder_ux_typography__deprecated-min-props-allowed-styles__body-2 {
    font-size: 12px;
    line-height: 18px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_ux_typography__deprecated-min-props-allowed-styles__body-1 {
    font-size: 20px;
    line-height: 35px;
  }
  
  .ladder_ux_typography__deprecated-min-props-allowed-styles__body-2 {
    font-size: 15px;
    line-height: 30px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_typography__deprecated-min-props-allowed-styles__body-1 {
    font-size: 20px;
    line-height: 35px;
  }
  
  .ladder_ux_typography__deprecated-min-props-allowed-styles__body-2 {
    font-size: 15px;
    line-height: 30px;
  }

}

.ladder_ux_typography__deprecated-min-props-allowed-styles__npq-price {
  font-size: 55px;
  line-height: 75px;
  font-weight: 700;
}

.ladder_ux_typography__deprecated-min-props-allowed-styles__npq-price-mobile {
  font-size: 40px;
  line-height: 65px;
  font-weight: 700;
}

.box-fields .ladder_ux_typography__deprecated-min-props-allowed-styles__npq-price {
  font-weight: 400;
}

.ladder_ux_typography__deprecated-min-props-allowed-styles__desktop-body-1 {
  font-size: 20px;
  line-height: 35px;
}

.ladder_ux_typography__deprecated-min-props-allowed-styles__desktop-body-2 {
  font-size: 15px;
  line-height: 30px;
}

.ladder_ux_typography__deprecated-min-props-allowed-styles__desktop-h3 {
  font-size: 30px;
  line-height: 45px;
  font-weight: 600;
}
.ladder_aimcor_compare__TermVsWhole-styles__wrapper {
  display: flex;
  align-items: center;
  background-color: #E7EEEF;
  border: solid 2px #CFDCDE;
  border-radius: 10px;
  padding: 15.0px;
}

.ladder_aimcor_compare__TermVsWhole-styles__icon {
  min-width: 20px;
  margin-right: 10.0px;
}

.ladder_aimcor_compare__TermVsWhole-styles__compare-link {
  margin-left: 5.0px;
}
.ladder_ux_logos__ladder-plus-partner-logo-styles__container {
  display: flex;
  align-items: center;
}

.ladder_ux_logos__ladder-plus-partner-logo-styles__partner-logo {
  height: 100px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_logos__ladder-plus-partner-logo-styles__partner-logo {
    height: 60px;
  }
  
  .ladder_ux_logos__ladder-plus-partner-logo-styles__ladder-logo {
    width: 120px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_logos__ladder-plus-partner-logo-styles__partner-logo {
    height: 60px;
  }
  
  .ladder_ux_logos__ladder-plus-partner-logo-styles__ladder-logo {
    width: 141px;
  }

}
.ladder_views_payment__ach-balance-warning-styles__root {
  width: 100%;
  max-width: 800px;
}

.ladder_views_payment__ach-balance-warning-styles__text-container {
  max-width: 725px;
}

.ladder_views_payment__ach-balance-warning-styles__accept-button-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: left;
  width: 100%;
}

@media (max-width: 499px) {

  
  
  .ladder_views_payment__ach-balance-warning-styles__accept-button-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
  }

}

.ladder_views_payment__ach-balance-warning-styles__secondary-cta {
  display: block;
  margin-left: 10px;
}

.ladder_views_payment__ach-balance-warning-styles__bold {
  font-weight: bold;
}
.ladder_account_next_views_policy__RungsPolicyCard-styles__root {
  margin-top: 16px;
}
.ladder_guide_life_insurance_guide__get-you-covered-panel-styles__color-container {
  width: 100%;
  overflow: visible;
  background-color: #F7F2EF;
  z-index: 1;
}

.ladder_guide_life_insurance_guide__get-you-covered-panel-styles__image {
  margin-bottom: -72px;
  margin-top: 50px;
}

.ladder_guide_life_insurance_guide__get-you-covered-panel-styles__read-text-container {
  margin-left: 20px;
  vertical-align: top;
}

.ladder_guide_life_insurance_guide__get-you-covered-panel-styles__heading {
  margin-bottom: 20px;
  margin-top: 30.0px;
}

.ladder_guide_life_insurance_guide__get-you-covered-panel-styles__summary-text {
  margin-bottom: 30.0px;
}

@media (min-width: 1025px) {

  
  
  .ladder_guide_life_insurance_guide__get-you-covered-panel-styles__container {
    display: flex;
    margin-top: 130.0px;
    overflow: visible;
    justify-content: space-around;
  }
  
  .ladder_guide_life_insurance_guide__get-you-covered-panel-styles__action-box {
    flex-basis: 48%;
    margin-bottom: 30.0px;
  }
  
  .ladder_guide_life_insurance_guide__get-you-covered-panel-styles__image-container {
    display: flex;
    justify-content: flex-start;
    align-self: flex-end;
    overflow: visible;
    align-items: flex-end;
    flex-basis: 32%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_guide_life_insurance_guide__get-you-covered-panel-styles__container {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  
  .ladder_guide_life_insurance_guide__get-you-covered-panel-styles__image {
    height: auto;
    max-width: 100%;
  }
  
  .ladder_guide_life_insurance_guide__get-you-covered-panel-styles__action-box {
    flex-direction: column;
    flex-basis: unset;
    padding-right: 0;
    max-width: 90%;
    margin: 0;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_guide_life_insurance_guide__get-you-covered-panel-styles__container {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  
  .ladder_guide_life_insurance_guide__get-you-covered-panel-styles__image {
    height: auto;
    max-width: 100%;
  }
  
  .ladder_guide_life_insurance_guide__get-you-covered-panel-styles__action-box {
    flex-direction: column;
    flex-basis: unset;
    padding-right: 0;
    max-width: 90%;
    margin: 0;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_guide_life_insurance_guide__get-you-covered-panel-styles__color-container {
    margin-top: 130.0px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_guide_life_insurance_guide__get-you-covered-panel-styles__color-container {
    margin-top: 130.0px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_guide_life_insurance_guide__get-you-covered-panel-styles__action-box {
    padding-top: 60px;
    max-width: 80%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_guide_life_insurance_guide__get-you-covered-panel-styles__image-container {
    margin-left: 20px;
    margin-right: 20px;
  }
  
  .ladder_guide_life_insurance_guide__get-you-covered-panel-styles__action-box {
    margin: 30.0px;
  }
  
  .ladder_guide_life_insurance_guide__get-you-covered-panel-styles__heading {
    margin: 10.0px 0;
  }
  
  .ladder_guide_life_insurance_guide__get-you-covered-panel-styles__image {
    margin-bottom: -140px;
  }

}
.ladder_account_next_views_beneficiaries_shares__checkbox-row-styles__\% {
  display: flex;
  justify-content: space-between;
}

.ladder_account_next_views_beneficiaries_shares__checkbox-row-styles__slider {
  min-width: 60px;
}
.ladder_form_traffic_diversion__explain-options-component-styles__root {
  margin-top: 48px;
  margin-bottom: 30.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_form_traffic_diversion__explain-options-component-styles__root {
    margin-top: 24px;
  }

}

.ladder_form_traffic_diversion__explain-options-component-styles__p {
  margin-bottom: 20px;
}
.ladder_agents_dashboard_inputs__input-field-styles__input-wrapper {
  display: flex;
  align-items: center;
  margin-top: 1em;
  padding: 1em;
  width: 100%;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 24px;
}

.ladder_agents_dashboard_inputs__input-field-styles__medium-font-size {
  font-size: 13px;
  font-weight: 500;
}

.ladder_agents_dashboard_inputs__input-field-styles__input-label {
  color: grey;
  font-size: 12px;
  padding: 1px 2px;
  margin-bottom: 1em;
}

.ladder_agents_dashboard_inputs__input-field-styles__white-input-background {
  background-color: white;
}

.ladder_agents_dashboard_inputs__input-field-styles__icon {
  margin-right: 10px;
}

.ladder_agents_dashboard_inputs__input-field-styles__disabled-input-background {
  background-color: #F6F6F6;
  color: #636363;
}
.ladder_ux_form__text-common-styles__background-color-transparent {

}

.ladder_ux_form__text-common-styles__background-color-white {
  background-color: #FFFFFF;
}

.ladder_ux_form__text-common-styles__background-color-background-gray {
  background-color: #F4F4F4;
}

.ladder_ux_form__text-common-styles__background-color-light-gray {
  background-color: #D0D0D0;
}

.ladder_ux_form__text-common-styles__background-color-medium-gray {
  background-color: #707070;
}

.ladder_ux_form__text-common-styles__width-full {
  width: 100%;
}

.ladder_ux_form__text-common-styles__width-s {
  width: 100%;
}

.ladder_ux_form__text-common-styles__height-s {
  height: 32px;
}

.ladder_ux_form__text-common-styles__height-sm {
  height: 40.0px;
}

.ladder_ux_form__text-common-styles__height-m {
  height: 48px;
}

.ladder_ux_form__text-common-styles__height-l {
  height: 64px;
}

.ladder_ux_form__text-common-styles__height-xl {
  height: 128px;
}

.ladder_ux_form__text-common-styles__height-xxl {
  height: 192px;
}

.ladder_ux_form__text-common-styles__min-height-s {
  min-height: 32px;
}

.ladder_ux_form__text-common-styles__min-height-sm {
  min-height: 40.0px;
}

.ladder_ux_form__text-common-styles__min-height-m {
  min-height: 48px;
}

.ladder_ux_form__text-common-styles__min-height-l {
  min-height: 64px;
}

.ladder_ux_form__text-common-styles__min-height-xl {
  min-height: 128px;
}

.ladder_ux_form__text-common-styles__min-height-xxl {
  min-height: 192px;
}

.ladder_ux_form__text-common-styles__left-right-padding-xs {
  padding: 0 2.0px;
}

.ladder_ux_form__text-common-styles__left-right-padding-s {
  padding: 0 8.0px;
}

.ladder_ux_form__text-common-styles__left-right-padding-m {
  padding: 0 12.0px;
}

.ladder_ux_form__text-common-styles__left-right-padding-l {
  padding: 0 16px;
}

.ladder_ux_form__text-common-styles__border-none {

}

.ladder_ux_form__text-common-styles__border-bottom {
  border-bottom: 1px solid #707070;
}

.ladder_ux_form__text-common-styles__border-full {
  border: 1px solid #707070;
}

.ladder_ux_form__text-common-styles__border-width-s {
  border-width: 1px;
}

.ladder_ux_form__text-common-styles__border-width-m {
  border-width: 2px;
}

.ladder_ux_form__text-common-styles__border-color-white {
  border-color: #FFFFFF;
}

.ladder_ux_form__text-common-styles__border-color-background-gray {
  border-color: #F4F4F4;
}

.ladder_ux_form__text-common-styles__border-color-light-gray {
  border-color: #D0D0D0;
}

.ladder_ux_form__text-common-styles__border-color-medium-gray {
  border-color: #707070;
}

.ladder_ux_form__text-common-styles__border-color-black {
  border-color: #000000;
}

.ladder_ux_form__text-common-styles__border-color-red {
  border-color: #FE4747;
}

.ladder_ux_form__text-common-styles__border-color-my-pink {
  border-color: #CF967E;
}

.ladder_ux_form__text-common-styles__text-alignment-left {
  text-align: left;
}

.ladder_ux_form__text-common-styles__text-alignment-center {
  text-align: center;
}

.ladder_ux_form__text-common-styles__text-alignment-right {
  text-align: right;
}

.ladder_ux_form__text-common-styles__text-color-white {
  color: #FFFFFF;
  -webkit-text-fill-color: #FFFFFF;
}

.ladder_ux_form__text-common-styles__text-color-background-gray {
  color: #F4F4F4;
}

.ladder_ux_form__text-common-styles__text-color-light-gray {
  color: #D0D0D0;
}

.ladder_ux_form__text-common-styles__text-color-medium-gray {
  color: #707070;
}

.ladder_ux_form__text-common-styles__text-color-black {
  color: #000000;
}

.ladder_ux_form__text-common-styles__text-color-red {
  color: #FE4747;
}

.ladder_ux_form__text-common-styles__placeholder-color-white::placeholder {
  color: #FFFFFF;
  opacity: 1;
}

.ladder_ux_form__text-common-styles__placeholder-color-light-gray::placeholder {
  color: #D0D0D0;
  opacity: 1;
}

.ladder_ux_form__text-common-styles__placeholder-color-medium-gray::placeholder {
  color: #707070;
  opacity: 1;
}

.ladder_ux_form__text-common-styles__text-family-helvetica {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.ladder_ux_form__text-common-styles__text-family-lato {
  font-family: Lato, sans-serif;
}

.ladder_ux_form__text-common-styles__text-family-montserrat {
  font-family: Montserrat, sans-serif;
}

.ladder_ux_form__text-common-styles__text-family-work-sans {
  font-family: Work Sans, sans-serif;
}

.ladder_ux_form__text-common-styles__text-family-tt-norms {
  font-family: TTNorms, sans-serif;
}

.ladder_ux_form__text-common-styles__text-family-larsseit {
  font-family: Larsseit, sans-serif;
}

.ladder_ux_form__text-common-styles__text-family-sailec {
  font-family: Sailec, sans-serif;
}

.ladder_ux_form__text-common-styles__text-weight-light {
  font-weight: 300;
}

.ladder_ux_form__text-common-styles__text-weight-light-medium {
  font-weight: 400;
}

.ladder_ux_form__text-common-styles__text-weight-medium {
  font-weight: 500;
}

.ladder_ux_form__text-common-styles__text-size-xs {
  font-size: 12px;
}

.ladder_ux_form__text-common-styles__text-size-13 {
  font-size: 13px;
}

.ladder_ux_form__text-common-styles__text-size-s {
  font-size: 16px;
}

.ladder_ux_form__text-common-styles__text-size-m {
  font-size: 20px;
}

.ladder_ux_form__text-common-styles__border-remove-border {
  border: none;
}

@media (min-width: 1025px) {

  
  
  .ladder_ux_form__text-common-styles__width-m {
    width: 500px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_form__text-common-styles__width-m {
    width: 70%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_form__text-common-styles__width-m {
    width: 70%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_form__text-common-styles__text-size-s {
    font-size: 14px;
  }
  
  .ladder_ux_form__text-common-styles__text-size-m {
    font-size: 16px;
  }
  
  .ladder_ux_form__text-common-styles__height-xxl {
    height: 144px;
  }

}

.box-fields .ladder_ux_form__text-common-styles__border-full {
  border-radius: 2.0px;
  overflow: hidden;
}
.ladder_account_next_views_gift__gift-selection-page-header-styles__wrapper {
  display: flex;
  margin-bottom: 32px;
}

.ladder_account_next_views_gift__gift-selection-page-header-styles__text-wrapper {
  padding-right: 16px;
  padding-bottom: 64px;
}

.ladder_account_next_views_gift__gift-selection-page-header-styles__congrats-image {
  width: 30%;
}

@media (max-width: 499px) {

  
  
  .ladder_account_next_views_gift__gift-selection-page-header-styles__wrapper {
    margin: 24.0px 16px;
  }
  
  .ladder_account_next_views_gift__gift-selection-page-header-styles__wrapper>*:first-child {
    padding-bottom: 0;
  }
  
  .ladder_account_next_views_gift__gift-selection-page-header-styles__header {
    padding-bottom: 16px;
  }

}
.ladder_views_decision_common__price-section-styles__wrapper {
  min-height: 80px;
  display: flex;
  flex-direction: column;
}

.ladder_views_decision_common__price-section-styles__spinner-wrapper {
  display: flex;
  margin-bottom: 10.0px;
}

.ladder_views_decision_common__price-section-styles__spinner-wrapper>div {
  justify-content: flex-start;
}

@media (max-width: 499px) {

  
  
  .ladder_views_decision_common__price-section-styles__wrapper {
    min-height: 55px;
  }

}
.ladder_apply_incentivized_traffic_terms_modal__IncentivizedTrafficPaymentTermsAgreementModal-styles__text-l {
  margin-bottom: 20px;
}

.ladder_apply_incentivized_traffic_terms_modal__IncentivizedTrafficPaymentTermsAgreementModal-styles__text-m {
  margin-bottom: 30.0px;
}

.ladder_apply_incentivized_traffic_terms_modal__IncentivizedTrafficPaymentTermsAgreementModal-styles__button {
  margin-bottom: 20px;
}

.ladder_apply_incentivized_traffic_terms_modal__IncentivizedTrafficPaymentTermsAgreementModal-styles__terms {
  margin: 20px;
  text-align: left;
}
.ladder_agents_dashboard_learn__learn-page-styles__png-icon {
  width: 44px;
  height: 44px;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard_learn__learn-page-styles__share-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    padding: 4em 0;
  }
  
  .ladder_agents_dashboard_learn__learn-page-styles__learn-group-wrapper {
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    margin-top: 1em;
    border-radius: 24px;
  }
  
  .ladder_agents_dashboard_learn__learn-page-styles__learn-row {
    min-width: 23em;
    width: 350px;
    border-width: 1px;
    justify-content: space-between;
    height: 84px;
    align-items: center;
    padding: 1em 1.5em 1em;
    flex-direction: row;
    cursor: pointer;
    background-color: #FFFFFF;
    border-radius: 24px;
    display: flex;
  }
  
  .ladder_agents_dashboard_learn__learn-page-styles__learn-row-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
  }
  
  .ladder_agents_dashboard_learn__learn-page-styles__learn-expanded-row-hr {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    border: 0;
    border-top: 1px solid lightgray;
  }
  
  .ladder_agents_dashboard_learn__learn-page-styles__learn-row+.ladder_agents_dashboard_learn__learn-page-styles__learn-expanded-row {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
  .ladder_agents_dashboard_learn__learn-page-styles__learn-expanded-row {
    min-width: 24em;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;
    flex-direction: column;
    margin-top: 0;
    border-top: 0;
    background-color: #FFFFFF;
    border-radius: 0;
    display: flex;
    padding-top: 0;
  }
  
  .ladder_agents_dashboard_learn__learn-page-styles__learn-expanded-row-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em 1.5em 1em;
    gap: 2em;
  }
  
  .ladder_agents_dashboard_learn__learn-page-styles__learn-expanded-row:last-child {
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
  }
  
  .ladder_agents_dashboard_learn__learn-page-styles__learn-expanded-row+.ladder_agents_dashboard_learn__learn-page-styles__learn-row {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_agents_dashboard_learn__learn-page-styles__share-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4em;
  }
  
  .ladder_agents_dashboard_learn__learn-page-styles__learn-group-wrapper {
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    margin-top: 1em;
    border-radius: 24px;
  }
  
  .ladder_agents_dashboard_learn__learn-page-styles__learn-row {
    min-width: 32em;
    width: 600px;
    border-width: 1px;
    justify-content: space-between;
    height: 84px;
    align-items: center;
    padding: 1em 1.5em 1em;
    flex-direction: row;
    cursor: pointer;
    background-color: #FFFFFF;
    border-radius: 24px;
    display: flex;
  }
  
  .ladder_agents_dashboard_learn__learn-page-styles__learn-row-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2em;
  }
  
  .ladder_agents_dashboard_learn__learn-page-styles__learn-expanded-row-hr {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    border: 0;
    border-top: 1px solid lightgray;
  }
  
  .ladder_agents_dashboard_learn__learn-page-styles__learn-row+.ladder_agents_dashboard_learn__learn-page-styles__learn-expanded-row {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
  .ladder_agents_dashboard_learn__learn-page-styles__learn-expanded-row {
    min-width: 30em;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;
    flex-direction: column;
    margin-top: 0;
    border-top: 0;
    background-color: #FFFFFF;
    border-radius: 0;
    display: flex;
    padding-top: 0;
  }
  
  .ladder_agents_dashboard_learn__learn-page-styles__learn-expanded-row-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em 1.5em 1em;
    gap: 2em;
  }
  
  .ladder_agents_dashboard_learn__learn-page-styles__learn-expanded-row:last-child {
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
  }
  
  .ladder_agents_dashboard_learn__learn-page-styles__learn-expanded-row+.ladder_agents_dashboard_learn__learn-page-styles__learn-row {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_dashboard_learn__learn-page-styles__share-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    padding: 4em 0;
  }
  
  .ladder_agents_dashboard_learn__learn-page-styles__learn-group-wrapper {
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    margin-top: 1em;
    border-radius: 24px;
  }
  
  .ladder_agents_dashboard_learn__learn-page-styles__learn-row {
    min-width: 23em;
    width: 350px;
    border-width: 1px;
    justify-content: space-between;
    height: 84px;
    align-items: center;
    padding: 1em 1.5em 1em;
    flex-direction: row;
    cursor: pointer;
    background-color: #FFFFFF;
    border-radius: 24px;
    display: flex;
  }
  
  .ladder_agents_dashboard_learn__learn-page-styles__learn-row-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
  }
  
  .ladder_agents_dashboard_learn__learn-page-styles__learn-expanded-row-hr {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    border: 0;
    border-top: 1px solid lightgray;
  }
  
  .ladder_agents_dashboard_learn__learn-page-styles__learn-row+.ladder_agents_dashboard_learn__learn-page-styles__learn-expanded-row {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
  .ladder_agents_dashboard_learn__learn-page-styles__learn-expanded-row {
    min-width: 24em;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;
    flex-direction: column;
    margin-top: 0;
    border-top: 0;
    background-color: #FFFFFF;
    border-radius: 0;
    display: flex;
    padding-top: 0;
  }
  
  .ladder_agents_dashboard_learn__learn-page-styles__learn-expanded-row-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em 1.5em 1em;
    gap: 2em;
  }
  
  .ladder_agents_dashboard_learn__learn-page-styles__learn-expanded-row:last-child {
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
  }
  
  .ladder_agents_dashboard_learn__learn-page-styles__learn-expanded-row+.ladder_agents_dashboard_learn__learn-page-styles__learn-row {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_agents_dashboard_learn__learn-page-styles__share-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 4em;
  }
  
  .ladder_agents_dashboard_learn__learn-page-styles__learn-group-wrapper {
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    margin-top: 1em;
    border-radius: 24px;
  }
  
  .ladder_agents_dashboard_learn__learn-page-styles__learn-row {
    min-width: 32em;
    width: 600px;
    border-width: 1px;
    justify-content: space-between;
    height: 84px;
    align-items: center;
    padding: 1em 1.5em 1em;
    flex-direction: row;
    cursor: pointer;
    background-color: #FFFFFF;
    border-radius: 24px;
    display: flex;
  }
  
  .ladder_agents_dashboard_learn__learn-page-styles__learn-row-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2em;
  }
  
  .ladder_agents_dashboard_learn__learn-page-styles__learn-expanded-row-hr {
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    border: 0;
    border-top: 1px solid lightgray;
  }
  
  .ladder_agents_dashboard_learn__learn-page-styles__learn-row+.ladder_agents_dashboard_learn__learn-page-styles__learn-expanded-row {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  
  .ladder_agents_dashboard_learn__learn-page-styles__learn-expanded-row {
    min-width: 30em;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0;
    flex-direction: column;
    margin-top: 0;
    border-top: 0;
    background-color: #FFFFFF;
    border-radius: 0;
    display: flex;
    padding-top: 0;
  }
  
  .ladder_agents_dashboard_learn__learn-page-styles__learn-expanded-row-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em 1.5em 1em;
    gap: 2em;
  }
  
  .ladder_agents_dashboard_learn__learn-page-styles__learn-expanded-row:last-child {
    border-bottom-left-radius: 24px;
    border-bottom-right-radius: 24px;
  }
  
  .ladder_agents_dashboard_learn__learn-page-styles__learn-expanded-row+.ladder_agents_dashboard_learn__learn-page-styles__learn-row {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

}
.ladder_form_quick_estimate__quick-estimate-group-styles__with-ladder {
  white-space: nowrap;
  display: flex;
  justify-content: center;
}

.ladder_form_quick_estimate__quick-estimate-group-styles__banner {
  text-align: center;
  padding-bottom: 22.5px;
}

.ladder_form_quick_estimate__quick-estimate-group-styles__banner-guide {
  text-align: left;
  padding-bottom: 22.5px;
}

.ladder_form_quick_estimate__quick-estimate-group-styles__form-fields {
  margin: 25.0px 0;
}

.ladder_form_quick_estimate__quick-estimate-group-styles__form-fields>div {
  margin-bottom: 10.0px;
}

.ladder_form_quick_estimate__quick-estimate-group-styles__row {
  display: flex;
  justify-content: space-between;
}

.ladder_form_quick_estimate__quick-estimate-group-styles__row > *:not(:last-child) {
  margin-right: 20px;
}

.ladder_form_quick_estimate__quick-estimate-group-styles__row>div {
  width: 100%;
}

@media (min-width: 1025px) {

  
  
  .ladder_form_quick_estimate__quick-estimate-group-styles__form-fields {
    margin: 20px 0 30.0px 0;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_form_quick_estimate__quick-estimate-group-styles__row {
    display: block;
  }
  
  .ladder_form_quick_estimate__quick-estimate-group-styles__row>div {
    margin-bottom: 10.0px;
  }

}
.ladder_requestion_post_sign_questions__re-question-quit-modal-styles__ctas {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin: 0 30px 30px 30px;
  gap: 15px;
}
.ladder_financial_advisors_home__allianz-panel-styles__\% {
  width: 100%;
  margin-top: 80px;
  padding: 0 5.5% 80px 5.5%;
}

@media (min-width: 1025px) {

  
  
  .ladder_financial_advisors_home__allianz-panel-styles__\% {
    max-width: 1200px;
    margin: 120px auto 0 auto;
    padding: 0 80px 90px 80px;
    display: flex;
  }

}
.ladder_onboarding_referrals__MegaphoneDetails-styles__container {
  display: flex;
  flex-direction: column;
}

.ladder_onboarding_referrals__MegaphoneDetails-styles__terms {
  margin-top: 100px;
  background-color: rgba(209,169,143,0.25);
  padding: 15.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_onboarding_referrals__MegaphoneDetails-styles__header {
    font-size: 30.0px;
  }
  
  .ladder_onboarding_referrals__MegaphoneDetails-styles__container {
    justify-content: center;
    align-content: center;
    padding: 0 25.0px;
  }
  
  .ladder_onboarding_referrals__MegaphoneDetails-styles__description {
    display: flex;
    text-align: center;
    justify-content: center;
  }
  
  .ladder_onboarding_referrals__MegaphoneDetails-styles__terms {
    margin-top: 40px;
  }

}
.ladder_onboarding_referrals__number-circle-with-squiggly-styles__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

@media (max-width: 499px) {



}
.ladder_account_next_cards_your_beneficiaries__beneficiary-item-person-styles__\% {
  display: flex;
  flex-direction: column;
  margin: 0 0 25.0px 0;
}

.ladder_account_next_cards_your_beneficiaries__beneficiary-item-person-styles__\% *:first-child {
  margin-bottom: 5.0px;
}
.ladder_views_decision_offer__gift-logos-styles__logo {
  height: auto;
  min-width: 24%;
  max-width: 28%;
}

@media (max-width: 499px) {

  
  
  .ladder_views_decision_offer__gift-logos-styles__logo-margin {
    margin: 20px 0;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_views_decision_offer__gift-logos-styles__logo-margin {
    margin: 20px 20px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_views_decision_offer__gift-logos-styles__logo-margin {
    margin: 20px 20px;
  }

}

.ladder_views_decision_offer__gift-logos-styles__multiple-logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.ladder_views_decision_offer__gift-logos-styles__one-logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

@media (max-width: 499px) {

  
  
  .ladder_views_decision_offer__gift-logos-styles__one-logos {
    justify-content: space-around;
  }

}
.ladder_savings_steps_dory__get-app-interstitial-styles__background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #FFFFFF;
  z-index: 14;
}

.ladder_savings_steps_dory__get-app-interstitial-styles__wrapper {
  width: 100%;
  top: 0;
  height: 100%;
  align-items: center;
  flex-direction: column;
  position: fixed;
  z-index: 14;
  display: flex;
  pointer-events: auto;
  background: #E7EEEF;
  padding-top: 60px;
  left: 0;
}

.ladder_savings_steps_dory__get-app-interstitial-styles__back-button-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  padding: 20px;
}

.ladder_savings_steps_dory__get-app-interstitial-styles__back-button {
  width: 30px;
  justify-content: center;
  height: 30px;
  align-items: center;
  padding: 7px;
  box-shadow: 0px 4px 4px rgba(51, 46, 84, 0.05);
  background-color: #FFFFFF;
  border-radius: 50%;
  display: flex;
}

.ladder_savings_steps_dory__get-app-interstitial-styles__animated {
  animation-name: move-down;
  animation-duration: 500ms;
  animation-fill-mode: backwards;
  animation-delay: 400ms;
  animation-timing-function: ease-in-out;
}

.ladder_savings_steps_dory__get-app-interstitial-styles__blurb {
  background-color: #FFFFFF;
  width: 100%;
  padding: 30.0px 9% 30.0px 9%;
  position: fixed;
  left: 0;
  bottom: 0;
}

.ladder_savings_steps_dory__get-app-interstitial-styles__blurb .ladder_savings_steps_dory__get-app-interstitial-styles__animated {
  animation-delay: 600ms;
}

.ladder_savings_steps_dory__get-app-interstitial-styles__copy {
  margin: 15.0px 0 20px;
}

.ladder_savings_steps_dory__get-app-interstitial-styles__buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ladder_savings_steps_dory__get-app-interstitial-styles__buttons .ladder_savings_steps_dory__get-app-interstitial-styles__get-app {
  pointer-events: none;
}

.ladder_savings_steps_dory__get-app-interstitial-styles__buttons .ladder_savings_steps_dory__get-app-interstitial-styles__no-thanks {
  margin-top: 10.0px;
}
.ladder_trustage_instant_decline_v2__why-consider-trustage-styles__border {
  display: flex;
  margin-top: 25px;
  border: 2px solid #BDBDBD;
  border-radius: 10px;
  width: 100%;
}

.ladder_trustage_instant_decline_v2__why-consider-trustage-styles__inner-container {
  padding: 32px;
}

.ladder_trustage_instant_decline_v2__why-consider-trustage-styles__checkmark {
  min-width: 12px;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.ladder_trustage_instant_decline_v2__why-consider-trustage-styles__why-trustage-row {
  margin-top: 25px;
  display: flex;
  gap: 15px;
}

.ladder_trustage_instant_decline_v2__why-consider-trustage-styles__why-trustage-text {
  margin-top: 2px;
}

@media (max-width: 499px) {

  
  
  .ladder_trustage_instant_decline_v2__why-consider-trustage-styles__why-trustage-text {
    margin-top: -2px;
  }

}
.ladder_agents_dashboard_inputs__single-select-options-styles__button {
  width: 170px;
  border-width: 1px;
  border-color: #CCCCCC;
  height: 50px;
  padding: 0 20px;
  border-style: solid;
  cursor: pointer;
  background-color: transparent;
  border-radius: 24px;
  box-sizing: border-box;
}

.ladder_agents_dashboard_inputs__single-select-options-styles__button.ladder_agents_dashboard_inputs__single-select-options-styles__grow {
  width: auto;
  flex: 1;
}

.ladder_agents_dashboard_inputs__single-select-options-styles__button .ladder_agents_dashboard_inputs__single-select-options-styles__emoji {
  margin-right: 15px;
}

.ladder_agents_dashboard_inputs__single-select-options-styles__button .ladder_agents_dashboard_inputs__single-select-options-styles__emoji>span {
  font-size: 24px;
}

.ladder_agents_dashboard_inputs__single-select-options-styles__selected-button {
  border-color: #336FED;
  background-color: #FFFFFF;
}

.ladder_agents_dashboard_inputs__single-select-options-styles__selected-button .ladder_agents_dashboard_inputs__single-select-options-styles__checked {
  background-color: #336FED;
  min-width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.ladder_agents_dashboard_inputs__single-select-options-styles__error {
  border-color: #FE4747;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard_inputs__single-select-options-styles__button {
    width: 145px;
  }
  
  .ladder_agents_dashboard_inputs__single-select-options-styles__button.ladder_agents_dashboard_inputs__single-select-options-styles__grow {
    width: auto;
    flex: 1;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_dashboard_inputs__single-select-options-styles__button {
    width: 145px;
  }
  
  .ladder_agents_dashboard_inputs__single-select-options-styles__button.ladder_agents_dashboard_inputs__single-select-options-styles__grow {
    width: auto;
    flex: 1;
  }

}

.ladder_agents_dashboard_inputs__single-select-options-styles__button-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ladder_agents_agent_assisted_app_views_decision__manual-underwriting-body-styles__container {
  display: flex;
  flex-direction: column;
  gap: 36px;
}

.ladder_agents_agent_assisted_app_views_decision__manual-underwriting-body-styles__list {
  list-style: outside;
  padding-left: 1em;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_agent_assisted_app_views_decision__manual-underwriting-body-styles__list {
    font-size: 15px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_agent_assisted_app_views_decision__manual-underwriting-body-styles__list {
    font-size: 15px;
  }

}
.ladder_careers__ui-custom-role-styles__custom-role-button-container {
  margin-top: 50.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_careers__ui-custom-role-styles__custom-role-button-container {
    margin: 20px 0 0 0;
    width: 100%;
  }

}
.ladder_aimcor_compare__term-vs-whole-compare-styles__wrapper {
  width: 100%;
}

.ladder_aimcor_compare__term-vs-whole-compare-styles__column-headers {
  display: flex;
  width: 100%;
  margin-bottom: 10.0px;
}

.ladder_aimcor_compare__term-vs-whole-compare-styles__column-headers>* {
  width: 50%;
}

.ladder_aimcor_compare__term-vs-whole-compare-styles__sections {
  padding-bottom: 20px;
}

.ladder_aimcor_compare__term-vs-whole-compare-styles__section-title {
  background-color: #EFEAE1;
  border-radius: 20px;
  text-align: center;
}

.ladder_aimcor_compare__term-vs-whole-compare-styles__section-body {
  display: flex;
}

.ladder_aimcor_compare__term-vs-whole-compare-styles__section-body .ladder_aimcor_compare__term-vs-whole-compare-styles__term {
  width: 50%;
  padding: 15.0px 10.0px;
}

.ladder_aimcor_compare__term-vs-whole-compare-styles__section-body .ladder_aimcor_compare__term-vs-whole-compare-styles__whole {
  width: 50%;
  padding: 15.0px 10.0px;
}

.ladder_aimcor_compare__term-vs-whole-compare-styles__section-body .ladder_aimcor_compare__term-vs-whole-compare-styles__term {
  border-right: dashed 1px #EFEAE1;
}

.ladder_aimcor_compare__term-vs-whole-compare-styles__cost .ladder_aimcor_compare__term-vs-whole-compare-styles__term {
  padding-bottom: 0;
}

.ladder_aimcor_compare__term-vs-whole-compare-styles__cost .ladder_aimcor_compare__term-vs-whole-compare-styles__whole {
  padding-bottom: 0;
}

@media (max-width: 499px) {

  
  
  
  
  .ladder_aimcor_compare__term-vs-whole-compare-styles__section-body .ladder_aimcor_compare__term-vs-whole-compare-styles__term {
    padding-left: 0;
  }
  
  .ladder_aimcor_compare__term-vs-whole-compare-styles__section-body .ladder_aimcor_compare__term-vs-whole-compare-styles__whole {
    padding-right: 0;
  }
  
  
  
  .ladder_aimcor_compare__term-vs-whole-compare-styles__section-body.ladder_aimcor_compare__term-vs-whole-compare-styles__cost .ladder_aimcor_compare__term-vs-whole-compare-styles__term {
    padding-top: 0;
  }
  
  .ladder_aimcor_compare__term-vs-whole-compare-styles__section-body.ladder_aimcor_compare__term-vs-whole-compare-styles__cost .ladder_aimcor_compare__term-vs-whole-compare-styles__whole {
    padding-top: 0;
  }

}
.ladder_savings_steps_dory__cycle-ccw-styles__cycle {
  animation-name: step-icon-spin;
  animation-delay: inherit;
  animation-duration: inherit;
  animation-timing-function: inherit;
}

.ladder_savings_steps_dory__cycle-ccw-styles__cycle .arrow {
  animation-name: go-green;
  animation-delay: inherit;
  animation-duration: inherit;
  animation-timing-function: inherit;
}
.ladder_financial_advisors_view_contract__root-styles__root {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFFFFF;
}

.ladder_financial_advisors_view_contract__root-styles__agreement-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75%;
  margin-top: 100px;
}

.ladder_financial_advisors_view_contract__root-styles__agreement-header {
  margin-bottom: 40px;
  text-align: center;
}

.ladder_financial_advisors_view_contract__root-styles__agree-wrapper {
  background-color: #975C46;
  position: sticky;
  bottom: 0px;
  display: flex;
  width: 100%;
  height: 90px;
  align-items: center;
  justify-content: center;
}

.ladder_financial_advisors_view_contract__root-styles__agree-inner {
  width: 75%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ladder_financial_advisors_view_contract__root-styles__checkbox-container {
  display: flex;
  width: 100%;
  margin-right: 20px;
  align-items: center;
  padding: 20px 0;
  background-color: #FFFFFF;
  border: 2px solid#D9D9D9;
}

@media (max-width: 499px) {

  
  
  .ladder_financial_advisors_view_contract__root-styles__agree-wrapper {
    height: fit-content;
  }
  
  .ladder_financial_advisors_view_contract__root-styles__agree-inner {
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    padding: 10px 0px;
  }
  
  .ladder_financial_advisors_view_contract__root-styles__checkbox-container {
    padding: 10px 0px;
    padding-left: 20px;
    margin-right: 0px;
  }

}
@media (min-width: 1025px) {

  
  
  .ladder_account_next_views_beneficiaries_shares__buttons-styles__buttons {
    display: flex;
    margin-top: 40px;
  }
  
  .ladder_account_next_views_beneficiaries_shares__buttons-styles__buttons>*:first-child {
    margin-right: 20px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account_next_views_beneficiaries_shares__buttons-styles__buttons {
    display: flex;
    margin-top: 40px;
  }
  
  .ladder_account_next_views_beneficiaries_shares__buttons-styles__buttons>*:first-child {
    margin-right: 20px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_account_next_views_beneficiaries_shares__buttons-styles__buttons {
    margin-top: 20px;
  }
  
  .ladder_account_next_views_beneficiaries_shares__buttons-styles__buttons>*:last-child {
    margin-top: 20px;
  }

}
.ladder_financial_advisors_home__flexible-coverage-panel-styles__container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 80px;
  padding: 0 5.5%;
}

.ladder_financial_advisors_home__flexible-coverage-panel-styles__\% {
  background-color: #E7EEEF;
  width: 100%;
  max-width: 1280px;
  position: relative;
  padding: 40px 0;
}

@media (min-width: 1025px) {

  
  
  .ladder_financial_advisors_home__flexible-coverage-panel-styles__container {
    padding: 0 80px;
    margin-top: 120px;
  }
  
  .ladder_financial_advisors_home__flexible-coverage-panel-styles__\% {
    max-width: 1280px;
    display: flex;
    justify-content: space-between;
    padding: 0 calc(min(3%, 90px)) 0 0;
  }

}
.ladder_careers__root-styles__root {
  text-align: center;
  width: 100%;
  margin-top: 130.0px;
}

.ladder_careers__root-styles__root h1 {
  margin-bottom: 20px;
}

.ladder_careers__root-styles__root h2 {
  margin-bottom: 22.5px;
}

.ladder_careers__root-styles__root h4 {
  margin-bottom: 20px;
}

@media (max-width: 499px) {

  
  
  .ladder_careers__root-styles__root {
    margin-top: 50.0px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_careers__root-styles__root {
    margin-top: 50.0px;
  }

}
.ladder_newsletter_signup__signup-panel-styles__email-root {
  width: 60%;
}

.ladder_newsletter_signup__signup-panel-styles__text-root {
  width: 75%;
}

.ladder_newsletter_signup__signup-panel-styles__rungs-img-root {
  max-width: 35%;
  margin: 0;
}

.ladder_newsletter_signup__signup-panel-styles__img-root {
  max-width: 750px;
  margin: 0;
}

@media (max-width: 499px) {

  
  
  .ladder_newsletter_signup__signup-panel-styles__img-root {
    width: 90%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_newsletter_signup__signup-panel-styles__img-root {
    width: 90%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_newsletter_signup__signup-panel-styles__email-root {
    width: 100%;
  }
  
  .ladder_newsletter_signup__signup-panel-styles__text-root {
    width: 100%;
  }
  
  .ladder_newsletter_signup__signup-panel-styles__img-root {
    margin-top: 20px;
  }

}
.ladder_newsletter_signup__styles__email-root {
  width: 60%;
}

.ladder_newsletter_signup__styles__text-root {
  width: 75%;
}

@media (max-width: 499px) {

  
  
  .ladder_newsletter_signup__styles__email-root {
    width: 100%;
  }
  
  .ladder_newsletter_signup__styles__text-root {
    width: 100%;
  }

}
.ladder_agents_agent_assisted_app_views_review__maybe-hide-pii-wrapper-styles__pii-wrapper {
  width: 100%;
}
.ladder_financial_advisors_laddering__top-panel-image-styles__image {
  width: 45%;
  max-width: 750px;
  height: auto;
  position: absolute;
  bottom: 0;
  right: 0%;
  z-index: 0;
  align-self: center;
}

@media (max-width: 499px) {

  
  
  .ladder_financial_advisors_laddering__top-panel-image-styles__image-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .ladder_financial_advisors_laddering__top-panel-image-styles__image {
    position: relative;
    width: 80%;
    right: 0%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_financial_advisors_laddering__top-panel-image-styles__image-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .ladder_financial_advisors_laddering__top-panel-image-styles__image {
    position: relative;
    width: 80%;
    right: 0%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_financial_advisors_laddering__top-panel-image-styles__image-container {
    width: 164%;
    transform: translateX(-16%);
  }

}
.ladder_megaphone_landing__heads-panel-styles__root {
  display: flex;
  height: 500px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: linear-gradient(to bottom, #FFFFFF 0%,#FFFFFF 60%,#F4F4F4 60%,#F4F4F4 100%);
}

.ladder_megaphone_landing__heads-panel-styles__image {
  height: 100%;
  width: 90%;
  max-width: 900px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.ladder_megaphone_landing__heads-panel-styles__rungs-image {
  display: block;
  max-width: 400px;
  width: auto;
  height: auto;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_megaphone_landing__heads-panel-styles__root {
    height: 380px;
  }
  
  .ladder_megaphone_landing__heads-panel-styles__rungs-image {
    max-width: 300px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_megaphone_landing__heads-panel-styles__root {
    height: 380px;
  }
  
  .ladder_megaphone_landing__heads-panel-styles__rungs-image {
    max-width: 300px;
    width: 90%;
    margin: 0 auto;
  }

}
.ladder_agents_dashboard_pre_screener__answer-weight-styles__container {
  flex: 1;
  max-width: 209px;
}
.ladder_ux_info_panel__narrow-container-styles__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
}
.ladder_calculator_rebrand__input-wrapper-styles__root {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: left;
}

.ladder_calculator_rebrand__input-wrapper-styles__input {
  margin: 22.5px 0;
  position: relative;
}

.ladder_calculator_rebrand__input-wrapper-styles__label {
  margin: 0 0 22.5px 0;
}

.ladder_calculator_rebrand__input-wrapper-styles__text {
  margin: 0 0 10.0px 0;
}

.ladder_calculator_rebrand__input-wrapper-styles__error {
  padding-top: 5.0px;
  height: 4.0px;
  visibility: hidden;
}

.ladder_calculator_rebrand__input-wrapper-styles__show-error {
  visibility: visible;
}

.ladder_calculator_rebrand__input-wrapper-styles__input-height-s {
  margin: 20px 0;
}

.ladder_calculator_rebrand__input-wrapper-styles__label-height-s {
  margin: 0 0 10.0px 0;
}

@media (max-width: 499px) {

  
  
  .ladder_calculator_rebrand__input-wrapper-styles__root {
    width: 100%;
  }
  
  .ladder_calculator_rebrand__input-wrapper-styles__input {
    margin: 20px 0;
  }

}
.ladder_agents_agent_assisted_app_views_offer__rungs-policy-card-styles__expiration {
  margin-top: 20px;
}

.ladder_agents_agent_assisted_app_views_offer__rungs-policy-card-styles__expiration>span {
  white-space: nowrap;
}

.ladder_agents_agent_assisted_app_views_offer__rungs-policy-card-styles__amendments {
  margin-bottom: 10px;
}

.ladder_agents_agent_assisted_app_views_offer__rungs-policy-card-styles__divider {
  margin-left: -12.5%;
  margin-right: -12.5%;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 125%;
  border-color: #F1F1F1;
}
.ladder_ux_info_panel__horizontal-panel-styles__root {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin: 20px;
}

.ladder_ux_info_panel__horizontal-panel-styles__root-no-margin {
  margin: 0;
}

> * {
  flex: 1;
}

.ladder_ux_info_panel__horizontal-panel-styles__s > * {
  margin: 10.0px;
}

.ladder_ux_info_panel__horizontal-panel-styles__m > * {
  margin: 20px;
}

.ladder_ux_info_panel__horizontal-panel-styles__l > * {
  margin: 30.0px;
}

.ladder_ux_info_panel__horizontal-panel-styles__xl > * {
  margin: 60px;
}

.ladder_ux_info_panel__horizontal-panel-styles__xxl > * {
  margin: 130.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_info_panel__horizontal-panel-styles__root {
    flex-direction: column;
    align-items: center;
  }

}
.ladder_guide_common__life-insurance-guide-pages-title-panel-styles__root {
  display: flex;
  background: #EFEAE1;
  width: 100%;
  z-index: 1;
}

.ladder_guide_common__life-insurance-guide-pages-title-panel-styles__color-container {
  width: 100%;
  background-color: #EFEAE1;
  display: flex;
  justify-content: center;
  padding-top: 60px;
  margin: auto;
  padding-right: 62px;
}

.ladder_guide_common__life-insurance-guide-pages-title-panel-styles__title-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 122px;
}

.ladder_guide_common__life-insurance-guide-pages-title-panel-styles__header {
  width: 760px;
}

.ladder_guide_common__life-insurance-guide-pages-title-panel-styles__bottom-margin {
  margin-bottom: 60px;
}

.ladder_guide_common__life-insurance-guide-pages-title-panel-styles__pull-image-up-toward-title {
  margin-top: -20px;
}

.ladder_guide_common__life-insurance-guide-pages-title-panel-styles__title {
  max-width: 700px;
}

.ladder_guide_common__life-insurance-guide-pages-title-panel-styles__image {
  width: 300px;
  margin-bottom: -69px;
  align-self: flex-end;
  margin-right: 122px;
}

@media (min-width: 1025px) {

  
  
  .ladder_guide_common__life-insurance-guide-pages-title-panel-styles__color-container {
    padding-right: 62px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_guide_common__life-insurance-guide-pages-title-panel-styles__color-container {
    padding-right: 62px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_guide_common__life-insurance-guide-pages-title-panel-styles__color-container {
    padding-left: 450px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_guide_common__life-insurance-guide-pages-title-panel-styles__image {
    width: 275px;
  }
  


}

@media (max-width: 1024px) {

  .ladder_guide_common__life-insurance-guide-pages-title-panel-styles__pull-image-up-toward-title {
    margin-top: -20px;
  }

}

@media (max-width: 650px) {

  .ladder_guide_common__life-insurance-guide-pages-title-panel-styles__pull-image-up-toward-title {
    margin-top: 0px;
  }

}
.ladder_ux_radio_input__custom-radio-input-styles__root {
  display: flex;
  padding: 4.0px 0;
  justify-content: center;
  align-items: center;
}

.ladder_ux_radio_input__custom-radio-input-styles__root-transparent-button {
  padding: 0;
}

.ladder_ux_radio_input__custom-radio-input-styles__full-width {
  width: 100%;
  justify-content: space-between;
}

.ladder_ux_radio_input__custom-radio-input-styles__height-button {
  padding: 0;
  height: 48px;
}

.ladder_ux_radio_input__custom-radio-input-styles__height-s {
  padding: 0;
  height: 40px;
}

.ladder_ux_radio_input__custom-radio-input-styles__column-wrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: stretch;
}

.ladder_ux_radio_input__custom-radio-input-styles__column-wrapper > *:not(:last-child) {
  margin-bottom: 16px;
}

.ladder_ux_radio_input__custom-radio-input-styles__row-wrapper {
  display: flex;
  width: 100%;
}

.ladder_ux_radio_input__custom-radio-input-styles__row-wrapper > *:not(:last-child) {
  margin-right: 16px;
}

.box-fields .ladder_ux_radio_input__custom-radio-input-styles__root {
  padding: 0;
}

.box-fields .ladder_ux_radio_input__custom-radio-input-styles__root>div {
  width: 100%;
}

.box-fields .ladder_ux_radio_input__custom-radio-input-styles__root > *:not(:last-child) {
  margin-right: 16px;
}
.ladder_nearly_perfect_quote_common__caption-panel-styles__text-alignment {
  padding: 0 25.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_nearly_perfect_quote_common__caption-panel-styles__text-alignment {
    padding: 0 10.0px;
  }

}
.ladder_account_next_views_profile_edit__EditPersonalDetails-styles__wrapper {
  border: 2px solid #000000;
  width: 100%;
}

.ladder_account_next_views_profile_edit__EditPersonalDetails-styles__contact-line-container {
  margin-top: -10px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account_next_views_profile_edit__EditPersonalDetails-styles__contact-line-container {
    margin-bottom: 20px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_account_next_views_profile_edit__EditPersonalDetails-styles__contact-line-container {
    margin-bottom: 25px;
  }

}
.ladder_views_decision_hurdle__bank-status-success-styles__root {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
}

.ladder_views_decision_hurdle__bank-status-success-styles__title {
  margin: 0 0 22.5px 10.0px;
}

.ladder_views_decision_hurdle__bank-status-success-styles__subtitle {
  margin: 0 0 20px 10.0px;
}

.ladder_views_decision_hurdle__bank-status-success-styles__reminder-text {
  margin: 20px 0 20px 10.0px;
}

.ladder_views_decision_hurdle__bank-status-success-styles__text {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin: 0 10.0px 20px 10.0px;
}

.ladder_views_decision_hurdle__bank-status-success-styles__button-container {
  padding: 20px 10.0px 0 10.0px;
}
.ladder_form_agent_pass_the_application__root-styles__wrapper {
  display: flex;
  margin-top: 40px;
}

.ladder_form_agent_pass_the_application__root-styles__image-wrapper {
  margin-right: 7%;
  width: 33%;
  display: flex;
}

.ladder_form_agent_pass_the_application__root-styles__image {
  height: auto;
  width: 100%;
}

.ladder_form_agent_pass_the_application__root-styles__form {
  max-width: 700px;
}

.ladder_form_agent_pass_the_application__root-styles__row {
  display: flex;
  margin-bottom: 20px;
}

.ladder_form_agent_pass_the_application__root-styles__row>div {
  flex-grow: 1;
  flex-basis: 0;
}

.ladder_form_agent_pass_the_application__root-styles__row>div:first-child {
  margin-right: 25.0px;
}

.ladder_form_agent_pass_the_application__root-styles__caption {
  margin-bottom: 15.0px;
}

@media (min-width: 1025px) {

  
  
  .ladder_form_agent_pass_the_application__root-styles__form {
    width: 60%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_form_agent_pass_the_application__root-styles__wrapper {
    justify-content: center;
  }

}
.ladder_account__remove-payment-btn-styles__remove-billing {
  padding: 15.0px 0 0 60px;
}

.ladder_account__remove-payment-btn-styles__modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.ladder_account__remove-payment-btn-styles__full-width {
  width: 100%;
}
.ladder_ux_carousel__slideshow-styles__root {
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.ladder_ux_carousel__slideshow-styles__wrapper {
  display: flex;
  justify-content: center;
  width: 250%;
  flex-shrink: 0;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_carousel__slideshow-styles__wrapper {
    width: 500%;
  }

}

.ladder_ux_carousel__slideshow-styles__child {
  flex: 0 0 auto;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ladder_ux_carousel__slideshow-styles__slide {
  transform: translateX(0);
}

.ladder_ux_carousel__slideshow-styles__slide.ladder_ux_carousel__slideshow-styles__transition {
  transition: all 400ms ease-in;
  transform: translateX(-100%);
}

.ladder_ux_carousel__slideshow-styles__slide.ladder_ux_carousel__slideshow-styles__pre-center.ladder_ux_carousel__slideshow-styles__transition {
  transition: all 400ms ease-out;
}

.ladder_ux_carousel__slideshow-styles__frame {
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0.5;
  transform: scale(0.6);
  transition: opacity 400ms cubic-bezier(0,0,0.2,1), transform 400ms cubic-bezier(0,0,0.2,1);
}

.ladder_ux_carousel__slideshow-styles__frame.ladder_ux_carousel__slideshow-styles__pre-center {
  transition: opacity 400ms cubic-bezier(0.8,0,1,1), transform 400ms cubic-bezier(0.8,0,1,1);
}

.ladder_ux_carousel__slideshow-styles__frame.ladder_ux_carousel__slideshow-styles__center {
  opacity: 1;
  transform: scale(1);
}

.ladder_ux_carousel__slideshow-styles__caption {
  opacity: 1;
}

.ladder_ux_carousel__slideshow-styles__caption.ladder_ux_carousel__slideshow-styles__transition {
  opacity: 0;
  transition: opacity 200ms linear;
}

.ladder_ux_carousel__slideshow-styles__caption-next {
  opacity: 0;
  position: absolute;
}

.ladder_ux_carousel__slideshow-styles__caption-next.ladder_ux_carousel__slideshow-styles__transition {
  opacity: 1;
  transition: opacity 200ms linear 200ms;
}
.ladder_trustage_quote__coverage-modal-styles__header {
  padding: 20px 30.0px;
}

.ladder_trustage_quote__coverage-modal-styles__content {
  padding: 30.0px;
  padding-top: 0;
  padding-bottom: 50.0px;
}

.ladder_trustage_quote__coverage-modal-styles__caption {
  padding: 20px 0;
}

.ladder_trustage_quote__coverage-modal-styles__coverage {
  border: 2px solid #BDBDBD;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 30.0px;
}

.ladder_trustage_quote__coverage-modal-styles__lower-coverage {
  display: flex;
  margin-top: 20px;
}

.ladder_trustage_quote__coverage-modal-styles__lower-coverage .ladder_trustage_quote__coverage-modal-styles__icon {
  min-width: 12px;
  width: 24px;
  height: 22px;
  margin-right: 10.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_trustage_quote__coverage-modal-styles__coverage {
    padding: 10.0px 10.0px;
  }
  
  .ladder_trustage_quote__coverage-modal-styles__nav {
    flex-direction: column-reverse;
  }
  
  .ladder_trustage_quote__coverage-modal-styles__save {
    margin-bottom: 10.0px;
  }

}
.ladder_ux_panels__agent-contact-icon-text-row-styles__row {
  display: flex;
  width: 100%;
}

.ladder_ux_panels__agent-contact-icon-text-row-styles__icon {
  display: flex;
  width: 10%;
}

.ladder_ux_panels__agent-contact-icon-text-row-styles__text {
  display: flex;
  width: 90%;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_panels__agent-contact-icon-text-row-styles__root {
    margin-top: 24.0px;
  }
  
  .ladder_ux_panels__agent-contact-icon-text-row-styles__info-box {
    padding-top: 8.0px;
    padding-bottom: 8.0px;
  }

}
.ladder_account_next_modals__payment-modal-styles__modal-wrapper {
  padding-top: 20px;
}

.ladder_account_next_modals__payment-modal-styles__payment-details {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  padding: 20px 0px;
}

.ladder_account_next_modals__payment-modal-styles__payment-details .ladder_account_next_modals__payment-modal-styles__single-item {
  display: block;
}

.ladder_account_next_modals__payment-modal-styles__payment-details >* {
  display: flex;
  width: 100%;
  column-gap: 20px;
  justify-content: space-between;
  padding: 10.0px 0;
}

.ladder_account_next_modals__payment-modal-styles__img {
  width: 200px;
  height: auto;
  margin: 0 auto;
}

.ladder_account_next_modals__payment-modal-styles__img-wrapper {
  display: flex;
  align-items: center;
}

.ladder_account_next_modals__payment-modal-styles__border {
  border-bottom: 1px solid #D9D9D9;
}

.ladder_account_next_modals__payment-modal-styles__divider {
  margin: 20px -12.5%;
  width: auto;
  border-color: #D9D9D9;
}

.ladder_account_next_modals__payment-modal-styles__text-right {
  text-align: right;
}

.ladder_account_next_modals__payment-modal-styles__nsf-warning {
  max-width: 422px;
}

.ladder_account_next_modals__payment-modal-styles__payment-method-info {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  column-gap: 20px;
}

@media (max-width: 499px) {

  
  
  .ladder_account_next_modals__payment-modal-styles__nsf-warning {
    max-width: 251px;
  }
  
  .ladder_account_next_modals__payment-modal-styles__payment-method-info {
    column-gap: 10.0px;
  }

}
.ladder_ux_homepage_testimonials_core__testimonial-card-styles__testimonial-card {
  justify-content: center;
  height: 285px;
  align-items: center;
  padding: 0 65px;
  flex-shrink: 0;
  flex-direction: column;
  white-space: normal;
  position: absolute;
  overflow: hidden;
  background-color: #F4F4F4;
  display: flex;
}

.ladder_ux_homepage_testimonials_core__testimonial-card-styles__testimonial-text {
  position: relative;
}

.ladder_ux_homepage_testimonials_core__testimonial-card-styles__rungs-testimonial-card {
  display: flex;
  flex-direction: column;
  height: 550px;
  justify-content: start;
  align-items: start;
  background-color: #EFEAE1;
}

.ladder_ux_homepage_testimonials_core__testimonial-card-styles__testimonial-card-white {
  background-color: #FFFFFF;
}

.ladder_ux_homepage_testimonials_core__testimonial-card-styles__testimonial-card-relative {
  position: relative;
}

.ladder_ux_homepage_testimonials_core__testimonial-card-styles__stars {
  display: flex;
  flex-direction: flex-start;
  justify-content: space-between;
  width: 200px;
  margin: 64px 0 16px 0;
}

.ladder_ux_homepage_testimonials_core__testimonial-card-styles__trustpilot-stars {
  height: 33px;
}

.ladder_ux_homepage_testimonials_core__testimonial-card-styles__star {
  width: 33px;
  height: 33px;
}

.ladder_ux_homepage_testimonials_core__testimonial-card-styles__quote-left {
  height: 24.0px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%) translateY(-35%);
}

.ladder_ux_homepage_testimonials_core__testimonial-card-styles__quote-right {
  height: 24.0px;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(100%) translateY(-35%);
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_homepage_testimonials_core__testimonial-card-styles__testimonial-card {
    padding: 0 40px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_homepage_testimonials_core__testimonial-card-styles__testimonial-card {
    padding: 0 40px;
  }

}
.ladder_ux_homepage_returners_core__returners-journey-panel-styles__returners-journey-panel-root {
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  padding: 32px 48px;
}

.ladder_ux_homepage_returners_core__returners-journey-panel-styles__bars-container {
  display: flex;
  width: 100%;
  height: 0.4vw;
  justify-content: space-between;
  margin-bottom: 32px;
}

.ladder_ux_homepage_returners_core__returners-journey-panel-styles__gray-bar-wrapper {
  display: flex;
  position: relative;
  flex-grow: 1;
  height: 100%;
  z-index: 1;
  margin: 16px 1% 0 1%;
  background-color: #F1F1F1;
  cursor: pointer;
}

.ladder_ux_homepage_returners_core__returners-journey-panel-styles__gray-bar-wrapper:first-child {
  margin-left: 0px;
}

.ladder_ux_homepage_returners_core__returners-journey-panel-styles__gray-bar-wrapper:last-child {
  margin-right: 0px;
}

.ladder_ux_homepage_returners_core__returners-journey-panel-styles__current-bar {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  width: 0%;
  background-color: #1A1A1A;
}

.ladder_ux_homepage_returners_core__returners-journey-panel-styles__current-bar.ladder_ux_homepage_returners_core__returners-journey-panel-styles__transition {
  transition: 8s linear;
}

.ladder_ux_homepage_returners_core__returners-journey-panel-styles__current-bar.ladder_ux_homepage_returners_core__returners-journey-panel-styles__selected {
  width: 100%;
}

.ladder_ux_homepage_returners_core__returners-journey-panel-styles__current-bar.ladder_ux_homepage_returners_core__returners-journey-panel-styles__unselected {
  transition: 0s;
}

.ladder_ux_homepage_returners_core__returners-journey-panel-styles__arrows-container {
  display: flex;
}

.ladder_ux_homepage_returners_core__returners-journey-panel-styles__left-arrow-wrapper {
  margin-right: 16px;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_homepage_returners_core__returners-journey-panel-styles__returners-journey-panel-root {
    padding: 16px 16px 24.0px 16px;
  }
  
  .ladder_ux_homepage_returners_core__returners-journey-panel-styles__bars-container {
    height: 6px;
  }

}
.ladder_views_decision_common__price-section-view-styles__estimated-price {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 50px;
}

.ladder_views_decision_common__price-section-view-styles__spinner {
  margin-left: 8px;
}
.ladder_admin_widget__GitSha-styles__root {
  padding: 4px;
  color: #1F4972;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.4);
}
.ladder_landing_pages_so_good_basics__life-insurance-basics-styles__basics-root {
  width: 100%;
}

.ladder_landing_pages_so_good_basics__life-insurance-basics-styles__basics-middle {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #1A1A1A;
  padding: 18px 0px;
}

.ladder_landing_pages_so_good_basics__life-insurance-basics-styles__basics-facts {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #000000;
}
.ladder_view_schedule__time-picker-styles__parent {
  flex: 1;
  margin-left: 20px;
  margin-right: 20px;
}

.ladder_view_schedule__time-picker-styles__root {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  color: #000000;
}

.ladder_view_schedule__time-picker-styles__time-slot {
  width: 100%;
  justify-content: center;
  height: 35px;
  border: 1px solid #D0D0D0;
  align-items: center;
  text-align: center;
  cursor: default;
  font-weight: 300;
  display: flex;
}

.ladder_view_schedule__time-picker-styles__time-slot-outer {
  width: 33%;
  padding: 5.0px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ladder_view_schedule__time-picker-styles__rungs-selected {
  background-color: #000000;
  border-color: #000000;
  color: #FFFFFF;
}

.ladder_view_schedule__time-picker-styles__selected {
  background-color: #FE4747;
  border-color: #FE4747;
  color: #FFFFFF;
}

.ladder_view_schedule__time-picker-styles__selectable {

}

.ladder_view_schedule__time-picker-styles__selectable:hover {
  background-color: #D0D0D0;
  cursor: pointer;
}

.ladder_view_schedule__time-picker-styles__container {
  text-align: center;
  margin-top: 20px;
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ladder_view_schedule__time-picker-styles__italicized {
  font-style: italic;
}

.ladder_view_schedule__time-picker-styles__preferred-select {
  width: 125px;
  margin: 20px auto;
}

@media (min-width: 1025px) {

  
  
  .ladder_view_schedule__time-picker-styles__parent {
    width: 336px;
  }
  
  .ladder_view_schedule__time-picker-styles__time-slot-outer {
    min-width: 76px;
    width: 25%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_view_schedule__time-picker-styles__parent {
    width: 336px;
  }
  
  .ladder_view_schedule__time-picker-styles__time-slot-outer {
    min-width: 76px;
    width: 25%;
  }

}
.ladder_account_next_cards_payment_method_component__get-payment-method-info-styles__payment-row {
  width: 100%;
  gap: 10px;
  font-family: Montserrat, sans-serif;
  align-items: center;
  flex-direction: row;
  font-weight: normal;
  font-size: 24.0px;
  display: flex;
  color: #000000;
}

.ladder_account_next_cards_payment_method_component__get-payment-method-info-styles__payment-icon {
  width: 75px;
}

.ladder_account_next_cards_payment_method_component__get-payment-method-info-styles__left-content {
  width: 65%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
}

.ladder_account_next_cards_payment_method_component__get-payment-method-info-styles__right-content {
  width: 35%;
  display: flex;
  align-items: center;
}

.ladder_account_next_cards_payment_method_component__get-payment-method-info-styles__text-custom-space {
  margin-left: -15px;
}

@media (max-width: 499px) {

  
  
  .ladder_account_next_cards_payment_method_component__get-payment-method-info-styles__payment-row {
    font-size: 14px;
    flex-direction: column;
    gap: 20px;
  }
  
  .ladder_account_next_cards_payment_method_component__get-payment-method-info-styles__left-content {
    width: 100%;
    justify-content: space-between;
  }
  
  .ladder_account_next_cards_payment_method_component__get-payment-method-info-styles__right-content {
    width: 100%;
  }
  
  .ladder_account_next_cards_payment_method_component__get-payment-method-info-styles__payment-icon {
    width: 50px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account_next_cards_payment_method_component__get-payment-method-info-styles__payment-row {
    font-size: 14px;
    flex-direction: column;
    gap: 20px;
  }
  
  .ladder_account_next_cards_payment_method_component__get-payment-method-info-styles__left-content {
    width: 100%;
    justify-content: space-between;
  }
  
  .ladder_account_next_cards_payment_method_component__get-payment-method-info-styles__right-content {
    width: 100%;
  }
  
  .ladder_account_next_cards_payment_method_component__get-payment-method-info-styles__payment-icon {
    width: 50px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account_next_cards_payment_method_component__get-payment-method-info-styles__payment-row {
    font-size: 20.0px;
  }

}
.ladder_views_decision_poca__offer-styles__accept-and-pay-width {
  max-width: 352px;
}

.ladder_views_decision_poca__offer-styles__space-on-top {
  padding-top: 32px;
}
.ladder_account_next_modals__SpouseConnectionModal-styles__margin-top-m {
  margin-top: 20px;
}

.ladder_account_next_modals__SpouseConnectionModal-styles__button {
  margin: 20px 0;
}

.ladder_account_next_modals__SpouseConnectionModal-styles__align-self-start {
  align-self: flex-start;
}

.ladder_account_next_modals__SpouseConnectionModal-styles__hr {
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (max-width: 499px) {

  
  
  .ladder_account_next_modals__SpouseConnectionModal-styles__button-wrapper {
    width: 100%;
  }

}
.ladder_agents_dashboard_quote__quote-modal-styles__quoter-and-rules {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: space-between;
}
.ladder_ux_panels__story-panel-homepage-2022-styles__root {
  width: 100%;
  background-color: #EFEAE1;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 390px;
}

.ladder_ux_panels__story-panel-homepage-2022-styles__testimonial-panel {
  display: flex;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;
}

.ladder_ux_panels__story-panel-homepage-2022-styles__logo-panel-desktop {
  display: flex;
  justify-content: flex-end;
  width: 35%;
}

.ladder_ux_panels__story-panel-homepage-2022-styles__logo-panel-mobile {
  display: none;
}

.ladder_ux_panels__story-panel-homepage-2022-styles__content {
  width: 65%;
  padding-bottom: 32px;
  max-width: 560px;
  border-left: 1px solid;
  border-color: #BDBDBD;
  flex-direction: column;
  min-height: 220px;
  margin-left: 16px;
  display: flex;
  padding-left: 16px;
}

.ladder_ux_panels__story-panel-homepage-2022-styles__top-bar-wrapper {
  display: flex;
  width: 100%;
  max-width: 900px;
  height: 0.3vw;
  margin-top: 32px;
  margin-bottom: 32px;
  justify-content: space-between;
}

.ladder_ux_panels__story-panel-homepage-2022-styles__gray-bar-wrapper {
  display: flex;
  position: relative;
  flex-grow: 1;
  height: 100%;
  z-index: 1;
  margin: 16px 0.5% 0 0.5%;
  background-color: #BDBDBD;
  cursor: pointer;
}

.ladder_ux_panels__story-panel-homepage-2022-styles__gray-bar-wrapper:first-child {
  margin-right: 0.5%;
}

.ladder_ux_panels__story-panel-homepage-2022-styles__gray-bar-wrapper:last-child {
  margin-left: 0.5%;
}

.ladder_ux_panels__story-panel-homepage-2022-styles__current-bar {
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  width: 0%;
  background-color: #1A1A1A;
}

.ladder_ux_panels__story-panel-homepage-2022-styles__current-bar.ladder_ux_panels__story-panel-homepage-2022-styles__transition {
  transition: 5s linear;
}

.ladder_ux_panels__story-panel-homepage-2022-styles__current-bar.ladder_ux_panels__story-panel-homepage-2022-styles__selected {
  width: 100%;
}

.ladder_ux_panels__story-panel-homepage-2022-styles__current-bar.ladder_ux_panels__story-panel-homepage-2022-styles__unselected {
  transition: 0s;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_panels__story-panel-homepage-2022-styles__root {
    flex-direction: column;
    height: auto;
    min-height: 220px;
  }
  
  .ladder_ux_panels__story-panel-homepage-2022-styles__logo-panel-desktop {
    display: none;
  }
  
  .ladder_ux_panels__story-panel-homepage-2022-styles__logo-panel-mobile {
    display: flex;
    justify-content: flex-end;
    width: 40%;
    margin-left: 8.0px;
  }
  
  .ladder_ux_panels__story-panel-homepage-2022-styles__testimonial-panel {
    width: 100%;
  }
  
  .ladder_ux_panels__story-panel-homepage-2022-styles__content {
    padding-bottom: 8.0px;
    min-height: 125px;
    width: 60%;
  }
  
  .ladder_ux_panels__story-panel-homepage-2022-styles__top-bar-wrapper {
    height: 3px;
    margin-top: 16px;
    margin-bottom: 16px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_panels__story-panel-homepage-2022-styles__root {
    flex-direction: column;
    height: auto;
    min-height: 220px;
  }
  
  .ladder_ux_panels__story-panel-homepage-2022-styles__logo-panel-desktop {
    display: none;
  }
  
  .ladder_ux_panels__story-panel-homepage-2022-styles__logo-panel-mobile {
    display: flex;
    justify-content: flex-end;
    width: 40%;
    margin-left: 8.0px;
  }
  
  .ladder_ux_panels__story-panel-homepage-2022-styles__testimonial-panel {
    width: 100%;
  }
  
  .ladder_ux_panels__story-panel-homepage-2022-styles__content {
    padding-bottom: 8.0px;
    min-height: 125px;
    width: 60%;
  }
  
  .ladder_ux_panels__story-panel-homepage-2022-styles__top-bar-wrapper {
    height: 3px;
    margin-top: 16px;
    margin-bottom: 16px;
  }

}
.ladder_apply_alcohol_abuse__root-styles__root {
  padding-bottom: 22.5px;
}

.ladder_apply_alcohol_abuse__root-styles__row {
  display: flex;
  justify-content: space-between;
}

.ladder_apply_alcohol_abuse__root-styles__row > *:not(:last-child) {
  margin-right: 20px;
}

.ladder_apply_alcohol_abuse__root-styles__row>div {
  width: 100%;
}

.ladder_apply_alcohol_abuse__root-styles__col {
  display: flex;
  flex-direction: column;
  row-gap: 22.5px;
}

@media (max-width: 499px) {

  
  
  .ladder_apply_alcohol_abuse__root-styles__row {
    flex-direction: column;
    row-gap: 15.0px;
  }
  
  .ladder_apply_alcohol_abuse__root-styles__col {
    row-gap: 15.0px;
  }

}
.ladder_savings_common__StepsPriceModal-styles__steps {
  margin: 10.0px 0 20px 0;
}
.ladder_ux_panels__ladder-gift-panel-styles__root {
  background-color: #DBE6DB;
  width: 100%;
  height: 750px;
  padding: 64px 0;
}

.ladder_ux_panels__ladder-gift-panel-styles__panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  width: 80%;
  height: 100%;
  margin: 0 auto;
}

.ladder_ux_panels__ladder-gift-panel-styles__text-panel {
  flex: 0 1 600px;
}

.ladder_ux_panels__ladder-gift-panel-styles__panel-heading {
  max-width: 400px;
}

.ladder_ux_panels__ladder-gift-panel-styles__panel-text {
  margin: 32px 0;
}

.ladder_ux_panels__ladder-gift-panel-styles__carousel-container {
  display: flex;
  flex: 1 1 600px;
  align-items: center;
  height: 100%;
  margin: 0 0 0 32px;
}

.ladder_ux_panels__ladder-gift-panel-styles__carousel-nav {
  padding: 8.0px;
  cursor: pointer;
}

.ladder_ux_panels__ladder-gift-panel-styles__carousel-panel {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  width: 100%;
  height: 100%;
}

.ladder_ux_panels__ladder-gift-panel-styles__carousel-content-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.ladder_ux_panels__ladder-gift-panel-styles__carousel-content {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: opacity 0.25s;
}

.ladder_ux_panels__ladder-gift-panel-styles__carousel-content.ladder_ux_panels__ladder-gift-panel-styles__hidden {
  opacity: 0;
  visibility: hidden;
}

.ladder_ux_panels__ladder-gift-panel-styles__carousel-image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 0;
  height: 100%;
  width: 90%;
}

.ladder_ux_panels__ladder-gift-panel-styles__carousel-image {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
  object-fit: scale-down;
}

.ladder_ux_panels__ladder-gift-panel-styles__carousel-image.ladder_ux_panels__ladder-gift-panel-styles__logo {
  max-height: 300px;
}

.ladder_ux_panels__ladder-gift-panel-styles__carousel-caption {
  text-align: center;
  width: 100%;
  padding: 32px 0;
  white-space: nowrap;
}

.ladder_ux_panels__ladder-gift-panel-styles__bottom-bar-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.ladder_ux_panels__ladder-gift-panel-styles__gray-bar-wrapper {
  flex-grow: 1;
  height: 0.3vw;
  margin: 16px 0.5% 32px;
  background-color: #F1F1F1;
  cursor: pointer;
}

@keyframes current-bar-fill {

  to {
    width: 100%;
  }

}

.ladder_ux_panels__ladder-gift-panel-styles__current-bar {
  width: 0%;
  height: 100%;
  background-color: #91AF92;
}

.ladder_ux_panels__ladder-gift-panel-styles__current-bar.ladder_ux_panels__ladder-gift-panel-styles__selected {
  animation: current-bar-fill 5s linear;
}

.ladder_ux_panels__ladder-gift-panel-styles__current-bar.ladder_ux_panels__ladder-gift-panel-styles__paused {
  animation-play-state: paused;
}

@media (min-width: 1025px) {

  
  
  .ladder_ux_panels__ladder-gift-panel-styles__desktop-button {
    display: block;
    max-width: 250px;
  }
  
  .ladder_ux_panels__ladder-gift-panel-styles__mobile-button {
    display: none;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_panels__ladder-gift-panel-styles__root {
    height: auto;
  }
  
  .ladder_ux_panels__ladder-gift-panel-styles__panel {
    flex-direction: column;
    width: 90%;
  }
  
  .ladder_ux_panels__ladder-gift-panel-styles__text-panel {
    align-items: center;
    justify-content: center;
    height: auto;
    flex: unset;
  }
  
  .ladder_ux_panels__ladder-gift-panel-styles__panel-heading {
    max-width: 250px;
  }
  
  .ladder_ux_panels__ladder-gift-panel-styles__carousel-container {
    width: 100%;
    margin: 0;
    flex: unset;
    height: 500px;
  }
  
  .ladder_ux_panels__ladder-gift-panel-styles__carousel-nav {
    display: none;
  }
  
  .ladder_ux_panels__ladder-gift-panel-styles__carousel-panel {
    align-items: center;
  }
  
  .ladder_ux_panels__ladder-gift-panel-styles__bottom-bar-wrapper {
    height: 5px;
    margin: 0 0 20.0px 0;
  }
  
  .ladder_ux_panels__ladder-gift-panel-styles__desktop-button {
    display: none;
  }
  
  .ladder_ux_panels__ladder-gift-panel-styles__mobile-button {
    display: block;
    width: 100%;
    margin: 48px 0 0;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_panels__ladder-gift-panel-styles__root {
    height: auto;
  }
  
  .ladder_ux_panels__ladder-gift-panel-styles__panel {
    flex-direction: column;
    width: 90%;
  }
  
  .ladder_ux_panels__ladder-gift-panel-styles__text-panel {
    align-items: center;
    justify-content: center;
    height: auto;
    flex: unset;
  }
  
  .ladder_ux_panels__ladder-gift-panel-styles__panel-heading {
    max-width: 250px;
  }
  
  .ladder_ux_panels__ladder-gift-panel-styles__carousel-container {
    width: 100%;
    margin: 0;
    flex: unset;
    height: 500px;
  }
  
  .ladder_ux_panels__ladder-gift-panel-styles__carousel-nav {
    display: none;
  }
  
  .ladder_ux_panels__ladder-gift-panel-styles__carousel-panel {
    align-items: center;
  }
  
  .ladder_ux_panels__ladder-gift-panel-styles__bottom-bar-wrapper {
    height: 5px;
    margin: 0 0 20.0px 0;
  }
  
  .ladder_ux_panels__ladder-gift-panel-styles__desktop-button {
    display: none;
  }
  
  .ladder_ux_panels__ladder-gift-panel-styles__mobile-button {
    display: block;
    width: 100%;
    margin: 48px 0 0;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_panels__ladder-gift-panel-styles__mobile-button {
    max-width: 250px;
  }

}
.ladder_agents_agent_assisted_app_views_review__gray-bar-unfixed-styles__root {
  border-top: 1px #D0D0D0 solid;
  width: 98%;
  margin: 20px 0 20px 5.0px;
  height: 1px;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_agent_assisted_app_views_review__gray-bar-unfixed-styles__root {
    width: 100%;
  }

}
.ladder_account_next_cards_welcome__header-img-styles__overflow-hider {
  overflow: hidden;
  height: 150px;
  width: 200px;
}

@media (min-width: 1025px) {

  
  
  .ladder_account_next_cards_welcome__header-img-styles__image {
    width: 170px;
    margin-left: 0;
    margin-top: 0;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account_next_cards_welcome__header-img-styles__image {
    width: 170px;
    margin-left: 0;
    margin-top: 0;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_account_next_cards_welcome__header-img-styles__image {
    width: 120px;
    margin-left: 0;
    margin-top: 0;
  }
  
  .ladder_account_next_cards_welcome__header-img-styles__overflow-hider {
    overflow: hidden;
    height: 105px;
    width: 200px;
  }

}
.ladder_user_upload_form__file-listing-styles__root {
  width: 100%;
}

.ladder_user_upload_form__file-listing-styles__root.ladder_user_upload_form__file-listing-styles__errored .ladder_user_upload_form__file-listing-styles__status {
  color: #FE4747;
}

.ladder_user_upload_form__file-listing-styles__root.ladder_user_upload_form__file-listing-styles__errored .ladder_user_upload_form__file-listing-styles__bar>div {
  background-color: #FE4747;
}

.ladder_user_upload_form__file-listing-styles__description {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.ladder_user_upload_form__file-listing-styles__name {
  margin-right: 6px;
}

.ladder_user_upload_form__file-listing-styles__status {
  margin-left: 4px;
}

.ladder_user_upload_form__file-listing-styles__bar {
  height: 8px;
  border-radius: 4px;
  background-color: #F4F4F4;
  padding: 2px;
}

.ladder_user_upload_form__file-listing-styles__bar >div {
  height: 8px;
  border-radius: 4px;
  background-color: #475665;
  transition: width 0.25s;
}

.ladder_user_upload_form__file-listing-styles__bar >div.ladder_user_upload_form__file-listing-styles__errored {
  background-color: #FE4747;
}

.ladder_user_upload_form__file-listing-styles__delete {
  cursor: pointer;
}
.landscape .ladder_widget_ui_v2__zipcode-field-styles__root {
  width: 32%;
}
.ladder_account_policy__policy-header-styles__root {
  display: flex;
  width: 100%;
  padding-bottom: 30.0px;
}

.ladder_account_policy__policy-header-styles__one-sixth {
  flex-basis: 20%;
}

.ladder_account_policy__policy-header-styles__one-third {
  flex-basis: 60%;
}

.ladder_account_policy__policy-header-styles__left-aligned {
  text-align: left;
}
@media (min-width: 1025px) {

  
  
  .ladder_account_next_nav__left-nav-styles__\% {
    background-color: #F4F4F4;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account_next_nav__left-nav-styles__\% {
    background-color: #F4F4F4;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_account_next_nav__left-nav-styles__\% {
    max-width: 250px;
    min-width: 250px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account_next_nav__left-nav-styles__\% {
    flex: 1 1 250px;
    max-width: 250px;
  }

}
.ladder_app_review__price-calc-and-display-styles__section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.ladder_ux_info_panel__spacer-styles__content {
  display: flex;
  flex-direction: column;
  padding: 30.0px 0;
  width: 100%;
}

.ladder_ux_info_panel__spacer-styles__align-items-center {
  align-items: center;
}

.ladder_ux_info_panel__spacer-styles__align-items-left {
  align-items: unset;
}

.ladder_ux_info_panel__spacer-styles__none {
  padding: 0;
}

.ladder_ux_info_panel__spacer-styles__xs {
  padding: 5.0px 0;
}

.ladder_ux_info_panel__spacer-styles__s {
  padding: 10.0px 0;
}

.ladder_ux_info_panel__spacer-styles__m {
  padding: 20px 0;
}

.ladder_ux_info_panel__spacer-styles__mml {
  padding: 25.0px 0;
}

.ladder_ux_info_panel__spacer-styles__l {
  padding: 30.0px 0;
}

.ladder_ux_info_panel__spacer-styles__xl {
  padding: 60px 0;
}

.ladder_ux_info_panel__spacer-styles__xxl {
  padding: 130.0px 0;
}

.ladder_ux_info_panel__spacer-styles__full-height {
  flex-basis: 100%;
}

.ladder_ux_info_panel__spacer-styles__align-items-start {
  align-items: flex-start;
}

.ladder_ux_info_panel__spacer-styles__align-items-stretch {
  align-items: stretch;
}

@media (min-width: 1025px) {

  
  
  .ladder_ux_info_panel__spacer-styles__desktop-full-width {
    width: 100%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_info_panel__spacer-styles__content {
    padding: 20px 0;
  }
  
  .ladder_ux_info_panel__spacer-styles__xs {
    padding: 5.0px 0;
  }
  
  .ladder_ux_info_panel__spacer-styles__s {
    padding: 10.0px 0;
  }
  
  .ladder_ux_info_panel__spacer-styles__m {
    padding: 15.0px 0;
  }
  
  .ladder_ux_info_panel__spacer-styles__l {
    padding: 20px 0;
  }
  
  .ladder_ux_info_panel__spacer-styles__xl {
    padding: 30.0px 0;
  }
  
  .ladder_ux_info_panel__spacer-styles__xxl {
    padding: 60px 0;
  }
  
  .ladder_ux_info_panel__spacer-styles__none {
    padding: 0;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_info_panel__spacer-styles__content {
    padding: 20px 0;
  }
  
  .ladder_ux_info_panel__spacer-styles__zero-padding-mobile {
    padding: 0;
  }
  
  .ladder_ux_info_panel__spacer-styles__xs {
    padding: 5.0px 0;
  }
  
  .ladder_ux_info_panel__spacer-styles__s {
    padding: 10.0px 0;
  }
  
  .ladder_ux_info_panel__spacer-styles__m {
    padding: 10.0px 0;
  }
  
  .ladder_ux_info_panel__spacer-styles__l {
    padding: 20px 0;
  }
  
  .ladder_ux_info_panel__spacer-styles__xl {
    padding: 30.0px 0;
  }
  
  .ladder_ux_info_panel__spacer-styles__xxl {
    padding: 60px 0;
  }
  
  .ladder_ux_info_panel__spacer-styles__none {
    padding: 0;
  }

}
.ladder_account_next_views_beneficiaries__Root-styles__\% {
  margin-top: 20px;
}
@keyframes highlight-step {

31% {
    background-color: #E7EEEF;
    transform: translateX(30px);
  }
  
69% {
    background-color: #E7EEEF;
    transform: translateX(30px);
  }

}

@keyframes highlight-step-number {

31% {
    opacity: 1;
  }
  
69% {
    opacity: 1;
  }

}

@keyframes go-green {

31% {
    stroke: #5ACD4F;
  }
  
69% {
    stroke: #5ACD4F;
  }

}

@keyframes step-icon-spin {

31% {
    transform: rotate(-180deg);
  }
  
69% {
    transform: rotate(-180deg);
  }

}

.ladder_savings_steps_dory__step-styles__step-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 10.0px;
  flex-shrink: 0;
}

.ladder_savings_steps_dory__step-styles__step-wrapper.ladder_savings_steps_dory__step-styles__animated>div {
  animation-duration: 2600ms;
  animation-delay: 3750.0ms;
  animation-timing-function: cubic-bezier(0.7,-0.4,0.4,1.4);
}

.ladder_savings_steps_dory__step-styles__step-wrapper.ladder_savings_steps_dory__step-styles__animated .ladder_savings_steps_dory__step-styles__icon {
  animation-duration: 2600ms;
  animation-delay: 3750.0ms;
  animation-timing-function: cubic-bezier(0.7,-0.4,0.4,1.4);
}

.ladder_savings_steps_dory__step-styles__step-wrapper.ladder_savings_steps_dory__step-styles__animated.ladder_savings_steps_dory__step-styles__delayed-1>div {
  animation-delay: 5550.0ms;
}

.ladder_savings_steps_dory__step-styles__step-wrapper.ladder_savings_steps_dory__step-styles__animated.ladder_savings_steps_dory__step-styles__delayed-1 .ladder_savings_steps_dory__step-styles__icon {
  animation-delay: 5550.0ms;
}

.ladder_savings_steps_dory__step-styles__step-wrapper.ladder_savings_steps_dory__step-styles__animated.ladder_savings_steps_dory__step-styles__delayed-2>div {
  animation-delay: 7350.0ms;
}

.ladder_savings_steps_dory__step-styles__step-wrapper.ladder_savings_steps_dory__step-styles__animated.ladder_savings_steps_dory__step-styles__delayed-2 .ladder_savings_steps_dory__step-styles__icon {
  animation-delay: 7350.0ms;
}

.ladder_savings_steps_dory__step-styles__step-number {
  width: 35px;
  justify-content: center;
  height: 35px;
  border: solid 1px #F1F1F1;
  align-items: center;
  position: absolute;
  animation-name: highlight-step-number;
  border-radius: 50%;
  display: flex;
  animation-timing-function: ease-in-out;
  opacity: 0;
  left: -10px;
}

.ladder_savings_steps_dory__step-styles__step {
  min-width: 90%;
  width: 90%;
  height: 72px;
  border: solid 1px #F1F1F1;
  align-items: center;
  padding: 0 15.0px;
  animation-name: highlight-step;
  box-shadow: 0 5px 15px rgba(27,27,27,0.12);
  background-color: #FFFFFF;
  border-radius: 10px;
  display: flex;
}

.ladder_savings_steps_dory__step-styles__step .ladder_savings_steps_dory__step-styles__icon {
  height: 38px;
  width: 38px;
  flex-shrink: 0;
  margin-right: 15.0px;
}

.ladder_savings_steps_dory__step-styles__step .ladder_savings_steps_dory__step-styles__copy {
  flex-shrink: 1;
}
.ladder_affiliates__partnership-options-styles__wrapper {
  width: 100%;
  padding-top: 130.0px;
}

.ladder_affiliates__partnership-options-styles__header {
  text-align: center;
}

.ladder_affiliates__partnership-options-styles__subheader {
  margin-top: 10.0px;
  text-align: center;
}

.ladder_affiliates__partnership-options-styles__numbered-section {
  text-align: left;
  margin: 30.0px 0;
}

.ladder_affiliates__partnership-options-styles__quote-widget {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ladder_affiliates__partnership-options-styles__quote-widget .ladder_affiliates__partnership-options-styles__quote-widget-text {
  margin-right: 20px;
}

.ladder_affiliates__partnership-options-styles__text-spacer {
  margin: 20px 0;
  max-width: 530px;
}

.ladder_affiliates__partnership-options-styles__line-break {
  margin: 20px 0;
  width: 80px;
  border-bottom: 1px solid #000000;
}

@media (max-width: 499px) {

  
  
  .ladder_affiliates__partnership-options-styles__quote-widget {
    flex-direction: column;
  }
  
  .ladder_affiliates__partnership-options-styles__quote-widget .ladder_affiliates__partnership-options-styles__quote-widget-text {
    margin-right: 0;
  }
  
  .ladder_affiliates__partnership-options-styles__text-spacer {
    max-width: 100%;
    margin: 20px 0;
  }
  
  .ladder_affiliates__partnership-options-styles__last-widget-text {
    margin-bottom: 30.0px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_affiliates__partnership-options-styles__quote-widget {
    flex-direction: column;
  }
  
  .ladder_affiliates__partnership-options-styles__quote-widget .ladder_affiliates__partnership-options-styles__quote-widget-text {
    margin-right: 0;
  }
  
  .ladder_affiliates__partnership-options-styles__text-spacer {
    max-width: 100%;
    margin: 20px 0;
  }
  
  .ladder_affiliates__partnership-options-styles__last-widget-text {
    margin-bottom: 30.0px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_affiliates__partnership-options-styles__wrapper {
    padding-top: 60px;
  }
  
  .ladder_affiliates__partnership-options-styles__header {
    text-align: left;
  }
  
  .ladder_affiliates__partnership-options-styles__subheader {
    text-align: left;
  }
  
  .ladder_affiliates__partnership-options-styles__line-break {
    margin: 20px 0;
  }

}
.ladder_apply_app_navigation_drop_down__app-navigation-drop-down-styles__root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 3px 0px #0000000D;
}

.ladder_apply_app_navigation_drop_down__app-navigation-drop-down-styles__milestone-container {
  display: flex;
  flex-direction: column;
  padding: 0 15.0px;
}

.ladder_apply_app_navigation_drop_down__app-navigation-drop-down-styles__row {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  padding: 10.0px 0;
}

.ladder_apply_app_navigation_drop_down__app-navigation-drop-down-styles__button {
  display: flex;
  margin-top: 30.0px;
  max-width: 250px;
}

.ladder_apply_app_navigation_drop_down__app-navigation-drop-down-styles__icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25.0px;
  height: 25.0px;
}

.ladder_apply_app_navigation_drop_down__app-navigation-drop-down-styles__icon {
  width: 20px;
  height: 20px;
}
.ladder_admin_widget__FeatureFlags-styles__root {
  color: #1F4972;
  background-color: rgba(255, 255, 255, 0.4);
}
.ladder_guide_types_of_life_insurance__quote-section-styles__container {
  width: 100%;
  background-color: #BBDAD7;
  position: relative;
}

.ladder_guide_types_of_life_insurance__quote-section-styles__markdown-container {
  position: relative;
}

.ladder_guide_types_of_life_insurance__quote-section-styles__image {
  position: absolute;
  right: 0;
  bottom: 0;
}

@media (min-width: 1025px) {

  
  
  .ladder_guide_types_of_life_insurance__quote-section-styles__image {
    width: 60%;
  }
  
  .ladder_guide_types_of_life_insurance__quote-section-styles__container {
    padding-bottom: 31%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_guide_types_of_life_insurance__quote-section-styles__container {
    margin-top: 60px;
    padding-top: 60px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_guide_types_of_life_insurance__quote-section-styles__container {
    margin-top: 60px;
    padding-top: 60px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_guide_types_of_life_insurance__quote-section-styles__image {
    width: 66%;
  }
  
  .ladder_guide_types_of_life_insurance__quote-section-styles__container {
    padding-bottom: 28%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_guide_types_of_life_insurance__quote-section-styles__container {
    margin-top: 30.0px;
    padding-top: 30.0px;
    padding-bottom: 62%;
  }
  
  .ladder_guide_types_of_life_insurance__quote-section-styles__image {
    width: 100%;
  }

}
.ladder_account__header-styles__policies-root {
  width: 100%;
  display: flex;
  max-width: 700px;
  align-items: center;
  box-sizing: border-box;
  flex-direction: column;
  margin-top: 20px;
}
.ladder_footer__footer-column-styles__root {
  padding: 0 10px;
  min-width: 100px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.ladder_footer__footer-column-styles__double {
  min-width: 250px;
  flex-grow: 1;
}

.ladder_footer__footer-column-styles__centered {
  text-align: center;
}

.ladder_footer__footer-column-styles__full {
  min-width: 100%;
  flex-grow: 1;
}

@media (max-width: 499px) {

  
  
  .ladder_footer__footer-column-styles__root {
    padding: 0;
  }

}
.ladder_onboarding_shared_components__two-panels-style__panel-bg {
  background-color: #E7EEEF;
}

@media (max-width: 499px) {

  
  
  .ladder_onboarding_shared_components__two-panels-style__panel {
    padding-right: 5%;
    padding-left: 5%;
  }
  
  .ladder_onboarding_shared_components__two-panels-style__panel-reduce-padding {
    padding-right: 5%;
    padding-left: 5%;
  }
  
  .ladder_onboarding_shared_components__two-panels-style__panel-1 {
    padding-top: 36px;
    padding-bottom: 30.0px;
  }
  
  .ladder_onboarding_shared_components__two-panels-style__panel-2 {
    padding-top: 50.0px;
    padding-bottom: 190.0px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_onboarding_shared_components__two-panels-style__panel {
    padding-right: 5%;
    padding-left: 5%;
  }
  
  .ladder_onboarding_shared_components__two-panels-style__panel-reduce-padding {
    padding-right: 5%;
    padding-left: 5%;
  }
  
  .ladder_onboarding_shared_components__two-panels-style__panel-1 {
    padding-top: 36px;
    padding-bottom: 30.0px;
  }
  
  .ladder_onboarding_shared_components__two-panels-style__panel-2 {
    padding-top: 50.0px;
    padding-bottom: 190.0px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_onboarding_shared_components__two-panels-style__root {
    display: flex;
  }
  
  .ladder_onboarding_shared_components__two-panels-style__panel {
    padding-top: 60px;
    padding-right: 110.0px;
    padding-bottom: 290.0px;
    padding-left: 80px;
  }
  
  .ladder_onboarding_shared_components__two-panels-style__panel-reduce-padding {
    padding-top: 60px;
    padding-left: 80px;
  }

}
.ladder_cover_me_asap_views_billing__root-styles__header {
  display: flex;
  margin: -100px 0;
}

.ladder_cover_me_asap_views_billing__root-styles__view-root {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

.ladder_cover_me_asap_views_billing__root-styles__left-column {
  display: flex;
  justify-content: center;
  min-height: 100%;
  width: 100%;
  background-color: #F7F2EF;
}

.ladder_cover_me_asap_views_billing__root-styles__left-column .ladder_cover_me_asap_views_billing__root-styles__partner {
  background-color: #F6F6F6;
}

.ladder_cover_me_asap_views_billing__root-styles__left-column .ladder_cover_me_asap_views_billing__root-styles__left-column-container {
  display: flex;
  flex-direction: column;
  max-width: 550px;
}

.ladder_cover_me_asap_views_billing__root-styles__left-column .ladder_cover_me_asap_views_billing__root-styles__what-you-are-apply-for-text {
  margin: 0 0 20px 0;
}

.ladder_cover_me_asap_views_billing__root-styles__left-column .ladder_cover_me_asap_views_billing__root-styles__coverage-container {
  display: flex;
  flex-direction: column;
  height: 98px;
  justify-content: space-around;
}

.ladder_cover_me_asap_views_billing__root-styles__left-column .ladder_cover_me_asap_views_billing__root-styles__coverage-container-amount {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ladder_cover_me_asap_views_billing__root-styles__left-column .ladder_cover_me_asap_views_billing__root-styles__coverage-container-term {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ladder_cover_me_asap_views_billing__root-styles__left-column .ladder_cover_me_asap_views_billing__root-styles__price-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 54px;
  margin: 10.0px 0;
}

.ladder_cover_me_asap_views_billing__root-styles__left-column .ladder_cover_me_asap_views_billing__root-styles__billing-affordability-price-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 2px solid #749475;
  border-radius: 8px;
  background-color: #DBE6DB;
  padding: 25px;
  margin-top: 10px;
}

.ladder_cover_me_asap_views_billing__root-styles__left-column .ladder_cover_me_asap_views_billing__root-styles__price-container-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ladder_cover_me_asap_views_billing__root-styles__left-column .ladder_cover_me_asap_views_billing__root-styles__monthly-price {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.ladder_cover_me_asap_views_billing__root-styles__left-column .ladder_cover_me_asap_views_billing__root-styles__edit-coverage-details-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px 0 30.0px 0;
}

.ladder_cover_me_asap_views_billing__root-styles__right-column {
  display: flex;
  justify-content: center;
  min-height: 100%;
}

.ladder_cover_me_asap_views_billing__root-styles__right-column .ladder_cover_me_asap_views_billing__root-styles__right-column-container {
  display: flex;
  flex-direction: column;
  max-width: 550px;
}

.ladder_cover_me_asap_views_billing__root-styles__right-column .ladder_cover_me_asap_views_billing__root-styles__payment-container {
  display: flex;
  flex-direction: column;
}

.ladder_cover_me_asap_views_billing__root-styles__right-column .ladder_cover_me_asap_views_billing__root-styles__payment-container .ladder_cover_me_asap_views_billing__root-styles__payment-error {
  margin-top: 15.0px;
}

.ladder_cover_me_asap_views_billing__root-styles__right-column .ladder_cover_me_asap_views_billing__root-styles__payment-container .ladder_cover_me_asap_views_billing__root-styles__payment-error > div {
  border-color: #D20D0D;
}

.ladder_cover_me_asap_views_billing__root-styles__right-column .ladder_cover_me_asap_views_billing__root-styles__line-break {
  margin: 42px 0;
}

.ladder_cover_me_asap_views_billing__root-styles__right-column .ladder_cover_me_asap_views_billing__root-styles__your-happiness-matters-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ladder_cover_me_asap_views_billing__root-styles__right-column .ladder_cover_me_asap_views_billing__root-styles__your-happiness-matters-container-text {
  display: flex;
  flex-direction: column;
}

.ladder_cover_me_asap_views_billing__root-styles__right-column .ladder_cover_me_asap_views_billing__root-styles__your-happiness-matters-container-text-title {
  margin-bottom: 10.0px;
}

.ladder_cover_me_asap_views_billing__root-styles__right-column .ladder_cover_me_asap_views_billing__root-styles__stamp-svg {
  width: 100px;
  height: 100px;
}

.ladder_cover_me_asap_views_billing__root-styles__right-column .ladder_cover_me_asap_views_billing__root-styles__submit-disclaimer-container {
  display: flex;
  flex-direction: column;
}

.ladder_cover_me_asap_views_billing__root-styles__right-column .ladder_cover_me_asap_views_billing__root-styles__submit-button-container {
  display: flex;
  flex-direction: column;
}

.ladder_cover_me_asap_views_billing__root-styles__right-column .ladder_cover_me_asap_views_billing__root-styles__no-cancellation-fee-img {
  width: 114px;
  height: 114px;
  margin-top: 10.0px;
  margin-left: 10.0px;
}

@media (min-width: 1025px) {

  
  
  .ladder_cover_me_asap_views_billing__root-styles__view-root {
    min-height: 100%;
  }
  
  .ladder_cover_me_asap_views_billing__root-styles__left-column {
    width: 40%;
    height: 100%;
    padding-top: 140px;
  }
  
  .ladder_cover_me_asap_views_billing__root-styles__left-column-container {
    width: 80%;
  }
  
  .ladder_cover_me_asap_views_billing__root-styles__right-column {
    width: 60%;
    height: 100%;
    padding-top: 140px;
  }
  
  .ladder_cover_me_asap_views_billing__root-styles__right-column-container {
    max-width: 550px;
  }
  
  .ladder_cover_me_asap_views_billing__root-styles__submit-and-pay-button {
    margin: 30.0px 0 30.0px 0;
    max-width: 400px;
  }
  
  .ladder_cover_me_asap_views_billing__root-styles__resource-center-link {
    max-width: 400px;
    margin-bottom: 100px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_cover_me_asap_views_billing__root-styles__header {
    margin: -60px 0;
  }
  
  .ladder_cover_me_asap_views_billing__root-styles__view-root {
    flex-direction: column;
    align-items: center;
    min-height: unset;
  }
  
  .ladder_cover_me_asap_views_billing__root-styles__left-column {
    min-height: unset;
  }
  
  .ladder_cover_me_asap_views_billing__root-styles__right-column {
    min-height: unset;
  }
  
  .ladder_cover_me_asap_views_billing__root-styles__left-column-container {
    width: 90%;
    margin: 100px 0 30.0px 0;
  }
  
  .ladder_cover_me_asap_views_billing__root-styles__right-column-container {
    align-items: center;
    width: 100%;
    max-width: 800px;
  }
  
  .ladder_cover_me_asap_views_billing__root-styles__right-column-container:last-child {
    margin-bottom: -30px;
  }
  
  .ladder_cover_me_asap_views_billing__root-styles__payment-container {
    margin: 20px 0;
    width: 90%;
  }
  
  .ladder_cover_me_asap_views_billing__root-styles__your-happiness-matters-container {
    width: 90%;
  }
  
  .ladder_cover_me_asap_views_billing__root-styles__submit-disclaimer-container {
    width: 90%;
    margin-bottom: 42px;
  }
  
  .ladder_cover_me_asap_views_billing__root-styles__submit-button-container {
    width: 100%;
    bottom: 0px;
    background: #F8F8F8;
    border-top: 1px solid #D0D0D0;
  }
  
  .ladder_cover_me_asap_views_billing__root-styles__submit-and-pay-button {
    max-width: 90%;
    margin: 10px 0 10px 5%;
  }
  
  .ladder_cover_me_asap_views_billing__root-styles__resource-center-link {
    max-width: 90%;
    margin-left: 5%;
    text-align: left;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_cover_me_asap_views_billing__root-styles__header {
    margin: -60px 0;
  }
  
  .ladder_cover_me_asap_views_billing__root-styles__view-root {
    flex-direction: column;
    align-items: center;
    min-height: unset;
  }
  
  .ladder_cover_me_asap_views_billing__root-styles__left-column {
    min-height: unset;
  }
  
  .ladder_cover_me_asap_views_billing__root-styles__right-column {
    min-height: unset;
  }
  
  .ladder_cover_me_asap_views_billing__root-styles__left-column-container {
    width: 90%;
    margin: 100px 0 30.0px 0;
  }
  
  .ladder_cover_me_asap_views_billing__root-styles__right-column-container {
    align-items: center;
    width: 100%;
    max-width: 800px;
  }
  
  .ladder_cover_me_asap_views_billing__root-styles__right-column-container:last-child {
    margin-bottom: -30px;
  }
  
  .ladder_cover_me_asap_views_billing__root-styles__payment-container {
    margin: 20px 0;
    width: 90%;
  }
  
  .ladder_cover_me_asap_views_billing__root-styles__your-happiness-matters-container {
    width: 90%;
  }
  
  .ladder_cover_me_asap_views_billing__root-styles__submit-disclaimer-container {
    width: 90%;
    margin-bottom: 42px;
  }
  
  .ladder_cover_me_asap_views_billing__root-styles__submit-button-container {
    width: 100%;
    bottom: 0px;
    background: #F8F8F8;
    border-top: 1px solid #D0D0D0;
  }
  
  .ladder_cover_me_asap_views_billing__root-styles__submit-and-pay-button {
    max-width: 90%;
    margin: 10px 0 10px 5%;
  }
  
  .ladder_cover_me_asap_views_billing__root-styles__resource-center-link {
    max-width: 90%;
    margin-left: 5%;
    text-align: left;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_cover_me_asap_views_billing__root-styles__no-cancellation-fee-img {
    display: none;
  }
  
  .ladder_cover_me_asap_views_billing__root-styles__in-funnel-survey-link {
    display: flex;
    justify-content: center;
    margin: 10px 0 10px 0;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_cover_me_asap_views_billing__root-styles__header {
    margin: -60px 0;
  }
  
  .ladder_cover_me_asap_views_billing__root-styles__right-column-container {
    align-items: flex-start;
  }
  
  .ladder_cover_me_asap_views_billing__root-styles__your-happiness-matters-container {
    width: 100%;
  }
  
  .ladder_cover_me_asap_views_billing__root-styles__submit-disclaimer-container {
    width: 100%;
  }

}
.ladder_account_shared_components__elevated-component-styles__root {
  text-align: center;
}

.ladder_account_shared_components__elevated-component-styles__warning-text {
  padding-bottom: 20px;
}
a {
  text-decoration: none;
}
.ladder_account_next_cards_gift_page_link__gift-page-link-card-styles__\% {
  width: 100%;
  justify-content: space-between;
  height: 220px;
  margin: 16px 0;
  flex-direction: row;
  background-color: #E7EEEF;
  border-radius: 8px;
  display: flex;
  padding-left: 40px;
}

@media (max-width: 499px) {

  
  
  .ladder_account_next_cards_gift_page_link__gift-page-link-card-styles__\% {
    width: 100%;
    height: unset;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account_next_cards_gift_page_link__gift-page-link-card-styles__\% {
    width: 100%;
    height: unset;
  }

}

.ladder_account_next_cards_gift_page_link__gift-page-link-card-styles__col {
  display: flex;
  flex-direction: column;
}

.ladder_account_next_cards_gift_page_link__gift-page-link-card-styles__img-container {
  height: 100%;
  padding: 20px;
}

.ladder_account_next_cards_gift_page_link__gift-page-link-card-styles__row {
  display: flex;
  flex-direction: row;
  padding: 20px 0;
}

.ladder_account_next_cards_gift_page_link__gift-page-link-card-styles__button {
  display: flex;
}

.ladder_account_next_cards_gift_page_link__gift-page-link-card-styles__spacer {
  margin-top: 25.0px;
}
.ladder_form_uplevel__uplevel-slide-up-styles__slide-up {
  position: relative;
}

.ladder_form_uplevel__uplevel-slide-up-styles__slide-up-animation-control {
  animation: slide-up-animation 500ms 1 forwards;
  opacity: 0;
}

.ladder_form_uplevel__uplevel-slide-up-styles__text-delay {
  animation-delay: 700ms;
}

.ladder_form_uplevel__uplevel-slide-up-styles__subtext-delay {
  animation-delay: 800ms;
}

@keyframes slide-up-animation {

0% {
    opacity: 0;
    top: 50px;
  }
  
55% {
    opacity: 1;
    top: 0px;
    animation-timing-function: ease;
  }
  
70% {
    opacity: 1;
    top: 3px;
    animation-timing-function: ease;
  }
  
85% {
    opacity: 1;
    top: 1px;
    animation-timing-function: ease;
  }
  
100% {
    opacity: 1;
    top: 2px;
    animation-timing-function: ease;
  }

}
.ladder_onboarding_spouse_connect_2__two-panels-layout-styles__panel-bg {
  background-color: #E7EEEF;
}

@media (max-width: 499px) {

  
  
  .ladder_onboarding_spouse_connect_2__two-panels-layout-styles__panel {
    padding-right: 5%;
    padding-left: 5%;
  }
  
  .ladder_onboarding_spouse_connect_2__two-panels-layout-styles__panel-reduce-padding {
    padding-right: 5%;
    padding-left: 5%;
  }
  
  .ladder_onboarding_spouse_connect_2__two-panels-layout-styles__panel-1 {
    padding-top: 36px;
    padding-bottom: 24.0px;
  }
  
  .ladder_onboarding_spouse_connect_2__two-panels-layout-styles__panel-2 {
    padding-top: 40.0px;
    padding-bottom: 0px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_onboarding_spouse_connect_2__two-panels-layout-styles__panel {
    padding-right: 5%;
    padding-left: 5%;
  }
  
  .ladder_onboarding_spouse_connect_2__two-panels-layout-styles__panel-reduce-padding {
    padding-right: 5%;
    padding-left: 5%;
  }
  
  .ladder_onboarding_spouse_connect_2__two-panels-layout-styles__panel-1 {
    padding-top: 36px;
    padding-bottom: 24.0px;
  }
  
  .ladder_onboarding_spouse_connect_2__two-panels-layout-styles__panel-2 {
    padding-top: 40.0px;
    padding-bottom: 0px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_onboarding_spouse_connect_2__two-panels-layout-styles__root {
    display: flex;
  }
  
  .ladder_onboarding_spouse_connect_2__two-panels-layout-styles__panel {
    padding-top: 60px;
    padding-right: 88.0px;
    padding-bottom: 232.0px;
    padding-left: 64px;
  }
  
  .ladder_onboarding_spouse_connect_2__two-panels-layout-styles__panel-reduce-padding {
    padding-top: 60px;
    padding-left: 64px;
  }

}
.ladder_text__subtitle-styles__root {

}
.ladder_landing_pages_so_good_core__got-5-minutes-styles__root {
  width: 100%;
  padding-bottom: 192px;
  justify-content: center;
  position: relative;
  z-index: 1;
  overflow: hidden;
  display: flex;
  background: #F7F2EF;
  padding-top: 192px;
}

.ladder_landing_pages_so_good_core__got-5-minutes-styles__text-container {
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 1400px;
}

.ladder_landing_pages_so_good_core__got-5-minutes-styles__image-container {
  z-index: -1;
  margin-top: 250px;
}

.ladder_landing_pages_so_good_core__got-5-minutes-styles__image {
  width: 50%;
  right: 0;
  max-width: 1500px;
  height: auto;
  position: absolute;
  object-fit: contain;
  object-position: right;
  bottom: 0;
  max-height: 90%;
}

.ladder_landing_pages_so_good_core__got-5-minutes-styles__five-minutes-subtext {
  margin-top: 8.0px;
}

.ladder_landing_pages_so_good_core__got-5-minutes-styles__button {
  margin-top: 32px;
  width: 192px;
}

@media (min-width: 1025px) {

  
  
  .ladder_landing_pages_so_good_core__got-5-minutes-styles__five-minutes-subtext {
    width: 50%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_landing_pages_so_good_core__got-5-minutes-styles__five-minutes-subtext {
    width: 50%;
  }
  
  .ladder_landing_pages_so_good_core__got-5-minutes-styles__image {
    width: 70%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_landing_pages_so_good_core__got-5-minutes-styles__root {
    padding-top: 64px;
  }
  
  .ladder_landing_pages_so_good_core__got-5-minutes-styles__image-container {
    overflow: hidden;
    margin-top: 420px;
  }
  
  .ladder_landing_pages_so_good_core__got-5-minutes-styles__text-container {
    margin: 36px auto 0;
    width: 90%;
    max-width: 100%;
  }
  
  .ladder_landing_pages_so_good_core__got-5-minutes-styles__image {
    width: 130%;
    display: flex;
    right: -8%;
  }

}
.ladder_beneficiary_form_modify_percentage__modify-percentage-shared-styles__modify-percentage-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #D0D0D0;
  padding: 10.0px 0;
}

.ladder_beneficiary_form_modify_percentage__modify-percentage-shared-styles__no-border-bottom {
  border-bottom: none;
}

.ladder_beneficiary_form_modify_percentage__modify-percentage-shared-styles__amount-dollars {
  flex-basis: 26%;
}

.ladder_beneficiary_form_modify_percentage__modify-percentage-shared-styles__beneficiary-name {
  flex-basis: 40%;
}

.ladder_beneficiary_form_modify_percentage__modify-percentage-shared-styles__pct-input {
  flex-basis: 14%;
}

@media (max-width: 499px) {

  
  
  .ladder_beneficiary_form_modify_percentage__modify-percentage-shared-styles__pct-input {
    flex-basis: 32%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_beneficiary_form_modify_percentage__modify-percentage-shared-styles__pct-input {
    flex-basis: 32%;
  }

}
.ladder_megaphone_refer__how-it-works-panel-styles__how-it-works-panel {
  display: flex;
  flex-direction: column;
}

.ladder_megaphone_refer__how-it-works-panel-styles__image-container {
  display: flex;
  align-items: center;
  max-width: 240px;
  height: 200px;
}

.ladder_megaphone_refer__how-it-works-panel-styles__image {
  max-height: 100%;
  max-width: 100%;
  margin-left: 0;
  margin-top: 0;
}

@media (max-width: 499px) {

  
  
  .ladder_megaphone_refer__how-it-works-panel-styles__how-it-works-panel {
    align-items: center;
    margin: 15.0px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_megaphone_refer__how-it-works-panel-styles__how-it-works-panel {
    align-items: center;
    margin: 15.0px;
  }

}
.ladder_view_schedule__hiv-form-inputs-styles__label {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 20px;
}

.ladder_view_schedule__hiv-form-inputs-styles__margin-top {
  margin-top: 20px;
}
.ladder_aimcor_form__time-slot-picker-styles__container {
  display: flex;
}

@media (min-width: 1025px) {

  
  
  .ladder_aimcor_form__time-slot-picker-styles__container {
    justify-content: space-between;
    margin-top: 5.0px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_aimcor_form__time-slot-picker-styles__container {
    justify-content: space-between;
    margin-top: 5.0px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_aimcor_form__time-slot-picker-styles__container {
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 15.0px;
  }

}
.ladder_markdoc_common__callout-comp-styles__callout {
  padding: 1em;
  margin: 1.5em 0;
  border-radius: 6px;
}

.ladder_markdoc_common__callout-comp-styles__info {
  background-color: #8fbcd4;
}

.ladder_markdoc_common__callout-comp-styles__warning {
  background-color: #c97a7e;
}
.ladder_apply_current_page_ui__standard-app-enhanced-buttons-container-styles__scrollable-button-wrapper {
  margin: 16px 0;
  width: 100%;
}

.ladder_apply_current_page_ui__standard-app-enhanced-buttons-container-styles__button-wrapper {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
}

.ladder_apply_current_page_ui__standard-app-enhanced-buttons-container-styles__geico-life-quotes-button {
  background-color: #3B8341;
  margin: 5.0px;
  padding: 5.0px;
  justify-content: center;
  text-align: center;
}

.ladder_apply_current_page_ui__standard-app-enhanced-buttons-container-styles__geico-life-quotes-button-text {
  color: #FFFFFF;
}

.ladder_apply_current_page_ui__standard-app-enhanced-buttons-container-styles__button-wrapper-cta-fixed-bottom {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  width: 50px;
}

.ladder_apply_current_page_ui__standard-app-enhanced-buttons-container-styles__app-enhancements-button-wrapper {
  margin: auto;
}

@media (max-width: 499px) {

  
  
  .ladder_apply_current_page_ui__standard-app-enhanced-buttons-container-styles__button-wrapper-flex-end {
    display: flex;
    justify-content: flex-end;
  }

}
.ladder_form_funhouse__ny-welcome-styles__welcome {
  max-width: 64%;
  margin-bottom: 20px;
}

.ladder_form_funhouse__ny-welcome-styles__text {
  margin-top: 20px;
  line-height: 2em;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_form_funhouse__ny-welcome-styles__welcome {
    max-height: 40%;
  }

}
.ladder_form_upper_funnel_routing__how-does-your-ladder-quote-compare-panel-styles__wrapper {
  display: flex;
  flex-direction: column;
  width: max-width;
  padding-bottom: 10.0px;
  border-bottom: solid 0.5px;
  border-color: #D9D9D9;
  margin-right: 30.0px;
}

.ladder_form_upper_funnel_routing__how-does-your-ladder-quote-compare-panel-styles__row {
  display: flex;
  max-width: 640px;
  margin-bottom: 30.0px;
}

.ladder_form_upper_funnel_routing__how-does-your-ladder-quote-compare-panel-styles__icon {
  width: 30.0px;
  justify-content: center;
  height: 30.0px;
  margin-right: 10.0px;
  align-items: center;
  padding: 8px;
  background-color: #F7F2EF;
  border-radius: 50%;
  display: flex;
}

.ladder_form_upper_funnel_routing__how-does-your-ladder-quote-compare-panel-styles__top-row {
  margin-bottom: 15.0px;
  max-width: 640px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_form_upper_funnel_routing__how-does-your-ladder-quote-compare-panel-styles__wrapper {
    margin-right: unset;
    border-bottom: unset;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_form_upper_funnel_routing__how-does-your-ladder-quote-compare-panel-styles__wrapper {
    margin-right: unset;
    border-bottom: unset;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_form_upper_funnel_routing__how-does-your-ladder-quote-compare-panel-styles__wrapper {
    margin-right: unset;
    justify-content: center;
    padding-bottom: 20px;
    border-bottom: unset;
  }
  
  .ladder_form_upper_funnel_routing__how-does-your-ladder-quote-compare-panel-styles__top-row {
    margin-bottom: 15.0px;
    max-width: 315px;
  }
  
  .ladder_form_upper_funnel_routing__how-does-your-ladder-quote-compare-panel-styles__row {
    display: flex;
    max-width: 315px;
    margin-bottom: 30.0px;
  }
  
  .ladder_form_upper_funnel_routing__how-does-your-ladder-quote-compare-panel-styles__row:last-of-type {
    margin-bottom: 0;
  }

}
.ladder_account__application-progress-bar-styles__status-bar-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 25px 0 45px 0;
  width: 100%;
}

.ladder_account__application-progress-bar-styles__icon {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  position: relative;
}

.ladder_account__application-progress-bar-styles__selected-icon {
  border-radius: 50%;
  border: 25px solid #FE4747;
}

.ladder_account__application-progress-bar-styles__active-color-tan .ladder_account__application-progress-bar-styles__selected-icon {
  border-color: #D1A98F;
}

.ladder_account__application-progress-bar-styles__svg-container {
  height: 24px;
}

.ladder_account__application-progress-bar-styles__selected-svg-container {
  min-height: 27px;
}

.application-status-submitted svg {
  min-width: 26px;
}

.application-status-health-check svg {
  min-width: 29px;
}

.application-status-processing svg {
  min-width: 32px;
}

.application-status-review svg {
  min-width: 26px;
}

.application-status-decision svg {
  min-width: 22px;
}

.ladder_account__application-progress-bar-styles__connector {
  height: 3px;
  width: 75px;
  flex-shrink: 1;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  margin: -2px;
}

.ladder_account__application-progress-bar-styles__active-bar {
  background-color: #FE4747;
  height: 100%;
  width: 100%;
}

.ladder_account__application-progress-bar-styles__active-color-tan .ladder_account__application-progress-bar-styles__active-bar {
  background-color: #D1A98F;
}

.ladder_account__application-progress-bar-styles__active-half-bar {
  background-color: #FE4747;
  height: 100%;
  width: 15%;
  position: absolute;
  border-radius: 2px;
}

.ladder_account__application-progress-bar-styles__active-color-tan .ladder_account__application-progress-bar-styles__active-half-bar {
  background-color: #D1A98F;
}

.ladder_account__application-progress-bar-styles__inactive-bar {
  background-color: #D0D0D0;
  height: 100%;
  width: 100%;
}

.ladder_account__application-progress-bar-styles__status-icon-container {
  position: relative;
}

.ladder_account__application-progress-bar-styles__status-description-text {
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(100%);
  white-space: nowrap;
  bottom: 0;
}

.ladder_account__application-progress-bar-styles__selected-description-text {
  font-size: 16px;
  font-weight: 700;
  bottom: -12px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account__application-progress-bar-styles__status-bar-container {
    padding: 0 15px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_account__application-progress-bar-styles__status-bar-container {
    padding: 0;
  }
  
  .ladder_account__application-progress-bar-styles__svg-container {
    height: 20px;
  }
  
  .ladder_account__application-progress-bar-styles__selected-svg-container {
    min-height: 23px;
  }
  
  .ladder_account__application-progress-bar-styles__icon {
    width: 42px;
    border-width: 21px;
    height: 42px;
  }
  
  .ladder_account__application-progress-bar-styles__status-description-text {
    font-size: 8px;
    line-height: 1em;
  }
  
  .ladder_account__application-progress-bar-styles__selected-description-text {
    font-size: 12px;
    bottom: -10px;
  }

}
.ladder_requestion_review_ui__ReQReviewBanner-styles__entire-banner {
  display: flex;
  margin-bottom: 10px;
}

.ladder_requestion_review_ui__ReQReviewBanner-styles__header-container {
  display: flex;
  flex-grow: 1;
  justify-content: left;
  align-items: center;
  gap: 8px;
}

@media (max-width: 499px) {

  
  
  .ladder_requestion_review_ui__ReQReviewBanner-styles__inner-banner-container {
    margin-left: 6%;
    margin-right: 6%;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_requestion_review_ui__ReQReviewBanner-styles__entire-banner {
    justify-content: center;
    max-width: 600px;
  }
  
  .ladder_requestion_review_ui__ReQReviewBanner-styles__inner-banner-container {
    margin-left: 35px;
    margin-right: 35px;
  }

}
.ladder_mediaalpha_views__mediaalpha-ads-styles__mediaalpha-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ladder_mediaalpha_views__mediaalpha-ads-styles__family-stroll-wrapper {
  border-radius: 30px;
  background-color: #E7EEEF;
  align-items: center;
  justify-content: center;
  display: flex;
}

.ladder_mediaalpha_views__mediaalpha-ads-styles__family-stroll-icon {
  transform: scaleX(-1);
}

.ladder_mediaalpha_views__mediaalpha-ads-styles__spinner {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 282px;
}

@media (min-width: 1025px) {

  
  
  .ladder_mediaalpha_views__mediaalpha-ads-styles__mediaalpha-container {
    height: 100%;
  }
  
  .ladder_mediaalpha_views__mediaalpha-ads-styles__family-stroll-wrapper {
    flex-basis: 50%;
    max-height: 70%;
    width: 658px;
    height: 658px;
    margin-right: 10%;
  }
  
  .ladder_mediaalpha_views__mediaalpha-ads-styles__family-stroll-icon {
    width: 556px;
    height: 313px;
    max-height: 70%;
    max-width: 70%;
  }
  
  .ladder_mediaalpha_views__mediaalpha-ads-styles__ads-wrapper {
    flex-basis: 50%;
    margin-top: 30px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_mediaalpha_views__mediaalpha-ads-styles__mediaalpha-container {
    height: 100%;
  }
  
  .ladder_mediaalpha_views__mediaalpha-ads-styles__family-stroll-wrapper {
    flex-basis: 50%;
    max-height: 70%;
    width: 658px;
    height: 658px;
    margin-right: 10%;
  }
  
  .ladder_mediaalpha_views__mediaalpha-ads-styles__family-stroll-icon {
    width: 556px;
    height: 313px;
    max-height: 70%;
    max-width: 70%;
  }
  
  .ladder_mediaalpha_views__mediaalpha-ads-styles__ads-wrapper {
    flex-basis: 50%;
    margin-top: 30px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_mediaalpha_views__mediaalpha-ads-styles__mediaalpha-container {
    flex-direction: column;
    padding: 30px;
  }
  
  .ladder_mediaalpha_views__mediaalpha-ads-styles__family-stroll-wrapper {
    margin-top: 15px;
    width: 100%;
    height: 310px;
  }
  
  .ladder_mediaalpha_views__mediaalpha-ads-styles__family-stroll-icon {
    width: 90%;
  }

}
.ladder_ux_form__select-styles__border-container {
  border: 1px solid #D0D0D0;
  display: flex;
  position: relative;
}

.ladder_ux_form__select-styles__border-container::after {
  background-position: center;
  right: 0;
  border-left: 1px solid #D0D0D0;
  top: 0;
  background-repeat: no-repeat;
  font-family: Montserrat;
  align-items: center;
  padding: 1rem;
  speak: none;
  position: absolute;
  font-weight: bold;
  content: '';
  background-size: 12px 8px;
  background-color: #FFFFFF;
  display: flex;
  bottom: 0;
  pointer-events: none;
  background-image: url(../img/down-arrow.svg);
  color: #707070;
}

.ladder_ux_form__select-styles__border-container:hover {
  color: #707070;
  border-color: #CF967E;
}

.ladder_ux_form__select-styles__border-container:hover::after {
  color: #707070;
  border-color: #CF967E;
}

.ladder_ux_form__select-styles__border-container.ladder_ux_form__select-styles__disabled:hover {
  border-color: #D0D0D0;
}

.ladder_ux_form__select-styles__border-container.ladder_ux_form__select-styles__disabled:hover::after {
  border-color: #D0D0D0;
}

.ladder_ux_form__select-styles__border-container [disabled] {
  background: #F4F4F4;
  border-radius: 0;
  color: gray;
  border-color: transparent;
}

.ladder_ux_form__select-styles__border-container .ladder_ux_form__select-styles__rungs>select {
  background-color: transparent;
}

.ladder_ux_form__select-styles__border-container [disabled] {
  background-color: transparent;
}

.ladder_ux_form__select-styles__border-container-text-input {
  box-sizing: border-box;
  height: 48px;
}

.ladder_ux_form__select-styles__active {
  color: #000000;
  border-color: #CF967E;
  border-width: 2.0px;
}

.ladder_ux_form__select-styles__active::after {
  color: #707070;
  border-color: #CF967E;
}

.ladder_ux_form__select-styles__border-error {
  border-color: #FE4747;
}

.ladder_ux_form__select-styles__border-error::after {
  border-right-color: #FE4747;
  color: #FE4747;
}

.ladder_ux_form__select-styles__full-width {
  width: 100%;
}

.ladder_ux_form__select-styles__full-height {
  height: 100%;
}

.ladder_ux_form__select-styles__text-family-lato {
  font-family: Lato, sans-serif;
}

.ladder_ux_form__select-styles__text-family-work-sans {
  font-family: Work Sans, sans-serif;
}

.ladder_ux_form__select-styles__text-family-montserrat {
  font-family: Montserrat, sans-serif;
}

.ladder_ux_form__select-styles__text-family-tt-norms {
  font-family: TTNorms, sans-serif;
}

.ladder_ux_form__select-styles__text-family-larsseit {
  font-family: Larsseit, sans-serif;
}

.ladder_ux_form__select-styles__text-family-sailec {
  font-family: Sailec, sans-serif;
}

.ladder_ux_form__select-styles__root {
  appearance: none;
  max-width: 100%;
  height: 46px;
  -ms-appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: none;
  vertical-align: middle;
  padding: 0 0 0 0.5em;
  outline: 0;
  font-weight: 300;
  font-size: inherit;
  background-color: #FFFFFF;
  flex: 1;
  color: #000000;
}

.ladder_ux_form__select-styles__root::-ms-expand {
  display: none;
}

.ladder_ux_form__select-styles__showing-placeholder {
  color: #707070;
}

.ladder_ux_form__select-styles__showing-placeholder.ladder_ux_form__select-styles__placeholder-error {
  color: #CB3A3A;
}

.ladder_ux_form__select-styles__root-text-input {
  height: auto;
}

.ladder_ux_form__select-styles__height-s {
  height: 40px !important;
  font-size: 12px;
}

.ladder_ux_form__select-styles__height-m {
  height: 48px !important;
  font-size: 14px;
}

.ladder_ux_form__select-styles__overflow-hidden {
  overflow: hidden;
}

.ladder_ux_form__select-styles__root-floating-placeholder {
  padding-top: 10px;
}

.ladder_ux_form__select-styles__root-floating-placeholder option:first-child[disabled] {
  color: orange;
}

.ladder_ux_form__select-styles__floating-placeholder-wrapper {
  position: absolute;
  height: 100%;
  padding: 0 0.5em;
  font-weight: 300;
  font-size: inherit;
  background-color: #FFFFFF;
  pointer-events: none;
  z-index: 2;
}

.ladder_ux_form__select-styles__floating-placeholder-wrapper>* {
  position: relative;
  top: 16px;
  line-height: 18px;
  color: #707070;
  transition-property: font-size, top, color;
}

.ladder_ux_form__select-styles__floating-placeholder-wrapper-isfloating {
  background-color: rgba(255, 255, 255, 0);
}

.ladder_ux_form__select-styles__floating-placeholder-wrapper-isfloating>* {
  top: 0px;
  font-size: 10px;
  color: #000000;
}

.ladder_ux_form__select-styles__pointer {
  cursor: pointer;
}

.ladder_ux_form__select-styles__border-container.ladder_ux_form__select-styles__rungs {
  height: 64px;
  align-items: center;
}

.ladder_ux_form__select-styles__border-container.ladder_ux_form__select-styles__rungs:after {
  content: '\00A0\00A0';
  font-family: Arial;
  background-size: 100% 100%;
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 10 10' xmlns='http://www.w3.org/2000/svg'%3E%3Ctext x='0' y='10' fill=' %23707070 ' font-family='Arial'%3E▾%3C/text%3E%3C/svg%3E");
  background-origin: content-box;
  border: none;
}

.ladder_ux_form__select-styles__border-container.ladder_ux_form__select-styles__rungs>* {
  padding-left: 12.0px;
}

.ladder_ux_form__select-styles__border-container.ladder_ux_form__select-styles__rungs select {
  height: 100%;
}

.ladder_ux_form__select-styles__border-container.ladder_ux_form__select-styles__rungs .ladder_ux_form__select-styles__floating-placeholder-wrapper {
  padding-right: 0;
  width: calc(100% - 25px);
  display: flex;
  align-items: center;
}

.ladder_ux_form__select-styles__border-container.ladder_ux_form__select-styles__rungs .ladder_ux_form__select-styles__floating-placeholder-wrapper-isfloating {
  display: block;
}

.ladder_ux_form__select-styles__border-container.ladder_ux_form__select-styles__rungs .ladder_ux_form__select-styles__floating-placeholder-wrapper>* {
  top: 0;
}

.ladder_ux_form__select-styles__border-container.ladder_ux_form__select-styles__rungs .ladder_ux_form__select-styles__floating-placeholder-wrapper-isfloating>* {
  top: 8px;
}

.ladder_ux_form__select-styles__border-container.ladder_ux_form__select-styles__rungs.ladder_ux_form__select-styles__height-m .ladder_ux_form__select-styles__floating-placeholder-wrapper-isfloating>* {
  top: 0;
}

.ladder_ux_form__select-styles__border-container.ladder_ux_form__select-styles__rungs.ladder_ux_form__select-styles__height-s .ladder_ux_form__select-styles__floating-placeholder-wrapper-isfloating>* {
  top: 0;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_form__select-styles__width-m {
    width: 90%;
  }
  
  .ladder_ux_form__select-styles__width-s {
    width: 60%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_form__select-styles__width-m {
    width: 90%;
  }
  
  .ladder_ux_form__select-styles__width-s {
    width: 60%;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_ux_form__select-styles__width-m {
    width: 470px;
  }

}

.ladder_ux_form__select-styles__remove-border {
  border: none;
}

.ladder_ux_form__select-styles__border-error.ladder_ux_form__select-styles__rungs:after {
  color: initial;
}

.box-fields .ladder_ux_form__select-styles__border-container {
  border-radius: 2.0px;
  overflow: hidden;
}

.box-fields .ladder_ux_form__select-styles__border-container select {
  border-radius: 0;
  padding-left: 14px;
}

.box-fields .ladder_ux_form__select-styles__border-container::after {
  border: none;
  background-size: 8px 5px;
}
.ladder_about__collage-styles__root {
  display: flex;
  width: 100%;
  height: auto;
  justify-content: center;
  background-color: #F7F2EF;
  margin: 200px auto -100px auto;
}

.ladder_about__collage-styles__collage {
  display: flex;
  width: 100%;
  max-width: 1800px;
  height: auto;
}

@media (max-width: 499px) {

  
  
  .ladder_about__collage-styles__root {
    margin-top: 119px;
    margin-bottom: 0px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_about__collage-styles__root {
    margin-top: 119px;
    margin-bottom: 0px;
  }

}
.ladder_views_api__use-case-element-styles__element {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.ladder_views_decision_offer__transparent-fee-info-styles__container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.ladder_views_decision_offer__transparent-fee-info-styles__stamp-container {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.ladder_views_decision_offer__transparent-fee-info-styles__stamp {
  width: 94px;
  height: 94px;
}

.ladder_views_decision_offer__transparent-fee-info-styles__list-container {
  flex-grow: 1;
  list-style: none;
  align-items: center;
}

.ladder_views_decision_offer__transparent-fee-info-styles__list-container>li {
  display: flex;
}

.ladder_views_decision_offer__transparent-fee-info-styles__icon {
  min-width: 22px;
  height: 22px;
  margin-top: 4px;
}

.ladder_views_decision_offer__transparent-fee-info-styles__caption {
  padding-left: 16px;
}

@media (max-width: 499px) {

  
  
  .ladder_views_decision_offer__transparent-fee-info-styles__stamp-container {
    display: none;
  }

}
.ladder_about__three-things-card-styles__container {
  display: flex;
  justify-content: center;
  width: 100%;
}

@media (min-width: 1025px) {

  
  
  .ladder_about__three-things-card-styles__container {
    width: 371px;
  }

}

.ladder_about__three-things-card-styles__card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 371px;
  min-height: 562px;
  padding: 100px 40px;
}

.ladder_about__three-things-card-styles__title {
  margin-top: 77px;
}

.ladder_about__three-things-card-styles__copy {
  margin-top: 20px;
}

.ladder_about__three-things-card-styles__bg-geyser {
  background-color: #CFDCDE;
}

.ladder_about__three-things-card-styles__bg-tan {
  background-color: #D1A98F;
}

.ladder_about__three-things-card-styles__bg-spring-rain {
  background-color: #AFC8AF;
}
.ladder_form_common__regular-field-styles__wrapper {
  width: 100%;
}

.ladder_form_common__regular-field-styles__breadcrumbs {
  margin-bottom: 32px;
}

@media (max-width: 499px) {

  
  
  .ladder_form_common__regular-field-styles__breadcrumbs {
    margin-bottom: 24px;
  }

}
.ladder_trustage_quote__quote-panel-styles__spinner {
  margin-top: 15.0px;
}

.ladder_trustage_quote__quote-panel-styles__panel {
  width: 100%;
  justify-content: space-between;
  border: solid 2px #D1A98F;
  align-items: center;
  padding: 20px;
  margin-top: 22.5px;
  background-color: #F7F2EF;
  border-radius: 10.0px;
  display: flex;
}

.ladder_trustage_quote__quote-panel-styles__panel.ladder_trustage_quote__quote-panel-styles__themed {
  background-color: #F1F1F1;
  border-width: 0;
}

.ladder_trustage_quote__quote-panel-styles__change-details {
  margin-top: 5.0px;
}

.ladder_trustage_quote__quote-panel-styles__go-to-trustage {
  margin-top: 22.5px;
}
.ladder_onboarding_beneficiaries__inform-email-sent-styles__\% {
  display: flex;
  align-items: center;
}

.ladder_onboarding_beneficiaries__inform-email-sent-styles__\%>*:last-child {
  margin-left: 12.0px;
}
@media (min-width: 1025px) {

  
  
  .ladder_account_next_views_overview__Title-styles__\% {
    margin-bottom: 32px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account_next_views_overview__Title-styles__\% {
    margin-bottom: 32px;
  }

}
@media (min-width: 1025px) {

  
  
  .ladder_guide_common__markdown-section-styles__container {
    width: 100%;
    margin-top: 130.0px;
  }
  
  .ladder_guide_common__markdown-section-styles__half-top-margin {
    margin-top: 60px;
  }
  
  .ladder_guide_common__markdown-section-styles__bottom-margin {
    margin-bottom: 60px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_guide_common__markdown-section-styles__container {
    width: 100%;
    margin-top: 130.0px;
  }
  
  .ladder_guide_common__markdown-section-styles__half-top-margin {
    margin-top: 60px;
  }
  
  .ladder_guide_common__markdown-section-styles__bottom-margin {
    margin-bottom: 60px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_guide_common__markdown-section-styles__bottom-margin {
    margin-bottom: 60px;
  }
  
  .ladder_guide_common__markdown-section-styles__container {
    width: 100%;
    margin-top: 60px;
  }

}
.ladder_quick_start_panels__homepage-faq-styles__root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 64px 0 128px 0;
  background-color: #AFC5C8;
}

.ladder_quick_start_panels__homepage-faq-styles__wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  max-width: 1400px;
  width: 100%;
}

.ladder_quick_start_panels__homepage-faq-styles__title {
  display: flex;
  text-align: center;
  justify-content: center;
  width: 80%;
  margin: 64px 0 32px 0;
}

.ladder_quick_start_panels__homepage-faq-styles__expand-card {
  display: flex;
  flex-direction: column;
  background-color: #E7EEEF;
  padding: 32px 32px;
  margin: 32px;
  width: 380px;
  height: 440px;
}

.ladder_quick_start_panels__homepage-faq-styles__mobile-expand-card {
  display: none;
  width: 80%;
}

.ladder_quick_start_panels__homepage-faq-styles__display {
  display: flex;
}

.ladder_quick_start_panels__homepage-faq-styles__text {
  font-size: 15px;
  line-height: 30px;
}

@media (max-width: 499px) {

  
  
  .ladder_quick_start_panels__homepage-faq-styles__mobile-expand-card {
    display: flex;
  }
  
  .ladder_quick_start_panels__homepage-faq-styles__expand-card {
    display: none;
  }
  
  .ladder_quick_start_panels__homepage-faq-styles__root {
    padding: 0 0 64px 0;
  }
  
  .ladder_quick_start_panels__homepage-faq-styles__text-wrapper {
    margin: 0 16px;
  }

}
.ladder_form_singleselection__inverse-checkbox-boolean-styles__option {
  display: flex;
  width: 100%;
  padding: 10.0px 0;
}
.ladder_views_decision_main__bike-repair-image-styles__container {

}

.ladder_views_decision_main__bike-repair-image-styles__image {
  max-width: 387px;
  width: 100%;
  height: auto;
}

@media (max-width: 499px) {

  
  
  .ladder_views_decision_main__bike-repair-image-styles__container {
    display: none;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_views_decision_main__bike-repair-image-styles__container {
    display: none;
  }

}
.ladder_account_next_views_secondary_addressee_view__view-fields-styles__fields {
  display: flex;
  flex-wrap: wrap;
}

.ladder_account_next_views_secondary_addressee_view__view-fields-styles__field {
  flex-basis: 100%;
  overflow-wrap: anywhere;
  margin-bottom: 30.0px;
}

@media (min-width: 1025px) {

  
  
  .ladder_account_next_views_secondary_addressee_view__view-fields-styles__field {
    flex-basis: calc(50% - 8px);
  }
  
  .ladder_account_next_views_secondary_addressee_view__view-fields-styles__field:nth-child(odd) {
    margin-right: 16px;
  }

}
.ladder_quick_start_panels__stability-and-resilience-for-your-family-styles__root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #F7F2EF;
}

.ladder_quick_start_panels__stability-and-resilience-for-your-family-styles__wrapper {
  display: flex;
  flex-direction: row;
  width: 90%;
  height: 100%;
  max-width: 1400px;
  position: relative;
  background-color: #F7F2EF;
  padding: 128px 0 0 0;
}

.ladder_quick_start_panels__stability-and-resilience-for-your-family-styles__title-container {
  display: flex;
  flex-direction: column;
  width: 40vw;
  margin-bottom: 128px;
  max-width: 800px;
  z-index: 1;
}

.ladder_quick_start_panels__stability-and-resilience-for-your-family-styles__title {
  display: flex;
  margin-bottom: 32px;
}

.ladder_quick_start_panels__stability-and-resilience-for-your-family-styles__text {
  margin: 8.0px 0;
}

.ladder_quick_start_panels__stability-and-resilience-for-your-family-styles__button {
  margin: 32px 0 32px 0;
}

.ladder_quick_start_panels__stability-and-resilience-for-your-family-styles__image {
  position: absolute;
  height: 50vw;
  max-height: 95%;
  width: auto;
  bottom: 0;
  right: 0;
  z-index: 0;
}

@media (max-width: 499px) {

  
  
  .ladder_quick_start_panels__stability-and-resilience-for-your-family-styles__wrapper {
    flex-direction: column;
    align-items: center;
  }
  
  .ladder_quick_start_panels__stability-and-resilience-for-your-family-styles__title-container {
    display: flex;
    width: 90%;
    max-width: 800px;
  }
  
  .ladder_quick_start_panels__stability-and-resilience-for-your-family-styles__image {
    display: flex;
    position: initial;
    width: 90%;
    max-width: 800px;
    height: auto;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_quick_start_panels__stability-and-resilience-for-your-family-styles__wrapper {
    flex-direction: column;
    align-items: center;
  }
  
  .ladder_quick_start_panels__stability-and-resilience-for-your-family-styles__title-container {
    display: flex;
    width: 90%;
    max-width: 800px;
  }
  
  .ladder_quick_start_panels__stability-and-resilience-for-your-family-styles__image {
    display: flex;
    position: initial;
    width: 90%;
    max-width: 800px;
    height: auto;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_quick_start_panels__stability-and-resilience-for-your-family-styles__wrapper {
    padding: 0;
  }
  
  .ladder_quick_start_panels__stability-and-resilience-for-your-family-styles__title-container {
    margin: 64px 0 0 0;
  }
  
  .ladder_quick_start_panels__stability-and-resilience-for-your-family-styles__title {
    margin-bottom: 18px;
  }
  
  .ladder_quick_start_panels__stability-and-resilience-for-your-family-styles__button {
    max-width: 100%;
  }
  
  .ladder_quick_start_panels__stability-and-resilience-for-your-family-styles__subtext {
    text-align: center;
    margin-bottom: 16px;
  }

}
.ladder_form_date__date-field-precision-month-styles__root {
  display: flex;
}

.ladder_form_date__date-field-precision-month-styles__month {
  flex-grow: 1;
  margin-right: 5.0px;
}

.ladder_form_date__date-field-precision-month-styles__year {
  flex-grow: 1;
  margin-left: 5.0px;
}
.ladder_view_schedule__SpotKit-styles__root {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  padding: 10.0px;
}

.ladder_view_schedule__SpotKit-styles__address-wrapper {
  padding-bottom: 10px;
}

.ladder_view_schedule__SpotKit-styles__technician-img {
  max-height: 270px;
}

.ladder_view_schedule__SpotKit-styles__text-align {
  text-align: center;
  max-width: 800px;
}

@media (max-width: 499px) {

  
  
  .ladder_view_schedule__SpotKit-styles__technician-img {
    height: calc(16vh -  16px );
    margin-bottom: 20px;
  }

}

.ladder_view_schedule__SpotKit-styles__page-root {
  display: flex;
  flex-direction: column;
}

.ladder_view_schedule__SpotKit-styles__top {
  padding-top: 16px;
}

@media (max-width: 499px) {

  
  
  .ladder_view_schedule__SpotKit-styles__page-root {
    min-height: calc(100vh - 60px);
  }

}
@media (min-width: 1025px) {

  
  
  .ladder_trustage_quote__button-wrapper-styles__wrapper {
    margin-top: 22.5px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_trustage_quote__button-wrapper-styles__wrapper {
    margin-top: 22.5px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_trustage_quote__button-wrapper-styles__wrapper {
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: #FFFFFF;
    box-shadow: 0 -2px 4px 2px rgba(0, 0, 0, 0.15);
    z-index: 5;
    width: 100%;
    padding: 15.0px 30.0px;
  }

}
.ladder_app_review__maybe-coverage-widget-modal-styles__buttons-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.ladder_ux_logos__sofi-partner-logo-styles__icon {
  height: 72.0px;
  width: auto;
  margin-top: -6px;
  margin-bottom: -6px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_logos__sofi-partner-logo-styles__icon {
    height: 48px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_logos__sofi-partner-logo-styles__icon {
    height: 48px;
  }

}
.ladder_apply_progress_bar__progress-bar-styles__inner {
  display: flex;
  width: 100%;
}

@media (max-width: 499px) {

  
  
  .ladder_apply_progress_bar__progress-bar-styles__inner {
    user-select: none;
  }

}
.ladder_account_shared_components__field-answer-pair-columns-container-styles__root {
  display: flex;
  width: 100%;
}

.ladder_account_shared_components__field-answer-pair-columns-container-styles__border-between:not(:last-child) {
  border-bottom: 1px solid #D0D0D0;
}

.ladder_account_shared_components__field-answer-pair-columns-container-styles__no-border-bottom:not(:last-child) {
  border-bottom: none;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account_shared_components__field-answer-pair-columns-container-styles__root {
    flex-direction: column;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_account_shared_components__field-answer-pair-columns-container-styles__root {
    flex-direction: column;
  }

}
.ladder_landing_pages_so_good_basics__basics-facts-container-styles__basics-facts-root {
  width: 70%;
  padding: 32px 0px 64px 0px;
}

.ladder_landing_pages_so_good_basics__basics-facts-container-styles__basics-facts-footer {
  display: flex;
  align-items: center;
  padding-top: 32px;
}

.ladder_landing_pages_so_good_basics__basics-facts-container-styles__orange-bar {
  width: 100%;
  border-top: solid 1px #CF967E;
  margin-right: 20px;
}

.ladder_landing_pages_so_good_basics__basics-facts-container-styles__basics-facts-footer-details {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.ladder_landing_pages_so_good_basics__basics-facts-container-styles__icon {
  width: 14px;
  height: 24px;
  color: #CF967E;
  margin-right: 12px;
}

.ladder_landing_pages_so_good_basics__basics-facts-container-styles__flip-horizontally {
  transform: rotate(180deg);
}

.ladder_landing_pages_so_good_basics__basics-facts-container-styles__details-left {
  display: flex;
  align-items: center;
}

.ladder_landing_pages_so_good_basics__basics-facts-container-styles__orange-text {
  margin-right: 12px;
}

.ladder_landing_pages_so_good_basics__basics-facts-container-styles__details-right {
  display: flex;
}

.ladder_landing_pages_so_good_basics__basics-facts-container-styles__ep-text {
  margin-right: 12px;
}

.ladder_landing_pages_so_good_basics__basics-facts-container-styles__no-wrap {
  white-space: nowrap;
}

@media (max-width: 499px) {

  
  
  .ladder_landing_pages_so_good_basics__basics-facts-container-styles__basics-facts-root {
    width: 85%;
  }
  
  .ladder_landing_pages_so_good_basics__basics-facts-container-styles__basics-facts-footer {
    display: block;
  }
  
  .ladder_landing_pages_so_good_basics__basics-facts-container-styles__orange-bar {
    margin-right: 0px;
  }
  
  .ladder_landing_pages_so_good_basics__basics-facts-container-styles__basics-facts-footer-details {
    margin-top: 8px;
  }
  
  .ladder_landing_pages_so_good_basics__basics-facts-container-styles__basics-facts-footer-details {
    display: block;
  }
  
  .ladder_landing_pages_so_good_basics__basics-facts-container-styles__icon {
    margin-right: 0px;
  }
  
  .ladder_landing_pages_so_good_basics__basics-facts-container-styles__details-left {
    padding-top: 8.0px;
  }
  
  .ladder_landing_pages_so_good_basics__basics-facts-container-styles__orange-text {
    margin-right: 8px;
  }
  
  .ladder_landing_pages_so_good_basics__basics-facts-container-styles__details-right {
    padding-top: 8.0px;
  }

}
.ladder_financial_advisors_laddering__easy-for-them-text-styles__header {
  margin: 0 0 30.0px 0;
}

.ladder_financial_advisors_laddering__easy-for-them-text-styles__text {
  margin: 0 0 30.0px 0;
}
.ladder_agents_agent_assisted_app_views_review__margin5__root {
  margin-top: 5.0px;
  margin-bottom: 5.0px;
}
.ladder_text__error-styles__root {

}
.ladder_privacy_page__privacy-page-styles__date {
  margin-top: 30.0px;
}
.ladder_components__spinner-styles__root {
  display: inline-block;
  border-radius: 50%;
  border-style: solid;
  border-right-color: #E5E5E6;
  border-top-color: #E5E5E6;
  border-bottom-color: #E5E5E6;
}

.ladder_components__spinner-styles__spin {
  animation: spin 1.1s infinite linear;
}

.ladder_components__spinner-styles__root.ladder_components__spinner-styles__turquoise {
  border-left-color: #17CAAC;
}

.ladder_components__spinner-styles__root.ladder_components__spinner-styles__darker-gray {
  border-left-color: #4C4C4E;
}

.ladder_components__spinner-styles__root.ladder_components__spinner-styles__medium-gray {
  border-left-color: #CDCDCD;
}

.ladder_components__spinner-styles__tiny {
  width: 0.9em;
  height: 0.9em;
  border-width: 0.15em;
}

.ladder_components__spinner-styles__medium {
  width: 4em;
  height: 4em;
  border-width: 0.5em;
}
.ladder_ux_policy_card__card-container-styles__root {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0;
  width: 100%;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_policy_card__card-container-styles__root {
    margin: 8.0px 0;
  }
  
  .ladder_ux_policy_card__card-container-styles__root-mobile-column {
    margin: 0;
    flex-direction: column;
  }

}
.ladder_account_next_nav_nav_item__PolicyGroup-styles__\% {
  display: contents;
}
.ladder_form_assessment_factor_search__afs-field-styles__options {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 32px;
}

.ladder_form_assessment_factor_search__afs-field-styles__option {
  display: flex;
}

.ladder_form_assessment_factor_search__afs-field-styles__checkbox-container {
  padding-right: 10.0px;
}

.ladder_form_assessment_factor_search__afs-field-styles__info-expander-container {
  padding: 5.0px 0 0 10.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_form_assessment_factor_search__afs-field-styles__options {
    gap: 8px;
    margin-bottom: 24px;
  }
  
  .ladder_form_assessment_factor_search__afs-field-styles__info-expander-container {
    padding-top: 0;
  }

}
.ladder_onboarding_spouse_connect_2__panel-1-styles__body {
  margin-top: 15.0px;
}

.ladder_onboarding_spouse_connect_2__panel-1-styles__image {
  display: none;
}

.ladder_onboarding_spouse_connect_2__panel-1-styles__large-image {
  display: none;
}

@media (min-width: 1025px) {

  
  
  .ladder_onboarding_spouse_connect_2__panel-1-styles__\% {
    width: 500px;
  }
  
  .ladder_onboarding_spouse_connect_2__panel-1-styles__body {
    margin-top: 30.0px;
  }
  
  .ladder_onboarding_spouse_connect_2__panel-1-styles__image {
    display: block;
    width: 336px;
    margin-top: 30.0px;
    margin-left: 40px;
  }
  
  .ladder_onboarding_spouse_connect_2__panel-1-styles__image img {
    max-width: 100%;
  }
  
  .ladder_onboarding_spouse_connect_2__panel-1-styles__large-image {
    display: block;
    margin-top: -175px;
    margin-left: -80px;
  }
  
  .ladder_onboarding_spouse_connect_2__panel-1-styles__large-image img {
    max-width: 100%;
  }

}
.ladder_account_next_modals__PaymentRetryStatus-styles__flat-tire {
  width: 200px;
  height: auto;
  margin: 0 auto;
}

.ladder_account_next_modals__PaymentRetryStatus-styles__celebrate {
  width: 300px;
  height: auto;
  margin: 0 auto;
}

.ladder_account_next_modals__PaymentRetryStatus-styles__img-wrapper {
  display: flex;
  align-items: center;
}

@media (max-width: 499px) {

  
  
  .ladder_account_next_modals__PaymentRetryStatus-styles__celebrate {
    width: 100%;
  }

}
.ladder_account_next_cards_notification_banners__NotificationBanner-styles__notification-banner-unread-message {
  width: 100%;
  margin-bottom: 20px;
  border-left: 5.0px solid #CB3A3A;
  display: flex;
  flex-direction: column;
  padding: 15.0px 50.0px 15.0px 25.0px;
  background-color: #EEA4A4;
}

.ladder_account_next_cards_notification_banners__NotificationBanner-styles__notification-banner {
  width: 100%;
  margin-bottom: 20px;
  border-left: 5.0px solid #482423;
  display: flex;
  flex-direction: column;
  padding: 15.0px 50.0px 15.0px 25.0px;
  background-color: #D8BCAF;
}

@media (max-width: 499px) {

  
  
  .ladder_account_next_cards_notification_banners__NotificationBanner-styles__notification-banner {
    margin-top: 20px;
  }

}
.ladder_work_home__why-panel-styles__root {

}
.ladder_account_ladder_up__quote-loading-styles__root {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60.0px;
}

.ladder_account_ladder_up__quote-loading-styles__spinner {
  animation: spin 1s linear infinite;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account_ladder_up__quote-loading-styles__root {
    height: 32px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_account_ladder_up__quote-loading-styles__root {
    height: 36.0px;
  }

}
.ladder_account_payment_history__RungsInvoiceHistory-styles__policy-title {
  margin-left: 10.0px;
  margin-top: 40px;
}
.ladder_views_api__question-styles__question {
  width: 100%;
  margin-bottom: 16px;
}

.ladder_views_api__question-styles__divider {
  margin: 16px 0 32px;
  border-color: #000000;
}
.ladder_calculator_rebrand__children-age-input-styles__root {

}

.ladder_calculator_rebrand__children-age-input-styles__text {

}

.ladder_calculator_rebrand__children-age-input-styles__input {

}
.ladder_financial_advisors_laddering__top-panel-text-styles__text-container {
  display: flex;
  flex-direction: column;
  max-width: 670px;
  z-index: 1;
}

.ladder_financial_advisors_laddering__top-panel-text-styles__text {
  margin: 30.0px 0 60px 0;
  width: 100%;
}

@media (max-width: 499px) {

  
  
  .ladder_financial_advisors_laddering__top-panel-text-styles__text-container {
    max-width: 90%;
  }

}
.ladder_account_payment_history__payment-history-row-styles__payment-container {
  width: 100%;
  display: flex;
}

.ladder_account_payment_history__payment-history-row-styles__payment-container.ladder_account_payment_history__payment-history-row-styles__payment-failed {
  opacity: 0.7;
}

.ladder_account_payment_history__payment-history-row-styles__flex {
  flex: 1;
}

.ladder_account_payment_history__payment-history-row-styles__full-width {
  width: 100%;
}

.ladder_account_payment_history__payment-history-row-styles__payment-row {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.ladder_account_payment_history__payment-history-row-styles__payment-column {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;
}

.ladder_account_payment_history__payment-history-row-styles__payment-metadata {
  text-align: left;
  min-width: 120px;
}

.ladder_account_payment_history__payment-history-row-styles__payment-description {
  margin-left: 20px;
}

.ladder_account_payment_history__payment-history-row-styles__payment-amount {
  white-space: nowrap;
}

.ladder_account_payment_history__payment-history-row-styles__summation-line-top {
  border-top: 1px solid #000000;
}
.ladder_advisor_only_standalone_quoter__root-styles__reset-form {
  height: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ladder_ux_radio_input__radio-option-styles__wrapper-full-width {
  width: 47%;
}

.ladder_ux_radio_input__radio-option-styles__root {
  justify-content: center;
  margin: 0 8.0px;
  user-select: none;
  align-items: center;
  padding: 4.0px 12.0px;
  outline: none;
  cursor: pointer;
  background-color: #F4F4F4;
  display: flex;
}

.ladder_ux_radio_input__radio-option-styles__root-transparent-button {
  height: 100%;
  background-color: transparent;
  border: 1px solid #707070;
}

.ladder_ux_radio_input__radio-option-styles__column {
  padding: 8.0px;
}

.ladder_ux_radio_input__radio-option-styles__three-dimensional {
  transition-delay: 0s;
  transition-duration: 0.1s;
  transition-timing-function: linear;
  box-shadow: 0px 1px 4px 1px rgba(0,0,0,0.1);
  transition-property: box-shadow,background-color,transform;
}

@media (min-width: 1025px) {

  
  
  
  
  .ladder_ux_radio_input__radio-option-styles__three-dimensional:hover {
    box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.1);
    transform: translateY(-1px);
  }
  
  .ladder_ux_radio_input__radio-option-styles__three-dimensional:active {
    box-shadow: none;
    transform: translateY(1px);
  }

}

@media (max-width: 499px) {

  
  
  
  
  .ladder_ux_radio_input__radio-option-styles__three-dimensional:active {
    box-shadow: none;
    transform: translateY(2px);
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  
  
  .ladder_ux_radio_input__radio-option-styles__three-dimensional:active {
    box-shadow: none;
    transform: translateY(2px);
  }

}

.ladder_ux_radio_input__radio-option-styles__radio {
  display: none;
}

.ladder_ux_radio_input__radio-option-styles__full-width {
  margin-right: 0;
  margin-left: 0;
  box-sizing: border-box;
  text-align: center;
}

.ladder_ux_radio_input__radio-option-styles__height {
  padding-top: 0;
  padding-bottom: 0;
  height: 100%;
}

.ladder_ux_radio_input__radio-option-styles__selected {
  background-color: #CF967E;
  cursor: default;
}

.ladder_ux_radio_input__radio-option-styles__selected .ladder_ux_radio_input__radio-option-styles__text {
  color: #FFFFFF;
}

@media (min-width: 1025px) {

  
  
  .ladder_ux_radio_input__radio-option-styles__not-selected {
  
  }
  
  .ladder_ux_radio_input__radio-option-styles__not-selected:hover {
    background-color: #F4F4F4;
  }

}

.box-fields .ladder_ux_radio_input__radio-option-styles__root {
  background-color: #FFFFFF;
  height: 48px;
  box-sizing: content-box;
  border-radius: 2px;
}

.box-fields .ladder_ux_radio_input__radio-option-styles__text {
  font-weight: normal;
  text-transform: capitalize;
}
@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard_contacts__contact-detail-page-styles__contact-cards {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 30px;
    gap: 40px;
  }
  
  .ladder_agents_dashboard_contacts__contact-detail-page-styles__container {
    display: flex;
    flex-direction: column;
    gap: 2em;
  }
  
  .ladder_agents_dashboard_contacts__contact-detail-page-styles__inner-container {
    gap: 1em;
    display: flex;
    flex-direction: column;
    width: 90vw;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_dashboard_contacts__contact-detail-page-styles__contact-cards {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: space-around;
    padding-bottom: 30px;
    gap: 40px;
  }
  
  .ladder_agents_dashboard_contacts__contact-detail-page-styles__container {
    display: flex;
    flex-direction: column;
    gap: 2em;
  }
  
  .ladder_agents_dashboard_contacts__contact-detail-page-styles__inner-container {
    gap: 1em;
    display: flex;
    flex-direction: column;
    width: 90vw;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_agents_dashboard_contacts__contact-detail-page-styles__contact-cards {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    padding-bottom: 30px;
    gap: 40px;
  }
  
  .ladder_agents_dashboard_contacts__contact-detail-page-styles__container {
    display: flex;
    flex-direction: column;
    gap: 4em;
  }
  
  .ladder_agents_dashboard_contacts__contact-detail-page-styles__inner-container {
    gap: 1em;
    display: flex;
    flex-direction: column;
    width: 600px;
  }

}

.ladder_agents_dashboard_contacts__contact-detail-page-styles__contact-name-container {
  margin-left: 1em;
}
@media (min-width: 1025px) {

  
  
  .ladder_onboarding_shared_components__panel-no-bp__panel {
    padding-bottom: 0px;
    padding-right: 110.0px;
    padding-left: 80px;
  }

}
.ladder_header__UserNav-styles__root {
  display: flex;
  align-items: center;
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.ladder_header__UserNav-styles__root.ladder_header__UserNav-styles__hide {
  opacity: 0;
  transition: initial;
}
.ladder_ux_homepage_core__rebrand-you-are-in-control-styles__root {
  display: flex;
  width: 100%;
  height: auto;
  background-color: #EFEAE1;
  justify-content: center;
  padding-bottom: 64px;
}

.ladder_ux_homepage_core__rebrand-you-are-in-control-styles__root.ladder_ux_homepage_core__rebrand-you-are-in-control-styles__homepage-ui {
  padding: 48px 0;
}

.ladder_ux_homepage_core__rebrand-you-are-in-control-styles__heading {
  margin: 8.0px 0;
}

.ladder_ux_homepage_core__rebrand-you-are-in-control-styles__container {
  display: flex;
  justify-content: space-between;
  max-width: 1400px;
  width: 80%;
  margin: 32px 0 32px 0;
}

.ladder_ux_homepage_core__rebrand-you-are-in-control-styles__image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 550px;
  width: 57%;
}

.ladder_ux_homepage_core__rebrand-you-are-in-control-styles__image-wrapper {
  display: block;
  width: 90%;
  height: auto;
}

.ladder_ux_homepage_core__rebrand-you-are-in-control-styles__image {
  display: block;
  width: 100%;
  height: auto;
}

.ladder_ux_homepage_core__rebrand-you-are-in-control-styles__action-box {
  flex-basis: 50%;
  margin-top: 5%;
  max-width: 600px;
  margin-bottom: 32px;
}

.ladder_ux_homepage_core__rebrand-you-are-in-control-styles__text {
  margin: 8.0px 0 64px 0;
}

.ladder_ux_homepage_core__rebrand-you-are-in-control-styles__image-text {
  display: flex;
  margin-top: 32px;
  justify-content: center;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_homepage_core__rebrand-you-are-in-control-styles__image-container {
    width: 100%;
    max-width: unset;
  }
  
  .ladder_ux_homepage_core__rebrand-you-are-in-control-styles__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 16px 0 32px 0;
  }
  
  .ladder_ux_homepage_core__rebrand-you-are-in-control-styles__action-box {
    flex-direction: column;
    flex-basis: unset;
    padding-right: 0;
    max-width: 80%;
    margin: 0 0 64px 0;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_homepage_core__rebrand-you-are-in-control-styles__image-container {
    width: 100%;
    max-width: unset;
  }
  
  .ladder_ux_homepage_core__rebrand-you-are-in-control-styles__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 16px 0 32px 0;
  }
  
  .ladder_ux_homepage_core__rebrand-you-are-in-control-styles__action-box {
    flex-direction: column;
    flex-basis: unset;
    padding-right: 0;
    max-width: 80%;
    margin: 0 0 64px 0;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_homepage_core__rebrand-you-are-in-control-styles__image-wrapper {
    width: 60%;
  }
  
  .ladder_ux_homepage_core__rebrand-you-are-in-control-styles__text {
    margin: 16px auto;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_homepage_core__rebrand-you-are-in-control-styles__action-box {
    max-width: 90%;
  }
  
  .ladder_ux_homepage_core__rebrand-you-are-in-control-styles__text {
    margin: 32px 0;
  }
  
  .ladder_ux_homepage_core__rebrand-you-are-in-control-styles__image-text {
    max-width: 90%;
    text-align: left;
  }

}
.ladder_gift_terms__root-styles__wrapper>* {
  margin-bottom: 20px;
}
.ladder_quick_start_panels__bulleted-content-styles__bullet-container {
  display: flex;
  margin-top: 32px;
  flex-wrap: wrap;
}

.ladder_quick_start_panels__bulleted-content-styles__icon {
  margin-right: 12.0px;
  margin-left: 12.0px;
  margin-bottom: 4.0px;
}

.ladder_quick_start_panels__bulleted-content-styles__wide-icon {
  margin-right: 12.0px;
  margin-bottom: 4.0px;
}

.ladder_quick_start_panels__bulleted-content-styles__bullet-row {
  display: flex;
  flex-basis: 100%;
  flex-grow: 1;
  margin-top: 32px;
  margin-left: -10px;
}

.ladder_quick_start_panels__bulleted-content-styles__bottom-row {
  margin-top: 200px;
}

@media (max-width: 499px) {

  
  
  .ladder_quick_start_panels__bulleted-content-styles__bullet-container {
    margin-top: 12.0px;
  }
  
  .ladder_quick_start_panels__bulleted-content-styles__bullet-row {
    margin-top: 8.0px;
  }
  
  .ladder_quick_start_panels__bulleted-content-styles__icon {
    margin-left: 8.0px;
  }
  
  .ladder_quick_start_panels__bulleted-content-styles__bottom-row {
    margin-top: 20.0px;
    margin-bottom: 20.0px;
  }

}
.ladder_ny_form_traffic_diversion__visit-partner-copy-styles__root {
  max-width: 1120px;
  margin-top: 64px;
  margin-bottom: 64px;
}

@media (max-width: 499px) {

  
  
  .ladder_ny_form_traffic_diversion__visit-partner-copy-styles__root {
    margin-top: 32px;
    margin-bottom: 32px;
  }

}
@media (min-width: 1025px) {

  
  
  .ladder_agents_dashboard__welcome-to-ladder-card-styles__contact-card-shadow-container {
    width: 100%;
    justify-content: space-evenly;
    margin: 0.5em 0em;
    align-items: center;
    padding: 1.5em;
    flex-direction: row;
    moz-border-radius: 30px;
    box-shadow: 0px 5px 10px rgba(51,46,84,.05);
    background-color: #F7DDC9;
    border-radius: 28px;
    display: flex;
    webkit-border-radius: 30px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard__welcome-to-ladder-card-styles__contact-card-shadow-container {
    justify-content: center;
    margin: 0.5em 0em;
    align-items: center;
    padding: 1em 1.5em;
    flex-direction: column;
    moz-border-radius: 30px;
    box-shadow: 0px 5px 10px rgba(51,46,84,.05);
    background-color: #F7DDC9;
    border-radius: 28px;
    display: flex;
    webkit-border-radius: 30px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_dashboard__welcome-to-ladder-card-styles__contact-card-shadow-container {
    justify-content: center;
    margin: 0.5em 0em;
    align-items: center;
    padding: 1em 1.5em;
    flex-direction: column;
    moz-border-radius: 30px;
    box-shadow: 0px 5px 10px rgba(51,46,84,.05);
    background-color: #F7DDC9;
    border-radius: 28px;
    display: flex;
    webkit-border-radius: 30px;
  }

}

.ladder_agents_dashboard__welcome-to-ladder-card-styles__welcome-image {
  max-width: 160px;
  max-height: 120px;
}
.ladder_ux_modal__fade-in-modal-styles__root {
  width: 100%;
  right: 0;
  justify-content: center;
  height: 100%;
  align-items: center;
  position: fixed;
  z-index: 14;
  display: flex;
  bottom: 0;
  pointer-events: none;
  transition: background 250ms;
  background: rgba(0,0,0,0);
  max-height: -webkit-fill-available;
  left: 0;
}

.ladder_ux_modal__fade-in-modal-styles__root >div {
  max-height: 90%;
  display: flex;
}

.ladder_ux_modal__fade-in-modal-styles__root.ladder_ux_modal__fade-in-modal-styles__full-page >div {
  max-height: calc(100% - 60px);
}

.ladder_ux_modal__fade-in-modal-styles__open {
  background: rgba(0, 0, 0, 0.8);
  pointer-events: auto;
}

.ladder_ux_modal__fade-in-modal-styles__drawer {
  border-radius: 10px 10px 0 0;
  width: 100%;
}

.ladder_ux_modal__fade-in-modal-styles__drawer >div {
  border-radius: 10px 10px 0 0;
}
@media (min-width: 1025px) {

  
  
  .ladder_apply_current_page_ui__agents-pta-landing-button-styles__wrapper {
    display: flex;
    width: 60%;
    align-self: flex-end;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_apply_current_page_ui__agents-pta-landing-button-styles__wrapper {
    width: 100%;
    margin: 16px 0;
  }

}
.ladder_form_upper_funnel_routing__upper-funnel-routing-npq-styles__wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 30.0px;
  align-self: center;
  max-width: 1250px;
  margin-left: auto;
  margin-right: auto;
}

.ladder_form_upper_funnel_routing__upper-funnel-routing-npq-styles__content {
  display: flex;
}

.ladder_form_upper_funnel_routing__upper-funnel-routing-npq-styles__v2-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ladder_form_upper_funnel_routing__upper-funnel-routing-npq-styles__how-does-your-ladder-quote-compare-panel {
  display: flex;
  flex-grow: 1;
}

.ladder_form_upper_funnel_routing__upper-funnel-routing-npq-styles__modal-content .ladder_form_upper_funnel_routing__upper-funnel-routing-npq-styles__caption {
  margin-bottom: 30.0px;
}

.ladder_form_upper_funnel_routing__upper-funnel-routing-npq-styles__ad-panel-v2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media (min-width: 1025px) {

  
  
  .ladder_form_upper_funnel_routing__upper-funnel-routing-npq-styles__npq {
    margin-left: 130.0px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_form_upper_funnel_routing__upper-funnel-routing-npq-styles__mobile-padding {
    padding: 0 20px;
  }
  
  .ladder_form_upper_funnel_routing__upper-funnel-routing-npq-styles__content {
    flex-direction: column;
  }
  
  .ladder_form_upper_funnel_routing__upper-funnel-routing-npq-styles__v2-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .ladder_form_upper_funnel_routing__upper-funnel-routing-npq-styles__links {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .ladder_form_upper_funnel_routing__upper-funnel-routing-npq-styles__links>* {
    margin-bottom: 20px;
  }
  
  .ladder_form_upper_funnel_routing__upper-funnel-routing-npq-styles__links-border-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: solid 1px;
    border-color: #D9D9D9;
    border-radius: 5.0px;
  }
  
  .ladder_form_upper_funnel_routing__upper-funnel-routing-npq-styles__links-border-bottom>* {
    margin-bottom: 20px;
  }
  
  .ladder_form_upper_funnel_routing__upper-funnel-routing-npq-styles__apply-with-ladder-mobile {
    margin: auto;
    display: flex;
    justify-content: center;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_form_upper_funnel_routing__upper-funnel-routing-npq-styles__mobile-padding {
    padding: 0 20px;
  }
  
  .ladder_form_upper_funnel_routing__upper-funnel-routing-npq-styles__content {
    flex-direction: column;
  }
  
  .ladder_form_upper_funnel_routing__upper-funnel-routing-npq-styles__v2-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .ladder_form_upper_funnel_routing__upper-funnel-routing-npq-styles__links {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .ladder_form_upper_funnel_routing__upper-funnel-routing-npq-styles__links>* {
    margin-bottom: 20px;
  }
  
  .ladder_form_upper_funnel_routing__upper-funnel-routing-npq-styles__links-border-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: solid 1px;
    border-color: #D9D9D9;
    border-radius: 5.0px;
  }
  
  .ladder_form_upper_funnel_routing__upper-funnel-routing-npq-styles__links-border-bottom>* {
    margin-bottom: 20px;
  }
  
  .ladder_form_upper_funnel_routing__upper-funnel-routing-npq-styles__apply-with-ladder-mobile {
    margin: auto;
    display: flex;
    justify-content: center;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_form_upper_funnel_routing__upper-funnel-routing-npq-styles__links-border-bottom {
    margin-top: 30.0px;
  }
  
  .ladder_form_upper_funnel_routing__upper-funnel-routing-npq-styles__links-border-bottom>* {
    margin-bottom: 20px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_form_upper_funnel_routing__upper-funnel-routing-npq-styles__how-does-your-ladder-quote-compare-panel {
    margin-right: 30.0px;
    margin-left: 30.0px;
  }

}
.ladder_views_decision_offer__body-text-styles__link-hidden {
  visibility: hidden;
}
.ladder_trustage_upper_funnel_routing__quote-page-content-styles__info {
  margin-top: 25.0px;
}

.ladder_trustage_upper_funnel_routing__quote-page-content-styles__info>*:first-child {
  margin-bottom: 20px;
}
.ladder_lux_markdown_components__heading-styles__h2-padding {
  padding: 16px 0;
}

.ladder_lux_markdown_components__heading-styles__h3-padding {
  padding: 8.0px 0;
}

.ladder_lux_markdown_components__heading-styles__h5-padding {
  padding: 4.0px 0;
}
.ladder_agents_dashboard__dashboard-styles__logo-container {
  display: flex;
  margin: 0.5em;
}

.ladder_agents_dashboard__dashboard-styles__help-container {
  width: 45%;
  gap: 10px;
  justify-content: center;
  margin: 0.5em 0em;
  border: 1px solid #8E8E8E;
  align-items: center;
  padding: 0.5em 1em;
  flex-direction: row;
  cursor: pointer;
  border-radius: 28px;
  display: flex;
}

.ladder_agents_dashboard__dashboard-styles__contact-details-page {
  min-height: 1650px;
}

.ladder_agents_dashboard__dashboard-styles__column {
  display: flex;
  flex-direction: column;
}

.ladder_agents_dashboard__dashboard-styles__feedback-banner {
  display: flex;
  flex-direction: column;
  background-color: #F7DDC9;
  align-items: center;
  justify-content: center;
}

.ladder_agents_dashboard__dashboard-styles__footer-container {
  margin: 4em;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard__dashboard-styles__home {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    height: 100%;
    min-height: calc(100vh - 20px);
    background-color: #F6F6F6;
  }
  
  .ladder_agents_dashboard__dashboard-styles__page-container {
    display: flex;
    flex-direction: column;
    padding: 5px 20px 0px;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    max-width: 600px;
  }
  
  .ladder_agents_dashboard__dashboard-styles__share-page {
    min-height: 1500px;
  }
  
  .ladder_agents_dashboard__dashboard-styles__settings-profile-page {
    min-height: 1200px;
  }
  
  .ladder_agents_dashboard__dashboard-styles__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color: #F6F6F6;
    padding-top: 5px;
    padding-bottom: 20px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_dashboard__dashboard-styles__home {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    height: 100%;
    min-height: calc(100vh - 20px);
    background-color: #F6F6F6;
  }
  
  .ladder_agents_dashboard__dashboard-styles__page-container {
    display: flex;
    flex-direction: column;
    padding: 5px 20px 0px;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    max-width: 600px;
  }
  
  .ladder_agents_dashboard__dashboard-styles__share-page {
    min-height: 1500px;
  }
  
  .ladder_agents_dashboard__dashboard-styles__settings-profile-page {
    min-height: 1200px;
  }
  
  .ladder_agents_dashboard__dashboard-styles__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color: #F6F6F6;
    padding-top: 5px;
    padding-bottom: 20px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard__dashboard-styles__page-container {
    max-width: 400px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_dashboard__dashboard-styles__help-container {
    width: 30%;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_agents_dashboard__dashboard-styles__home {
    width: 100%;
    padding-bottom: 2em;
    justify-content: flex-end;
    height: 100%;
    align-items: flex-start;
    flex-direction: row-reverse;
    min-height: calc(100vh - 20px);
    background-color: #F6F6F6;
    display: flex;
  }
  
  .ladder_agents_dashboard__dashboard-styles__page-container {
    display: flex;
    padding-top: 35px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  .ladder_agents_dashboard__dashboard-styles__nav-bar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    margin: 2em 4em 2em 40px;
  }
  
  .ladder_agents_dashboard__dashboard-styles__share-page {
    min-height: 1500px;
  }
  
  .ladder_agents_dashboard__dashboard-styles__settings-profile-page {
    min-height: 1200px;
  }

}
.ladder_account_next_views_gift__Root-styles__root {
  max-width: 750px;
}

.ladder_account_next_views_gift__Root-styles__digital-delivery-wrapper {
  width: 100%;
  display: flex;
}

.ladder_account_next_views_gift__Root-styles__digital-button-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 16px 0;
}

.ladder_account_next_views_gift__Root-styles__buttons {
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 499px) {

  
  
  .ladder_account_next_views_gift__Root-styles__buttons {
    flex-direction: column;
  }
  
  .ladder_account_next_views_gift__Root-styles__buttons>*:first-child {
    margin-bottom: 16px;
  }

}
@media (min-width: 1025px) {

  
  
  .ladder_agents_agent_assisted_app_views_waiting_for_decision__image-styles__image-container {
    max-width: 344px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_agents_agent_assisted_app_views_waiting_for_decision__image-styles__image-container {
    max-width: 163px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_agent_assisted_app_views_waiting_for_decision__image-styles__image-container {
    max-width: 163px;
  }

}

.ladder_agents_agent_assisted_app_views_waiting_for_decision__image-styles__image {
  width: 100%;
}
.ladder_footer__root-styles__root {
  padding-bottom: 64px;
  margin-top: 100px;
  flex-grow: 0;
  z-index: 1;
  box-sizing: content-box;
}

.ladder_footer__root-styles__dark-background {
  background: #475665;
}

.ladder_footer__root-styles__black-background {
  background: #000000;
}

.ladder_footer__root-styles__no-background-color {
  background: #FFFFFF;
}

.ladder_footer__root-styles__inner {
  max-width: 1400px;
  width: 90%;
  padding: 32px;
  margin-left: auto;
  margin-right: auto;
}

.ladder_footer__root-styles__columns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ladder_footer__root-styles__root-no-margin {
  margin: 0;
}

.ladder_footer__root-styles__root-no-margin {
  margin: 0;
}

.ladder_footer__root-styles__admin-container {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 499px) {

  
  
  .ladder_footer__root-styles__inner {
    width: auto;
  }
  
  .ladder_footer__root-styles__columns {
    flex-direction: column;
    flex-basis: 100%;
    flex-wrap: wrap;
  }

}
.ladder_ux_logos__geico-logo-styles__icon {
  max-height: 27.2px;
  margin-top: 4.0px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_logos__geico-logo-styles__icon {
    max-height: 20.8px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_logos__geico-logo-styles__icon {
    max-height: 19.2px;
  }

}
.ladder_app_review__margin5-styles__root {
  margin-top: 5.0px;
  margin-bottom: 5.0px;
}
.ladder_claims__faq-styles__root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 130.0px;
  background-color: #AFC5C8;
}

.ladder_claims__faq-styles__wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 30.0px;
  max-width: 1400px;
  width: 100%;
}

.ladder_claims__faq-styles__title {
  display: flex;
  text-align: center;
  justify-content: center;
  width: 80%;
  margin: 60px 0 30.0px 0;
}

.ladder_claims__faq-styles__expand-card {
  display: flex;
  flex-direction: column;
  background-color: #E7EEEF;
  padding: 37px;
  margin: 15.0px;
  width: 380px;
  height: 440px;
}

.ladder_claims__faq-styles__mobile-expand-card {
  display: none;
  width: 80%;
}

.ladder_claims__faq-styles__display {
  display: flex;
}

.ladder_claims__faq-styles__text {
  font-size: 15px;
  line-height: 30px;
}

@media (max-width: 499px) {

  
  
  .ladder_claims__faq-styles__mobile-expand-card {
    display: flex;
  }
  
  .ladder_claims__faq-styles__expand-card {
    display: none;
  }
  
  .ladder_claims__faq-styles__root {
    padding: 0 0 60px 0;
  }
  
  .ladder_claims__faq-styles__text-wrapper {
    margin: 0 20px;
  }

}
.ladder_ux_logos__policygenius-logo-styles__half-width {
  width: 50%;
}

.ladder_ux_logos__policygenius-logo-styles__quarter-width {
  width: 25%;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_logos__policygenius-logo-styles__quarter-width {
    width: 50%;
  }

}

.ladder_ux_logos__policygenius-logo-styles__top-bottom-margin {
  margin: 8px 0px;
}
.ladder_careers__ui-benefits-row-styles__root {
  display: flex;
  flex-direction: row;
}

.ladder_careers__ui-benefits-row-styles__root>* {
  margin-top: 30.0px;
}

.ladder_careers__ui-benefits-row-styles__root > *:not(:last-child) {
  margin-right: 30.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_careers__ui-benefits-row-styles__root {
    border: none;
    flex-direction: column;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_careers__ui-benefits-row-styles__root {
    border: none;
    flex-direction: column;
  }

}
.ladder_ux_panels__story-panel-styles__root {
  display: flex;
  width: 100%;
  height: 45vw;
}

.ladder_ux_panels__story-panel-styles__image-panel {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 45%;
  height: auto;
  background-color: #E7EEEF;
}

.ladder_ux_panels__story-panel-styles__image {
  display: block;
  height: auto;
  width: 100%;
}

.ladder_ux_panels__story-panel-styles__testimonial-panel {
  display: flex;
  width: 55%;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
}

.ladder_ux_panels__story-panel-styles__content {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 85%;
}

.ladder_ux_panels__story-panel-styles__top-bar-wrapper {
  display: flex;
  width: 100%;
  height: 0.3vw;
  justify-content: space-between;
}

.ladder_ux_panels__story-panel-styles__gray-bar-wrapper {
  display: flex;
  position: relative;
  flex-grow: 1;
  height: 100%;
  z-index: 1;
  margin: 16px 0.5% 0 0.5%;
  background-color: #F1F1F1;
  cursor: pointer;
}

.ladder_ux_panels__story-panel-styles__gray-bar-wrapper:first-child {
  margin-right: 0.5%;
}

.ladder_ux_panels__story-panel-styles__gray-bar-wrapper:last-child {
  margin-left: 0.5%;
}

.ladder_ux_panels__story-panel-styles__current-bar {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  width: 0%;
  background-color: #1A1A1A;
}

.ladder_ux_panels__story-panel-styles__current-bar.ladder_ux_panels__story-panel-styles__transition {
  transition: 5s linear;
}

.ladder_ux_panels__story-panel-styles__current-bar.ladder_ux_panels__story-panel-styles__selected {
  width: 100%;
}

.ladder_ux_panels__story-panel-styles__current-bar.ladder_ux_panels__story-panel-styles__unselected {
  transition: 0s;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_panels__story-panel-styles__root {
    flex-direction: column-reverse;
    height: auto;
  }
  
  .ladder_ux_panels__story-panel-styles__image-panel {
    width: 100%;
    position: unset;
  }
  
  .ladder_ux_panels__story-panel-styles__image {
    margin-top: 64px;
  }
  
  .ladder_ux_panels__story-panel-styles__testimonial-panel {
    width: 100%;
    height: 450px;
  }
  
  .ladder_ux_panels__story-panel-styles__content {
    height: 85%;
  }
  
  .ladder_ux_panels__story-panel-styles__top-bar-wrapper {
    height: 3px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_panels__story-panel-styles__root {
    flex-direction: column-reverse;
    height: auto;
  }
  
  .ladder_ux_panels__story-panel-styles__image-panel {
    width: 100%;
    position: unset;
  }
  
  .ladder_ux_panels__story-panel-styles__image {
    margin-top: 64px;
  }
  
  .ladder_ux_panels__story-panel-styles__testimonial-panel {
    width: 100%;
    height: 450px;
  }
  
  .ladder_ux_panels__story-panel-styles__content {
    height: 85%;
  }
  
  .ladder_ux_panels__story-panel-styles__top-bar-wrapper {
    height: 3px;
  }

}
.ladder_prototype_laddering__root-styles__root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ladder_prototype_laddering__root-styles__menu {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ladder_prototype_laddering__root-styles__menu-item {
  padding: 0 5px;
}
.ladder_views_payment__credit-card-form-styles__card-element {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  padding: 0 20px;
  height: 48px;
  border: 1px solid #707070;
}

.ladder_views_payment__credit-card-form-styles__card-submit-wrapper {
  display: flex;
  align-items: center;
  gap: 10.0px;
}

.ladder_views_payment__credit-card-form-styles__border-error {
  border: 1px solid #CB3A3A;
}

.ladder_views_payment__credit-card-form-styles__error-message-container {
  width: 100%;
  display: flex;
  align-items: center;
  height: 32px;
}

.ladder_views_payment__credit-card-form-styles__expired-error-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}
.ladder_agents_dashboard_nav__nav-bar-styles__uw-guidelines-box {
  background-color: #F3D7AA;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
}

.ladder_agents_dashboard_nav__nav-bar-styles__button-container {
  width: 100%;
}

.ladder_agents_dashboard_nav__nav-bar-styles__help-container {
  width: 80%;
  gap: 20px;
  justify-content: center;
  margin: 0.5em 0em;
  border: 1px solid #8E8E8E;
  align-items: center;
  padding: 1em 1.5em;
  flex-direction: row;
  cursor: pointer;
  border-radius: 28px;
  display: flex;
}

.ladder_agents_dashboard_nav__nav-bar-styles__help-modal-container {
  gap: 10px;
  justify-content: center;
  margin: 0.5em 0em;
  border: 1px solid #8E8E8E;
  align-items: center;
  padding: 1em 1em;
  flex-direction: column;
  cursor: pointer;
  border-radius: 28px;
  display: flex;
}

.ladder_agents_dashboard_nav__nav-bar-styles__help-button-spacing {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding-bottom: 30px;
}

@media (min-width: 1025px) {

  
  
  .ladder_agents_dashboard_nav__nav-bar-styles__bubble-arrangement {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  
  .ladder_agents_dashboard_nav__nav-bar-styles__help-modal-container {
    width: 80%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_dashboard_nav__nav-bar-styles__bubble-arrangement {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  
  .ladder_agents_dashboard_nav__nav-bar-styles__help-modal-container {
    width: 80%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard_nav__nav-bar-styles__button-container {
    display: flex;
    height: 100%;
    justify-content: space-between;
    flex-direction: column;
  }
  
  .ladder_agents_dashboard_nav__nav-bar-styles__bubble-arrangement {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .ladder_agents_dashboard_nav__nav-bar-styles__help-modal-container {
    width: 90%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard_nav__nav-bar-styles__help-button-spacing {
    padding-bottom: unset;
  }
  
  .ladder_agents_dashboard_nav__nav-bar-styles__nav-bar-shadow-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-radius: 40px;
    background-color: #F3D7AA;
    box-shadow: 0 8px 13px rgba(51,46,84,.3);
  }
  
  .ladder_agents_dashboard_nav__nav-bar-styles__nav-bar-item-container {
    display: flex;
    flex-direction: row;
    width: 400px;
    align-items: center;
    justify-content: space-around;
  }
  
  .ladder_agents_dashboard_nav__nav-bar-styles__uw-guidelines-box {
    width: 350px;
    background-color: unset;
  }
  
  .ladder_agents_dashboard_nav__nav-bar-styles__nav-bar-item-inner-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0.5em 2em;
    background-color: #FFFFFF;
    border-radius: 40px;
    align-items: center;
    justify-content: space-around;
  }
  
  .ladder_agents_dashboard_nav__nav-bar-styles__pinned-to-bottom {
    position: fixed;
    bottom: 1em;
    left: 0;
    right: 0;
    width: 350px;
    height: 95px;
    z-index: 10;
    margin: 0 auto;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_dashboard_nav__nav-bar-styles__help-button-spacing {
    padding-bottom: unset;
  }
  
  .ladder_agents_dashboard_nav__nav-bar-styles__nav-bar-shadow-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-radius: 40px;
    background-color: #F3D7AA;
    box-shadow: 0 8px 13px rgba(51,46,84,.3);
  }
  
  .ladder_agents_dashboard_nav__nav-bar-styles__nav-bar-item-container {
    display: flex;
    flex-direction: row;
    width: 400px;
    align-items: center;
    justify-content: space-around;
  }
  
  .ladder_agents_dashboard_nav__nav-bar-styles__uw-guidelines-box {
    width: 350px;
    background-color: unset;
  }
  
  .ladder_agents_dashboard_nav__nav-bar-styles__nav-bar-item-inner-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0.5em 2em;
    background-color: #FFFFFF;
    border-radius: 40px;
    align-items: center;
    justify-content: space-around;
  }
  
  .ladder_agents_dashboard_nav__nav-bar-styles__pinned-to-bottom {
    position: fixed;
    bottom: 1em;
    left: 0;
    right: 0;
    width: 350px;
    height: 95px;
    z-index: 10;
    margin: 0 auto;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_agents_dashboard_nav__nav-bar-styles__nav-bar-shadow-container {
    margin-right: initial;
    flex-direction: row;
    position: relative;
    moz-border-radius: 35px;
    box-shadow: 0 8px 13px rgba(51,46,84,.3);
    background-color: #FFFFFF;
    border-radius: 40px;
    display: flex;
    webkit-border-radius: 35px;
  }
  
  .ladder_agents_dashboard_nav__nav-bar-styles__nav-bar-item-container {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 600px;
    align-items: center;
  }
  
  .ladder_agents_dashboard_nav__nav-bar-styles__nav-bar-item-inner-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-around;
  }
  
  .ladder_agents_dashboard_nav__nav-bar-styles__nav-bar-shadow-container {
    height: 800px;
  }
  
  .ladder_agents_dashboard_nav__nav-bar-styles__logo-container {
    display: flex;
    margin: 2em;
  }

}
.ladder_agents_dashboard_contacts__start-app-button-styles__start-app-button {
  margin-top: 20px;
}
.ladder_account_next_views_profile_edit__half-width-styles__container {
  display: flex;
}

.ladder_account_next_views_profile_edit__half-width-styles__half {
  width: 50%;
}

.ladder_account_next_views_profile_edit__half-width-styles__half:first-child {
  padding-right: 6px;
}

.ladder_account_next_views_profile_edit__half-width-styles__half:last-child {
  padding-left: 6px;
}
.ladder_account_next_cards_need_help__hours-styles__\% {
  margin-top: 20px;
}

.ladder_account_next_cards_need_help__hours-styles__\% *:first-child {
  margin-bottom: 2.5px;
}
.ladder_agents_dashboard_quote__get-a-quote-button-styles__quoter-button {
  width: 100%;
  filter: drop-shadow(0 3px 6px rgba(37, 112, 170, 0.25));
}

.ladder_agents_dashboard_quote__get-a-quote-button-styles__quoter-button-container {

}
.ladder_header__top-nav-icon-styles__icon {
  display: flex;
  justify-content: center;
  position: relative;
  width: 32px;
}

.ladder_header__top-nav-icon-styles__alert-dot {
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: #CB3A3A;
  position: absolute;
  top: -2px;
  right: 1px;
  z-index: 1;
}
.ladder_home__logo-panel-styles__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  box-sizing: content-box;
  justify-content: space-between;
}

.ladder_home__logo-panel-styles__logo {
  padding: 20px 5.0px;
  height: 32px;
  max-width: 12%;
}

.ladder_home__logo-panel-styles__logo {
  fill: #707070;
}

.ladder_home__logo-panel-styles__logo path[fill]:not([fill=none]) {
  fill: #707070;
}

.ladder_home__logo-panel-styles__logo g[fill]:not([fill=none]) {
  fill: #707070;
}

.ladder_home__logo-panel-styles__logo path[stroke]:not([stroke=none]) {
  stroke: #707070;
}

.ladder_home__logo-panel-styles__logo g[stroke]:not([stroke=none]) {
  stroke: #707070;
}

.ladder_home__logo-panel-styles__logo-large {
  max-width: 18%;
  padding: 10.0px 5.0px;
  height: 64px;
}

.ladder_home__logo-panel-styles__logo-large {
  fill: #707070;
}

.ladder_home__logo-panel-styles__logo-large path[fill]:not([fill=none]) {
  fill: #707070;
}

.ladder_home__logo-panel-styles__logo-large g[fill]:not([fill=none]) {
  fill: #707070;
}

.ladder_home__logo-panel-styles__logo-large path[stroke]:not([stroke=none]) {
  stroke: #707070;
}

.ladder_home__logo-panel-styles__logo-large g[stroke]:not([stroke=none]) {
  stroke: #707070;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_home__logo-panel-styles__logo {
    max-width: 18%;
  }
  
  .ladder_home__logo-panel-styles__logo-large {
    max-width: 22%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_home__logo-panel-styles__logo {
    max-width: 30%;
    padding-left: 0;
    padding-right: 0;
  }
  
  .ladder_home__logo-panel-styles__logo-large {
    max-width: none;
    padding: 10.0px 5.0px;
    height: 64px;
  }

}
@media (max-width: 499px) {

  
  
  .ladder_accessibility__Root-styles__link {
    width: 100%;
  }

}
.ladder_apply_current_page_ui__current-page-ui-styles__root {
  width: 100%;
}

.ladder_apply_current_page_ui__current-page-ui-styles__quick-start-padding {
  padding-top: 20px;
}

.ladder_apply_current_page_ui__current-page-ui-styles__top-padding-none {
  padding-top: 0px;
}

.ladder_apply_current_page_ui__current-page-ui-styles__hidden {
  display: none;
}

.ladder_apply_current_page_ui__current-page-ui-styles__sticky-review-button {
  position: sticky;
  bottom: 40px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
}

.ladder_apply_current_page_ui__current-page-ui-styles__sticky-go-to-question-button {
  position: sticky;
  bottom: 40px;
  width: 100%;
  margin-bottom: 20px;
}

.ladder_apply_current_page_ui__current-page-ui-styles__full-width-hack-overflow-x-hidden {
  overflow-x: hidden;
}

.ladder_apply_current_page_ui__current-page-ui-styles__app-top-padding-none {
  padding-top: 0;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_apply_current_page_ui__current-page-ui-styles__app-top-padding-none {
    padding-top: 0;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_apply_current_page_ui__current-page-ui-styles__app-top-padding-none-mobile-only {
    padding-top: 0;
  }
  
  .ladder_apply_current_page_ui__current-page-ui-styles__app-top-padding-none {
    padding-top: 0;
  }
  
  .ladder_apply_current_page_ui__current-page-ui-styles__mobile-fixed-bottom {
    opacity: 1;
    position: fixed;
    bottom: 0;
    background-color: #FFFFFF;
    box-shadow: 0 -2px 4px 2px rgba(0, 0, 0, 0.15);
    z-index: 5;
    width: 100%;
  }
  
  .ladder_apply_current_page_ui__current-page-ui-styles__modal-z-index {
    z-index: 14;
  }

}

.ladder_apply_current_page_ui__current-page-ui-styles__captioned-group-header {
  max-width: 600px;
  width: 100%;
}

.ladder_apply_current_page_ui__current-page-ui-styles__sectioned-app-header {
  max-width: 600px;
  width: 90%;
  margin: 0 auto;
}
.ladder_ux_typography__deprecated-text-styles__inherit {
  color: inherit;
}

.ladder_ux_typography__deprecated-text-styles__white {
  color: #FFFFFF;
}

.ladder_ux_typography__deprecated-text-styles__light-gray {
  color: #D0D0D0;
}

.ladder_ux_typography__deprecated-text-styles__medium-gray {
  color: #707070;
}

.ladder_ux_typography__deprecated-text-styles__darker-gray {
  color: #4C4C4E;
}

.ladder_ux_typography__deprecated-text-styles__dark-blue {
  color: #475665;
}

.ladder_ux_typography__deprecated-text-styles__red {
  color: #FE4747;
}

.ladder_ux_typography__deprecated-text-styles__dark-red {
  color: #DA2413;
}

.ladder_ux_typography__deprecated-text-styles__error {
  color: #CB3A3A;
}

.ladder_ux_typography__deprecated-text-styles__black {
  color: #000000;
}

.ladder_ux_typography__deprecated-text-styles__tan {
  color: #D1A98F;
}

.ladder_ux_typography__deprecated-text-styles__phoenix {
  color: #975C46;
}

.ladder_ux_typography__deprecated-text-styles__scorpion {
  color: #5D5D5D;
}

.ladder_ux_typography__deprecated-text-styles__disabled {
  color: #BDBDBD;
}

.ladder_ux_typography__deprecated-text-styles__light-beige {
  color: #F7F2EF;
}

.ladder_ux_typography__deprecated-text-styles__aqua-squeeze {
  color: #DBE6DB;
}

.ladder_ux_typography__deprecated-text-styles__mystic {
  color: #E7EEEF;
}

.ladder_ux_typography__deprecated-text-styles__cape-cod {
  color: #464F50;
}

.ladder_ux_typography__deprecated-text-styles__spring-wood {
  color: #F7F2EF;
}

.ladder_ux_typography__deprecated-text-styles__dark-green {
  color: #91AF92;
}

.ladder_ux_typography__deprecated-text-styles__opal {
  color: #AFC5C8;
}

.ladder_ux_typography__deprecated-text-styles__orange-brown {
  color: #D1A98F;
}

.ladder_ux_typography__deprecated-text-styles__gamboge-brown {
  color: #9B6600;
}

.ladder_ux_typography__deprecated-text-styles__my-pink {
  color: #CF967E;
}

.ladder_ux_typography__deprecated-text-styles__light-black {
  color: #1A1A1A;
}

.ladder_ux_typography__deprecated-text-styles__mine-shaft {
  color: #3B3B3B;
}

.ladder_ux_typography__deprecated-text-styles__helvetica {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.ladder_ux_typography__deprecated-text-styles__lato {
  font-family: Lato, sans-serif;
}

.ladder_ux_typography__deprecated-text-styles__mono {
  font-family: SFMono-Regular, Consolas, 'Liberation Mono', Menlo, Courier, monospace;;
}

.ladder_ux_typography__deprecated-text-styles__montserrat {
  font-family: Montserrat, sans-serif;
}

.ladder_ux_typography__deprecated-text-styles__tt-norms {
  font-family: TTNorms, sans-serif;
}

.ladder_ux_typography__deprecated-text-styles__larsseit {
  font-family: Larsseit, sans-serif;
}

.ladder_ux_typography__deprecated-text-styles__sailec {
  font-family: Sailec, sans-serif;
}

.ladder_ux_typography__deprecated-text-styles__work-sans {
  font-family: Work Sans, sans-serif;
}

.ladder_ux_typography__deprecated-text-styles__subpixel {
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
}

.ladder_ux_typography__deprecated-text-styles__grayscale {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ladder_ux_typography__deprecated-text-styles__uppercase {
  text-transform: uppercase;
}

.ladder_ux_typography__deprecated-text-styles__italic {
  font-style: italic;
}

.ladder_ux_typography__deprecated-text-styles__underline-dashed {
  text-decoration: underline dashed;
  text-underline-offset: 20%;
}

.ladder_ux_typography__deprecated-text-styles__underline-dotted {
  text-decoration: underline dotted;
  text-underline-offset: 20%;
}

.ladder_ux_typography__deprecated-text-styles__underline {
  text-decoration: underline;
  text-underline-offset: 20%;
}

.ladder_ux_typography__deprecated-text-styles__ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ladder_ux_typography__deprecated-text-styles__center {
  text-align: center;
}

.ladder_ux_typography__deprecated-text-styles__left {
  text-align: left;
}

.ladder_ux_typography__deprecated-text-styles__right {
  text-align: right;
}

.ladder_ux_typography__deprecated-text-styles__s-l {
  letter-spacing: 0.25em;
}

.ladder_ux_typography__deprecated-text-styles__light {
  font-weight: 300;
}

.ladder_ux_typography__deprecated-text-styles__light-medium {
  font-weight: 400;
}

.ladder_ux_typography__deprecated-text-styles__medium {
  font-weight: 500;
}

.ladder_ux_typography__deprecated-text-styles__slight-bold {
  font-weight: 550;
}

.ladder_ux_typography__deprecated-text-styles__medium-bold {
  font-weight: 600;
}

.ladder_ux_typography__deprecated-text-styles__bold {
  font-weight: 700;
}

.ladder_ux_typography__deprecated-text-styles__super-bold {
  font-weight: 900;
}

.ladder_ux_typography__deprecated-text-styles__article-s {
  font-size: 18px;
  line-height: 24px;
}

.ladder_ux_typography__deprecated-text-styles__article-quote {
  font-size: 25px;
  line-height: 38px;
}

.ladder_ux_typography__deprecated-text-styles__xxxs {
  font-size: 10px;
  line-height: 15px;
}

.ladder_ux_typography__deprecated-text-styles__xxs {
  font-size: 12px;
  line-height: 15px;
}

.ladder_ux_typography__deprecated-text-styles__xs {
  font-size: 13px;
  line-height: 16px;
}

.ladder_ux_typography__deprecated-text-styles__s {
  font-size: 16px;
  line-height: 26px;
}

.ladder_ux_typography__deprecated-text-styles__m {
  font-size: 20px;
  line-height: 35px;
}

.ladder_ux_typography__deprecated-text-styles__l {
  font-size: 24px;
  line-height: 32px;
}

.ladder_ux_typography__deprecated-text-styles__ll {
  font-size: 30px;
  line-height: 40px;
}

.ladder_ux_typography__deprecated-text-styles__xl {
  font-size: 40px;
  line-height: 60px;
  letter-spacing: -0.3px;
}

.ladder_ux_typography__deprecated-text-styles__header-s {
  font-size: 46px;
  light-height: 46px;
  letter-spacing: -0.4px;
}

.ladder_ux_typography__deprecated-text-styles__xxl {
  font-size: 64px;
  line-height: 72px;
  letter-spacing: -0.6px;
}

.ladder_ux_typography__deprecated-text-styles__xxxl {
  font-size: 100px;
  line-height: 102px;
  letter-spacing: -1px;
}

.ladder_ux_typography__deprecated-text-styles__info-content {
  font-size: 18px;
  line-height: 30px;
}

.ladder_ux_typography__deprecated-text-styles__info-header {
  font-size: 20px;
  line-height: 35px;
}

.ladder_ux_typography__deprecated-text-styles__chat-widget {
  font-size: 16px;
  line-height: 19.5px;
}

.ladder_ux_typography__deprecated-text-styles__story-quote-text {
  font-size: 30px;
  line-height: 40px;
}

.ladder_ux_typography__deprecated-text-styles__story-name-text {
  font-size: 20px;
  line-height: 35px;
}

.ladder_ux_typography__deprecated-text-styles__fine-print {
  font-size: 11px;
  line-height: 17px;
}

.ladder_ux_typography__deprecated-text-styles__inline {
  display: inline;
}

.ladder_ux_typography__deprecated-text-styles__full-width {
  width: 100%;
}

@media (min-width: 1025px) {

  
  
  .ladder_ux_typography__deprecated-text-styles__extra-wide {
    width: 110%;
  }

}

.ladder_ux_typography__deprecated-text-styles__nowrap {
  white-space: nowrap;
}

.ladder_ux_typography__deprecated-text-styles__pre-wrap {
  white-space: pre-wrap;
}

.ladder_ux_typography__deprecated-text-styles__rungs-xs {
  font-size: 12px;
  line-height: 18px;
}

.ladder_ux_typography__deprecated-text-styles__rungs-s {
  font-size: 15px;
  line-height: 30px;
}

.ladder_ux_typography__deprecated-text-styles__homepage-l {
  font-size: 20px;
  line-height: 30px;
}

.ladder_ux_typography__deprecated-text-styles__rungs-l {
  font-size: 20px;
  line-height: 30px;
}

.ladder_ux_typography__deprecated-text-styles__az-l {
  font-size: 24px;
  line-height: 32px;
}

.ladder_ux_typography__deprecated-text-styles__rungs-lll {
  font-size: 33px;
  line-height: 63px;
}

.ladder_ux_typography__deprecated-text-styles__rungs-xl {
  font-size: 40px;
  line-height: 50px;
  letter-spacing: -0.3px;
}

.ladder_ux_typography__deprecated-text-styles__rungs-xl-alternate {
  font-size: 40px;
  line-height: 65px;
  letter-spacing: -0.3px;
}

.ladder_ux_typography__deprecated-text-styles__rungs-xxl {
  font-size: 55px;
  line-height: 75px;
  letter-spacing: -0.6px;
}

.ladder_ux_typography__deprecated-text-styles__homepage-bigger-xxl {
  font-size: 55px;
  line-height: 70px;
  letter-spacing: -0.6px;
}

.ladder_ux_typography__deprecated-text-styles__homepage-xxl {
  font-size: 55px;
  line-height: 70px;
  letter-spacing: -0.6px;
}

.ladder_ux_typography__deprecated-text-styles__laddering-page-xxl {
  font-size: 55px;
  line-height: 70px;
  letter-spacing: -0.6px;
}

.ladder_ux_typography__deprecated-text-styles__homepage-xxxl {
  font-size: 80px;
  line-height: 90px;
  letter-spacing: -1px;
}

.ladder_ux_typography__deprecated-text-styles__rungs-xxxl {
  font-size: 80px;
  line-height: 90px;
  letter-spacing: -1px;
}

.ladder_ux_typography__deprecated-text-styles__header-3 {
  font-size: 20px;
  line-height: 30px;
}

.ladder_ux_typography__deprecated-text-styles__rungs-header-3 {
  font-size: 30px;
  line-height: 45px;
}

.ladder_ux_typography__deprecated-text-styles__header-4 {
  font-size: 20px;
  line-height: 30px;
}

.ladder_ux_typography__deprecated-text-styles__rungs-header-4 {
  font-size: 20px;
  line-height: 30px;
}

.ladder_ux_typography__deprecated-text-styles__header-5 {
  font-size: 16px;
  line-height: 26px;
}

.ladder_ux_typography__deprecated-text-styles__rungs-header-5 {
  font-size: 15px;
  line-height: 22.5px;
}

.ladder_ux_typography__deprecated-text-styles__header-6 {
  font-size: 12px;
  line-height: 18px;
}

.ladder_ux_typography__deprecated-text-styles__rungs-header-6 {
  font-size: 12px;
  line-height: 18px;
}

.ladder_ux_typography__deprecated-text-styles__italic {
  font-style: italic;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_typography__deprecated-text-styles__homepage-xxl {
    font-size: 40px;
    line-height: 50px;
    letter-spacing: -0.3px;
  }
  
  .ladder_ux_typography__deprecated-text-styles__laddering-page-xxl {
    font-size: 40px;
    line-height: 50px;
    letter-spacing: -0.3px;
  }
  
  .ladder_ux_typography__deprecated-text-styles__homepage-xxxl {
    font-size: 55px;
    line-height: 70px;
    letter-spacing: -0.6px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_typography__deprecated-text-styles__grayscale-on-mobile {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .ladder_ux_typography__deprecated-text-styles__xxs {
    font-size: 12px;
    line-height: 14px;
  }
  
  .ladder_ux_typography__deprecated-text-styles__xs {
    font-size: 12px;
    line-height: 14px;
  }
  
  .ladder_ux_typography__deprecated-text-styles__s {
    font-size: 14px;
    line-height: 20px;
  }
  
  .ladder_ux_typography__deprecated-text-styles__sm {
    font-size: 15px;
    line-height: 30px;
  }
  
  .ladder_ux_typography__deprecated-text-styles__m {
    font-size: 16px;
    line-height: 30px;
  }
  
  .ladder_ux_typography__deprecated-text-styles__l {
    font-size: 18px;
    line-height: 24px;
  }
  
  .ladder_ux_typography__deprecated-text-styles__ll {
    font-size: 20px;
    line-height: 32px;
  }
  
  .ladder_ux_typography__deprecated-text-styles__xl {
    font-size: 24px;
    line-height: 36px;
  }
  
  .ladder_ux_typography__deprecated-text-styles__xxl {
    font-size: 30px;
    line-height: 40px;
  }
  
  .ladder_ux_typography__deprecated-text-styles__xxxl {
    font-size: 42px;
    line-height: 52px;
  }
  
  .ladder_ux_typography__deprecated-text-styles__story-quote-text {
    font-size: 14px;
    line-height: 18px;
  }
  
  .ladder_ux_typography__deprecated-text-styles__story-name-text {
    font-size: 14px;
    line-height: 20px;
  }
  
  .ladder_ux_typography__deprecated-text-styles__info-content {
    font-size: 12px;
    line-height: 20px;
  }
  
  .ladder_ux_typography__deprecated-text-styles__info-header {
    font-size: 18px;
    line-height: 30px;
  }
  
  .ladder_ux_typography__deprecated-text-styles__geico-smart-sort {
    font-size: 14px;
    line-height: 21px;
  }
  
  .ladder_ux_typography__deprecated-text-styles__rungs-xs {
    font-size: 12px;
    line-height: 14px;
  }
  
  .ladder_ux_typography__deprecated-text-styles__rungs-xs13 {
    font-size: 13px;
    line-height: 14px;
  }
  
  .ladder_ux_typography__deprecated-text-styles__rungs-s {
    font-size: 14px;
    line-height: 30px;
  }
  
  .ladder_ux_typography__deprecated-text-styles__rungs-l {
    font-size: 18px;
    line-height: 24px;
  }
  
  .ladder_ux_typography__deprecated-text-styles__rungs-lll {
    font-size: 20px;
    line-height: 40px;
  }
  
  .ladder_ux_typography__deprecated-text-styles__rungs-xl {
    font-size: 24px;
    line-height: 36px;
  }
  
  .ladder_ux_typography__deprecated-text-styles__rungs-xxl {
    font-size: 30px;
    line-height: 40px;
  }
  
  .ladder_ux_typography__deprecated-text-styles__rungs-xxxl {
    font-size: 42px;
    line-height: 52px;
  }
  
  .ladder_ux_typography__deprecated-text-styles__header-3 {
    font-size: 18px;
    line-height: 24px;
  }
  
  .ladder_ux_typography__deprecated-text-styles__rungs-header-3 {
    font-size: 18px;
    line-height: 24px;
  }
  
  .ladder_ux_typography__deprecated-text-styles__header-4 {
    font-size: 16px;
    line-height: 22px;
  }
  
  .ladder_ux_typography__deprecated-text-styles__rungs-header-4 {
    font-size: 16px;
    line-height: 22px;
  }
  
  .ladder_ux_typography__deprecated-text-styles__header-5 {
    font-size: 14px;
    line-height: 20px;
  }
  
  .ladder_ux_typography__deprecated-text-styles__rungs-header-5 {
    font-size: 14px;
    line-height: 20px;
  }
  
  .ladder_ux_typography__deprecated-text-styles__header-6 {
    font-size: 12px;
    line-height: 14px;
  }
  
  .ladder_ux_typography__deprecated-text-styles__rungs-header-6 {
    font-size: 12px;
    line-height: 14px;
  }
  
  .ladder_ux_typography__deprecated-text-styles__az-l {
    font-size: 20px;
    line-height: 26px;
  }
  
  .ladder_ux_typography__deprecated-text-styles__rungs-xl-alternate {
    font-size: 24px;
    line-height: 36px;
  }
  
  .ladder_ux_typography__deprecated-text-styles__homepage-l {
    font-size: 16px;
    line-height: 22px;
  }
  
  .ladder_ux_typography__deprecated-text-styles__homepage-bigger-xxl {
    font-size: 40px;
    line-height: 50px;
    letter-spacing: -0.3px;
  }
  
  .ladder_ux_typography__deprecated-text-styles__homepage-xxl {
    font-size: 40px;
    line-height: 50px;
    letter-spacing: -0.3px;
  }
  
  .ladder_ux_typography__deprecated-text-styles__homepage-xxxl {
    font-size: 40px;
    line-height: 50px;
    letter-spacing: -0.3px;
  }
  
  .ladder_ux_typography__deprecated-text-styles__laddering-page-xxl {
    font-size: 30px;
    line-height: 45px;
    font-weight: 600;
    letter-spacing: -0.3px;
  }

}
.ladder_about__OurLeaderModal-styles__container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  margin: 20px 0;
}

.ladder_about__OurLeaderModal-styles__picture {
  width: auto;
  height: 370px;
  margin: 0 30.0px;
}

.ladder_about__OurLeaderModal-styles__description {
  display: flex;
  flex-direction: column;
  margin: 0 20px 20px 0;
}

.ladder_about__OurLeaderModal-styles__leader-text {

}

.ladder_about__OurLeaderModal-styles__role-text {
  margin-bottom: 30.0px;
}

.ladder_about__OurLeaderModal-styles__action-buttons {
  display: flex;
  flex-direction: row;
  margin: 30.0px 0 0 0;
}

.ladder_about__OurLeaderModal-styles__prev-button {
  margin-right: 20px;
}

.ladder_about__OurLeaderModal-styles__next-button {

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_about__OurLeaderModal-styles__container {
    flex-direction: column;
  }
  
  .ladder_about__OurLeaderModal-styles__description {
    margin: 10.0px 30.0px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_about__OurLeaderModal-styles__container {
    flex-direction: column;
    width: 90%;
    margin: auto;
    margin-bottom: 130px;
  }
  
  .ladder_about__OurLeaderModal-styles__description {
    width: 100%;
    margin: unset;
  }
  
  .ladder_about__OurLeaderModal-styles__action-buttons {
    width: 100%;
    height: 105px;
    margin: auto;
    padding: 20px 10.0px;
    position: fixed;
    border-top: 0.5px solid #D0D0D0;
    z-index: 15;
    background-color: #FFFFFF;
    bottom: 0;
    left: 0;
  }
  
  .ladder_about__OurLeaderModal-styles__picture {
    width: 100%;
    height: auto;
    margin: 0 0 20px 0;
  }

}
.ladder_form_quick_start__one-page-styles__form-fields {
  margin: 25.0px 0;
}

.ladder_form_quick_start__one-page-styles__form-fields>div {
  margin-bottom: 10.0px;
}

.ladder_form_quick_start__one-page-styles__row {
  display: flex;
  justify-content: space-between;
}

.ladder_form_quick_start__one-page-styles__row > *:not(:last-child) {
  margin-right: 20px;
}

.ladder_form_quick_start__one-page-styles__row>div {
  width: 100%;
}

.ladder_form_quick_start__one-page-styles__header {
  margin-bottom: 16px;
}

.ladder_form_quick_start__one-page-styles__subheading {
  margin-bottom: 25px;
}

@media (min-width: 1025px) {

  
  
  .ladder_form_quick_start__one-page-styles__form-fields {
    margin: 20px 0 30.0px 0;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_form_quick_start__one-page-styles__row {
    display: block;
  }
  
  .ladder_form_quick_start__one-page-styles__row>div {
    margin-top: 10px;
  }

}
.ladder_form_quick_start__one-page-coverage-styles__form-fields {
  margin: 25.0px 0;
}

.ladder_form_quick_start__one-page-coverage-styles__form-fields>div {
  margin-bottom: 10.0px;
}

@media (min-width: 1025px) {

  
  
  .ladder_form_quick_start__one-page-coverage-styles__half-width-desktop {
    width: 47%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_form_quick_start__one-page-coverage-styles__half-width-desktop {
    width: 47%;
  }

}

.ladder_form_quick_start__one-page-coverage-styles__display-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (max-width: 499px) {

  
  
  .ladder_form_quick_start__one-page-coverage-styles__display-flex {
    flex-direction: column;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_form_quick_start__one-page-coverage-styles__form-fields {
    margin: 20px 0 30.0px 0;
  }

}
.ladder_text__label-styles__root {

}
.ladder_widget_ui_v1__footer-styles__root {
  display: flex;
  align-items: center;
  padding: 10.0px 0;
}

.ladder_widget_ui_v1__footer-styles__ladder-icon {
  height: 22px;
  margin-left: 10.0px;
}
.ladder_account_payment_history__RungsFullHistory-styles__year-select {
  max-width: 250px;
  padding: 0;
  margin: 0 20px 20px 20px;
}

.ladder_account_payment_history__RungsFullHistory-styles__header {
  margin: 20px 20px;
}

@media (max-width: 499px) {

  
  
  .ladder_account_payment_history__RungsFullHistory-styles__year-select {
    margin: 0 22.5px 20px 22.5px;
  }
  
  .ladder_account_payment_history__RungsFullHistory-styles__header {
    margin: 20px 22.5px;
  }

}
.ladder_header__top-navigation-hamburger-styles__root {
  position: fixed;
  overflow-y: auto;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  pointer-events: none;
}

.ladder_header__top-navigation-hamburger-styles__open-root {
  pointer-events: auto;
}

.ladder_header__top-navigation-hamburger-styles__menu {
  background-color: white;
  position: relative;
  border: 1px solid #D9D9D9;
  margin: 4vw;
  pointer-events: auto;
}

.ladder_header__top-navigation-hamburger-styles__close-button {
  position: absolute;
  top: 20px;
  right: 20px;
}

.ladder_header__top-navigation-hamburger-styles__sections > *:not(:first-child) {
  border-top: 1px solid #D9D9D9;
}

.ladder_header__top-navigation-hamburger-styles__section {
  padding: 25.0px 20px;
}

.ladder_header__top-navigation-hamburger-styles__section .ladder_header__top-navigation-hamburger-styles__section-header {
  margin-bottom: 10.0px;
}

.ladder_header__top-navigation-hamburger-styles__section>a:last-child {
  padding-bottom: 0;
}
.ladder_prudential_views__prudential-quote-ui-styles__wrapper {
  position: fixed;
  top: 100px;
  left: 0;
  width: 100%;
  background-color: #F7F2EF;
  display: flex;
  justify-content: center;
}

.ladder_prudential_views__prudential-quote-ui-styles__wrapper.ladder_prudential_views__prudential-quote-ui-styles__themed {
  background-color: white;
}

.ladder_prudential_views__prudential-quote-ui-styles__wrapper.ladder_prudential_views__prudential-quote-ui-styles__relative {
  position: relative;
  top: 0;
}

@media (min-width: 1025px) {

  
  
  .ladder_prudential_views__prudential-quote-ui-styles__wrapper {
    height: calc(100% - 100px);
    top: 100px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_prudential_views__prudential-quote-ui-styles__wrapper {
    height: calc(100% - 60px);
    top: 60px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_prudential_views__prudential-quote-ui-styles__wrapper {
    height: calc(100% - 60px);
    top: 60px;
  }

}

.ladder_prudential_views__prudential-quote-ui-styles__content-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  overflow-y: scroll;
}

.ladder_prudential_views__prudential-quote-ui-styles__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: fit-content;
  max-width: 600px;
}

@media (max-width: 499px) {

  
  
  .ladder_prudential_views__prudential-quote-ui-styles__content {
    padding: 0 30px;
  }

}

.ladder_prudential_views__prudential-quote-ui-styles__header {
  margin: 60px 0 30px 0;
}

@media (max-width: 499px) {

  
  
  .ladder_prudential_views__prudential-quote-ui-styles__header {
    margin: 20px 0;
  }

}

.ladder_prudential_views__prudential-quote-ui-styles__quote {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 35px;
  background-color: #FFFFFF;
  border-radius: 10px;
  margin: 25px 0;
}

.ladder_prudential_views__prudential-quote-ui-styles__logo {
  height: 56px;
  margin-bottom: 20px;
  align-self: flex-start;
}

.ladder_prudential_views__prudential-quote-ui-styles__slider {
  flex-basis: 50%;
  margin-bottom: 20px;
}

.ladder_prudential_views__prudential-quote-ui-styles__apply-button {
  border-radius: 10px;
  width: 100%;
  margin: 25px 0 10px 0;
}

.ladder_prudential_views__prudential-quote-ui-styles__apply-link {
  width: 100%;
}

.ladder_prudential_views__prudential-quote-ui-styles__apply {
  padding-bottom: 35px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 499px) {

  
  
  .ladder_prudential_views__prudential-quote-ui-styles__quote {
    padding: 30px;
  }
  
  .ladder_prudential_views__prudential-quote-ui-styles__logo {
    height: 40px;
  }

}

.ladder_prudential_views__prudential-quote-ui-styles__why {
  border: solid 1px;
  border-color: #749475;
  border-radius: 10px;
  padding: 25px 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 30px;
}

.ladder_prudential_views__prudential-quote-ui-styles__why-bullet {
  display: flex;
  gap: 15px;
}

.ladder_prudential_views__prudential-quote-ui-styles__checkmark {
  min-height: 30px;
  min-width: 30px;
  height: 30px;
  width: 30px;
}

@media (max-width: 499px) {

  
  
  .ladder_prudential_views__prudential-quote-ui-styles__why {
    padding: 25px;
    gap: 20px;
  }
  
  .ladder_prudential_views__prudential-quote-ui-styles__checkmark {
    min-height: 25px;
    min-width: 25px;
    height: 25px;
    width: 25px;
  }

}

.ladder_prudential_views__prudential-quote-ui-styles__legal {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin: 50px 0;
}

@media (max-width: 499px) {

  
  
  .ladder_prudential_views__prudential-quote-ui-styles__legal {
    margin: 30px 0;
  }

}

.ladder_prudential_views__prudential-quote-ui-styles__estimated-price-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 10.0px;
  width: 100%;
  margin-bottom: 20px;
}

.ladder_prudential_views__prudential-quote-ui-styles__estimated-price {
  padding: 16px;
  border: 2px solid #007AC1;
  border-radius: 8px;
  background-color: #007AC11A;
}

.ladder_prudential_views__prudential-quote-ui-styles__estimated-price-title {
  margin-bottom: 24px;
}

@media (max-width: 499px) {

  
  
  .ladder_prudential_views__prudential-quote-ui-styles__estimated-price-title {
    margin-bottom: 16px;
  }

}

.ladder_prudential_views__prudential-quote-ui-styles__themed .ladder_prudential_views__prudential-quote-ui-styles__wrapper {
  background-color: #FFFFFF;
}

.ladder_prudential_views__prudential-quote-ui-styles__themed .ladder_prudential_views__prudential-quote-ui-styles__quote {
  background-color: none;
  border: solid 1px;
}

.ladder_prudential_views__prudential-quote-ui-styles__themed .ladder_prudential_views__prudential-quote-ui-styles__why {
  border: none;
  background-color: #F1F1F1;
}
.ladder_views_decision_carousel__step-styles__root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0;
}

.ladder_views_decision_carousel__step-styles__text {
  transition: color 200ms ease-in;
}

.ladder_views_decision_carousel__step-styles__icon-container {
  height: 25px;
  width: 25px;
  position: relative;
}

.ladder_views_decision_carousel__step-styles__icon {
  height: 25px;
  width: 25px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.ladder_guide_common__top-menu-styles__x-menu-item {
  min-height: 40px;
  width: 100%;
  background-color: #EFEAE1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 30.0px;
}

.ladder_guide_common__top-menu-styles__x-button {
  width: 11px;
  cursor: pointer;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_guide_common__top-menu-styles__x-button {
    margin-top: 11px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_guide_common__top-menu-styles__x-button {
    margin-top: 11px;
  }
  
  .ladder_guide_common__top-menu-styles__x-menu-item {
    padding-right: 20px;
  }

}
.ladder_ux_form__text-area-inner-styles__root-common {
  display: flex;
  box-sizing: border-box;
}

.ladder_ux_form__text-area-inner-styles__textarea-common {
  resize: none;
  margin: 0;
  padding: 8.0px 0;
  box-sizing: border-box;
  line-height: 24px;
  width: 100%;
}

.ladder_ux_form__text-area-inner-styles__textarea-common:-webkit-autofill {
  transition: background-color 0s 7200s;
}

.ladder_ux_form__text-area-inner-styles__textarea-common:disabled {
  color: #707070;
}
.ladder_views_decision_main__additional-options-modal-styles__modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.ladder_views_decision_optional_tic_common__OptionalTICOffer-styles__legalese {
  max-width: 750px;
}
.ladder_widget_ui_v1__WidgetBack-styles__root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  width: 300px;
  height: 350px;
}

.ladder_widget_ui_v1__WidgetBack-styles__quote-price-container {
  display: flex;
  padding: 10.0px 0;
  justify-content: center;
  align-items: flex-end;
}

.ladder_widget_ui_v1__WidgetBack-styles__dollar-sign {
  align-self: flex-start;
}
.ladder_widget_ui_v2__get-quote-button-styles__root {
  display: flex;
  align-items: flex-end;
}

.tile-a .ladder_widget_ui_v2__get-quote-button-styles__root {
  margin-top: 10.0px;
}

.tile-b .ladder_widget_ui_v2__get-quote-button-styles__root {
  margin-top: 10.0px;
}

.portrait .ladder_widget_ui_v2__get-quote-button-styles__root {
  margin-top: 16px;
}

.panel .ladder_widget_ui_v2__get-quote-button-styles__root {
  margin-top: 16px;
}

.landscape .ladder_widget_ui_v2__get-quote-button-styles__root {
  flex-grow: 83;
}
.ladder_financial_advisors_terms_modal__PaidTermsModal-styles__modal-header {
  margin-bottom: 20px;
}
.ladder_gift_promo__cta-button-styles__cta-button {
  max-width: 350px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_gift_promo__cta-button-styles__cta-button {
    max-width: unset;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_gift_promo__cta-button-styles__cta-button {
    max-width: unset;
  }

}
.ladder_onboarding_spouse_connect_2__control-dots-wrapper-mobile-styles__control-dots-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: rgba(255, 255, 255, 0);
  justify-content: center;
  align-items: center;
}
.ladder_ny_form_weight__root-styles__input {
  width: 100px;
}

@media (max-width: 499px) {

  
  
  .ladder_ny_form_weight__root-styles__input {
    width: 50px;
  }

}
.ladder_lux_markdown_components__underline-styles__root {
  text-decoration: underline;
}
.ladder_header__top-navigation-styles__root {
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  padding-right: 20px;
  padding-left: 30.0px;
}

.ladder_header__top-navigation-styles__section-container {
  margin: 0 20px;
}

.ladder_header__top-navigation-styles__drop-down-container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-end;
}

.ladder_header__top-navigation-styles__bar {
  align-self: flex-start;
  width: 0;
  border-bottom: solid 2px #975C46;
  transition: width 200ms linear;
}

.ladder_header__top-navigation-styles__open-bar {
  width: 100%;
}

.ladder_header__top-navigation-styles__section-container:hover .ladder_header__top-navigation-styles__bar {
  width: 100%;
}

.ladder_header__top-navigation-styles__top-text {
  text-align: center;
  white-space: nowrap;
}

.ladder_header__top-navigation-styles__caret {
  margin-left: 5.0px;
}

.ladder_header__top-navigation-styles__themed .ladder_header__top-navigation-styles__top-text {
  color: var(--tc);
}

.ladder_header__top-navigation-styles__themed .ladder_header__top-navigation-styles__caret {
  fill: var(--tc);
}

.ladder_header__top-navigation-styles__themed .ladder_header__top-navigation-styles__bar {
  border-color: var(--tc);
}

.ladder_header__top-navigation-styles__drop-down-anchor {
  position: absolute;
  right: 0;
  margin-top: 48px;
  z-index: 99;
}

.ladder_header__top-navigation-styles__drop-down-anchor.ladder_header__top-navigation-styles__centered {
  transform: translateX(calc((100% - 32px) / 2));
}

.ladder_header__top-navigation-styles__drop-down {
  padding: 10.0px;
  background-color: #FFFFFF;
  border: 1px solid #D0D0D0;
  white-space: nowrap;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_header__top-navigation-styles__root {
    padding-right: 30.0px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_header__top-navigation-styles__root {
    display: none;
  }

}
.ladder_apply_app_navigation_drop_down__navigation-drop-down-title-component-styles__title-root {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 15.0px;
  flex-direction: row;
  border-bottom-color: #F1F1F1;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  display: flex;
}

.ladder_apply_app_navigation_drop_down__navigation-drop-down-title-component-styles__toggle-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25.0px;
  height: 25.0px;
}

.ladder_apply_app_navigation_drop_down__navigation-drop-down-title-component-styles__toggle-icon {
  width: 15.0px;
  height: 15.0px;
}
.ladder_affiliates__phone-video-looper-styles__phones {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}
.ladder_ux_logos__upgrade-logo-styles__icon {
  height: 44.8px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_logos__upgrade-logo-styles__icon {
    height: 38.4px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_logos__upgrade-logo-styles__icon {
    height: 28.8px;
  }

}
.ladder_views_decision_common__rider-info-bubble-styles__icon {
  display: flex;
  cursor: pointer;
  justify-content: center;
}

.ladder_views_decision_common__rider-info-bubble-styles__rider-bubble-container {
  gap: 0.5em;
  margin: 1em 0em;
  align-items: center;
  padding: 0.5em 1em;
  flex-direction: row;
  cursor: pointer;
  background-color: #F7DDC9;
  border-radius: 15px;
  display: flex;
}
.ladder_ux_panels__mobile-agent-contact-info-box-styles__tab {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding-top: 8.0px;
  padding-bottom: 8.0px;
  border-top: 1px solid #1A1A1A59;
}

.ladder_ux_panels__mobile-agent-contact-info-box-styles__bottom-tab {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.ladder_ux_panels__mobile-agent-contact-info-box-styles__bottom-tab>div {

}

.ladder_ux_panels__mobile-agent-contact-info-box-styles__bottom-tab>div:nth-child(1n) {
  border-right: 1px solid #1A1A1A59;
}

.ladder_ux_panels__mobile-agent-contact-info-box-styles__bottom-tab>div:last-child {
  border-right: none;
}
.ladder_cover_me_asap_views_billing__higher-price-disclaimer-styles__higher-price-disclaimer-container {
  display: flex;
  flex-direction: row;
  background-color: #EFEAE1;
  padding: 30.0px;
  border-radius: 8px;
}

.ladder_cover_me_asap_views_billing__higher-price-disclaimer-styles__higher-price-disclaimer-container-icon {
  display: flex;
  margin-top: 2.5px;
  margin-right: 10.0px;
}

.ladder_cover_me_asap_views_billing__higher-price-disclaimer-styles__higher-price-disclaimer-container-text {
  display: flex;
}
.ladder_agents_dashboard_pre_screener__info-icon-styles__icon {
  width: auto;
  height: 100%;
  max-height: 20px;
  margin-left: 10px;
}
.ladder_account_next_nav_nav_item__rungs-user-coverage-summary\*-styles__\% {
  height: 102px;
  display: flex;
  width: 85%;
  flex-direction: column;
  cursor: pointer;
  justify-content: center;
}
.ladder_ux_banner__banner-styles__\% {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 10;
  min-height: 50px;
  padding: 12px;
}
.ladder_financial_advisors_home__TopPanel-styles__container {
  width: 100%;
  z-index: 1;
  background-color: #EFEAE1;
  position: relative;
  padding: 60px 0 0 0;
}

.ladder_financial_advisors_home__TopPanel-styles__signup-container {
  z-index: 2;
}

.ladder_financial_advisors_home__TopPanel-styles__\% {
  width: 100%;
  margin: 0 auto;
  max-width: 1440px;
}

.ladder_financial_advisors_home__TopPanel-styles__center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 1025px) {

  
  
  .ladder_financial_advisors_home__TopPanel-styles__container {
    padding: 80px 0 0 calc(min(130px, 10%));
  }
  
  .ladder_financial_advisors_home__TopPanel-styles__signup-container {
    margin: 0 0 90px 60px;
  }
  
  .ladder_financial_advisors_home__TopPanel-styles__\% {
    display: flex;
  }

}
.ladder_views_payment__payment-option-wrapper-styles__root {
  box-sizing: border-box;
  width: calc(100% / 3);
  padding: 8.0px;
}
@media (min-width: 1025px) {

  
  
  .ladder_account_next_views_overview__row-styles__\%:empty {
    display: none;
  }
  
  .ladder_account_next_views_overview__row-styles__\% {
    display: flex;
    margin: 16px 0;
  }
  
  .ladder_account_next_views_overview__row-styles__\%>* {
    margin: 0 8px;
    flex: 1 1 100%;
  }
  
  .ladder_account_next_views_overview__row-styles__\%>*:first-child {
    margin-left: 0;
  }
  
  .ladder_account_next_views_overview__row-styles__\%>*:last-child {
    margin-right: 0;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account_next_views_overview__row-styles__\%:empty {
    display: none;
  }
  
  .ladder_account_next_views_overview__row-styles__\% {
    display: flex;
    margin: 16px 0;
  }
  
  .ladder_account_next_views_overview__row-styles__\%>* {
    margin: 0 8px;
    flex: 1 1 100%;
  }
  
  .ladder_account_next_views_overview__row-styles__\%>*:first-child {
    margin-left: 0;
  }
  
  .ladder_account_next_views_overview__row-styles__\%>*:last-child {
    margin-right: 0;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_account_next_views_overview__row-styles__\% {
    display: contents;
  }
  
  .ladder_account_next_views_overview__row-styles__\%>* {
    margin: 16px 0;
  }

}
.ladder_landing_pages_so_good_hero__lttd-background-simple-styles__image-container {
  z-index: -1;
}

.ladder_landing_pages_so_good_hero__lttd-background-simple-styles__image {
  width: 100%;
  height: auto;
  z-index: -1;
  position: absolute;
  object-fit: stretch;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_landing_pages_so_good_hero__lttd-background-simple-styles__image {
    width: 130%;
    height: auto;
    right: -10%;
    object-fit: contain;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_landing_pages_so_good_hero__lttd-background-simple-styles__image {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

}
.ladder_views_decision_main__decline-styles__spinner {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 282px;
}

.ladder_views_decision_main__decline-styles__trustage-wrapper {
  width: 100%;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ladder_views_decision_main__decline-styles__mediaalpha-instant-decline {
  margin: 50px 5%;
}

.ladder_views_decision_main__decline-styles__trustage-or-mediaalpha-instant-decline-page {
  width: 100%;
  overflow: hidden;
}

@media (min-width: 1025px) {

  
  
  .ladder_views_decision_main__decline-styles__no-product {
    padding: 60px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_views_decision_main__decline-styles__no-product {
    padding: 60px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_views_decision_main__decline-styles__no-product {
    padding: 30px;
  }
  
  .ladder_views_decision_main__decline-styles__mediaalpha-instant-decline {
    margin: 0 5%;
  }

}
.ladder_text__medium-caps-styles__root {
  font-size: 100%;
  letter-spacing: 0.12em;
  font-weight: 400;
  text-transform: uppercase;
}
.tile-a .ladder_widget_ui_v2__age-field-styles__root {
  flex-basis: 100%;
}

.tile-b .ladder_widget_ui_v2__age-field-styles__root {
  width: 95.0px;
}
.ladder_ux_banner__alert-icon-styles__\% {
  width: 64px;
  align-self: flex-start;
  margin-top: 8px;
  margin-left: 8px;
  margin-right: 16px;
  z-index: 10;
}
.ladder_views_api__header-panel-styles__root {
  background-color: #CFDCDE;
  display: flex;
  justify-content: center;
  height: 732px;
  position: relative;
  width: 100%;
  z-index: 1;
  overflow: clip;
}

.ladder_views_api__header-panel-styles__text-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  max-width: 700px;
  margin: 64px 32px auto;
}

.ladder_views_api__header-panel-styles__heading {
  margin-bottom: 48px;
}

.ladder_views_api__header-panel-styles__caption {
  margin-bottom: 48px;
}

.ladder_views_api__header-panel-styles__phone-container {
  max-width: 700px;
  display: flex;
  justify-content: end;
  width: 50%;
  height: 909px;
}

.ladder_views_api__header-panel-styles__phone-frame {
  position: relative;
  top: 24.0px;
  width: 475px;
  height: 100%;
}

@media (min-width: 1025px) {

  
  
  .ladder_views_api__header-panel-styles__phone-container {
    margin-right: 32px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_views_api__header-panel-styles__text-container {
    align-items: center;
    text-align: center;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_views_api__header-panel-styles__root {
    height: unset;
    max-height: 800px;
    flex-direction: column;
    align-items: center;
    justify-content: unset;
  }
  
  .ladder_views_api__header-panel-styles__text-container {
    width: unset;
    padding: 0 32px;
    margin: 16px 8.0px;
  }
  
  .ladder_views_api__header-panel-styles__heading {
    margin-bottom: 24.0px;
    font-weight: 600;
    margin-top: 16px;
  }
  
  .ladder_views_api__header-panel-styles__phone-container {
    max-width: 100%;
    width: 320px;
    display: flex;
    justify-content: center;
    height: 770px;
  }
  
  .ladder_views_api__header-panel-styles__phone-frame {
    width: unset;
    height: 770px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_views_api__header-panel-styles__root {
    height: unset;
    max-height: 800px;
    flex-direction: column;
    align-items: center;
    justify-content: unset;
  }
  
  .ladder_views_api__header-panel-styles__text-container {
    width: unset;
    padding: 0 32px;
    margin: 16px 8.0px;
  }
  
  .ladder_views_api__header-panel-styles__heading {
    margin-bottom: 24.0px;
    font-weight: 600;
    margin-top: 16px;
  }
  
  .ladder_views_api__header-panel-styles__phone-container {
    max-width: 100%;
    width: 320px;
    display: flex;
    justify-content: center;
    height: 770px;
  }
  
  .ladder_views_api__header-panel-styles__phone-frame {
    width: unset;
    height: 770px;
  }

}
.ladder_ux_autocomplete__afs-autocomplete-styles__container {
  display: flex;
  flex-flow: row wrap;
  padding: 5.0px;
  min-height: 48px;
  box-sizing: border-box;
  border: 1px solid #D0D0D0;
  cursor: text;
}

.ladder_ux_autocomplete__afs-autocomplete-styles__focused-container {
  border-width: 2.5px;
  margin: -1.5px;
}

.ladder_ux_autocomplete__afs-autocomplete-styles__selected-item {
  display: flex;
  align-items: center;
  padding: 10.0px 10.0px;
  margin: 2px;
  height: 32px;
  border-radius: 16px;
  box-sizing: border-box;
  background-color: #F4F4F4;
}

.ladder_ux_autocomplete__afs-autocomplete-styles__selected-item-label {
  max-width: 240px;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  top: -1px;
  text-overflow: ellipsis;
}

.ladder_ux_autocomplete__afs-autocomplete-styles__remove-button {
  cursor: pointer;
  padding-left: 5.0px;
  width: 16px;
  height: 16px;
}

.ladder_ux_autocomplete__afs-autocomplete-styles__input-container {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  margin-left: 2px;
  width: auto;
  max-width: 100%;
}

.ladder_ux_autocomplete__afs-autocomplete-styles__input {
  padding: 10.0px 0;
  font: inherit;
  flex-grow: 1;
  line-height: 18px;
  border: 0;
  width: 100%;
  min-width: 20px;
}

.ladder_ux_autocomplete__afs-autocomplete-styles__input::placeholder {
  color: #707070;
}

.ladder_ux_autocomplete__afs-autocomplete-styles__autocomplete-root {
  position: relative;
  width: 100%;
  height: 0px;
}

.ladder_ux_autocomplete__afs-autocomplete-styles__autocomplete {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  background-color: #F4F4F4;
  border: 1px solid #D0D0D0;
  box-sizing: border-box;
  z-index: 1;
}

.ladder_ux_autocomplete__afs-autocomplete-styles__autocomplete-option {
  padding: 10.0px;
  border-bottom: 1px solid #D0D0D0;
  margin-bottom: -1px;
  cursor: pointer;
  outline: none;
}

.ladder_ux_autocomplete__afs-autocomplete-styles__autocomplete-option:hover {
  background-color: #707070;
}

.ladder_ux_autocomplete__afs-autocomplete-styles__autocomplete-option.ladder_ux_autocomplete__afs-autocomplete-styles__selected {
  background-color: #D0D0D0;
}

.ladder_ux_autocomplete__afs-autocomplete-styles__autocomplete-option.ladder_ux_autocomplete__afs-autocomplete-styles__selected:hover {
  background-color: #707070;
}

.ladder_ux_autocomplete__afs-autocomplete-styles__two-col-label {
  display: flex;
  width: 100%;
  padding: 0% 5%;
  justify-content: space-between;
}

.ladder_ux_autocomplete__afs-autocomplete-styles__no-results {
  padding: 10.0px;
  background-color: #CB3A3A;
}

.ladder_ux_autocomplete__afs-autocomplete-styles__autocomplete-hidden {
  display: none;
}

.ladder_ux_autocomplete__afs-autocomplete-styles__rungs {
  text-transform: lowercase;
}

.ladder_ux_autocomplete__afs-autocomplete-styles__rungs .ladder_ux_autocomplete__afs-autocomplete-styles__container {
  border-color: #CBCBCB;
  padding: 15.0px;
  align-items: center;
}

.ladder_ux_autocomplete__afs-autocomplete-styles__rungs .ladder_ux_autocomplete__afs-autocomplete-styles__scrollable-app-container {
  background-color: #FFFFFF;
}

.ladder_ux_autocomplete__afs-autocomplete-styles__rungs .ladder_ux_autocomplete__afs-autocomplete-styles__selected-item {
  border-radius: 0;
  padding-right: 0;
}

.ladder_ux_autocomplete__afs-autocomplete-styles__rungs .ladder_ux_autocomplete__afs-autocomplete-styles__selected-item-label {
  top: 0;
}

.ladder_ux_autocomplete__afs-autocomplete-styles__rungs .ladder_ux_autocomplete__afs-autocomplete-styles__autocomplete {
  background-color: #FFFFFF;
}

.ladder_ux_autocomplete__afs-autocomplete-styles__rungs .ladder_ux_autocomplete__afs-autocomplete-styles__autocomplete-option:hover {
  background-color: #D0D0D0;
}

.ladder_ux_autocomplete__afs-autocomplete-styles__rungs .ladder_ux_autocomplete__afs-autocomplete-styles__selected-item-label::first-letter {
  text-transform: uppercase;
}

.ladder_ux_autocomplete__afs-autocomplete-styles__rungs .ladder_ux_autocomplete__afs-autocomplete-styles__autocomplete-option::first-letter {
  text-transform: uppercase;
}

.ladder_ux_autocomplete__afs-autocomplete-styles__rungs .ladder_ux_autocomplete__afs-autocomplete-styles__remove-button {
  height: 32px;
  width: 32px;
  padding: 10.0px;
  margin-left: 10.0px;
  fill: #000000;
}

.ladder_ux_autocomplete__afs-autocomplete-styles__rungs .ladder_ux_autocomplete__afs-autocomplete-styles__input {
  font-size: 16px;
}

.ladder_ux_autocomplete__afs-autocomplete-styles__no-sentence-case {
  text-transform: none;
}

.ladder_ux_autocomplete__afs-autocomplete-styles__rungs-colors .ladder_ux_autocomplete__afs-autocomplete-styles__selected-item {
  background-color: #F7F2EF;
}

.ladder_ux_autocomplete__afs-autocomplete-styles__rungs-colors .ladder_ux_autocomplete__afs-autocomplete-styles__autocomplete-option {
  color: #707070;
}

.ladder_ux_autocomplete__afs-autocomplete-styles__rungs-colors .ladder_ux_autocomplete__afs-autocomplete-styles__autocomplete-option:hover {
  background-color: #D1A98F;
  color: #000000;
}

.ladder_ux_autocomplete__afs-autocomplete-styles__rungs-colors .ladder_ux_autocomplete__afs-autocomplete-styles__autocomplete-option.ladder_ux_autocomplete__afs-autocomplete-styles__selected {
  background-color: #D1A98F;
  color: #000000;
}

.ladder_ux_autocomplete__afs-autocomplete-styles__theme-colors .ladder_ux_autocomplete__afs-autocomplete-styles__autocomplete-option {
  background-color: #FFFFFF;
}

.ladder_ux_autocomplete__afs-autocomplete-styles__theme-colors .ladder_ux_autocomplete__afs-autocomplete-styles__autocomplete-option>* {
  color: #000000;
}

.ladder_ux_autocomplete__afs-autocomplete-styles__theme-colors .ladder_ux_autocomplete__afs-autocomplete-styles__autocomplete-option.ladder_ux_autocomplete__afs-autocomplete-styles__selected {
  background-color: #D0D0D0;
  color: #000000;
}

.ladder_ux_autocomplete__afs-autocomplete-styles__theme-colors .ladder_ux_autocomplete__afs-autocomplete-styles__autocomplete-option:hover {
  background-color: #707070;
  color: #FFFFFF;
}

.ladder_ux_autocomplete__afs-autocomplete-styles__theme-colors .ladder_ux_autocomplete__afs-autocomplete-styles__autocomplete-option>* {
  color: #FFFFFF;
}
.ladder_savings_common__steps-offer-panel-styles__icon {
  fill: #465046;
  width: 20px;
  height: auto;
  margin-right: 10.0px;
}

.ladder_savings_common__steps-offer-panel-styles__icon-wrapper {
  display: flex;
}

.ladder_savings_common__steps-offer-panel-styles__copy {
  display: flex;
  align-items: center;
}
.ladder_agents_dashboard_inputs__quoter-select-styles__select-text {
  appearance: none;
  line-height: 20px;
  width: 100%;
  border-color: transparent;
  font-family: Montserrat;
  -ms-appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  font-weight: 500;
  font-size: 13px;
}
.ladder_agents_dashboard_quote__start-app-panel-styles__title {
  margin-bottom: 10.0px;
}

.ladder_agents_dashboard_quote__start-app-panel-styles__copy {
  margin-bottom: 15.0px;
}
.ladder_gift_promo__illustrated-panel-styles__root {
  display: flex;
  width: 100%;
  min-height: 775px;
}

.ladder_gift_promo__illustrated-panel-styles__top-container {
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  max-width: 1400px;
  width: 90%;
}

.ladder_gift_promo__illustrated-panel-styles__illustration-left {
  flex-direction: row-reverse;
}

.ladder_gift_promo__illustrated-panel-styles__illustration-right {
  flex-direction: row;
}

.ladder_gift_promo__illustrated-panel-styles__illustration {
  display: block;
  margin: 60px;
  max-height: 50%;
  max-width: 40%;
  height: auto;
  width: auto;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_gift_promo__illustrated-panel-styles__root {
    min-height: 750px;
  }
  
  .ladder_gift_promo__illustrated-panel-styles__top-container {
    flex-direction: column;
    width: 100%;
    margin: 60px 0 0 0;
  }
  
  .ladder_gift_promo__illustrated-panel-styles__illustration {
    align-self: bottom;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_gift_promo__illustrated-panel-styles__root {
    min-height: 750px;
  }
  
  .ladder_gift_promo__illustrated-panel-styles__top-container {
    flex-direction: column;
    width: 100%;
    margin: 60px 0 0 0;
  }
  
  .ladder_gift_promo__illustrated-panel-styles__illustration {
    align-self: bottom;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_gift_promo__illustrated-panel-styles__root {
    min-height: 600px;
  }
  
  .ladder_gift_promo__illustrated-panel-styles__top-container {
    height: 100%;
  }
  
  .ladder_gift_promo__illustrated-panel-styles__illustration {
    max-width: 75%;
    max-height: 350px;
    margin: 30.0px auto;
  }

}
.ladder_trustage_common__see-more-options-styles__more-options {
  display: block;
  margin-top: 25.0px;
}
.ladder_agents_agent_assisted_app_views_offer__rungs-offer-styles__wrapper {
  width: 100%;
  color: black;
  background-color: #F7F2EF;
  display: flex;
  justify-content: center;
}

.ladder_agents_agent_assisted_app_views_offer__rungs-offer-styles__content-wrapper {
  max-width: 1400px;
  width: 70%;
  z-index: 2;
  display: flex;
  justify-content: space-between;
}

.ladder_agents_agent_assisted_app_views_offer__rungs-offer-styles__content {
  padding: 120px 0;
  width: 50%;
  display: flex;
  flex-direction: column;
}

.ladder_agents_agent_assisted_app_views_offer__rungs-offer-styles__card {
  width: 35%;
  margin: 20px 0;
  margin-bottom: 68px;
  z-index: 1;
}

.ladder_agents_agent_assisted_app_views_offer__rungs-offer-styles__best-price-got-it {
  text-align: center;
  margin-top: 2em;
}

.ladder_agents_agent_assisted_app_views_offer__rungs-offer-styles__confetti {
  position: absolute;
  top: 0;
  background-image: url(../img/confetti.png);
  background-size: contain;
  background-repeat: repeat-x;
  height: 200px;
  width: 100%;
}

.ladder_agents_agent_assisted_app_views_offer__rungs-offer-styles__underline {
  text-decoration: underline;
}

.ladder_agents_agent_assisted_app_views_offer__rungs-offer-styles__expiration {
  margin-top: 20px;
}

.ladder_agents_agent_assisted_app_views_offer__rungs-offer-styles__before-image {
  padding-bottom: 20px;
}

.ladder_agents_agent_assisted_app_views_offer__rungs-offer-styles__poca-overlay {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 14;
}

.ladder_agents_agent_assisted_app_views_offer__rungs-offer-styles__modal-z {
  z-index: 14;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_agent_assisted_app_views_offer__rungs-offer-styles__wrapper {
    flex-direction: column;
    background-size: 200%;
  }
  
  .ladder_agents_agent_assisted_app_views_offer__rungs-offer-styles__content-wrapper {
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    width: 100%;
  }
  
  .ladder_agents_agent_assisted_app_views_offer__rungs-offer-styles__content {
    width: 86%;
    padding-top: 0px;
    padding-bottom: 10px;
  }
  
  .ladder_agents_agent_assisted_app_views_offer__rungs-offer-styles__card {
    width: 86%;
    padding: 10px 0;
  }
  
  .ladder_agents_agent_assisted_app_views_offer__rungs-offer-styles__confetti {
    height: 80px;
    position: relative;
    background-size: cover;
  }
  
  .ladder_agents_agent_assisted_app_views_offer__rungs-offer-styles__confetti-split-offer {
    position: absolute;
    opacity: 0.5;
  }
  
  .ladder_agents_agent_assisted_app_views_offer__rungs-offer-styles__h1-split-offer {
    margin-top: 24px;
    z-index: 1;
  }
  
  .ladder_agents_agent_assisted_app_views_offer__rungs-offer-styles__card-split-offer {
    margin-top: 0px;
    margin-bottom: 20px;
    padding-top: 0px;
  }
  
  .ladder_agents_agent_assisted_app_views_offer__rungs-offer-styles__reach-out-card {
    width: 86%;
    align-self: center;
    margin-bottom: 24px;
  }
  
  .ladder_agents_agent_assisted_app_views_offer__rungs-offer-styles__gift-disclaimer {
    width: 86%;
    bottom: 20px;
  }

}
.ladder_onboarding_spouse_connect_2__center-styles__root {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  width: 100%;
}

.ladder_onboarding_spouse_connect_2__center-styles__root-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}
.ladder_trustage_common__legal-styles__legal {
  margin-top: 25.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_trustage_common__legal-styles__legal:last-child {
    margin-bottom: 60px;
  }

}
.ladder_onboarding_spouse_connect_2__clicked-maybe-later-styles__col {
  display: flex;
  flex-direction: column;
}
.ladder_nearly_perfect_quote_bundles__npq-bundles-tooltip-modal-ui-styles__buttons-container {
  margin-top: 40px;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

@media (max-width: 499px) {

  
  
  .ladder_nearly_perfect_quote_bundles__npq-bundles-tooltip-modal-ui-styles__buttons-container {
    gap: 10px;
  }

}

.ladder_nearly_perfect_quote_bundles__npq-bundles-tooltip-modal-ui-styles__back-button {
  border: solid 2px black;
}
.ladder_widget_ui_v2__family-history-checkbox-styles__root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.ladder_widget_ui_v2__family-history-checkbox-styles__root>*:last-child {
  flex: 0 0 60px;
  margin-left: 8px;
}
.ladder_views_decision_common__formatted-amendment-styles__amendment-line {
  padding: 10.0px 0;
}

.ladder_views_decision_common__formatted-amendment-styles__signable {
  text-align: left;
  width: 100%;
}
.ladder_adjust_offer__adjust-offer-inner-styles__root {
  text-align: center;
  margin-top: 30.0px;
  margin-bottom: 60px;
}

.ladder_adjust_offer__adjust-offer-inner-styles__header-subtitle {
  padding: 0 20px;
}

.ladder_adjust_offer__adjust-offer-inner-styles__tic-header-subtitle {
  box-sizing: border-box;
  max-width: 1100px;
  margin: 0 auto;
}

.ladder_adjust_offer__adjust-offer-inner-styles__tic-over-3m {
  width: 100%;
  max-width: 580px;
  margin: 0 auto;
}

.ladder_adjust_offer__adjust-offer-inner-styles__card {
  padding: 30.0px 20px;
  margin-bottom: 30.0px;
  width: 100%;
  max-width: 1100px;
  display: inline-block;
  box-sizing: border-box;
}

@media (max-width: 499px) {

  
  
  .ladder_adjust_offer__adjust-offer-inner-styles__button-wrapper {
    width: 90%;
    margin: 0 auto;
  }
  
  .ladder_adjust_offer__adjust-offer-inner-styles__tic-header-subtitle {
    padding: 0 20px;
  }

}
.ladder_careers_values__ui-value-tiles-styles__root {
  width: 100%;
  background-color: #EFEAE1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ladder_careers_values__ui-value-tiles-styles__constrain-size {
  height: 450px;
  width: 958px;
  max-width: 90%;
}

@media (max-width: 499px) {

  
  
  .ladder_careers_values__ui-value-tiles-styles__constrain-size {
    height: 300px;
  }

}
.ladder_widget_ui_v2__back-main-content-styles__root {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tile-a .ladder_widget_ui_v2__back-main-content-styles__root {
  flex-grow: 1;
}

.tile-b .ladder_widget_ui_v2__back-main-content-styles__root {
  flex-grow: 1;
}

.portrait .ladder_widget_ui_v2__back-main-content-styles__root {
  flex-grow: 461;
  padding: 0 15.0px 15.0px 15.0px;
}

.panel .ladder_widget_ui_v2__back-main-content-styles__root {
  flex-grow: 461;
  padding: 0 15.0px 15.0px 15.0px;
}

.landscape .ladder_widget_ui_v2__back-main-content-styles__root {
  flex-grow: 165;
}
.ladder_apply_current_page_components__allianz-footer-styles__root {
  width: 90%;
  margin: 0 auto 40px auto;
}
.ladder_financial_advisors_home__flexible-coverage-desktop-image-styles__\% {
  overflow: hidden;
  display: flex;
  justify-content: flex-end;
  flex: 0 0 50%;
  height: 100%;
}

.ladder_financial_advisors_home__flexible-coverage-desktop-image-styles__\%>img {
  height: 100%;
}
.ladder_form_traffic_diversion__root-styles__root {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: left;
  max-width: 1000px;
  margin-bottom: 60px;
}

@media (max-width: 499px) {

  
  
  .ladder_form_traffic_diversion__root-styles__root {
    padding: 12px;
  }

}

.ladder_form_traffic_diversion__root-styles__p {
  margin-bottom: 20px;
}

.ladder_form_traffic_diversion__root-styles__skip-to-billing {
  width: 100%;
  max-width: 600px;
  text-align: left;
}

.ladder_form_traffic_diversion__root-styles__spinner {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 282px;
}

.ladder_form_traffic_diversion__root-styles__title {
  text-align: left;
  margin-left: 5%;
  margin-top: -13px;
}
.ladder_agents_dashboard_quote__price-cell-styles__cell {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-radius: 4px;
}

.ladder_agents_dashboard_quote__price-cell-styles__cell:first-child {
  background-color: rgb(232,246,245);
}

.ladder_agents_dashboard_quote__price-cell-styles__label {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.ladder_onboarding_referrals__description-styles__container {
  margin-top: 40px;
  margin-bottom: 40px;
}

.ladder_onboarding_referrals__description-styles__text {
  font-size: 20px;
}

@media (max-width: 499px) {

  
  
  .ladder_onboarding_referrals__description-styles__container {
    margin-bottom: 20px;
  }

}
.ladder_careers__ui-benefit-styles__root {
  flex: 1 0 0;
}

@media (max-width: 499px) {

  
  
  .ladder_careers__ui-benefit-styles__root {
    padding: 20px 0;
  }

}
.ladder_account_next_views_beneficiaries_shares__sum-difference-rows-styles__rows {
  margin-top: 40px;
}

.ladder_account_next_views_beneficiaries_shares__sum-difference-rows-styles__row {
  display: flex;
  justify-content: space-between;
}

.ladder_account_next_views_beneficiaries_shares__sum-difference-rows-styles__row>*:last-child {
  text-align: right;
}

@media (max-width: 499px) {

  
  
  .ladder_account_next_views_beneficiaries_shares__sum-difference-rows-styles__row>*:first-child {
    max-width: 60%;
  }

}

.ladder_account_next_views_beneficiaries_shares__sum-difference-rows-styles__row:last-child {
  margin-top: 10.0px;
}
.ladder_ux_policy_card__policy-card-structure-styles__root {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  width: 100%;
}

.ladder_ux_policy_card__policy-card-structure-styles__root-bgcolor {
  width: 100%;
  background-color: #F4F4F4;
  box-sizing: border-box;
  padding: 16px 64px;
}

.ladder_ux_policy_card__policy-card-structure-styles__root-bgcolor-golden {
  background: linear-gradient(140deg, #F5E39D, #E4C66C);
  padding: 16px 64px;
}

.ladder_ux_policy_card__policy-card-structure-styles__full-width {
  max-width: initial;
}

.ladder_ux_policy_card__policy-card-structure-styles__vertical-padding-s {
  padding-top: 8.0px;
  padding-bottom: 8.0px;
}

.ladder_ux_policy_card__policy-card-structure-styles__vertical-padding-m {
  padding-top: 32px;
  padding-bottom: 32px;
}

.ladder_ux_policy_card__policy-card-structure-styles__vertical-padding-l {
  padding-top: 64px;
  padding-bottom: 64px;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_policy_card__policy-card-structure-styles__root-padding {
    padding: 8.0px 0;
  }
  
  .ladder_ux_policy_card__policy-card-structure-styles__root-bgcolor {
    padding: 16px 16px;
  }
  
  .ladder_ux_policy_card__policy-card-structure-styles__root-bgcolor-golden {
    padding: 16px 16px;
  }
  
  .ladder_ux_policy_card__policy-card-structure-styles__root-bgcolor-with-title {
    padding-top: 8.0px;
  }
  
  .ladder_ux_policy_card__policy-card-structure-styles__root-bgcolor-with-subtitle {
    padding-bottom: 8.0px;
  }
  
  .ladder_ux_policy_card__policy-card-structure-styles__vertical-padding-s {
    padding-top: 4.0px;
    padding-bottom: 4.0px;
  }
  
  .ladder_ux_policy_card__policy-card-structure-styles__vertical-padding-m {
    padding-top: 8.0px;
    padding-bottom: 8.0px;
  }
  
  .ladder_ux_policy_card__policy-card-structure-styles__vertical-padding-l {
    padding-top: 32px;
    padding-bottom: 32px;
  }

}
.ladder_gift_promo__faq-panel-styles__root {
  display: flex;
  height: auto;
  overflow: hidden;
  width: 100%;
  margin-bottom: 60px;
  justify-content: center;
  background-color: #FFFFFF;
}

.ladder_gift_promo__faq-panel-styles__top-container {
  display: flex;
  max-width: 1000px;
  justify-content: center;
  margin: 130.0px 0 60px;
  width: 90%;
}

.ladder_gift_promo__faq-panel-styles__title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.ladder_gift_promo__faq-panel-styles__faq-container {
  display: flex;
  width: 90%;
  max-width: 600px;
  flex-direction: column;
}

@media (max-width: 499px) {

  
  
  .ladder_gift_promo__faq-panel-styles__title {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .ladder_gift_promo__faq-panel-styles__link {
    margin-top: 10.0px;
  }
  
  .ladder_gift_promo__faq-panel-styles__top-container {
    margin: 60px 0 30.0px;
  }

}
.ladder_agents_dashboard_quote__pre-screener-styles__section {
  background-color: #FFFFFF;
  border-radius: 36px;
  box-sizing: border-box;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
}

.ladder_agents_dashboard_quote__pre-screener-styles__quoter-copy {
  margin: 10.0px 0 30.0px 0;
}

@media (min-width: 1025px) {

  
  
  .ladder_agents_dashboard_quote__pre-screener-styles__quoter-and-app-button {
    display: flex;
    align-items: flex-start;
  }
  
  .ladder_agents_dashboard_quote__pre-screener-styles__section {
    padding: 30px 40px;
    max-width: 834px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard_quote__pre-screener-styles__section {
    padding: 20px;
    margin-bottom: 10.0px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_dashboard_quote__pre-screener-styles__section {
    padding: 20px;
    margin-bottom: 10.0px;
  }

}
.ladder_ux_logos__dpl-logo-styles__icon {
  height: 44.8px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_logos__dpl-logo-styles__icon {
    height: 35.2px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_logos__dpl-logo-styles__icon {
    height: 25.6px;
  }

}
.ladder_gift_promo__partner-banner-styles__root {
  background-color: #975C46;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 160px;
}

.ladder_gift_promo__partner-banner-styles__heading {
  margin: 30.0px 0 15.0px;
  text-align: center;
}

@media (max-width: 499px) {

  
  
  .ladder_gift_promo__partner-banner-styles__root {
    min-height: 100px;
  }
  
  .ladder_gift_promo__partner-banner-styles__heading {
    margin: 10.0px 10.0px 0;
  }

}
.ladder_views_next__impersonation-banner-styles__banner {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  background: #FE4747;
  justify-content: center;
}
.ladder_account_shared_components__payment-method-info-styles__icon {
  width: 45px;
  height: 35px;
}

.ladder_account_shared_components__payment-method-info-styles__bank-icon {
  margin: 0 20px -10.0px 0;
  width: 35px;
  height: 35px;
}

@media (max-width: 499px) {

  
  
  .ladder_account_shared_components__payment-method-info-styles__icon {
    width: 40px;
    height: 30px;
  }
  
  .ladder_account_shared_components__payment-method-info-styles__bank-icon {
    margin-right: 10.0px;
    width: 30px;
    height: 30px;
  }

}
.ladder_account_next_views_policy__ViewedPolicyMicroSummary-styles__\% {
  padding-top: 16px;
}
.ladder_agents_agent_assisted_app_views_review__expandable-review-wrapper-styles__wrapper {
  border: solid 1px black;
}

.ladder_agents_agent_assisted_app_views_review__expandable-review-wrapper-styles__expander {
  border-top: solid 1px black;
  padding: 10px;
  display: flex;
  justify-content: center;
}

@media (min-width: 1025px) {

  
  
  .ladder_agents_agent_assisted_app_views_review__expandable-review-wrapper-styles__content {
    max-height: 160px;
    overflow: scroll;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_agents_agent_assisted_app_views_review__expandable-review-wrapper-styles__wrapper {
    margin: 0 10px 10px 10px;
  }

}
.ladder_laddering_page__responsive-component-styles__if-desktop {
  display: flex;
}

.ladder_laddering_page__responsive-component-styles__if-tablet {
  display: flex;
}

.ladder_laddering_page__responsive-component-styles__if-mobile {
  display: flex;
}

@media (max-width: 499px) {

  
  
  .ladder_laddering_page__responsive-component-styles__if-mobile {
    display: flex;
  }
  
  .ladder_laddering_page__responsive-component-styles__if-desktop {
    display: none;
  }
  
  .ladder_laddering_page__responsive-component-styles__if-tablet {
    display: none;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_laddering_page__responsive-component-styles__if-mobile {
    display: none;
  }
  
  .ladder_laddering_page__responsive-component-styles__if-desktop {
    display: flex;
  }
  
  .ladder_laddering_page__responsive-component-styles__if-tablet {
    display: none;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_laddering_page__responsive-component-styles__if-mobile {
    display: none;
  }
  
  .ladder_laddering_page__responsive-component-styles__if-desktop {
    display: none;
  }
  
  .ladder_laddering_page__responsive-component-styles__if-tablet {
    display: flex;
  }

}
.ladder_ux_trustpilot__trustpilot-divider-black-styles__root {
  margin-left: 10px;
  margin-right: 5px;
  display: flex;
  border-color: black;
  border-width: 1px;
  border-style: solid;
  opacity: 0.5;
}
.ladder_account_shared_components__section-tabs-styles__\% {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  margin-top: 0;
}

.ladder_account_shared_components__section-tabs-styles__spacer {
  padding: 0 19px 10px;
}

.ladder_account_shared_components__section-tabs-styles__agents-spacer {
  padding: 0 5px 10px;
}

.ladder_account_shared_components__section-tabs-styles__spacer:last-of-type {
  flex-grow: 1;
  padding: 0 0 10px 0;
}

.ladder_account_shared_components__section-tabs-styles__agents-dashboard {
  width: 100%;
  justify-content: flex-start;
}

@media (max-width: 499px) {

  
  
  .ladder_account_shared_components__section-tabs-styles__position-relative {
    position: relative;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }
  
  .ladder_account_shared_components__section-tabs-styles__scroll {
    padding: 0 10%;
    overflow-x: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
  }
  
  .ladder_account_shared_components__section-tabs-styles__scroll::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
  }

}
.ladder_ny_form_reg60__root-styles__root {
  padding-bottom: 5.0px;
  margin-top: 10.0px;
}

.ladder_ny_form_reg60__root-styles__error-message {
  color: #FE4747;
}
.ladder_form_nearly_perfect_quote__root-styles__dropdown {
  width: 60%;
  margin: 10.0px 0;
}

.ladder_form_nearly_perfect_quote__root-styles__slider {
  margin: 10.0px 0 22.5px;
}

.ladder_form_nearly_perfect_quote__root-styles__full-width {
  width: 100%;
}

.ladder_form_nearly_perfect_quote__root-styles__estimated-price-title {
  display: flex;
  flex-direction: row;
  margin-bottom: 10.0px;
}

.ladder_form_nearly_perfect_quote__root-styles__estimated-price-title > *:not(:last-child) {
  margin-right: 10.0px;
}

.ladder_form_nearly_perfect_quote__root-styles__q-container {
  width: 60%;
  text-align: center;
}

.ladder_form_nearly_perfect_quote__root-styles__tel {
  display: inline-block;
}

.ladder_form_nearly_perfect_quote__root-styles__spinner {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 282px;
}

@media (max-width: 499px) {

  
  
  .ladder_form_nearly_perfect_quote__root-styles__wrapper {
    padding-bottom: 22.5px;
  }
  
  .ladder_form_nearly_perfect_quote__root-styles__dropdown {
    width: 100%;
  }
  
  .ladder_form_nearly_perfect_quote__root-styles__q-container {
    width: 100%;
  }

}

.ladder_form_nearly_perfect_quote__root-styles__recommended-button {
  padding: 10px 5px;
  margin: 0 5px;
  border: solid, 1px, #7A8A8C;
  border-radius: 9px;
  white-space: nowrap;
  cursor: pointer;
}

.ladder_form_nearly_perfect_quote__root-styles__recommended-button:hover {
  background-color: #E7EEEF;
}

.ladder_form_nearly_perfect_quote__root-styles__recommended-button>div {
  font-size: 11px;
}

.ladder_form_nearly_perfect_quote__root-styles__recommended-button-selected {
  background-color: #E7EEEF;
  cursor: default;
  pointer-events: none;
}
.ladder_ux_single_selection_radio__single-selection-radio-styles__root {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  padding: 16px 0 0;
}

.ladder_ux_single_selection_radio__single-selection-radio-styles__root.ladder_ux_single_selection_radio__single-selection-radio-styles__left-align {
  align-items: start;
}

.ladder_ux_single_selection_radio__single-selection-radio-styles__root.ladder_ux_single_selection_radio__single-selection-radio-styles__rungs {
  width: 100%;
}

.ladder_ux_single_selection_radio__single-selection-radio-styles__padding-xs {
  padding: 4.0px 0 0;
}

.ladder_ux_single_selection_radio__single-selection-radio-styles__options-wrapper {
  display: flex;
  flex-direction: column;
}

.ladder_ux_single_selection_radio__single-selection-radio-styles__full-width {
  width: 100%;
}

.ladder_ux_single_selection_radio__single-selection-radio-styles__option {
  display: flex;
  align-items: center;
  padding: 8.0px 0;
}

.ladder_ux_single_selection_radio__single-selection-radio-styles__dot-container {
  padding-right: 8.0px;
}

.ladder_ux_single_selection_radio__single-selection-radio-styles__radio {
  display: none;
}

.ladder_ux_single_selection_radio__single-selection-radio-styles__radio-dot {
  width: 24.0px;
  top: 0px;
  height: 24.0px;
  border: 1px solid #707070;
  position: relative;
  background-color: #FFFFFF;
  border-radius: 50%;
  box-sizing: content-box;
  display: block;
  left: 0px;
}

.ladder_ux_single_selection_radio__single-selection-radio-styles__radio-selected {

}

.ladder_ux_single_selection_radio__single-selection-radio-styles__radio-dot-inner {
  display: block;
  position: relative;
  top: 3.0px;
  left: 3.0px;
  width: 18.0px;
  height: 18.0px;
  border-radius: 50%;
  background: #CF967E;
}

@media (min-width: 1025px) {

  
  
  
  
  .ladder_ux_single_selection_radio__single-selection-radio-styles__options-wrapper.ladder_ux_single_selection_radio__single-selection-radio-styles__options-wrapper-inline {
    flex-direction: row;
  }
  
  .ladder_ux_single_selection_radio__single-selection-radio-styles__options-wrapper.ladder_ux_single_selection_radio__single-selection-radio-styles__options-wrapper-inline > *:not(:last-child) {
    margin-right: 16px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  
  
  .ladder_ux_single_selection_radio__single-selection-radio-styles__options-wrapper.ladder_ux_single_selection_radio__single-selection-radio-styles__options-wrapper-inline {
    flex-direction: row;
  }
  
  .ladder_ux_single_selection_radio__single-selection-radio-styles__options-wrapper.ladder_ux_single_selection_radio__single-selection-radio-styles__options-wrapper-inline > *:not(:last-child) {
    margin-right: 16px;
  }

}

.ladder_ux_single_selection_radio__single-selection-radio-styles__rungs > *:not(:last-child) {
  margin-bottom: 10px;
}
.ladder_apply_ny_current_page__second-column-styles__root {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  width: 30%;
  background-color: #F7F2EF;
}

.ladder_apply_ny_current_page__second-column-styles__wrapper {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ladder_not_found__main-panel-styles__root {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ladder_not_found__main-panel-styles__tire-img {
  max-width: 33%;
  height: auto;
  margin: 30.0px auto;
}

.ladder_not_found__main-panel-styles__gallery {
  width: 33%;
}
.ladder_landing_pages_so_good_rewatch__rewatch-styles__rewatch-root {
  width: 100%;
}

.ladder_landing_pages_so_good_rewatch__rewatch-styles__rewatch-middle {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #1A1A1A;
  padding: 18px 0px;
}

.ladder_landing_pages_so_good_rewatch__rewatch-styles__rewatch-container {
  padding: 36px 0px;
}
.ladder_beneficiary_form__inform-bene-label-styles__label {
  line-height: normal;
}
.ladder_account_next_views_secondary_addressee_edit__edit-secondary-addressee-buttons-styles__buttons {
  margin-top: 50.0px;
  display: flex;
}

@media (max-width: 499px) {

  
  
  .ladder_account_next_views_secondary_addressee_edit__edit-secondary-addressee-buttons-styles__buttons {
    margin-top: 15.0px;
  }

}

.ladder_account_next_views_secondary_addressee_edit__edit-secondary-addressee-buttons-styles__buttons>*:first-child {
  margin-right: 22.5px;
}

.ladder_account_next_views_secondary_addressee_edit__edit-secondary-addressee-buttons-styles__save-button {
  max-width: 101px;
}

.ladder_account_next_views_secondary_addressee_edit__edit-secondary-addressee-buttons-styles__cancel-button {
  max-width: 117px;
}
.ladder_account_next_views_gift__winner-image-styles__root {
  max-height: 400px;
}

.ladder_account_next_views_gift__winner-image-styles__root>img {
  max-height: 400px;
}
.ladder_views_api__two-col-panel-styles__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.ladder_views_api__two-col-panel-styles__column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
}

.ladder_views_api__two-col-panel-styles__right {
  align-items: center;
}

.ladder_views_api__two-col-panel-styles__ltr {
  flex-direction: row;
}

.ladder_views_api__two-col-panel-styles__rtl {
  flex-direction: row-reverse;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_views_api__two-col-panel-styles__container {
    gap: 30.0px;
  }
  
  .ladder_views_api__two-col-panel-styles__stack-tablet {
    flex-direction: column;
  }
  
  .ladder_views_api__two-col-panel-styles__stack-tablet > div {
    width: 100%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_views_api__two-col-panel-styles__column {
    align-items: center;
  }
  
  .ladder_views_api__two-col-panel-styles__left {
    margin-bottom: 20px;
  }
  
  .ladder_views_api__two-col-panel-styles__container {
    flex-direction: column;
  }
  
  .ladder_views_api__two-col-panel-styles__container > div {
    width: 100%;
  }

}
.ladder_savings_common__steps-price-comparison-styles__price-common {
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  padding: 10.0px 15.0px;
}

.ladder_savings_common__steps-price-comparison-styles__current-price {
  border: 1px solid  #D9D9D9;
}

.ladder_savings_common__steps-price-comparison-styles__discounted-price {
  background-color: #DBE6DB;
}
.ladder_form_quick_start__diagnosed-family-member-styles__capitalize {
  text-transform: capitalize;
}

.ladder_form_quick_start__diagnosed-family-member-styles__row {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
}

.ladder_form_quick_start__diagnosed-family-member-styles__row > *:not(:last-child) {
  margin-right: 20px;
}

.ladder_form_quick_start__diagnosed-family-member-styles__row>div {
  width: 100%;
}

@media (max-width: 499px) {

  
  
  .ladder_form_quick_start__diagnosed-family-member-styles__row {
    flex-direction: column;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_form_quick_start__diagnosed-family-member-styles__row {
    height: 80px;
  }

}
.ladder_admin_widget__teleport-styles__spacer {
  margin: 30.0px 0;
  border-top: 1px solid white;
}

.ladder_admin_widget__teleport-styles__root {
  background-color: #FFFFFF;
  margin: 30.0px 0;
  padding: 30.0px;
}

.ladder_admin_widget__teleport-styles__content {
  display: flex;
  flex-direction: row;
}

.ladder_admin_widget__teleport-styles__options-outer-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.ladder_admin_widget__teleport-styles__options-column {
  display: flex;
  flex-grow: 1;
  margin: 0 20px;
  align-items: flex-start;
}

.ladder_admin_widget__teleport-styles__buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ladder_admin_widget__teleport-styles__buttons > * {
  padding: 20px 0;
}

@media (max-width: 499px) {

  
  
  .ladder_admin_widget__teleport-styles__content {
    flex-direction: column;
  }
  
  .ladder_admin_widget__teleport-styles__options-outer-container {
    flex-direction: column;
  }

}
.ladder_form_singleselection__checkbox-boolean-styles__option {
  display: flex;
  width: 100%;
  padding: 10.0px 0;
}
.ladder_careers__ui-tetraptych-styles__root {
  display: flex;
  flex-direction: column;
  line-height: 0px;
}

.ladder_careers__ui-tetraptych-styles__row {
  display: flex;
  flex-direction: row;
}

.ladder_careers__ui-tetraptych-styles__scale {
  max-width: 301px;
  max-height: 274px;
}

.ladder_careers__ui-tetraptych-styles__pad-right {
  margin-right: 30px;
}

.ladder_careers__ui-tetraptych-styles__pad-below {
  margin-bottom: 30px;
}

@media (max-width: 499px) {

  
  
  .ladder_careers__ui-tetraptych-styles__scale {
    max-width: 150.5px;
    max-height: 137px;
  }
  
  .ladder_careers__ui-tetraptych-styles__pad-right {
    margin-right: 15px;
  }
  
  .ladder_careers__ui-tetraptych-styles__pad-below {
    margin-bottom: 15px;
  }

}
.ladder_apply_core__root-styles__root {
  display: flex;
  flex-direction: column;
}

.ladder_apply_core__root-styles__sticky {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: sticky;
  top: 100px;
  z-index: 9;
}

.ladder_apply_core__root-styles__embedded {
  display: flex;
  flex: 1;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_apply_core__root-styles__sticky {
    top: 60px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_apply_core__root-styles__sticky {
    top: 60px;
  }

}
@media (min-width: 1025px) {

  
  
  .ladder_agents_dashboard__settings-profile-page-styles__container {
    width: 100%;
    max-width: 40em;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .ladder_agents_dashboard__settings-profile-page-styles__input-wrapper {
    display: flex;
    align-items: center;
    margin-top: 1em;
    padding: 1em;
    width: 100%;
    height: 70px;
    border: 1px solid #ccc;
    border-radius: 24px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard__settings-profile-page-styles__container {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .ladder_agents_dashboard__settings-profile-page-styles__input-wrapper {
    display: flex;
    align-items: center;
    margin-top: 1em;
    padding: 1em;
    width: 100%;
    height: 70px;
    border: 1px solid #ccc;
    border-radius: 24px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_dashboard__settings-profile-page-styles__container {
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .ladder_agents_dashboard__settings-profile-page-styles__input-wrapper {
    display: flex;
    align-items: center;
    margin-top: 1em;
    padding: 1em;
    width: 100%;
    height: 70px;
    border: 1px solid #ccc;
    border-radius: 24px;
  }

}

.ladder_agents_dashboard__settings-profile-page-styles__input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  background-color: transparent;
  padding: 5px 10px;
}

.ladder_agents_dashboard__settings-profile-page-styles__input-label {
  color: grey;
  font-size: 12px;
  padding: 1px 2px;
  margin-bottom: 1em;
}

.ladder_agents_dashboard__settings-profile-page-styles__icon {
  margin-right: 10px;
}

.ladder_agents_dashboard__settings-profile-page-styles__input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ladder_agents_dashboard__settings-profile-page-styles__input {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ladder_agents_dashboard__settings-profile-page-styles__save-changes-button {
  border-radius: 24px;
  margin-top: 2em;
  margin-bottom: 2em;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.ladder_agents_dashboard__settings-profile-page-styles__medium-font-size {
  font-size: 13px;
  font-weight: 500;
}

.ladder_agents_dashboard__settings-profile-page-styles__agency-name-style {
  font-size: 13px;
  font-weight: 500;
  font-family: Montserrat, sans-serif;
  cursor: not-allowed;
}

.ladder_agents_dashboard__settings-profile-page-styles__updated-email-reminder-text {
  margin-top: 2em;
  margin-bottom: 2em;
}

.ladder_agents_dashboard__settings-profile-page-styles__share-contact-slider-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.ladder_agents_dashboard__settings-profile-page-styles__white-input-background {
  background-color: white;
}

.ladder_agents_dashboard__settings-profile-page-styles__share-phone-number-type {
  margin: 1em 0em;
}
.ladder_nearly_perfect_quote_common__affordability-mode-selector-styles__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ladder_nearly_perfect_quote_common__affordability-mode-selector-styles__caption {
  margin-top: 20px;
  cursor: pointer;
}

.ladder_nearly_perfect_quote_common__affordability-mode-selector-styles__divider {
  height: 1px;
  margin-top: 30.0px;
  background: repeating-linear-gradient(to right, #BDBDBD 0, #BDBDBD 10px, transparent 10px, transparent 20px);
  width: 100%;
}
.ladder_ux_link__link-styles__root {
  cursor: pointer;
}

.ladder_ux_link__link-styles__theme-hover:hover {
  color: #4C4C4E;
}

.ladder_ux_link__link-styles__black-bold-hover:hover {
  color: #000000;
  weight: bold;
}

.ladder_ux_link__link-styles__rungs-hover:hover {
  color: #CF967E;
}

.ladder_ux_link__link-styles__rungs-hover.ladder_ux_link__link-styles__hover-scorpion:hover {
  color: #5D5D5D;
}

.ladder_ux_link__link-styles__rungs-hover.ladder_ux_link__link-styles__hover-phoenix:hover {
  color: #975C46;
}

.ladder_ux_link__link-styles__underline {
  text-decoration: underline;
}

.ladder_ux_link__link-styles__hover-underline {
  position: relative;
}

@media (min-width: 1025px) {

  
  
  .ladder_ux_link__link-styles__hover-underline:after {
    width: 0%;
    max-width: 192px;
    height: 1px;
    transform: translateX(-50%);
    position: absolute;
    content: "";
    background-color: #975C46;
    bottom: -2px;
    transition: width 150ms linear;
    left: 50%;
  }
  
  .ladder_ux_link__link-styles__hover-underline:hover:after {
    width: 65%;
  }
  
  .ladder_ux_link__link-styles__hover-underline:focus:after {
    width: 65%;
  }
  
  .ladder_ux_link__link-styles__rungs-underline:hover {
    text-decoration: underline;
  }

}

.ladder_ux_link__link-styles__dashed-underline {
  border-bottom: dashed 1px;
}
.ladder_account__RungsBillingCard-styles__double-column {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.ladder_account__RungsBillingCard-styles__title {
  margin-left: 5.0px;
  margin-bottom: 30.0px;
}

.ladder_account__RungsBillingCard-styles__fix-collapsed-top-margin {
  margin-top: 20px;
}

@media (max-width: 499px) {

  
  
  .ladder_account__RungsBillingCard-styles__double-column {
    flex-direction: column;
  }
  
  .ladder_account__RungsBillingCard-styles__double-column >div:first-child {
    margin-top: 20px;
  }

}
.ladder_ux_modal__two-col-modal-inner-styles__wrapper {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  max-height: 65%;
  position: relative;
  width: 100%;
}

.ladder_ux_modal__two-col-modal-inner-styles__section {
  padding: 25px;
}

.ladder_ux_modal__two-col-modal-inner-styles__header {
  border-bottom: #F4F4F4 solid 1px;
  padding-bottom: 22.5px;
  padding-right: 45px;
  padding-top: 22.5px;
}

.ladder_ux_modal__two-col-modal-inner-styles__header .ladder_ux_modal__two-col-modal-inner-styles__header-text {
  line-height: normal;
  color: black;
}

.ladder_ux_modal__two-col-modal-inner-styles__body {
  padding-top: 10px;
  flex-grow: 1;
  overflow: auto;
  white-space: pre-wrap;
}

.ladder_ux_modal__two-col-modal-inner-styles__remove-button-container {
  width: 30px;
  right: 15px;
  top: 15px;
  height: 30px;
  padding: 10px;
  position: absolute;
  cursor: pointer;
  box-shadow: 0px 2.5px 5px rgba(51, 46, 84, 0.06);
  background-color: #FFFFFF;
  border-radius: 50%;
}

.ladder_ux_modal__two-col-modal-inner-styles__icon {
  position: absolute;
  height: 15px;
  width: 15px;
  left: 8px;
  bottom: 6px;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_modal__two-col-modal-inner-styles__wrapper {
    width: 100%;
    height: 100%;
    max-height: unset;
  }
  
  .ladder_ux_modal__two-col-modal-inner-styles__body {
    display: flex;
    flex-direction: column;
  }

}
.ladder_ny_form_single_line__root-styles__root {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.ladder_ny_form_single_line__root-styles__elem {
  flex-grow: 1;
}

.ladder_ny_form_single_line__root-styles__spacer {
  flex-grow: 0.1;
}
.ladder_markdown_components__bold-underline-styles__root {
  text-decoration: underline;
  font-weight: bold;
}
.ladder_onboarding_referrals__onboarding-number-styles__root {
  font-size: 30.0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  width: 100%;
}

.ladder_onboarding_referrals__onboarding-number-styles__text {
  color: white;
  font-size: 30.0px;
  font-weight: 600;
}

.ladder_onboarding_referrals__onboarding-number-styles__number-container {
  display: flex;
  text-align: center;
}

@media (max-width: 499px) {

  
  
  .ladder_onboarding_referrals__onboarding-number-styles__text {
    color: #7A8A8C;
  }

}
.ladder_app_review__accelerated-benefit-rider-disclosure-styles__container {
  border: 1px solid black;
  padding: 24px 40px;
  max-height: 287px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media (max-width: 499px) {

  
  
  .ladder_app_review__accelerated-benefit-rider-disclosure-styles__container {
    padding: 16px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_app_review__accelerated-benefit-rider-disclosure-styles__container {
    padding: 16px;
  }

}
.ladder_agents_home__trustpilot-brief-styles__wrapper {
  width: 100%;
  background-color: #EFEAE1;
}

.ladder_agents_home__trustpilot-brief-styles__trustpilot-image {
  height: 30px;
}
.ladder_account_next_views_gift__gift-selection-styles__root {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
}

.ladder_account_next_views_gift__gift-selection-styles__next-button {
  margin: 0 16px 16px auto;
}

@media (max-width: 499px) {

  
  
  .ladder_account_next_views_gift__gift-selection-styles__root {
    padding-right: 8.0px;
  }
  
  .ladder_account_next_views_gift__gift-selection-styles__next-button {
    margin: 16px 16px;
  }

}
.ladder_agents_dashboard_quote__start-app-button-styles__shadow {
  filter: drop-shadow(0 3px 6px rgba(37, 112, 170, 0.25));
}
.ladder_nearly_perfect_quote_common__npq-fields-panel-styles__coverage-field-container {
  border: 2px solid #BDBDBD;
  border-radius: 8px;
  padding: 30.0px 30.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_nearly_perfect_quote_common__npq-fields-panel-styles__coverage-field-container {
    padding: 10.0px 10.0px;
  }

}
.ladder_account_next_views_policy__ModifyCoverageButtonsWrapper-styles__\% {
  display: flex;
  justify-content: center;
}
.ladder_financial_advisors_laddering__get-started-text-styles__\% {
  position: relative;
  z-index: 2;
}
.ladder_account_next_views_gift__choose-color-ui-styles__next-button {
  margin: 0 16px 16px auto;
}

@media (max-width: 499px) {

  
  
  .ladder_account_next_views_gift__choose-color-ui-styles__next-button {
    margin: 16px 16px;
  }

}
.ladder_ccpa_form__ccpa-form-styles__option {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.ladder_ccpa_form__ccpa-form-styles__margin-bottom {
  margin-bottom: 20px;
}

.ladder_ccpa_form__ccpa-form-styles__form {
  max-width: 500px;
}

.ladder_ccpa_form__ccpa-form-styles__upload-container {
  display: flex;
  flex-direction: column;
}
.ladder_onboarding_significant_other_connections__clicked-maybe-later-styles__wrapper {
  display: flex;
  flex-direction: column;
}

.ladder_onboarding_significant_other_connections__clicked-maybe-later-styles__row {
  display: flex;
  flex-direction: row;
}

@media (max-width: 499px) {

  
  
  .ladder_onboarding_significant_other_connections__clicked-maybe-later-styles__row {
    display: flex;
    flex-direction: column;
  }

}
.ladder_agents_agent_assisted_app_views_decision__manual-underwriting-page-styles__container {
  display: flex;
  gap: 100px;
  width: 100%;
  max-width: 1038px;
  margin: 0 auto;
  padding: 66px 0 80px 0;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_agent_assisted_app_views_decision__manual-underwriting-page-styles__container {
    flex-direction: column;
    padding: 30px 0;
    width: 90%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_agent_assisted_app_views_decision__manual-underwriting-page-styles__container {
    flex-direction: column;
    padding: 30px 0;
    width: 90%;
  }

}
.ladder_form_weight__Root-styles__input {
  width: 100px;
}

@media (max-width: 499px) {

  
  
  .ladder_form_weight__Root-styles__input {
    width: 50px;
  }

}
.ladder_onboarding_beneficiaries__EditBeneficiary-styles__top-row {
  width: 100%;
  justify-content: space-between;
  display: flex;
}
.ladder_ux_button__rungs-box-styles__root {
  min-width: 192px;
  justify-content: center;
  font-family: Montserrat, sans-serif;
  height: 48px;
  user-select: none;
  align-items: center;
  padding: 0 32px;
  outline: none;
  position: relative;
  min-height: 40.0px;
  cursor: pointer;
  font-weight: 700;
  text-decoration: none;
  font-size: 16px;
  box-sizing: border-box;
  display: inline-flex;
}

.ladder_ux_button__rungs-box-styles__wide-content {
  padding: 0 8.0px;
  justify-content: space-around;
}

.ladder_ux_button__rungs-box-styles__min-width-s {
  min-width: 32px;
}

.ladder_ux_button__rungs-box-styles__min-width-m {
  min-width: 64px;
}

.ladder_ux_button__rungs-box-styles__min-width-l {
  min-width: 135px;
}

.ladder_ux_button__rungs-box-styles__min-width-none {
  min-width: unset;
}

.ladder_ux_button__rungs-box-styles__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.ladder_ux_button__rungs-box-styles__full-width {
  width: 100%;
  min-width: 0;
}

.ladder_ux_button__rungs-box-styles__full-height {
  height: 100%;
}

.ladder_ux_button__rungs-box-styles__height-s {
  height: 40px;
}

.ladder_ux_button__rungs-box-styles__height-m {
  height: 64px;
}

.ladder_ux_button__rungs-box-styles__height-l {
  height: 96px;
}

.ladder_ux_button__rungs-box-styles__rounded-corners {
  border-radius: 25px;
}

.ladder_ux_button__rungs-box-styles__less-rounded-corners {
  border-radius: 10px;
}

.ladder_ux_button__rungs-box-styles__lowercase {
  text-transform: lowercase;
}

.ladder_ux_button__rungs-box-styles__uppercase {
  text-transform: uppercase;
}

.ladder_ux_button__rungs-box-styles__shrink-on-click {
  transition: all 150ms ease-out;
}

.ladder_ux_button__rungs-box-styles__shrink-on-click:active {
  transform: scale(0.9);
}

@media (hover: hover) {

  
  
  .ladder_ux_button__rungs-box-styles__lighten-on-hover {
    box-shadow: inset 5em 5em transparent;
    transition: box-shadow 0.3s, opacity 0.3s;
  }
  
  .ladder_ux_button__rungs-box-styles__lighten-on-hover:hover {
    box-shadow: inset 5em 5em rgba(255,255,255,0.3);
  }
  
  .ladder_ux_button__rungs-box-styles__fade-on-hover {
    transition: opacity 0.3s;
  }
  
  .ladder_ux_button__rungs-box-styles__fade-on-hover:hover {
    opacity: 0.6;
  }

}

.ladder_ux_button__rungs-box-styles__icon-wrapper {
  transition: transform 150ms ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 25px;
}

.ladder_ux_button__rungs-box-styles__icon {
  width: 100%;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_button__rungs-box-styles__root {
    padding: 0 16px;
  }
  
  .ladder_ux_button__rungs-box-styles__wide-content {
    padding: 0 4.0px;
  }
  
  .ladder_ux_button__rungs-box-styles__dynamic-height {
    height: 40.0px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_button__rungs-box-styles__root {
    padding: 0 16px;
  }
  
  .ladder_ux_button__rungs-box-styles__mobile-full-width {
    width: 100%;
  }
  
  .ladder_ux_button__rungs-box-styles__wide-content {
    padding: 0 4.0px;
  }
  
  .ladder_ux_button__rungs-box-styles__dynamic-height {
    height: 32px;
  }

}

.ladder_ux_button__rungs-box-styles__loading {
  background-color: #D0D0D0;
  cursor: default;
  color: #FFFFFF;
  border: none;
}

.ladder_ux_button__rungs-box-styles__disabled {
  background-color: #707070;
  cursor: not-allowed;
  color: #FFFFFF;
}

.ladder_ux_button__rungs-box-styles__button-label::first-letter {
  text-transform: uppercase;
}

.ladder_ux_button__rungs-box-styles__homepage-cta.ladder_ux_button__rungs-box-styles__root {
  border-radius: 5px;
  font-size: 20px;
  font-weight: 650;
  line-height: 30px;
}

.ladder_ux_button__rungs-box-styles__transparent {
  border-width: 1px;
  border-style: solid;
}
.ladder_view_schedule__hiv-consent-statement-styles__outer-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ladder_view_schedule__hiv-consent-statement-styles__checkbox-text {
  text-align: left;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ladder_view_schedule__hiv-consent-statement-styles__checkbox-container {
  padding: 10.0px 22.5px 10.0px 10.0px;
}
.ladder_landing_pages_so_good_core__explain-like-im-five-styles__root {
  width: 100%;
  background-color: #F7F2EF;
  display: flex;
  justify-content: center;
}

.ladder_landing_pages_so_good_core__explain-like-im-five-styles__explain-like-im-five-container {
  padding: 220px 0px;
}

.ladder_landing_pages_so_good_core__explain-like-im-five-styles__subtext {
  padding-top: 16px;
}

.ladder_landing_pages_so_good_core__explain-like-im-five-styles__fw-800 {
  font-weight: 800;
}

@media (max-width: 499px) {

  
  
  .ladder_landing_pages_so_good_core__explain-like-im-five-styles__explain-like-im-five-root {
    padding: 64px 0px;
  }
  
  .ladder_landing_pages_so_good_core__explain-like-im-five-styles__subtext {
    padding-top: 8px;
  }

}
.ladder_markdoc_common__image-comp-styles__img {
  width: 100%;
}
.ladder_careers__ui-activity-card-styles__root {
  max-width: 1100px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  flex-direction: row;
  margin: 0px;
  text-align: left;
}

.ladder_careers__ui-activity-card-styles__image {
  height: 448px;
  width: auto;
  margin-left: auto;
}

.ladder_careers__ui-activity-card-styles__text {
  margin-left: 50.0px;
  margin-right: auto;
  text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -webkit-text-size-adjust: none;
}

@media (max-width: 499px) {

  
  
  .ladder_careers__ui-activity-card-styles__root {
    width: 100%;
    text-align: left;
  }
  
  .ladder_careers__ui-activity-card-styles__text {
    margin: 30.0px 15.0px 0 15.0px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_careers__ui-activity-card-styles__root {
    width: 100%;
    text-align: left;
  }
  
  .ladder_careers__ui-activity-card-styles__text {
    margin: 30.0px 15.0px 0 15.0px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_careers__ui-activity-card-styles__image {
    height: 330px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_careers__ui-activity-card-styles__root {
    flex-direction: column;
  }
  
  .ladder_careers__ui-activity-card-styles__image {
    height: 350px;
    margin-right: auto;
  }

}
.ladder_form_quick_start__inline-section-styles__root {
  display: flex;
  align-items: center;
  padding: 5.0px 0;
}

@media (max-width: 499px) {

  
  
  .ladder_form_quick_start__inline-section-styles__root {
    padding: 5.0px 0;
  }

}
.ladder_claims_naic_redirect__farewell-styles__root {
  padding-bottom: 60px;
  background-color: #F7F2EF;
}

.ladder_claims_naic_redirect__farewell-styles__header {
  background-color: #F7F2EF;
  width: 100%;
}

.ladder_claims_naic_redirect__farewell-styles__copy {
  max-width: 680px;
}

@media (max-width: 499px) {

  
  
  .ladder_claims_naic_redirect__farewell-styles__content {
    padding-top: 40px;
  }

}
.ladder_ux_checkbox__checkmark-styles__check-container {
  position: relative;
  transform: translate(0px, -2px) rotate(45deg);
  width: 8px;
  height: 14px;
}

.ladder_ux_checkbox__checkmark-styles__check-container-relative {
  margin-bottom: 10%;
  transform: rotate(45deg);
  width: 40%;
  height: 70%;
}

.ladder_ux_checkbox__checkmark-styles__check-left {
  position: absolute;
  background-color: #FFFFFF;
  width: 100%;
  height: 3px;
  bottom: 0;
  right: 0;
}

.ladder_ux_checkbox__checkmark-styles__check-left-relative {
  height: 20%;
  max-height: 3px;
}

.ladder_ux_checkbox__checkmark-styles__check-right {
  position: absolute;
  background-color: #FFFFFF;
  height: 100%;
  width: 3px;
  top: 0;
  right: 0;
}

.ladder_ux_checkbox__checkmark-styles__check-right-relative {
  width: 35%;
  max-width: 3px;
}

.ladder_ux_checkbox__checkmark-styles__check-container-box-shadow {
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.ladder_ux_checkbox__checkmark-styles__my-pink {
  background-color: #CF967E;
}

.ladder_ux_checkbox__checkmark-styles__blue {
  background-color: #336FED;
}

.ladder_ux_checkbox__checkmark-styles__red {
  background-color: #FE4747;
}
.ladder_account_next_cards_notification_banners__Root-styles__\% {

}
.ladder_ny_form_google_autocomplete__google-autocomplete-styles__container {
  display: flex;
  flex-flow: row wrap;
  padding: 5.0px;
  min-height: 48px;
  height: 48px;
  box-sizing: border-box;
  border: 1px solid #D0D0D0;
  cursor: text;
}

.ladder_ny_form_google_autocomplete__google-autocomplete-styles__container:hover {
  border: 1px solid #707070;
}

.ladder_ny_form_google_autocomplete__google-autocomplete-styles__container:focus-within {
  border: 1px solid #000000;
}

.ladder_ny_form_google_autocomplete__google-autocomplete-styles__input-container {
  margin-left: 2px;
  margin-right: 8px;
  flex-grow: 1;
  width: auto;
  max-width: 100%;
}

.ladder_ny_form_google_autocomplete__google-autocomplete-styles__input {
  min-width: 20px;
  width: 100%;
  height: 35px;
  border: 0;
  flex-grow: 1;
  font: inherit;
  font-size: 16px;
  color: #707070;
  padding-left: 8px;
}

.ladder_ny_form_google_autocomplete__google-autocomplete-styles__v2-header {
  margin-bottom: 3px;
  display: flex;
}

.ladder_ny_form_google_autocomplete__google-autocomplete-styles__cant-find-dr-button {
  justify-content: flex-end;
  margin-left: auto;
  font-weight: 300;
  cursor: pointer;
  color: #975C46;
}

@media (max-width: 499px) {

  
  
  .ladder_ny_form_google_autocomplete__google-autocomplete-styles__root {
    padding-bottom: 60px;
  }

}
.ladder_prototype_laddering__v4-styles__root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 30px;
}

.ladder_prototype_laddering__v4-styles__entry {
  display: flex;
  padding: 10px 0;
  flex-direction: column;
}

.ladder_prototype_laddering__v4-styles__sentence {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 15px;
}

.ladder_prototype_laddering__v4-styles__amounts {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.ladder_prototype_laddering__v4-styles__select {
  border-width: 1.5px;
  border-color: #DA2413;
  font-family: Lato, sans-serif;
  margin: 2px 1px;
  -webkit-appearance: unset;
  padding: 0 1px;
  border-style: solid;
  appearence: unset;
  font-size: 18px;
  background: none;
  color: #DA2413;
}

.ladder_prototype_laddering__v4-styles__select:focus {
  border: none;
}

.ladder_prototype_laddering__v4-styles__plan {
  display: flex;
  height: 60px;
  width: 100%;
  align-items: flex-end;
}

.ladder_prototype_laddering__v4-styles__plan-saved-premium {
  align-items: flex-start;
}

.ladder_prototype_laddering__v4-styles__year {
  height: 30px;
  background-color: #FE4747;
  transition-property: height;
  transition-duration: 1s;
}

.ladder_prototype_laddering__v4-styles__year-selected {
  background-color: #FFFFFF;
  border-width: 1px;
  border-style: solid;
  border-color: #FE4747;
}

.ladder_prototype_laddering__v4-styles__year-expense {
  background-color: #DA2413;
}

.ladder_prototype_laddering__v4-styles__year-cash {
  background-color: #00C397;
}

.ladder_prototype_laddering__v4-styles__year-saved-premium {
  background-color: #00C397;
}

.ladder_prototype_laddering__v4-styles__typograph-wrapper {
  position: absolute;
  pointer-events: none;
  background-color: white;
  left: 0;
}

.ladder_prototype_laddering__v4-styles__policy-information {
  display: flex;
  justify-content: space-between;
}

.ladder_prototype_laddering__v4-styles__text-wrapper {
  margin: 2px 1px;
}

.ladder_prototype_laddering__v4-styles__percision-coverage {
  border-width: 5px;
  border-style: solid;
  border-color: #D0D0D0;
  padding: 15px;
}

.ladder_prototype_laddering__v4-styles__percision-coverage-checkbox {
  display: flex;
}

.ladder_prototype_laddering__v4-styles__icon-arrow-one {
  margin: 0 16px;
  width: 20px;
}

.ladder_prototype_laddering__v4-styles__icon-arrow {
  margin: 16px;
  width: 20.0px;
}
.ladder_ny_form_quick_estimate__inline-section-styles__root {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5.0px 0;
}

@media (max-width: 499px) {

  
  
  .ladder_ny_form_quick_estimate__inline-section-styles__root {
    padding: 5.0px 0;
  }

}

.box-fields .ladder_ny_form_quick_estimate__inline-section-styles__root {
  padding: 0;
  justify-content: left;
}
.ladder_agents_agent_assisted_app_views_offer__allianz-rating-modal-styles__text-box {
  margin-top: 15.0px;
}

.ladder_agents_agent_assisted_app_views_offer__allianz-rating-modal-styles__all-img {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}

.ladder_agents_agent_assisted_app_views_offer__allianz-rating-modal-styles__img-row {
  display: flex;
  justify-content: center;
  margin-right: 60px;
}

.ladder_agents_agent_assisted_app_views_offer__allianz-rating-modal-styles__second-logo {
  display: flex;
  justify-content: center;
  margin-top: 5.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_agent_assisted_app_views_offer__allianz-rating-modal-styles__all-img {
    display: inline;
  }
  
  .ladder_agents_agent_assisted_app_views_offer__allianz-rating-modal-styles__img-row {
    margin-top: 20px;
    margin-right: 0px;
  }
  
  .ladder_agents_agent_assisted_app_views_offer__allianz-rating-modal-styles__second-logo {
    margin-top: 20px;
    margin-right: 0px;
  }

}
@media (max-width: 499px) {

  
  
  .ladder_careers__ui-values-styles__root {
    margin-top: 50.0px;
  }
  
  .ladder_careers__ui-values-styles__carousel-padding {
    margin: 30.0px 0 30.0px 0;
  }

}
.ladder_onboarding_referrals__panel-2-updated-styles__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  height: 100%;
}

.ladder_onboarding_referrals__panel-2-updated-styles__center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}

.ladder_onboarding_referrals__panel-2-updated-styles__spacer {
  margin-top: 180px;
  margin-bottom: 140px;
}

.ladder_onboarding_referrals__panel-2-updated-styles__megaphone {
  position: relative;
}

.ladder_onboarding_referrals__panel-2-updated-styles__continue-to-account-link {
  margin-top: 30.0px;
}

.ladder_onboarding_referrals__panel-2-updated-styles__copy-link-container {
  padding: 15.0px;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  border: 2px solid #D9D9D9;
}

.ladder_onboarding_referrals__panel-2-updated-styles__copy-link {
  margin-left: 30.0px;
}

.ladder_onboarding_referrals__panel-2-updated-styles__referral-url {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}

.ladder_onboarding_referrals__panel-2-updated-styles__social-sharing-row {
  display: flex;
  margin-left: 15.0px;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  min-width: 150px;
  max-width: 200px;
}

.ladder_onboarding_referrals__panel-2-updated-styles__sticky {
  position: sticky;
  bottom: 0;
}

.ladder_onboarding_referrals__panel-2-updated-styles__some-padding {
  margin: 15.0px 20px;
}

@media (min-width: 1025px) {

  
  
  .ladder_onboarding_referrals__panel-2-updated-styles__copy-link-container {
    max-width: 62%;
  }
  
  .ladder_onboarding_referrals__panel-2-updated-styles__link-and-social-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .ladder_onboarding_referrals__panel-2-updated-styles__cta-header {
    margin-bottom: 42.0px;
  }
  
  
  
  .ladder_onboarding_referrals__panel-2-updated-styles__no-margin .ladder_onboarding_referrals__panel-2-updated-styles__body {
    margin-top: 0px;
  }
  
  .ladder_onboarding_referrals__panel-2-updated-styles__mobile-spacing {
    margin-top: 0px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_onboarding_referrals__panel-2-updated-styles__copy-link-container {
    max-width: 62%;
  }
  
  .ladder_onboarding_referrals__panel-2-updated-styles__link-and-social-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .ladder_onboarding_referrals__panel-2-updated-styles__cta-header {
    margin-bottom: 42.0px;
  }
  
  
  
  .ladder_onboarding_referrals__panel-2-updated-styles__no-margin .ladder_onboarding_referrals__panel-2-updated-styles__body {
    margin-top: 0px;
  }
  
  .ladder_onboarding_referrals__panel-2-updated-styles__mobile-spacing {
    margin-top: 0px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_onboarding_referrals__panel-2-updated-styles__cta-header {
    text-align: center;
  }
  
  .ladder_onboarding_referrals__panel-2-updated-styles__hidden-mobile {
    display: none;
  }
  
  .ladder_onboarding_referrals__panel-2-updated-styles__mobile-spacing {
    margin-top: 0px;
  }
  
  .ladder_onboarding_referrals__panel-2-updated-styles__copy-link-container {
    width: 100%;
  }
  
  .ladder_onboarding_referrals__panel-2-updated-styles__continue-to-account-link {
    display: flex;
    justify-content: center;
  }
  
  .ladder_onboarding_referrals__panel-2-updated-styles__social-sharing-row {
    margin-top: 30.0px;
    margin-left: auto;
    margin-right: auto;
  }

}
.ladder_account_shared_components__policy-id-title-styles__border {
  padding: 5px 10px;
  border: 1px solid #1A1A1A;
  border-radius: 3px;
  background-color: #F1F1F1;
  width: fit-content;
  height: fit-content;
}
.ladder_markdoc_core__root-styles__root {
  display: flex;
  border-top-width: 1px;
  border-top-style: solid;
  width: 100%;
  height: calc(100vh - 170px);
  border-top-color: #E5E5E5;
}

.ladder_markdoc_core__root-styles__content {
  border-left-width: 1px;
  border-left-style: solid;
  padding: 0 1em;
  border-left-color: #E5E5E5;
  overflow-y: scroll;
  width: 100%;
}

@media (max-width: 499px) {

  
  
  .ladder_markdoc_core__root-styles__content {
    margin-left: unset;
    border-left-width: unset;
    border-left-style: unset;
    border-left-color: unset;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_markdoc_core__root-styles__content {
    margin-left: unset;
    border-left-width: unset;
    border-left-style: unset;
    border-left-color: unset;
  }

}
.ladder_form_quick_start__Height-styles__horizontal {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.ladder_form_quick_start__Height-styles__vertical {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.ladder_form_quick_start__Height-styles__margin-top {
  margin-top: 10.0px;
}

.ladder_form_quick_start__Height-styles__margin-left {
  margin-left: 10.0px;
}

.ladder_form_quick_start__Height-styles__margin-right {
  margin-right: 10.0px;
}

.ladder_form_quick_start__Height-styles__margin-bottom {
  margin-bottom: 10.0px;
}

.ladder_form_quick_start__Height-styles__form-fields {
  margin: 25.0px 0;
}

.ladder_form_quick_start__Height-styles__form-fields>div {
  margin-bottom: 10.0px;
}

.ladder_form_quick_start__Height-styles__row {
  display: flex;
  justify-content: space-between;
}

.ladder_form_quick_start__Height-styles__row > *:not(:last-child) {
  margin-right: 20px;
}

.ladder_form_quick_start__Height-styles__row>div {
  width: 100%;
}

@media (min-width: 1025px) {

  
  
  .ladder_form_quick_start__Height-styles__form-fields {
    margin: 20px 0 30.0px 0;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_form_quick_start__Height-styles__row {
    display: block;
  }
  
  .ladder_form_quick_start__Height-styles__row>div {
    margin-bottom: 10.0px;
  }

}
.ladder_views_decision_common__amendment-section-styles__root {
  width: 100%;
  max-width: 700px;
}

.ladder_views_decision_common__amendment-section-styles__card {
  width: 100%;
  box-sizing: border-box;
  padding: 20px 60px;
  text-align: left;
  background-color: #F4F4F4;
}

.ladder_views_decision_common__amendment-section-styles__amendment-line {
  width: 100%;
  padding: 10.0px 0;
}

@media (max-width: 499px) {

  
  
  .ladder_views_decision_common__amendment-section-styles__card {
    padding: 20px 20px;
  }

}
.ladder_apply_beneficiary_banner__BeneficiaryBanner-styles__banner {
  width: 100%;
  padding: 20px;
  background-color: #E7EEEF;
}
@media (min-width: 1025px) {

  
  
  .ladder_agents_dashboard__settings-support-page-styles__container {
    max-width: 600px;
  }
  
  .ladder_agents_dashboard__settings-support-page-styles__schedule-call-button {
    border-radius: 24px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    margin-top: 4em;
    width: 600px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard__settings-support-page-styles__container {
    max-width: 350px;
  }
  
  .ladder_agents_dashboard__settings-support-page-styles__schedule-call-button {
    border-radius: 24px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    margin-top: 4em;
    width: 350px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_dashboard__settings-support-page-styles__container {
    max-width: 350px;
  }
  
  .ladder_agents_dashboard__settings-support-page-styles__schedule-call-button {
    border-radius: 24px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    margin-top: 4em;
    width: 350px;
  }

}
.ladder_account_next_views_beneficiaries_edit__edit-beneficiary-fields-display-styles__field {
  margin: 10px 0;
}

@media (min-width: 1025px) {

  
  
  .ladder_account_next_views_beneficiaries_edit__edit-beneficiary-fields-display-styles__columns {
    display: flex;
    flex-direction: row;
  }
  
  .ladder_account_next_views_beneficiaries_edit__edit-beneficiary-fields-display-styles__maybe-column {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  
  .ladder_account_next_views_beneficiaries_edit__edit-beneficiary-fields-display-styles__maybe-column:first-of-type {
    padding-right: 15px;
  }
  
  .ladder_account_next_views_beneficiaries_edit__edit-beneficiary-fields-display-styles__maybe-column:last-of-type {
    padding-left: 15px;
  }
  


}

@media (max-width: 499px) {

  
  
  .ladder_account_next_views_beneficiaries_edit__edit-beneficiary-fields-display-styles__maybe-column {
    display: contents;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account_next_views_beneficiaries_edit__edit-beneficiary-fields-display-styles__maybe-column {
    display: contents;
  }

}
.ladder_account_next_views_messages__post-sign-drq-thread-styles__root {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.ladder_agents_dashboard_nav__back-button-styles__circle {
  width: 30px;
  justify-content: center;
  height: 30px;
  align-items: center;
  transform: rotate(90deg);
  bottom-margin: 2em;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  background: #FFFFFF;
}
.ladder_account_next_views_additional_actions__title-styles__\% {
  margin-bottom: 32px;
}

@media (max-width: 499px) {

  
  
  .ladder_account_next_views_additional_actions__title-styles__\% {
    margin-top: 16px;
  }

}
.ladder_financial_advisors_home__how-it-works-items-styles__\% {
  padding: 0 5.5%;
}

@media (min-width: 1025px) {

  
  
  .ladder_financial_advisors_home__how-it-works-items-styles__\% {
    display: flex;
    width: 80%;
    padding: 0;
    justify-content: space-between;
    margin-top: 48px;
  }

}
.ladder_view_ladder_down__premiums-styles__premiums-container {
  width: 100%;
  max-width: 1100px;
}

.ladder_view_ladder_down__premiums-styles__root {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

@media (max-width: 499px) {

  
  
  .ladder_view_ladder_down__premiums-styles__root {
    flex-direction: column;
    align-items: center;
  }

}
.ladder_ux_policy_card__inset-card-styles__root {
  display: flex;
  justify-content: center;
  position: relative;
  background-color: #FFFFFF;
  height: 96px;
  box-sizing: content-box;
  width: 100%;
  margin: 0 8.0px;
}

.ladder_ux_policy_card__inset-card-styles__root>* {
  box-sizing: border-box;
}

.ladder_ux_policy_card__inset-card-styles__background-golden {
  background-color: #FFF2CA;
}

.ladder_ux_policy_card__inset-card-styles__height-s {
  height: 96px;
}

.ladder_ux_policy_card__inset-card-styles__height-sm {
  height: 112px;
}

.ladder_ux_policy_card__inset-card-styles__height-m {
  height: 128px;
}

.ladder_ux_policy_card__inset-card-styles__width-half {
  flex-basis: 50%;
}

.ladder_ux_policy_card__inset-card-styles__ribbon-anchor {
  position: absolute;
  overflow: hidden;
  top: 0;
  width: 100%;
  height: 100%;
}

.ladder_ux_policy_card__inset-card-styles__ribbon {
  transform: translate(32px, 16px) translate(-100px) rotate(-45deg);
  text-align: center;
  display: block;
  position: absolute;
  width: 200px;
  overflow-x: hidden;
  background-color: #FE4747;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_policy_card__inset-card-styles__root {
    height: 64px;
  }
  
  .ladder_ux_policy_card__inset-card-styles__height-s {
    height: 56.0px;
  }
  
  .ladder_ux_policy_card__inset-card-styles__height-sm {
    height: 64px;
  }
  
  .ladder_ux_policy_card__inset-card-styles__height-m {
    height: 96px;
  }
  
  .ladder_ux_policy_card__inset-card-styles__root-mobile-column {
    margin: 4.0px 0;
    padding: 8.0px 0;
  }

}
.ladder_agents_dashboard_inputs__centered-checkbox-styles__checkbox-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  background-color: #FFFFFF;
  border-radius: 24px;
  border: 1px solid #D0D0D0;
}

.ladder_agents_dashboard_inputs__centered-checkbox-styles__checkbox-container label {
  padding: 20px;
}
.ladder_agents_agent_assisted_app_views_offer__gift-logos-styles__logo {
  height: auto;
  min-width: 24%;
  max-width: 28%;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_agent_assisted_app_views_offer__gift-logos-styles__logo-margin {
    margin: 20px 0;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_agents_agent_assisted_app_views_offer__gift-logos-styles__logo-margin {
    margin: 20px 20px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_agent_assisted_app_views_offer__gift-logos-styles__logo-margin {
    margin: 20px 20px;
  }

}

.ladder_agents_agent_assisted_app_views_offer__gift-logos-styles__multiple-logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.ladder_agents_agent_assisted_app_views_offer__gift-logos-styles__one-logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.ladder_agents_agent_assisted_app_views_offer__gift-logos-styles__two-logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_agent_assisted_app_views_offer__gift-logos-styles__one-logos {
    justify-content: space-around;
  }

}
@media (min-width: 1025px) {

  
  
  .ladder_account_next_views_beneficiaries_view__MoreActionsRows-styles__row {
    display: flex;
    justify-content: space-between;
  }

}

.ladder_account_next_views_beneficiaries_view__MoreActionsRows-styles__row {
  margin: 20px 0;
}
.ladder_views_decision_main__refuse-styles__spacer {
  padding: 30.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_views_decision_main__refuse-styles__spacer {
    padding: 22.5px;
  }

}
.ladder_form_upper_funnel_routing__upper-funnel-routing-loading-styles__wrapper {
  display: flex;
  align-items: center;
  padding: 60px 0;
}

.ladder_form_upper_funnel_routing__upper-funnel-routing-loading-styles__content {
  display: flex;
  padding: 0 5%;
}

.ladder_form_upper_funnel_routing__upper-funnel-routing-loading-styles__copy .ladder_form_upper_funnel_routing__upper-funnel-routing-loading-styles__progress {
  margin: 22.5px 0 30.0px 0;
}

.ladder_form_upper_funnel_routing__upper-funnel-routing-loading-styles__rollerskate {
  width: 40%;
}

@media (max-width: 499px) {

  
  
  .ladder_form_upper_funnel_routing__upper-funnel-routing-loading-styles__wrapper {
    padding: 0;
  }
  
  .ladder_form_upper_funnel_routing__upper-funnel-routing-loading-styles__content {
    flex-direction: column;
    align-items: center;
  }
  
  .ladder_form_upper_funnel_routing__upper-funnel-routing-loading-styles__rollerskate {
    width: 80%;
    margin-top: 30.0px;
  }

}
@media (max-width: 499px) {

  
  
  .ladder_onboarding_spouse_connections__Panel1-styles__header {
    font-size: 30px;
  }

}
.ladder_cap_and_accel_post_sign_view__Root-styles__horizontal-container {
  display: flex;
  background-color: #CFDCDE;
  height: 90vh;
  width: 100%;
}

@media (max-width: 499px) {

  
  
  .ladder_cap_and_accel_post_sign_view__Root-styles__horizontal-container {
    flex-direction: column-reverse;
    justify-content: flex-end;
    height: auto;
  }

}

.ladder_cap_and_accel_post_sign_view__Root-styles__main-text-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
}

@media (max-width: 499px) {

  
  
  .ladder_cap_and_accel_post_sign_view__Root-styles__main-text-container {
    padding: 0 20px;
    width: 100%;
  }

}

.ladder_cap_and_accel_post_sign_view__Root-styles__text-container {
  max-width: 750px;
}

.ladder_cap_and_accel_post_sign_view__Root-styles__padding {
  padding-top: 5%;
}

.ladder_cap_and_accel_post_sign_view__Root-styles__padding-large {
  padding-top: 15%;
}

.ladder_cap_and_accel_post_sign_view__Root-styles__button-container {
  padding-top: 10%;
  display: flex;
  justify-content: flex-start;
}

.ladder_cap_and_accel_post_sign_view__Root-styles__image-container {
  display: flex;
  align-self: flex-end;
  width: 35%;
}

@media (max-width: 499px) {

  
  
  .ladder_cap_and_accel_post_sign_view__Root-styles__image-container {
    align-self: flex-start;
    width: 66.67%;
  }

}

.ladder_cap_and_accel_post_sign_view__Root-styles__img {
  width: 100%;
}
.ladder_views_decision_main__wait-styles__root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
}

.ladder_views_decision_main__wait-styles__text {
  margin-bottom: 45vh;
  margin-top: 60px;
  padding: 0 10.0px;
  text-align: center;
}

.ladder_views_decision_main__wait-styles__spinner {
  margin-top: 60px;
}
.ladder_agents_agent_assisted_app_views_review__default-link-styles__root {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 30.0px 0 0px;
}

.ladder_agents_agent_assisted_app_views_review__default-link-styles__pencil {
  height: 16px;
  width: 16px;
}

.ladder_agents_agent_assisted_app_views_review__default-link-styles__display-lines {
  display: flex;
  flex-direction: column;
}

.ladder_agents_agent_assisted_app_views_review__default-link-styles__hidden {
  visibility: hidden;
}

.ladder_agents_agent_assisted_app_views_review__default-link-styles__pencil-wrapper {
  padding-left: 20px;
}

.ladder_agents_agent_assisted_app_views_review__default-link-styles__edit-button {

}
.ladder_onboarding_spouse_connect_2__directing-you-to-new-application-styles__col {
  display: flex;
  flex-direction: column;
}

.ladder_onboarding_spouse_connect_2__directing-you-to-new-application-styles__row {
  display: flex;
  flex-direction: row;
}
.ladder_onboarding_accepted_offer__CoverMeASAPRoot-styles__root {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 100%;
  background-color: #F7F2EF;
}

.ladder_onboarding_accepted_offer__CoverMeASAPRoot-styles__content {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: space-around;
}

.ladder_onboarding_accepted_offer__CoverMeASAPRoot-styles__confetti {
  position: absolute;
  top: 0;
  background-image: url(../img/confetti.png);
  background-size: contain;
  background-repeat: repeat-x;
  height: 200px;
  z-index: 0;
  width: 100%;
}

.ladder_onboarding_accepted_offer__CoverMeASAPRoot-styles__left-container {
  width: 50%;
  max-width: 600px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 60px;
  z-index: 1;
  margin-left: 60px;
  display: flex;
}

.ladder_onboarding_accepted_offer__CoverMeASAPRoot-styles__right-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  width: 40%;
  max-width: 600px;
}

.ladder_onboarding_accepted_offer__CoverMeASAPRoot-styles__policy-details-container {
  display: flex;
  background-color: white;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 450px;
  margin: 30.0px 0;
}

.ladder_onboarding_accepted_offer__CoverMeASAPRoot-styles__policy-details-text {
  margin: 10.0px 0;
}

.ladder_onboarding_accepted_offer__CoverMeASAPRoot-styles__coverage-amount-details-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.ladder_onboarding_accepted_offer__CoverMeASAPRoot-styles__coverage-amount-details-text-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  align-items: center;
}

.ladder_onboarding_accepted_offer__CoverMeASAPRoot-styles__add-my-beneficiary-button {
  display: flex;
  max-width: 80%;
  margin: 0 0 60px 0;
}

.ladder_onboarding_accepted_offer__CoverMeASAPRoot-styles__faq-container {
  display: flex;
  width: 90%;
  margin: auto;
}

@media (max-width: 499px) {

  
  
  .ladder_onboarding_accepted_offer__CoverMeASAPRoot-styles__confetti {
    height: 80px;
    background-size: cover;
    opacity: 0.5;
  }
  
  .ladder_onboarding_accepted_offer__CoverMeASAPRoot-styles__content {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .ladder_onboarding_accepted_offer__CoverMeASAPRoot-styles__left-container {
    height: 100%;
    width: 100%;
    margin: unset;
  }
  
  .ladder_onboarding_accepted_offer__CoverMeASAPRoot-styles__right-container {
    width: 100%;
  }
  
  .ladder_onboarding_accepted_offer__CoverMeASAPRoot-styles__policy-details-container {
    max-width: unset;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_onboarding_accepted_offer__CoverMeASAPRoot-styles__confetti {
    height: 80px;
    background-size: cover;
    opacity: 0.5;
  }
  
  .ladder_onboarding_accepted_offer__CoverMeASAPRoot-styles__content {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .ladder_onboarding_accepted_offer__CoverMeASAPRoot-styles__left-container {
    height: 100%;
    width: 100%;
    margin: unset;
  }
  
  .ladder_onboarding_accepted_offer__CoverMeASAPRoot-styles__right-container {
    width: 100%;
  }
  
  .ladder_onboarding_accepted_offer__CoverMeASAPRoot-styles__policy-details-container {
    max-width: unset;
  }

}
.ladder_agents_dashboard_contacts__contact-page-styles__section-styling {
  width: 100%;
  gap: 20px;
  padding: 25px;
  flex-direction: column;
  margin-top: 1em;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  background-color: #FFFFFF;
  border-radius: 25px;
  display: flex;
}

.ladder_agents_dashboard_contacts__contact-page-styles__quote-container-padding {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
}

.ladder_agents_dashboard_contacts__contact-page-styles__horizontal-bar {
  width: 100%;
  margin-top: 1em;
  margin-bottom: 1em;
  height: 1px;
  background: #CCCCCC;
}

.ladder_agents_dashboard_contacts__contact-page-styles__quote-box-spacing {
  justify-content: space-between;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard_contacts__contact-page-styles__section-styling {
    gap: 40px;
  }
  
  .ladder_agents_dashboard_contacts__contact-page-styles__personal-details-layout {
    display: flex;
    flex-direction: column-reverse;
    gap: 30px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_dashboard_contacts__contact-page-styles__section-styling {
    gap: 40px;
  }
  
  .ladder_agents_dashboard_contacts__contact-page-styles__personal-details-layout {
    display: flex;
    flex-direction: column-reverse;
    gap: 30px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_agents_dashboard_contacts__contact-page-styles__personal-details-layout {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 30px;
    width: 100%;
  }
  
  .ladder_agents_dashboard_contacts__contact-page-styles__section-styling {
    gap: 20px;
  }

}
.ladder_app_review__expandable-review-wrapper-styles__wrapper {
  border: solid 1px black;
}

.ladder_app_review__expandable-review-wrapper-styles__expander {
  border-top: solid 1px black;
  padding: 10px;
  display: flex;
  justify-content: center;
}

@media (min-width: 1025px) {

  
  
  .ladder_app_review__expandable-review-wrapper-styles__content {
    max-height: 160px;
    overflow: scroll;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_app_review__expandable-review-wrapper-styles__wrapper {
    margin: 0 10px 10px 10px;
  }

}
.ladder_views_decision_common__affordability-warning-section-styles__logo {
  min-height: 15px;
  min-width: 15px;
  margin-right: 10px;
}

.ladder_views_decision_common__affordability-warning-section-styles__container {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.ladder_views_decision_common__affordability-warning-section-styles__text {

}
.ladder_ny_form_traffic_diversion__next-steps-title-styles__root {
  margin-top: 48px;
  margin-bottom: 16px;
}

@media (max-width: 499px) {

  
  
  .ladder_ny_form_traffic_diversion__next-steps-title-styles__root {
    margin-top: 24px;
  }

}
.ladder_agents_agent_assisted_app_views_decision__decline-body-styles__container {
  display: flex;
  flex-direction: column;
  max-width: 514px;
  gap: 36px;
}
.ladder_nearly_perfect_quote_affordability__affordability-range-bar-styles__arb-root {
  margin-top: 10.0px;
}

.ladder_nearly_perfect_quote_affordability__affordability-range-bar-styles__container-bar {
  width: 100%;
  display: flex;
  padding-right: 3.5px;
}

.ladder_nearly_perfect_quote_affordability__affordability-range-bar-styles__box {
  height: 10px;
  width: 100%;
  display: flex;
}

.ladder_nearly_perfect_quote_affordability__affordability-range-bar-styles__half-box {
  height: 100%;
  width: 100%;
  display: flex;
}

.ladder_nearly_perfect_quote_affordability__affordability-range-bar-styles__hb-mark {
  border-right: solid 1px #749475;
}

.ladder_nearly_perfect_quote_affordability__affordability-range-bar-styles__quarter-box {
  height: 7px;
  width: 100%;
  align-self: end;
}

.ladder_nearly_perfect_quote_affordability__affordability-range-bar-styles__filled {
  background-color: #DBE6DB;
}

.ladder_nearly_perfect_quote_affordability__affordability-range-bar-styles__coverage-update-alert {
  display: flex;
  margin-top: 10px;
  gap: 10px;
  align-items: flex-start;
}

.ladder_nearly_perfect_quote_affordability__affordability-range-bar-styles__alert-shield {
  max-height: 30px;
  width: 30px;
}

@media (max-width: 499px) {

  
  
  .ladder_nearly_perfect_quote_affordability__affordability-range-bar-styles__alert-shield {
    max-height: 50px;
    width: 50px;
  }

}
.ladder_agents_agent_assisted_app_views_review__multiselect-styles__root {
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.ladder_agents_agent_assisted_app_views_review__multiselect-styles__multiselect-root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ladder_agents_agent_assisted_app_views_review__multiselect-styles__option-root {
  display: flex;
  text-align: left;
  padding: 5.0px 0;
}

.ladder_agents_agent_assisted_app_views_review__multiselect-styles__option {
  display: flex;
  cursor: pointer;
}

.ladder_agents_agent_assisted_app_views_review__multiselect-styles__checkbox-container {
  padding-top: 3px;
  padding-right: 10.0px;
}

.ladder_agents_agent_assisted_app_views_review__multiselect-styles__info-expander-container {
  padding-top: 3px;
  padding-left: 10.0px;
}

.ladder_agents_agent_assisted_app_views_review__multiselect-styles__display-lines {
  display: flex;
  flex-direction: column;
}

.ladder_agents_agent_assisted_app_views_review__multiselect-styles__hidden {
  visibility: hidden;
}

.ladder_agents_agent_assisted_app_views_review__multiselect-styles__pencil {
  height: 16px;
  width: 16px;
}

.ladder_agents_agent_assisted_app_views_review__multiselect-styles__pencil-wrapper {
  padding-left: 20px;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_agent_assisted_app_views_review__multiselect-styles__checkbox-container {
    padding-top: 0;
  }
  
  .ladder_agents_agent_assisted_app_views_review__multiselect-styles__info-expander-container {
    padding-top: 0;
  }

}
.ladder_app_review__default-link-styles__root {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 30.0px 0 0px;
}

.ladder_app_review__default-link-styles__pencil {
  height: 16px;
  width: 16px;
}

.ladder_app_review__default-link-styles__display-lines {
  display: flex;
  flex-direction: column;
}

.ladder_app_review__default-link-styles__hidden {
  visibility: hidden;
}

.ladder_app_review__default-link-styles__pencil-wrapper {
  padding-left: 20px;
}

.ladder_app_review__default-link-styles__edit-button {

}
.ladder_agents_agent_assisted_app_views_review__question-styles__root {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
}

.ladder_agents_agent_assisted_app_views_review__question-styles__anchor-skip-header {
  scroll-margin-top: 99px;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_agent_assisted_app_views_review__question-styles__anchor-skip-header {
    scroll-margin-top: 60px;
    scroll-snap-margin-top: 60px;
  }

}
.ladder_widget_ui_v2__ad-corner-styles__triangle {
  height: 75px;
  width: 75px;
  position: relative;
  justify-content: flex-end;
  display: flex;
  max-width: 100%;
}

.ladder_widget_ui_v2__ad-corner-styles__beige-gradient {
  background-image: -webkit-linear-gradient(right top, #F7F3EE 50%, transparent 50%);
}

.ladder_widget_ui_v2__ad-corner-styles__text {
  font-weight: 500;
  margin-top: 15px;
  margin-right: 10px;
}

.ladder_widget_ui_v2__ad-corner-styles__upper-container {
  display: flex;
  justify-content: space-between;
}

.ladder_widget_ui_v2__ad-corner-styles__logo {
  width: 30%;
  margin-left: 20px;
  margin-top: 20px;
}
.ladder_savings_common__steps-account-card-styles__wrapper {
  border: 1px solid #707070;
  margin: 16px 0px;
  padding: 15.0px 20px;
}

.ladder_savings_common__steps-account-card-styles__modal-wrapper {
  padding-top: 20px;
}

.ladder_savings_common__steps-account-card-styles__modal-wrapper > * {
  margin-bottom: 20px;
}

.ladder_savings_common__steps-account-card-styles__learn-more {
  display: block;
  margin-top: 10.0px;
}

.ladder_savings_common__steps-account-card-styles__qr-code {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ladder_account_next_views_beneficiaries_view__BeneficiariesSectionTabs-styles__\% {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  margin-top: 0;
}

.ladder_account_next_views_beneficiaries_view__BeneficiariesSectionTabs-styles__spacer {
  padding: 0 19px 10px;
}

.ladder_account_next_views_beneficiaries_view__BeneficiariesSectionTabs-styles__spacer:last-of-type {
  flex-grow: 1;
  padding: 0 0 10px 0;
}

@media (max-width: 499px) {

  
  
  .ladder_account_next_views_beneficiaries_view__BeneficiariesSectionTabs-styles__\% {
    position: relative;
    width: 100vw;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }

}
.ladder_trustage_quote__quote-panel-v2-styles__spinner {
  margin-top: 15.0px;
}

.ladder_trustage_quote__quote-panel-v2-styles__panel {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 10.0px;
  margin-top: 22.5px;
  padding: 20px;
}

.ladder_trustage_quote__quote-panel-v2-styles__panel.ladder_trustage_quote__quote-panel-v2-styles__themed {
  border-width: 0;
}

.ladder_trustage_quote__quote-panel-v2-styles__change-details {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.ladder_trustage_quote__quote-panel-v2-styles__your-quote {
  flex-basis: 50%;
  margin-top: 30px;
}

@media (min-width: 1025px) {

  
  
  
  
  .ladder_trustage_quote__quote-panel-v2-styles__change-details .ladder_trustage_quote__quote-panel-v2-styles__logo {
    margin: 30.0px 0 25.0px;
    height: 31px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  
  
  .ladder_trustage_quote__quote-panel-v2-styles__change-details .ladder_trustage_quote__quote-panel-v2-styles__logo {
    margin: 30.0px 0 25.0px;
    height: 31px;
  }

}

@media (max-width: 499px) {

  
  
  
  
  .ladder_trustage_quote__quote-panel-v2-styles__change-details .ladder_trustage_quote__quote-panel-v2-styles__your-quote {
    margin-top: 10.0px;
  }
  
  .ladder_trustage_quote__quote-panel-v2-styles__change-details .ladder_trustage_quote__quote-panel-v2-styles__logo {
    width: 110px;
    margin: 20px 0 25.0px;
  }

}
.ladder_account_next_modals__PaymentRetrySelectInvoices-styles__buttons-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.ladder_account_next_modals__PaymentRetrySelectInvoices-styles__invoice-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  width: 100%;
}

.ladder_account_next_modals__PaymentRetrySelectInvoices-styles__invoice-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.ladder_account_next_modals__PaymentRetrySelectInvoices-styles__info-error-copy {
  min-height: 22.5px;
}

.ladder_account_next_modals__PaymentRetrySelectInvoices-styles__info-with-icon {
  display: flex;
  column-gap: 10.0px;
}

.ladder_account_next_modals__PaymentRetrySelectInvoices-styles__info-icon {
  flex-shrink: 0;
  flex-grow: 0;
  margin-top: 2.5px;
}
.ladder_ux_typography__screen-type-specific-typography-styles__montserrat {
  font-family: Montserrat, sans-serif;
}

.ladder_ux_typography__screen-type-specific-typography-styles__helvetica {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.ladder_ux_typography__screen-type-specific-typography-styles__underline-dashed {
  text-decoration: underline dashed;
  text-underline-offset: 20%;
}

.ladder_ux_typography__screen-type-specific-typography-styles__underline-dotted {
  text-decoration: underline dotted;
  text-underline-offset: 20%;
}

.ladder_ux_typography__screen-type-specific-typography-styles__underline {
  text-decoration: underline;
  text-underline-offset: 20%;
}

.ladder_ux_typography__screen-type-specific-typography-styles__thin-underline {
  text-decoration: underline;
  text-underline-offset: 20%;
  text-decoration-thickness: 2px;
}

.ladder_ux_typography__screen-type-specific-typography-styles__ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ladder_ux_typography__screen-type-specific-typography-styles__subpixel {
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
}

.ladder_ux_typography__screen-type-specific-typography-styles__grayscale {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ladder_ux_typography__screen-type-specific-typography-styles__center {
  text-align: center;
}

.ladder_ux_typography__screen-type-specific-typography-styles__left {
  text-align: left;
}

.ladder_ux_typography__screen-type-specific-typography-styles__right {
  text-align: right;
}

.ladder_ux_typography__screen-type-specific-typography-styles__light {
  font-weight: 300;
}

.ladder_ux_typography__screen-type-specific-typography-styles__normal {
  font-weight: 400;
}

.ladder_ux_typography__screen-type-specific-typography-styles__medium {
  font-weight: 550;
}

.ladder_ux_typography__screen-type-specific-typography-styles__medium-bold {
  font-weight: 600;
}

.ladder_ux_typography__screen-type-specific-typography-styles__bold {
  font-weight: 700;
}

.ladder_ux_typography__screen-type-specific-typography-styles__header-55 {
  font-size: 55px;
  line-height: 75px;
}

.ladder_ux_typography__screen-type-specific-typography-styles__header-40 {
  font-size: 40px;
  line-height: 50px;
}

.ladder_ux_typography__screen-type-specific-typography-styles__header-36 {
  font-size: 36px;
  line-height: 46px;
}

.ladder_ux_typography__screen-type-specific-typography-styles__header-32 {
  font-size: 32px;
  line-height: 42px;
}

.ladder_ux_typography__screen-type-specific-typography-styles__header-20 {
  font-size: 20px;
  line-height: 30px;
}

.ladder_ux_typography__screen-type-specific-typography-styles__header-18 {
  font-size: 18px;
  line-height: 28px;
}

.ladder_ux_typography__screen-type-specific-typography-styles__header-17 {
  font-size: 17px;
  line-height: 30px;
}

.ladder_ux_typography__screen-type-specific-typography-styles__header-16 {
  font-size: 16px;
  line-height: 26px;
}

.ladder_ux_typography__screen-type-specific-typography-styles__header-15 {
  font-size: 15px;
  line-height: 20px;
}

.ladder_ux_typography__screen-type-specific-typography-styles__header-13 {
  font-size: 13px;
  line-height: 20px;
}

.ladder_ux_typography__screen-type-specific-typography-styles__header-12 {
  font-size: 12px;
  line-height: 18px;
}

.ladder_ux_typography__screen-type-specific-typography-styles__header-10 {
  font-size: 10px;
  line-height: 15px;
}

.ladder_ux_typography__screen-type-specific-typography-styles__body-20 {
  font-size: 20px;
  line-height: 30px;
}

.ladder_ux_typography__screen-type-specific-typography-styles__body-17 {
  font-size: 17px;
  line-height: 30px;
}

.ladder_ux_typography__screen-type-specific-typography-styles__body-16 {
  font-size: 16px;
  line-height: 26px;
}

.ladder_ux_typography__screen-type-specific-typography-styles__body-15 {
  font-size: 15px;
  line-height: 20px;
}

.ladder_ux_typography__screen-type-specific-typography-styles__body-13 {
  font-size: 13px;
  line-height: 20px;
}

.ladder_ux_typography__screen-type-specific-typography-styles__body-12 {
  font-size: 12px;
  line-height: 18px;
}

@media (min-width: 1025px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__desktop-montserrat {
    font-family: Montserrat, sans-serif;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__tablet-montserrat {
    font-family: Montserrat, sans-serif;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__mobile-montserrat {
    font-family: Montserrat, sans-serif;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__desktop-helvetica {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__tablet-helvetica {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__mobile-helvetica {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__desktop-underline-dashed {
    text-decoration: underline dashed;
    text-underline-offset: 20%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__tablet-underline-dashed {
    text-decoration: underline dashed;
    text-underline-offset: 20%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__mobile-underline-dashed {
    text-decoration: underline dashed;
    text-underline-offset: 20%;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__desktop-underline-dotted {
    text-decoration: underline dotted;
    text-underline-offset: 20%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__tablet-underline-dotted {
    text-decoration: underline dotted;
    text-underline-offset: 20%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__mobile-underline-dotted {
    text-decoration: underline dotted;
    text-underline-offset: 20%;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__desktop-underline {
    text-decoration: underline;
    text-underline-offset: 20%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__tablet-underline {
    text-decoration: underline;
    text-underline-offset: 20%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__mobile-underline {
    text-decoration: underline;
    text-underline-offset: 20%;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__desktop-thin-underline {
    text-decoration: underline;
    text-underline-offset: 20%;
    text-decoration-thickness: 2px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__tablet-thin-underline {
    text-decoration: underline;
    text-underline-offset: 20%;
    text-decoration-thickness: 2px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__mobile-thin-underline {
    text-decoration: underline;
    text-underline-offset: 20%;
    text-decoration-thickness: 2px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__desktop-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__tablet-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__mobile-ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__desktop-subpixel {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: unset;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__tablet-subpixel {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: unset;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__mobile-subpixel {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: unset;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__desktop-grayscale {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__tablet-grayscale {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__mobile-grayscale {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__desktop-center {
    text-align: center;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__tablet-center {
    text-align: center;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__mobile-center {
    text-align: center;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__desktop-left {
    text-align: left;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__tablet-left {
    text-align: left;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__mobile-left {
    text-align: left;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__desktop-right {
    text-align: right;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__tablet-right {
    text-align: right;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__mobile-right {
    text-align: right;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__desktop-light {
    font-weight: 300;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__tablet-light {
    font-weight: 300;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__mobile-light {
    font-weight: 300;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__desktop-normal {
    font-weight: 400;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__tablet-normal {
    font-weight: 400;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__mobile-normal {
    font-weight: 400;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__desktop-medium {
    font-weight: 550;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__tablet-medium {
    font-weight: 550;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__mobile-medium {
    font-weight: 550;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__desktop-medium-bold {
    font-weight: 600;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__tablet-medium-bold {
    font-weight: 600;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__mobile-medium-bold {
    font-weight: 600;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__desktop-bold {
    font-weight: 700;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__tablet-bold {
    font-weight: 700;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__mobile-bold {
    font-weight: 700;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__desktop-header-55 {
    font-size: 55px;
    line-height: 75px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__tablet-header-55 {
    font-size: 55px;
    line-height: 75px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__mobile-header-55 {
    font-size: 55px;
    line-height: 75px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__desktop-header-40 {
    font-size: 40px;
    line-height: 50px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__tablet-header-40 {
    font-size: 40px;
    line-height: 50px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__mobile-header-40 {
    font-size: 40px;
    line-height: 50px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__desktop-header-36 {
    font-size: 36px;
    line-height: 46px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__tablet-header-36 {
    font-size: 36px;
    line-height: 46px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__mobile-header-36 {
    font-size: 36px;
    line-height: 46px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__desktop-header-32 {
    font-size: 32px;
    line-height: 42px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__tablet-header-32 {
    font-size: 32px;
    line-height: 42px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__mobile-header-32 {
    font-size: 32px;
    line-height: 42px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__desktop-header-20 {
    font-size: 20px;
    line-height: 30px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__tablet-header-20 {
    font-size: 20px;
    line-height: 30px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__mobile-header-20 {
    font-size: 20px;
    line-height: 30px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__desktop-header-18 {
    font-size: 18px;
    line-height: 28px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__tablet-header-18 {
    font-size: 18px;
    line-height: 28px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__mobile-header-18 {
    font-size: 18px;
    line-height: 28px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__desktop-header-17 {
    font-size: 17px;
    line-height: 30px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__tablet-header-17 {
    font-size: 17px;
    line-height: 30px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__mobile-header-17 {
    font-size: 17px;
    line-height: 30px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__desktop-header-16 {
    font-size: 16px;
    line-height: 26px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__tablet-header-16 {
    font-size: 16px;
    line-height: 26px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__mobile-header-16 {
    font-size: 16px;
    line-height: 26px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__desktop-header-15 {
    font-size: 15px;
    line-height: 20px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__tablet-header-15 {
    font-size: 15px;
    line-height: 20px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__mobile-header-15 {
    font-size: 15px;
    line-height: 20px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__desktop-header-13 {
    font-size: 13px;
    line-height: 20px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__tablet-header-13 {
    font-size: 13px;
    line-height: 20px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__mobile-header-13 {
    font-size: 13px;
    line-height: 20px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__desktop-header-12 {
    font-size: 12px;
    line-height: 18px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__tablet-header-12 {
    font-size: 12px;
    line-height: 18px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__mobile-header-12 {
    font-size: 12px;
    line-height: 18px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__desktop-header-10 {
    font-size: 10px;
    line-height: 15px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__tablet-header-10 {
    font-size: 10px;
    line-height: 15px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__mobile-header-10 {
    font-size: 10px;
    line-height: 15px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__desktop-body-20 {
    font-size: 20px;
    line-height: 30px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__tablet-body-20 {
    font-size: 20px;
    line-height: 30px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__mobile-body-20 {
    font-size: 20px;
    line-height: 30px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__desktop-body-17 {
    font-size: 17px;
    line-height: 30px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__tablet-body-17 {
    font-size: 17px;
    line-height: 30px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__mobile-body-17 {
    font-size: 17px;
    line-height: 30px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__desktop-body-16 {
    font-size: 16px;
    line-height: 26px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__tablet-body-16 {
    font-size: 16px;
    line-height: 26px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__mobile-body-16 {
    font-size: 16px;
    line-height: 26px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__desktop-body-15 {
    font-size: 15px;
    line-height: 20px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__tablet-body-15 {
    font-size: 15px;
    line-height: 20px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__mobile-body-15 {
    font-size: 15px;
    line-height: 20px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__desktop-body-13 {
    font-size: 13px;
    line-height: 20px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__tablet-body-13 {
    font-size: 13px;
    line-height: 20px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__mobile-body-13 {
    font-size: 13px;
    line-height: 20px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__desktop-body-12 {
    font-size: 12px;
    line-height: 18px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__tablet-body-12 {
    font-size: 12px;
    line-height: 18px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__mobile-body-12 {
    font-size: 12px;
    line-height: 18px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__desktop-color {
    color: var(--desktop-color);
  }
  
  .ladder_ux_typography__screen-type-specific-typography-styles__desktop-line-height {
    line-height: var(--desktop-line-height);
  }
  
  .ladder_ux_typography__screen-type-specific-typography-styles__desktop-font-size {
    font-size: var(--desktop-font-size);
  }
  
  .ladder_ux_typography__screen-type-specific-typography-styles__desktop-letter-spacing {
    font-size: var(--desktop-letter-spacing);
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__tablet-color {
    color: var(--tablet-color);
  }
  
  .ladder_ux_typography__screen-type-specific-typography-styles__tablet-line-height {
    line-height: var(--tablet-line-height);
  }
  
  .ladder_ux_typography__screen-type-specific-typography-styles__tablet-font-size {
    font-size: var(--tablet-font-size);
  }
  
  .ladder_ux_typography__screen-type-specific-typography-styles__tablet-letter-spacing {
    font-size: var(--tablet-letter-spacing);
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_typography__screen-type-specific-typography-styles__mobile-color {
    color: var(--mobile-color);
  }
  
  .ladder_ux_typography__screen-type-specific-typography-styles__mobile-line-height {
    line-height: var(--mobile-line-height);
  }
  
  .ladder_ux_typography__screen-type-specific-typography-styles__mobile-font-size {
    font-size: var(--mobile-font-size);
  }
  
  .ladder_ux_typography__screen-type-specific-typography-styles__mobile-letter-spacing {
    font-size: var(--mobile-letter-spacing);
  }

}
.ladder_agents_agent_assisted_app_views_review__section-title-styles__root {
  width: 100%;
  cursor: default;
  scroll-margin-top: 100px;
}

.ladder_agents_agent_assisted_app_views_review__section-title-styles__header-container {
  display: flex;
  flex-grow: 1;
  justify-content: left;
  align-items: center;
}
.ladder_agents_agent_assisted_app_views_decision__email-sent-modal-styles__button {
  margin-top: 40px;
}
.ladder_ny_form_quick_estimate__input-block-styles__root {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 5.0px;
  align-items: center;
}

.ladder_ny_form_quick_estimate__input-block-styles__hide-bottom-text {
  visibility: hidden;
}

.ladder_ny_form_quick_estimate__input-block-styles__show-bottom-text {
  bottom: -18px;
  width: 100px;
  text-align: center;
  position: absolute;
  visibility: visible;
}

@media (max-width: 499px) {

  
  
  .ladder_ny_form_quick_estimate__input-block-styles__root {
    padding: 0 2.5px;
  }
  
  .ladder_ny_form_quick_estimate__input-block-styles__show-bottom-text {
    bottom: -14px;
  }

}
.ladder_ny_form_list__root-styles__actions {
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
}
.ladder_agents_agent_assisted_app_views_decision__decline-panel-styles__container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.ladder_markdown_components__ordered-list-styles__root {
  list-style: decimal;
  color: #4C4C4E;
  padding-left: 32px;
}
.ladder_agents_home__contact-us-modal-styles__inputs-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.ladder_agents_home__contact-us-modal-styles__input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10.0px 0;
}

.ladder_agents_home__contact-us-modal-styles__half-desktop {
  width: calc(50% - 10px);
}

.ladder_agents_home__contact-us-modal-styles__terms-container {
  margin: 0 10.0px;
  padding: 10.0px 0;
  justify-content: left;
}

.ladder_agents_home__contact-us-modal-styles__terms-agreement {
  margin-left: 10.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_home__contact-us-modal-styles__half-desktop {
    width: 100%;
  }
  
  .ladder_agents_home__contact-us-modal-styles__inputs-container {
    flex-direction: column;
  }
  
  .ladder_agents_home__contact-us-modal-styles__terms-container {
    flex-direction: row;
  }

}
.ladder_nearly_perfect_quote_common__nav-buttons-styles__buttons-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
  gap: 20px;
}

.ladder_nearly_perfect_quote_common__nav-buttons-styles__buttons-wrapper.ladder_nearly_perfect_quote_common__nav-buttons-styles__bottom-padding {
  padding-bottom: 30px;
}

@media (max-width: 499px) {

  
  
  .ladder_nearly_perfect_quote_common__nav-buttons-styles__buttons-wrapper {
    padding-top: 20px;
  }

}

.ladder_nearly_perfect_quote_common__nav-buttons-styles__column {
  flex-direction: column-reverse;
  gap: 10px;
}
.ladder_beneficiary_form__beneficiary-input-entry-person-styles__checkbox-row {
  display: flex;
}

.ladder_beneficiary_form__beneficiary-input-entry-person-styles__checkbox-wrapper {
  flex-shrink: 0;
  padding: 0 10.0px 0 0;
}
.ladder_agents_dashboard_pre_screener__questions-list-styles__container {
  display: flex;
  flex-direction: column;
  font-size: 13px;
  gap: 32px;
}
.ladder_aimcor_transfer__transfer-screen-styles__root {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
}

.ladder_aimcor_transfer__transfer-screen-styles__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  padding-bottom: 30.0px;
}

.ladder_aimcor_transfer__transfer-screen-styles__body {
  display: flex;
  flex-direction: column;
  gap: 25.0px;
}

.ladder_aimcor_transfer__transfer-screen-styles__nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.ladder_aimcor_transfer__transfer-screen-styles__legal {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30.0px;
}

@media (min-width: 1025px) {

  
  
  .ladder_aimcor_transfer__transfer-screen-styles__body {
    width: 90%;
  }
  
  .ladder_aimcor_transfer__transfer-screen-styles__header {
    margin: 30.0px 0 25.0px;
  }
  
  .ladder_aimcor_transfer__transfer-screen-styles__copy {
    margin-bottom: 20px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_aimcor_transfer__transfer-screen-styles__body {
    width: 90%;
  }
  
  .ladder_aimcor_transfer__transfer-screen-styles__header {
    margin: 30.0px 0 25.0px;
  }
  
  .ladder_aimcor_transfer__transfer-screen-styles__copy {
    margin-bottom: 20px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_aimcor_transfer__transfer-screen-styles__container {
    width: 80%;
  }
  
  .ladder_aimcor_transfer__transfer-screen-styles__header {
    margin: 25.0px 0;
  }

}
.ladder_ux_expand_card__mobile-nav-expand-card-styles__root {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  border-top: 1px solid #D0D0D0;
}

.ladder_ux_expand_card__mobile-nav-expand-card-styles__root last-child {
  border-bottom: 1px solid #D0D0D0;
}

.ladder_ux_expand_card__mobile-nav-expand-card-styles__root.ladder_ux_expand_card__mobile-nav-expand-card-styles__no-border-top {
  border-top: unset;
}

.ladder_ux_expand_card__mobile-nav-expand-card-styles__root.ladder_ux_expand_card__mobile-nav-expand-card-styles__mystic-background {
  background: #E7EEEF;
}

.ladder_ux_expand_card__mobile-nav-expand-card-styles__root.ladder_ux_expand_card__mobile-nav-expand-card-styles__white-background {
  background: #FFFFFF;
}

.ladder_ux_expand_card__mobile-nav-expand-card-styles__root.ladder_ux_expand_card__mobile-nav-expand-card-styles__margin-bottom-m {
  margin-bottom: 16px;
}

.ladder_ux_expand_card__mobile-nav-expand-card-styles__root.ladder_ux_expand_card__mobile-nav-expand-card-styles__round-corners {
  border-radius: 8px;
}

.ladder_ux_expand_card__mobile-nav-expand-card-styles__title-row {
  text-align: left;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding: 12.0px 12.0px 12.0px 32px;
}

.ladder_ux_expand_card__mobile-nav-expand-card-styles__title-row.ladder_ux_expand_card__mobile-nav-expand-card-styles__bigger-padding {
  padding: 24.0px;
}

.ladder_ux_expand_card__mobile-nav-expand-card-styles__title-wrapper {
  width: 80%;
  padding: 8.0px 0;
}

.ladder_ux_expand_card__mobile-nav-expand-card-styles__title-icon-wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.ladder_ux_expand_card__mobile-nav-expand-card-styles__title-icon-wrapper.ladder_ux_expand_card__mobile-nav-expand-card-styles__stick-to-top {
  align-items: flex-start;
}

.ladder_ux_expand_card__mobile-nav-expand-card-styles__icon {
  display: flex;
  width: 20.0px;
  height: 20.0px;
  margin: 8.0px;
}

.ladder_ux_expand_card__mobile-nav-expand-card-styles__icon.ladder_ux_expand_card__mobile-nav-expand-card-styles__stick-to-top {
  margin: 1px 8.0px 8.0px 8.0px;
}

.ladder_ux_expand_card__mobile-nav-expand-card-styles__expanded {
  padding: 0 24.0px 24.0px 24.0px;
  margin-top: -10px;
}

.ladder_ux_expand_card__mobile-nav-expand-card-styles__text-wrapper {
  display: flex;
  width: 90%;
  margin-right: 4px;
}
.ladder_account_next_nav_nav_item__rungs-user-coverage-summary-name-styles__\% {
  overflow: hidden;
  margin-bottom: 10.0px;
  text-overflow: ellipsis;
}

@media (min-width: 1025px) {

  
  
  .ladder_account_next_nav_nav_item__rungs-user-coverage-summary-name-styles__\% {
    line-height: 21px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account_next_nav_nav_item__rungs-user-coverage-summary-name-styles__\% {
    line-height: 21px;
  }

}
.ladder_guide_how_much_life_insurance__Root-styles__spacer {
  background-color: #D1A98F;
  width: 100%;
  height: 82px;
}
.ladder_ux_button__addon-styles__thin {
  padding: 0 8.0px !important;
}
.ladder_user_upload_form__divider-styles__divider {
  border: none;
  border-bottom: 1px solid #F4F4F4;
  margin: 8px 0px;
}

.ladder_user_upload_form__divider-styles__divider:last-child {
  display: none;
}
@media (min-width: 1025px) {

  
  
  .ladder_account_next_views_policy__LinksToPolicySubsections-styles__\% {
    padding-top: 64px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account_next_views_policy__LinksToPolicySubsections-styles__\% {
    padding-top: 64px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_account_next_views_policy__LinksToPolicySubsections-styles__\% {
    padding-top: 32px;
  }

}
.ladder_account_next_modals__thumbs-up-styles__thumbs-up {
  width: 80px;
  height: auto;
  margin: 20px;
}
.ladder_lux_markdown_components__bold-styles__root {
  font-weight: bold;
}

.ladder_lux_markdown_components__bold-styles__medium-bold {
  font-weight: 600;
}
.ladder_form_upper_funnel_routing__ads-panel-styles__wrapper {
  background-color: #F7F2EF;
  border: solid 2px;
  border-color: #975C46;
  border-radius: 8px;
  padding: 30.0px 50.0px;
  margin-bottom: 30.0px;
}

.ladder_form_upper_funnel_routing__ads-panel-styles__header {
  display: flex;
  margin-bottom: 10.0px;
  align-items: center;
}

.ladder_form_upper_funnel_routing__ads-panel-styles__star {
  height: 32px;
  width: 32px;
  margin-right: 20px;
}

.ladder_form_upper_funnel_routing__ads-panel-styles__ads {
  display: flex;
  flex-wrap: wrap;
  margin-top: 22.5px;
  justify-content: space-between;
}

@media (min-width: 1025px) {

  
  
  .ladder_form_upper_funnel_routing__ads-panel-styles__ads {
    margin-bottom: 22.5px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_form_upper_funnel_routing__ads-panel-styles__ads {
    justify-content: flex-start;
  }
  
  .ladder_form_upper_funnel_routing__ads-panel-styles__ads >* {
    margin-bottom: 20px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_form_upper_funnel_routing__ads-panel-styles__wrapper {
    padding: 30.0px 25.0px;
  }
  
  .ladder_form_upper_funnel_routing__ads-panel-styles__star {
    height: 26px;
    width: 27px;
    margin-right: 10.0px;
  }
  
  .ladder_form_upper_funnel_routing__ads-panel-styles__ads {
    flex-direction: column;
  }
  
  .ladder_form_upper_funnel_routing__ads-panel-styles__ads >* {
    margin-bottom: 20px;
  }

}
.ladder_trustage_fallback__trustage-fallback-v2-styles__wrapper {
  position: fixed;
  top: 100px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  transition: background-color 200ms ease-in 1400ms;
}

.ladder_trustage_fallback__trustage-fallback-v2-styles__wrapper.ladder_trustage_fallback__trustage-fallback-v2-styles__themed {
  background-color: white;
}

.ladder_trustage_fallback__trustage-fallback-v2-styles__wrapper.ladder_trustage_fallback__trustage-fallback-v2-styles__relative {
  position: relative;
  top: 0;
}

.ladder_trustage_fallback__trustage-fallback-v2-styles__content-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  overflow-y: scroll;
}

.ladder_trustage_fallback__trustage-fallback-v2-styles__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: fit-content;
  max-width: 700px;
}

.ladder_trustage_fallback__trustage-fallback-v2-styles__carousel-container {
  padding: 50.0px 20px;
}

.ladder_trustage_fallback__trustage-fallback-v2-styles__quote-container {
  padding: 50.0px 20px;
}

@media (min-width: 1025px) {

  
  
  .ladder_trustage_fallback__trustage-fallback-v2-styles__wrapper {
    height: calc(100% - 100px);
    top: 100px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_trustage_fallback__trustage-fallback-v2-styles__wrapper {
    height: calc(100% - 60px);
    top: 60px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_trustage_fallback__trustage-fallback-v2-styles__wrapper {
    height: calc(100% - 60px);
    top: 60px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_trustage_fallback__trustage-fallback-v2-styles__wrapper.ladder_trustage_fallback__trustage-fallback-v2-styles__modal {
    position: relative;
    top: 0;
    height: 100%;
  }

}
.ladder_agents_pass_the_application__h-spacer-styles__h-spacer {
  width: 32px;
}
.ladder_cover_me_asap_views_billing_affordability__defaulted-coverage-tooltip-styles__info-expander-container {
  display: flex;
  justify-content: flex-end;
}

.ladder_cover_me_asap_views_billing_affordability__defaulted-coverage-tooltip-styles__info-expander-text {
  max-width: 100%;
}

@media (min-width: 1025px) {

  
  
  .ladder_cover_me_asap_views_billing_affordability__defaulted-coverage-tooltip-styles__info-expander-container {
    margin-bottom: 10px;
  }
  
  .ladder_cover_me_asap_views_billing_affordability__defaulted-coverage-tooltip-styles__info-expander-text {
    padding: 20px 10px;
  }

}
.ladder_gift_promo__cta-panel-styles__button-wrapper {
  align-self: center;
}

@media (max-width: 499px) {

  
  
  .ladder_gift_promo__cta-panel-styles__button-wrapper {
    display: none;
  }

}
.ladder_form_quick_start__fleet-funnel-personal-styles__form-fields {
  margin: 25.0px 0;
}

.ladder_form_quick_start__fleet-funnel-personal-styles__form-fields>div {
  margin-bottom: 10.0px;
}

.ladder_form_quick_start__fleet-funnel-personal-styles__row {
  display: flex;
  justify-content: space-between;
}

.ladder_form_quick_start__fleet-funnel-personal-styles__row > *:not(:last-child) {
  margin-right: 20px;
}

.ladder_form_quick_start__fleet-funnel-personal-styles__row>div {
  width: 100%;
}

.ladder_form_quick_start__fleet-funnel-personal-styles__state {
  flex-direction: column;
  gap: 16px;
}

@media (min-width: 1025px) {

  
  
  .ladder_form_quick_start__fleet-funnel-personal-styles__form-fields {
    margin: 20px 0 30.0px 0;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_form_quick_start__fleet-funnel-personal-styles__row {
    display: block;
  }
  
  .ladder_form_quick_start__fleet-funnel-personal-styles__row>div {
    margin-bottom: 10.0px;
  }

}
.ladder_quick_start__TimeTestedAndProven-styles__root {
  display: flex;
  width: 90%;
  max-width: 1400px;
  background-color: #FFFFFF;
  justify-content: space-between;
}

.ladder_quick_start__TimeTestedAndProven-styles__text {
  margin: 30.0px 0;
  width: 640px;
}

.ladder_quick_start__TimeTestedAndProven-styles__trustpilot-container {
  margin: 30.0px;
  display: flex;
  align-items: center;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_quick_start__TimeTestedAndProven-styles__root {
    flex-direction: column;
  }
  
  .ladder_quick_start__TimeTestedAndProven-styles__text {
    display: flex;
    width: 90%;
  }
  
  .ladder_quick_start__TimeTestedAndProven-styles__trustpilot-container {
    width: 50%;
    margin: 60px 130.0px 60px 0;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_quick_start__TimeTestedAndProven-styles__root {
    flex-direction: column;
  }
  
  .ladder_quick_start__TimeTestedAndProven-styles__text {
    display: flex;
    width: 90%;
  }
  
  .ladder_quick_start__TimeTestedAndProven-styles__trustpilot-container {
    width: 50%;
    margin: 60px 130.0px 60px 0;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_quick_start__TimeTestedAndProven-styles__trustpilot-container {
    margin: 60px 60px 60px 0;
  }

}
.ladder_agents_agent_assisted_app_views_app_status__panels-container-styles__container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 40px;
  width: 100%;
  height: 100%;
}

@media (min-width: 1025px) {

  
  
  .ladder_agents_agent_assisted_app_views_app_status__panels-container-styles__container {
    min-height: 448px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_agents_agent_assisted_app_views_app_status__panels-container-styles__container {
    flex-direction: column;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_agent_assisted_app_views_app_status__panels-container-styles__container {
    flex-direction: column;
  }

}
.ladder_financial_advisors_payment_select__nonpaid-advisor-form-styles__nonpaid-advisor-form {

}

.ladder_financial_advisors_payment_select__nonpaid-advisor-form-styles__agree-terms-form {
  display: flex;
  margin-top: 20px;
  border: 2px solid#D9D9D9;
  padding: 20px;
}

.ladder_financial_advisors_payment_select__nonpaid-advisor-form-styles__checkbox-wrapper {
  width: 20px;
  margin-right: 25px;
}
.ladder_about__nice-to-meet-you-styles__container {
  margin-top: 50.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_about__nice-to-meet-you-styles__copy-1 {
    margin-top: 60px;
  }
  
  .ladder_about__nice-to-meet-you-styles__copy-2 {
    margin-top: 50.0px;
  }
  
  .ladder_about__nice-to-meet-you-styles__mobile-heading {
    font-size: 30px;
    line-height: 45px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_about__nice-to-meet-you-styles__copy-1 {
    margin-top: 60px;
  }
  
  .ladder_about__nice-to-meet-you-styles__copy-2 {
    margin-top: 50.0px;
  }
  
  .ladder_about__nice-to-meet-you-styles__mobile-heading {
    font-size: 30px;
    line-height: 45px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_about__nice-to-meet-you-styles__copy-1 {
    margin-top: 75px;
  }
  
  .ladder_about__nice-to-meet-you-styles__copy-2 {
    margin-top: 50.0px;
  }

}
.ladder_savings_common__steps-billing-panel-styles__icon {
  fill: #465046;
  width: 30.0px;
  height: auto;
  margin-right: 10.0px;
}
.ladder_agents_agent_assisted_app_views_decision__decline-modal-text-styles__container {
  margin-top: 16px;
}
.ladder_guide_common__second-equation-styles__equation-divider {
  border-bottom: 1px solid black;
  width: 225px;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (max-width: 499px) {

  
  
  .ladder_guide_common__second-equation-styles__mobile-wrapper {
    margin-top: 30.0px;
    margin-bottom: 30.0px;
  }

}
.ladder_ux_info_expander__info-expander-absolute-styles__root {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ladder_ux_info_expander__info-expander-absolute-styles__label-container {
  display: flex;
  align-items: center;
}

.ladder_ux_info_expander__info-expander-absolute-styles__label-text {
  margin-right: 5px;
}

.ladder_ux_info_expander__info-expander-absolute-styles__label-text:hover {
  text-decoration: underline;
}

.ladder_ux_info_expander__info-expander-absolute-styles__rungs-label-text {
  text-decoration: dashed underline;
}

.ladder_ux_info_expander__info-expander-absolute-styles__rungs-label-text:hover {
  color: #D1A98F;
}

.ladder_ux_info_expander__info-expander-absolute-styles__label-text-expanded {
  text-decoration: underline;
}

.ladder_ux_info_expander__info-expander-absolute-styles__billing-affordability-label-text {
  text-decoration: underline;
  text-underline-offset: 4px;
  color: #465046;
  cursor: pointer;
}

.ladder_ux_info_expander__info-expander-absolute-styles__align-center {
  display: flex;
  align-items: center;
}

.ladder_ux_info_expander__info-expander-absolute-styles__icon {
  cursor: pointer;
  max-width: 20.0px;
  max-height: 20.0px;
}

.ladder_ux_info_expander__info-expander-absolute-styles__medium-icon {
  max-width: 16px;
}

.ladder_ux_info_expander__info-expander-absolute-styles__small-icon {
  max-width: 12.0px;
  height: 15px;
}

.ladder_ux_info_expander__info-expander-absolute-styles__triangle-container {
  position: absolute;
  width: 32px;
  height: 32px;
  bottom: 100%;
}

.ladder_ux_info_expander__info-expander-absolute-styles__triangle-container-down {
  top: 100%;
}

.ladder_ux_info_expander__info-expander-absolute-styles__triangle {
  width: 100%;
  height: 100%;
}

.ladder_ux_info_expander__info-expander-absolute-styles__triangle-down {
  transform: rotate(180deg);
}

.ladder_ux_info_expander__info-expander-absolute-styles__expanded-container {
  width: 300px;
  margin: 0 0 20px 0;
  padding: 16px;
  text-align: left;
  position: absolute;
  z-index: 3;
  background-color: #475665;
  box-sizing: border-box;
  bottom: 100%;
}

.ladder_ux_info_expander__info-expander-absolute-styles__wide-expanded-container {
  width: 80vw;
  max-width: 500px;
}

.ladder_ux_info_expander__info-expander-absolute-styles__medium-expanded-container {
  width: 70vw;
  max-width: 405px;
}

.ladder_ux_info_expander__info-expander-absolute-styles__expanded-container-down {
  top: 100%;
  bottom: auto;
  margin: 23px 0 0 0;
}

.ladder_ux_info_expander__info-expander-absolute-styles__inline {
  display: inline-flex;
}

.ladder_ux_info_expander__info-expander-absolute-styles__rungs-triangle-container {
  position: absolute;
  width: 18px;
  height: 22px;
  bottom: 100%;
}

.ladder_ux_info_expander__info-expander-absolute-styles__rungs-expanded-container {
  border-radius: 5px;
  background-color: #FFFFFF;
  border: solid 1px #D0D0D0;
}

.ladder_ux_info_expander__info-expander-absolute-styles__rungs-triangle {
  position: absolute;
  border-radius: 12px;
  width: 24px;
  z-index: 4;
  top: -5px;
  left: -4px;
}

.ladder_ux_info_expander__info-expander-absolute-styles__rungs-triangle>g {
  fill: #FFFFFF;
}

.ladder_ux_info_expander__info-expander-absolute-styles__rungs-triangle-down {
  top: 50px;
}

.ladder_ux_info_expander__info-expander-absolute-styles__content-text {
  padding-right: 30px;
}

.ladder_ux_info_expander__info-expander-absolute-styles__close-button-container {
  width: 30px;
  right: 0px;
  top: 0px;
  height: 30px;
  position: absolute;
  cursor: pointer;
  box-shadow: 0px 1.5px 3px rgba(51, 46, 84, 0.22);
  background-color: #FFFFFF;
  border-radius: 50%;
}

.ladder_ux_info_expander__info-expander-absolute-styles__close-icon {
  height: 15px;
  width: 15px;
  left: 8px;
  top: 8px;
  position: absolute;
}

.ladder_ux_info_expander__info-expander-absolute-styles__content-text-wrapper {
  position: relative;
}

.ladder_ux_info_expander__info-expander-absolute-styles__full-width {
  width: 100%;
  min-width: 0;
}

.ladder_ux_info_expander__info-expander-absolute-styles__billing-affordability {
  background-color: #DBE6DB;
  border-radius: 8px;
  padding: 0 5px;
}

.ladder_ux_info_expander__info-expander-absolute-styles__billing-affordability-triangle {
  position: absolute;
  z-index: 4;
}

.ladder_ux_info_expander__info-expander-absolute-styles__gap-between-label-and-icon {
  gap: 5px;
}

.box-fields .ladder_ux_info_expander__info-expander-absolute-styles__root {
  justify-content: center;
}
.ladder_markdown_components__table-styles__root {
  table-layout: fixed;
  text-align: left;
  width: 100%;
}
.ladder_account_shared_components__field-answer-pair-styles__root {
  padding: 15.0px 0;
}

.ladder_account_shared_components__field-answer-pair-styles__field {
  padding: 5.0px 0;
}
.ladder_requestion_post_sign_questions__re-question-explanation-page-right-column-styles__root {
  display: flex;
  justify-content: center;
}

.ladder_requestion_post_sign_questions__re-question-explanation-page-right-column-styles__inner-container {
  width: 65%;
}

.ladder_requestion_post_sign_questions__re-question-explanation-page-right-column-styles__text-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 50px;
}

.ladder_requestion_post_sign_questions__re-question-explanation-page-right-column-styles__button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  padding: 30px;
  margin-top: 30px;
}

.ladder_requestion_post_sign_questions__re-question-explanation-page-right-column-styles__button {
  width: 90%;
}

@media (max-width: 499px) {

  
  
  .ladder_requestion_post_sign_questions__re-question-explanation-page-right-column-styles__inner-container {
    width: 100%;
    margin: 0 20px;
  }
  
  .ladder_requestion_post_sign_questions__re-question-explanation-page-right-column-styles__button-container {
    gap: 15px;
    margin-top: 30px;
    padding: 0px;
  }
  
  .ladder_requestion_post_sign_questions__re-question-explanation-page-right-column-styles__button {
    width: 100%;
  }
  
  .ladder_requestion_post_sign_questions__re-question-explanation-page-right-column-styles__root {
    margin-bottom: -70px;
  }
  
  .ladder_requestion_post_sign_questions__re-question-explanation-page-right-column-styles__text-container {
    margin-top: 30px;
  }

}
.ladder_footer__divider-styles__divider {
  border: none;
  border-bottom: 1px solid #D0D0D0;
  margin: 30.0px auto;
}

@media (max-width: 499px) {

  
  
  .ladder_footer__divider-styles__divider {
    margin: 20px auto;
  }

}
.ladder_ny_form_review__new-york-legal-notice-styles__container {
  display: flex;
  align-items: center;
  padding: 20px 0;
  width: 100%;
}

.ladder_ny_form_review__new-york-legal-notice-styles__ladder-text {
  white-space: nowrap;
  margin: 10.0px 0;
}

@media (max-width: 499px) {

  
  
  .ladder_ny_form_review__new-york-legal-notice-styles__container {
    flex-wrap: wrap;
    padding: 10.0px 0;
  }

}
.ladder_account_next_views_billing__NoContentCard-styles__title {
  margin-left: 5.0px;
  margin-bottom: 30.0px;
}
.ladder_account_payment_history__line-item-list-styles__root {
  box-sizing: border-box;
  width: 100%;
}

.ladder_account_payment_history__line-item-list-styles__root >* {
  box-sizing: border-box;
}

.ladder_account_payment_history__line-item-list-styles__border-container {
  border-bottom: 1px solid #D0D0D0;
  width: 100%;
}

.ladder_account_payment_history__line-item-list-styles__border-container:last-child {
  border-bottom: 0;
}

@media (min-width: 1025px) {

  
  
  .ladder_account_payment_history__line-item-list-styles__border-container {
    padding-left: 20px;
  }

}
.ladder_apply_fallback_carousel__step-styles__root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0;
}

.ladder_apply_fallback_carousel__step-styles__text {
  transition: color 200ms ease-in;
}

.ladder_apply_fallback_carousel__step-styles__icon-container {
  height: 25px;
  width: 25px;
  position: relative;
}

.ladder_apply_fallback_carousel__step-styles__icon {
  height: 25px;
  width: 25px;
}

@media (max-width: 499px) {

  
  
  .ladder_apply_fallback_carousel__step-styles__text {
    margin-right: 25px;
  }

}
.ladder_form_name__root-styles__root {
  display: flex;
  flex-direction: column;
}

.ladder_form_name__root-styles__first-middle {
  flex-grow: 1;
}

.ladder_form_name__root-styles__desktop-display {
  display: flex;
}

.ladder_form_name__root-styles__first {
  flex-grow: 1;
}

.ladder_form_name__root-styles__first-desktop {
  margin-right: 22.5px;
}

.ladder_form_name__root-styles__middle {
  flex-grow: 1;
}
.ladder_account_next_views_cancel_app__Root-styles__title {
  margin-bottom: 30.0px;
}
.ladder_advisor_only_standalone_quoter__quoter-styles__steps-to-get-started {
  width: 824px;
  padding-left: 30px;
}

.ladder_advisor_only_standalone_quoter__quoter-styles__flex-container {
  justify-content: center;
  display: flex;
  flex-direction: row;
}
.ladder_savings_common__npq-ribbon-styles__ribbon {
  background-color: #DBE6DB;
  padding: 10.0px;
}
.ladder_onboarding_spouse_connections__Panel2-styles__container {
  max-width: 620px;
  flex-direction: column;
  display: flex;
}

.ladder_onboarding_spouse_connections__Panel2-styles__divider {
  margin-top: 60px;
  margin-bottom: 40px;
}

@media (max-width: 499px) {

  
  
  .ladder_onboarding_spouse_connections__Panel2-styles__container {
    flex-direction: column-reverse;
  }
  
  .ladder_onboarding_spouse_connections__Panel2-styles__divider {
    visibility: hidden;
    margin-top: 35.0px;
    margin-bottom: 0;
  }
  
  .ladder_onboarding_spouse_connections__Panel2-styles__header {
    font-size: 20px;
  }

}
.ladder_views_decision_common__refuse-offer-are-you-sure-modal-styles__modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.ladder_app_in_app_billing__in-app-billing-design-styles__root {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 100%;
}
.ladder_ux_homepage_testimonials_core__dash-buttons-styles__button-container {
  width: 90%;
  max-width: 300px;
  display: flex;
  justify-content: space-around;
  padding: 12.0px 0;
  margin: 0 auto;
}

.ladder_ux_homepage_testimonials_core__dash-buttons-styles__light-button {
  width: 40px;
  height: 4px;
  background-color: #D0D0D0;
  cursor: pointer;
}

.ladder_ux_homepage_testimonials_core__dash-buttons-styles__dark-button {
  background-color: #707070;
}
.ladder_onboarding_significant_other_connections__significant-other-input-form-styles__row {
  display: flex;
  flex-direction: row;
}

@media (max-width: 499px) {

  
  
  .ladder_onboarding_significant_other_connections__significant-other-input-form-styles__row {
    display: flex;
    flex-direction: column;
  }

}

.ladder_onboarding_significant_other_connections__significant-other-input-form-styles__width-100 {
  width: 100%;
}
.ladder_header__offset-overlapping-column-styles__root {
  display: flex;
  margin-top: -200px;
  padding-top: 250px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_header__offset-overlapping-column-styles__root {
    margin-top: -60px;
    padding-top: 60px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_header__offset-overlapping-column-styles__root {
    margin-top: -60px;
    padding-top: 60px;
  }

}
.ladder_account_next_cards_need_help__ContactUsActions-styles__\% {
  margin-top: 22.5px;
}
html {
  height: 100%;
  overflow-y: scroll;
  font-family: sans-serif;
}

body {
  color: #000000;
  height: 100%;
}

.root {
  height: 100%;
}

#app {
  height: 100%;
}

a {
  text-decoration: none;
}

code {
  line-height: 1.42857143;
  font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
  margin: 0 0 10px;
  border: 1px solid #ccc;
  word-break: break-all;
  padding: 9.5px;
  word-wrap: break-word;
  overflow: auto;
  font-size: 13px;
  background-color: #f5f5f5;
  border-radius: 4px;
  display: block;
  color: #333;
}

hr {
  width: 100%;
  border: none;
  border-bottom: 1px solid #D4D8DC;
}

.poker {
  position: absolute;
  bottom: 0;
}

::placeholder {
  color: #E5E5E6;
}
.ladder_aimcor_compare__cost-panel-styles__cost-panel {
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: 10.0px 0;
  background-color: #EFEAE1;
  border-radius: 20px;
  text-align: center;
  height: 100%;
}

.ladder_aimcor_compare__cost-panel-styles__cost-panel .ladder_aimcor_compare__cost-panel-styles__daily-price {
  margin: 5.0px 10.0px;
}

.ladder_aimcor_compare__cost-panel-styles__cost-panel .ladder_aimcor_compare__cost-panel-styles__cta {
  border-top: dashed 1px #D9D9D9;
  padding-top: 10.0px;
  margin-top: 10.0px;
}
.ladder_account_next_views_gift__gift-caption-styles__root {
  padding: 15px 0;
}

.ladder_account_next_views_gift__gift-caption-styles__icon {
  max-width: 128px;
}
.ladder_financial_advisors_laddering__make-life-insurance-interactive-styles__\% {
  padding: 60px 0;
}
.ladder_financial_advisors_home__flexible-coverage-body-styles__\% {
  margin-top: 30px;
}

@media (min-width: 1025px) {

  
  
  .ladder_financial_advisors_home__flexible-coverage-body-styles__\% {
    margin-top: 20px;
  }

}
.ladder_megaphone_refer__ReferralPanel-styles__root-container {
  display: flex;
  flex-direction: row;
}

.ladder_megaphone_refer__ReferralPanel-styles__referral-header {
  font-size: 40px;
  font-weight: 600;
}

.ladder_megaphone_refer__ReferralPanel-styles__referral-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 20px;
}

.ladder_megaphone_refer__ReferralPanel-styles__rungs-image {
  display: block;
  max-width: 20vw;
  width: auto;
  height: auto;
}

.ladder_megaphone_refer__ReferralPanel-styles__full-width {
  width: 100%;
}

.ladder_megaphone_refer__ReferralPanel-styles__icons-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.ladder_megaphone_refer__ReferralPanel-styles__referral-terms {
  font-size: 12px;
  margin-top: 50.0px;
}

@media (min-width: 1025px) {

  
  
  .ladder_megaphone_refer__ReferralPanel-styles__icons-container {
    margin-left: 50.0px;
  }
  
  .ladder_megaphone_refer__ReferralPanel-styles__text-content {
    margin-right: 60px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_megaphone_refer__ReferralPanel-styles__icons-container {
    margin-left: 50.0px;
  }
  
  .ladder_megaphone_refer__ReferralPanel-styles__text-content {
    margin-right: 60px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_megaphone_refer__ReferralPanel-styles__referral-terms {
    text-align: center;
    margin-top: 30.0px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_megaphone_refer__ReferralPanel-styles__root-container {
    flex-direction: column;
    align-items: center;
  }
  
  .ladder_megaphone_refer__ReferralPanel-styles__referral-header {
    font-size: 30.0px;
    margin-top: 30.0px;
  }
  
  .ladder_megaphone_refer__ReferralPanel-styles__rungs-image {
    max-width: 200px;
    margin-top: 30.0px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .ladder_megaphone_refer__ReferralPanel-styles__referral-content {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  
  .ladder_megaphone_refer__ReferralPanel-styles__referral-terms {
    text-align: center;
    margin-top: 30.0px;
  }

}
.ladder_markdoc_common__two-col-row-comp-styles__row {
  display: flex;
  justify-content: space-between;
}

.ladder_markdoc_common__two-col-row-comp-styles__wrapper {

}

@media (max-width: 499px) {

  
  
  .ladder_markdoc_common__two-col-row-comp-styles__row {
    flex-direction: column;
  }

}
@media (min-width: 1025px) {

  
  
  .ladder_account_next_views_account_documents__AdditionalDocumentsCardWrapper-styles__title {
    margin-left: 20px;
    margin-bottom: 30.0px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account_next_views_account_documents__AdditionalDocumentsCardWrapper-styles__title {
    margin-left: 20px;
    margin-bottom: 30.0px;
  }

}
.ladder_landing_pages_so_good_basics__fact-styles__fact {
  font-size: 24px;
  line-height: 35px;
}

@media (max-width: 499px) {

  
  
  .ladder_landing_pages_so_good_basics__fact-styles__fact {
    font-size: 18px;
    line-height: 30px;
  }

}
.ladder_ux_three_attributes_card__three-attributes-card-styles__card {
  display: flex;
  flex-direction: column;
  width: 88%;
  max-width: 1400px;
  padding: 64px;
  margin: 0 auto;
}

.ladder_ux_three_attributes_card__three-attributes-card-styles__card-less-bottom-padding {
  padding-bottom: 32px;
}

.ladder_ux_three_attributes_card__three-attributes-card-styles__card-opal {
  background: #CFDCDE;
}

.ladder_ux_three_attributes_card__three-attributes-card-styles__title {
  text-align: center;
  margin-bottom: 32px;
}

.ladder_ux_three_attributes_card__three-attributes-card-styles__items {
  display: flex;
  flex-direction: row;
}

.ladder_ux_three_attributes_card__three-attributes-card-styles__items .ladder_ux_three_attributes_card__three-attributes-card-styles__item {
  display: flex;
  flex-direction: row;
  margin-bottom: 32px;
}

.ladder_ux_three_attributes_card__three-attributes-card-styles__items .ladder_ux_three_attributes_card__three-attributes-card-styles__item .ladder_ux_three_attributes_card__three-attributes-card-styles__icon {
  margin: 8.0px;
}

.ladder_ux_three_attributes_card__three-attributes-card-styles__items .ladder_ux_three_attributes_card__three-attributes-card-styles__item .ladder_ux_three_attributes_card__three-attributes-card-styles__text {
  margin: 8.0px;
}

.ladder_ux_three_attributes_card__three-attributes-card-styles__cta {
  display: block;
  width: 30%;
  margin: 0 auto;
  margin-top: 16px;
}

.ladder_ux_three_attributes_card__three-attributes-card-styles__asterisk-text {
  margin-top: 32px;
  text-align: center;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_three_attributes_card__three-attributes-card-styles__items {
    flex-direction: column;
  }
  
  
  
  .ladder_ux_three_attributes_card__three-attributes-card-styles__items .ladder_ux_three_attributes_card__three-attributes-card-styles__item .ladder_ux_three_attributes_card__three-attributes-card-styles__text-center {
    display: flex;
    align-items: center;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_three_attributes_card__three-attributes-card-styles__items {
    flex-direction: column;
  }
  
  
  
  .ladder_ux_three_attributes_card__three-attributes-card-styles__items .ladder_ux_three_attributes_card__three-attributes-card-styles__item .ladder_ux_three_attributes_card__three-attributes-card-styles__text-center {
    display: flex;
    align-items: center;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_three_attributes_card__three-attributes-card-styles__card {
    padding: 32px;
  }
  
  
  
  
  
  .ladder_ux_three_attributes_card__three-attributes-card-styles__items .ladder_ux_three_attributes_card__three-attributes-card-styles__item .ladder_ux_three_attributes_card__three-attributes-card-styles__icon {
    margin: 4.0px;
  }
  
  .ladder_ux_three_attributes_card__three-attributes-card-styles__items .ladder_ux_three_attributes_card__three-attributes-card-styles__item .ladder_ux_three_attributes_card__three-attributes-card-styles__text {
    margin: 4.0px;
  }
  
  .ladder_ux_three_attributes_card__three-attributes-card-styles__cta {
    width: 70%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_three_attributes_card__three-attributes-card-styles__card {
    padding: 16px;
  }
  
  
  
  
  
  .ladder_ux_three_attributes_card__three-attributes-card-styles__items .ladder_ux_three_attributes_card__three-attributes-card-styles__item .ladder_ux_three_attributes_card__three-attributes-card-styles__icon {
    margin: 4.0px;
  }
  
  .ladder_ux_three_attributes_card__three-attributes-card-styles__items .ladder_ux_three_attributes_card__three-attributes-card-styles__item .ladder_ux_three_attributes_card__three-attributes-card-styles__text {
    margin: 4.0px;
  }
  
  .ladder_ux_three_attributes_card__three-attributes-card-styles__cta {
    width: 100%;
  }

}
.ladder_account_next_views_messages__ThreadList-styles__thread-list {
  width: 100%;
}

.ladder_account_next_views_messages__ThreadList-styles__thread-card {
  width: 100%;
}
.ladder_onboarding_spouse_connect_2__katie-signature-styles__root {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.ladder_onboarding_spouse_connect_2__katie-signature-styles__short {
  max-height: 25px;
}

@media (max-width: 499px) {

  
  
  .ladder_onboarding_spouse_connect_2__katie-signature-styles__root {
    flex-direction: column;
    align-items: flex-start;
  }

}
.ladder_ny_form_assessment_factor_search__autocomplete-styles__container {
  display: flex;
  flex-flow: row wrap;
  padding: 5.0px;
  min-height: 48px;
  box-sizing: border-box;
  border: 1px solid #D0D0D0;
  cursor: text;
}

.ladder_ny_form_assessment_factor_search__autocomplete-styles__selected-item {
  display: flex;
  align-items: center;
  padding: 10.0px 10.0px;
  margin: 2px;
  height: 32px;
  border-radius: 16px;
  box-sizing: border-box;
  background-color: #F4F4F4;
}

.ladder_ny_form_assessment_factor_search__autocomplete-styles__selected-item-label {
  max-width: 240px;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  top: -1px;
  text-overflow: ellipsis;
}

.ladder_ny_form_assessment_factor_search__autocomplete-styles__remove-button {
  cursor: pointer;
  padding-left: 5.0px;
  width: 16px;
  height: 16px;
}

.ladder_ny_form_assessment_factor_search__autocomplete-styles__input-container {
  margin-left: 2px;
  flex-grow: 1;
  width: auto;
  max-width: 100%;
}

.ladder_ny_form_assessment_factor_search__autocomplete-styles__input {
  padding: 10.0px 0;
  font: inherit;
  flex-grow: 1;
  line-height: 18px;
  border: 0;
  width: 100%;
  min-width: 20px;
}

.ladder_ny_form_assessment_factor_search__autocomplete-styles__input::placeholder {
  color: #707070;
}

.ladder_ny_form_assessment_factor_search__autocomplete-styles__autocomplete-root {
  position: relative;
  width: 100%;
  height: 0px;
}

.ladder_ny_form_assessment_factor_search__autocomplete-styles__autocomplete {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  background-color: #F4F4F4;
  border: 1px solid #D0D0D0;
  box-sizing: border-box;
  z-index: 1;
}

.ladder_ny_form_assessment_factor_search__autocomplete-styles__autocomplete-option {
  padding: 10.0px;
  border-bottom: 1px solid #D0D0D0;
  margin-bottom: -1px;
  cursor: pointer;
  outline: none;
}

.ladder_ny_form_assessment_factor_search__autocomplete-styles__autocomplete-option:hover {
  background-color: #707070;
}

.ladder_ny_form_assessment_factor_search__autocomplete-styles__autocomplete-option.ladder_ny_form_assessment_factor_search__autocomplete-styles__selected {
  background-color: #D0D0D0;
}

.ladder_ny_form_assessment_factor_search__autocomplete-styles__autocomplete-option.ladder_ny_form_assessment_factor_search__autocomplete-styles__selected:hover {
  background-color: #707070;
}

.ladder_ny_form_assessment_factor_search__autocomplete-styles__no-results {
  padding: 10.0px;
  background-color: #FE4747;
}

.ladder_ny_form_assessment_factor_search__autocomplete-styles__autocomplete-hidden {
  display: none;
}

.ladder_ny_form_assessment_factor_search__autocomplete-styles__rungs {
  text-transform: lowercase;
}

.ladder_ny_form_assessment_factor_search__autocomplete-styles__rungs .ladder_ny_form_assessment_factor_search__autocomplete-styles__container {
  border-color: #CBCBCB;
  padding: 15.0px;
  align-items: center;
}

.ladder_ny_form_assessment_factor_search__autocomplete-styles__rungs .ladder_ny_form_assessment_factor_search__autocomplete-styles__selected-item {
  border-radius: 0;
  padding-right: 0;
}

.ladder_ny_form_assessment_factor_search__autocomplete-styles__rungs .ladder_ny_form_assessment_factor_search__autocomplete-styles__selected-item-label {
  top: 0;
}

.ladder_ny_form_assessment_factor_search__autocomplete-styles__rungs .ladder_ny_form_assessment_factor_search__autocomplete-styles__autocomplete {
  background-color: #FFFFFF;
}

.ladder_ny_form_assessment_factor_search__autocomplete-styles__rungs .ladder_ny_form_assessment_factor_search__autocomplete-styles__autocomplete-option:hover {
  background-color: #D0D0D0;
}

.ladder_ny_form_assessment_factor_search__autocomplete-styles__rungs .ladder_ny_form_assessment_factor_search__autocomplete-styles__selected-item-label::first-letter {
  text-transform: uppercase;
}

.ladder_ny_form_assessment_factor_search__autocomplete-styles__rungs .ladder_ny_form_assessment_factor_search__autocomplete-styles__autocomplete-option::first-letter {
  text-transform: uppercase;
}

.ladder_ny_form_assessment_factor_search__autocomplete-styles__rungs .ladder_ny_form_assessment_factor_search__autocomplete-styles__remove-button {
  height: 32px;
  width: 32px;
  padding: 10.0px;
  margin-left: 10.0px;
  fill: #000000;
}

.ladder_ny_form_assessment_factor_search__autocomplete-styles__rungs .ladder_ny_form_assessment_factor_search__autocomplete-styles__remove-button:hover {
  background-color: #FE4747;
  fill: #FFFFFF;
}

.ladder_ny_form_assessment_factor_search__autocomplete-styles__rungs .ladder_ny_form_assessment_factor_search__autocomplete-styles__input {
  font-size: 16px;
}

.ladder_ny_form_assessment_factor_search__autocomplete-styles__rungs-colors .ladder_ny_form_assessment_factor_search__autocomplete-styles__selected-item {
  background-color: #F4F1ED;
}

.ladder_ny_form_assessment_factor_search__autocomplete-styles__rungs-colors .ladder_ny_form_assessment_factor_search__autocomplete-styles__autocomplete-option:hover {
  background-color: #D8BCAF;
}

.ladder_ny_form_assessment_factor_search__autocomplete-styles__rungs-colors .ladder_ny_form_assessment_factor_search__autocomplete-styles__autocomplete-option.ladder_ny_form_assessment_factor_search__autocomplete-styles__selected {
  background-color: #D8BCAF;
}

.ladder_ny_form_assessment_factor_search__autocomplete-styles__rungs-colors .ladder_ny_form_assessment_factor_search__autocomplete-styles__remove-button:hover {
  background-color: #E26767;
}
.ladder_laddering_page__total-savings-styles__root {
  display: flex;
  flex-direction: column;
  height: auto;
  overflow: hidden;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
}

.ladder_laddering_page__total-savings-styles__top-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 5vh 0;
  max-width: 2100px;
  width: 95%;
}

.ladder_laddering_page__total-savings-styles__bottom-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 5vh 0;
  max-width: 2100px;
  width: 95%;
}

.ladder_laddering_page__total-savings-styles__sentences-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 30.0px;
}

.ladder_laddering_page__total-savings-styles__sentence-container {
  display: flex;
  height: auto;
  flex-direction: row;
  margin: 5.0px 0;
}

.ladder_laddering_page__total-savings-styles__sentence {
  display: flex;
  flex-grow: 1;
  margin: 0 0 20px 20px;
}

.ladder_laddering_page__total-savings-styles__total-savings-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  flex-grow: 1;
}

.ladder_laddering_page__total-savings-styles__total-savings-box {
  display: flex;
  background-color: #EFEAE1;
  border: 2px solid #975C46;
  max-height: 300px;
  height: 300px;
}

.ladder_laddering_page__total-savings-styles__total-savings-calculation-box {
  width: 100%;
  max-width: 560px;
  justify-content: center;
  height: 240px;
  border: 2px solid #3B3B3B;
  align-items: center;
  flex-direction: column;
  margin-left: 20px;
  display: flex;
}

.ladder_laddering_page__total-savings-styles__calculation-input-box {
  display: flex;
  flex-direction: column;
  width: 760px;
  height: auto;
  margin: 0 20px;
}

.ladder_laddering_page__total-savings-styles__calculation-cta-container {
  display: flex;
  flex-direction: column;
  max-width: 560px;
  margin-left: 20px;
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.ladder_laddering_page__total-savings-styles__calculation-cta {
  width: 30%;
}

.ladder_laddering_page__total-savings-styles__calculation-cta-text {
  margin-top: 50.0px;
  margin-bottom: 30.0px;
}

.ladder_laddering_page__total-savings-styles__calculation-container-hide {
  visibility: hidden;
  opacity: 0;
}

.ladder_laddering_page__total-savings-styles__input-container {
  display: flex;
  margin: 50.0px 0 0 0;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.ladder_laddering_page__total-savings-styles__input {
  flex-grow: 1;
  margin-right: 10.0px;
}

.ladder_laddering_page__total-savings-styles__icon {
  display: flex;
  width: 22px;
  height: 22px;
}

.ladder_laddering_page__total-savings-styles__button {
  flex-grow: 2;
  max-width: 250px;
  margin: 60px 0;
}

.ladder_laddering_page__total-savings-styles__total-savings-text {
  text-align: center;
  align-self: center;
  width: 100%;
}

.ladder_laddering_page__total-savings-styles__right-column {
  display: flex;
  max-width: 800px;
  width: 40%;
  margin-left: 20px;
  flex-direction: column;
  justify-content: space-between;
}

.ladder_laddering_page__total-savings-styles__hr {
  max-width: 1650px;
  width: 90%;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_laddering_page__total-savings-styles__top-container {
    flex-direction: column-reverse;
    margin: 30.0px 0 0 0;
  }
  
  .ladder_laddering_page__total-savings-styles__total-savings-box {
    width: 100%;
  }
  
  .ladder_laddering_page__total-savings-styles__total-savings-container {
    width: 100%;
    max-width: 700px;
  }
  
  .ladder_laddering_page__total-savings-styles__bottom-container {
    flex-direction: column-reverse;
    margin: 60px 0 30.0px 0;
  }
  
  .ladder_laddering_page__total-savings-styles__right-column {
    width: 95%;
    max-width: 600px;
    height: 200px;
    margin-left: unset;
    margin-bottom: 30.0px;
  }
  
  .ladder_laddering_page__total-savings-styles__calculation-input-box {
    width: 90%;
    max-width: 700px;
    margin: unset;
  }
  
  .ladder_laddering_page__total-savings-styles__calculation-cta-container {
    margin-left: 0px;
    width: 95%;
  }
  
  .ladder_laddering_page__total-savings-styles__calculation-cta {
    width: 100%;
    margin-bottom: 20px;
  }
  
  .ladder_laddering_page__total-savings-styles__calculation-cta-text {
    margin-top: 0;
    margin-bottom: 30.0px;
  }
  
  .ladder_laddering_page__total-savings-styles__calculation-container-hide {
    display: none;
  }
  
  .ladder_laddering_page__total-savings-styles__total-savings-calculation-box {
    max-width: 600px;
    width: 95%;
    margin-left: unset;
    margin-bottom: 30.0px;
  }
  
  .ladder_laddering_page__total-savings-styles__sentences-container {
    max-width: 600px;
    width: 90%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_laddering_page__total-savings-styles__top-container {
    flex-direction: column-reverse;
    margin: 30.0px 0 0 0;
  }
  
  .ladder_laddering_page__total-savings-styles__total-savings-box {
    width: 100%;
  }
  
  .ladder_laddering_page__total-savings-styles__total-savings-container {
    width: 100%;
    max-width: 700px;
  }
  
  .ladder_laddering_page__total-savings-styles__bottom-container {
    flex-direction: column-reverse;
    margin: 60px 0 30.0px 0;
  }
  
  .ladder_laddering_page__total-savings-styles__right-column {
    width: 95%;
    max-width: 600px;
    height: 200px;
    margin-left: unset;
    margin-bottom: 30.0px;
  }
  
  .ladder_laddering_page__total-savings-styles__calculation-input-box {
    width: 90%;
    max-width: 700px;
    margin: unset;
  }
  
  .ladder_laddering_page__total-savings-styles__calculation-cta-container {
    margin-left: 0px;
    width: 95%;
  }
  
  .ladder_laddering_page__total-savings-styles__calculation-cta {
    width: 100%;
    margin-bottom: 20px;
  }
  
  .ladder_laddering_page__total-savings-styles__calculation-cta-text {
    margin-top: 0;
    margin-bottom: 30.0px;
  }
  
  .ladder_laddering_page__total-savings-styles__calculation-container-hide {
    display: none;
  }
  
  .ladder_laddering_page__total-savings-styles__total-savings-calculation-box {
    max-width: 600px;
    width: 95%;
    margin-left: unset;
    margin-bottom: 30.0px;
  }
  
  .ladder_laddering_page__total-savings-styles__sentences-container {
    max-width: 600px;
    width: 90%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_laddering_page__total-savings-styles__bottom-container {
    margin: 0 0 30.0px 0;
  }
  
  .ladder_laddering_page__total-savings-styles__total-savings-calculation-box {
    height: 150px;
  }
  
  .ladder_laddering_page__total-savings-styles__calculation-input-box {
    max-width: 650px;
    width: 95%;
    margin: 30.0px 0;
  }
  
  .ladder_laddering_page__total-savings-styles__input-container {
    flex-direction: column;
    margin: 20px 0 0 0;
  }
  
  .ladder_laddering_page__total-savings-styles__input {
    margin: 10.0px 0 20px 0;
  }
  
  .ladder_laddering_page__total-savings-styles__button {
    max-width: unset;
    margin: 20px 0 0 0;
  }

}
.ladder_text__section-title-styles__root {
  margin-top: 32px;
  margin-bottom: 8px;
}
.ladder_gift_promo__sticky-cta-button-styles__root {
  display: none;
  height: 0;
  width: 100%;
  position: sticky;
  top: 100%;
  margin: 200vh 0 -200vh;
}

.ladder_gift_promo__sticky-cta-button-styles__button-wrapper {
  margin: calc(-60px - env(safe-area-inset-bottom)) 5% 0;
}

@media (max-width: 499px) {

  
  
  .ladder_gift_promo__sticky-cta-button-styles__root {
    display: unset;
  }

}
.ladder_agents_agent_assisted_app_views_decision__decline-reasons-paragraph-styles__list {
  list-style: outside decimal;
  padding-left: 1em;
  font-family: Montserrat, sans-serif;
  font-size: 20px;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_agent_assisted_app_views_decision__decline-reasons-paragraph-styles__list {
    font-size: 15px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_agent_assisted_app_views_decision__decline-reasons-paragraph-styles__list {
    font-size: 15px;
  }

}
@media (max-width: 499px) {

  
  
  .ladder_account__additional-document-entry-styles__rungs-link {
    margin-left: 20px;
  }

}
@media (min-width: 1025px) {

  
  
  .ladder_apply_honesty_statement__pre-review-honesty-statement\*-styles__root {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 130.0px;
    gap: 80px;
  }
  
  .ladder_apply_honesty_statement__pre-review-honesty-statement\*-styles__no-illustrations-root {
    justify-content: center;
    text-align: center;
  }
  
  .ladder_apply_honesty_statement__pre-review-honesty-statement\*-styles__content {
    display: flex;
    width: 70%;
    flex-direction: column;
    gap: 25.0px;
  }
  
  .ladder_apply_honesty_statement__pre-review-honesty-statement\*-styles__no-illustrations-content {
    align-items: center;
  }
  
  .ladder_apply_honesty_statement__pre-review-honesty-statement\*-styles__button {
    max-width: 260px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_apply_honesty_statement__pre-review-honesty-statement\*-styles__root {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 130.0px;
    gap: 80px;
  }
  
  .ladder_apply_honesty_statement__pre-review-honesty-statement\*-styles__no-illustrations-root {
    justify-content: center;
    text-align: center;
  }
  
  .ladder_apply_honesty_statement__pre-review-honesty-statement\*-styles__content {
    display: flex;
    width: 70%;
    flex-direction: column;
    gap: 25.0px;
  }
  
  .ladder_apply_honesty_statement__pre-review-honesty-statement\*-styles__no-illustrations-content {
    align-items: center;
  }
  
  .ladder_apply_honesty_statement__pre-review-honesty-statement\*-styles__button {
    max-width: 260px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_apply_honesty_statement__pre-review-honesty-statement\*-styles__root {
    gap: 60px;
  }
  
  .ladder_apply_honesty_statement__pre-review-honesty-statement\*-styles__content {
    width: 60%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_apply_honesty_statement__pre-review-honesty-statement\*-styles__root {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    gap: 60px;
    width: 100%;
  }
  
  .ladder_apply_honesty_statement__pre-review-honesty-statement\*-styles__content {
    display: flex;
    flex-direction: column;
    gap: 25.0px;
  }

}
.ladder_onboarding_spouse_connections__SpouseInviteModal-styles__text {
  margin-top: 30.0px;
}

.ladder_onboarding_spouse_connections__SpouseInviteModal-styles__title {
  position: absolute;
  left: 0;
  top: 0;
  padding-top: 25.0px;
  padding-left: 5%;
}

@media (min-width: 1025px) {

  
  
  .ladder_onboarding_spouse_connections__SpouseInviteModal-styles__buttons {
    display: flex;
    margin-top: 50.0px;
  }
  
  .ladder_onboarding_spouse_connections__SpouseInviteModal-styles__buttons>*:last-child {
    margin-left: 25.0px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_onboarding_spouse_connections__SpouseInviteModal-styles__buttons {
    display: flex;
    margin-top: 50.0px;
  }
  
  .ladder_onboarding_spouse_connections__SpouseInviteModal-styles__buttons>*:last-child {
    margin-left: 25.0px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_onboarding_spouse_connections__SpouseInviteModal-styles__buttons {
    margin-top: 40px;
  }
  
  .ladder_onboarding_spouse_connections__SpouseInviteModal-styles__buttons>*:last-child {
    margin-top: 15.0px;
  }

}
.ladder_agents_agent_assisted_app_views_review__agreement-section-styles__root {
  max-width: 600px;
  width: 100%;
  margin: 0 10.0px;
  padding: 0 10.0px;
}

.ladder_agents_agent_assisted_app_views_review__agreement-section-styles__title {
  margin-top: 10.0px;
  text-align: left;
}

.ladder_agents_agent_assisted_app_views_review__agreement-section-styles__subtext-description {
  text-align: left;
  margin: 20px 0;
  padding-bottom: 30px;
}

.ladder_agents_agent_assisted_app_views_review__agreement-section-styles__subtext {
  text-align: left;
  margin: 10.0px 0;
}

.ladder_agents_agent_assisted_app_views_review__agreement-section-styles__markdown {

}

@media (max-width: 499px) {

  
  
  .ladder_agents_agent_assisted_app_views_review__agreement-section-styles__root {
    width: 91%;
    margin: 0 10.0px;
  }
  
  .ladder_agents_agent_assisted_app_views_review__agreement-section-styles__title {
    margin: 10.0px 8px;
  }
  
  .ladder_agents_agent_assisted_app_views_review__agreement-section-styles__subtext {
    margin: 10.0px 8px;
  }
  
  .ladder_agents_agent_assisted_app_views_review__agreement-section-styles__markdown {
    margin: 10.0px 8px;
  }
  
  .ladder_agents_agent_assisted_app_views_review__agreement-section-styles__expandable-review {
    padding: 0;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_agent_assisted_app_views_review__agreement-section-styles__root {
    width: 90%;
    margin: 0 26px;
  }
  
  .ladder_agents_agent_assisted_app_views_review__agreement-section-styles__title {
    margin: 0;
  }
  
  .ladder_agents_agent_assisted_app_views_review__agreement-section-styles__subtext {
    margin: 0;
  }
  
  .ladder_agents_agent_assisted_app_views_review__agreement-section-styles__markdown {
    margin: 0;
  }

}

.ladder_agents_agent_assisted_app_views_review__agreement-section-styles__expandable-review {
  max-width: 800px;
  width: 90vw;
  margin: auto;
}
.ladder_claims__header-styles__icon-container {
  display: flex;
  flex-direction: row;
  text-align: center;
  width: 100%;
}

.ladder_claims__header-styles__icon {
  margin: 60px auto 0 auto;
  max-width: 306px;
}

.ladder_claims__header-styles__padded-icon {
  margin: -13px 0;
}

.ladder_claims__header-styles__disclaimer {
  text-align: right;
}

@media (max-width: 499px) {

  
  
  .ladder_claims__header-styles__disclaimer {
    text-align: center;
  }
  
  .ladder_claims__header-styles__icon-container {
    flex-direction: column;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_claims__header-styles__disclaimer {
    text-align: center;
  }
  
  .ladder_claims__header-styles__icon-container {
    flex-direction: column;
  }

}
.ladder_ux_alert_card__failure-alert-card-styles__box-n {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  border: 4px solid #CB3A3A;
  flex-direction: row;
  position: relative;
  padding: 48px 48px;
  gap: 25px;
}

.ladder_ux_alert_card__failure-alert-card-styles__image-container {
  height: 200px;
  display: flex;
  flex-direction: column;
}

.ladder_ux_alert_card__failure-alert-card-styles__component-image {
  width: 170px;
}

.ladder_ux_alert_card__failure-alert-card-styles__error-header {
  font-size: 32px;
}

.ladder_ux_alert_card__failure-alert-card-styles__error-content {
  font-size: 16px;
}

.ladder_ux_alert_card__failure-alert-card-styles__error-icon {
  height: 40px;
  width: 40px;
}

.ladder_ux_alert_card__failure-alert-card-styles__full-width {
  width: 100%;
}

.ladder_ux_alert_card__failure-alert-card-styles__content-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  line-height: 2.0em;
  gap: 20px;
}

.ladder_ux_alert_card__failure-alert-card-styles__content-row {
  flex: 1;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  align-items: center;
}

.ladder_ux_alert_card__failure-alert-card-styles__footer {
  justify-content: flex-end;
  flex-wrap: nowrap;
}

.ladder_ux_alert_card__failure-alert-card-styles__simple-inline {
  display: inline;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_alert_card__failure-alert-card-styles__box-n {
    padding: 12.0px 12.0px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_alert_card__failure-alert-card-styles__box-n {
    padding: 12.0px 12.0px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_alert_card__failure-alert-card-styles__error-header {
    font-size: 20.0px;
  }
  
  .ladder_ux_alert_card__failure-alert-card-styles__footer {
    flex-direction: column-reverse;
  }
  
  .ladder_ux_alert_card__failure-alert-card-styles__error-icon {
    height: 30px;
    width: 30px;
  }
  
  .ladder_ux_alert_card__failure-alert-card-styles__button-container {
    width: 100%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_alert_card__failure-alert-card-styles__error-header {
    font-size: 24.0px;
  }

}
.ladder_aimcor_form__time-slot-styles__wrapper {
  display: flex;
  justify-content: center;
  padding: 2.5px 0;
  border: solid 1px #5D5D5D;
  border-radius: 8px;
  cursor: pointer;
  width: 168px;
}

.ladder_aimcor_form__time-slot-styles__wrapper.ladder_aimcor_form__time-slot-styles__selected {
  border-color: #D1A98F;
  background-color: #D1A98F;
}

.ladder_aimcor_form__time-slot-styles__wrapper.ladder_aimcor_form__time-slot-styles__themed.ladder_aimcor_form__time-slot-styles__selected {
  color: #FFFFFF;
  border-color: #000000;
  background-color: #000000;
}

input[type='radio'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  opacity: 0;
  position: absolute;
  height: 1px;
  width: 1px;
}

@media (min-width: 1025px) {

  
  
  .ladder_aimcor_form__time-slot-styles__wrapper {
    width: 168px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_aimcor_form__time-slot-styles__wrapper {
    width: 168px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_aimcor_form__time-slot-styles__wrapper {
    width: 135px;
    margin-bottom: 15.0px;
  }

}
.ladder_ux_carousel__transition-styles__root {
  width: 100%;
  position: relative;
}

.ladder_ux_carousel__transition-styles__img {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 1s ease-in-out;
}

.ladder_ux_carousel__transition-styles__img:first-child {
  position: static;
}

.ladder_ux_carousel__transition-styles__fast-transition {
  transition: opacity 325ms ease-in-out;
}

.ladder_ux_carousel__transition-styles__full-width {
  width: 100%;
}

.ladder_ux_carousel__transition-styles__opaque {
  opacity: 1;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_carousel__transition-styles__wrapper {
    width: 500%;
  }

}
.ladder_apply_ny_current_page__current-page-ui-styles__root {
  width: 100%;
}

.ladder_apply_ny_current_page__current-page-ui-styles__root-top-padding {
  padding-top: 48px;
}

.ladder_apply_ny_current_page__current-page-ui-styles__quick-start-padding {
  padding-top: 20px;
}

.ladder_apply_ny_current_page__current-page-ui-styles__almost-there-banner-margin {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 50.0px 0 0 0;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_apply_ny_current_page__current-page-ui-styles__root-top-padding {
    padding-top: 80px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_apply_ny_current_page__current-page-ui-styles__root-top-padding {
    padding-top: 0px;
  }

}

.ladder_apply_ny_current_page__current-page-ui-styles__top-padding-none {
  padding-top: 0px;
}

.ladder_apply_ny_current_page__current-page-ui-styles__hidden {
  display: none;
}

.ladder_apply_ny_current_page__current-page-ui-styles__sticky-review-button {
  position: sticky;
  bottom: 40px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
}

.ladder_apply_ny_current_page__current-page-ui-styles__sticky-go-to-question-button {
  position: sticky;
  bottom: 40px;
  width: 100%;
  margin-bottom: 20px;
}

.ladder_apply_ny_current_page__current-page-ui-styles__scrollable-overflow-x-hidden {
  overflow-x: hidden;
}

.ladder_apply_ny_current_page__current-page-ui-styles__app-content-container {
  padding: 20px 0;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_apply_ny_current_page__current-page-ui-styles__app-content-container {
    padding: 20px 0;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_apply_ny_current_page__current-page-ui-styles__app-content-container {
    padding: 10.0px 0;
  }
  
  .ladder_apply_ny_current_page__current-page-ui-styles__mobile-fixed-bottom {
    opacity: 1;
    position: fixed;
    bottom: 0;
    background-color: #FFFFFF;
    box-shadow: 0 -2px 4px 2px rgba(0, 0, 0, 0.15);
    z-index: 2;
    width: 100%;
  }

}

.ladder_apply_ny_current_page__current-page-ui-styles__captioned-group-header {
  max-width: 600px;
  width: 100%;
}

.ladder_apply_ny_current_page__current-page-ui-styles__sectioned-app-header {
  max-width: 600px;
  width: 90%;
  margin: 0 auto;
}
.ladder_form_upper_funnel_routing__still-interested-panel-styles__wrapper {

}

.ladder_form_upper_funnel_routing__still-interested-panel-styles__row {
  display: flex;
  margin-bottom: 30.0px;
}

.ladder_form_upper_funnel_routing__still-interested-panel-styles__icon {
  width: 30.0px;
  justify-content: center;
  height: 30.0px;
  margin-right: 10.0px;
  align-items: center;
  padding: 8px;
  background-color: #F7F2EF;
  border-radius: 50%;
  display: flex;
}

.ladder_form_upper_funnel_routing__still-interested-panel-styles__top-row {
  margin-bottom: 15.0px;
}

.ladder_form_upper_funnel_routing__still-interested-panel-styles__bottom-row {
  padding-top: 50.0px;
  border-top: solid 0.5px;
  border-color: #D9D9D9;
}
.ladder_account_next_views_policy__link-that-looks-like-an-unexpanded-account-expand-card-styles__\% {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #D0D0D0;
}

@media (min-width: 1025px) {

  
  
  .ladder_account_next_views_policy__link-that-looks-like-an-unexpanded-account-expand-card-styles__\% {
    padding: 32px 0px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account_next_views_policy__link-that-looks-like-an-unexpanded-account-expand-card-styles__\% {
    padding: 32px 0px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_account_next_views_policy__link-that-looks-like-an-unexpanded-account-expand-card-styles__\% {
    padding: 22px 0px;
  }

}
.ladder_agents_dashboard_uxcommon__profile-edit-error-styles__profile-edit-error-text {
  display: flex;
  width: 100%;
  justify-content: center;
}
.ladder_ny_form_authorizations__form-number-footer-styles__footer-container {
  width: 100%;
  text-align: left;
}
.ladder_savings_steps__conditions-styles__conditions {
  width: 100%;
  text-align: left;
}

.ladder_savings_steps__conditions-styles__footnotes {
  width: 100%;
  text-align: left;
}

.ladder_savings_steps__conditions-styles__footnotes li {
  margin-top: 20px;
}

.ladder_savings_steps__conditions-styles__footnotes .ladder_savings_steps__conditions-styles__number-of-steps {
  list-style: '* ';
}

.ladder_savings_steps__conditions-styles__footnotes .ladder_savings_steps__conditions-styles__ten-thousand-steps {
  list-style: '† ';
}

@media (max-width: 499px) {

  
  
  .ladder_savings_steps__conditions-styles__conditions {
    width: 90%;
    margin: auto;
  }
  
  .ladder_savings_steps__conditions-styles__footnotes {
    width: 90%;
    margin: auto;
  }

}
.ladder_requestion_post_sign_questions__re-question-explanation-page-left-column-styles__root {
  display: flex;
  justify-content: center;
  background-color: #F7F2EF;
  height: 200%;
}

.ladder_requestion_post_sign_questions__re-question-explanation-page-left-column-styles__inner-container {
  width: 65%;
  margin-top: 50px;
}

.ladder_requestion_post_sign_questions__re-question-explanation-page-left-column-styles__image-container {
  display: flex;
  justify-content: center;
}

.ladder_requestion_post_sign_questions__re-question-explanation-page-left-column-styles__image {
  width: 90%;
  margin-top: 50px;
}

@media (max-width: 499px) {

  
  
  .ladder_requestion_post_sign_questions__re-question-explanation-page-left-column-styles__image {
    display: none;
  }
  
  .ladder_requestion_post_sign_questions__re-question-explanation-page-left-column-styles__inner-container {
    width: 100%;
    margin: 15px;
  }

}
.ladder_agents_agent_assisted_app_views_review__navigation-styles__root {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: calc(50% - 300px);
  position: fixed;
  left: 0;
  top: 0;
}

.ladder_agents_agent_assisted_app_views_review__navigation-styles__wrapper {
  display: flex;
  flex-direction: column;
}

.ladder_agents_agent_assisted_app_views_review__navigation-styles__section {
  border-left: solid 3px;
  padding: 10px;
}

.ladder_agents_agent_assisted_app_views_review__navigation-styles__current-section {
  cursor: default;
  pointer-events: none;
}

.ladder_agents_agent_assisted_app_views_review__navigation-styles__other-section {
  color: #BDBDBD;
}

.ladder_agents_agent_assisted_app_views_review__navigation-styles__icon-container {
  display: flex;
  flex-grow: 1;
  justify-content: left;
  align-items: center;
}
@media (min-width: 1025px) {

  
  
  .ladder_account_next_cards_base__card-styles__\% {
    border: 1px solid #707070;
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    padding: 16px;
  }
  
  .ladder_account_next_cards_base__card-styles__\%.ladder_account_next_cards_base__card-styles__mobile-only {
    display: contents;
  }
  
  .ladder_account_next_cards_base__card-styles__mobile-bar {
    display: none;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account_next_cards_base__card-styles__\% {
    border: 1px solid #707070;
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    padding: 16px;
  }
  
  .ladder_account_next_cards_base__card-styles__\%.ladder_account_next_cards_base__card-styles__mobile-only {
    display: contents;
  }
  
  .ladder_account_next_cards_base__card-styles__mobile-bar {
    display: none;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_account_next_cards_base__card-styles__\% {
    margin-top: 32px;
  }
  
  .ladder_account_next_cards_base__card-styles__mobile-bar {
    margin-top: 8px;
    border-bottom: 1px solid #D0D0D0;
  }

}
.ladder_ux_trustpilot__TrustpilotWidgetSmall-styles__container {
  display: flex;
  max-width: 100%;
  flex-direction: column;
  align-items: flex-start;
}

.ladder_ux_trustpilot__TrustpilotWidgetSmall-styles__top {
  display: flex;
}

.ladder_ux_trustpilot__TrustpilotWidgetSmall-styles__trustpilot-stars {
  height: 32px;
  margin: 5px 0;
}

.ladder_ux_trustpilot__TrustpilotWidgetSmall-styles__trustpilot-score {
  height: 14px;
}

.ladder_ux_trustpilot__TrustpilotWidgetSmall-styles__inverted-colors {
  filter: invert(1);
}

.ladder_ux_trustpilot__TrustpilotWidgetSmall-styles__trustpilot-logo {
  height: 32px;
}
.ladder_financial_advisors_dashboard__metric-card-styles__root {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.ladder_financial_advisors_dashboard__metric-card-styles__root:last-child {
  border-bottom: 1px solid #D0D0D0;
}

.ladder_financial_advisors_dashboard__metric-card-styles__border-top {
  border-top: 1px solid #D0D0D0;
}

.ladder_financial_advisors_dashboard__metric-card-styles__title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15.0px 0;
}

.ladder_financial_advisors_dashboard__metric-card-styles__metric {
  margin-left: auto;
  white-space: nowrap;
}

.ladder_financial_advisors_dashboard__metric-card-styles__metric-text {
  font-size: 150%;
  margin-right: -35px;
}

.ladder_financial_advisors_dashboard__metric-card-styles__metric-item {
  display: inline-block;
  width: 140px;
}

.ladder_financial_advisors_dashboard__metric-card-styles__info-expander-container {
  margin-left: 10.0px;
  margin-right: 20px;
}

@media (max-width: 499px) {

  
  
  .ladder_financial_advisors_dashboard__metric-card-styles__metric-item {
    width: 65px;
  }

}
.ladder_savings_steps_dory__get-app-phone-styles__phone {
  position: relative;
  display: flex;
  width: 65%;
}

.ladder_savings_steps_dory__get-app-phone-styles__phone .ladder_savings_steps_dory__get-app-phone-styles__phone-bg-svg {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}

.ladder_savings_steps_dory__get-app-phone-styles__phone .ladder_savings_steps_dory__get-app-phone-styles__phone-txt {
  width: 100%;
  height: 100%;
}

.ladder_savings_steps_dory__get-app-phone-styles__phone .ladder_savings_steps_dory__get-app-phone-styles__phone-txt>text {
  fill: #000000;
  font-family: Montserrat;
  font-weight: 600;
  letter-spacing: 0em;
}

.ladder_savings_steps_dory__get-app-phone-styles__phone .ladder_savings_steps_dory__get-app-phone-styles__phone-txt .ladder_savings_steps_dory__get-app-phone-styles__subtitle {
  font-size: 6.87288px;
}

.ladder_savings_steps_dory__get-app-phone-styles__phone .ladder_savings_steps_dory__get-app-phone-styles__phone-txt .ladder_savings_steps_dory__get-app-phone-styles__name {
  font-size: 12.8394px;
}

.ladder_savings_steps_dory__get-app-phone-styles__phone .ladder_savings_steps_dory__get-app-phone-styles__phone-txt .ladder_savings_steps_dory__get-app-phone-styles__welcome {
  font-size: 7.48842px;
}

.ladder_savings_steps_dory__get-app-phone-styles__phone .ladder_savings_steps_dory__get-app-phone-styles__phone-txt .ladder_savings_steps_dory__get-app-phone-styles__coverage {
  font-size: 10.9876px;
}

.ladder_savings_steps_dory__get-app-phone-styles__phone .ladder_savings_steps_dory__get-app-phone-styles__phone-txt .ladder_savings_steps_dory__get-app-phone-styles__detail {
  font-size: 12.551px;
}
.ladder_markdoc_common__table-comp-styles__table {
  border-width: 1px;
  width: 100%;
  border-style: solid;
  border-radius: 8px;
  border-color: #E5E5E5;
  border-spacing: 10px;
  border-collapse: separate;
}
.ladder_hdyhau_upper_funnel__survey-styles__button {
  margin: 32px;
}
@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard_referral_link__referral-link-styles__referral-link-container {
    width: 80%;
  }
  
  .ladder_agents_dashboard_referral_link__referral-link-styles__input-wrapper {
    width: 350px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_dashboard_referral_link__referral-link-styles__referral-link-container {
    width: 80%;
  }
  
  .ladder_agents_dashboard_referral_link__referral-link-styles__input-wrapper {
    width: 350px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_agents_dashboard_referral_link__referral-link-styles__input-wrapper {
    width: 600px;
  }

}

.ladder_agents_dashboard_referral_link__referral-link-styles__input-wrapper {
  display: flex;
  align-items: center;
  height: 70px;
  padding: 1em;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 24px;
  position: relative;
}

.ladder_agents_dashboard_referral_link__referral-link-styles__referral-link-container {
  margin-left: 2em;
}

.ladder_agents_dashboard_referral_link__referral-link-styles__unique-tracking-link {
  margin-top: 1em;
  margin-left: 2em;
}

.ladder_agents_dashboard_referral_link__referral-link-styles__referral-link {
  font-size: 15px;
}

.ladder_agents_dashboard_referral_link__referral-link-styles__tracking-link {
  font-size: 13px;
  line-height: 20px;
}
.ladder_onboarding_secondary_addressee__Form-styles__root {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.ladder_onboarding_secondary_addressee__Form-styles__root > * {
  display: flex;
  flex-direction: row;
  margin: 10.0px 0;
}

.ladder_onboarding_secondary_addressee__Form-styles__one-field > * {
  width: 100%;
}

.ladder_onboarding_secondary_addressee__Form-styles__two-fields > :first-child {
  padding-right: 15.0px;
}

.ladder_onboarding_secondary_addressee__Form-styles__fifty-fifty {
  width: 50%;
}

.ladder_onboarding_secondary_addressee__Form-styles__sixty-forty:first-child {
  width: 60%;
}

.ladder_onboarding_secondary_addressee__Form-styles__sixty-forty:last-child {
  width: 40%;
}

.ladder_onboarding_secondary_addressee__Form-styles__seventy-thirty:first-child {
  width: 70%;
}

.ladder_onboarding_secondary_addressee__Form-styles__seventy-thirty:last-child {
  width: 30%;
}

@media (min-width: 1025px) {

  
  
  
  
  .ladder_onboarding_secondary_addressee__Form-styles__buttons > * {
    width: 165px;
    height: 70px;
    margin-right: 15.0px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  
  
  .ladder_onboarding_secondary_addressee__Form-styles__buttons > * {
    width: 165px;
    height: 70px;
    margin-right: 15.0px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_onboarding_secondary_addressee__Form-styles__buttons {
    flex-direction: column;
  }
  
  .ladder_onboarding_secondary_addressee__Form-styles__buttons > * {
    width: 100%;
    margin-top: 15.0px;
  }

}
.ladder_views_next__layout-styles__header-tag {
  overflow: visible;
  z-index: 10;
}

.ladder_views_next__layout-styles__wrapper {
  min-height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  transition: background-color 0.5s;
}

.ladder_views_next__layout-styles__white {
  background-color: #FFFFFF;
}

.ladder_views_next__layout-styles__lighter-gray {
  background-color: #F9F9F9;
}

.ladder_views_next__layout-styles__spring-wood {
  background-color: #F7F2EF;
}

.ladder_views_next__layout-styles__content {
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column;
}

:focus {
  outline: 0;
}

.ladder_views_next__layout-styles__using-keyboard :focus {
  outline: #08f auto 2px;
}

@media (max-width: 499px) {

  
  
  .ladder_views_next__layout-styles__cta-fixed-bottom {
    height: 100px;
  }

}
.ladder_form_single_line__root-styles__root {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.ladder_form_single_line__root-styles__elem {
  flex-grow: 1;
}

.ladder_form_single_line__root-styles__spacer {
  flex-grow: 0.1;
}
.ladder_agents_pass_the_application__birth-location-styles__container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ladder_agents_pass_the_application__birth-location-styles__root-container {
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: flex-start;
}
.ladder_views_api__faq-panel-styles__header {
  margin-bottom: 32px;
}

@media (max-width: 499px) {

  
  
  .ladder_views_api__faq-panel-styles__header {
    margin-bottom: 16px;
  }

}
.ladder_apply_ineligibility_modal__ineligibility-modal-styles__extra-top-space {
  margin-top: 10px;
}

.ladder_apply_ineligibility_modal__ineligibility-modal-styles__extra-bottom-space {
  margin-bottom: 10px;
}

.ladder_apply_ineligibility_modal__ineligibility-modal-styles__btn-center {
  display: flex;
  margin: 0 auto;
}

.ladder_apply_ineligibility_modal__ineligibility-modal-styles__spinner {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 282px;
}

@media (min-width: 1025px) {

  
  
  .ladder_apply_ineligibility_modal__ineligibility-modal-styles__mediaalpha {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 0 5%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_apply_ineligibility_modal__ineligibility-modal-styles__mediaalpha {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 0 5%;
  }

}
@media (min-width: 1025px) {

  
  
  .ladder_agents_dashboard_contacts__contact-detail-page-quote-section-styles__run-a-quote-button-container {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 600px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard_contacts__contact-detail-page-quote-section-styles__run-a-quote-button-container {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 300px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_dashboard_contacts__contact-detail-page-quote-section-styles__run-a-quote-button-container {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 300px;
  }

}
.ladder_widget_ui_v1__input-group-styles__root {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  padding: 5.0px 0;
}

.ladder_widget_ui_v1__input-group-styles__child {
  padding: 2.5px 0;
}
.ladder_app_in_app_billing__bank-status-enter-billing-styles__title {
  margin: 0 0 22.5px 10.0px;
}

.ladder_app_in_app_billing__bank-status-enter-billing-styles__subtitle {
  margin: 0 0 20px 10.0px;
}

.ladder_app_in_app_billing__bank-status-enter-billing-styles__text {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin: 0 0 20px 0;
}
.ladder_ux_logos__geico-portfolio-styles__container {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.ladder_ux_logos__geico-portfolio-styles__partner-logo {
  height: 30px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_logos__geico-portfolio-styles__partner-logo {
    height: 25px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_logos__geico-portfolio-styles__partner-logo {
    height: 15px;
  }

}
.ladder_login__login-form-styles__fields {
  max-width: 700px;
  width: 35%;
}

.ladder_login__login-form-styles__margin-top {
  margin-top: 5.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_login__login-form-styles__fields {
    width: 100%;
  }

}
.ladder_form_list__root-styles__actions {
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
}

.ladder_form_list__root-styles__action {
  display: flex;
  align-items: center;
}

.ladder_form_list__root-styles__action-label {
  margin-left: 3px;
}
.ladder_account_next_views_overview__root-style__\% {
  width: 100%;
}
.ladder_text__helper-styles__root {

}
.ladder_view_schedule__day-square-styles__day {
  line-height: 48px;
  width: 48px;
  justify-content: center;
  height: 48px;
  align-items: center;
  flex-direction: column;
  position: relative;
  cursor: default;
  display: flex;
}

.ladder_view_schedule__day-square-styles__selectable {

}

@media (min-width: 1025px) {

  
  
  .ladder_view_schedule__day-square-styles__selectable:hover {
    background-color: #D0D0D0;
    cursor: pointer;
  }
  
  .ladder_view_schedule__day-square-styles__selected-day {
    background-color: #FE4747;
    color: #FFFFFF !important;
  }
  
  .ladder_view_schedule__day-square-styles__rungs-selected-day {
    background-color: #000000;
    color: #FFFFFF !important;
  }
  
  .ladder_view_schedule__day-square-styles__rungs-selected-day:after {
    color: #FFFFFF;
  }
  
  .ladder_view_schedule__day-square-styles__selected-day:after {
    color: #FFFFFF;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_view_schedule__day-square-styles__selectable:hover {
    background-color: #D0D0D0;
    cursor: pointer;
  }
  
  .ladder_view_schedule__day-square-styles__selected-day {
    background-color: #FE4747;
    color: #FFFFFF !important;
  }
  
  .ladder_view_schedule__day-square-styles__rungs-selected-day {
    background-color: #000000;
    color: #FFFFFF !important;
  }
  
  .ladder_view_schedule__day-square-styles__rungs-selected-day:after {
    color: #FFFFFF;
  }
  
  .ladder_view_schedule__day-square-styles__selected-day:after {
    color: #FFFFFF;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_view_schedule__day-square-styles__day {
    width: 11.111%;
    height: 60px;
    flex-shrink: 0;
  }

}
.ladder_account_shared_components__document-icon-and-copy-styles__root {
  display: flex;
  align-items: center;
}

.ladder_account_shared_components__document-icon-and-copy-styles__copy {
  padding: 0 20px;
}

.ladder_account_shared_components__document-icon-and-copy-styles__italicized {
  font-style: italic;
}

.ladder_account_shared_components__document-icon-and-copy-styles__left-aligned {
  align-self: flex-start;
}
.ladder_form_authorizations__form-number-footer-styles__footer-container {
  width: 100%;
  text-align: left;
}
.ladder_form_google_autocomplete__generic-address-form-styles__cant-find-address-button {
  font-weight: 300;
  cursor: pointer;
  color: #FE4747;
  display: flex;
  justify-content: flex-start;
  width: 70%;
  margin-right: 10.0px;
}

.ladder_form_google_autocomplete__generic-address-form-styles__address-container {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
}

.ladder_form_google_autocomplete__generic-address-form-styles__autocomplete-address-component {
  width: 100%;
  margin-bottom: 20px;
}

.ladder_form_google_autocomplete__generic-address-form-styles__no-bottom-margin {
  margin-bottom: 0;
}

.ladder_form_google_autocomplete__generic-address-form-styles__address-row {
  width: 100%;
  display: flex;
}
.ladder_experience_survey_accelerated_dropoff__Root-styles__root {
  width: 100%;
  max-width: 600px;
  text-align: center;
  margin: 20px;
}

.ladder_experience_survey_accelerated_dropoff__Root-styles__header {
  margin: 30.0px;
}

.ladder_experience_survey_accelerated_dropoff__Root-styles__dropdown {
  margin: 30.0px 0;
}

.ladder_experience_survey_accelerated_dropoff__Root-styles__top-text {
  margin: 20px;
}

.ladder_experience_survey_accelerated_dropoff__Root-styles__bottom-text {
  margin: 20px;
}

.ladder_experience_survey_accelerated_dropoff__Root-styles__text-area {
  margin: 20px 0;
}

.ladder_experience_survey_accelerated_dropoff__Root-styles__button {
  margin: 20px;
}
.ladder_financial_advisors_home__satisfied-clients-title-styles__\% {
  margin-top: 30px;
}
.ladder_trustage_instant_decline_v2__InstantDeclineV2-styles__root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #F7F2EF;
  padding-top: 20px;
}

.ladder_trustage_instant_decline_v2__InstantDeclineV2-styles__container {
  width: 90%;
  max-width: 600px;
}

.ladder_trustage_instant_decline_v2__InstantDeclineV2-styles__subtitle {
  margin-top: 20px;
}

.ladder_trustage_instant_decline_v2__InstantDeclineV2-styles__price-and-coverage-box {
  display: flex;
  justify-content: center;
  border: 2px solid #BDBDBD;
  border-radius: 10px;
  background-color: #FFFFFF;
  margin-top: 20px;
}

.ladder_trustage_instant_decline_v2__InstantDeclineV2-styles__price-and-coverage-box-inner {
  display: flex;
  flex-direction: column;
  width: 85%;
  gap: 20px;
  margin: 20px 0px;
}

.ladder_trustage_instant_decline_v2__InstantDeclineV2-styles__price-box {
  display: flex;
  flex-direction: column;
  background-color: #D1A98F20;
  border: 2px solid #D1A98F;
  border-radius: 10px;
  padding: 10px;
  gap: 10px;
}

.ladder_trustage_instant_decline_v2__InstantDeclineV2-styles__continue-button {
  width: 100%;
  margin-top: 25px;
}

.ladder_trustage_instant_decline_v2__InstantDeclineV2-styles__logo {
  width: 50%;
}

.ladder_trustage_instant_decline_v2__InstantDeclineV2-styles__face-amount-spinner {
  animation: spin 1s linear infinite;
}

.ladder_trustage_instant_decline_v2__InstantDeclineV2-styles__daily-price-spinner {
  animation: spin 1s linear infinite;
  margin-bottom: 5px;
}

.ladder_trustage_instant_decline_v2__InstantDeclineV2-styles__slider-bar {
  margin: -15px 0 15px 0;
}

@media (max-width: 499px) {

  
  
  .ladder_trustage_instant_decline_v2__InstantDeclineV2-styles__slider-bar {
    margin: -15px 0 0 0;
  }

}
.ladder_beneficiary_form__beneficiary-input-entry-styles__border-top {
  border-top: 1px solid #D0D0D0;
  width: 100%;
}
.ladder_agents_common__input-row-styles__root {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  justify-content: space-between;
  width: 100%;
}

.ladder_agents_common__input-row-styles__child {
  padding: 10.0px 0;
}

.ladder_agents_common__input-row-styles__child:not(:first-child) {
  margin-left: 5.0px;
}

.ladder_agents_common__input-row-styles__child:not(:last-child) {
  margin-right: 5.0px;
}

.ladder_agents_common__input-row-styles__child-no-padding {
  padding: 0;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_common__input-row-styles__root {
    flex-direction: column;
  }
  
  
  
  .ladder_agents_common__input-row-styles__child:not(:first-child) {
    margin-left: 0;
  }
  
  .ladder_agents_common__input-row-styles__child:not(:last-child) {
    margin-right: 0;
  }

}
.ladder_careers_values__ui-value-tile-styles__root {
  max-width: 800px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  flex-direction: row;
  margin-top: auto;
  margin-bottom: auto;
  text-align: left;
}

.ladder_careers_values__ui-value-tile-styles__image {
  height: 200px;
  margin-left: auto;
}

.ladder_careers_values__ui-value-tile-styles__text {
  margin-left: 50.0px;
  margin-right: auto;
}

@media (max-width: 499px) {

  
  
  .ladder_careers_values__ui-value-tile-styles__root {
    margin: 0 0 40px;
    width: 100%;
    flex-direction: column;
    text-align: left;
  }
  
  .ladder_careers_values__ui-value-tile-styles__image {
    margin-top: 66px;
    margin-bottom: 28px;
    margin-right: auto;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_careers_values__ui-value-tile-styles__root {
    margin: 0 0 40px;
    width: 100%;
    flex-direction: column;
    text-align: left;
  }
  
  .ladder_careers_values__ui-value-tile-styles__image {
    margin-top: 66px;
    margin-bottom: 28px;
    margin-right: auto;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_careers_values__ui-value-tile-styles__image {
    width: 240px;
  }
  
  .ladder_careers_values__ui-value-tile-styles__text {
    margin: auto;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_careers_values__ui-value-tile-styles__image {
    height: 85px;
  }
  
  .ladder_careers_values__ui-value-tile-styles__text {
    padding-left: 15.0px;
    margin: 0px;
  }

}
.ladder_ux_logos__ladder-logo-styles__flex {
  display: flex;
}
.ladder_widget_ui_v2__widget-container-styles__root {
  display: flex;
  flex-direction: column;
  background-color: #EFEAE1;
  border: 1px solid #707070;
  padding: 15.0px;
  height: 100%;
  width: 100%;
  margin: auto;
}

.ladder_widget_ui_v2__widget-container-styles__root.tile-a {
  min-height: 250px;
  max-height: 250px;
  min-width: 300px;
  max-width: 300px;
}

.ladder_widget_ui_v2__widget-container-styles__root.tile-b {
  min-height: 280px;
  max-height: 280px;
  min-width: 336px;
  max-width: 336px;
}

.ladder_widget_ui_v2__widget-container-styles__root.portrait {
  padding: 0;
  min-height: 458px;
  max-height: 623px;
  min-width: 259px;
  max-width: 420px;
}

.ladder_widget_ui_v2__widget-container-styles__root.landscape {
  min-height: 311px;
  max-height: 377px;
  min-width: 643px;
  max-width: 720px;
}

.ladder_widget_ui_v2__widget-container-styles__root.panel {
  border: 0px;
}
.ladder_account_next_views_gift__AddressFormUI-styles__address-wrapper {
  padding: 16px;
}

.ladder_account_next_views_gift__AddressFormUI-styles__submit-button {
  margin: 0 16px 16px auto;
}

@media (max-width: 499px) {

  
  
  .ladder_account_next_views_gift__AddressFormUI-styles__submit-button {
    margin: 0;
  }

}
@media (min-width: 1025px) {

  
  
  .ladder_agents_agent_assisted_app_views_waiting_for_decision__body-text-styles__text {
    margin-top: 48px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_agents_agent_assisted_app_views_waiting_for_decision__body-text-styles__text {
    margin-top: 32px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_agent_assisted_app_views_waiting_for_decision__body-text-styles__text {
    margin-top: 32px;
  }

}
.ladder_financial_advisors_home__TopPanelSignup-styles__just-a-placeholder.ladder_financial_advisors_home__TopPanelSignup-styles__\% {
  background-color: transparent;
}

.ladder_financial_advisors_home__TopPanelSignup-styles__\% {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
  padding: 40px 5.5%;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_financial_advisors_home__TopPanelSignup-styles__container {
    width: 100%;
    background-color: white;
  }
  
  .ladder_financial_advisors_home__TopPanelSignup-styles__\% {
    width: 600px;
    margin: 0 auto;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_financial_advisors_home__TopPanelSignup-styles__container {
    margin: 0;
  }
  
  .ladder_financial_advisors_home__TopPanelSignup-styles__\% {
    padding: 32px 22px;
    width: 450px;
    min-height: 430px;
  }

}
.ladder_landing_pages_so_good_common__videos-wrapper-styles__root {
  width: 100%;
  background-color: #000000;
  display: flex;
  justify-content: center;
  padding: 32px 0px;
}

.ladder_landing_pages_so_good_common__videos-wrapper-styles__iframe-screenshot {
  width: 100%;
  height: 100%;
  background-color: blue;
}

.ladder_landing_pages_so_good_common__videos-wrapper-styles__iframe-container {
  width: 80%;
  height: 700px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_landing_pages_so_good_common__videos-wrapper-styles__iframe-container {
    width: 90%;
    height: 300px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_landing_pages_so_good_common__videos-wrapper-styles__iframe-container {
    width: 90%;
    height: 200px;
  }

}
.ladder_savings_steps__celebrate-styles__image {
  max-width: 500px;
  width: 100%;
  height: auto;
  align-self: flex-start;
}

@media (max-width: 499px) {

  
  
  .ladder_savings_steps__celebrate-styles__image {
    align-self: center;
  }

}
.ladder_ux_link__link-with-down-arrow-styles__icon-arrow {
  width: 16px;
  margin: 0 4.0px;
}
.ladder_account_next_views_beneficiaries_edit__same-name-warning-styles__wrapper {
  display: flex;
  fill: #C29A2B;
  color: #C29A2B;
}

.ladder_account_next_views_beneficiaries_edit__same-name-warning-styles__icon {
  margin-right: 10.0px;
}
.ladder_calculator_native_calculator_share__root-styles__root {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #FFFFFF;
}

.ladder_calculator_native_calculator_share__root-styles__title-banner {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #DBE6DB;
}

.ladder_calculator_native_calculator_share__root-styles__title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 310px;
  max-width: 800px;
}

.ladder_calculator_native_calculator_share__root-styles__title-text-contents {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.ladder_calculator_native_calculator_share__root-styles__title-image-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  position: relative;
  top: 35px;
}

.ladder_calculator_native_calculator_share__root-styles__title-image {
  display: block;
  max-width: 200px;
  width: auto;
  height: auto;
}

.ladder_calculator_native_calculator_share__root-styles__results-breakdown-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #FFFFFF;
}

.ladder_calculator_native_calculator_share__root-styles__results-breakdown-container {
  min-width: 330px;
  max-width: 500px;
  width: 90%;
  z-index: 1;
}

.ladder_calculator_native_calculator_share__root-styles__results-card {
  min-width: 330px;
  width: 90%;
  max-width: 500px;
  margin: 15.0px;
  align-items: center;
  padding: 0 20px;
  flex-direction: column;
  min-height: 100px;
  display: flex;
}

.ladder_calculator_native_calculator_share__root-styles__bottom-button-container {
  display: flex;
  justify-content: space-between;
  margin: 15.0px;
}

.ladder_calculator_native_calculator_share__root-styles__bottom-button {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_calculator_native_calculator_share__root-styles__title-container {
    width: 90%;
    height: 280px;
  }
  
  .ladder_calculator_native_calculator_share__root-styles__title-text-contents {
    margin-top: 20px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_calculator_native_calculator_share__root-styles__title-container {
    width: 90%;
    height: 220px;
  }
  
  .ladder_calculator_native_calculator_share__root-styles__title-text-contents {
    margin-top: 20px;
  }
  
  .ladder_calculator_native_calculator_share__root-styles__title-image {
    display: none;
  }
  
  .ladder_calculator_native_calculator_share__root-styles__bottom-button-container {
    flex-direction: column;
  }

}
.ladder_careers__open-roles-link-styles__down-arrow {
  transform: rotate(0.25turn);
  position: relative;
  left: 10px;
  bottom: -3px;
}
.ladder_landing_pages_trustage__bullet-styles__bullet {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.ladder_landing_pages_trustage__bullet-styles__icon-container {
  width: 24px;
  justify-content: center;
  height: 24px;
  margin-right: 12px;
  align-items: center;
  align-self: start;
  background-color: white;
  border-radius: 50%;
  display: flex;
}

.ladder_landing_pages_trustage__bullet-styles__icon {
  height: 12px;
}

@media (max-width: 499px) {

  
  
  .ladder_landing_pages_trustage__bullet-styles__text {
    width: 80%;
    line-height: 18px;
  }

}
.ladder_financial_advisors_home__header-background-styles__\% {
  background-color: #EFEAE1;
  width: 100%;
}
.ladder_view_schedule__HIVConsentModal-styles__content {
  text-align: left;
}

.ladder_view_schedule__HIVConsentModal-styles__modal-banner {
  position: absolute;
  width: 100%;
  padding: 5px;
  text-align: center;
  color: #FE4747;
  bottom: 0;
  left: 0;
}

.ladder_view_schedule__HIVConsentModal-styles__red {
  color: #FE4747;
}
.ladder_trustage_common__info-with-icon-styles__info-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 15.0px;
}

.ladder_trustage_common__info-with-icon-styles__info-icon {
  min-width: 30.0px;
  width: 30.0px;
  justify-content: center;
  height: 30.0px;
  margin-right: 15.0px;
  align-items: center;
  padding: 5.0px;
  min-height: 30.0px;
  background-color: #FFFFFF;
  border-radius: 50%;
  display: flex;
}

.ladder_trustage_common__info-with-icon-styles__info-icon>svg {
  width: 100%;
  height: auto;
  max-height: 100%;
}

.ladder_trustage_common__info-with-icon-styles__info-icon.ladder_trustage_common__info-with-icon-styles__themed {
  background-color: #F1F1F1;
}

.ladder_trustage_common__info-with-icon-styles__info-icon.ladder_trustage_common__info-with-icon-styles__themed path {
  fill: inherit;
}
.ladder_account_next_views_cancel_app__CancelCard-styles__root {
  background-color: #CB3A3A12;
  width: 100%;
  margin-top: 50.0px;
  padding: 50.0px;
}

.ladder_account_next_views_cancel_app__CancelCard-styles__root > *:not(:last-child) {
  margin-bottom: 20px;
}

.ladder_account_next_views_cancel_app__CancelCard-styles__root > button {
  width: 256px;
}

@media (max-width: 499px) {

  
  
  
  
  .ladder_account_next_views_cancel_app__CancelCard-styles__root > button {
    width: 100%;
  }

}
.ladder_ux_animate__pop-in-fade-out-styles-medium__enterActive {
  opacity: 0;
  transition: opacity 900ms ease-in;
  transition-delay: 1100ms;
}

.ladder_ux_animate__pop-in-fade-out-styles-medium__appearActive {
  opacity: 0;
  transition: opacity 900ms ease-in;
  transition-delay: 1100ms;
}

.ladder_ux_animate__pop-in-fade-out-styles-medium__enterDone {
  opacity: 0;
}

.ladder_ux_animate__pop-in-fade-out-styles-medium__appearDone {
  opacity: 0;
}
.ladder_views_decision_offer__fsl-rating-modal-styles__text-box {
  margin-top: 15.0px;
}

.ladder_views_decision_offer__fsl-rating-modal-styles__img-row {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.ladder_form_quick_start__coverage-styles__form-fields {
  margin: 25.0px 0;
}

.ladder_form_quick_start__coverage-styles__form-fields>div {
  margin-bottom: 10.0px;
}

@media (min-width: 1025px) {

  
  
  .ladder_form_quick_start__coverage-styles__form-fields {
    margin: 20px 0 30.0px 0;
  }

}
.ladder_ux_alert_card__alert-card-styles__box-n {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  border: 1px solid #D0D0D0;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
}

.ladder_ux_alert_card__alert-card-styles__box-dismissable {
  padding: 4.0px 4.0px;
}

.ladder_ux_alert_card__alert-card-styles__box-no-dismiss {
  padding: 0 32px;
}

.ladder_ux_alert_card__alert-card-styles__box-account-page {
  padding: 0 48px;
  text-align: left;
}

.ladder_ux_alert_card__alert-card-styles__rungs {
  background-color: #f4e5d3;
  border: none;
  text-align: left;
  padding: 0 16px;
  color: #000000;
}

.ladder_ux_alert_card__alert-card-styles__error {
  color: #FE4747;
  border-color: #FE4747;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_alert_card__alert-card-styles__copy-margin-dismissable {
    margin-top: 16px;
  }
  
  .ladder_ux_alert_card__alert-card-styles__copy-margin-no-dismiss {
    margin-top: 32px;
  }
  
  .ladder_ux_alert_card__alert-card-styles__copy-squeeze {
    margin-left: 8.0px;
    right: 8.0px;
  }
  
  .ladder_ux_alert_card__alert-card-styles__box-account-page {
    padding: 0 12.0px;
    text-align: left;
  }

}
.ladder_ux_logos__acrisure-logo-styles__icon {
  height: 44.8px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_logos__acrisure-logo-styles__icon {
    height: 38.4px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_logos__acrisure-logo-styles__icon {
    height: 28.8px;
  }

}
.ladder_agents_agent_assisted_app_views_review__field-root-styles__root {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  max-width: 600px;
  width: 100%;
  padding: 10.0px;
  align-items: flex-start;
}

.ladder_agents_agent_assisted_app_views_review__field-root-styles__root-edit {
  cursor: pointer;
}

.ladder_agents_agent_assisted_app_views_review__field-root-styles__expandable-review:first-of-type {
  margin-top: 0;
}

.ladder_agents_agent_assisted_app_views_review__field-root-styles__disabled {
  pointer-events: none;
}

@media (min-width: 1025px) {

  
  
  .ladder_agents_agent_assisted_app_views_review__field-root-styles__expandable-review:first-of-type {
    margin-top: 5px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_agents_agent_assisted_app_views_review__field-root-styles__root {
    padding: 5.0px 8px;
  }

}
.ladder_account_partners__privacy-expand-card-styles__title-row {
  display: flex;
  align-items: center;
  margin-top: 22.5px;
}

.ladder_account_partners__privacy-expand-card-styles__partner-title {
  margin-left: 22.5px;
}
.ladder_app_review__expandable-review-submit-wrapper-styles__sticky-root {
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 2;
  background: white;
  padding: 15.0px 20px;
}

.ladder_app_review__expandable-review-submit-wrapper-styles__with-border {
  border-top: 1px solid #D0D0D0;
}

.ladder_app_review__expandable-review-submit-wrapper-styles__without-border {
  border-top: 0;
}

@media (min-width: 1025px) {

  
  
  .ladder_app_review__expandable-review-submit-wrapper-styles__sticky-root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_app_review__expandable-review-submit-wrapper-styles__sticky-root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

}
.ladder_app_common__e-signature-name-and-date-styles__fields {
  justify-content: space-between;
  display: flex;
}

.ladder_app_common__e-signature-name-and-date-styles__root {
  width: 100%;
}

.ladder_app_common__e-signature-name-and-date-styles__field {
  flex-grow: 1;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_app_common__e-signature-name-and-date-styles__root {
    margin-bottom: 40px;
  }
  
  .ladder_app_common__e-signature-name-and-date-styles__field {
    max-width: 48.75%;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_app_common__e-signature-name-and-date-styles__root {
    margin-bottom: 40px;
  }
  
  .ladder_app_common__e-signature-name-and-date-styles__field {
    max-width: 48.75%;
  }

}
.ladder_onboarding_spouse_connect_2__spacer-styles__root {
  display: flex;
  height: 20px;
  width: 20px;
}
.ladder_agents_dashboard_contacts__status-graphic-styles__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ladder_agents_dashboard_contacts__status-graphic-styles__wrapper {
  display: flex;
  align-items: flex-start;
}

.ladder_agents_dashboard_contacts__status-graphic-styles__wrapper .ladder_agents_dashboard_contacts__status-graphic-styles__timeline {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  width: 25px;
}

.ladder_agents_dashboard_contacts__status-graphic-styles__wrapper .ladder_agents_dashboard_contacts__status-graphic-styles__dot {
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background-color: #E5E5E5;
}

.ladder_agents_dashboard_contacts__status-graphic-styles__wrapper .ladder_agents_dashboard_contacts__status-graphic-styles__line {
  border-left: solid 3px;
  border-color: #E5E5E5;
  height: 50px;
}

.ladder_agents_dashboard_contacts__status-graphic-styles__wrapper:last-of-type .ladder_agents_dashboard_contacts__status-graphic-styles__line {
  display: none;
}

.ladder_agents_dashboard_contacts__status-graphic-styles__selected .ladder_agents_dashboard_contacts__status-graphic-styles__dot {
  background-color: #3ACA95;
}

.ladder_agents_dashboard_contacts__status-graphic-styles__selected .ladder_agents_dashboard_contacts__status-graphic-styles__line {
  border-color: #3ACA95;
}

.ladder_agents_dashboard_contacts__status-graphic-styles__selected>div {
  line-height: 14px;
}

.ladder_agents_dashboard_contacts__status-graphic-styles__current .ladder_agents_dashboard_contacts__status-graphic-styles__dot {
  background-color: #3ACA95;
  height: 20px;
  width: 20px;
}

.ladder_agents_dashboard_contacts__status-graphic-styles__current .ladder_agents_dashboard_contacts__status-graphic-styles__status-text {
  display: flex;
  gap: 15px;
}

.ladder_agents_dashboard_contacts__status-graphic-styles__current .ladder_agents_dashboard_contacts__status-graphic-styles__status-text>div {
  line-height: 20px;
}

.ladder_agents_dashboard_contacts__status-graphic-styles__current .ladder_agents_dashboard_contacts__status-graphic-styles__status-text>a {
  line-height: 20px;
}
.ladder_onboarding_accepted_offer__Panel2-styles__margin-top-1 {
  margin-top: 15.0px;
}

.ladder_onboarding_accepted_offer__Panel2-styles__margin-top-2 {
  margin-top: 30.0px;
}

.ladder_onboarding_accepted_offer__Panel2-styles__divider {
  margin: 50.0px 0 30.0px;
}
.ladder_agents_common__centered-checkbox-styles__wrapper {
  display: flex;
}

.ladder_agents_common__centered-checkbox-styles__column {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.ladder_agents_common__centered-checkbox-styles__margin-right {
  margin-right: 10.0px;
}

.ladder_agents_common__centered-checkbox-styles__margin-left {
  margin-left: 10.0px;
}

.ladder_agents_common__centered-checkbox-styles__expand {
  flex: 1;
}

.ladder_agents_common__centered-checkbox-styles__outer-border {
  padding: 10.0px;
}

.ladder_agents_common__centered-checkbox-styles__outer-border-light-gray {
  border: 1px solid #D0D0D0;
}

.ladder_agents_common__centered-checkbox-styles__outer-border-phoenix {
  border: 1px solid #975C46;
}

.ladder_agents_common__centered-checkbox-styles__remove-border\? {
  border: none;
}
.ladder_savings_common__slashed-price-styles__wrapper {
  position: relative;
}

.ladder_savings_common__slashed-price-styles__slash {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
}

@media (min-width: 1025px) {

  
  
  .ladder_savings_common__slashed-price-styles__slash {
    padding: 5px 0;
  }

}
.ladder_widget_ui_v2__drawing-on-wall-image-styles__root {
  flex-basis: 100%;
  flex-shrink: 1;
  display: flex;
  justify-content: center;
}

.ladder_widget_ui_v2__drawing-on-wall-image-styles__image-ish {
  background-image: url(../img/congratulatory.png);
  max-width: 279px;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.ladder_guide_common__table-header-cell-styles__table-header {
  background-color: #D1A98F;
  padding: 10.0px;
}

.ladder_guide_common__table-header-cell-styles__table-header div {
  font-weight: 600;
}
.ladder_markdoc_common__td-comp-styles__cell {
  overflow: wrap;
  text-overflow: wrap;
  word-wrap: break-word;
}
.ladder_financial_advisors_home__allianz-logo-spaced-styles__\% {
  display: flex;
  margin: 20px auto 0 auto;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_financial_advisors_home__allianz-logo-spaced-styles__\% {
    max-width: 590px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_financial_advisors_home__allianz-logo-spaced-styles__\% {
    margin: 45px 0 0 0;
    align-items: center;
  }

}
.ladder_agents_dashboard_pre_screener__fit-meter-styles__container {
  width: 100%;
  max-width: calc(min(85%,460px));
}

.ladder_agents_dashboard_pre_screener__fit-meter-styles__row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.ladder_agents_dashboard_pre_screener__fit-meter-styles__label {
  max-width: 32%;
  justify-content: center;
  height: 20px;
  align-items: center;
  margin-top: 10px;
  font-size: 13px;
  border-radius: 24px;
  display: flex;
  flex: 1;
  color: #333333;
}

.ladder_agents_dashboard_pre_screener__fit-meter-styles__label.ladder_agents_dashboard_pre_screener__fit-meter-styles__red {
  background-color: rgb(251,241,241);
}

.ladder_agents_dashboard_pre_screener__fit-meter-styles__label.ladder_agents_dashboard_pre_screener__fit-meter-styles__yellow {
  background-color: rgb(254,250,241);
}

.ladder_agents_dashboard_pre_screener__fit-meter-styles__label.ladder_agents_dashboard_pre_screener__fit-meter-styles__green {
  background-color: rgb(232,246,245);
}

@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard_pre_screener__fit-meter-styles__label {
    font-size: 10px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_dashboard_pre_screener__fit-meter-styles__label {
    font-size: 10px;
  }

}

.ladder_agents_dashboard_pre_screener__fit-meter-styles__triangle {
  position: absolute;
  left: -50%;
  top: -10px;
  width: 10px;
  height: 10px;
  background-color: #333333;
  visibility: hidden;
  clip-path: path('M4.86603 6.5C4.48113 7.16667 3.51887 7.16667 3.13397 6.5L0.535899 2C0.150999 1.33333 0.632124 0.5 1.40192 0.5L6.59808 0.500001C7.36788 0.500001 7.849 1.33333 7.4641 2L4.86603 6.5Z');
}

.ladder_agents_dashboard_pre_screener__fit-meter-styles__rect {
  width: 4px;
  height: 32px;
  border-radius: 2px;
  position: relative;
}

.ladder_agents_dashboard_pre_screener__fit-meter-styles__red {
  background-color: #ED6C74;
}

.ladder_agents_dashboard_pre_screener__fit-meter-styles__yellow {
  background-color: #FFC76E;
}

.ladder_agents_dashboard_pre_screener__fit-meter-styles__green {
  background-color: #00A997;
}

.ladder_agents_dashboard_pre_screener__fit-meter-styles__low-fit .ladder_agents_dashboard_pre_screener__fit-meter-styles__rect:nth-child(1n+2) {
  opacity: 0.1;
}

.ladder_agents_dashboard_pre_screener__fit-meter-styles__low-fit .ladder_agents_dashboard_pre_screener__fit-meter-styles__rect:nth-child(1) .ladder_agents_dashboard_pre_screener__fit-meter-styles__triangle {
  visibility: visible;
}

@media (min-width: 1025px) {

  
  
  
  
  
  
  .ladder_agents_dashboard_pre_screener__fit-meter-styles__low-moderate-fit .ladder_agents_dashboard_pre_screener__fit-meter-styles__rect:nth-child(1n+14) {
    opacity: 0.1;
  }
  
  
  
  .ladder_agents_dashboard_pre_screener__fit-meter-styles__low-moderate-fit .ladder_agents_dashboard_pre_screener__fit-meter-styles__rect:nth-child(13) .ladder_agents_dashboard_pre_screener__fit-meter-styles__triangle {
    visibility: visible;
  }
  
  
  
  
  
  .ladder_agents_dashboard_pre_screener__fit-meter-styles__moderate-fit .ladder_agents_dashboard_pre_screener__fit-meter-styles__rect:nth-child(1n+16) {
    opacity: 0.1;
  }
  
  
  
  .ladder_agents_dashboard_pre_screener__fit-meter-styles__moderate-fit .ladder_agents_dashboard_pre_screener__fit-meter-styles__rect:nth-child(15) .ladder_agents_dashboard_pre_screener__fit-meter-styles__triangle {
    visibility: visible;
  }
  
  
  
  
  
  .ladder_agents_dashboard_pre_screener__fit-meter-styles__good-fit .ladder_agents_dashboard_pre_screener__fit-meter-styles__rect:nth-child(1n+25) {
    opacity: 0.1;
  }
  
  
  
  .ladder_agents_dashboard_pre_screener__fit-meter-styles__good-fit .ladder_agents_dashboard_pre_screener__fit-meter-styles__rect:nth-child(24) .ladder_agents_dashboard_pre_screener__fit-meter-styles__triangle {
    visibility: visible;
  }

}

@media (max-width: 499px) {

  
  
  
  
  .ladder_agents_dashboard_pre_screener__fit-meter-styles__rect:nth-child(8n+8) {
    display: none;
  }
  
  .ladder_agents_dashboard_pre_screener__fit-meter-styles__rect:nth-child(9n+9) {
    display: none;
  }
  
  
  
  
  
  .ladder_agents_dashboard_pre_screener__fit-meter-styles__low-moderate-fit .ladder_agents_dashboard_pre_screener__fit-meter-styles__rect:nth-child(1n+12) {
    opacity: 0.1;
  }
  
  
  
  .ladder_agents_dashboard_pre_screener__fit-meter-styles__low-moderate-fit .ladder_agents_dashboard_pre_screener__fit-meter-styles__rect:nth-child(11) .ladder_agents_dashboard_pre_screener__fit-meter-styles__triangle {
    visibility: visible;
  }
  
  
  
  
  
  .ladder_agents_dashboard_pre_screener__fit-meter-styles__moderate-fit .ladder_agents_dashboard_pre_screener__fit-meter-styles__rect:nth-child(1n+14) {
    opacity: 0.1;
  }
  
  
  
  .ladder_agents_dashboard_pre_screener__fit-meter-styles__moderate-fit .ladder_agents_dashboard_pre_screener__fit-meter-styles__rect:nth-child(13) .ladder_agents_dashboard_pre_screener__fit-meter-styles__triangle {
    visibility: visible;
  }
  
  
  
  
  
  .ladder_agents_dashboard_pre_screener__fit-meter-styles__good-fit .ladder_agents_dashboard_pre_screener__fit-meter-styles__rect:nth-child(1n+23) {
    opacity: 0.1;
  }
  
  
  
  .ladder_agents_dashboard_pre_screener__fit-meter-styles__good-fit .ladder_agents_dashboard_pre_screener__fit-meter-styles__rect:nth-child(22) .ladder_agents_dashboard_pre_screener__fit-meter-styles__triangle {
    visibility: visible;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  
  
  .ladder_agents_dashboard_pre_screener__fit-meter-styles__rect:nth-child(8n+8) {
    display: none;
  }
  
  .ladder_agents_dashboard_pre_screener__fit-meter-styles__rect:nth-child(9n+9) {
    display: none;
  }
  
  
  
  
  
  .ladder_agents_dashboard_pre_screener__fit-meter-styles__low-moderate-fit .ladder_agents_dashboard_pre_screener__fit-meter-styles__rect:nth-child(1n+12) {
    opacity: 0.1;
  }
  
  
  
  .ladder_agents_dashboard_pre_screener__fit-meter-styles__low-moderate-fit .ladder_agents_dashboard_pre_screener__fit-meter-styles__rect:nth-child(11) .ladder_agents_dashboard_pre_screener__fit-meter-styles__triangle {
    visibility: visible;
  }
  
  
  
  
  
  .ladder_agents_dashboard_pre_screener__fit-meter-styles__moderate-fit .ladder_agents_dashboard_pre_screener__fit-meter-styles__rect:nth-child(1n+14) {
    opacity: 0.1;
  }
  
  
  
  .ladder_agents_dashboard_pre_screener__fit-meter-styles__moderate-fit .ladder_agents_dashboard_pre_screener__fit-meter-styles__rect:nth-child(13) .ladder_agents_dashboard_pre_screener__fit-meter-styles__triangle {
    visibility: visible;
  }
  
  
  
  
  
  .ladder_agents_dashboard_pre_screener__fit-meter-styles__good-fit .ladder_agents_dashboard_pre_screener__fit-meter-styles__rect:nth-child(1n+23) {
    opacity: 0.1;
  }
  
  
  
  .ladder_agents_dashboard_pre_screener__fit-meter-styles__good-fit .ladder_agents_dashboard_pre_screener__fit-meter-styles__rect:nth-child(22) .ladder_agents_dashboard_pre_screener__fit-meter-styles__triangle {
    visibility: visible;
  }

}
.ladder_app_review__gray-bar-unfixed-styles__root {
  border-top: 1px #D0D0D0 solid;
  width: 98%;
  margin: 20px 0 20px 5.0px;
  height: 1px;
}

@media (max-width: 499px) {

  
  
  .ladder_app_review__gray-bar-unfixed-styles__root {
    width: 100%;
  }

}
.ladder_ux_form_groups__input-button-group-styles__input-group {
  display: flex;
  width: 100%;
}

.ladder_ux_form_groups__input-button-group-styles__rungs {
  border-radius: 32px;
  border: solid 1px white;
  align-items: center;
  padding-right: 8px;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_form_groups__input-button-group-styles__input-group>*:last-child {
    width: 25%;
  }

}
.ladder_megaphone_refer__SocialSharingRow-styles__root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 400px;
}

.ladder_megaphone_refer__SocialSharingRow-styles__mobile-hidden {
  display: contents;
}

@media (max-width: 499px) {

  
  
  .ladder_megaphone_refer__SocialSharingRow-styles__mobile-hidden {
    display: none;
  }

}
.ladder_guide_life_insurance_guide__life-insurance-guide-still-need-help-panel-styles__color-container {
  width: 100%;
  overflow: visible;
  background-color: #CFDCDE;
  text-align: center;
}

.ladder_guide_life_insurance_guide__life-insurance-guide-still-need-help-panel-styles__summary-container {
  margin-bottom: 30.0px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_guide_life_insurance_guide__life-insurance-guide-still-need-help-panel-styles__color-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 520px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_guide_life_insurance_guide__life-insurance-guide-still-need-help-panel-styles__color-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 520px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_guide_life_insurance_guide__life-insurance-guide-still-need-help-panel-styles__mobile-content {
    width: 80%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_guide_life_insurance_guide__life-insurance-guide-still-need-help-panel-styles__color-container {
    padding-top: 130.0px;
    padding-bottom: 130.0px;
  }
  
  .ladder_guide_life_insurance_guide__life-insurance-guide-still-need-help-panel-styles__mobile-header {
    margin-bottom: 20px;
  }
  
  .ladder_guide_life_insurance_guide__life-insurance-guide-still-need-help-panel-styles__mobile-content {
    margin-top: 60px;
  }
  
  .ladder_guide_life_insurance_guide__life-insurance-guide-still-need-help-panel-styles__mobile-container {
    padding-left: 30.0px;
    padding-right: 30.0px;
  }

}
.ladder_landing_pages_so_good_common__mobile-episode-selector-styles__dropdown-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ladder_landing_pages_so_good_common__mobile-episode-selector-styles__dropdown-icon {
  width: 30px;
  height: 35px;
  margin-right: 5%;
  color: #FFFFFF;
  z-index: 11;
}

.ladder_landing_pages_so_good_common__mobile-episode-selector-styles__up-icon {
  transform: rotate(270deg);
}

.ladder_landing_pages_so_good_common__mobile-episode-selector-styles__down-icon {
  transform: rotate(90deg);
}

.ladder_landing_pages_so_good_common__mobile-episode-selector-styles__dropdown-anchor {
  position: absolute;
  width: 100%;
  bottom: 0px;
  z-index: 10;
}

.ladder_landing_pages_so_good_common__mobile-episode-selector-styles__dropdown {
  width: 100%;
  padding: 18px 5% 0px;
  background-color: #1A1A1A;
  opacity: 0.9;
  white-space: nowrap;
}

.ladder_landing_pages_so_good_common__mobile-episode-selector-styles__section-title {
  margin-left: 5%;
}

.ladder_landing_pages_so_good_common__mobile-episode-selector-styles__dropdown-link {
  font-size: 20px;
  line-height: 35px;
}
.ladder_onboarding_referrals__circle-styles__root {
  width: 45.0px;
  justify-content: center;
  height: 45.0px;
  flex-shrink: 0;
  flex-direction: column;
  align-content: center;
  background-color: #7A8A8C;
  border-radius: 50%;
  display: flex;
}

@media (max-width: 499px) {

  
  
  .ladder_onboarding_referrals__circle-styles__root {
    background-color: #E7EEEF;
  }

}
.ladder_agents_dashboard_pre_screener__answer-height-styles__container {
  flex: 1;
  max-width: 209px;
}
.ladder_ux_spinner__dots-loader-styles__hidden {
  visibility: hidden;
}

.ladder_ux_spinner__dots-loader-styles__dot {
  padding: 0 4px;
}
.ladder_nearly_perfect_quote_bundles__bundles-modal-ui-styles__buttons {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}

.ladder_nearly_perfect_quote_bundles__bundles-modal-ui-styles__spinner {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 10px;
  overflow: hidden;
}

.ladder_nearly_perfect_quote_bundles__bundles-modal-ui-styles__show-details {
  overflow-x: scroll;
  position: relative;
}

.ladder_nearly_perfect_quote_bundles__bundles-modal-ui-styles__show-details .ladder_nearly_perfect_quote_bundles__bundles-modal-ui-styles__buttons {
  min-width: 100%;
  display: inline-flex;
  flex-wrap: nowrap;
  padding-left: 25px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.ladder_nearly_perfect_quote_bundles__bundles-modal-ui-styles__show-details .ladder_nearly_perfect_quote_bundles__bundles-modal-ui-styles__buttons >* {
  flex-shrink: 0;
}

.ladder_nearly_perfect_quote_bundles__bundles-modal-ui-styles__show-details .ladder_nearly_perfect_quote_bundles__bundles-modal-ui-styles__buttons:after {
  content: '';
  padding-right: 25px;
}

@media (min-width: 1025px) {

  
  
  .ladder_nearly_perfect_quote_bundles__bundles-modal-ui-styles__show-details {
    padding-bottom: 15.0px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_nearly_perfect_quote_bundles__bundles-modal-ui-styles__container {
    scrollbar-width: none;
  }
  
  .ladder_nearly_perfect_quote_bundles__bundles-modal-ui-styles__container::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_nearly_perfect_quote_bundles__bundles-modal-ui-styles__container {
    scrollbar-width: none;
  }
  
  .ladder_nearly_perfect_quote_bundles__bundles-modal-ui-styles__container::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
  }

}
.ladder_markdown_components__hr-styles__root {
  margin-top: 20px;
  margin-bottom: 20px;
}
.ladder_quick_start_panels__rebrand-speed-simplicity-panel-styles__root {
  display: flex;
  background-color: #D1A98F;
  width: 100%;
  z-index: 0;
  justify-content: center;
}

.ladder_quick_start_panels__rebrand-speed-simplicity-panel-styles__text-container {
  display: flex;
  width: 80%;
  max-width: 1000px;
  margin: 256px 0;
  text-align: center;
  justify-content: center;
  flex-direction: column;
}

.ladder_quick_start_panels__rebrand-speed-simplicity-panel-styles__homepage-ui {
  margin: 128px 0;
}

@media (max-width: 499px) {

  
  
  .ladder_quick_start_panels__rebrand-speed-simplicity-panel-styles__text-container {
    width: 90%;
    margin: 128px 0;
  }
  
  .ladder_quick_start_panels__rebrand-speed-simplicity-panel-styles__homepage-ui {
    margin: 64px 0;
  }

}
.ladder_agents_dashboard_quote__quote-response-section-styles__container {
  display: flex;
  align-items: center;
  border-radius: 24px;
  min-height: 175px;
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.1);
}

.ladder_agents_dashboard_quote__quote-response-section-styles__buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

@media (min-width: 1025px) {

  
  
  .ladder_agents_dashboard_quote__quote-response-section-styles__container {
    flex-direction: row;
    justify-content: space-between;
    padding: 16px 24px 34px 24px;
  }
  
  .ladder_agents_dashboard_quote__quote-response-section-styles__start-app-button-container {
    width: 316px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard_quote__quote-response-section-styles__container {
    flex-direction: column;
    padding: 24px 16px;
  }
  
  .ladder_agents_dashboard_quote__quote-response-section-styles__start-app-button-container {
    width: 100%;
  }
  
  .ladder_agents_dashboard_quote__quote-response-section-styles__buttons {
    width: 100%;
    margin-top: 24px;
    gap: 16px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_dashboard_quote__quote-response-section-styles__container {
    flex-direction: column;
    padding: 24px 16px;
  }
  
  .ladder_agents_dashboard_quote__quote-response-section-styles__start-app-button-container {
    width: 100%;
  }
  
  .ladder_agents_dashboard_quote__quote-response-section-styles__buttons {
    width: 100%;
    margin-top: 24px;
    gap: 16px;
  }

}
.ladder_onboarding_beneficiaries__AddBeneficiaryButton-styles__\% {
  min-width: 210.0px;
}
.ladder_ux_modal__modal-styles__root {
  width: 100%;
  justify-content: center;
  height: 100%;
  align-items: center;
  position: fixed;
  z-index: 14;
  display: flex;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  left: 0;
}
.ladder_ux_button__rungs-round-styles__root {
  min-width: 48px;
  width: 48px;
  justify-content: center;
  height: 48px;
  align-items: center;
  position: relative;
  z-index: 0;
  cursor: pointer;
  display: flex;
}

.ladder_ux_button__rungs-round-styles__shrinkable-on-click {
  border-radius: 50%;
  display: flex;
  width: 64px;
  height: 64px;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.ladder_ux_button__rungs-round-styles__shrink-on-click-transition {
  cursor: pointer;
}

.ladder_ux_button__rungs-round-styles__shrink-on-click-transition .ladder_ux_button__rungs-round-styles__icon-wrapper {
  transition: transform 150ms ease-out;
}

.ladder_ux_button__rungs-round-styles__shrink-on-click-transition .ladder_ux_button__rungs-round-styles__button-background {
  transition: transform 150ms ease-out;
}

.ladder_ux_button__rungs-round-styles__shrink-on-click-transition:active .ladder_ux_button__rungs-round-styles__icon-wrapper {
  transform: scale(0.8);
}

.ladder_ux_button__rungs-round-styles__shrink-on-click-transition:active .ladder_ux_button__rungs-round-styles__button-background {
  transform: scale(0.8);
}

.ladder_ux_button__rungs-round-styles__button-background {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  position: absolute;
}

.ladder_ux_button__rungs-round-styles__button-background+* {
  z-index: 1;
}

.ladder_ux_button__rungs-round-styles__icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 80%;
}

.ladder_ux_button__rungs-round-styles__icon {
  width: 50%;
}

.ladder_ux_button__rungs-round-styles__left-align {
  justify-content: flex-start;
}

.ladder_ux_button__rungs-round-styles__left {
  transform: rotate(0.5turn);
}

.ladder_ux_button__rungs-round-styles__loading {

}

.ladder_ux_button__rungs-round-styles__hidden {
  visibility: hidden;
}

.ladder_ux_button__rungs-round-styles__disabled {
  cursor: not-allowed;
}

.ladder_ux_button__rungs-round-styles__disabled .ladder_ux_button__rungs-round-styles__icon {
  opacity: 0.204;
}
.ladder_ux_button__transparent-arrow-styles__arrow {
  padding: 0 0 2px 4.0px;
}
.ladder_agents_dashboard_contacts__contact-card-name-and-progress-bar-styles__contact-card-name-and-progress-bar {
  display: flex;
  width: 65%;
  flex-direction: column;
  gap: 5px;
}

.ladder_agents_dashboard_contacts__contact-card-name-and-progress-bar-styles__locked {
  margin-left: 5px;
}

@media (min-width: 1025px) {

  
  
  .ladder_agents_dashboard_contacts__contact-card-name-and-progress-bar-styles__contact-card-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 300px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard_contacts__contact-card-name-and-progress-bar-styles__contact-card-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 180px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_dashboard_contacts__contact-card-name-and-progress-bar-styles__contact-card-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 180px;
  }

}
.ladder_header__legacy-header-logo-styles__flex {
  display: flex;
}
.ladder_account_next_views_beneficiaries_shares__table-styles__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ladder_account_next_views_beneficiaries_shares__table-styles__header-row {
  margin-top: 40px;
}

.ladder_account_next_views_beneficiaries_shares__table-styles__body-rows {
  padding: 10.0px 0;
  margin-top: 15.0px;
  border-top: 2px solid rgba(0,0,0,0.2);
  border-bottom: 2px solid rgba(0,0,0,0.2);
}

.ladder_account_next_views_beneficiaries_shares__table-styles__body-rows .ladder_account_next_views_beneficiaries_shares__table-styles__row {
  margin: 24px 0;
}

.ladder_account_next_views_beneficiaries_shares__table-styles__cell1 {
  flex-basis: 40%;
}

.ladder_account_next_views_beneficiaries_shares__table-styles__cell2 {
  flex-basis: 26%;
  min-width: 104px;
}

.ladder_account_next_views_beneficiaries_shares__table-styles__cell3 {
  flex-basis: 22%;
}

@media (min-width: 1025px) {

  
  
  .ladder_account_next_views_beneficiaries_shares__table-styles__cell3 {
    text-align: right;
  }

}

.ladder_account_next_views_beneficiaries_shares__table-styles__body-rows .ladder_account_next_views_beneficiaries_shares__table-styles__cell3 {
  max-width: 74px;
}
.ladder_ny_form_multiselection__root-styles__option {
  display: flex;
  padding: 10.0px 0;
}

.ladder_ny_form_multiselection__root-styles__checkbox-container {
  padding-right: 10.0px;
}

.ladder_ny_form_multiselection__root-styles__info-expander-container {
  padding: 5.0px 0 0 10.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_ny_form_multiselection__root-styles__info-expander-container {
    padding-top: 0;
  }

}
.ladder_aimcor_form__open-case-form-styles__copy {
  margin-bottom: 25.0px;
}

.ladder_aimcor_form__open-case-form-styles__field-group {
  display: flex;
  width: 100%;
}

.ladder_aimcor_form__open-case-form-styles__continue {
  display: flex;
  align-items: center;
}

@media (min-width: 1025px) {

  
  
  
  
  .ladder_aimcor_form__open-case-form-styles__field-group>div {
    flex-grow: 1;
  }
  
  .ladder_aimcor_form__open-case-form-styles__field-group>div:first-child {
    margin-right: 15.0px;
  }
  
  .ladder_aimcor_form__open-case-form-styles__cont-text {
    margin-left: 15.0px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  
  
  .ladder_aimcor_form__open-case-form-styles__field-group>div {
    flex-grow: 1;
  }
  
  .ladder_aimcor_form__open-case-form-styles__field-group>div:first-child {
    margin-right: 15.0px;
  }
  
  .ladder_aimcor_form__open-case-form-styles__cont-text {
    margin-left: 15.0px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_aimcor_form__open-case-form-styles__copy {
    margin-bottom: 15.0px;
  }
  
  .ladder_aimcor_form__open-case-form-styles__field-group {
    flex-direction: column;
  }
  
  .ladder_aimcor_form__open-case-form-styles__continue {
    flex-direction: column;
  }
  
  .ladder_aimcor_form__open-case-form-styles__continue .ladder_aimcor_form__open-case-form-styles__cont-text {
    margin-top: 25.0px;
  }

}
.ladder_form_quick_start__captioned-tobacco-styles__description {
  margin: 20px 0;
}

.ladder_form_quick_start__captioned-tobacco-styles__form-field {
  margin-top: 30.0px;
}
.ladder_hdyhau_lower_funnel__lower-funnel-root-styles__survey {
  margin-top: 30px;
}

.ladder_hdyhau_lower_funnel__lower-funnel-root-styles__second-section {
  margin-top: 40px;
}

.ladder_hdyhau_lower_funnel__lower-funnel-root-styles__sticky-cta {
  display: flex;
  justify-content: center;
  background-color: white;
  position: sticky;
  bottom: 0;
  padding: 30px 0 30px 0;
}
@media (min-width: 1025px) {

  
  
  .ladder_financial_advisors_home__top-panel-image-styles__\% {
    max-width: 416px;
    height: auto;
    position: absolute;
    bottom: 0;
    right: 0;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_financial_advisors_home__top-panel-image-styles__\% {
    display: none;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_financial_advisors_home__top-panel-image-styles__\% {
    display: none;
  }

}
.ladder_apply_current_page_components__allianz-title-styles__text {
  padding-top: 22.5px;
  width: 90%;
  max-width: 600px;
  margin: 0 auto;
}

.ladder_apply_current_page_components__allianz-title-styles__reg60 {
  max-width: 800px;
}
.ladder_laddering_page__ready-to-get-started-styles__root {
  display: flex;
  height: 650px;
  overflow: hidden;
  width: 100%;
  justify-content: center;
  background-color: #DBE6DB;
  margin-bottom: -100px;
}

.ladder_laddering_page__ready-to-get-started-styles__top-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1000px;
  margin: 60px 0;
  width: 80vw;
}

.ladder_laddering_page__ready-to-get-started-styles__left-text-container {
  display: flex;
  width: 450px;
  flex-direction: column;
}

.ladder_laddering_page__ready-to-get-started-styles__button {
  max-width: 200px;
}

.ladder_laddering_page__ready-to-get-started-styles__image {
  max-width: 739px;
  max-height: 756px;
  min-width: 250px;
  width: 20vw;
  height: auto;
}

@media (max-width: 499px) {

  
  
  .ladder_laddering_page__ready-to-get-started-styles__root {
    height: auto;
  }
  
  .ladder_laddering_page__ready-to-get-started-styles__top-container {
    flex-direction: column;
    justify-content: center;
    width: 90%;
    max-width: unset;
  }
  
  .ladder_laddering_page__ready-to-get-started-styles__left-text-container {
    width: 90%;
    margin: 0 0 0 0;
  }
  
  .ladder_laddering_page__ready-to-get-started-styles__button {
    max-width: unset;
  }
  
  .ladder_laddering_page__ready-to-get-started-styles__image {
    margin: 60px 0 0 0;
  }

}
.ladder_form_upper_funnel_routing__exchange-carrier-styles__wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  background-color: #FFFFFF;
  min-width: 30%;
  height: 80px;
  border-radius: 5px;
}

.ladder_form_upper_funnel_routing__exchange-carrier-styles__carrier {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 20px;
}

.ladder_form_upper_funnel_routing__exchange-carrier-styles__impr-beacon {
  display: none;
}

.ladder_form_upper_funnel_routing__exchange-carrier-styles__img {
  max-height: 60px;
  max-width: 124px;
}

.ladder_form_upper_funnel_routing__exchange-carrier-styles__view {
  background-color: #000000;
  color: #FFFFFF;
  border-radius: 7px;
  padding: 2.5px 15.0px;
  display: flex;
  align-items: center;
}

.ladder_form_upper_funnel_routing__exchange-carrier-styles__view:hover {
  background-color: #5D5D5D;
}

.ladder_form_upper_funnel_routing__exchange-carrier-styles__view svg {
  margin-left: 5.0px;
}

.ladder_form_upper_funnel_routing__exchange-carrier-styles__view path {
  fill: #FFFFFF;
}

@media (min-width: 1025px) {

  
  
  .ladder_form_upper_funnel_routing__exchange-carrier-styles__wrapper {
    flex: 1;
  }
  
  .ladder_form_upper_funnel_routing__exchange-carrier-styles__wrapper:not(:last-child) {
    margin-right: 3%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  
  
  .ladder_form_upper_funnel_routing__exchange-carrier-styles__wrapper:not(:last-child) {
    margin-right: 20px;
  }
  
  .ladder_form_upper_funnel_routing__exchange-carrier-styles__carrier {
    padding: 0 30.0px;
  }
  
  .ladder_form_upper_funnel_routing__exchange-carrier-styles__view {
    margin-left: 60px;
  }

}
.ladder_agents_agent_assisted_app_views_app_status__progress-step-styles__container {
  display: flex;
  flex-direction: column;
  min-height: 90px;
  gap: 20px;
  border-radius: 8px;
  padding: 20px;
}

.ladder_agents_agent_assisted_app_views_app_status__progress-step-styles__gray {
  background-color: #DEDEDE;
}

.ladder_agents_agent_assisted_app_views_app_status__progress-step-styles__green {
  background-color: #DBE6DB;
}

.ladder_agents_agent_assisted_app_views_app_status__progress-step-styles__top-row {
  display: flex;
  gap: 20px;
  flex-direction: row;
  align-items: center;
}

.ladder_agents_agent_assisted_app_views_app_status__progress-step-styles__bottom-row {
  margin-left: 70px;
}
.ladder_form_authorizations__root-styles__terms-container {
  padding: 20px 0;
  display: flex;
  align-items: flex-start;
}

.ladder_form_authorizations__root-styles__checkbox-container {
  padding-right: 20px;
}

@media (max-width: 499px) {

  
  
  .ladder_form_authorizations__root-styles__terms-container {
    padding: 0;
  }

}
.ladder_calculator_rebrand__email-results-modal-styles__page-1 {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 20px;
}

.ladder_calculator_rebrand__email-results-modal-styles__page-2 {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  row-gap: 20px;
}

.ladder_calculator_rebrand__email-results-modal-styles__button-submit {
  margin: 15.0px 0 0;
}
.ladder_agents_agent_assisted_app_views_review__maybe-coverage-widget-modal-styles__buttons-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.ladder_onboarding_beneficiaries__buttons-styles__buttons {
  margin-top: 40px;
  display: flex;
}

.ladder_onboarding_beneficiaries__buttons-styles__buttons>*:first-child {
  margin-right: 15.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_onboarding_beneficiaries__buttons-styles__buttons {
    width: 100%;
    padding-bottom: 15.0px;
    flex-direction: column;
    position: fixed;
    margin-top: 30.0px;
    z-index: 4;
    box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.05);
    background-color: white;
    padding-right: 15.0px;
    bottom: 0;
    padding-left: 15.0px;
    padding-top: 15.0px;
    left: 0;
  }
  
  .ladder_onboarding_beneficiaries__buttons-styles__buttons>*:first-child {
    padding-right: 15.0px;
    padding-left: 15.0px;
    margin-right: 0px;
    margin-bottom: 15.0px;
  }

}
.ladder_account_next_cards_your_beneficiaries__beneficiary-item-trust-styles__\% {
  display: flex;
  flex-direction: column;
  margin: 0 0 25.0px 0;
}

.ladder_account_next_cards_your_beneficiaries__beneficiary-item-trust-styles__\%>*:first-child {
  margin-bottom: 5.0px;
}
.ladder_ux_button__button-inner-styles__root {
  min-width: 192px;
  justify-content: center;
  font-family: Lato, sans-serif;
  height: 48px;
  user-select: none;
  align-items: center;
  padding: 0 32px;
  outline: none;
  position: relative;
  min-height: 40.0px;
  cursor: pointer;
  font-weight: 700;
  text-decoration: none;
  font-size: 13px;
  letter-spacing: 0.25em;
  box-sizing: border-box;
  display: inline-flex;
}

.ladder_ux_button__button-inner-styles__text-transform {
  text-transform: uppercase;
}

.ladder_ux_button__button-inner-styles__wide-content {
  padding: 0 8.0px;
  justify-content: space-around;
}

.ladder_ux_button__button-inner-styles__min-width-s {
  min-width: 32px;
}

.ladder_ux_button__button-inner-styles__min-width-none {
  min-width: none;
}

.ladder_ux_button__button-inner-styles__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.ladder_ux_button__button-inner-styles__hidden {
  visibility: hidden;
}

.ladder_ux_button__button-inner-styles__full-width {
  width: 100%;
  min-width: 0;
}

.ladder_ux_button__button-inner-styles__height-s {
  height: 40px;
}

.ladder_ux_button__button-inner-styles__height-m {
  height: 54px;
}

.ladder_ux_button__button-inner-styles__height-l {
  height: 96px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_button__button-inner-styles__root {
    padding: 0 16px;
  }
  
  .ladder_ux_button__button-inner-styles__wide-content {
    padding: 0 4.0px;
  }
  
  .ladder_ux_button__button-inner-styles__dynamic-height {
    height: 40.0px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_button__button-inner-styles__root {
    padding: 0 16px;
    width: 100%;
  }
  
  .ladder_ux_button__button-inner-styles__wide-content {
    padding: 0 4.0px;
  }
  
  .ladder_ux_button__button-inner-styles__dynamic-height {
    height: 32px;
  }

}

.ladder_ux_button__button-inner-styles__primary {
  background-color: #000000;
  color: #FFFFFF;
}

.ladder_ux_button__button-inner-styles__secondary {
  background-color: #FFFFFF;
  border: 1px solid #707070;
  color: #707070;
}

.ladder_ux_button__button-inner-styles__transparent {
  border: 1px solid #707070;
  color: #707070;
}

.ladder_ux_button__button-inner-styles__text-only {
  color: #707070;
}

@media (min-width: 1025px) {

  
  
  
  
  .ladder_ux_button__button-inner-styles__active.ladder_ux_button__button-inner-styles__primary.ladder_ux_button__button-inner-styles__three-dimensional:hover {
    background-color: #000000;
  }
  
  
  
  .ladder_ux_button__button-inner-styles__active.ladder_ux_button__button-inner-styles__secondary.ladder_ux_button__button-inner-styles__three-dimensional:hover {
    background-color: #F4F4F4;
  }
  
  
  
  .ladder_ux_button__button-inner-styles__active.ladder_ux_button__button-inner-styles__transparent.ladder_ux_button__button-inner-styles__three-dimensional:hover {
    background-color: #F4F4F4;
  }

}

.ladder_ux_button__button-inner-styles__loading {
  background-color: #D0D0D0;
  cursor: default;
  color: #FFFFFF;
  border: none;
}

.ladder_ux_button__button-inner-styles__disabled {
  background-color: #707070;
  cursor: default;
  color: #FFFFFF;
}

.ladder_ux_button__button-inner-styles__three-dimensional {
  transition-delay: 0s;
  transition-duration: 0.1s;
  transition-timing-function: linear;
  box-shadow: 0px 1px 4px 1px rgba(0,0,0,0.1);
  transition-property: box-shadow,background-color,transform;
}

@media (min-width: 1025px) {

  
  
  
  
  .ladder_ux_button__button-inner-styles__three-dimensional:hover {
    box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.1);
    transform: translateY(-1px);
  }
  
  .ladder_ux_button__button-inner-styles__three-dimensional:active {
    box-shadow: none;
    transform: translateY(1px);
  }

}

@media (max-width: 499px) {

  
  
  
  
  .ladder_ux_button__button-inner-styles__three-dimensional:active {
    box-shadow: none;
    transform: translateY(2px);
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  
  
  .ladder_ux_button__button-inner-styles__three-dimensional:active {
    box-shadow: none;
    transform: translateY(2px);
  }

}
@media (min-width: 1025px) {

  
  
  .ladder_about__grow-with-us-styles__container {
    margin-top: 99px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_about__grow-with-us-styles__container {
    margin-top: 0px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_about__grow-with-us-styles__container {
    margin-top: 0px;
  }

}

.ladder_about__grow-with-us-styles__content {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  max-width: 673px;
}

.ladder_about__grow-with-us-styles__copy {
  margin-top: 30.0px;
  text-align: center;
}

.ladder_about__grow-with-us-styles__button {
  margin-top: 72px;
}
.ladder_savings_steps__faqs-styles__wrapper {
  width: 100%;
}

.ladder_savings_steps__faqs-styles__questions {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ladder_savings_steps__faqs-styles__header {
  margin-bottom: 30px;
}

@media (max-width: 499px) {

  
  
  .ladder_savings_steps__faqs-styles__wrapper {
    width: 90%;
  }
  
  .ladder_savings_steps__faqs-styles__header {
    margin: 20px 0;
  }
  
  .ladder_savings_steps__faqs-styles__questions {
    flex-direction: column;
  }

}
.ladder_about__Root-styles__root {
  width: 100%;
  margin: 0 auto;
}
.ladder_agents_dashboard_incentives__incentive-card-styles__header-text {
  display: flex;
  flex-direction: column;
}

.ladder_agents_dashboard_incentives__incentive-card-styles__title {
  margin-bottom: 8px;
}

.ladder_agents_dashboard_incentives__incentive-card-styles__bonus-image {
  width: 70%;
  height: auto;
}

.ladder_agents_dashboard_incentives__incentive-card-styles__pink-background {
  background-color: #F7A5BD;
}

.ladder_agents_dashboard_incentives__incentive-card-styles__blue-background {
  background-color: #B5CDFF;
}

.ladder_agents_dashboard_incentives__incentive-card-styles__bonus-styling {
  width: 100%;
  justify-content: space-between;
  height: 250px;
  padding: 30px;
  flex-direction: row;
  moz-border-radius: 30px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  border-radius: 30px;
  display: flex;
  webkit-border-radius: 30px;
}

.ladder_agents_dashboard_incentives__incentive-card-styles__bonus-shadow {
  max-width: 600px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
  moz-border-radius: 30px;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  background-color: #FFFFFF;
  border-radius: 30px;
  display: flex;
  webkit-border-radius: 30px;
}

.ladder_agents_dashboard_incentives__incentive-card-styles__pic-and-days-left-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_dashboard_incentives__incentive-card-styles__bonus-image {
    width: 110%;
    height: auto;
  }
  
  .ladder_agents_dashboard_incentives__incentive-card-styles__bonus-styling {
    display: flex;
    height: 175px;
    flex-direction: column;
    gap: 30px;
  }
  
  .ladder_agents_dashboard_incentives__incentive-card-styles__bonus-shadow {
    width: 80%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard_incentives__incentive-card-styles__header-text {
    gap: 20px;
  }
  
  .ladder_agents_dashboard_incentives__incentive-card-styles__bonus-image {
    width: 100%;
    height: auto;
  }
  
  .ladder_agents_dashboard_incentives__incentive-card-styles__bonus-styling {
    display: flex;
    height: 205px;
    flex-direction: column;
    gap: 20px;
  }
  
  .ladder_agents_dashboard_incentives__incentive-card-styles__bonus-shadow {
    width: 100%;
  }
  
  .ladder_agents_dashboard_incentives__incentive-card-styles__pic-and-days-left-container {
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: flex-end;
  }

}

.ladder_agents_dashboard_incentives__incentive-card-styles__bonus-table {
  width: 100%;
}

.ladder_agents_dashboard_incentives__incentive-card-styles__bonus-table tr {
  width: 100%;
}

.ladder_agents_dashboard_incentives__incentive-card-styles__bonus-table tr td {
  padding-bottom: 5.0px;
}

.ladder_agents_dashboard_incentives__incentive-card-styles__bonus-table tr td.ladder_agents_dashboard_incentives__incentive-card-styles__values {
  text-align: right;
}
.ladder_landing_pages_so_good_hero__lttd-content-styles__root {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 90px;
}

.ladder_landing_pages_so_good_hero__lttd-content-styles__container {
  width: 45%;
}

.ladder_landing_pages_so_good_hero__lttd-content-styles__text {
  margin-top: 200px;
}

.ladder_landing_pages_so_good_hero__lttd-content-styles__first-subtext {
  margin-top: 24px;
}

.ladder_landing_pages_so_good_hero__lttd-content-styles__button {
  margin-top: 32px;
  border: solid 2px;
  width: 250px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_landing_pages_so_good_hero__lttd-content-styles__root {
    padding-bottom: 30px;
  }
  
  .ladder_landing_pages_so_good_hero__lttd-content-styles__text {
    margin-top: 100px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_landing_pages_so_good_hero__lttd-content-styles__root {
    justify-content: center;
    padding-bottom: 30px;
  }
  
  .ladder_landing_pages_so_good_hero__lttd-content-styles__text {
    margin-top: 150px;
  }
  
  .ladder_landing_pages_so_good_hero__lttd-content-styles__container {
    width: 80%;
  }
  
  .ladder_landing_pages_so_good_hero__lttd-content-styles__first-subtext {
    margin-top: 12px;
  }

}
.ladder_agents_pass_the_application_identity__pta-identity-styles__input-container {
  display: flex;
  justify-content: space-between;
}

.ladder_agents_pass_the_application_identity__pta-identity-styles__question-container {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.ladder_agents_pass_the_application_identity__pta-identity-styles__text {
  margin: 16px 0;
}

.ladder_agents_pass_the_application_identity__pta-identity-styles__input {
  width: 100%;
}

.ladder_agents_pass_the_application_identity__pta-identity-styles__root {
  display: flex;
  flex-direction: column;
  background: #F7F2EF;
}

.ladder_agents_pass_the_application_identity__pta-identity-styles__content {
  display: flex;
  flex-direction: row;
  margin-top: 80px;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
}

.ladder_agents_pass_the_application_identity__pta-identity-styles__failed-content {
  max-width: 800px;
  justify-content: center;
  margin-right: auto;
  align-items: center;
  padding: 16px 32px;
  flex-direction: column;
  margin-top: 120px;
  margin-left: auto;
  display: flex;
}

.ladder_agents_pass_the_application_identity__pta-identity-styles__contact-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;
}

.ladder_agents_pass_the_application_identity__pta-identity-styles__error {
  margin: 16px 0;
}

.ladder_agents_pass_the_application_identity__pta-identity-styles__image {
  margin-right: 80px;
}

.ladder_agents_pass_the_application_identity__pta-identity-styles__cta-button {
  margin-top: 32px;
}

.ladder_agents_pass_the_application_identity__pta-identity-styles__input-content {
  max-width: 700px;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_pass_the_application_identity__pta-identity-styles__cta-button {
    margin-top: 16px;
  }
  
  .ladder_agents_pass_the_application_identity__pta-identity-styles__input-content {
    margin-top: 32px;
  }
  
  .ladder_agents_pass_the_application_identity__pta-identity-styles__image {
    margin-right: unset;
    height: auto;
    width: 100%;
  }
  
  .ladder_agents_pass_the_application_identity__pta-identity-styles__content {
    margin-top: 16px;
    flex-direction: column;
  }

}
.ladder_affiliates__three-attributes-styles__root {
  width: 100%;
  padding: 60px 20px;
  background: linear-gradient(to bottom, #FFFFFF 0%,#FFFFFF 50%,#EFEAE1 50%,#EFEAE1 100%);
}
.ladder_form_google_autocomplete__google-autocomplete-styles__container {
  display: flex;
  flex-flow: row wrap;
  padding: 5.0px;
  min-height: 48px;
  height: 48px;
  box-sizing: border-box;
  border: 1px solid #D0D0D0;
  cursor: text;
}

.ladder_form_google_autocomplete__google-autocomplete-styles__container:hover {
  border: 1px solid #707070;
}

.ladder_form_google_autocomplete__google-autocomplete-styles__container:focus-within {
  border: 1px solid #000000;
}

.ladder_form_google_autocomplete__google-autocomplete-styles__input-container {
  margin-left: 2px;
  margin-right: 8px;
  flex-grow: 1;
  width: auto;
  max-width: 100%;
}

.ladder_form_google_autocomplete__google-autocomplete-styles__input {
  min-width: 20px;
  width: 100%;
  height: 35px;
  border: 0;
  flex-grow: 1;
  font: inherit;
  font-size: 16px;
  color: #707070;
  padding-left: 8px;
}

.ladder_form_google_autocomplete__google-autocomplete-styles__v2-header {
  margin-bottom: 3px;
  display: flex;
}

.ladder_form_google_autocomplete__google-autocomplete-styles__v2-header-label {
  color: #000000;
  font-weight: 300;
}

.ladder_form_google_autocomplete__google-autocomplete-styles__cant-find-dr-button {
  justify-content: flex-end;
  margin-left: auto;
  font-weight: 300;
  cursor: pointer;
  color: #FE4747;
}

.ladder_form_google_autocomplete__google-autocomplete-styles__cant-find-address-button {
  font-weight: 300;
  cursor: pointer;
  color: #FE4747;
  display: flex;
  justify-content: flex-start;
  width: 70%;
  margin-right: 10.0px;
}

.ladder_form_google_autocomplete__google-autocomplete-styles__simple-address-container {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
}

.ladder_form_google_autocomplete__google-autocomplete-styles__simple-address-row {
  width: 100%;
  display: flex;
}

.ladder_form_google_autocomplete__google-autocomplete-styles__full-address-row {
  display: flex;
  width: 100%;
  margin-top: 15.0px;
}

.ladder_form_google_autocomplete__google-autocomplete-styles__street {
  width: calc(72% - 10px);
  box-sizing: border-box;
  flex-grow: 1;
  margin-right: 10.0px;
}

.ladder_form_google_autocomplete__google-autocomplete-styles__apartment {
  width: 28%;
}

@media (max-width: 499px) {

  
  
  .ladder_form_google_autocomplete__google-autocomplete-styles__root {
    padding-bottom: 60px;
  }
  
  .ladder_form_google_autocomplete__google-autocomplete-styles__street {
    width: 100%;
  }
  
  .ladder_form_google_autocomplete__google-autocomplete-styles__apartment {
    width: 100%;
    margin-bottom: 10.0px;
  }
  
  .ladder_form_google_autocomplete__google-autocomplete-styles__simple-address-row {
    width: 100%;
    display: block;
  }

}
.ladder_form_upper_funnel_routing__ads-panel-v2-styles__wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 30.0px 0;
  margin-bottom: 30.0px;
}

.ladder_form_upper_funnel_routing__ads-panel-v2-styles__header {
  display: flex;
  margin-bottom: 10.0px;
  align-items: center;
}

.ladder_form_upper_funnel_routing__ads-panel-v2-styles__our-top-pick {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ladder_form_upper_funnel_routing__ads-panel-v2-styles__star {
  max-height: 25px;
  max-width: 23px;
  margin-right: 5.0px;
  margin-top: -5px;
}

.ladder_form_upper_funnel_routing__ads-panel-v2-styles__ads {
  display: flex;
  flex-wrap: wrap;
  margin-top: 22.5px;
  justify-content: space-between;
}

@media (min-width: 1025px) {

  
  
  .ladder_form_upper_funnel_routing__ads-panel-v2-styles__ads {
    margin-bottom: 22.5px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_form_upper_funnel_routing__ads-panel-v2-styles__ads {
    justify-content: flex-start;
  }
  
  .ladder_form_upper_funnel_routing__ads-panel-v2-styles__ads >* {
    margin-bottom: 20px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_form_upper_funnel_routing__ads-panel-v2-styles__wrapper {
    padding: 30.0px 0;
  }
  
  .ladder_form_upper_funnel_routing__ads-panel-v2-styles__why-these-providers {
    margin: auto;
  }
  
  .ladder_form_upper_funnel_routing__ads-panel-v2-styles__star {
    max-height: 18px;
    margin-right: 10.0px;
  }
  
  .ladder_form_upper_funnel_routing__ads-panel-v2-styles__ads {
    flex-direction: column;
  }
  
  .ladder_form_upper_funnel_routing__ads-panel-v2-styles__ads >* {
    margin-bottom: 20px;
  }
  
  .ladder_form_upper_funnel_routing__ads-panel-v2-styles__wrapper {
    padding: 30.0px 30.0px 0 30.0px;
  }

}
.ladder_form_traffic_diversion__next-steps-title-styles__root {
  margin-top: 48px;
  margin-bottom: 30.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_form_traffic_diversion__next-steps-title-styles__root {
    margin-top: 24px;
  }

}
.ladder_markdown_components__table-cell-styles__root {
  padding-bottom: 10.0px;
}
.ladder_text__title-styles__root {
  margin-bottom: 16px;
}
.ladder_financial_advisors_home__flexible-coverage-text-content-styles__\% {
  padding: 0 5.5%;
}

@media (min-width: 1025px) {

  
  
  .ladder_financial_advisors_home__flexible-coverage-text-content-styles__\% {
    transform: translateX(-8%);
    flex: 1 1 auto;
    padding: 4% 0 3% 0;
  }

}
.ladder_cover_me_asap_views_in_funnel_survey__InFunnelSurveyModal-styles__title {
  weight: light;
  margin: 20px 0 10px 5%;
}

.ladder_cover_me_asap_views_in_funnel_survey__InFunnelSurveyModal-styles__breadcrumb {
  margin: 15px 5% 0 5%;
}

.ladder_cover_me_asap_views_in_funnel_survey__InFunnelSurveyModal-styles__content-wrapper {
  display: flex;
  flex-direction: column;
  margin: 20px 5% 0 5%;
  gap: 20px;
}

.ladder_cover_me_asap_views_in_funnel_survey__InFunnelSurveyModal-styles__ads-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: 20px 0 30px 0;
}

.ladder_cover_me_asap_views_in_funnel_survey__InFunnelSurveyModal-styles__button {
  width: 100%;
}

.ladder_cover_me_asap_views_in_funnel_survey__InFunnelSurveyModal-styles__back-link {
  align-self: center;
  margin: 15px 0 15px 0;
}

.ladder_cover_me_asap_views_in_funnel_survey__InFunnelSurveyModal-styles__footer {
  display: flex;
  flex-direction: column;
  padding: 20px 5% 10px 5%;
}

.ladder_cover_me_asap_views_in_funnel_survey__InFunnelSurveyModal-styles__footer.ladder_cover_me_asap_views_in_funnel_survey__InFunnelSurveyModal-styles__sticky {
  position: sticky;
  bottom: 0;
}

.ladder_cover_me_asap_views_in_funnel_survey__InFunnelSurveyModal-styles__spinner {
  display: flex;
  flex-direction: column;
  margin: 20px 5% 0 5%;
  gap: 30px;
}

@media (max-width: 499px) {

  
  
  .ladder_cover_me_asap_views_in_funnel_survey__InFunnelSurveyModal-styles__ads-wrapper {
    flex-direction: column;
    justify-content: center;
    margin-bottom: 10px;
  }
  
  .ladder_cover_me_asap_views_in_funnel_survey__InFunnelSurveyModal-styles__title {
    margin: 25px 0 15px 5%;
  }
  
  .ladder_cover_me_asap_views_in_funnel_survey__InFunnelSurveyModal-styles__content-wrapper {
    margin: 5px 5% 0 5%;
    gap: 10px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_cover_me_asap_views_in_funnel_survey__InFunnelSurveyModal-styles__ads-wrapper {
    flex-direction: column;
    justify-content: center;
    margin-bottom: 10px;
  }
  
  .ladder_cover_me_asap_views_in_funnel_survey__InFunnelSurveyModal-styles__title {
    margin: 25px 0 15px 5%;
  }
  
  .ladder_cover_me_asap_views_in_funnel_survey__InFunnelSurveyModal-styles__content-wrapper {
    margin: 5px 5% 0 5%;
    gap: 10px;
  }

}
.ladder_views_decision_common__inline-text-expander-styles__root {
  display: inline-block;
}

.ladder_views_decision_common__inline-text-expander-styles__text {
  text-decoration-line: underline;
  text-decoration-style: dashed;
}

.ladder_views_decision_common__inline-text-expander-styles__text:hover {
  text-decoration-style: solid;
  color: #FE5757;
}

@media (max-width: 499px) {

  
  
  .ladder_views_decision_common__inline-text-expander-styles__root {
    margin-top: 0;
  }

}
.ladder_agents_agent_assisted_app_views_offer__fsl-rating-modal-styles__text-box {
  margin-top: 15.0px;
}

.ladder_agents_agent_assisted_app_views_offer__fsl-rating-modal-styles__img-row {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.ladder_incentivized_traffic_payment_modal__Root-styles__modal-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.ladder_incentivized_traffic_payment_modal__Root-styles__header {
  margin: 10.0px 0 0 0;
}

.ladder_incentivized_traffic_payment_modal__Root-styles__desc {
  margin: 22.5px 0;
}

.ladder_incentivized_traffic_payment_modal__Root-styles__red-font {
  color: #FE4747;
}

.ladder_incentivized_traffic_payment_modal__Root-styles__email-input-container {
  width: 100%;
  max-width: 350px;
}

.ladder_incentivized_traffic_payment_modal__Root-styles__button {
  margin: 30.0px 0 0 0;
  align-self: start;
}
.ladder_laddering_page__answers-to-good-questions-styles__root {
  display: flex;
  height: auto;
  overflow: hidden;
  width: 100%;
  margin-bottom: 60px;
  justify-content: center;
  background-color: #FFFFFF;
}

.ladder_laddering_page__answers-to-good-questions-styles__top-container {
  display: flex;
  max-width: 1000px;
  justify-content: center;
  margin: 130.0px 0 60px 0;
  width: 90%;
}

.ladder_laddering_page__answers-to-good-questions-styles__title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.ladder_laddering_page__answers-to-good-questions-styles__faq-container {
  display: flex;
  width: 90%;
  max-width: 600px;
  flex-direction: column;
}

@media (max-width: 499px) {

  
  
  .ladder_laddering_page__answers-to-good-questions-styles__title {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .ladder_laddering_page__answers-to-good-questions-styles__link {
    margin-top: 10.0px;
  }
  
  .ladder_laddering_page__answers-to-good-questions-styles__top-container {
    margin: 60px 0 30.0px 0;
  }

}
.ladder_quick_start_panels__small-rating-banner-styles__root {
  display: flex;
  width: 100%;
  background-color: #CFDCDE;
  justify-content: center;
  padding: 4.0px;
}

.ladder_quick_start_panels__small-rating-banner-styles__icon {
  margin-top: 4.0px;
}

.ladder_quick_start_panels__small-rating-banner-styles__review-text {
  margin-left: 10px;
  margin-right: 10px;
}

.ladder_quick_start_panels__small-rating-banner-styles__bolded-text {
  font-weight: bold;
}
.ladder_views_decision_common__poca-slider-range-styles__afford-range-bar {
  width: 100%;
  padding-left: 7px;
}
.ladder_header__back-arrow-styles__icon-arrow {
  box-sizing: content-box;
  padding: 15.0px;
  height: 32px;
  width: 32px;
}

@media (max-width: 499px) {

  
  
  .ladder_header__back-arrow-styles__icon-arrow {
    padding: 15.0px;
    height: 20.0px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_header__back-arrow-styles__icon-arrow {
    padding: 15.0px;
    height: 20.0px;
  }

}
.ladder_app_review__additional-information-section-styles__assessment-factor-group {
  margin-top: 30.0px;
  width: 100%;
}

.ladder_app_review__additional-information-section-styles__title {
  padding-left: 10.0px;
  text-align: left;
}
.ladder_agents_agent_assisted_app_views_review__accelerated-benefit-rider-disclosure-styles__container {
  border: 1px solid black;
  padding: 24px 40px;
  max-height: 287px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_agent_assisted_app_views_review__accelerated-benefit-rider-disclosure-styles__container {
    padding: 16px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_agent_assisted_app_views_review__accelerated-benefit-rider-disclosure-styles__container {
    padding: 16px;
  }

}
.ladder_agents_pass_the_application__date-of-birth-styles__container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ladder_agents_pass_the_application__date-of-birth-styles__root-container {
  width: 50%;
}
.ladder_account_next_cards_your_beneficiaries__beneficiaries-group-styles__group:not(:last-child) {
  border-bottom: 1px solid #D0D0D0;
}

.ladder_account_next_cards_your_beneficiaries__beneficiaries-group-styles__policy-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.ladder_account_next_cards_your_beneficiaries__beneficiaries-group-styles__policy-id {
  margin: 12px 0;
}
.ladder_onboarding_secondary_addressee__Modal-styles__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30.0px;
}

.ladder_onboarding_secondary_addressee__Modal-styles__info-container {
  display: flex;
  flex-wrap: wrap;
}

.ladder_onboarding_secondary_addressee__Modal-styles__detail {
  width: 50%;
  margin-bottom: 30.0px;
}
.ladder_agents_agent_assisted_app_views_review__multiselect-bare-styles__pencil {
  height: 16px;
  width: 16px;
}

.ladder_agents_agent_assisted_app_views_review__multiselect-bare-styles__display-lines {
  display: flex;
  flex-direction: column;
}

.ladder_agents_agent_assisted_app_views_review__multiselect-bare-styles__hidden {
  visibility: hidden;
}

.ladder_agents_agent_assisted_app_views_review__multiselect-bare-styles__pencil-wrapper {
  padding-left: 20px;
}

.ladder_agents_agent_assisted_app_views_review__multiselect-bare-styles__root {
  display: flex;
  flex-direction: column;
  width: 85%;
}

.ladder_agents_agent_assisted_app_views_review__multiselect-bare-styles__link {
  display: flex;
  align-items: left;
}

.ladder_agents_agent_assisted_app_views_review__multiselect-bare-styles__answer-root {
  display: flex;
  align-items: left;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_agent_assisted_app_views_review__multiselect-bare-styles__link {
    margin: 5.0px 0;
  }

}
.ladder_server_error__root-styles__wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.ladder_agents_dashboard_contacts__edit-contact-modal-styles__edit-contact-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  justify-content: space-around;
  padding-bottom: 10px;
  padding-top: 10px;
}

.ladder_agents_dashboard_contacts__edit-contact-modal-styles__share-quote-error-text {
  display: flex;
  width: 100%;
  justify-content: center;
}

.ladder_agents_dashboard_contacts__edit-contact-modal-styles__submit-button-text {
  font-size: 15px;
}

.ladder_agents_dashboard_contacts__edit-contact-modal-styles__button {
  margin-top: 3em;
  width: 100%;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard_contacts__edit-contact-modal-styles__modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_dashboard_contacts__edit-contact-modal-styles__modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_agents_dashboard_contacts__edit-contact-modal-styles__modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 600px;
  }

}
.ladder_header__top-nav-link-styles__dropdown-link {
  display: flex;
  padding: 10.0px 15.0px;
}

.ladder_header__top-nav-link-styles__dropdown-link:hover {
  background-color: #F1F1F1;
}

.ladder_header__top-nav-link-styles__caption {
  margin-bottom: 5.0px;
}

.ladder_header__top-nav-link-styles__icon {
  fill: #975C46;
  margin-right: 5.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_header__top-nav-link-styles__dropdown-link {
    padding: 10.0px 0;
  }
  
  .ladder_header__top-nav-link-styles__sub-link {
    padding-left: 20px;
  }

}
.ladder_ux_social__all-social-icons-styles__root {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.ladder_ux_social__all-social-icons-styles__flush {
  justify-content: space-between;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_social__all-social-icons-styles__mobile-two-rows\? {
    width: 150px;
  }

}
.ladder_ux_form__slider-select-styles__root {
  margin: auto;
}

.ladder_ux_form__slider-select-styles__tooltip-container {
  position: relative;
}

.ladder_ux_form__slider-select-styles__caption-container {
  margin: 11px 0px 15px;
}

.ladder_ux_form__slider-select-styles__inline {
  display: inline;
}

.ladder_ux_form__slider-select-styles__label {
  position: relative;
  text-align: center;
  left: 0;
  margin-bottom: 10px;
  background: #475665;
}

.ladder_ux_form__slider-select-styles__label.ladder_ux_form__slider-select-styles__rungs {
  background: #000000;
}

.ladder_ux_form__slider-select-styles__tooltip-above {
  margin-bottom: 10px;
}

.ladder_ux_form__slider-select-styles__tooltip-above .ladder_ux_form__slider-select-styles__label:after {
  top: 100%;
  border-bottom-color: rgba(255, 255, 255, 0);
  border-top-color: #475665;
}

.ladder_ux_form__slider-select-styles__tooltip-above .ladder_ux_form__slider-select-styles__label.ladder_ux_form__slider-select-styles__rungs:after {
  border-top-color: #000000;
}

.ladder_ux_form__slider-select-styles__tooltip-below {
  margin-top: 15px;
}

.ladder_ux_form__slider-select-styles__tooltip-below .ladder_ux_form__slider-select-styles__label:after {
  bottom: 100%;
  border-bottom-color: #475665;
  border-top-color: rgba(255, 255, 255, 0);
}

.ladder_ux_form__slider-select-styles__tooltip-below .ladder_ux_form__slider-select-styles__label.ladder_ux_form__slider-select-styles__rungs:after {
  border-bottom-color: #000000;
}

.ladder_ux_form__slider-select-styles__label:after {
  width: 0;
  border-width: 5px;
  height: 0;
  border-right-color: rgba(255, 255, 255, 0);
  border-style: solid;
  position: absolute;
  border-left-color: rgba(255, 255, 255, 0);
  content: " ";
  margin-left: -5px;
  pointer-events: none;
  left: 50%;
}

.ladder_ux_form__slider-select-styles__small-int {
  width: 1.5em;
  margin-left: -0.75em;
  padding: 3px 0;
}

.ladder_ux_form__slider-select-styles__big-money {
  width: 100px;
  margin-left: -50px;
  padding: 7px 0;
}

.ladder_ux_form__slider-select-styles__affordability {
  position: relative;
  display: inline;
}
.ladder_agents_agent_assisted_app_views_app_status__status-panel-styles__panel {
  display: flex;
  flex-direction: column;
  max-width: 595px;
  height: 100%;
  max-height: 410px;
  padding: 40px;
  border: 2px solid #EFEAE1;
  border-radius: 8px;
}
.ladder_account_policy__policy-documents-card-styles__root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.ladder_ux_form__text-input-inner-button-styles__button-position {
  margin-right: 10px;
}
.ladder_guide_common__first-equation-styles__equation-divider {
  border-bottom: 1px solid black;
  width: 225px;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (max-width: 499px) {

  
  
  .ladder_guide_common__first-equation-styles__mobile-wrapper {
    margin-top: 30.0px;
    margin-bottom: 30.0px;
  }

}
.ladder_ny_form_review__field-root-styles__root {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10.0px;
  margin-top: 20px;
}

.ladder_ny_form_review__field-root-styles__root-edit {
  cursor: pointer;
}

.ladder_ny_form_review__field-root-styles__root-edit:hover {
  background-color: #F1F1F1;
}

.ladder_ny_form_review__field-root-styles__no-side-padding {
  padding-left: 0;
  padding-right: 0;
}

.ladder_ny_form_review__field-root-styles__no-top-margin {
  margin-top: 0;
}

@media (max-width: 499px) {

  
  
  .ladder_ny_form_review__field-root-styles__root {
    padding: 0;
  }

}
.ladder_financial_advisors_payment_select__root-styles__two-col-wrapper {
  display: flex;
  width: 100%;
}

.ladder_financial_advisors_payment_select__root-styles__col-left {
  width: 50%;
  background-color: #E7EEEF;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 125px;
}

.ladder_financial_advisors_payment_select__root-styles__left-col-text {
  text-align: left;
  width: 80%;
}

.ladder_financial_advisors_payment_select__root-styles__col-right {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
}

.ladder_financial_advisors_payment_select__root-styles__speed-image {
  width: 267px;
  margin-top: 117px;
}

.ladder_financial_advisors_payment_select__root-styles__right-col-text {
  text-align: left;
  width: 80%;
}

@media (max-width: 499px) {

  
  
  .ladder_financial_advisors_payment_select__root-styles__two-col-wrapper {
    flex-direction: column;
  }
  
  .ladder_financial_advisors_payment_select__root-styles__col-left {
    width: 100%;
    background-color: #FFFFFF;
    padding: 0px;
  }
  
  .ladder_financial_advisors_payment_select__root-styles__col-right {
    width: 100%;
    background-color: #FFFFFF;
    padding: 0px;
  }
  
  .ladder_financial_advisors_payment_select__root-styles__col-left {
    padding: 40px 0px;
  }
  
  .ladder_financial_advisors_payment_select__root-styles__col-right {
    padding-bottom: 35px;
  }
  
  .ladder_financial_advisors_payment_select__root-styles__speed-image {
    display: none;
  }

}
.ladder_form_funhouse__funhouse-styles__wrapper {
  width: 100%;
  top: 8px;
  justify-content: center;
  height: 100%;
  padding: 0 20px;
  flex-direction: column;
  text-align: center;
  position: fixed;
  z-index: 9;
  background-color: #F7F2EF;
  display: flex;
  left: 0;
}

.ladder_form_funhouse__funhouse-styles__shift-child-up>div {
  margin-top: -100px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_form_funhouse__funhouse-styles__wrapper {
    top: 68px;
  }
  
  .ladder_form_funhouse__funhouse-styles__wrapper>div {
    margin-top: 0;
    max-height: 100%;
  }

}
.ladder_ux_animate__fade-styles__fade-very-short-enter {
  opacity: 0.01;
}

.ladder_ux_animate__fade-styles__fade-very-short-enter.ladder_ux_animate__fade-styles__fade-very-short-enter-active {
  opacity: 1;
  transition: all 100ms ease-in;
}

.ladder_ux_animate__fade-styles__fade-very-short-leave {
  opacity: 1;
}

.ladder_ux_animate__fade-styles__fade-very-short-leave.ladder_ux_animate__fade-styles__fade-very-short-leave-active {
  opacity: 0.01;
  transition: all 100ms ease-out;
}

.ladder_ux_animate__fade-styles__fade-very-short-appear {
  opacity: 0.01;
}

.ladder_ux_animate__fade-styles__fade-very-short-appear.ladder_ux_animate__fade-styles__fade-very-short-appear-active {
  opacity: 1;
  transition: all 100ms ease-in;
}

.ladder_ux_animate__fade-styles__fade-short-enter {
  opacity: 0.01;
}

.ladder_ux_animate__fade-styles__fade-short-enter.ladder_ux_animate__fade-styles__fade-short-enter-active {
  opacity: 1;
  transition: all 250ms ease-in;
}

.ladder_ux_animate__fade-styles__fade-short-leave {
  opacity: 1;
}

.ladder_ux_animate__fade-styles__fade-short-leave.ladder_ux_animate__fade-styles__fade-short-leave-active {
  opacity: 0.01;
  transition: all 250ms ease-out;
}

.ladder_ux_animate__fade-styles__fade-short-appear {
  opacity: 0.01;
}

.ladder_ux_animate__fade-styles__fade-short-appear.ladder_ux_animate__fade-styles__fade-short-appear-active {
  opacity: 1;
  transition: all 250ms ease-in;
}

.ladder_ux_animate__fade-styles__fade-enter {
  opacity: 0.01;
}

.ladder_ux_animate__fade-styles__fade-enter.ladder_ux_animate__fade-styles__fade-enter-active {
  opacity: 1;
  transition: all 500ms ease-in;
}

.ladder_ux_animate__fade-styles__fade-leave {
  opacity: 1;
}

.ladder_ux_animate__fade-styles__fade-leave.ladder_ux_animate__fade-styles__fade-leave-active {
  opacity: 0.01;
  transition: all 500ms ease-out;
}

.ladder_ux_animate__fade-styles__fade-appear {
  opacity: 0.01;
}

.ladder_ux_animate__fade-styles__fade-appear.ladder_ux_animate__fade-styles__fade-appear-active {
  opacity: 1;
  transition: all 500ms ease-in;
}

.ladder_ux_animate__fade-styles__fade-long-enter {
  opacity: 0.01;
}

.ladder_ux_animate__fade-styles__fade-long-enter.ladder_ux_animate__fade-styles__fade-long-enter-active {
  opacity: 1;
  transition: all 1000ms ease-in;
}

.ladder_ux_animate__fade-styles__fade-long-leave {
  opacity: 1;
}

.ladder_ux_animate__fade-styles__fade-long-leave.ladder_ux_animate__fade-styles__fade-long-leave-active {
  opacity: 0.01;
  transition: all 1000ms ease-out;
}

.ladder_ux_animate__fade-styles__fade-long-appear {
  opacity: 0.01;
}

.ladder_ux_animate__fade-styles__fade-long-appear.ladder_ux_animate__fade-styles__fade-long-appear-active {
  opacity: 1;
  transition: all 1000ms ease-in;
}
.ladder_agents_dashboard__accept-terms-modal-styles__quoter-and-rules {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: space-between;
}
.ladder_ux_homepage_testimonials_core__testimonial-scroller-styles__wrapper {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ladder_ux_homepage_testimonials_core__testimonial-scroller-styles__view {
  height: 450px;
  width: 100%;
  max-width: 2620px;
  margin: 50px 0 30px 0;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_homepage_testimonials_core__testimonial-scroller-styles__view {
    height: 330px;
  }

}
.ladder_ux_trustpilot__TrustpilotSection-styles__trustpilot-section {
  text-align: left;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.ladder_ux_trustpilot__TrustpilotSection-styles__trustpilot-logo {
  height: 32px;
}

.ladder_ux_trustpilot__TrustpilotSection-styles__trustpilot-stars {
  height: 32px;
  margin: 5px 0;
}

.ladder_ux_trustpilot__TrustpilotSection-styles__trustpilot-score {
  height: 14px;
}

.ladder_ux_trustpilot__TrustpilotSection-styles__s .ladder_ux_trustpilot__TrustpilotSection-styles__trustpilot-logo {
  height: 42px;
  width: 126px;
}

.ladder_ux_trustpilot__TrustpilotSection-styles__s .ladder_ux_trustpilot__TrustpilotSection-styles__trustpilot-stars {
  height: 24px;
  width: 126px;
  margin: 5px 0;
}

.ladder_ux_trustpilot__TrustpilotSection-styles__s .ladder_ux_trustpilot__TrustpilotSection-styles__trustpilot-score {
  height: 11px;
  width: 146px;
}

.ladder_ux_trustpilot__TrustpilotSection-styles__l .ladder_ux_trustpilot__TrustpilotSection-styles__trustpilot-logo {
  height: 55px;
  width: 175px;
}

.ladder_ux_trustpilot__TrustpilotSection-styles__l .ladder_ux_trustpilot__TrustpilotSection-styles__trustpilot-stars {
  height: 31px;
  width: 175px;
}

.ladder_ux_trustpilot__TrustpilotSection-styles__l .ladder_ux_trustpilot__TrustpilotSection-styles__trustpilot-score {
  height: 14px;
  width: 175px;
}

.ladder_ux_trustpilot__TrustpilotSection-styles__xl .ladder_ux_trustpilot__TrustpilotSection-styles__trustpilot-logo {
  height: 48px;
}

.ladder_ux_trustpilot__TrustpilotSection-styles__xl .ladder_ux_trustpilot__TrustpilotSection-styles__trustpilot-stars {
  height: 48px;
}

.ladder_ux_trustpilot__TrustpilotSection-styles__xl .ladder_ux_trustpilot__TrustpilotSection-styles__trustpilot-score {
  height: 21px;
}
.ladder_ux_homepage_returners_core__journey-panel-body-styles__mb {
  margin-bottom: 16px;
}
.ladder_ny_form_traffic_diversion__Root-styles__root {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 499px) {

  
  
  .ladder_ny_form_traffic_diversion__Root-styles__root {
    padding: 12px;
  }

}
.ladder_account_next__rungs-view-styles__\% {
  margin-bottom: 60px;
}

@media (min-width: 1025px) {

  
  
  .ladder_account_next__rungs-view-styles__\% {
    width: 1040px;
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 32px;
    flex-grow: 1;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account_next__rungs-view-styles__\% {
    flex: 1 2 850px;
    min-width: 480px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 16px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_account_next__rungs-view-styles__\% {
    padding-left: 22px;
    padding-right: 22px;
    width: 100%;
  }

}
.ladder_ux_root__root-styles__root {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.ladder_ux_root__root-styles__background-color-white {
  background-color: #FFFFFF;
}

.ladder_ux_root__root-styles__background-color-lighter-gray {
  background-color: #E5E5E5;
}

.ladder_ux_root__root-styles__center-text {
  text-align: center;
}

.ladder_ux_root__root-styles__full-height {
  flex-grow: 1;
}

.ladder_ux_root__root-styles__align-items-center {
  align-items: center;
}

.ladder_ux_root__root-styles__align-items-start {
  align-items: flex-start;
}

.ladder_ux_root__root-styles__align-items-stretch {
  align-items: stretch;
}
.ladder_agents_quote_landing__welcome-banner-styles__root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ladder_agents_quote_landing__welcome-banner-styles__image {
  width: 492px;
  height: auto;
  margin-bottom: 80px;
}

.ladder_agents_quote_landing__welcome-banner-styles__header {
  margin-bottom: 100px;
}
@media (min-width: 1025px) {

  
  
  .ladder_apply_spouse_connect__SpouseEmailSentNotification-styles__fix-height {
    height: 100px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_apply_spouse_connect__SpouseEmailSentNotification-styles__fix-height {
    height: 100px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_apply_spouse_connect__SpouseEmailSentNotification-styles__message-width {
    width: 100%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_apply_spouse_connect__SpouseEmailSentNotification-styles__message-width {
    width: 90%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_apply_spouse_connect__SpouseEmailSentNotification-styles__message-width {
    width: 90%;
  }

}
.ladder_landing_pages_so_good_hero__lttd-bars-styles__root {
  display: flex;
  justify-content: flex-end;
  margin-right: 5%;
}

.ladder_landing_pages_so_good_hero__lttd-bars-styles__bars-container {
  display: flex;
  width: 40%;
  height: 0.4vw;
  justify-content: space-between;
}

.ladder_landing_pages_so_good_hero__lttd-bars-styles__gray-bar-wrapper {
  display: flex;
  position: relative;
  flex-grow: 1;
  height: 100%;
  z-index: 1;
  margin: 20.0px 1% 0 1%;
  background-color: #5D5D5D;
  cursor: pointer;
}

.ladder_landing_pages_so_good_hero__lttd-bars-styles__gray-bar-wrapper:first-child {
  margin-left: 0px;
}

.ladder_landing_pages_so_good_hero__lttd-bars-styles__gray-bar-wrapper:last-child {
  margin-right: 0px;
}

.ladder_landing_pages_so_good_hero__lttd-bars-styles__current-bar {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  width: 0%;
  background-color: #FFFFFF;
}

.ladder_landing_pages_so_good_hero__lttd-bars-styles__current-bar.ladder_landing_pages_so_good_hero__lttd-bars-styles__transition {
  transition: 6s linear;
}

.ladder_landing_pages_so_good_hero__lttd-bars-styles__current-bar.ladder_landing_pages_so_good_hero__lttd-bars-styles__selected {
  width: 100%;
}

.ladder_landing_pages_so_good_hero__lttd-bars-styles__current-bar.ladder_landing_pages_so_good_hero__lttd-bars-styles__unselected {
  transition: 0s;
}

@media (max-width: 499px) {

  
  
  .ladder_landing_pages_so_good_hero__lttd-bars-styles__root {
    justify-content: center;
    margin-right: 0%;
  }
  
  .ladder_landing_pages_so_good_hero__lttd-bars-styles__bars-container {
    width: 75%;
    height: 6px;
  }

}
.ladder_adjust_offer__rungs-adjust-offer-inner-styles__select {
  margin-top: 10px;
}
.ladder_ux_panels__home-image-aliyah-family-styles__image-container {
  z-index: -1;
}

.ladder_ux_panels__home-image-aliyah-family-styles__image {
  width: 100%;
  max-width: 1500px;
  max-height: 690px;
  min-height: 540px;
  position: absolute;
}

.ladder_ux_panels__home-image-aliyah-family-styles__image-desktop {
  display: block;
  right: 0;
  bottom: 0;
  object-fit: contain;
  object-position: right;
}

.ladder_ux_panels__home-image-aliyah-family-styles__image-mobile {
  display: none;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_panels__home-image-aliyah-family-styles__image {
    width: 85%;
    min-width: 1024px;
  }
  
  .ladder_ux_panels__home-image-aliyah-family-styles__image-b-desktop {
    right: unset;
    left: 100px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_panels__home-image-aliyah-family-styles__image {
    width: 100%;
    height: auto;
    max-height: 620px;
    min-height: 550px;
    bottom: 0;
  }
  
  .ladder_ux_panels__home-image-aliyah-family-styles__image-desktop {
    display: none;
  }
  
  .ladder_ux_panels__home-image-aliyah-family-styles__image-mobile {
    display: block;
  }

}
.ladder_financial_advisors_laddering__customer-quote-styles__root {
  display: flex;
  background: linear-gradient(to bottom, #E7EEEF 0%,#E7EEEF 50%,#FFFFFF 0%,#FFFFFF 100%);
  justify-content: center;
  width: 100%;
}

.ladder_financial_advisors_laddering__customer-quote-styles__container {
  display: flex;
  width: 90%;
  background-color: #F7F2EF;
  flex-direction: row;
  padding: 0 0 0 130.0px;
  justify-content: space-between;
  align-items: center;
}

.ladder_financial_advisors_laddering__customer-quote-styles__image-container {
  display: grid;
  height: 100%;
  flex-basis: 30%;
  align-items: end;
}

.ladder_financial_advisors_laddering__customer-quote-styles__text-container {
  display: flex;
  align-items: center;
  flex-basis: 60%;
  height: 100%;
}

@media (max-width: 499px) {

  
  
  .ladder_financial_advisors_laddering__customer-quote-styles__container {
    padding: 10.0px 30.0px;
  }
  
  .ladder_financial_advisors_laddering__customer-quote-styles__image-container {
    display: none;
  }
  
  .ladder_financial_advisors_laddering__customer-quote-styles__text-container {
    flex-basis: 100%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_financial_advisors_laddering__customer-quote-styles__container {
    padding: 10.0px 30.0px;
  }
  
  .ladder_financial_advisors_laddering__customer-quote-styles__image-container {
    display: none;
  }
  
  .ladder_financial_advisors_laddering__customer-quote-styles__text-container {
    flex-basis: 100%;
  }

}
.ladder_account_payment_history__info-card-styles__root {
  border: 1px solid #808080;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 25.0px 0 15.0px 0;
}

.ladder_account_payment_history__info-card-styles__root-margin {
  margin: 10.0px;
}

.ladder_account_payment_history__info-card-styles__header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: start;
  width: 100%;
  padding-bottom: 15.0px;
  padding-left: 50.0px;
  padding-right: 50.0px;
}

.ladder_account_payment_history__info-card-styles__padded {
  margin-left: auto;
}

.ladder_account_payment_history__info-card-styles__root-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  max-width: 1100px;
  text-align: left;
}

.ladder_account_payment_history__info-card-styles__root-content>* {
  width: 100%;
}

.ladder_account_payment_history__info-card-styles__error-border {
  border: 4px solid #CB3A3A;
}

@media (max-width: 499px) {

  
  
  .ladder_account_payment_history__info-card-styles__root {
    padding: 0;
  }
  
  .ladder_account_payment_history__info-card-styles__header {
    flex-direction: column;
    padding: 10.0px 15.0px;
  }
  
  .ladder_account_payment_history__info-card-styles__padded {
    margin: 0;
  }

}
.ladder_account_policy__policy-document-entry-styles__rungs-link {
  padding: 0 20px;
}

@media (max-width: 499px) {

  
  
  .ladder_account_policy__policy-document-entry-styles__link-wrapper {
    width: 100%;
  }

}
.ladder_agents_agent_assisted_app_views_review__gray-bar-styles__root {
  border-top: 1px #D0D0D0 solid;
  width: 98%;
  margin: 20px 0 20px 5.0px;
  height: 1px;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_agent_assisted_app_views_review__gray-bar-styles__root {
    width: 100%;
    margin: 0 0 0 5.0px;
  }

}
.ladder_account_next_views_messages__ViewThread-styles__buttons-container {
  display: flex;
  box-sizing: content-box;
  align-items: center;
  padding: 20px 5.0px;
  justify-content: flex-end;
}

.ladder_account_next_views_messages__ViewThread-styles__button {
  margin: 10.0px;
}

.ladder_account_next_views_messages__ViewThread-styles__file-spinner {
  display: inline-flex !important;
  vertical-align: text-top;
}

@media (max-width: 499px) {

  
  
  .ladder_account_next_views_messages__ViewThread-styles__\% {
    padding-bottom: 20px;
  }
  
  .ladder_account_next_views_messages__ViewThread-styles__buttons-container {
    flex-direction: column-reverse;
    width: 100%;
  }

}
.ladder_agents_agent_assisted_app_views_decision__bike-repair-image-styles__container {

}

.ladder_agents_agent_assisted_app_views_decision__bike-repair-image-styles__image {
  max-width: 387px;
  width: 100%;
  height: auto;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_agent_assisted_app_views_decision__bike-repair-image-styles__container {
    display: none;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_agent_assisted_app_views_decision__bike-repair-image-styles__container {
    display: none;
  }

}
.ladder_financial_advisors_laddering__scenario-item-styles__content-video-panel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ladder_financial_advisors_laddering__scenario-item-styles__video-container {
  position: relative;
}

.ladder_financial_advisors_laddering__scenario-item-styles__flex-text-grid {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.ladder_financial_advisors_laddering__scenario-item-styles__flex-column-align-left {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.ladder_financial_advisors_laddering__scenario-item-styles__flex-assumptions-and-specs {
  display: flex;
  flex-direction: column;
  flex-basis: 30%;
  margin-right: 30.0px;
}

.ladder_financial_advisors_laddering__scenario-item-styles__text-block {
  flex-basis: 50%;
  padding: 20px 0;
}

.ladder_financial_advisors_laddering__scenario-item-styles__video-container {
  display: flex;
  flex-basis: 70%;
  align-items: flex-start;
}

@media (max-width: 499px) {

  
  
  .ladder_financial_advisors_laddering__scenario-item-styles__content-video-panel {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_financial_advisors_laddering__scenario-item-styles__content-video-panel {
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }

}
.ladder_work_home__get-started-section-styles__root {
  width: 100%;
}

.ladder_work_home__get-started-section-styles__form-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ladder_work_home__get-started-section-styles__form-wrapper .ladder_work_home__get-started-section-styles__confirmation-wrapper {
  visibility: hidden;
  position: absolute;
}

.ladder_work_home__get-started-section-styles__form-wrapper.ladder_work_home__get-started-section-styles__submitted .ladder_work_home__get-started-section-styles__input-wrapper {
  visibility: hidden;
}

.ladder_work_home__get-started-section-styles__form-wrapper.ladder_work_home__get-started-section-styles__submitted .ladder_work_home__get-started-section-styles__confirmation-wrapper {
  visibility: initial;
}

.ladder_work_home__get-started-section-styles__input-group {
  width: 60%;
}

.ladder_work_home__get-started-section-styles__input-wrapper {
  padding: 10.0px;
  width: 100%;
}

@media (max-width: 499px) {

  
  
  .ladder_work_home__get-started-section-styles__input-group {
    width: 100%;
  }

}
@media (min-width: 1025px) {

  
  
  .ladder_onboarding_spouse_connect_2__spouse-connect-heading-styles__header {
    width: 120%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_onboarding_spouse_connect_2__spouse-connect-heading-styles__header {
    font-size: 30px;
  }

}
.ladder_ux_logos__tomorrow-logo-styles__icon {
  height: 56.0px;
  margin-left: 28.8px;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_logos__tomorrow-logo-styles__icon {
    height: 36.8px;
    margin-left: 16px;
  }

}

.ladder_ux_logos__tomorrow-logo-styles__quick-start-icon {
  height: 20.8px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_logos__tomorrow-logo-styles__quick-start-icon {
    height: 20.8px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_logos__tomorrow-logo-styles__quick-start-icon {
    height: 16px;
  }

}
.tile-a .ladder_widget_ui_v2__height-field-styles__root {
  width: 130.0px;
  margin-top: 10.0px;
}

.tile-b .ladder_widget_ui_v2__height-field-styles__root {
  width: 95.0px;
}

.landscape .ladder_widget_ui_v2__height-field-styles__root {
  width: 32%;
}
.ladder_guide_common__takeaways-panel-styles__container {
  background-color: #CFDCDE;
}

@media (min-width: 1025px) {

  
  
  .ladder_guide_common__takeaways-panel-styles__container {
    width: 100%;
    height: 250px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_guide_common__takeaways-panel-styles__container {
    width: 100%;
    padding-top: 60px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_guide_common__takeaways-panel-styles__container {
    width: 100%;
    padding-top: 60px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_guide_common__takeaways-panel-styles__container {
    padding-bottom: 60px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_guide_common__takeaways-panel-styles__container {
    width: 100%;
    padding-top: 60px;
  }

}
.ladder_account_shared_components__rungs-card-wrapper-styles__rungs-card-wrapper {
  border: 1px solid #808080;
  display: flex;
  flex: 1;
  padding: 25.0px 50.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_account_shared_components__rungs-card-wrapper-styles__rungs-card-wrapper {
    padding: 15.0px 20px;
  }

}
.ladder_account_next_views_messages__thread-card-styles__card-text {
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 48px;
  justify-content: space-between;
}

.ladder_account_next_views_messages__thread-card-styles__outer-card {
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #808080;
  display: flex;
  padding: 25.0px 50.0px 25.0px 10.0px;
  cursor: pointer;
}

.ladder_account_next_views_messages__thread-card-styles__dot-spacer {
  margin: auto 15.0px;
  height: 10px;
  width: 10px;
  border-radius: 100%;
}

.ladder_account_next_views_messages__thread-card-styles__dot {
  background-color: #000000;
}

@media (max-width: 499px) {

  
  
  .ladder_account_next_views_messages__thread-card-styles__outer-card {
    border: none;
    width: 100vw;
    border-top: 1px solid #808080;
    margin: 0;
    padding: 15.0px 10.0px;
  }
  
  .ladder_account_next_views_messages__thread-card-styles__outer-card:last-child {
    border-bottom: 1px solid #808080;
  }
  
  .ladder_account_next_views_messages__thread-card-styles__card-text {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .ladder_account_next_views_messages__thread-card-styles__dot-spacer {
    margin: 5.0px 10.0px;
  }

}
.ladder_trustage_upper_funnel_routing__upper-funnel-routing-trustage-ui-styles__wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.ladder_trustage_upper_funnel_routing__upper-funnel-routing-trustage-ui-styles__content {
  width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ladder_trustage_upper_funnel_routing__upper-funnel-routing-trustage-ui-styles__content>* {
  max-width: 630px;
  width: 100%;
}

.ladder_trustage_upper_funnel_routing__upper-funnel-routing-trustage-ui-styles__back-arrow {
  display: flex;
}

.ladder_trustage_upper_funnel_routing__upper-funnel-routing-trustage-ui-styles__subsection {
  margin: 30.0px 0 40px 0;
}

.ladder_trustage_upper_funnel_routing__upper-funnel-routing-trustage-ui-styles__subsection>*:first-child {
  margin-bottom: 10.0px;
}

.ladder_trustage_upper_funnel_routing__upper-funnel-routing-trustage-ui-styles__continue-with-ladder {
  margin: 20px 0;
}

@media (min-width: 1025px) {

  
  
  
  
  .ladder_trustage_upper_funnel_routing__upper-funnel-routing-trustage-ui-styles__content>.ladder_trustage_upper_funnel_routing__upper-funnel-routing-trustage-ui-styles__allow-wider {
    max-width: unset;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  
  
  .ladder_trustage_upper_funnel_routing__upper-funnel-routing-trustage-ui-styles__content>.ladder_trustage_upper_funnel_routing__upper-funnel-routing-trustage-ui-styles__allow-wider {
    max-width: unset;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_trustage_upper_funnel_routing__upper-funnel-routing-trustage-ui-styles__content {
    width: 100%;
  }
  
  .ladder_trustage_upper_funnel_routing__upper-funnel-routing-trustage-ui-styles__content>* {
    max-width: 310px;
  }
  
  .ladder_trustage_upper_funnel_routing__upper-funnel-routing-trustage-ui-styles__subsection {
    margin: 20px 0;
  }
  
  .ladder_trustage_upper_funnel_routing__upper-funnel-routing-trustage-ui-styles__subsection>*:first-child {
    margin-bottom: 10.0px;
  }
  
  .ladder_trustage_upper_funnel_routing__upper-funnel-routing-trustage-ui-styles__continue-with-ladder {
    margin: 30.0px 0 20px 0;
  }

}
.ladder_ux_info_expander__two-col-expander-styles__root {
  padding: 1px;
}

.ladder_ux_info_expander__two-col-expander-styles__root:focus {
  outline-color: #D1A98F;
}

.ladder_ux_info_expander__two-col-expander-styles__inline {
  display: inline;
}

.ladder_ux_info_expander__two-col-expander-styles__inline-block {
  display: inline-block;
}

.ladder_ux_info_expander__two-col-expander-styles__icon {
  cursor: pointer;
}

.ladder_ux_info_expander__two-col-expander-styles__float-right {
  float: right;
}
.ladder_ux_checkbox__checkbox-slider-styles__slider {
  width: 60px;
  height: 20px;
  align-items: center;
  padding: 4.0px;
  position: relative;
  cursor: pointer;
  border-radius: 16px;
  box-sizing: content-box;
  display: flex;
  background: #707070;
  color: #FFFFFF;
}

.ladder_ux_checkbox__checkbox-slider-styles__slider-dot {
  padding: inherit;
  position: absolute;
  left: 0;
  transition: 0.4s;
}

.ladder_ux_checkbox__checkbox-slider-styles__slider-dot-inner {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #FFFFFF;
}

.ladder_ux_checkbox__checkbox-slider-styles__disabled {
  cursor: auto;
}

.ladder_ux_checkbox__checkbox-slider-styles__text {
  position: absolute;
  padding: inherit;
  letter-spacing: 2px;
  font-size: 12px;
  transition: 0.4s;
}

.ladder_ux_checkbox__checkbox-slider-styles__text-on {
  left: 5px;
  opacity: 0;
}

.ladder_ux_checkbox__checkbox-slider-styles__text-off {
  right: 5px;
  opacity: 1;
}

.ladder_ux_checkbox__checkbox-slider-styles__checked.ladder_ux_checkbox__checkbox-slider-styles__slider {
  background: #3ACA95;
}

.ladder_ux_checkbox__checkbox-slider-styles__checked.ladder_ux_checkbox__checkbox-slider-styles__spring-rain {
  background: #AFC8AF;
}

.ladder_ux_checkbox__checkbox-slider-styles__checked .ladder_ux_checkbox__checkbox-slider-styles__slider-dot {
  left: calc(100% - 29px);
}

.ladder_ux_checkbox__checkbox-slider-styles__checked .ladder_ux_checkbox__checkbox-slider-styles__text-on {
  opacity: 1;
}

.ladder_ux_checkbox__checkbox-slider-styles__checked .ladder_ux_checkbox__checkbox-slider-styles__text-off {
  opacity: 0;
}
.ladder_claims__notify-us-styles__root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 260px 130.0px 260px;
  background-color: #F7F2EF;
}

.ladder_claims__notify-us-styles__header {
  text-align: center;
  align-items: center;
  margin: 60px 0 30.0px 0;
}

.ladder_claims__notify-us-styles__subheader {
  text-align: center;
  margin-bottom: 30.0px;
}

.ladder_claims__notify-us-styles__progress-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
}

.ladder_claims__notify-us-styles__progress-bar-item {
  text-align: center;
  padding: 10.0px;
  font-size: 20px;
}

.ladder_claims__notify-us-styles__progress-bar-line {
  width: 100%;
  overflow: hidden;
  border-radius: 3px;
}

.ladder_claims__notify-us-styles__progress-circle-default {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0);
  text-align: center;
  padding: 9px 10.0px 0 10.0px;
}

.ladder_claims__notify-us-styles__progress-circle-unfilled {
  border: 1px solid #000000;
}

.ladder_claims__notify-us-styles__progress-circle-filled {
  background-color: #975C46;
  color: #FFFFFF;
  border: 1px solid #975C46;
}

@media (max-width: 499px) {

  
  
  .ladder_claims__notify-us-styles__root {
    padding: 0 15.0px 50.0px 15.0px;
  }
  
  .ladder_claims__notify-us-styles__progress-bar {
    width: 100%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_claims__notify-us-styles__root {
    padding: 0 15.0px 50.0px 15.0px;
  }
  
  .ladder_claims__notify-us-styles__progress-bar {
    width: 100%;
  }

}
.ladder_account__lab-results-styles__row {
  display: table;
  width: 100%;
  text-align: left;
  border-bottom: 1px solid #D0D0D0;
}

.ladder_account__lab-results-styles__row:last-child {
  border-bottom: none;
}

.ladder_account__lab-results-styles__cell {
  display: table-cell;
  width: 50%;
}

.ladder_account__lab-results-styles__header {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.ladder_account__lab-results-styles__text {
  width: 50%;
  text-align: left;
  margin-bottom: 10.0px;
}

.ladder_account__lab-results-styles__button {
  margin-top: 30.0px;
}

.ladder_account__lab-results-styles__full-width {
  width: 100%;
}

@media (max-width: 499px) {

  
  
  .ladder_account__lab-results-styles__button {
    width: 100%;
  }

}
.ladder_financial_advisors_home__top-panel-body-styles__\% {
  margin-top: 20px;
}

@media (min-width: 1025px) {

  
  
  .ladder_financial_advisors_home__top-panel-body-styles__\% {
    margin-bottom: 60px;
  }

}
.ladder_ux_banner__info-banner-styles__\% {
  background-color: #B9CDEC;
  z-index: 11;
}
.ladder_careers__ui-gallery-styles__wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  line-height: 0px;
}

.ladder_careers__ui-gallery-styles__wrapper > *:not(:last-child) {
  margin-right: 30px;
}

.ladder_careers__ui-gallery-styles__wrapper .ladder_careers__ui-gallery-styles__scale {
  width: auto;
  height: auto;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_careers__ui-gallery-styles__wrapper {
    flex-direction: column;
  }
  
  .ladder_careers__ui-gallery-styles__wrapper > *:not(:last-child) {
    margin-right: 0px;
    margin-bottom: 30px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_careers__ui-gallery-styles__scale {
    max-width: 316px;
    height: auto;
  }
  
  .ladder_careers__ui-gallery-styles__wrapper {
    flex-direction: column;
    padding: auto;
  }
  
  .ladder_careers__ui-gallery-styles__wrapper > *:not(:last-child) {
    margin-right: 0px;
    margin-bottom: 15px;
  }

}
.ladder_account_shared_components__section-tab-styles__tab {
  padding-bottom: 10px;
  white-space: nowrap;
  cursor: pointer;
  color: #5D5D5D;
}

.ladder_account_shared_components__section-tab-styles__agent-dashboard-tab {
  background-color: #E7EEEF;
  color: #5D5D5D;
  width: 280px;
  height: 80px;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 80px;
}

.ladder_account_shared_components__section-tab-styles__agent-dashboard-tab-selected {
  background-color: #464F50;
  color: #FFFFFF;
}

.ladder_account_shared_components__section-tab-styles__tab.ladder_account_shared_components__section-tab-styles__selected {
  color: #000000;
  border-bottom: 5px solid;
  border-bottom-color: #D8BCAF;
}
.ladder_ux_form__text-input-inner-styles__root-common {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: relative;
}

.ladder_ux_form__text-input-inner-styles__text-common {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-grow: 1;
}

.ladder_ux_form__text-input-inner-styles__text-common:-webkit-autofill {
  transition: background-color 0s 7200s;
}

.ladder_ux_form__text-input-inner-styles__text-common:disabled {
  color: #707070;
}

.ladder_ux_form__text-input-inner-styles__text-common:disabled:hover {
  cursor: not-allowed;
}

.ladder_ux_form__text-input-inner-styles__width-1ch {
  width: 3ch;
}

.ladder_ux_form__text-input-inner-styles__width-2ch {
  width: 4ch;
}

.ladder_ux_form__text-input-inner-styles__width-3ch {
  width: 5ch;
}

.ladder_ux_form__text-input-inner-styles__width-5ch {
  width: 7ch;
}

.ladder_ux_form__text-input-inner-styles__width-7ch {
  width: 9ch;
}

.ladder_ux_form__text-input-inner-styles__width-9ch {
  width: 11ch;
}

.ladder_ux_form__text-input-inner-styles__width-10ch {
  width: 12ch;
}

.ladder_ux_form__text-input-inner-styles__width-12ch {
  width: 14ch;
}

.ladder_ux_form__text-input-inner-styles__height-full {
  height: 100%;
}

.ladder_ux_form__text-input-inner-styles__border-none {
  border: none;
}

.ladder_ux_form__text-input-inner-styles__root-floating-placeholder {
  position: relative;
}

.ladder_ux_form__text-input-inner-styles__floating-placeholder {
  position: absolute;
  padding-left: 2px;
  pointer-events: none;
  line-height: 14px;
}

.ladder_ux_form__text-input-inner-styles__floating-placeholder-padding-m {
  padding-left: 12.0px;
}

.ladder_ux_form__text-input-inner-styles__text-padding-m {
  padding-left: 12.0px;
}

.ladder_ux_form__text-input-inner-styles__text-input-icon-right-padding-m {
  padding-right: 12.0px;
}

@media (min-width: 1025px) {

  
  
  .ladder_ux_form__text-input-inner-styles__floating-placeholder {
    transition-timing-function: linear;
    transition-duration: 0.2s;
    transition-property: top, color, font-size;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_form__text-input-inner-styles__floating-placeholder {
    transition-timing-function: linear;
    transition-duration: 0.2s;
    transition-property: top, color, font-size;
  }

}

.ladder_ux_form__text-input-inner-styles__floating-placeholder-color-light-gray {
  color: #D0D0D0;
  opacity: 1;
}

.ladder_ux_form__text-input-inner-styles__floating-placeholder-color-medium-gray {
  color: #707070;
  opacity: 1;
}

.ladder_ux_form__text-input-inner-styles__floating-placeholder-isfloating-s {
  top: 2px;
  color: #000000;
  font-size: 8px;
}

.ladder_ux_form__text-input-inner-styles__floating-placeholder-isfloating-m {
  top: 3px;
  color: #000000;
  font-size: 10px;
}

.ladder_ux_form__text-input-inner-styles__floating-placeholder-isfloating-l {
  top: 10px;
  color: #000000;
  font-size: 10px;
}

.ladder_ux_form__text-input-inner-styles__input-with-floating-placeholder {
  padding-top: 10px;
}

.ladder_ux_form__text-input-inner-styles__prefix-centered.ladder_ux_form__text-input-inner-styles__root-common {
  position: relative;
  justify-content: center;
  width: 50%;
  margin: auto;
  margin-top: 24.0px;
}

.ladder_ux_form__text-input-inner-styles__prefix-wrapper {
  position: absolute;
  display: flex;
  justify-content: center;
}

.ladder_ux_form__text-input-inner-styles__centered-prefix {
  color: #000000;
  font-weight: 200;
}

.ladder_ux_form__text-input-inner-styles__centered-prefix span {
  vertical-align: sub;
}

.ladder_ux_form__text-input-inner-styles__prefix-padding {
  visibility: hidden;
}

.ladder_ux_form__text-input-inner-styles__prefixed-input-value {
  text-align: center;
  z-index: 2;
  font-size: 2em;
}

.ladder_ux_form__text-input-inner-styles__prefixed-input-placeholder {
  color: lightgray;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_form__text-input-inner-styles__prefix-centered.ladder_ux_form__text-input-inner-styles__root-common {
    width: 80%;
  }
  
  .ladder_ux_form__text-input-inner-styles__prefixed-input-value {
    font-size: 2em;
  }

}

.ladder_ux_form__text-input-inner-styles__offset-focused-border {
  margin-left: -1px;
}

.ladder_ux_form__text-input-inner-styles__completion-prompt {
  position: absolute;
  margin-left: 1px;
}
.ladder_account_next_cards_need_help__our-team-styles__\% {
  margin-top: 20px;
}

.ladder_account_next_cards_need_help__our-team-styles__\% *:first-child {
  margin-bottom: 2.5px;
}
.ladder_calculator_rebrand__page-one-styles__root {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #FFFFFF;
  align-items: center;
}

.ladder_calculator_rebrand__page-one-styles__content-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 100%;
  margin: 60px 0;
}

.ladder_calculator_rebrand__page-one-styles__step-one {
  color: #5D5D5D;
}

.ladder_calculator_rebrand__page-one-styles__title {
  margin: 20px 0 20px 0;
}

.ladder_calculator_rebrand__page-one-styles__header {
  margin: 0 0 30.0px 0;
}

.ladder_calculator_rebrand__page-one-styles__button {
  margin: 30.0px 0 0 0;
}

@media (min-width: 1025px) {

  
  
  .ladder_calculator_rebrand__page-one-styles__button {
    max-width: 150px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_calculator_rebrand__page-one-styles__title {
    font-size: 20px;
  }
  
  .ladder_calculator_rebrand__page-one-styles__content-wrapper {
    width: 90%;
    margin: 30.0px 0 20px 0;
  }

}
.ladder_cover_me_asap_views_billing_affordability__recommended-coverage-option-message-styles__root {
  background-color: #DBE6DB;
  border-radius: 8px;
  padding: 15.0px 15.0px 15.0px 20px;
}

.ladder_cover_me_asap_views_billing_affordability__recommended-coverage-option-message-styles__content {
  display: flex;
  flex-direction: row;
  align-items: top;
  gap: 20px;
}
.ladder_account_shared_components__rungs-card-title-styles__\% {
  display: flex;
  flex-grow: 1;
  align-items: center;
}

@media (max-width: 499px) {

  
  
  .ladder_account_shared_components__rungs-card-title-styles__\% {
    justify-content: space-between;
  }
  
  
  
  .ladder_account_shared_components__rungs-card-title-styles__\%>*:first-child {
    margin-right: 10.0px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_account_shared_components__rungs-card-title-styles__\% {
    justify-content: flex-start;
  }
  
  
  
  .ladder_account_shared_components__rungs-card-title-styles__\%>*:first-child {
    margin-right: 30.0px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account_shared_components__rungs-card-title-styles__\% {
    justify-content: flex-start;
  }
  
  
  
  .ladder_account_shared_components__rungs-card-title-styles__\%>*:first-child {
    margin-right: 30.0px;
  }

}
.ladder_form_common__scrollable-regular-field-styles__scrollable-root {
  width: 100%;
}

.ladder_form_common__scrollable-regular-field-styles__top-level-scrollable-root {
  margin-bottom: 32px;
}

.ladder_form_common__scrollable-regular-field-styles__reflexive-caption-container {
  margin-bottom: 32px;
}

.ladder_form_common__scrollable-regular-field-styles__scrollable-tips {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ladder_form_common__scrollable-regular-field-styles__error-box {
  margin-top: 8px;
  margin-bottom: 8px;
  min-height: 16px;
}

@media (max-width: 499px) {

  
  
  .ladder_form_common__scrollable-regular-field-styles__top-level-scrollable-root {
    margin-bottom: 24px;
  }
  
  .ladder_form_common__scrollable-regular-field-styles__reflexive-caption-container {
    margin-bottom: 24px;
  }

}
.landscape .ladder_widget_ui_v2__date-of-birth-field-styles__root {
  width: 32%;
}
.ladder_views_decision_poca__tic-offer-styles__accept-and-pay {
  padding: 10.0px;
  max-width: 352px;
}

.ladder_views_decision_poca__tic-offer-styles__agreement-container {
  padding-top: 30.0px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_views_decision_poca__tic-offer-styles__agreement-container {
    padding: 0;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_views_decision_poca__tic-offer-styles__cta-container {
    width: 100%;
    padding-top: 20px;
  }
  
  .ladder_views_decision_poca__tic-offer-styles__agreement-container {
    padding: 0;
  }

}
.ladder_ny_form_quick_estimate__quick-estimate-dob-styles__quick-estimate-dob {
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.ladder_apply_progress_bar__progress-bar-section-styles__root {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.ladder_apply_progress_bar__progress-bar-section-styles__one-section {
  width: 100%;
}

.ladder_apply_progress_bar__progress-bar-section-styles__multiple-sections {
  width: 33.33%;
}

.ladder_apply_progress_bar__progress-bar-section-styles__section-title {
  padding-right: 25px;
}
.ladder_megaphone_refer__your-earnings-panel-styles__earning-panel-container {
  border: 3px solid #7A8A8C;
  border-radius: 6px;
  margin-top: 50.0px;
  min-width: 320px;
  display: flex;
  height: 216px;
  flex-direction: column;
}

.ladder_megaphone_refer__your-earnings-panel-styles__earnings-title-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px 25.0px 9px 25.0px;
  background-color: #E7EEEF;
  border-radius: 6px 6px 0px 0px;
}

.ladder_megaphone_refer__your-earnings-panel-styles__earnings-info {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-grow: 1;
  padding: 0 30.0px;
}

.ladder_megaphone_refer__your-earnings-panel-styles__balance-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 499px) {

  
  
  .ladder_megaphone_refer__your-earnings-panel-styles__your-earning-header {
    line-height: 30.0px;
    font-size: 20px;
  }

}
.ladder_agents_dashboard_contacts__contact-detail-page-entry-field-styles__section-styling {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 28px;
  justify-content: flex-start;
}

.ladder_agents_dashboard_contacts__contact-detail-page-entry-field-styles__space-between {
  justify-content: space-between;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard_contacts__contact-detail-page-entry-field-styles__section-styling {
    width: 100%;
  }

}
.ladder_widget_ui_v2__panel-error-message-styles__root {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 16px;
}

.ladder_widget_ui_v2__panel-error-message-styles__button-container {
  display: flex;
  flex-grow: 2;
}

.ladder_widget_ui_v2__panel-error-message-styles__button {
  display: flex;
  align-self: flex-end;
}
.ladder_guide_what_is_life_insurance__family-picture-panel-styles__container {
  width: 100%;
  margin-top: 60px;
}

.ladder_guide_what_is_life_insurance__family-picture-panel-styles__image-container {
  display: inline-block;
}

.ladder_guide_what_is_life_insurance__family-picture-panel-styles__image {
  width: 64%;
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 750px;
}

.ladder_guide_what_is_life_insurance__family-picture-panel-styles__markdown-container {
  position: relative;
  z-index: 1;
}

@media (min-width: 1025px) {

  
  
  .ladder_guide_what_is_life_insurance__family-picture-panel-styles__second-to-last-paragraph {
    width: 81%;
  }
  
  .ladder_guide_what_is_life_insurance__family-picture-panel-styles__last-paragraph {
    width: 40%;
  }
  
  .ladder_guide_what_is_life_insurance__family-picture-panel-styles__color-container {
    padding-left: 70px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_guide_what_is_life_insurance__family-picture-panel-styles__color-container {
    background-color: #BBDAD7;
    padding-top: 60px;
    padding-bottom: 20%;
    position: relative;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_guide_what_is_life_insurance__family-picture-panel-styles__color-container {
    background-color: #BBDAD7;
    padding-top: 60px;
    padding-bottom: 20%;
    position: relative;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_guide_what_is_life_insurance__family-picture-panel-styles__last-paragraph {
    width: 48%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_guide_what_is_life_insurance__family-picture-panel-styles__color-container {
    background-color: #BBDAD7;
    padding-top: 60px;
    padding-bottom: 60%;
    position: relative;
  }
  
  .ladder_guide_what_is_life_insurance__family-picture-panel-styles__image {
    width: 90%;
  }

}
.ladder_app_review__submit-wrapper-styles__sticky-root {
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 2;
  background: white;
}

.ladder_app_review__submit-wrapper-styles__with-border {
  border-top: 1px solid #D0D0D0;
}

.ladder_app_review__submit-wrapper-styles__without-border {
  border-top: 0;
}

.ladder_app_review__submit-wrapper-styles__sticky-overlay {
  margin: 15.0px 20px;
}

.ladder_app_review__submit-wrapper-styles__nonstick {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 540px;
}
.ladder_account__RungsCurrentBills-styles__inline-div {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.ladder_account__RungsCurrentBills-styles__savings-text {
  margin-top: 10.0px;
}

.ladder_account__RungsCurrentBills-styles__pseudo-footer {
  margin-top: 10.0px;
}
.ladder_form_height__height-singleselection-styles__horizontal {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.ladder_form_height__height-singleselection-styles__vertical {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.ladder_form_height__height-singleselection-styles__margin-top {
  margin-top: 10.0px;
}

.ladder_form_height__height-singleselection-styles__margin-left {
  margin-left: 10.0px;
}

.ladder_form_height__height-singleselection-styles__margin-right {
  margin-right: 10.0px;
}
.ladder_ux_carousel__carousel-styles__carousel {
  display: flex;
  position: relative;
  flex-direction: column;
  z-index: 1;
  width: 100%;
}

.ladder_ux_carousel__carousel-styles__added-gap {
  gap: 10px;
}

.ladder_ux_carousel__carousel-styles__carousel-slides {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: scroll;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.ladder_ux_carousel__carousel-styles__carousel-slides::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

.ladder_ux_carousel__carousel-styles__hide-scroll-bar {
  overflow-x: hidden;
}

.ladder_ux_carousel__carousel-styles__carousel-slide {
  display: flex;
  flex-direction: row;
  flex: none;
  width: 100%;
  position: relative;
  justify-content: center;
  transition: transform 500ms;
}

.ladder_ux_carousel__carousel-styles__carousel-slide-peek-neighbors {
  width: 80%;
}

.ladder_ux_carousel__carousel-styles__carousel-slide-peek-neighbors:first-child {
  margin-left: 10%;
}

.ladder_ux_carousel__carousel-styles__carousel-slide-peek-neighbors:last-child {
  padding-right: 10%;
}

.ladder_ux_carousel__carousel-styles__control-dots-wrapper-bottom {
  width: 100%;
  bottom: 15px;
  left: 0;
  right: 0;
}
.ladder_financial_advisors_home__how-it-works-title-styles__\% {
  align-self: center;
  margin-top: 80px;
}

@media (min-width: 1025px) {

  
  
  .ladder_financial_advisors_home__how-it-works-title-styles__\% {
    margin-top: 120px;
  }

}
.ladder_savings_steps__how-it-works-styles__wrapper {
  background-color: #F7F2EF;
  width: 100%;
}

@media (max-width: 499px) {

  
  
  .ladder_savings_steps__how-it-works-styles__wrapper {
    width: 90%;
    padding: 5%;
  }

}
.ladder_agents_dashboard_contacts__mock-contact-card-styles__mock {
  width: 100%;
  height: 70px;
  align-items: center;
  padding: 20px;
  moz-border-radius: 30px;
  background-color: #D9D9D9;
  border-radius: 28px;
  display: flex;
  webkit-border-radius: 30px;
  opacity: 0.4;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard_contacts__mock-contact-card-styles__mock {
    height: 60px;
  }

}
.ladder_ny_form_date__date-field-precision-month-styles__root {
  display: flex;
}

.ladder_ny_form_date__date-field-precision-month-styles__month {
  flex-grow: 1;
  margin-right: 5.0px;
}

.ladder_ny_form_date__date-field-precision-month-styles__year {
  flex-grow: 1;
  margin-left: 5.0px;
}
.ladder_ny_form_authorizations__root-styles__terms-container {
  padding: 20px 0;
  display: flex;
  align-items: flex-start;
}

.ladder_ny_form_authorizations__root-styles__checkbox-container {
  padding-right: 20px;
}

@media (max-width: 499px) {

  
  
  .ladder_ny_form_authorizations__root-styles__terms-container {
    padding: 0;
  }

}
.ladder_onboarding_spouse_connections__refer-friends-modal-styles__text-1 {
  margin-top: 20px;
}

.ladder_onboarding_spouse_connections__refer-friends-modal-styles__megaphone {
  margin-top: 40px;
}

.ladder_onboarding_spouse_connections__refer-friends-modal-styles__text-2 {
  margin-top: 40px;
  margin-bottom: 30.0px;
}

.ladder_onboarding_spouse_connections__refer-friends-modal-styles__title {
  position: absolute;
  left: 0;
  top: 0;
  padding-top: 25.0px;
  padding-left: 5%;
}

.ladder_onboarding_spouse_connections__refer-friends-modal-styles__link-and-button-container {
  display: flex;
  justify-content: space-between;
  min-height: 50.0px;
  width: 100%;
  border: 2px solid #D9D9D9;
  align-items: center;
}

.ladder_onboarding_spouse_connections__refer-friends-modal-styles__referral-url {
  text-overflow: ellipsis;
  padding-left: 20px;
  padding-right: 20px;
  overflow-x: hidden;
  max-width: 100%;
  white-space: nowrap;
}

.ladder_onboarding_spouse_connections__refer-friends-modal-styles__copy-url-button {
  max-width: 220px;
  width: 25%;
  position: relative;
  right: -2px;
  margin-top: -2px;
  margin-bottom: -2px;
}

.ladder_onboarding_spouse_connections__refer-friends-modal-styles__start-app-button {
  margin-top: 15.0px;
}
.ladder_agents_agent_assisted_app_views_app_status__root-layout-styles__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 114px 0;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_agent_assisted_app_views_app_status__root-layout-styles__container {
    padding: 30px 0;
    width: 90%;
    margin: 0 auto;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_agent_assisted_app_views_app_status__root-layout-styles__container {
    padding: 30px 0;
    width: 90%;
    margin: 0 auto;
  }

}
.ladder_careers__ui-activities-styles__root {
  margin-top: 50.0px;
  margin-bottom: 50.0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ladder_careers__ui-activities-styles__constrain-size {
  height: 448px;
  width: 1100px;
  margin-top: 30.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_careers__ui-activities-styles__title {
    margin-bottom: 20px;
  }
  
  .ladder_careers__ui-activities-styles__constrain-size {
    height: 645px;
    width: 100%;
  }

}
.ladder_agents_dashboard_quote__monthly-premium-styles__price {
  display: flex;
  flex-direction: column;
}

@media (min-width: 1025px) {

  
  
  .ladder_agents_dashboard_quote__monthly-premium-styles__price {
    padding-left: 24px;
    gap: 12px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard_quote__monthly-premium-styles__price {
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_dashboard_quote__monthly-premium-styles__price {
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

}
.ladder_guide_life_insurance_cost__quote-picture-panel-styles__container {
  width: 100%;
  margin-top: 60px;
  position: relative;
  background-color: #BBDAD7;
}

.ladder_guide_life_insurance_cost__quote-picture-panel-styles__bottom-text-wrapper {
  padding: 16px 0px;
}

@media (min-width: 1025px) {

  
  
  .ladder_guide_life_insurance_cost__quote-picture-panel-styles__image {
    width: 90%;
  }
  
  .ladder_guide_life_insurance_cost__quote-picture-panel-styles__container {
    padding-top: 4%;
  }
  
  .ladder_guide_life_insurance_cost__quote-picture-panel-styles__image {
    margin-bottom: -3px;
  }
  
  .ladder_guide_life_insurance_cost__quote-picture-panel-styles__markdown-container {
    position: absolute;
    top: 8%;
    right: 8%;
    width: 50%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_guide_life_insurance_cost__quote-picture-panel-styles__markdown-container {
    position: absolute;
    top: 32px;
    margin-left: 60px;
    margin-right: 60px;
  }
  
  .ladder_guide_life_insurance_cost__quote-picture-panel-styles__container {
    padding-top: 12%;
    min-height: 500px;
  }
  
  .ladder_guide_life_insurance_cost__quote-picture-panel-styles__image {
    width: 70%;
    position: absolute;
    bottom: 0;
    left: -26px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_guide_life_insurance_cost__quote-picture-panel-styles__markdown-container {
    width: 90%;
  }
  
  .ladder_guide_life_insurance_cost__quote-picture-panel-styles__container {
    padding-top: 60px;
    padding-bottom: 60%;
  }
  
  .ladder_guide_life_insurance_cost__quote-picture-panel-styles__image {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

}
.ladder_apply_current_page_ui__single-page-jiggy-buttons-container-styles__single-page-back-button {
  border: solid;
}
.ladder_ux_logos__amerisave-logo-styles__icon {
  max-height: 64px;
  max-width: 300px;
  margin-top: 4.0px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_logos__amerisave-logo-styles__icon {
    max-height: 48px;
    max-width: 220px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_logos__amerisave-logo-styles__icon {
    max-height: 40.0px;
    max-width: 190px;
  }

}
.ladder_markdoc_common__th-comp-styles__cell {
  overflow: wrap;
  text-overflow: wrap;
  word-wrap: break-word;
}
.ladder_views_decision_common__trust-panel-content-styles__content {
  width: 66%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 499px) {

  
  
  .ladder_views_decision_common__trust-panel-content-styles__content {
    width: 100%;
  }

}
.ladder_markdoc_common__col-comp-styles__col {
  width: 47.5%;
  overflow: scroll;
}

@media (max-width: 499px) {

  
  
  .ladder_markdoc_common__col-comp-styles__col {
    width: 100%;
  }

}
.ladder_gift_header__gift-dropdown-styles__content {
  white-space: normal;
  min-width: 300px;
  padding: 15.0px;
}

.ladder_gift_header__gift-dropdown-styles__content>div {
  margin-bottom: 10.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_gift_header__gift-dropdown-styles__content {
    padding: 0;
  }

}
@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard_referral_link__referral-link-widget-styles__input-wrapper {
    width: 100%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_dashboard_referral_link__referral-link-widget-styles__input-wrapper {
    width: 100%;
  }

}

.ladder_agents_dashboard_referral_link__referral-link-widget-styles__input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
}

.ladder_agents_dashboard_referral_link__referral-link-widget-styles__input-wrapper {
  display: flex;
  align-items: center;
  height: 70px;
  padding: 1em;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 24px;
  position: relative;
}

.ladder_agents_dashboard_referral_link__referral-link-widget-styles__referral-field {
  font-size: 13px;
  line-height: 20px;
  width: 100%;
}

.ladder_agents_dashboard_referral_link__referral-link-widget-styles__referral-field:focus {
  outline: none;
  user-select: all;
}

.ladder_agents_dashboard_referral_link__referral-link-widget-styles__input {
  display: flex;
  flex-direction: column;
  margin-left: 1em;
  width: 100%;
}

.ladder_agents_dashboard_referral_link__referral-link-widget-styles__input-label {
  color: grey;
  font-size: 10px;
  line-height: 16px;
}
.ladder_agents_agent_assisted_app_views_decision__term-offer-page-styles__margin-top {
  margin-top: 32px;
}
.ladder_ux_expand_card__faqs-expand-card-styles__root {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  transition: all 250ms linear;
}

.ladder_ux_expand_card__faqs-expand-card-styles__root:not(:last-child) {
  border-bottom: 1px solid #D0D0D0;
}

.ladder_ux_expand_card__faqs-expand-card-styles__title-wrapper {
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 12.0px 0;
}

.ladder_ux_expand_card__faqs-expand-card-styles__title-wrapper:hover {
  cursor: pointer;
}

.ladder_ux_expand_card__faqs-expand-card-styles__row {
  display: flex;
  align-items: center;
}

.ladder_ux_expand_card__faqs-expand-card-styles__root-expanded {
  border-bottom: none;
}

.ladder_ux_expand_card__faqs-expand-card-styles__root-expanded.ladder_ux_expand_card__faqs-expand-card-styles__gray-background {
  background-color: #F4F4F4;
}

.ladder_ux_expand_card__faqs-expand-card-styles__root-expanded.ladder_ux_expand_card__faqs-expand-card-styles__mystic-background {
  background-color: #E7EEEF;
}

.ladder_ux_expand_card__faqs-expand-card-styles__icon {
  width: 20.0px;
  height: 20.0px;
  margin: 16px;
}

.ladder_ux_expand_card__faqs-expand-card-styles__description {
  padding: 0 16px 16px 16px;
  text-align: left;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_expand_card__faqs-expand-card-styles__title-wrapper {
    padding: 16px 0;
  }

}
.ladder_form_quick_start__personal-styles__form-fields {
  margin-bottom: 25.0px;
}

.ladder_form_quick_start__personal-styles__form-fields>div {
  margin-bottom: 10.0px;
}

.ladder_form_quick_start__personal-styles__row {
  display: flex;
  justify-content: space-between;
}

.ladder_form_quick_start__personal-styles__row > *:not(:last-child) {
  margin-right: 20px;
}

.ladder_form_quick_start__personal-styles__row>div {
  width: 100%;
}

@media (min-width: 1025px) {

  
  
  .ladder_form_quick_start__personal-styles__form-fields {
    margin-bottom: 30.0px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_form_quick_start__personal-styles__row {
    display: block;
  }
  
  .ladder_form_quick_start__personal-styles__row>div {
    margin-bottom: 10.0px;
  }

}
.ladder_account_next_modals__CancelPolicyModal-styles__educational-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
}

.ladder_account_next_modals__CancelPolicyModal-styles__educational-content>* {
  margin-top: 10.0px;
  margin-bottom: 10.0px;
}

.ladder_account_next_modals__CancelPolicyModal-styles__email-us {
  margin-top: 30.0px;
}
.ladder_views_decision_common__recommended-for-you-modal-styles__button {
  margin-top: 20px;
}
.ladder_lux_markdown_components__ordered-list-styles__root {
  list-style: decimal;
  color: #707070;
  padding-left: 32px;
}

.ladder_lux_markdown_components__ordered-list-styles__no-padding {
  padding-left: 14px;
}

.ladder_lux_markdown_components__ordered-list-styles__font-weight-light {
  font-weight: 300;
}

.ladder_lux_markdown_components__ordered-list-styles__nested-alpha ol {
  list-style-type: lower-alpha;
}
.ladder_account_shared_components__rungs-faqs-section\*-styles__read-more {
  margin-top: 20px;
}
.ladder_financial_advisors_home__HowItWorksFaqsLink-styles__\% {
  margin-top: 48px;
}

@media (min-width: 1025px) {

  
  
  .ladder_financial_advisors_home__HowItWorksFaqsLink-styles__\% {
    margin-top: 80px;
  }

}
.ladder_form_funhouse__Testimonial-styles__testimonial-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ladder_form_funhouse__Testimonial-styles__trustpilot-logo {
  width: 15%;
  height: auto;
  margin-bottom: 20px;
}

.ladder_form_funhouse__Testimonial-styles__trustpilot-stars {
  width: 15%;
  margin-bottom: 12px;
}

.ladder_form_funhouse__Testimonial-styles__trustpilot-score {
  width: 100%;
  color: #000000;
  margin-bottom: 40px;
}

.ladder_form_funhouse__Testimonial-styles__text {
  line-height: 1.8em;
}

@media (max-width: 499px) {

  
  
  .ladder_form_funhouse__Testimonial-styles__trustpilot-logo {
    width: 50%;
    margin-bottom: 16px;
  }
  
  .ladder_form_funhouse__Testimonial-styles__trustpilot-stars {
    width: 50%;
    margin-bottom: 12px;
  }

}
.ladder_account_next_nav_nav_item__user-coverage-summary-name-styles__\% {
  overflow: hidden;
  line-height: 16px;
  margin-bottom: 4px;
  text-overflow: ellipsis;
}
.ladder_savings_steps__more-steps-benefits-styles__sub-wrapper {
  display: flex;
  justify-content: space-between;
}

.ladder_savings_steps__more-steps-benefits-styles__sub {
  margin-top: 20px;
}

.ladder_savings_steps__more-steps-benefits-styles__icon {
  height: 35px;
  width: auto;
}

.ladder_savings_steps__more-steps-benefits-styles__health-icon {
  fill: #E26767;
}

.ladder_savings_steps__more-steps-benefits-styles__shield-icon {
  fill: #464F50;
}

.ladder_savings_steps__more-steps-benefits-styles__super {
  vertical-align: super;
}

.ladder_savings_steps__more-steps-benefits-styles__image {
  max-width: 350px;
  width: 100%;
  height: auto;
  align-self: flex-start;
  transform: scaleX(-1);
}

@media (min-width: 1025px) {

  
  
  .ladder_savings_steps__more-steps-benefits-styles__sub {
    width: 48%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_savings_steps__more-steps-benefits-styles__sub {
    width: 48%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_savings_steps__more-steps-benefits-styles__sub-wrapper {
    flex-direction: column;
  }
  
  .ladder_savings_steps__more-steps-benefits-styles__image {
    max-width: 225px;
    align-self: center;
  }

}
.ladder_nearly_perfect_quote_affordability__affordability-range-bar-poca-styles__arb-root {
  margin-top: 10.0px;
}

.ladder_nearly_perfect_quote_affordability__affordability-range-bar-poca-styles__container-bar {
  width: 100%;
  display: flex;
  padding-right: 3.5px;
}

.ladder_nearly_perfect_quote_affordability__affordability-range-bar-poca-styles__box {
  height: 10px;
  width: 100%;
  display: flex;
}

.ladder_nearly_perfect_quote_affordability__affordability-range-bar-poca-styles__half-box {
  height: 100%;
  width: 100%;
  display: flex;
}

.ladder_nearly_perfect_quote_affordability__affordability-range-bar-poca-styles__hb-mark {
  border-right: solid 1px #749475;
}

.ladder_nearly_perfect_quote_affordability__affordability-range-bar-poca-styles__first-mark {
  border-left: solid 1px #749475;
}

.ladder_nearly_perfect_quote_affordability__affordability-range-bar-poca-styles__quarter-box {
  height: 7px;
  width: 100%;
  align-self: end;
}

.ladder_nearly_perfect_quote_affordability__affordability-range-bar-poca-styles__filled {
  background-color: #DBE6DB;
}
@keyframes checked-page-content-1 {

31% {
    stroke-dasharray: 15;
    stroke-dashoffset: -9;
  }
  
69% {
    stroke-dasharray: 15;
    stroke-dashoffset: -9;
  }

}

@keyframes checked-page-content-2 {

31% {
    stroke-dasharray: 15;
    stroke-dashoffset: -7;
  }
  
69% {
    stroke-dasharray: 15;
    stroke-dashoffset: -7;
  }

}

@keyframes checked-page-content-3 {

31% {
    stroke-dasharray: 10;
    stroke-dashoffset: 5;
  }
  
69% {
    stroke-dasharray: 10;
    stroke-dashoffset: 5;
  }

}

.ladder_savings_steps_dory__checked-page-styles__checked-page {
  animation-delay: inherit;
  animation-duration: inherit;
  animation-timing-function: inherit;
}

.ladder_savings_steps_dory__checked-page-styles__checked-page path {
  animation-delay: inherit;
  animation-duration: inherit;
  animation-timing-function: inherit;
}

.ladder_savings_steps_dory__checked-page-styles__checked-page .content-1 {
  animation-name: go-green, checked-page-content-1;
}

.ladder_savings_steps_dory__checked-page-styles__checked-page .content-2 {
  animation-name: go-green, checked-page-content-2;
}

.ladder_savings_steps_dory__checked-page-styles__checked-page .content-3 {
  animation-name: go-green, checked-page-content-3;
}

.ladder_savings_steps_dory__checked-page-styles__checked-page .check {
  animation-name: go-green;
}
.ladder_server_error__main-panel-styles__root {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30.0px;
}

.ladder_server_error__main-panel-styles__footer-margin-hack {
  margin-bottom: -100px;
}

.ladder_server_error__main-panel-styles__sparks-panel {
  width: 60%;
  position: relative;
  vertical-align: bottom;
}

.ladder_server_error__main-panel-styles__sparks-img {
  width: 100%;
  position: relative;
  vertical-align: bottom;
}

.ladder_server_error__main-panel-styles__center {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.ladder_server_error__main-panel-styles__text-center {
  text-align: center;
}

.ladder_server_error__main-panel-styles__tire-img {
  max-width: 70%;
  height: auto;
  margin: 30.0px auto;
}

.ladder_server_error__main-panel-styles__sparks-button {
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  top: 37%;
}
.ladder_agents_dashboard_contacts__mock-contact-cards-styles__wrapper {
  position: relative;
  width: 100%;
}

.ladder_agents_dashboard_contacts__mock-contact-cards-styles__wrapper>div {
  margin-bottom: 15.0px;
}

.ladder_agents_dashboard_contacts__mock-contact-cards-styles__gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 10%,#F6F6F6 80%);
}
.ladder_text__text-styles__navy {
  color: #1F4972;
}

.ladder_text__text-styles__dark-navy {
  color: #0F2D4C;
}

.ladder_text__text-styles__orange {
  color: #FF4848;
}

.ladder_text__text-styles__dark-orange {
  color: #FF253F;
}

.ladder_text__text-styles__turquoise {
  color: #17CAAC;
}

.ladder_text__text-styles__white {
  color: #FFFFFF;
}

.ladder_text__text-styles__lighter-gray-2 {
  color: #F5F5F5;
}

.ladder_text__text-styles__lighter-gray {
  color: #F9F9F9;
}

.ladder_text__text-styles__light-gray {
  color: #E5E5E6;
}

.ladder_text__text-styles__medium-gray {
  color: #CDCDCD;
}

.ladder_text__text-styles__dark-gray {
  color: #A3A3A3;
}

.ladder_text__text-styles__darker-gray {
  color: #4C4C4E;
}

.ladder_text__text-styles__yellow {
  color: #F6BC49;
}

.ladder_text__text-styles__left {
  text-align: left;
}

.ladder_text__text-styles__right {
  text-align: right;
}

.ladder_text__text-styles__center {
  text-align: center;
}

.ladder_text__text-styles__helvetica {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.ladder_text__text-styles__lato {
  font-family: Lato, sans-serif;
}

.ladder_text__text-styles__work-sans {
  font-family: Work Sans, sans-serif;
}

.ladder_text__text-styles__xxs {
  font-size: 13px;
  line-height: 16px;
}

.ladder_text__text-styles__xs {
  font-size: 14px;
  line-height: 16px;
}

.ladder_text__text-styles__s {
  font-size: 16px;
  line-height: 22px;
}

.ladder_text__text-styles__m {
  font-size: 18px;
  line-height: 26px;
}

.ladder_text__text-styles__l {
  font-size: 24px;
  line-height: 32px;
}

.ladder_text__text-styles__xl {
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.3px;
}

.ladder_text__text-styles__xxl {
  font-size: 36px;
  line-height: 42px;
  letter-spacing: -0.6px;
}

.ladder_text__text-styles__home-s {
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 2px;
}

.ladder_text__text-styles__home-m {
  font-size: 26px;
  line-height: 40px;
  letter-spacing: 0px;
}

.ladder_text__text-styles__home-l {
  font-size: 34px;
  line-height: 50px;
  letter-spacing: 0px;
}

.ladder_text__text-styles__home-xl {
  font-size: 50px;
  line-height: 50px;
  letter-spacing: 0px;
}

.ladder_text__text-styles__home-xxl {
  font-size: 180px;
  letter-spacing: 0px;
}

.ladder_text__text-styles__quote-l {
  font-size: 22px;
}

.ladder_text__text-styles__subpixel {
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
}

.ladder_text__text-styles__grayscale {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ladder_text__text-styles__light {
  font-weight: 300;
}

.ladder_text__text-styles__light-small {
  font-weight: 400;
}

.ladder_text__text-styles__medium {
  font-weight: 500;
}

.ladder_text__text-styles__medium-on-windows {
  font-weight: 600;
}

.ladder_text__text-styles__bold {
  font-weight: 700;
}

.ladder_text__text-styles__black {
  font-weight: 900;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_text__text-styles__home-s {
    font-size: 12.8px;
    line-height: 17.6px;
    letter-spacing: 1.6px;
  }
  
  .ladder_text__text-styles__home-m {
    font-size: 20.8px;
    line-height: 32px;
    letter-spacing: 0px;
  }
  
  .ladder_text__text-styles__home-l {
    font-size: 27.2px;
    line-height: 40px;
    letter-spacing: 0px;
  }
  
  .ladder_text__text-styles__home-xl {
    font-size: 40px;
    line-height: 40px;
    letter-spacing: 0px;
  }
  
  .ladder_text__text-styles__home-xxl {
    font-size: 144px;
    letter-spacing: 0px;
  }
  
  .ladder_text__text-styles__quote-l {
    font-size: 20px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_text__text-styles__xxs {
    font-size: 13px;
    line-height: 16px;
  }
  
  .ladder_text__text-styles__xs {
    font-size: 14px;
    line-height: 16px;
  }
  
  .ladder_text__text-styles__s {
    font-size: 16px;
    line-height: 20px;
  }
  
  .ladder_text__text-styles__m {
    font-size: 16px;
    line-height: 24px;
  }
  
  .ladder_text__text-styles__l {
    font-size: 20px;
    line-height: 24px;
  }
  
  .ladder_text__text-styles__xl {
    font-size: 28px;
    line-height: 32px;
  }
  
  .ladder_text__text-styles__xxl {
    font-size: 30px;
    line-height: 32px;
  }
  
  .ladder_text__text-styles__home-s {
    font-size: 9.6px;
    line-height: 13.2px;
    letter-spacing: 1.2px;
  }
  
  .ladder_text__text-styles__home-m {
    font-size: 15.6px;
    line-height: 24px;
    letter-spacing: 0px;
  }
  
  .ladder_text__text-styles__home-l {
    font-size: 20.4px;
    line-height: 30px;
    letter-spacing: 0px;
  }
  
  .ladder_text__text-styles__home-xl {
    font-size: 30px;
    line-height: 30px;
    letter-spacing: 0px;
  }
  
  .ladder_text__text-styles__home-xxl {
    font-size: 108px;
    letter-spacing: 0px;
  }
  
  .ladder_text__text-styles__quote-l {
    font-size: 18px;
  }

}
.ladder_traffic_diversion_views__no-product-or-geico-life-quotes-styles__no-product-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ladder_traffic_diversion_views__no-product-or-geico-life-quotes-styles__flat-tire-wrapper {
  flex-basis: 50%;
  border-radius: 30px;
  background-color: #E7EEEF;
  align-items: center;
  justify-content: center;
  display: flex;
}

.ladder_traffic_diversion_views__no-product-or-geico-life-quotes-styles__no-product-text-wrapper {
  flex-basis: 50%;
}

.ladder_traffic_diversion_views__no-product-or-geico-life-quotes-styles__flat-tire-icon {
  width: 100%;
}

.ladder_traffic_diversion_views__no-product-or-geico-life-quotes-styles__geico-lifequotes-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  gap: 30px;
}

@media (min-width: 1025px) {

  
  
  .ladder_traffic_diversion_views__no-product-or-geico-life-quotes-styles__flat-tire-wrapper {
    height: 658px;
    margin: 0 5% 0 0;
  }
  
  .ladder_traffic_diversion_views__no-product-or-geico-life-quotes-styles__flat-tire-icon {
    width: 100%;
    margin: 0 10%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_traffic_diversion_views__no-product-or-geico-life-quotes-styles__flat-tire-wrapper {
    height: 658px;
    margin: 0 5% 0 0;
  }
  
  .ladder_traffic_diversion_views__no-product-or-geico-life-quotes-styles__flat-tire-icon {
    width: 100%;
    margin: 0 10%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_traffic_diversion_views__no-product-or-geico-life-quotes-styles__no-product-container {
    flex-direction: column;
    padding: 20px;
  }
  
  .ladder_traffic_diversion_views__no-product-or-geico-life-quotes-styles__flat-tire-wrapper {
    min-height: 310px;
    width: 100%;
  }
  
  .ladder_traffic_diversion_views__no-product-or-geico-life-quotes-styles__flat-tire-icon {
    margin: 0 10%;
  }

}
.ladder_markdoc_core__editor-styles__wrapper {
  display: flex;
}

.ladder_markdoc_core__editor-styles__editor {
  display: flex;
  min-width: 50%;
  height: 100vh;
  width: 100%;
}

.ladder_markdoc_core__editor-styles__line-numbers {
  width: auto;
  font-family: courier;
  padding-top: 10px;
  padding-left: 8px;
  overflow: hidden;
  position: absolute;
  height: 100%;
  text-align: right;
}

.ladder_markdoc_core__editor-styles__editor-area {
  width: 100%;
  border-width: 1px;
  border-color: black;
  font-family: courier;
  height: 100%;
  padding: 0.5em;
  text-wrap: nowrap;
  border-style: solid;
  margin-left: 48px;
}
.ladder_agents_dashboard_incentives__bonus-earnings-terms-and-conditions-styles__details-modal-text-container {

}

.ladder_agents_dashboard_incentives__bonus-earnings-terms-and-conditions-styles__details-modal-text-container>* {
  margin-top: 10.0px;
}

.ladder_agents_dashboard_incentives__bonus-earnings-terms-and-conditions-styles__conditions-list {
  margin-left: 10.0px;
  list-style: lower-alpha inside none;
}

.ladder_agents_dashboard_incentives__bonus-earnings-terms-and-conditions-styles__conditions-list>li {
  display: list-item;
}
.ladder_widget_ui_v2__panel-root-styles__root {
  min-width: 830px;
  width: 100%;
  max-width: 900px;
  height: 100%;
  margin-right: auto;
  border: 1px solid #707070;
  flex-direction: column;
  min-height: 560px;
  margin-left: auto;
  display: flex;
  max-height: 623px;
}
.ladder_savings_steps__healthy-habits-styles__image {
  max-width: 300px;
  width: 100%;
  height: auto;
  align-self: flex-start;
}

@media (max-width: 499px) {

  
  
  .ladder_savings_steps__healthy-habits-styles__image {
    max-width: 225px;
    align-self: center;
  }

}
.ladder_agents_agent_assisted_app_views_app_status__status-panel-progressbar-styles__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin-top: auto;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_agent_assisted_app_views_app_status__status-panel-progressbar-styles__container {
    margin-top: 30px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_agent_assisted_app_views_app_status__status-panel-progressbar-styles__container {
    margin-top: 30px;
  }

}

.ladder_agents_agent_assisted_app_views_app_status__status-panel-progressbar-styles__bar {
  height: 8px;
  border-radius: 6px;
}

.ladder_agents_agent_assisted_app_views_app_status__status-panel-progressbar-styles__gray {
  background-color: #D9D9D9;
  width: 100%;
  position: absolute;
}

.ladder_agents_agent_assisted_app_views_app_status__status-panel-progressbar-styles__blue {
  background-color: #AFC5C8;
  position: absolute;
}

.ladder_agents_agent_assisted_app_views_app_status__status-panel-progressbar-styles__logo {
  width: 18px;
  height: auto;
  position: absolute;
  right: -9px;
  top: -50%;
}
.ladder_nearly_perfect_quote_common__npq-coverage-amount-field-ui-styles__wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.ladder_nearly_perfect_quote_common__npq-coverage-amount-field-ui-styles__normal-wrapper {
  width: 100%;
}

.ladder_nearly_perfect_quote_common__npq-coverage-amount-field-ui-styles__aff-restricted-wrapper {
  width: 90%;
}

@media (min-width: 1025px) {

  
  
  .ladder_nearly_perfect_quote_common__npq-coverage-amount-field-ui-styles__aff-restricted-label {
    margin-bottom: 12px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_nearly_perfect_quote_common__npq-coverage-amount-field-ui-styles__aff-restricted-label {
    margin-bottom: 12px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_nearly_perfect_quote_common__npq-coverage-amount-field-ui-styles__aff-restricted-wrapper {
    width: 100%;
  }

}
.ladder_ny_form_review__additional-information-section-styles__assessment-factor-group {
  margin-top: 30.0px;
}

.ladder_ny_form_review__additional-information-section-styles__title {
  padding-left: 10.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_ny_form_review__additional-information-section-styles__title {
    padding-left: 0;
  }

}
.ladder_view_ladder_down__premium-styles__root {
  box-sizing: border-box;
}

.ladder_view_ladder_down__premium-styles__inner {
  width: 100%;
  box-sizing: border-box;
}

.ladder_view_ladder_down__premium-styles__inner-container {
  display: flex;
  align-items: center;
}

.ladder_view_ladder_down__premium-styles__premium-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ladder_view_ladder_down__premium-styles__padded-top {
  margin-top: 15.0px;
}

@media (min-width: 1025px) {

  
  
  .ladder_view_ladder_down__premium-styles__root {
    width: 35%;
    max-width: 250px;
    padding: 10.0px;
  }
  
  .ladder_view_ladder_down__premium-styles__inner {
    padding: 20px 0;
    border: 1px solid #707070;
    cursor: pointer;
  }
  
  .ladder_view_ladder_down__premium-styles__inner-container {
    flex-direction: column;
  }
  
  .ladder_view_ladder_down__premium-styles__inner-border {
    border: 2px solid #FFFFFF;
  }
  
  .ladder_view_ladder_down__premium-styles__premium-wrapper {
    padding: 5.0px 0;
  }
  
  .ladder_view_ladder_down__premium-styles__selected {
    border: 3px solid #FE4747;
  }
  
  .ladder_view_ladder_down__premium-styles__selected.ladder_view_ladder_down__premium-styles__rungs {
    border: 3px solid #D1A98F;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_view_ladder_down__premium-styles__root {
    width: 35%;
    max-width: 250px;
    padding: 10.0px;
  }
  
  .ladder_view_ladder_down__premium-styles__inner {
    padding: 20px 0;
    border: 1px solid #707070;
    cursor: pointer;
  }
  
  .ladder_view_ladder_down__premium-styles__inner-container {
    flex-direction: column;
  }
  
  .ladder_view_ladder_down__premium-styles__inner-border {
    border: 2px solid #FFFFFF;
  }
  
  .ladder_view_ladder_down__premium-styles__premium-wrapper {
    padding: 5.0px 0;
  }
  
  .ladder_view_ladder_down__premium-styles__selected {
    border: 3px solid #FE4747;
  }
  
  .ladder_view_ladder_down__premium-styles__selected.ladder_view_ladder_down__premium-styles__rungs {
    border: 3px solid #D1A98F;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_view_ladder_down__premium-styles__root {
    width: 100%;
  }
  
  .ladder_view_ladder_down__premium-styles__inner {
    border-top: 1px solid #FFFFFF;
    border-bottom: 1px solid #D0D0D0;
    padding: 0 10.0px;
  }
  
  .ladder_view_ladder_down__premium-styles__inner-container {
    justify-content: space-between;
  }
  
  .ladder_view_ladder_down__premium-styles__inner-border {
    border-top: 2px solid #FFFFFF;
    border-right: 3px solid #FFFFFF;
    border-bottom: 2px solid #FFFFFF;
    border-left: 3px solid #FFFFFF;
  }
  
  .ladder_view_ladder_down__premium-styles__premium-wrapper {
    padding: 10.0px 20px;
  }
  
  .ladder_view_ladder_down__premium-styles__border-top {
    border-top: 1px solid #D0D0D0;
  }
  
  .ladder_view_ladder_down__premium-styles__selected {
    border: 3px solid #FE4747;
  }
  
  .ladder_view_ladder_down__premium-styles__selected.ladder_view_ladder_down__premium-styles__rungs {
    border: 3px solid #D1A98F;
  }

}
.ladder_newsletter_signup__thank-you-panel-styles__root {
  max-width: 750px;
  margin: 0;
}

@media (max-width: 499px) {

  
  
  .ladder_newsletter_signup__thank-you-panel-styles__root {
    width: 90%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_newsletter_signup__thank-you-panel-styles__root {
    width: 90%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_newsletter_signup__thank-you-panel-styles__root {
    margin-top: 20px;
  }

}
.ladder_views_api__BelovedByCustomersPanel-styles__row {
  display: flex;
}

.ladder_views_api__BelovedByCustomersPanel-styles__row > div:not(:first-child) {
  padding-left: 8.0px;
}

.ladder_views_api__BelovedByCustomersPanel-styles__big-gap {
  margin-bottom: 48px;
}

.ladder_views_api__BelovedByCustomersPanel-styles__little-gap {
  margin-bottom: 24.0px;
}
.ladder_form_funhouse__welcome-styles__welcome-container {
  display: flex;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.ladder_form_funhouse__welcome-styles__video {
  width: 50vw;
}

.ladder_form_funhouse__welcome-styles__header-buffer {
  height: 50px;
}

.ladder_form_funhouse__welcome-styles__text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50vw;
  padding-left: 5%;
  padding-right: 5%;
}

.ladder_form_funhouse__welcome-styles__show {
  opacity: 1;
  transition: opacity 1000ms ease;
}

.ladder_form_funhouse__welcome-styles__hide {
  opacity: 0;
}

@media (max-width: 499px) {

  
  
  .ladder_form_funhouse__welcome-styles__welcome-container {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    bottom: none;
    width: 100vw;
  }
  
  .ladder_form_funhouse__welcome-styles__video {
    width: 100%;
  }
  
  .ladder_form_funhouse__welcome-styles__header-buffer {
    height: 0px;
    display: none;
  }
  
  .ladder_form_funhouse__welcome-styles__text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
  }
  
  .ladder_form_funhouse__welcome-styles__text {
    margin-top: 20px;
  }
  
  .ladder_form_funhouse__welcome-styles__subtext {
    margin-top: 10px;
    line-height: 2em;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_form_funhouse__welcome-styles__welcome-container {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    bottom: none;
    width: 100vw;
  }
  
  .ladder_form_funhouse__welcome-styles__video {
    width: 100%;
  }
  
  .ladder_form_funhouse__welcome-styles__header-buffer {
    height: 0px;
    display: none;
  }
  
  .ladder_form_funhouse__welcome-styles__text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
  }
  
  .ladder_form_funhouse__welcome-styles__text {
    margin-top: 20px;
  }
  
  .ladder_form_funhouse__welcome-styles__subtext {
    margin-top: 10px;
    line-height: 2em;
  }

}
.ladder_ux_panels__animated-header-styles__root {
  display: inline-block;
  z-index: 1;
  width: 100%;
  height: auto;
}

.ladder_ux_panels__animated-header-styles__wrapper {
  position: relative;
  display: inline-block;
  overflow: visible;
}

.ladder_ux_panels__animated-header-styles__wrapper-2 {
  display: inline;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.ladder_ux_panels__animated-header-styles__wrapper-2 >div {
  display: inline-block;
  width: 100%;
}

.ladder_ux_panels__animated-header-styles__placeholder-show {
  display: inline;
  position: absolute;
  opacity: 1;
  left: 0;
  top: 0;
}

.ladder_ux_panels__animated-header-styles__placeholder-hide {
  transition: all 300ms ease-out;
  opacity: 0;
  top: -45px;
}

.ladder_ux_panels__animated-header-styles__sizing-text {
  opacity: 0;
  visibility: hidden;
}

.ladder_ux_panels__animated-header-styles__display-none {
  display: none;
}

.ladder_ux_panels__animated-header-styles__so-good-campaign-wrapper {
  display: block;
  max-width: 600px;
}

.ladder_ux_panels__animated-header-styles__phoenix-text {
  color: #975C46;
}

@media (min-width: 1025px) {

  
  
  .ladder_ux_panels__animated-header-styles__white-text {
    color: #FFFFFF;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_panels__animated-header-styles__so-good-campaign-wrapper {
    font-size: 40px;
    line-height: 50px;
    letter-spacing: -0.3px;
  }
  
  .ladder_ux_panels__animated-header-styles__white-text {
    color: #FFFFFF;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_panels__animated-header-styles__root {
    line-height: 40px;
  }
  
  .ladder_ux_panels__animated-header-styles__placeholder-hide {
    transition: all 200ms ease-in;
    top: -25px;
  }
  
  .ladder_ux_panels__animated-header-styles__so-good-campaign-wrapper {
    display: block;
  }

}
.ladder_ux_panels__agent-info-boxes-styles__root {
  max-width: 540px;
  margin-top: 8.0px;
}

.ladder_ux_panels__agent-info-boxes-styles__row {
  display: flex;
  width: 100%;
}

.ladder_ux_panels__agent-info-boxes-styles__info-box-no-padding {
  width: 100%;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.7);
  text-align: center;
}

.ladder_ux_panels__agent-info-boxes-styles__info-box {
  width: 100%;
  padding-bottom: 20.0px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin-top: 8.0px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  display: flex;
  padding-top: 20.0px;
}

.ladder_ux_panels__agent-info-boxes-styles__info-box-name {
  padding-top: 12.0px;
  padding-bottom: 12.0px;
  padding-right: 48px;
  padding-left: 48px;
  text-align: left;
}

.ladder_ux_panels__agent-info-boxes-styles__second-row-left {
  margin-right: 4.0px;
}

.ladder_ux_panels__agent-info-boxes-styles__second-row-right {
  margin-left: 4.0px;
}

.ladder_ux_panels__agent-info-boxes-styles__icon-left {
  margin-right: 4.0px;
}

.ladder_ux_panels__agent-info-boxes-styles__icon-right {
  margin-left: 4.0px;
}

.ladder_ux_panels__agent-info-boxes-styles__icon {
  display: flex;
  width: 10%;
}

.ladder_ux_panels__agent-info-boxes-styles__text {
  display: flex;
  width: 90%;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_panels__agent-info-boxes-styles__info-box-no-padding {
    background-color: rgba(255, 255, 255, 0.9);
  }
  
  .ladder_ux_panels__agent-info-boxes-styles__info-box {
    padding-top: 8.0px;
    padding-bottom: 8.0px;
    background-color: rgba(255, 255, 255, 0.9);
  }
  
  .ladder_ux_panels__agent-info-boxes-styles__info-box-name {
    padding-right: 16px;
    padding-left: 16px;
    text-align: left;
  }
  
  .ladder_ux_panels__agent-info-boxes-styles__second-row-left {
    flex: 4;
  }
  
  .ladder_ux_panels__agent-info-boxes-styles__second-row-right {
    flex: 3;
  }

}
.ladder_views_decision_offer__rungs-policy-card-styles__expiration {
  margin-top: 20px;
}

.ladder_views_decision_offer__rungs-policy-card-styles__expiration>span {
  white-space: nowrap;
}

.ladder_views_decision_offer__rungs-policy-card-styles__amendments {
  margin-bottom: 10px;
}

.ladder_views_decision_offer__rungs-policy-card-styles__divider {
  margin-left: -12.5%;
  margin-right: -12.5%;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 125%;
  border-color: #F1F1F1;
}
.ladder_ux_panels__story-card-homepage-2022-styles__story {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 2;
}

.ladder_ux_panels__story-card-homepage-2022-styles__person {
  margin: 32px 0 0 0;
}
.ladder_account_next_views_policy__RungsPolicyInfo-styles__root {
  margin-top: 16px;
}

.ladder_account_next_views_policy__RungsPolicyInfo-styles__subtitle {
  padding: 10px 0 25px;
}

.ladder_account_next_views_policy__RungsPolicyInfo-styles__info-row {
  border-top: 1px solid #D0D0D0;
  padding: 25px 0;
}

.ladder_account_next_views_policy__RungsPolicyInfo-styles__title-row {
  display: flex;
  justify-content: space-between;
}

.ladder_account_next_views_policy__RungsPolicyInfo-styles__item {
  padding-top: 24px;
}
@media (max-width: 499px) {

  
  
  .ladder_onboarding_shared_components__more-body-mt__body {
    margin-top: 20px;
  }

}
.ladder_agents_dashboard_inputs__single-select-styles__container {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard_inputs__single-select-styles__container {
    gap: 5px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_dashboard_inputs__single-select-styles__container {
    gap: 5px;
  }

}
.ladder_calculator_rebrand__root-styles__root {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #F7F2EF;
}

.ladder_calculator_rebrand__root-styles__title-root {
  display: flex;
  align-items: left;
  height: 310px;
  max-width: 600px;
}

.ladder_calculator_rebrand__root-styles__title-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.ladder_calculator_rebrand__root-styles__title {
  width: 100%;
  margin: 80px 0 20px 0;
}

@media (max-width: 499px) {

  
  
  .ladder_calculator_rebrand__root-styles__title-root {
    width: 90%;
    height: 280px;
  }
  
  .ladder_calculator_rebrand__root-styles__title {
    margin: 50px 0 20px 0;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_calculator_rebrand__root-styles__title-root {
    width: 90%;
    height: 280px;
  }
  
  .ladder_calculator_rebrand__root-styles__title {
    margin: 50px 0 20px 0;
  }

}
.ladder_nearly_perfect_quote_affordability__affordability-range-bar-caption-styles__container-bar {
  width: 100%;
  display: flex;
  padding-right: 3.5px;
}

.ladder_nearly_perfect_quote_affordability__affordability-range-bar-caption-styles__caption-margin {
  margin-top: 5.0px;
}

.ladder_nearly_perfect_quote_affordability__affordability-range-bar-caption-styles__caption-padding {
  height: 20px;
}

.ladder_nearly_perfect_quote_affordability__affordability-range-bar-caption-styles__caption {
  height: 20px;
  display: flex;
  justify-content: center;
  position: relative;
  white-space: nowrap;
}

.ladder_nearly_perfect_quote_affordability__affordability-range-bar-caption-styles__caption-wrapper {
  position: absolute;
}

.ladder_nearly_perfect_quote_affordability__affordability-range-bar-caption-styles__caption-text {
  text-align: center;
  cursor: pointer;
}

@media (max-width: 499px) {

  
  
  .ladder_nearly_perfect_quote_affordability__affordability-range-bar-caption-styles__caption-text {
    line-height: 18px;
  }

}
.ladder_globals__PrivacyBanner-styles__root {
  width: 100%;
  padding-bottom: 16px;
  justify-content: center;
  position: fixed;
  z-index: 14;
  box-shadow: 0px -5px 10px rgba(51, 46, 84, 0.22);
  background-color: #FFFFFF;
  padding-right: 32px;
  display: flex;
  bottom: 0px;
  padding-left: 32px;
  padding-top: 16px;
}

.ladder_globals__PrivacyBanner-styles__x-button-wrapper {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  padding: 8.0px;
  z-index: 14;
}

.ladder_globals__PrivacyBanner-styles__x-button {
  width: 30px;
  justify-content: center;
  height: 30px;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 1.5px 3px rgba(51, 46, 84, 0.22);
  background-color: #FFFFFF;
  border-radius: 50%;
  display: flex;
}

.ladder_globals__PrivacyBanner-styles__text-container {
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.ladder_globals__PrivacyBanner-styles__button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
}

@media (max-width: 499px) {

  
  
  .ladder_globals__PrivacyBanner-styles__root {
    flex-direction: column;
    width: 100%;
    padding-top: 12.0px;
    padding-left: 48px;
    padding-right: 48px;
    padding-bottom: 0;
  }
  
  .ladder_globals__PrivacyBanner-styles__text-container {
    width: 100%;
    margin-bottom: 16px;
  }
  
  .ladder_globals__PrivacyBanner-styles__button-container {
    width: 100%;
  }

}
.ladder_form_funhouse__birthday-styles__emoji {
  font-size: 3em;
  margin-bottom: 40px;
}
.ladder_account_policy__beneficiary-spouse-connection-modal-styles__thumbs-up {
  width: 80px;
  margin: 20px;
}

.ladder_account_policy__beneficiary-spouse-connection-modal-styles__click-text {
  margin-top: 20px;
}

.ladder_account_policy__beneficiary-spouse-connection-modal-styles__button {
  margin: 20px;
}
.ladder_agents_agent_assisted_app_views_offer__transparent-fee-info-styles__container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.ladder_agents_agent_assisted_app_views_offer__transparent-fee-info-styles__stamp-container {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.ladder_agents_agent_assisted_app_views_offer__transparent-fee-info-styles__stamp {
  width: 94px;
  height: 94px;
}

.ladder_agents_agent_assisted_app_views_offer__transparent-fee-info-styles__list-container {
  flex-grow: 1;
  list-style: none;
  align-items: center;
}

.ladder_agents_agent_assisted_app_views_offer__transparent-fee-info-styles__list-container>li {
  display: flex;
}

.ladder_agents_agent_assisted_app_views_offer__transparent-fee-info-styles__icon {
  min-width: 22px;
  height: 22px;
  margin-top: 4px;
}

.ladder_agents_agent_assisted_app_views_offer__transparent-fee-info-styles__caption {
  padding-left: 16px;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_agent_assisted_app_views_offer__transparent-fee-info-styles__stamp-container {
    display: none;
  }

}
.ladder_form_multiselection__Root-styles__options {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.ladder_form_multiselection__Root-styles__option {
  display: flex;
}

.ladder_form_multiselection__Root-styles__checkbox-container {
  padding-right: 10.0px;
}

.ladder_form_multiselection__Root-styles__info-expander-container {
  padding: 5.0px 0 0 10.0px;
}

.ladder_form_multiselection__Root-styles__scrollable-tips {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 499px) {

  
  
  .ladder_form_multiselection__Root-styles__options {
    gap: 8px;
  }
  
  .ladder_form_multiselection__Root-styles__info-expander-container {
    padding-top: 0;
  }

}
.ladder_views_payment__payment-selection-box-styles__root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px 0;
}

.ladder_views_payment__payment-selection-box-styles__root.ladder_views_payment__payment-selection-box-styles__left-align {
  align-items: start;
}

.ladder_views_payment__payment-selection-box-styles__options-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ladder_views_payment__payment-selection-box-styles__option {
  display: flex;
  align-items: center;
}

.ladder_views_payment__payment-selection-box-styles__dot-container {
  padding-right: 10.0px;
}

.ladder_views_payment__payment-selection-box-styles__radio {
  display: none;
}

.ladder_views_payment__payment-selection-box-styles__radio-dot {
  width: 16px;
  top: 0px;
  height: 16px;
  border: 2px solid #D0D0D0;
  position: relative;
  cursor: pointer;
  background-color: #FFFFFF;
  border-radius: 50%;
  box-sizing: content-box;
  display: block;
  left: 0px;
}

.ladder_views_payment__payment-selection-box-styles__radio-selected {
  border-color: #CF967E;
}

.ladder_views_payment__payment-selection-box-styles__radio-dot-inner {
  display: block;
  position: relative;
  top: 3.0px;
  left: 3.0px;
  width: 10.0px;
  height: 10.0px;
  border-radius: 50%;
  background: #FE4747;
}

.ladder_views_payment__payment-selection-box-styles__radio-dot-inner-rungs {
  background: #CF967E;
}

.ladder_views_payment__payment-selection-box-styles__subtext {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  max-width: 90%;
  margin: 0 30.0px;
}

.ladder_views_payment__payment-selection-box-styles__action-button {
  margin: 20px 0 0 0;
}

.ladder_views_payment__payment-selection-box-styles__apple-action-button {
  color: #FFFFFF;
  border-radius: 8px;
  max-width: 90%;
  margin: 20px 0;
  background-color: #000000;
  cursor: pointer;
}

.ladder_views_payment__payment-selection-box-styles__recommended-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0 0 0;
}

.ladder_views_payment__payment-selection-box-styles__hr {
  margin: 20px 0 20px -20px;
}

.ladder_views_payment__payment-selection-box-styles__credit-card-form {
  width: 93%;
  padding: 20px 30.0px;
}

.ladder_views_payment__payment-selection-box-styles__explanation-text {
  color: #000000;
  margin: 5.0px 0 0 0;
}

.ladder_views_payment__payment-selection-box-styles__icon {
  width: 36px;
  height: 30px;
}

.ladder_views_payment__payment-selection-box-styles__google-pay-mark {
  height: 30px;
  width: 72px;
  vertical-align: middle;
  margin-left: 10.0px;
}

.ladder_views_payment__payment-selection-box-styles__google-pay-button {
  width: 90%;
  border-width: 1px;
  max-width: 220px;
  border-color: #D0D0D0;
  height: 26px;
  padding: 10.0px;
  border-style: solid;
  margin-top: 20px;
  cursor: pointer;
  box-shadow: 0px 1px 3px #D0D0D0;
  border-radius: 6px;
}

.ladder_views_payment__payment-selection-box-styles__apple-pay-mark {
  height: 30px;
  width: 72px;
  vertical-align: middle;
}

.ladder_views_payment__payment-selection-box-styles__apple-pay-button-container {
  margin: 20px 0 0 0;
  width: 90%;
  max-width: 220px;
  height: 42px;
  cursor: pointer;
}

.ladder_views_payment__payment-selection-box-styles__apple-pay-button-black {
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-style: black;
  -apple-pay-button-type: continue;
  height: 100%;
}

.ladder_views_payment__payment-selection-box-styles__google-pay-icon {
  height: 80%;
  vertical-align: middle;
}

@media (max-width: 499px) {

  
  
  .ladder_views_payment__payment-selection-box-styles__subtext {
    margin: 10.0px 0 10.0px 0;
  }
  
  .ladder_views_payment__payment-selection-box-styles__credit-card-form {
    padding-left: 0;
    padding-right: 10.0px;
  }
  
  .ladder_views_payment__payment-selection-box-styles__google-pay-button {
    margin-top: 10.0px;
  }
  
  .ladder_views_payment__payment-selection-box-styles__apple-pay-button-container {
    margin: 10.0px 0 0 20px;
  }

}
.ladder_ny_form_common__regular-field-styles__root {
  padding-bottom: 5.0px;
}

.ladder_ny_form_common__regular-field-styles__caption-container {
  display: flex;
  align-items: center;
  padding-bottom: 5.0px;
}

.ladder_ny_form_common__regular-field-styles__caption-centered {
  justify-content: space-around;
}

.ladder_ny_form_common__regular-field-styles__info-expander-container {
  padding: 5.0px 0 0 10.0px;
}

.ladder_ny_form_common__regular-field-styles__subcaption {
  padding-bottom: 20px;
}

.ladder_ny_form_common__regular-field-styles__top-level-caption {
  padding: 20px 0;
}

@media (max-width: 499px) {

  
  
  .ladder_ny_form_common__regular-field-styles__top-level-caption {
    padding-bottom: 10.0px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_ny_form_common__regular-field-styles__top-level-caption.ladder_ny_form_common__regular-field-styles__helper-subcaption {
    padding-bottom: 10.0px;
  }
  
  .ladder_ny_form_common__regular-field-styles__inline-info-helper {
    display: inline;
    vertical-align: middle;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ny_form_common__regular-field-styles__top-level-caption.ladder_ny_form_common__regular-field-styles__helper-subcaption {
    padding-bottom: 10.0px;
  }
  
  .ladder_ny_form_common__regular-field-styles__inline-info-helper {
    display: inline;
    vertical-align: middle;
  }

}
.ladder_app_review__navigation-styles__root {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: calc(50% - 300px);
  position: fixed;
  left: 0;
  top: 0;
}

.ladder_app_review__navigation-styles__wrapper {
  display: flex;
  flex-direction: column;
}

.ladder_app_review__navigation-styles__section {
  border-left: solid 3px;
  padding: 10px;
}

.ladder_app_review__navigation-styles__current-section {
  cursor: default;
  pointer-events: none;
}

.ladder_app_review__navigation-styles__other-section {
  color: #BDBDBD;
}

.ladder_app_review__navigation-styles__icon-container {
  display: flex;
  flex-grow: 1;
  justify-content: left;
  align-items: center;
}
.ladder_laddering_page__header-styles__root {
  display: flex;
  width: 100%;
  height: 710px;
  overflow: hidden;
  background-color: #CFDCDE;
}

.ladder_laddering_page__header-styles__top-container {
  display: flex;
  position: relative;
  max-width: 1600px;
  margin: 0 auto;
  width: 100%;
  height: auto;
}

.ladder_laddering_page__header-styles__title-container {
  display: flex;
  position: absolute;
  margin: 9% 0% 5% 3vw;
  width: 38%;
  height: auto;
  z-index: 1;
}

.ladder_laddering_page__header-styles__image-container {
  display: flex;
  position: absolute;
  bottom: -3px;
  max-width: 1000px;
  width: 70%;
  height: auto;
  right: 0px;
}

.ladder_laddering_page__header-styles__image {
  width: 100%;
  height: auto;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_laddering_page__header-styles__root {
    height: 720px;
    position: relative;
  }
  
  .ladder_laddering_page__header-styles__top-container {
    flex-direction: column;
    position: absolute;
    bottom: -3px;
    margin: unset;
    align-items: center;
  }
  
  .ladder_laddering_page__header-styles__title-container {
    position: unset;
    margin: 10% 0% 0% -10%;
    max-width: 450px;
    width: 90%;
  }
  
  .ladder_laddering_page__header-styles__image-container {
    width: 80%;
    right: unset;
    max-width: 650px;
    height: auto;
    align-self: center;
    position: unset;
    margin-top: 50.0px;
    margin-left: 20%;
    bottom: unset;
  }
  
  .ladder_laddering_page__header-styles__image {
    width: 100%;
    height: auto;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_laddering_page__header-styles__root {
    height: 720px;
    position: relative;
  }
  
  .ladder_laddering_page__header-styles__top-container {
    flex-direction: column;
    position: absolute;
    bottom: -3px;
    margin: unset;
    align-items: center;
  }
  
  .ladder_laddering_page__header-styles__title-container {
    position: unset;
    margin: 10% 0% 0% -10%;
    max-width: 450px;
    width: 90%;
  }
  
  .ladder_laddering_page__header-styles__image-container {
    width: 80%;
    right: unset;
    max-width: 650px;
    height: auto;
    align-self: center;
    position: unset;
    margin-top: 50.0px;
    margin-left: 20%;
    bottom: unset;
  }
  
  .ladder_laddering_page__header-styles__image {
    width: 100%;
    height: auto;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_laddering_page__header-styles__root {
    height: 550px;
  }
  
  .ladder_laddering_page__header-styles__title-container {
    margin-left: unset;
    width: 90%;
  }
  
  .ladder_laddering_page__header-styles__image-container {
    width: 100%;
    margin-left: 10%;
  }

}
.ladder_account_shared_components__field-input-pair-styles__root {
  padding: 15.0px 0;
}

.ladder_account_shared_components__field-input-pair-styles__optional {
  margin-left: 3px;
  color: #5D5D5D;
}

.ladder_account_shared_components__field-input-pair-styles__help-text {
  font-weight: normal;
}

.ladder_account_shared_components__field-input-pair-styles__field {
  padding: 5.0px 0;
}
.ladder_account_next_nav_nav_item__nav-item-styles__\% {
  display: flex;
  cursor: pointer;
  align-items: center;
}

@media (max-width: 499px) {

  
  
  .ladder_account_next_nav_nav_item__nav-item-styles__\% {
    height: 50px;
    justify-content: space-between;
    font-size: 14px;
    line-height: 17px;
  }
  
  .ladder_account_next_nav_nav_item__nav-item-styles__mobile-angle-bracket {
    height: 10px;
  }
  
  .ladder_account_next_nav_nav_item__nav-item-styles__indent-1 {
    padding-left: 1em;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_account_next_nav_nav_item__nav-item-styles__\% {
    height: 50px;
    line-height: 26px;
  }
  
  .ladder_account_next_nav_nav_item__nav-item-styles__mobile-angle-bracket {
    display: none;
  }
  
  .ladder_account_next_nav_nav_item__nav-item-styles__selected {
    border-left: 8px solid #DA2413;
    background-color: #FE4747;
  }
  
  .ladder_account_next_nav_nav_item__nav-item-styles__has-selected-child {
    background-color: #475665;
  }
  
  .ladder_account_next_nav_nav_item__nav-item-styles__indent-1 {
    padding-left: 1em;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account_next_nav_nav_item__nav-item-styles__\% {
    height: 50px;
    line-height: 26px;
  }
  
  .ladder_account_next_nav_nav_item__nav-item-styles__mobile-angle-bracket {
    display: none;
  }
  
  .ladder_account_next_nav_nav_item__nav-item-styles__selected {
    border-left: 8px solid #DA2413;
    background-color: #FE4747;
  }
  
  .ladder_account_next_nav_nav_item__nav-item-styles__has-selected-child {
    background-color: #475665;
  }
  
  .ladder_account_next_nav_nav_item__nav-item-styles__indent-1 {
    padding-left: 1em;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_account_next_nav_nav_item__nav-item-styles__\% {
    font-size: 16px;
  }
  
  .ladder_account_next_nav_nav_item__nav-item-styles__not-selected {
    padding-left: 32px;
  }
  
  .ladder_account_next_nav_nav_item__nav-item-styles__selected {
    padding-left: 24px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account_next_nav_nav_item__nav-item-styles__\% {
    font-size: 15px;
  }
  
  .ladder_account_next_nav_nav_item__nav-item-styles__not-selected {
    padding-left: 16px;
  }
  
  .ladder_account_next_nav_nav_item__nav-item-styles__selected {
    padding-left: 8px;
  }

}
.ladder_ux_panels__rebrand-header-panel-styles__root {
  display: flex;
  position: relative;
  background: #EFEAE1;
  flex-direction: row;
  min-height: 850px;
  width: 100%;
  z-index: 1;
  overflow: hidden;
}

.ladder_ux_panels__rebrand-header-panel-styles__container {
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 1400px;
  margin: 92px auto 192px;
}

.ladder_ux_panels__rebrand-header-panel-styles__container-2022-restyle {
  margin: 0 auto 50px;
}

.ladder_ux_panels__rebrand-header-panel-styles__mobile-only-container {
  display: none;
}

.ladder_ux_panels__rebrand-header-panel-styles__non-mobile-container {
  display: flex;
}

.ladder_ux_panels__rebrand-header-panel-styles__text-container {
  max-width: 1400px;
}

.ladder_ux_panels__rebrand-header-panel-styles__text-container-homepage-ui {
  margin: 32px 0 64px 0;
}

.ladder_ux_panels__rebrand-header-panel-styles__mobile-agent-container {

}

.ladder_ux_panels__rebrand-header-panel-styles__mobile-agent-text-container {

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_panels__rebrand-header-panel-styles__container {
    margin: 36px auto;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_panels__rebrand-header-panel-styles__root {
    min-height: 540px;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .ladder_ux_panels__rebrand-header-panel-styles__root-llic-v2 {
    min-height: 600px;
    background-color: #E0E3E7;
    padding-top: calc(60px + 25%);
  }
  
  .ladder_ux_panels__rebrand-header-panel-styles__container {
    margin: 19px auto 0;
    width: 90%;
  }
  
  .ladder_ux_panels__rebrand-header-panel-styles__mobile-agent-container {
    margin-top: 0px;
  }
  
  .ladder_ux_panels__rebrand-header-panel-styles__mobile-agent-text-container-margin {
    margin: 225px 0 12.0px 0;
  }
  
  .ladder_ux_panels__rebrand-header-panel-styles__text-container-margin {
    margin: 200px 0 30px 0;
  }
  
  .ladder_ux_panels__rebrand-header-panel-styles__mobile-only-container {
    display: flex;
  }
  
  .ladder_ux_panels__rebrand-header-panel-styles__non-mobile-container {
    display: none;
  }
  
  .ladder_ux_panels__rebrand-header-panel-styles__text-container {
    max-width: 100%;
  }
  
  .ladder_ux_panels__rebrand-header-panel-styles__text-container-homepage-ui {
    margin: 16px 0 16px 0;
  }

}
.ladder_onboarding_shared_components__no-buttons-margin__buttons {
  margin-top: 0px;
}

@media (min-width: 1025px) {

  
  
  .ladder_onboarding_shared_components__no-buttons-margin__buttons {
    display: flex;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_onboarding_shared_components__no-buttons-margin__buttons {
    display: flex;
  }

}
.ladder_account_next_cards_welcome__WelcomeCard-styles__\% {
  border: 1px solid #707070;
  display: flex;
  margin: 16px 0px;
}

.ladder_account_next_cards_welcome__WelcomeCard-styles__welcome {
  margin-top: 30.0px;
  margin-right: 20px;
  line-height: 1.3em;
}

@media (min-width: 1025px) {

  
  
  .ladder_account_next_cards_welcome__WelcomeCard-styles__welcome {
    font-size: 32px;
    margin-left: 30.0px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account_next_cards_welcome__WelcomeCard-styles__welcome {
    font-size: 28px;
    margin-left: 30.0px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_account_next_cards_welcome__WelcomeCard-styles__welcome {
    margin-top: 50.0px;
  }

}
.ladder_apply_progress_bar__progress-line-styles__root {
  width: 100%;
  display: flex;
  align-items: center;
}

.ladder_apply_progress_bar__progress-line-styles__line {
  display: flex;
  flex-grow: 1;
  height: 6px;
  background-color: #D9D9D9;
}

.ladder_apply_progress_bar__progress-line-styles__progress-line {
  height: 6px;
  background-color: #CF967E;
}

.ladder_apply_progress_bar__progress-line-styles__rungs-progress-line-height {
  height: 8.0px;
}

.ladder_apply_progress_bar__progress-line-styles__rounded-left {
  border-radius: 3px 0 0 3px;
}

.ladder_apply_progress_bar__progress-line-styles__rounded-right {
  border-radius: 0 3px 3px 0;
}

.ladder_apply_progress_bar__progress-line-styles__rounded-left-and-right {
  border-radius: 3px;
}

.ladder_apply_progress_bar__progress-line-styles__dot {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  background-color: #D9D9D9;
  box-shadow: inset 0 2px 6px #B5B5B5;
  border-radius: 100%;
}

.ladder_apply_progress_bar__progress-line-styles__dot-checked {
  background-color: #CF967E;
  box-shadow: none;
}
.ladder_ux_expand_card__rungs-expand-card-styles__root {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  transition: all 250ms linear;
}

.ladder_ux_expand_card__rungs-expand-card-styles__root:not(:last-child) {
  border-bottom: 1px solid #D0D0D0;
}

.ladder_ux_expand_card__rungs-expand-card-styles__root-l-height {
  margin-top: 16px;
  min-height: 80px;
}

.ladder_ux_expand_card__rungs-expand-card-styles__title-wrapper {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px 0;
}

.ladder_ux_expand_card__rungs-expand-card-styles__title-wrapper:hover {
  cursor: pointer;
}

.ladder_ux_expand_card__rungs-expand-card-styles__title-wrapper.ladder_ux_expand_card__rungs-expand-card-styles__stick-to-top {
  align-items: flex-start;
}

.ladder_ux_expand_card__rungs-expand-card-styles__title-wrapper.ladder_ux_expand_card__rungs-expand-card-styles__title-no-padding {
  padding: 0;
  margin: 0;
}

.ladder_ux_expand_card__rungs-expand-card-styles__root-expanded {
  border-bottom: none;
}

.ladder_ux_expand_card__rungs-expand-card-styles__icon {
  width: 20.0px;
  height: 20.0px;
  padding: 0 0 0 4.0px;
}

.ladder_ux_expand_card__rungs-expand-card-styles__icon-l-height {
  width: 32px;
  height: 32px;
}

.ladder_ux_expand_card__rungs-expand-card-styles__description {
  padding: 0 0 16px 0;
  text-align: left;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_expand_card__rungs-expand-card-styles__title-wrapper {
    padding: 12.0px 0;
  }

}
.ladder_account_shared_components__AccountPageTitle-styles__title {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}

.ladder_account_shared_components__AccountPageTitle-styles__big-text {
  margin-right: 20px;
}

@media (max-width: 499px) {

  
  
  .ladder_account_shared_components__AccountPageTitle-styles__title {
    margin-top: 15.0px;
  }

}
.ladder_ux_banner__alert-banner-styles__\% {
  background-color: #FBDBA7;
}
.ladder_guide_how_much_life_insurance__flexible-coverage-panel-styles__container {
  width: 100%;
  margin-top: 60px;
  background-color: #E4DCCE;
}

.ladder_guide_how_much_life_insurance__flexible-coverage-panel-styles__color-container {
  position: relative;
}

.ladder_guide_how_much_life_insurance__flexible-coverage-panel-styles__image-container {
  display: inline-block;
}

.ladder_guide_how_much_life_insurance__flexible-coverage-panel-styles__image {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 70%;
}

.ladder_guide_how_much_life_insurance__flexible-coverage-panel-styles__markdown-container {
  position: relative;
  z-index: 1;
}

@media (min-width: 1025px) {

  
  
  .ladder_guide_how_much_life_insurance__flexible-coverage-panel-styles__image {
    width: 63%;
  }
  
  .ladder_guide_how_much_life_insurance__flexible-coverage-panel-styles__space-remaining-after-menu {
    padding-left: 70px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_guide_how_much_life_insurance__flexible-coverage-panel-styles__container {
  
  }
  
  .ladder_guide_how_much_life_insurance__flexible-coverage-panel-styles__second-to-last-paragraph {
    width: 60%;
  }
  
  .ladder_guide_how_much_life_insurance__flexible-coverage-panel-styles__last-paragraph {
    width: 54%;
  }
  
  .ladder_guide_how_much_life_insurance__flexible-coverage-panel-styles__color-container {
    padding-top: 60px;
    padding-bottom: 9%;
  }
  
  .ladder_guide_how_much_life_insurance__flexible-coverage-panel-styles__image {
    max-width: 870px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_guide_how_much_life_insurance__flexible-coverage-panel-styles__container {
  
  }
  
  .ladder_guide_how_much_life_insurance__flexible-coverage-panel-styles__second-to-last-paragraph {
    width: 60%;
  }
  
  .ladder_guide_how_much_life_insurance__flexible-coverage-panel-styles__last-paragraph {
    width: 54%;
  }
  
  .ladder_guide_how_much_life_insurance__flexible-coverage-panel-styles__color-container {
    padding-top: 60px;
    padding-bottom: 9%;
  }
  
  .ladder_guide_how_much_life_insurance__flexible-coverage-panel-styles__image {
    max-width: 870px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_guide_how_much_life_insurance__flexible-coverage-panel-styles__image {
    width: 70%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_guide_how_much_life_insurance__flexible-coverage-panel-styles__color-container {
    padding-top: 60px;
    padding-bottom: 75%;
  }
  
  .ladder_guide_how_much_life_insurance__flexible-coverage-panel-styles__image {
    width: 100%;
  }
  
  .ladder_guide_how_much_life_insurance__flexible-coverage-panel-styles__container {
  
  }

}
.ladder_views_decision_tic_offer_accepted__root-styles__root {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.ladder_footer__copyright-styles__root {
  text-align: center;
  max-width: 1100px;
  width: 90%;
  margin: 0 auto 0 auto;
}

.ladder_footer__copyright-styles__container {
  margin-bottom: 10px;
}

.ladder_footer__copyright-styles__disclaimer-line {
  margin-bottom: 5px;
}

.ladder_footer__copyright-styles__disclaimer-group {
  display: flex;
  flex-direction: column;
}
.ladder_views_api__request-access-modal-styles__inputs-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.ladder_views_api__request-access-modal-styles__input-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10.0px 0;
}

.ladder_views_api__request-access-modal-styles__half-desktop {
  width: calc(50% - 10px);
}

.ladder_views_api__request-access-modal-styles__terms-container {
  margin: 0 10.0px;
  padding: 10.0px 0;
  justify-content: left;
}

.ladder_views_api__request-access-modal-styles__terms-agreement {
  margin-left: 10.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_views_api__request-access-modal-styles__half-desktop {
    width: 100%;
  }
  
  .ladder_views_api__request-access-modal-styles__inputs-container {
    flex-direction: column;
  }
  
  .ladder_views_api__request-access-modal-styles__terms-container {
    flex-direction: row;
  }

}
.ladder_work_home__mountain-section-styles__root {
  width: 100%;
  background: linear-gradient(to bottom, #FFFFFF 0%,#FFFFFF 65%,#F7F2EF 65%,#F7F2EF 100%);
  display: flex;
  justify-content: center;
}

.ladder_work_home__mountain-section-styles__image-wrapper {
  display: flex;
  width: 70%;
  height: auto;
}

.ladder_work_home__mountain-section-styles__image {
  width: 100%;
  height: auto;
}

.ladder_work_home__mountain-section-styles__rungs-image {
  display: block;
  width: 50%;
  height: auto;
  margin: 0 auto;
}

@media (max-width: 499px) {

  
  
  .ladder_work_home__mountain-section-styles__root {
    width: 250%;
  }
  
  .ladder_work_home__mountain-section-styles__rungs-image {
    width: 25%;
  }

}
.ladder_guide_life_insurance_guide__life-insurance-guide-start-panel-styles__root {
  display: flex;
  position: relative;
  background: #EFEAE1;
  flex-direction: column;
  min-height: 850px;
  width: 100%;
  z-index: 1;
  overflow: visible;
}

.ladder_guide_life_insurance_guide__life-insurance-guide-start-panel-styles__question-banner {
  margin: auto;
  width: 90%;
  background: #CFDCDE;
  align-self: center;
  padding: 50.0px;
  text-align: center;
}

.ladder_guide_life_insurance_guide__life-insurance-guide-start-panel-styles__space-question-banner-text {
  margin-bottom: 22.5px;
}

.ladder_guide_life_insurance_guide__life-insurance-guide-start-panel-styles__text-outer-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 80%;
  max-width: 1400px;
  margin: 150px auto 40px;
}

.ladder_guide_life_insurance_guide__life-insurance-guide-start-panel-styles__text {
  margin: 30.0px 0 60px 0;
  width: 100%;
}

.ladder_guide_life_insurance_guide__life-insurance-guide-start-panel-styles__image-container {
  z-index: -1;
}

@media (min-width: 1025px) {

  
  
  .ladder_guide_life_insurance_guide__life-insurance-guide-start-panel-styles__image {
    width: 35%;
    max-width: 980px;
    height: auto;
    position: absolute;
    top: 120px;
    left: 100px;
  }
  
  .ladder_guide_life_insurance_guide__life-insurance-guide-start-panel-styles__image-container {
    overflow: hidden;
    margin-top: 300px;
  }
  
  .ladder_guide_life_insurance_guide__life-insurance-guide-start-panel-styles__text-container {
    max-width: 728px;
    text-align: right;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_guide_life_insurance_guide__life-insurance-guide-start-panel-styles__image {
    width: 35%;
    max-width: 980px;
    height: auto;
    position: absolute;
    top: 120px;
    left: 100px;
  }
  
  .ladder_guide_life_insurance_guide__life-insurance-guide-start-panel-styles__image-container {
    overflow: hidden;
    margin-top: 300px;
  }
  
  .ladder_guide_life_insurance_guide__life-insurance-guide-start-panel-styles__text-container {
    max-width: 728px;
    text-align: right;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_guide_life_insurance_guide__life-insurance-guide-start-panel-styles__text-outer-container {
    margin: 130.0px auto 0;
    width: 90%;
  }
  
  .ladder_guide_life_insurance_guide__life-insurance-guide-start-panel-styles__text-container {
    text-align: right;
  }
  
  .ladder_guide_life_insurance_guide__life-insurance-guide-start-panel-styles__image {
    top: 60px;
    left: 50px;
    width: 40%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_guide_life_insurance_guide__life-insurance-guide-start-panel-styles__image-title-container {
    margin-bottom: 60px;
  }
  
  .ladder_guide_life_insurance_guide__life-insurance-guide-start-panel-styles__image {
    width: 70%;
    height: auto;
    position: absolute;
    display: flex;
    top: 43px;
    left: -25px;
    right: -8%;
  }
  
  .ladder_guide_life_insurance_guide__life-insurance-guide-start-panel-styles__image-container {
    overflow: hidden;
    margin-top: 300px;
  }
  
  .ladder_guide_life_insurance_guide__life-insurance-guide-start-panel-styles__root {
    min-height: 540px;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .ladder_guide_life_insurance_guide__life-insurance-guide-start-panel-styles__text-outer-container {
    margin: 36px auto 0;
    width: 90%;
  }
  
  .ladder_guide_life_insurance_guide__life-insurance-guide-start-panel-styles__text-container {
    max-width: 90%;
    margin-top: 60px;
    text-align: right;
  }

}
.ladder_trustage_quote__quote-content-styles__info {
  margin-top: 25.0px;
}

.ladder_trustage_quote__quote-content-styles__info>*:first-child {
  margin-bottom: 20px;
}

@media (max-width: 499px) {

  
  
  .ladder_trustage_quote__quote-content-styles__trustage-button {
    width: 100%;
  }

}
.ladder_landing_pages_so_good_common__desktop-episode-selector-styles__basics-middle-container {
  width: 80%;
  display: flex;
  justify-content: space-between;
}

.ladder_landing_pages_so_good_common__desktop-episode-selector-styles__episode-selector {
  color: #FFFFFF;
  font-size: 20px;
  padding: 8px 16px;
  border: solid 1px #FFFFFF;
  border-radius: 100px;
  margin-left: 12px;
  cursor: pointer;
}

.ladder_landing_pages_so_good_common__desktop-episode-selector-styles__episode-selector:hover {
  opacity: 0.6;
}

.ladder_landing_pages_so_good_common__desktop-episode-selector-styles__episode-selected {
  background-color: #FFFFFF;
}

.ladder_landing_pages_so_good_common__desktop-episode-selector-styles__selectors-container {
  display: flex;
  align-items: center;
}
.tile-a .ladder_widget_ui_v2__weight-field-styles__root {
  width: 130.0px;
  margin-top: 10.0px;
}

.tile-b .ladder_widget_ui_v2__weight-field-styles__root {
  width: 95.0px;
}

.landscape .ladder_widget_ui_v2__weight-field-styles__root {
  width: 32%;
}
.ladder_form_id_verification__root-styles__root {
  width: 100%;
}

.ladder_form_id_verification__root-styles__heading {
  padding-bottom: 30.0px;
  width: 100%;
}
.ladder_form_common__regular-field-common-styles__root {
  width: 100%;
}

.ladder_form_common__regular-field-common-styles__top-level-question {
  margin-bottom: 32px;
}

.ladder_form_common__regular-field-common-styles__caption-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 32px;
}

.ladder_form_common__regular-field-common-styles__post-sign-drq-caption-container {
  margin-bottom: 20px;
}

.ladder_form_common__regular-field-common-styles__caption-inline {
  display: inline;
}

.ladder_form_common__regular-field-common-styles__caption-centered {
  justify-content: space-around;
}

.ladder_form_common__regular-field-common-styles__emphasized-subcaption {
  padding-bottom: 20px;
}

.ladder_form_common__regular-field-common-styles__regular-field-has-error {

}

.ladder_form_common__regular-field-common-styles__info-question-text {
  display: flex;
  align-items: center;
}

@media (max-width: 499px) {

  
  
  .ladder_form_common__regular-field-common-styles__top-level-question {
    margin-bottom: 24px;
  }
  
  .ladder_form_common__regular-field-common-styles__caption-container {
    margin-bottom: 24px;
  }
  
  .ladder_form_common__regular-field-common-styles__emphasized-subcaption {
    padding-bottom: 5.0px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_form_common__regular-field-common-styles__helper-subcaption {
    padding-bottom: 10.0px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_form_common__regular-field-common-styles__helper-subcaption {
    padding-bottom: 10.0px;
  }

}
.ladder_landing_pages_so_good_core__trustpilot-styles__trustpilot-root {
  width: 100%;
  padding: 64px 0px;
  background: #F7F2EF;
}

.ladder_landing_pages_so_good_core__trustpilot-styles__trustpilot-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ladder_landing_pages_so_good_core__trustpilot-styles__trustpilot-logo {
  height: 33px;
  width: auto;
  margin-right: 20px;
}

.ladder_landing_pages_so_good_core__trustpilot-styles__trustpilot-stars {
  height: 33px;
  width: auto;
  margin-right: 20px;
}

.ladder_landing_pages_so_good_core__trustpilot-styles__rating-link {
  font-size: 14px;
}

@media (max-width: 499px) {

  
  
  .ladder_landing_pages_so_good_core__trustpilot-styles__trustpilot-root {
    display: flex;
    justify-content: center;
    padding: 32px 0px;
  }
  
  .ladder_landing_pages_so_good_core__trustpilot-styles__trustpilot-container {
    width: 90%;
  }
  
  .ladder_landing_pages_so_good_core__trustpilot-styles__trustpilot-logo {
    margin-right: 20px;
  }
  
  .ladder_landing_pages_so_good_core__trustpilot-styles__trustpilot-stars {
    margin-right: 20px;
  }
  
  .ladder_landing_pages_so_good_core__trustpilot-styles__rating-link {
    font-size: 10px;
    white-space: nowrap;
  }

}
.ladder_financial_advisors_home__top-panel-text-content-styles__\% {
  padding: 0 5.5% 50px 5.5%;
}

@media (min-width: 1025px) {

  
  
  .ladder_financial_advisors_home__top-panel-text-content-styles__\% {
    padding: 0;
    width: 555px;
  }

}
.ladder_account_next_views_beneficiaries_shares__ModifyPercentage\*-styles__card {
  margin-top: 30px;
}
.ladder_views_next__flash-message-styles__root {
  display: flex;
  align-items: center;
}

@media (min-width: 1025px) {

  
  
  .ladder_views_next__flash-message-styles__root {
    padding: 25.0px 60px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_views_next__flash-message-styles__root {
    padding: 20px 20px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_views_next__flash-message-styles__root {
    padding: 20px 20px;
  }

}

.ladder_views_next__flash-message-styles__message {
  flex-grow: 1;
}

.ladder_views_next__flash-message-styles__close {
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.ladder_views_next__flash-message-styles__error {
  background-color: #FBDBA7;
}

.ladder_views_next__flash-message-styles__success {
  background-color: #BACDEC;
}

.ladder_views_next__flash-message-styles__warn {
  background-color: #FBDBA7;
}

.ladder_views_next__flash-message-styles__image {
  padding-right: 10px;
  width: 30px;
}
.ladder_agents_common__form-group-styles__root {
  display: flex;
  flex-direction: column;
  padding: 0 30.0px;
}
.ladder_agents_dashboard_contacts__contact-card-expansion-section-styles__column-header {
  font-size: 15px;
}

.ladder_agents_dashboard_contacts__contact-card-expansion-section-styles__column-value {
  font-size: 18px;
}

.ladder_agents_dashboard_contacts__contact-card-expansion-section-styles__column {
  display: flex;
  flex-direction: column;
}

.ladder_agents_dashboard_contacts__contact-card-expansion-section-styles__buttons-wide-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.ladder_agents_dashboard_contacts__contact-card-expansion-section-styles__expanded-info-rows {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 40px;
}

.ladder_agents_dashboard_contacts__contact-card-expansion-section-styles__expanded-info-pairs {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 90%;
}

@media (min-width: 1025px) {

  
  
  .ladder_agents_dashboard_contacts__contact-card-expansion-section-styles__button-container-with-gap {
    display: flex;
    flex-direction: row;
    gap: 2em;
    width: 60%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard_contacts__contact-card-expansion-section-styles__button-container-with-gap {
    display: flex;
    flex-direction: row;
    gap: 2em;
    width: 80%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_dashboard_contacts__contact-card-expansion-section-styles__button-container-with-gap {
    display: flex;
    flex-direction: row;
    gap: 2em;
    width: 80%;
  }

}
.ladder_markdown_components__bold-styles__root {
  font-weight: bold;
}
.ladder_views_payment__payment-options-container-styles__root {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.ladder_onboarding_accepted_offer__cover-me-asap-faq-styles__root {
  display: flex;
  flex-direction: column;
  min-height: 420px;
  height: 100%;
  width: 100%;
  margin: 30.0px 0;
}

.ladder_onboarding_accepted_offer__cover-me-asap-faq-styles__expand-card {
  margin-top: 20px;
}

.ladder_onboarding_accepted_offer__cover-me-asap-faq-styles__faq-text {
  padding: 0 10.0px 10.0px 16px;
}

@media (max-width: 499px) {

  
  
  .ladder_onboarding_accepted_offer__cover-me-asap-faq-styles__root {
    margin: 10.0px 0 30.0px 0;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_onboarding_accepted_offer__cover-me-asap-faq-styles__root {
    margin: 10.0px 0 30.0px 0;
  }

}
.ladder_ux_button__spinner-styles__root {
  width: 24px;
  border-width: 3px;
  top: calc(50% - 12px);
  height: 24px;
  border-style: solid;
  position: absolute;
  animation: spin 1.1s infinite linear;
  border-bottom-color: #D0D0D0;
  border-radius: 50%;
  box-sizing: border-box;
  left: calc(50% - 12px);
}
@media (max-width: 499px) {

  
  
  .ladder_header__top-nav-icon-modal-expander-styles__spacing {
    margin-right: 15.0px;
  }

}
.ladder_ux_typography__swoosh-styles__swoosh-wrapper {
  position: relative;
  padding-bottom: 10px;
  display: inline-block;
  z-index: 1;
}

.ladder_ux_typography__swoosh-styles__swoosh-wrapper>* {
  position: relative;
}

.ladder_ux_typography__swoosh-styles__m {
  padding-bottom: 10px;
}

.ladder_ux_typography__swoosh-styles__l {
  padding-bottom: 16px;
}

.ladder_ux_typography__swoosh-styles__swoosh {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: -2;
}

.ladder_ux_typography__swoosh-styles__animate-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.ladder_ux_typography__swoosh-styles__animate {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1.0;
  background-color: #F7F2EF;
  animation: 0.25s ease-in 0s 1 normal forwards running move-across-narrow;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_typography__swoosh-styles__swoosh {
    max-height: 30%;
  }

}
.ladder_ux_button__primary-arrow-styles__arrow {
  padding: 0 0 2px 4.0px;
}
.ladder_agents_dashboard_contacts__contact-detail-page-personal-details-section-styles__container-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.ladder_agents_dashboard_contacts__contact-detail-page-personal-details-section-styles__content-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  gap: 1em;
}

.ladder_agents_dashboard_contacts__contact-detail-page-personal-details-section-styles__edit-button {
  cursor: pointer;
}
.ladder_cover_me_asap_views_resource_center__resource-center-tile-styles__content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #D9D9D9;
  border-radius: 8px;
  padding: 20px;
  text-align: left;
}

.ladder_cover_me_asap_views_resource_center__resource-center-tile-styles__content-wrapper:hover {
  border: 1px solid #975C46;
  background-color: #F7F2EF;
  cursor: pointer;
}

.ladder_cover_me_asap_views_resource_center__resource-center-tile-styles__info-icon {
  flex-shrink: 0;
  flex-grow: 0;
}

.ladder_cover_me_asap_views_resource_center__resource-center-tile-styles__icon-and-title {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

@media (min-width: 1025px) {

  
  
  .ladder_cover_me_asap_views_resource_center__resource-center-tile-styles__content-wrapper {
    width: 100%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_cover_me_asap_views_resource_center__resource-center-tile-styles__content-wrapper {
    max-width: 100%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_cover_me_asap_views_resource_center__resource-center-tile-styles__content-wrapper {
    max-width: 100%;
  }

}
.ladder_markdown_components__table-row-styles__root {

}
.ladder_markdoc_tags_specs__spec-comp-styles__child-container {
  margin: 1em 0;
}
.ladder_app_review__multiselect-styles__root {
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.ladder_app_review__multiselect-styles__multiselect-root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ladder_app_review__multiselect-styles__option-root {
  display: flex;
  text-align: left;
  padding: 5.0px 0;
}

.ladder_app_review__multiselect-styles__option {
  display: flex;
  cursor: pointer;
}

.ladder_app_review__multiselect-styles__checkbox-container {
  padding-top: 3px;
  padding-right: 10.0px;
}

.ladder_app_review__multiselect-styles__info-expander-container {
  padding-top: 3px;
  padding-left: 10.0px;
}

.ladder_app_review__multiselect-styles__display-lines {
  display: flex;
  flex-direction: column;
}

.ladder_app_review__multiselect-styles__hidden {
  visibility: hidden;
}

.ladder_app_review__multiselect-styles__pencil {
  height: 16px;
  width: 16px;
}

.ladder_app_review__multiselect-styles__pencil-wrapper {
  padding-left: 20px;
}

@media (max-width: 499px) {

  
  
  .ladder_app_review__multiselect-styles__checkbox-container {
    padding-top: 0;
  }
  
  .ladder_app_review__multiselect-styles__info-expander-container {
    padding-top: 0;
  }

}
.ladder_onboarding_referrals__header-and-text-small-styles__text-container {
  margin-left: 40px;
}

@media (max-width: 499px) {

  
  
  .ladder_onboarding_referrals__header-and-text-small-styles__text-container {
    margin-left: 25.0px;
  }

}

.ladder_onboarding_referrals__header-and-text-small-styles__little-text {
  font-size: 15.0px;
  color: black;
}
.ladder_aimcor_form__field-styles__wrapper {
  margin-bottom: 20px;
}

.ladder_aimcor_form__field-styles__caption {
  margin-bottom: 10.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_aimcor_form__field-styles__subcaption {
    margin-top: 5.0px;
  }

}
.ladder_views_decision_common__accelerated-subtext-styles__root {
  margin-top: 20px;
}

@media (max-width: 499px) {

  
  
  .ladder_views_decision_common__accelerated-subtext-styles__root {
    margin-top: 0;
  }

}
.ladder_landing_pages_so_good_hero__lttd-background-styles__image-container {
  z-index: -1;
}

.ladder_landing_pages_so_good_hero__lttd-background-styles__image {
  width: 100%;
  height: auto;
  z-index: -1;
  position: absolute;
  object-fit: stretch;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_landing_pages_so_good_hero__lttd-background-styles__image {
    width: 130%;
    height: auto;
    right: -10%;
    object-fit: contain;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_landing_pages_so_good_hero__lttd-background-styles__image {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

}
.ladder_financial_advisors_laddering__title-styles__root {
  display: flex;
  position: relative;
  background: #EFEAE1;
  flex-direction: row;
  min-height: 700px;
  width: 100%;
  z-index: 1;
  overflow: hidden;
}

.ladder_financial_advisors_laddering__title-styles__container {
  display: flex;
  flex-direction: row;
  width: 80%;
  max-width: 1800px;
  margin: 92px auto;
}

@media (max-width: 499px) {

  
  
  .ladder_financial_advisors_laddering__title-styles__container {
    flex-direction: column;
    margin: 36px auto 0;
    justify-content: space-between;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_financial_advisors_laddering__title-styles__container {
    flex-direction: column;
    margin: 36px auto 0;
    justify-content: space-between;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_financial_advisors_laddering__title-styles__root {
    min-height: 660px;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .ladder_financial_advisors_laddering__title-styles__container {
    width: 90%;
  }

}
.ladder_advisor_only_standalone_quoter__header-styles__root {
  display: flex;
  flex-direction: row;
  height: 100px;
  flex-grow: 0;
}

.ladder_advisor_only_standalone_quoter__header-styles__plus-symbol {
  padding: 0 15.0px;
}

.ladder_advisor_only_standalone_quoter__header-styles__flex-container {
  height: 100%;
  justify-content: space-between;
}

.ladder_advisor_only_standalone_quoter__header-styles__left-side-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.ladder_advisor_only_standalone_quoter__header-styles__right-side-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}

@media (min-width: 1025px) {

  
  
  .ladder_advisor_only_standalone_quoter__header-styles__flex-container {
    padding: 0 60px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_advisor_only_standalone_quoter__header-styles__flex-container {
    padding: 0 20px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_advisor_only_standalone_quoter__header-styles__flex-container {
    padding: 0 20px;
  }

}
.ladder_careers__ui-remote-life-styles__root {
  margin: 60px 0 60px 0;
}

.ladder_careers__ui-remote-life-styles__picture-container {
  display: flex;
  flex-direction: row;
  text-align: center;
  width: 100%;
}

.ladder_careers__ui-remote-life-styles__valentines-container {
  width: 100%;
  height: auto;
  max-width: 1100px;
  margin: 0 auto 0 auto;
  text-align: right;
}

.ladder_careers__ui-remote-life-styles__valentines-collage {
  width: 100%;
  height: auto;
}

.ladder_careers__ui-remote-life-styles__picture {
  margin: 50.0px auto 0 auto;
  max-width: 264px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_careers__ui-remote-life-styles__valentines-arrow {
    margin-right: 22.5px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_careers__ui-remote-life-styles__valentines-arrow {
    margin: 22.5px 22.5px 10.0px 0;
  }
  
  .ladder_careers__ui-remote-life-styles__return-to-office {
    margin-top: 30.0px;
    margin-bottom: 50.0px;
  }
  
  .ladder_careers__ui-remote-life-styles__remote-stipends-container {
    padding: 30.0px 0 30.0px 0;
  }
  
  .ladder_careers__ui-remote-life-styles__picture-container {
    display: block;
    flex-direction: column;
  }

}
.ladder_widget_ui_v2__error-message-styles__root {
  text-align: center;
}

.tile-a .ladder_widget_ui_v2__error-message-styles__root {
  font-size: 16px;
  line-height: 26px;
}

.tile-b .ladder_widget_ui_v2__error-message-styles__root {
  font-size: 16px;
  line-height: 26px;
}

.portrait .ladder_widget_ui_v2__error-message-styles__root {
  font-size: 24px;
  line-height: 32px;
}

.landscape .ladder_widget_ui_v2__error-message-styles__root {
  font-size: 24px;
  line-height: 32px;
}
.ladder_agents_agent_assisted_app_views_waiting_for_decision__waiting-for-decision-styles__root {
  display: flex;
  justify-content: center;
  width: 100%;
}

.ladder_agents_agent_assisted_app_views_waiting_for_decision__waiting-for-decision-styles__content {
  display: flex;
  align-items: center;
}

@media (min-width: 1025px) {

  
  
  .ladder_agents_agent_assisted_app_views_waiting_for_decision__waiting-for-decision-styles__root {
    padding-top: 120px;
    padding-bottom: 120px;
  }
  
  .ladder_agents_agent_assisted_app_views_waiting_for_decision__waiting-for-decision-styles__content {
    justify-content: space-between;
    flex-grow: 1;
    height: 417px;
    max-width: 1000px;
  }
  
  .ladder_agents_agent_assisted_app_views_waiting_for_decision__waiting-for-decision-styles__text-container {
    max-width: 570px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_agents_agent_assisted_app_views_waiting_for_decision__waiting-for-decision-styles__root {
    padding-top: 32px;
    padding-left: 32px;
    padding-right: 32px;
  }
  
  .ladder_agents_agent_assisted_app_views_waiting_for_decision__waiting-for-decision-styles__content {
    flex-direction: column;
    max-width: 400px;
  }
  
  .ladder_agents_agent_assisted_app_views_waiting_for_decision__waiting-for-decision-styles__text-container {
    margin-top: 45px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_agent_assisted_app_views_waiting_for_decision__waiting-for-decision-styles__root {
    padding-top: 32px;
    padding-left: 32px;
    padding-right: 32px;
  }
  
  .ladder_agents_agent_assisted_app_views_waiting_for_decision__waiting-for-decision-styles__content {
    flex-direction: column;
    max-width: 400px;
  }
  
  .ladder_agents_agent_assisted_app_views_waiting_for_decision__waiting-for-decision-styles__text-container {
    margin-top: 45px;
  }

}
@media (min-width: 1025px) {

  
  
  .ladder_apply_question_description__blurb-styles__blurb {
    align-self: flex-start;
    width: 100%;
    margin-top: 30%;
    position: sticky;
    top: 20vh;
    padding: 0 10%;
  }
  
  .ladder_apply_question_description__blurb-styles__header {
    margin-bottom: 20px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_apply_question_description__blurb-styles__blurb {
    align-self: flex-start;
    width: 100%;
    margin-top: 30%;
    position: sticky;
    top: 20vh;
    padding: 0 10%;
  }
  
  .ladder_apply_question_description__blurb-styles__header {
    margin-bottom: 20px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_apply_question_description__blurb-styles__blurb {
    background-color: #F7F2EF;
    padding: 25px 6%;
  }

}
.ladder_account_next_views_gift__fitbit-selector-styles__fitbit-chooser {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50% 50%;
}

.ladder_account_next_views_gift__fitbit-selector-styles__fitbit-option {
  border: 3px solid #975C46;
  display: block;
  border-radius: 15px;
  margin: 15px;
  padding: 15px;
}

.ladder_account_next_views_gift__fitbit-selector-styles__fitbit-option-selected {
  border-color: #749475;
  background-color: #F7F2EF;
}

.ladder_account_next_views_gift__fitbit-selector-styles__fitbit-img {
  width: 100%;
}

.ladder_account_next_views_gift__fitbit-selector-styles__fitbit-text {
  width: 100%;
}

@media (max-width: 499px) {

  
  
  .ladder_account_next_views_gift__fitbit-selector-styles__fitbit-chooser {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: repeat(3, 33%);
  }

}
.ladder_financial_advisors_home__top-panel-signup-button-styles__\% {
  width: 100%;
  margin: 20px 0 30px 0;
  padding: 0 8px;
}
.ladder_text__small-label-styles__root {

}
.ladder_quick_start_panels__story-panel-styles__root {
  display: flex;
  width: 100%;
  height: 45vw;
}

.ladder_quick_start_panels__story-panel-styles__image-panel {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 45%;
  height: auto;
  background-color: #E7EEEF;
}

.ladder_quick_start_panels__story-panel-styles__image {
  display: flex;
  height: auto;
  width: 100%;
  align-self: flex-end;
}

.ladder_quick_start_panels__story-panel-styles__testimonial-panel {
  display: flex;
  width: 55%;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
}

.ladder_quick_start_panels__story-panel-styles__content {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 85%;
}

.ladder_quick_start_panels__story-panel-styles__top-bar-wrapper {
  display: flex;
  width: 100%;
  height: 0.3vw;
  justify-content: space-between;
}

.ladder_quick_start_panels__story-panel-styles__gray-bar-wrapper {
  display: flex;
  position: relative;
  flex-grow: 1;
  height: 100%;
  z-index: 1;
  margin: 16px 0.5% 0 0.5%;
  background-color: #F1F1F1;
  cursor: pointer;
}

.ladder_quick_start_panels__story-panel-styles__gray-bar-wrapper:first-child {
  margin-right: 0.5%;
}

.ladder_quick_start_panels__story-panel-styles__gray-bar-wrapper:last-child {
  margin-left: 0.5%;
}

.ladder_quick_start_panels__story-panel-styles__current-bar {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  width: 0%;
  background-color: #1A1A1A;
}

.ladder_quick_start_panels__story-panel-styles__current-bar.ladder_quick_start_panels__story-panel-styles__transition {
  transition: 5s linear;
}

.ladder_quick_start_panels__story-panel-styles__current-bar.ladder_quick_start_panels__story-panel-styles__selected {
  width: 100%;
}

.ladder_quick_start_panels__story-panel-styles__current-bar.ladder_quick_start_panels__story-panel-styles__unselected {
  transition: 0s;
}

@media (max-width: 499px) {

  
  
  .ladder_quick_start_panels__story-panel-styles__root {
    flex-direction: column-reverse;
    height: auto;
  }
  
  .ladder_quick_start_panels__story-panel-styles__image-panel {
    width: 100%;
    position: unset;
  }
  
  .ladder_quick_start_panels__story-panel-styles__image {
    position: unset;
    margin-top: 64px;
  }
  
  .ladder_quick_start_panels__story-panel-styles__testimonial-panel {
    width: 100%;
    height: 450px;
  }
  
  .ladder_quick_start_panels__story-panel-styles__content {
    height: 85%;
  }
  
  .ladder_quick_start_panels__story-panel-styles__top-bar-wrapper {
    height: 3px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_quick_start_panels__story-panel-styles__root {
    flex-direction: column-reverse;
    height: auto;
  }
  
  .ladder_quick_start_panels__story-panel-styles__image-panel {
    width: 100%;
    position: unset;
  }
  
  .ladder_quick_start_panels__story-panel-styles__image {
    position: unset;
    margin-top: 64px;
  }
  
  .ladder_quick_start_panels__story-panel-styles__testimonial-panel {
    width: 100%;
    height: 450px;
  }
  
  .ladder_quick_start_panels__story-panel-styles__content {
    height: 85%;
  }
  
  .ladder_quick_start_panels__story-panel-styles__top-bar-wrapper {
    height: 3px;
  }

}
.ladder_widget_ui_v2__price-intro-styles__root {
  align-self: center;
}

.tile-a .ladder_widget_ui_v2__price-intro-styles__root {
  font-size: 20px;
  line-height: 32px;
}

.tile-b .ladder_widget_ui_v2__price-intro-styles__root {
  font-size: 20px;
  line-height: 32px;
}

.landscape .ladder_widget_ui_v2__price-intro-styles__root {
  font-size: 30px;
  line-height: 40px;
}

.portrait .ladder_widget_ui_v2__price-intro-styles__root {
  font-size: 30px;
  line-height: 40px;
}

.panel .ladder_widget_ui_v2__price-intro-styles__root {
  font-size: 30px;
  line-height: 40px;
}

.portrait .ladder_widget_ui_v2__price-intro-styles__root {
  flex-grow: 36;
}

@media (max-width: 499px) {

  
  
  
  
  .portrait .ladder_widget_ui_v2__price-intro-styles__root {
    font-size: 27px;
  }

}

.landscape .ladder_widget_ui_v2__price-intro-styles__root {
  flex-grow: 4;
}
.ladder_ux_panels__home-image-styles__image {
  width: 70%;
  right: 0;
  max-width: 1500px;
  height: auto;
  position: absolute;
  object-fit: contain;
  object-position: right;
  bottom: 0;
  max-height: 90%;
}

.ladder_ux_panels__home-image-styles__image-container {
  z-index: -1;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_panels__home-image-styles__image {
    width: 85%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_panels__home-image-styles__image-container {
    overflow: hidden;
    margin-top: 300px;
  }
  
  .ladder_ux_panels__home-image-styles__image {
    width: 130%;
    display: flex;
    right: -8%;
  }
  
  .ladder_ux_panels__home-image-styles__llic-image-container {
    margin-top: 0;
  }
  
  .ladder_ux_panels__home-image-styles__llic-image-container .ladder_ux_panels__home-image-styles__image {
    width: 100%;
    min-height: 108%;
    max-height: unset;
    top: -8%;
    right: 0;
    object-fit: cover;
    object-position: top;
  }
  
  .ladder_ux_panels__home-image-styles__text-container-homepage-ui {
    margin: 16px 0 16px 0;
  }

}
.ladder_agents_dashboard__plus-circle-button-styles__button-container {

}

.ladder_agents_dashboard__plus-circle-button-styles__button {

}

.ladder_agents_dashboard__plus-circle-button-styles__button-circle {
  position: relative;
  width: 50px;
  height: 50px;
  background: #336FED;
  box-shadow: inset 5em 5em transparent;
  filter: drop-shadow(0 3px 6px rgba(37, 112, 170, 0.25));
  transition: box-shadow 0.3s, opacity 0.3s;
  border-radius: 50%;
}

.ladder_agents_dashboard__plus-circle-button-styles__button-circle:hover {
  cursor: pointer;
  box-shadow: inset 5em 5em rgba(255,255,255,0.3);
}

.ladder_agents_dashboard__plus-circle-button-styles__loading {
  background: #D0D0D0;
}

.ladder_agents_dashboard__plus-circle-button-styles__plus-horizontal {
  position: absolute;
  width: 21px;
  height: 3px;
  background: #FFFFFF;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ladder_agents_dashboard__plus-circle-button-styles__plus-vertical {
  position: absolute;
  width: 3px;
  height: 21px;
  background: #FFFFFF;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.ladder_landing_pages_so_good_basics__basics-facts-styles__fact-container {
  padding: 32px 0px;
}

.ladder_landing_pages_so_good_basics__basics-facts-styles__border-bottom {
  border-bottom: solid 1px #FFFFFF;
}

.ladder_landing_pages_so_good_basics__basics-facts-styles__two-fact-columns {
  display: flex;
}

.ladder_landing_pages_so_good_basics__basics-facts-styles__fact-column {
  width: 50%;
}

.ladder_landing_pages_so_good_basics__basics-facts-styles__disclaimer {
  font-size: 14px;
  color: white;
}

@media (max-width: 499px) {

  
  
  .ladder_landing_pages_so_good_basics__basics-facts-styles__two-fact-columns {
    display: block;
  }
  
  .ladder_landing_pages_so_good_basics__basics-facts-styles__fact-column {
    width: 100%;
  }

}
.ladder_agents_agent_assisted_app_views_review__price-calc-and-display-styles__section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.ladder_view_schedule__SpecialInstructionsInput-styles__special-instructions {
  margin: 20px auto;
  width: 100%;
}

@media (max-width: 499px) {

  
  
  .ladder_view_schedule__SpecialInstructionsInput-styles__special-instructions {
    width: 90%;
  }

}
.ladder_view_schedule__scheduler-styles__hidden {
  display: none;
}

.ladder_view_schedule__scheduler-styles__root {
  display: flex;
  flex-direction: column;
}

.ladder_view_schedule__scheduler-styles__subtext {
  text-align: center;
  overflow-wrap: break-word;
  max-width: 1100px;
  margin: 20px;
}

.ladder_view_schedule__scheduler-styles__error {
  text-align: center;
}

.ladder_view_schedule__scheduler-styles__img-container {
  max-width: 350px;
  margin: 0 30.0px;
}

.ladder_view_schedule__scheduler-styles__img {
  width: 100%;
  height: 100%;
}

.ladder_view_schedule__scheduler-styles__change-selection {
  text-align: center;
  margin: 30.0px 0 30.0px 0;
}

@media (max-width: 499px) {

  
  
  .ladder_view_schedule__scheduler-styles__root {
    min-height: calc(100vh - 60px);
  }

}
.ladder_financial_advisors_laddering__scenarios-styles__scenario-select {
  width: 50%;
}

@media (max-width: 499px) {

  
  
  .ladder_financial_advisors_laddering__scenarios-styles__scenario-select {
    width: 100%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_financial_advisors_laddering__scenarios-styles__scenario-select {
    width: 100%;
  }

}
.landscape .ladder_widget_ui_v2__household-income-field-styles__root {
  width: 32%;
}
.ladder_form_geico_smart_sort__smart-sort-styles__background {
  width: 100%;
}

.ladder_form_geico_smart_sort__smart-sort-styles__header {
  margin-bottom: 4.0px;
}

.ladder_form_geico_smart_sort__smart-sort-styles__card-container {
  display: flex;
  justify-content: space-between;
}

.ladder_form_geico_smart_sort__smart-sort-styles__card {
  width: 50%;
  justify-content: space-between;
  margin-right: 10px;
  margin-top: 10px;
  min-height: 200px;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: #F6F6F6;
  display: flex;
}

.ladder_form_geico_smart_sort__smart-sort-styles__card-content {
  padding: 10px;
  border-width: 1px;
  width: 100%;
  height: 100%;
  border-style: solid;
  border-radius: 5px;
  border-color: #D2D2D2;
  box-shadow: 0px 2px 5px 1px rgba(0,0,0,0.1);
}

.ladder_form_geico_smart_sort__smart-sort-styles__get-my-quote-text {
  width: 100%;
  text-align: center;
}

.ladder_form_geico_smart_sort__smart-sort-styles__content-spacing-for-text {
  height: 40%;
  padding-bottom: 10px;
}

.ladder_form_geico_smart_sort__smart-sort-styles__content-spacing {
  padding-bottom: 10px;
}

.ladder_form_geico_smart_sort__smart-sort-styles__bottom-padding {
  padding-bottom: 200px;
}

@media (max-width: 499px) {

  
  
  .ladder_form_geico_smart_sort__smart-sort-styles__bottom-padding {
    padding-bottom: 10px;
  }
  
  .ladder_form_geico_smart_sort__smart-sort-styles__card-container {
    display: flex;
    flex-direction: column;
  }
  
  .ladder_form_geico_smart_sort__smart-sort-styles__card {
    background-color: #F6F6F6;
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
  }

}
.ladder_agents_limited__benchmark-limited-styles__root {
  display: flex;
  flex-direction: row;
  background: #F7F2EF;
  justify-content: center;
  align-items: center;
}

.ladder_agents_limited__benchmark-limited-styles__image-container {
  width: 50%;
  padding-right: 10%;
  display: flex;
  justify-content: flex-end;
}

.ladder_agents_limited__benchmark-limited-styles__image {
  width: auto;
}

.ladder_agents_limited__benchmark-limited-styles__text-root {
  width: 50%;
}

.ladder_agents_limited__benchmark-limited-styles__text-content {
  width: 100%;
  max-width: 650px;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.ladder_agents_limited__benchmark-limited-styles__middle-text {
  margin-top: 24px;
  margin-bottom: 24px;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_limited__benchmark-limited-styles__root {
    flex-direction: column;
    padding: 0 16px;
  }
  
  .ladder_agents_limited__benchmark-limited-styles__image-container {
    width: 95%;
    padding-right: unset;
  }
  
  .ladder_agents_limited__benchmark-limited-styles__text-root {
    width: 100%;
  }

}
.ladder_account_next_cards_your_beneficiaries__beneficiary-item-company-styles__\% {
  display: flex;
  flex-direction: column;
  margin: 20px 0;
}

.ladder_account_next_cards_your_beneficiaries__beneficiary-item-company-styles__\%>*:first-child {
  margin-bottom: 5.0px;
}
.ladder_ny_form_common__error-message-styles__root {
  margin-top: 4px;
  min-height: 16px;
}

.ladder_ny_form_common__error-message-styles__hidden {
  visibility: hidden;
}

.ladder_ny_form_common__error-message-styles__should-disappear {
  display: none;
}
.ladder_app_review__multiselect-bare-styles__pencil {
  height: 16px;
  width: 16px;
}

.ladder_app_review__multiselect-bare-styles__display-lines {
  display: flex;
  flex-direction: column;
}

.ladder_app_review__multiselect-bare-styles__hidden {
  visibility: hidden;
}

.ladder_app_review__multiselect-bare-styles__pencil-wrapper {
  padding-left: 20px;
}

.ladder_app_review__multiselect-bare-styles__root {
  display: flex;
  flex-direction: column;
  width: 85%;
}

.ladder_app_review__multiselect-bare-styles__link {
  display: flex;
  align-items: left;
}

.ladder_app_review__multiselect-bare-styles__answer-root {
  display: flex;
  align-items: left;
}

@media (max-width: 499px) {

  
  
  .ladder_app_review__multiselect-bare-styles__link {
    margin: 5.0px 0;
  }

}
.ladder_affiliates__title-styles__root {
  display: flex;
  position: relative;
  background: #EFEAE1;
  flex-direction: row;
  min-height: 914px;
  width: 100%;
  z-index: 1;
  overflow: hidden;
}

.ladder_affiliates__title-styles__container {
  display: flex;
  flex-direction: row;
  width: 80%;
  max-width: 1800px;
  margin: 92px auto;
}

.ladder_affiliates__title-styles__text-container {
  display: flex;
  flex-direction: column;
  max-width: 650px;
  z-index: 1;
}

.ladder_affiliates__title-styles__image {
  width: 40%;
  max-width: 700px;
  height: auto;
  position: absolute;
  bottom: 0;
  right: 5%;
  z-index: 0;
  align-self: center;
}

.ladder_affiliates__title-styles__text {
  margin: 30.0px 0 60px 0;
  width: 100%;
}

.ladder_affiliates__title-styles__button {
  display: block;
  width: 50%;
}

.ladder_affiliates__title-styles__links {
  display: flex;
  flex-direction: column;
}

.ladder_affiliates__title-styles__links .ladder_affiliates__title-styles__log-in {
  margin: 30.0px 0;
}

@media (max-width: 499px) {

  
  
  .ladder_affiliates__title-styles__container {
    flex-direction: column;
    margin: 36px auto 0;
  }
  
  .ladder_affiliates__title-styles__image-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .ladder_affiliates__title-styles__image {
    position: relative;
    width: 80%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_affiliates__title-styles__container {
    flex-direction: column;
    margin: 36px auto 0;
  }
  
  .ladder_affiliates__title-styles__image-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  
  .ladder_affiliates__title-styles__image {
    position: relative;
    width: 80%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_affiliates__title-styles__root {
    min-height: 660px;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .ladder_affiliates__title-styles__container {
    width: 90%;
  }
  
  .ladder_affiliates__title-styles__image-container {
    width: 164%;
    transform: translateX(-16%);
  }
  
  .ladder_affiliates__title-styles__text-container {
    max-width: 90%;
  }
  
  .ladder_affiliates__title-styles__button {
    width: 100%;
  }

}
.ladder_account_next_views_policy__RungsLadderUpCard-styles__root {
  margin-top: 16px;
}

.ladder_account_next_views_additional_actions__Root-styles__\% {
  display: flex;
  flex-direction: column;
}

.ladder_account_next_views_additional_actions__Root-styles__title {
  margin-left: 5.0px;
  margin-bottom: 30.0px;
}

.ladder_account_next_views_additional_actions__Root-styles__add-margins-to-children {

}

.ladder_account_next_views_additional_actions__Root-styles__add-margins-to-children>* {
  margin: 10.0px;
}
.ladder_views_decision_common__accept-buttons-fixed-cta-styles__root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.ladder_views_decision_common__accept-buttons-fixed-cta-styles__fixed-cta-background {
  max-width: 352px;
  padding: 22.5px 0 20px;
}

@media (max-width: 499px) {

  
  
  .ladder_views_decision_common__accept-buttons-fixed-cta-styles__fixed-cta-background {
    background-color: white;
    position: fixed;
    bottom: 0;
    padding: 0;
    max-width: unset;
    box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.05);
    width: 100%;
  }
  
  .ladder_views_decision_common__accept-buttons-fixed-cta-styles__button-wrapper-sticky {
    margin: auto;
    width: 86%;
    padding: 10.0px 0 10.0px;
  }

}

.ladder_views_decision_common__accept-buttons-fixed-cta-styles__full-width .ladder_views_decision_common__accept-buttons-fixed-cta-styles__fixed-cta-background {
  background-color: white;
  position: fixed;
  bottom: 0;
  padding: 0;
  max-width: unset;
  box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.05);
  width: 100%;
}

.ladder_views_decision_common__accept-buttons-fixed-cta-styles__full-width .ladder_views_decision_common__accept-buttons-fixed-cta-styles__button-wrapper-sticky {
  margin: auto;
  width: 86%;
  padding: 10.0px 0 10.0px;
}
.ladder_ux_homepage_returners_core__returners-panel-styles__returners-root {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #E7EEEF;
}

.ladder_ux_homepage_returners_core__returners-panel-styles__returners-container {
  width: 80%;
  display: flex;
  justify-content: space-between;
  margin: 64px 0;
}

.ladder_ux_homepage_returners_core__returners-panel-styles__returners-left-panel {
  width: 40%;
  margin-bottom: 48px;
}

.ladder_ux_homepage_returners_core__returners-panel-styles__returners-right-panel {
  width: 50%;
}

.ladder_ux_homepage_returners_core__returners-panel-styles__returners-panel-text {
  margin-top: 32px;
}

.ladder_ux_homepage_returners_core__returners-panel-styles__returners-panel-button {
  width: 100%;
  margin-top: 32px;
}

.ladder_ux_homepage_returners_core__returners-panel-styles__returners-panel-secondary-button {
  display: none;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_homepage_returners_core__returners-panel-styles__returners-container {
    display: block;
  }
  
  .ladder_ux_homepage_returners_core__returners-panel-styles__returners-left-panel {
    width: 100%;
    margin-bottom: 8.0px;
  }
  
  .ladder_ux_homepage_returners_core__returners-panel-styles__returners-right-panel {
    width: 100%;
  }
  
  .ladder_ux_homepage_returners_core__returners-panel-styles__returners-panel-text {
    margin-top: 16px;
  }
  
  .ladder_ux_homepage_returners_core__returners-panel-styles__returners-panel-button {
    display: none;
  }
  
  .ladder_ux_homepage_returners_core__returners-panel-styles__returners-panel-secondary-button {
    display: block;
    margin-top: 32px;
  }

}
.ladder_account_next_views_beneficiaries_shares__text-cell-styles__overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}
.ladder_agents_agent_assisted_app_views_decision__decline-page-styles__container {
  display: flex;
  gap: 100px;
  width: 100%;
  max-width: 1001px;
  margin: 0 auto;
  padding: 180px 0 80px 0;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_agent_assisted_app_views_decision__decline-page-styles__container {
    flex-direction: column;
    padding: 30px 0;
    width: 90%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_agent_assisted_app_views_decision__decline-page-styles__container {
    flex-direction: column;
    padding: 30px 0;
    width: 90%;
  }

}
.ladder_account_payment_history__rungs-payment-invoice-history-row-styles__payment-row {
  padding: 25.0px 50.0px;
  border-top: 1px solid #D0D0D0;
  width: 100%;
}

.ladder_account_payment_history__rungs-payment-invoice-history-row-styles__footer-row {
  padding-bottom: 15.0px;
}

.ladder_account_payment_history__rungs-payment-invoice-history-row-styles__header-row {
  padding: 15.0px 50.0px;
}

.ladder_account_payment_history__rungs-payment-invoice-history-row-styles__payment-items {
  display: flex;
}

.ladder_account_payment_history__rungs-payment-invoice-history-row-styles__payment-date {
  width: 18%;
  padding-right: 10.0px;
}

.ladder_account_payment_history__rungs-payment-invoice-history-row-styles__payment-status {
  width: 12%;
  min-width: 60px;
  padding: 0 10.0px;
}

.ladder_account_payment_history__rungs-payment-invoice-history-row-styles__payment-status-border {
  border-radius: 4px;
  padding: 3px 8px;
  width: fit-content;
  height: fit-content;
}

.ladder_account_payment_history__rungs-payment-invoice-history-row-styles__payment-status-border-success {
  border: 1px solid #749475;
}

.ladder_account_payment_history__rungs-payment-invoice-history-row-styles__payment-status-border-warning {
  border: 1px solid #C29A2B;
}

.ladder_account_payment_history__rungs-payment-invoice-history-row-styles__payment-status-border-error {
  border: 1px solid #CB3A3A;
}

.ladder_account_payment_history__rungs-payment-invoice-history-row-styles__payment-status-border-grey {
  border: 1px solid #5D5D5D;
}

.ladder_account_payment_history__rungs-payment-invoice-history-row-styles__payment-details {
  width: 55%;
  padding: 0 10.0px;
}

.ladder_account_payment_history__rungs-payment-invoice-history-row-styles__payment-amount {
  width: 15%;
  padding: 0 10.0px;
}

.ladder_account_payment_history__rungs-payment-invoice-history-row-styles__payment-retry-copy {
  width: 65%;
  padding-right: 30.0px;
}

.ladder_account_payment_history__rungs-payment-invoice-history-row-styles__payment-button-container {
  width: 35%;
  display: flex;
  justify-content: flex-end;
  padding-top: 15.0px;
}

.ladder_account_payment_history__rungs-payment-invoice-history-row-styles__payment-button {
  width: 300px;
}

.ladder_account_payment_history__rungs-payment-invoice-history-row-styles__payment-details-text {
  display: flex;
}

.ladder_account_payment_history__rungs-payment-invoice-history-row-styles__payment-details-icon {
  margin-right: 5.0px;
  font-weight: initial;
}

.ladder_account_payment_history__rungs-payment-invoice-history-row-styles__icon-container {
  flex: 0 0 auto;
}

.ladder_account_payment_history__rungs-payment-invoice-history-row-styles__icon-spacer {
  padding: 2.5px 0;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account_payment_history__rungs-payment-invoice-history-row-styles__payment-details-text {
    max-width: 300px;
    padding-left: 20px;
  }
  
  .ladder_account_payment_history__rungs-payment-invoice-history-row-styles__payment-row {
    padding: 10.0px 50.0px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_account_payment_history__rungs-payment-invoice-history-row-styles__payment-details-text {
    max-width: 160px;
  }
  
  .ladder_account_payment_history__rungs-payment-invoice-history-row-styles__payment-row {
    padding: 10.0px 15.0px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_account_payment_history__rungs-payment-invoice-history-row-styles__payment-button {
    width: 100%;
  }
  
  .ladder_account_payment_history__rungs-payment-invoice-history-row-styles__payment-items {
    flex-direction: column;
    align-items: space-between;
    border-bottom: 0;
  }
  
  .ladder_account_payment_history__rungs-payment-invoice-history-row-styles__payment-items .ladder_account_payment_history__rungs-payment-invoice-history-row-styles__payment-details-text {
    display: block;
    text-align: right;
  }
  
  .ladder_account_payment_history__rungs-payment-invoice-history-row-styles__payment-items .ladder_account_payment_history__rungs-payment-invoice-history-row-styles__payment-details-icon {
    margin: 0 5.0px -5.0px 0;
  }
  
  .ladder_account_payment_history__rungs-payment-invoice-history-row-styles__payment-items >* {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 10.0px 0;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account_payment_history__rungs-payment-invoice-history-row-styles__payment-button {
    width: 100%;
  }
  
  .ladder_account_payment_history__rungs-payment-invoice-history-row-styles__payment-items {
    flex-direction: column;
    align-items: space-between;
    border-bottom: 0;
  }
  
  .ladder_account_payment_history__rungs-payment-invoice-history-row-styles__payment-items .ladder_account_payment_history__rungs-payment-invoice-history-row-styles__payment-details-text {
    display: block;
    text-align: right;
  }
  
  .ladder_account_payment_history__rungs-payment-invoice-history-row-styles__payment-items .ladder_account_payment_history__rungs-payment-invoice-history-row-styles__payment-details-icon {
    margin: 0 5.0px -5.0px 0;
  }
  
  .ladder_account_payment_history__rungs-payment-invoice-history-row-styles__payment-items >* {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 10.0px 0;
  }

}
.ladder_footer__footer-title-styles__root {
  padding-top: 16px;
  margin-bottom: 20px;
}

@media (max-width: 499px) {

  
  
  .ladder_footer__footer-title-styles__title {
    font-size: 18px;
  }

}
.ladder_api_v3_sdk_demo__app-demo-styles__app-demo {
  width: 100%;
  height: 100%;
  display: flex;
}

.ladder_api_v3_sdk_demo__app-demo-styles__app-demo-init {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.ladder_ux_progress_bar__progress-bar-styles__root {
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background-color: #D9D9D9;
  transition: background-color 200ms ease-in;
  display: flex;
  overflow: hidden;
  align-items: stretch;
}

.ladder_ux_progress_bar__progress-bar-styles__height-s {
  height: 5px;
}

.ladder_ux_progress_bar__progress-bar-styles__done {
  transition: width  400ms  ease-in-out;
}
.ladder_account_shared_components__health-provider-entry-styles__root {
  display: flex;
  align-items: center;
}

.ladder_account_shared_components__health-provider-entry-styles__copy {
  padding: 0 20px;
}

.ladder_account_shared_components__health-provider-entry-styles__left-aligned {
  align-self: flex-start;
}
.ladder_ux_three_steps_section__three-steps-section-styles__root {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
}

.ladder_ux_three_steps_section__three-steps-section-styles__root>div:not(:last-child):after {
  content: '';
  position: absolute;
  height: 80%;
  right: -1px;
  top: 20%;
  border-right: 1px solid #D0D0D0;
}

.ladder_ux_three_steps_section__three-steps-section-styles__less-padding>div {
  padding: 16px;
}

@media (max-width: 499px) {

  
  
  
  
  .ladder_ux_three_steps_section__three-steps-section-styles__root>div:not(:last-child):after {
    content: '';
    border-right: none;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  
  
  .ladder_ux_three_steps_section__three-steps-section-styles__root>div:not(:last-child):after {
    content: '';
    border-right: none;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_three_steps_section__three-steps-section-styles__root {
    flex-flow: column;
  }

}
.ladder_account_shared_components__cc-expiration-alert-styles__root {
  text-align: left;
  padding: 10.0px 15.0px;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  column-gap: 10.0px;
}

.ladder_account_shared_components__cc-expiration-alert-styles__icon {
  flex-grow: 0;
  flex-shrink: 0;
}

.ladder_account_shared_components__cc-expiration-alert-styles__expired {
  border: 1px solid #CB3A3A;
}

.ladder_account_shared_components__cc-expiration-alert-styles__expiring {
  background-color: #F7F2EF;
}

.ladder_account_shared_components__cc-expiration-alert-styles__content {
  display: flex;
  flex-direction: column;
  gap: 5.0px;
}
.ladder_agents_dashboard_pre_screener__results-container-styles__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
  gap: 16px;
  box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.1);
}

@media (min-width: 1025px) {

  
  
  .ladder_agents_dashboard_pre_screener__results-container-styles__container {
    padding: 24px 32px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard_pre_screener__results-container-styles__container {
    padding: 16px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_dashboard_pre_screener__results-container-styles__container {
    padding: 16px;
  }

}
.ladder_calculator_native_calculator_share__results-card-title-component-styles__toggle-icon {
  width: 20.0px;
  height: 20.0px;
  padding: 0 0 0 5.0px;
}
.ladder_financial_advisors_signup__root-styles__max-width-80 {
  max-width: 80%;
  text-align: center;
}

.ladder_financial_advisors_signup__root-styles__max-width-50 {
  max-width: 50%;
  text-align: center;
}

@media (max-width: 499px) {

  
  
  .ladder_financial_advisors_signup__root-styles__max-width-80 {
    max-width: 90%;
  }
  
  .ladder_financial_advisors_signup__root-styles__max-width-50 {
    max-width: 90%;
  }

}
.ladder_widget_ui_v2__input-fields-container-styles__root {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.tile-b .ladder_widget_ui_v2__input-fields-container-styles__root {
  flex-grow: 167;
}

.portrait .ladder_widget_ui_v2__input-fields-container-styles__root {
  flex-grow: 374;
  flex-direction: column;
  height: 100%;
}

.portrait .ladder_widget_ui_v2__input-fields-container-styles__root>* {
  flex-grow: 1;
}

.panel .ladder_widget_ui_v2__input-fields-container-styles__root {
  flex-grow: 374;
  flex-direction: column;
  height: 100%;
}

.panel .ladder_widget_ui_v2__input-fields-container-styles__root>* {
  flex-grow: 1;
}

.landscape .ladder_widget_ui_v2__input-fields-container-styles__root {
  flex-grow: 167;
}
.ladder_form_upper_funnel_routing__exchange-carrier-v2-styles__wrapper {
  min-width: 30%;
  border-width: 1px;
  justify-content: center;
  height: 80px;
  align-items: stretch;
  flex-direction: column;
  border-style: solid;
  background-color: #FFFFFF;
  border-radius: 5px;
  display: flex;
}

.ladder_form_upper_funnel_routing__exchange-carrier-v2-styles__carrier {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 20px;
}

.ladder_form_upper_funnel_routing__exchange-carrier-v2-styles__impr-beacon {
  display: none;
}

.ladder_form_upper_funnel_routing__exchange-carrier-v2-styles__img {
  max-height: 60px;
  max-width: 124px;
}

.ladder_form_upper_funnel_routing__exchange-carrier-v2-styles__view {
  min-width: 94px;
  justify-content: center;
  margin-right: 10.0px;
  align-items: center;
  padding: 2.5px 15.0px;
  background-color: #000000;
  border-radius: 7px;
  display: flex;
  color: #FFFFFF;
}

.ladder_form_upper_funnel_routing__exchange-carrier-v2-styles__view:hover {
  background-color: #5D5D5D;
}

.ladder_form_upper_funnel_routing__exchange-carrier-v2-styles__view svg {
  margin-left: 5.0px;
}

.ladder_form_upper_funnel_routing__exchange-carrier-v2-styles__view path {
  fill: #FFFFFF;
}

@media (min-width: 1025px) {

  
  
  .ladder_form_upper_funnel_routing__exchange-carrier-v2-styles__wrapper {
    flex: 1;
  }
  
  .ladder_form_upper_funnel_routing__exchange-carrier-v2-styles__wrapper:not(:last-child) {
    margin-right: 3%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  
  
  .ladder_form_upper_funnel_routing__exchange-carrier-v2-styles__wrapper:not(:last-child) {
    margin-right: 20px;
  }
  
  .ladder_form_upper_funnel_routing__exchange-carrier-v2-styles__carrier {
    padding: 0 30.0px;
  }
  
  .ladder_form_upper_funnel_routing__exchange-carrier-v2-styles__view {
    margin-left: 60px;
  }

}
.ladder_financial_advisors_payment_select__PaidAdvisorForm-styles__root {
  width: 100%;
}

.ladder_financial_advisors_payment_select__PaidAdvisorForm-styles__signup-form {
  width: 100%;
}
.ladder_account_next_views_profile__Root-styles__title {
  margin-left: 5.0px;
  margin-bottom: 30.0px;
}
.ladder_trustage_quote__coverage-non-modal-details-styles__container {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}

.ladder_trustage_quote__coverage-non-modal-details-styles__why-trustage-link {
  flex-basis: 45%;
  display: flex;
  justify-content: center;
}

.ladder_trustage_quote__coverage-non-modal-details-styles__trustage-cta {
  border-radius: 13px;
  font-size: 17px;
  letter-spacing: 1px;
  padding: 0;
  flex-basis: 50%;
}

.ladder_trustage_quote__coverage-non-modal-details-styles__checkmark {
  min-width: 12px;
  width: 33px;
  height: 33px;
  flex-shrink: 0;
}

.ladder_trustage_quote__coverage-non-modal-details-styles__why-trustage-content {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
}

.ladder_trustage_quote__coverage-non-modal-details-styles__why-trustage-row-text {
  line-height: 40px;
}

.ladder_trustage_quote__coverage-non-modal-details-styles__why-trustage-nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ladder_trustage_quote__coverage-non-modal-details-styles__why-trustage-nav .ladder_trustage_quote__coverage-non-modal-details-styles__close {
  border-radius: 15px;
}

.ladder_trustage_quote__coverage-non-modal-details-styles__coverage {
  border: 1px solid;
  background-color: #FFFFFF;
  display: flex;
  justify-content: space-between;
}

.ladder_trustage_quote__coverage-non-modal-details-styles__slider {
  flex-basis: 50%;
}

.ladder_trustage_quote__coverage-non-modal-details-styles__pricing {
  flex-basis: 50%;
  display: flex;
  justify-content: space-between;
}

.ladder_trustage_quote__coverage-non-modal-details-styles__footnotes {
  margin: 0 30px 30px;
  display: flex;
  justify-content: center;
}

@media (min-width: 1025px) {

  
  
  .ladder_trustage_quote__coverage-non-modal-details-styles__content {
    padding: 0px 30.0px;
  }
  
  .ladder_trustage_quote__coverage-non-modal-details-styles__coverage {
    border-radius: 15.0px;
    height: 120px;
  }
  
  .ladder_trustage_quote__coverage-non-modal-details-styles__slider {
    padding: 45px 25.0px 10.0px;
  }
  
  .ladder_trustage_quote__coverage-non-modal-details-styles__pricing {
    border-left: 1px solid;
    padding: 30px 35px;
  }
  
  .ladder_trustage_quote__coverage-non-modal-details-styles__footer {
    margin-top: 22.5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .ladder_trustage_quote__coverage-non-modal-details-styles__why-trustage-nav {
    padding: 20px 60px;
  }
  
  .ladder_trustage_quote__coverage-non-modal-details-styles__why-trustage-content {
    padding: 10px 40px;
  }
  
  .ladder_trustage_quote__coverage-non-modal-details-styles__why-trustage-row {
    margin: 17px 0;
    flex-basis: 33%;
    display: flex;
    flex-direction: row;
  }
  
  .ladder_trustage_quote__coverage-non-modal-details-styles__checkmark {
    margin-top: 5px;
  }
  
  .ladder_trustage_quote__coverage-non-modal-details-styles__why-trustage-row-text {
    padding: 0 50px;
    line-height: 40px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_trustage_quote__coverage-non-modal-details-styles__content {
    padding: 0px 30.0px;
  }
  
  .ladder_trustage_quote__coverage-non-modal-details-styles__coverage {
    border-radius: 15.0px;
    height: 120px;
  }
  
  .ladder_trustage_quote__coverage-non-modal-details-styles__slider {
    padding: 45px 25.0px 10.0px;
  }
  
  .ladder_trustage_quote__coverage-non-modal-details-styles__pricing {
    border-left: 1px solid;
    padding: 30px 35px;
  }
  
  .ladder_trustage_quote__coverage-non-modal-details-styles__footer {
    margin-top: 22.5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .ladder_trustage_quote__coverage-non-modal-details-styles__why-trustage-nav {
    padding: 20px 60px;
  }
  
  .ladder_trustage_quote__coverage-non-modal-details-styles__why-trustage-content {
    padding: 10px 40px;
  }
  
  .ladder_trustage_quote__coverage-non-modal-details-styles__why-trustage-row {
    margin: 17px 0;
    flex-basis: 33%;
    display: flex;
    flex-direction: row;
  }
  
  .ladder_trustage_quote__coverage-non-modal-details-styles__checkmark {
    margin-top: 5px;
  }
  
  .ladder_trustage_quote__coverage-non-modal-details-styles__why-trustage-row-text {
    padding: 0 50px;
    line-height: 40px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_trustage_quote__coverage-non-modal-details-styles__content {
    margin-top: 10px;
    flex-direction: column;
  }
  
  .ladder_trustage_quote__coverage-non-modal-details-styles__why-trustage-link {
    margin-top: 30.0px;
  }
  
  .ladder_trustage_quote__coverage-non-modal-details-styles__why-trustage-nav {
    flex-direction: column-reverse;
    padding: 10.0px 60px;
  }
  
  .ladder_trustage_quote__coverage-non-modal-details-styles__why-trustage-nav .ladder_trustage_quote__coverage-non-modal-details-styles__close {
    margin-bottom: 10.0px;
  }
  
  .ladder_trustage_quote__coverage-non-modal-details-styles__why-trustage-content {
    margin: 40px 80px 0 30px;
  }
  
  .ladder_trustage_quote__coverage-non-modal-details-styles__footer {
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: #FFFFFF;
    box-shadow: 0 -2px 4px 2px rgba(0, 0, 0, 0.15);
    z-index: 5;
    width: 100%;
    padding: 15.0px 30.0px;
  }
  
  .ladder_trustage_quote__coverage-non-modal-details-styles__coverage {
    flex-direction: column;
    border-radius: 15.0px;
  }
  
  .ladder_trustage_quote__coverage-non-modal-details-styles__slider {
    padding: 30.0px;
  }
  
  .ladder_trustage_quote__coverage-non-modal-details-styles__pricing {
    border-top: 1px solid;
    padding: 20px 37px;
  }
  
  .ladder_trustage_quote__coverage-non-modal-details-styles__pricing .ladder_trustage_quote__coverage-non-modal-details-styles__pricing-border {
    border-right: 1px dashed;
  }
  
  .ladder_trustage_quote__coverage-non-modal-details-styles__why-trustage-row {
    display: flex;
    margin-top: 5px;
  }
  
  .ladder_trustage_quote__coverage-non-modal-details-styles__checkmark {
    margin: 20px 20px 20px 0;
  }
  
  .ladder_trustage_quote__coverage-non-modal-details-styles__why-trustage-row-text {
    margin: 13px 0;
  }

}
.ladder_careers__ui-heads-divider-styles__root {
  background: linear-gradient(to bottom, #F4F4F4 0%,#F4F4F4 50%,#FFFFFF 50%,#FFFFFF 100%);
  box-sizing: border-box;
  padding: 0 30.0px;
  text-align: center;
  width: 100%;
}

.ladder_careers__ui-heads-divider-styles__heads {
  max-width: 100%;
  width: 717px;
  height: auto;
}
div .ladder_guide_common__nav-bar-styles__selected-option {
  background-color: #D1A98F;
  font-weight: bold;
}

.ladder_guide_common__nav-bar-styles__menu-options-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

@media (min-width: 1025px) {

  
  
  .ladder_guide_common__nav-bar-styles__root {
    position: absolute;
    left: 8px;
    margin-top: 90px;
    width: 360px;
    z-index: 5;
    height: 100%;
  }
  
  .ladder_guide_common__nav-bar-styles__sticky-container {
    position: sticky;
    top: 120px;
  }
  
  .ladder_guide_common__nav-bar-styles__option {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 72px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    width: 89%;
  }
  
  .ladder_guide_common__nav-bar-styles__right-chev-icon {
    cursor: pointer;
    width: 8px;
    align-self: center;
  }
  
  .ladder_guide_common__nav-bar-styles__down-chev-icon {
    cursor: pointer;
    width: 13px;
    align-self: center;
  }
  
  .ladder_guide_common__nav-bar-styles__indented-option {
    padding-left: 102px;
    width: 100%;
  }
  
  .ladder_guide_common__nav-bar-styles__beacon-link-container {
    width: 89%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_guide_common__nav-bar-styles__right-chev-icon {
    width: 8px;
    align-self: center;
    cursor: pointer;
  }
  
  .ladder_guide_common__nav-bar-styles__down-chev-icon {
    width: 13px;
    cursor: pointer;
    align-self: center;
  }
  
  .ladder_guide_common__nav-bar-styles__mobile-down-arrow {
    margin-bottom: 3px;
  }
  
  .ladder_guide_common__nav-bar-styles__root {
    position: absolute;
    z-index: 5;
    width: 100%;
    height: 50px;
    border-top: 1px solid #CFCFCF;
  }
  
  .ladder_guide_common__nav-bar-styles__sticky-container {
    position: sticky;
    top: 60px;
    height: 50px;
    background-color: #EFEAE1;
  }
  
  .ladder_guide_common__nav-bar-styles__label-container {
    margin-left: 30.0px;
    margin-right: 30.0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 100%;
    cursor: pointer;
  }
  
  .ladder_guide_common__nav-bar-styles__mini-title {
    z-index: 6;
    position: relative;
    width: 60%;
  }
  
  .ladder_guide_common__nav-bar-styles__title-container {
    width: 78%;
    padding-bottom: 20px;
    justify-content: space-between;
    position: relative;
    min-height: 90px;
    margin-left: 20px;
    background-color: #EFEAE1;
    display: flex;
    padding-top: 20px;
  }
  
  .ladder_guide_common__nav-bar-styles__title-image {
    max-width: 65px;
    max-height: 60px;
    z-index: 2;
  }
  
  .ladder_guide_common__nav-bar-styles__option {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 72px;
    padding-right: 30.0px;
    cursor: pointer;
    background-color: #EFEAE1;
    display: flex;
    justify-content: space-between;
  }
  
  .ladder_guide_common__nav-bar-styles__indented-option {
    padding-left: 102px;
  }
  
  .ladder_guide_common__nav-bar-styles__mobile-menu-spacer {
    height: 16px;
    width: 100%;
    background-color: #EFEAE1;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_guide_common__nav-bar-styles__right-chev-icon {
    width: 8px;
    align-self: center;
    cursor: pointer;
  }
  
  .ladder_guide_common__nav-bar-styles__down-chev-icon {
    width: 13px;
    cursor: pointer;
    align-self: center;
  }
  
  .ladder_guide_common__nav-bar-styles__mobile-down-arrow {
    margin-bottom: 3px;
  }
  
  .ladder_guide_common__nav-bar-styles__root {
    position: absolute;
    z-index: 5;
    width: 100%;
    height: 50px;
    border-top: 1px solid #CFCFCF;
  }
  
  .ladder_guide_common__nav-bar-styles__sticky-container {
    position: sticky;
    top: 60px;
    height: 50px;
    background-color: #EFEAE1;
  }
  
  .ladder_guide_common__nav-bar-styles__label-container {
    margin-left: 30.0px;
    margin-right: 30.0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 100%;
    cursor: pointer;
  }
  
  .ladder_guide_common__nav-bar-styles__mini-title {
    z-index: 6;
    position: relative;
    width: 60%;
  }
  
  .ladder_guide_common__nav-bar-styles__title-container {
    width: 78%;
    padding-bottom: 20px;
    justify-content: space-between;
    position: relative;
    min-height: 90px;
    margin-left: 20px;
    background-color: #EFEAE1;
    display: flex;
    padding-top: 20px;
  }
  
  .ladder_guide_common__nav-bar-styles__title-image {
    max-width: 65px;
    max-height: 60px;
    z-index: 2;
  }
  
  .ladder_guide_common__nav-bar-styles__option {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 72px;
    padding-right: 30.0px;
    cursor: pointer;
    background-color: #EFEAE1;
    display: flex;
    justify-content: space-between;
  }
  
  .ladder_guide_common__nav-bar-styles__indented-option {
    padding-left: 102px;
  }
  
  .ladder_guide_common__nav-bar-styles__mobile-menu-spacer {
    height: 16px;
    width: 100%;
    background-color: #EFEAE1;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_guide_common__nav-bar-styles__selected-option {
    padding-right: 30px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_guide_common__nav-bar-styles__mobile-title-outer-container {
    background-color: #EFEAE1;
  }
  
  .ladder_guide_common__nav-bar-styles__label-container {
    margin-left: 20px;
    margin-right: 20px;
  }
  
  .ladder_guide_common__nav-bar-styles__mobile-down-arrow {
    margin-bottom: 2px;
  }
  
  .ladder_guide_common__nav-bar-styles__right-chev-icon {
    width: 8px;
  }
  
  .ladder_guide_common__nav-bar-styles__down-chev-icon {
    width: 13px;
  }
  
  .ladder_guide_common__nav-bar-styles__option {
    padding-right: 20px;
  }
  
  .ladder_guide_common__nav-bar-styles__selected-option {
    padding-right: 14px;
  }

}
.ladder_ux_logos__sequoia-logo-styles__icon {
  height: 32px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_logos__sequoia-logo-styles__icon {
    height: 24.0px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_logos__sequoia-logo-styles__icon {
    height: 24.0px;
  }

}
.ladder_megaphone_refer__referral-url-styles__root {
  width: 100%;
  max-width: 500px;
}

.ladder_megaphone_refer__referral-url-styles__horizontal-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.ladder_megaphone_refer__referral-url-styles__code-wrapper {
  width: 50%;
  justify-content: flex-start;
  border: 1px solid #D0D0D0;
  align-items: center;
  padding: 0 10.0px;
  flex-grow: 1;
  cursor: pointer;
  background-color: #FFFFFF;
  box-sizing: border-box;
  display: flex;
}

.ladder_megaphone_refer__referral-url-styles__code-height {
  height: 48px;
}

.ladder_megaphone_refer__referral-url-styles__code-height.ladder_megaphone_refer__referral-url-styles__textarea {
  height: 96px;
}

.ladder_megaphone_refer__referral-url-styles__code {
  white-space: nowrap;
  overflow: auto;
  color: #707070;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 26px;
  font-weight: 300;
}

.ladder_megaphone_refer__referral-url-styles__code.ladder_megaphone_refer__referral-url-styles__textarea {
  white-space: normal;
  text-align: left;
}

.ladder_megaphone_refer__referral-url-styles__copied-sucess {
  width: 175px;
  top: 50%;
  justify-content: center;
  height: 175px;
  align-items: center;
  flex-direction: column;
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  z-index: 100;
  border-radius: 15px;
  display: flex;
  background: #CFDCDE;
  left: 50%;
}

.ladder_megaphone_refer__referral-url-styles__copied-sucess >svg {
  zoom: 2;
  padding-bottom: 10.0px;
}

.ladder_megaphone_refer__referral-url-styles__copied-sucess.ladder_megaphone_refer__referral-url-styles__hidden {
  opacity: 0;
  transition-duration: 1500ms;
}

@media (max-width: 499px) {

  
  
  .ladder_megaphone_refer__referral-url-styles__button {
    max-width: 96px;
  }
  
  
  
  .ladder_megaphone_refer__referral-url-styles__code-height.ladder_megaphone_refer__referral-url-styles__textarea {
    height: 144px;
  }

}
.ladder_claims__confirmation-page-styles__bottom-text {
  margin-top: 50.0px;
}
.ladder_ny_form_address__root-styles__root {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  margin-right: -7px;
}

.ladder_ny_form_address__root-styles__city {
  margin-right: 7px;
}

.ladder_ny_form_address__root-styles__state {
  margin-right: 7px;
}

.ladder_ny_form_address__root-styles__zip {
  margin-right: 7px;
}

.ladder_ny_form_address__root-styles__city {
  width: 8em;
  flex-grow: 1;
}

.ladder_ny_form_address__root-styles__state {
  width: 13em;
  flex-grow: 1;
}

.ladder_ny_form_address__root-styles__zip {
  width: 5em;
  flex-grow: 1;
}

.ladder_ny_form_address__root-styles__rungs .ladder_ny_form_address__root-styles__city {
  width: 100%;
}
.ladder_account_shared_components__faqs-section\*-styles__\% {
  margin-top: 60px;
  margin-bottom: 60px;
}

.ladder_account_shared_components__faqs-section\*-styles__action-link {
  padding-top: 20px;
}

.ladder_account_shared_components__faqs-section\*-styles__title {
  border-bottom: 1px solid #D0D0D0;
  padding-bottom: 20px;
}
.ladder_onboarding_spouse_connect_2__page-layout-styles__content-panel {
  color: #F7F2EF;
  width: 100%;
  height: 100%;
  background-color: #F7F2EF;
}

.ladder_onboarding_spouse_connect_2__page-layout-styles__carousel {
  height: 100%;
  width: 100%;
  background-color: #F7F2EF;
}

.ladder_onboarding_spouse_connect_2__page-layout-styles__carousel .ladder_onboarding_spouse_connect_2__page-layout-styles__control-dots-wrapper {
  margin-top: 30px;
}

.ladder_onboarding_spouse_connect_2__page-layout-styles__row {
  width: 100%;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  flex-direction: row;
  min-height: 150px;
  padding-right: 16px;
  display: flex;
  padding-left: 16px;
}

.ladder_onboarding_spouse_connect_2__page-layout-styles__small {
  display: flex;
  width: 20%;
}

.ladder_onboarding_spouse_connect_2__page-layout-styles__col {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 16px;
}

.ladder_onboarding_spouse_connect_2__page-layout-styles__big-number {
  display: flex;
  width: 30%;
}

.ladder_onboarding_spouse_connect_2__page-layout-styles__small-copy {
  display: flex;
  width: 80%;
}

.ladder_onboarding_spouse_connect_2__page-layout-styles__flex {
  display: flex;
}
.ladder_trustage_quote__quote-content-v2-styles__info>*:first-child {
  margin-bottom: 20px;
}

@media (max-width: 499px) {

  
  
  .ladder_trustage_quote__quote-content-v2-styles__info {
    margin-bottom: 60px;
  }

}
.ladder_ux_panels__homepage-info-boxes-styles__root {
  max-width: 540px;
  margin-top: 80px;
}

.ladder_ux_panels__homepage-info-boxes-styles__row {
  display: flex;
  width: 100%;
}

.ladder_ux_panels__homepage-info-boxes-styles__info-box {
  margin-top: 4.0px;
  margin-bottom: 4.0px;
  padding-top: 20.0px;
  padding-bottom: 20.0px;
  width: 100%;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  text-align: center;
}

.ladder_ux_panels__homepage-info-boxes-styles__second-row-left {
  margin-right: 4.0px;
}

.ladder_ux_panels__homepage-info-boxes-styles__second-row-right {
  margin-left: 4.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_panels__homepage-info-boxes-styles__root {
    margin-top: 24.0px;
  }
  
  .ladder_ux_panels__homepage-info-boxes-styles__info-box {
    padding-top: 8.0px;
    padding-bottom: 8.0px;
  }

}
.ladder_ny_form_assessment_factor_search__afs-field-styles__option {
  display: flex;
  padding: 10.0px 0;
}

.ladder_ny_form_assessment_factor_search__afs-field-styles__checkbox-container {
  padding-right: 10.0px;
}

.ladder_ny_form_assessment_factor_search__afs-field-styles__info-expander-container {
  padding: 5.0px 0 0 10.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_ny_form_assessment_factor_search__afs-field-styles__info-expander-container {
    padding-top: 0;
  }

}

.ladder_ny_form_assessment_factor_search__afs-field-styles__option.ladder_ny_form_assessment_factor_search__afs-field-styles__rungs {
  padding-bottom: 20px;
}
.ladder_ny_form_review__submit-wrapper-styles__root {
  bottom: 0;
  width: 100%;
  z-index: 2;
  background: white;
}

.ladder_ny_form_review__submit-wrapper-styles__with-border {
  border-top: 1px solid #D0D0D0;
}

.ladder_ny_form_review__submit-wrapper-styles__without-border {
  border-top: 0;
}

.ladder_ny_form_review__submit-wrapper-styles__sticky-overlay {
  margin: 15.0px 20px;
}

.ladder_ny_form_review__submit-wrapper-styles__nonstick {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
}
.ladder_text__small-caps-styles__root {
  font-size: 75%;
  letter-spacing: 0.12em;
  font-weight: 400;
  text-transform: uppercase;
}
.ladder_admin_widget__all-feature-flags-styles__root {
  display: flex;
  background: #FFFFFF;
}

.ladder_admin_widget__all-feature-flags-styles__main-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 30.0px;
}

.ladder_admin_widget__all-feature-flags-styles__transact-container {
  flex-basis: 250px;
}

.ladder_admin_widget__all-feature-flags-styles__transact-container .ladder_admin_widget__all-feature-flags-styles__transact-button {
  position: sticky;
  top: 100px;
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.ladder_admin_widget__all-feature-flags-styles__flags-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.ladder_admin_widget__all-feature-flags-styles__flag-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid black;
  padding: 10.0px;
}

.ladder_admin_widget__all-feature-flags-styles__select-container {
  width: 200px;
}

@media (max-width: 499px) {

  
  
  .ladder_admin_widget__all-feature-flags-styles__root {
    flex-direction: column;
  }

}
.ladder_components__link-styles__pointer {
  cursor: pointer;
}
.ladder_apply_ny_current_page__new-york-form-id-styles__root {
  width: 90%;
  margin: auto;
}
.ladder_onboarding_referrals__panel-2-styles__megaphone {
  margin-top: 30.0px;
  position: relative;
}

.ladder_onboarding_referrals__panel-2-styles__continue-to-account-link {
  margin-top: 30.0px;
}

.ladder_onboarding_referrals__panel-2-styles__copy-link-container {
  padding: 15.0px;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  border: 2px solid #D9D9D9;
}

.ladder_onboarding_referrals__panel-2-styles__copy-link {
  margin-left: 30.0px;
}

.ladder_onboarding_referrals__panel-2-styles__referral-url {
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}

.ladder_onboarding_referrals__panel-2-styles__social-sharing-row {
  display: flex;
  margin-left: 15.0px;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  min-width: 150px;
  max-width: 200px;
}

.ladder_onboarding_referrals__panel-2-styles__terms {
  margin-top: 20px;
}

@media (min-width: 1025px) {

  
  
  .ladder_onboarding_referrals__panel-2-styles__copy-link-container {
    max-width: 62%;
  }
  
  .ladder_onboarding_referrals__panel-2-styles__link-and-social-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_onboarding_referrals__panel-2-styles__copy-link-container {
    max-width: 62%;
  }
  
  .ladder_onboarding_referrals__panel-2-styles__link-and-social-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_onboarding_referrals__panel-2-styles__copy-link-container {
    width: 100%;
  }
  
  .ladder_onboarding_referrals__panel-2-styles__continue-to-account-link {
    display: flex;
    justify-content: center;
  }
  
  .ladder_onboarding_referrals__panel-2-styles__social-sharing-row {
    margin-top: 30.0px;
    margin-left: auto;
    margin-right: auto;
  }

}
.ladder_guide_life_insurance_cost__factors-panel-styles__container {
  width: 100%;
  margin-top: 60px;
}

.ladder_guide_life_insurance_cost__factors-panel-styles__color-container {
  background-color: #E7EEEF;
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;
}

@media (min-width: 1025px) {

  
  
  .ladder_guide_life_insurance_cost__factors-panel-styles__color-container {
    padding-left: 70px;
  }

}
.ladder_guide_what_is_life_insurance__Root-styles__image-outer-container {
  margin: auto;
  max-width: 700px;
  width: 90%;
  padding-top: 30.0px;
}

.ladder_guide_what_is_life_insurance__Root-styles__separating-line-container {
  width: 100%;
  padding-right: 60px;
}

.ladder_guide_what_is_life_insurance__Root-styles__separating-line-inner-container {
  max-width: 700px;
  margin: auto;
  width: 90%;
  margin-top: 60px;
}

.ladder_guide_what_is_life_insurance__Root-styles__separating-line {
  border-bottom: 1.5px solid black;
  width: 50px;
}

@media (min-width: 1025px) {

  
  
  .ladder_guide_what_is_life_insurance__Root-styles__image-outer-container {
    margin-bottom: 60px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_guide_what_is_life_insurance__Root-styles__image-outer-container {
    margin-bottom: 60px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_guide_what_is_life_insurance__Root-styles__mobile-image {
    width: 100%;
  }
  
  .ladder_guide_what_is_life_insurance__Root-styles__image-outer-container {
    margin-bottom: 60px;
  }

}
.ladder_apply_fallback_carousel__checklist-carousel-styles__wrapper {
  opacity: 1;
  transition: opacity 200ms ease-in 1400ms;
}

.ladder_apply_fallback_carousel__checklist-carousel-styles__finished {
  opacity: 0;
}

.ladder_apply_fallback_carousel__checklist-carousel-styles__content {
  width: 100%;
}

.ladder_apply_fallback_carousel__checklist-carousel-styles__checklist-progress {
  border: 2px solid;
  border-radius: 10px;
  padding: 30px 50px;
}

.ladder_apply_fallback_carousel__checklist-carousel-styles__extra-info {
  margin-top: 30px;
  display: flex;
  border-radius: 10px;
}

.ladder_apply_fallback_carousel__checklist-carousel-styles__extra-info .ladder_apply_fallback_carousel__checklist-carousel-styles__icon {
  flex-shrink: 0;
}

.ladder_apply_fallback_carousel__checklist-carousel-styles__life-insurance-reason-row {
  display: flex;
}

.ladder_apply_fallback_carousel__checklist-carousel-styles__life-insurance-reason-text {
  line-height: 30px;
}

.ladder_apply_fallback_carousel__checklist-carousel-styles__heading-container {
  position: relative;
}

.ladder_apply_fallback_carousel__checklist-carousel-styles__finding {
  opacity: 0;
  transition: opacity 200ms ease-in;
}

.ladder_apply_fallback_carousel__checklist-carousel-styles__done {
  opacity: 0;
  transition: opacity 200ms ease-in;
}

.ladder_apply_fallback_carousel__checklist-carousel-styles__active-finding>.ladder_apply_fallback_carousel__checklist-carousel-styles__finding {
  opacity: 1;
}

.ladder_apply_fallback_carousel__checklist-carousel-styles__active-done>.ladder_apply_fallback_carousel__checklist-carousel-styles__done {
  opacity: 1;
}

@media (min-width: 1025px) {

  
  
  .ladder_apply_fallback_carousel__checklist-carousel-styles__content {
    width: 612px;
  }
  
  .ladder_apply_fallback_carousel__checklist-carousel-styles__life-insurance-reason-row {
    margin-top: 30px;
  }
  
  .ladder_apply_fallback_carousel__checklist-carousel-styles__life-insurance-reason-text {
    padding-left: 25px;
  }
  
  .ladder_apply_fallback_carousel__checklist-carousel-styles__extra-info {
    padding: 30px 50px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_apply_fallback_carousel__checklist-carousel-styles__content {
    width: 612px;
  }
  
  .ladder_apply_fallback_carousel__checklist-carousel-styles__life-insurance-reason-row {
    margin-top: 30px;
  }
  
  .ladder_apply_fallback_carousel__checklist-carousel-styles__life-insurance-reason-text {
    padding-left: 25px;
  }
  
  .ladder_apply_fallback_carousel__checklist-carousel-styles__extra-info {
    padding: 30px 50px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_apply_fallback_carousel__checklist-carousel-styles__wrapper {
    padding-top: 20px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_apply_fallback_carousel__checklist-carousel-styles__wrapper {
    padding: 32px 16px 0;
    max-width: 100%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_apply_fallback_carousel__checklist-carousel-styles__content {
    padding-top: 20px;
  }
  
  .ladder_apply_fallback_carousel__checklist-carousel-styles__checklist-progress {
    padding: 30px 50px 0;
  }
  
  .ladder_apply_fallback_carousel__checklist-carousel-styles__life-insurance-reason-row {
    align-items: center;
    margin-top: 15px;
  }
  
  .ladder_apply_fallback_carousel__checklist-carousel-styles__life-insurance-reason-text {
    padding: 5px 15px 0 25px;
  }
  
  .ladder_apply_fallback_carousel__checklist-carousel-styles__extra-info {
    padding: 30px 35px;
  }

}
.ladder_affiliates__embedded-widget-styles__mobile-widget {
  display: none;
}

@media (max-width: 499px) {

  
  
  .ladder_affiliates__embedded-widget-styles__mobile-widget {
    display: block;
  }
  
  .ladder_affiliates__embedded-widget-styles__non-mobile-widget {
    display: none;
  }

}
.ladder_ux_checkbox__checkbox-styles__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 32px;
  height: 32px;
}

.ladder_ux_checkbox__checkbox-styles__wrapper-s {
  width: 20.0px;
  height: 20.0px;
}

.ladder_ux_checkbox__checkbox-styles__wrapper-inline {
  display: inline-flex;
  padding: 3px 3px 0 0;
}

.ladder_ux_checkbox__checkbox-styles__checkbox {
  appearance: none;
  width: 100%;
  height: 100%;
  margin: 0px;
  -ms-appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: 1px solid #707070;
  position: absolute;
  cursor: pointer;
  box-sizing: border-box;
}

.ladder_ux_checkbox__checkbox-styles__checkmark-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ladder_ux_checkbox__checkbox-styles__border-color-light-gray {
  border: 1px solid #D0D0D0;
}

.ladder_ux_checkbox__checkbox-styles__checkbox:active {
  background-color: #D0D0D0;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_checkbox__checkbox-styles__wrapper-inline {
    padding: 1.5px 1.5px 0 0;
  }
  
  .ladder_ux_checkbox__checkbox-styles__wrapper {
    width: 24.0px;
    height: 24.0px;
  }
  
  .ladder_ux_checkbox__checkbox-styles__wrapper-s {
    width: 20.0px;
    height: 20.0px;
  }

}
.ladder_account_next_views_gift__slide-toggle-top-styles__root {
  width: 100%;
  justify-content: space-between;
  height: 65px;
  border: 1px solid #5D5D5D;
  padding: 18px 40px;
  flex-direction: row;
  margin-top: -1px;
  background-color: #E7EEEF;
  display: flex;
}

.ladder_account_next_views_gift__slide-toggle-top-styles__root-hidden {
  background-color: #F1F1F1;
}

.ladder_account_next_views_gift__slide-toggle-top-styles__hover-bold:hover {
  font-weight: 600;
}

.ladder_account_next_views_gift__slide-toggle-top-styles__icon-arrow {
  margin: 0;
  width: 20.0px;
}
.ladder_ux_radio_input__inline-radio-input-styles__root {
  display: flex;
  flex-direction: row;
  border-style: solid;
  border-radius: 9px;
  margin-top: 10px;
}

.ladder_ux_radio_input__inline-radio-input-styles__root > *:first-child {
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
}

.ladder_ux_radio_input__inline-radio-input-styles__root > *:last-child {
  border-top-right-radius: 9px;
  border-bottom-right-radius: 9px;
}

.ladder_ux_radio_input__inline-radio-input-styles__root > *:not(:first-child) {
  border-left-style: solid;
}

.ladder_ux_radio_input__inline-radio-input-styles__button-container {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  padding: 10px 2px;
  cursor: pointer;
}

.ladder_ux_radio_input__inline-radio-input-styles__button-container:hover {
  background-color: #E7EEEF;
}

.ladder_ux_radio_input__inline-radio-input-styles__selected-button {
  background-color: #E7EEEF;
}

.ladder_ux_radio_input__inline-radio-input-styles__border-common {
  border-color: #7A8A8C;
  border-width: 1px;
}

.ladder_ux_radio_input__inline-radio-input-styles__radio {
  display: none;
}
.ladder_savings_common__StepsPricePanel-styles__big-wrapper {
  width: 100%;
}

.ladder_savings_common__StepsPricePanel-styles__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #DBE6DB;
  padding: 20px;
  margin-top: 10.0px;
}

.ladder_savings_common__StepsPricePanel-styles__npq {
  margin-top: 22.5px;
}

.ladder_savings_common__StepsPricePanel-styles__title {
  width: 80%;
}

.ladder_savings_common__StepsPricePanel-styles__prices-wrapper {
  display: flex;
  align-items: center;
}

.ladder_savings_common__StepsPricePanel-styles__price {
  background-color: #FFFFFF;
  padding: 10.0px;
  margin: 10.0px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ladder_savings_common__StepsPricePanel-styles__per-day {
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
}

.ladder_savings_common__StepsPricePanel-styles__slashed-price-wrapper {
  display: flex;
  align-items: center;
}

.ladder_savings_common__StepsPricePanel-styles__slashed-price {
  margin-left: 20px;
}

.ladder_savings_common__StepsPricePanel-styles__expander {
  margin-top: 10.0px;
  display: block;
}

@media (max-width: 499px) {

  
  
  .ladder_savings_common__StepsPricePanel-styles__wrapper {
    width: 100%;
  }
  
  .ladder_savings_common__StepsPricePanel-styles__price {
    width: 100%;
  }

}
.ladder_guide_common__full-width-color-markdown-section-styles__no-top-margin {
  margin-top: 0;
}

@media (min-width: 1025px) {

  
  
  .ladder_guide_common__full-width-color-markdown-section-styles__container {
    width: 100%;
    padding-top: 60px;
    padding-bottom: 60px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_guide_common__full-width-color-markdown-section-styles__container {
    width: 100%;
    padding-top: 60px;
    padding-bottom: 60px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_guide_common__full-width-color-markdown-section-styles__container {
    width: 100%;
    padding-top: 60px;
    padding-bottom: 60px;
  }

}
.ladder_account_next_views_secondary_addressee_edit__edit-secondary-addressee-fields-display-styles__field {
  margin: 10px 0;
}

@media (min-width: 1025px) {

  
  
  .ladder_account_next_views_secondary_addressee_edit__edit-secondary-addressee-fields-display-styles__fields {
    display: flex;
    flex-direction: row;
  }
  
  .ladder_account_next_views_secondary_addressee_edit__edit-secondary-addressee-fields-display-styles__maybe-column {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  
  .ladder_account_next_views_secondary_addressee_edit__edit-secondary-addressee-fields-display-styles__maybe-column:first-of-type {
    padding-right: 20px;
  }
  
  .ladder_account_next_views_secondary_addressee_edit__edit-secondary-addressee-fields-display-styles__maybe-column:last-of-type {
    padding-left: 20px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_account_next_views_secondary_addressee_edit__edit-secondary-addressee-fields-display-styles__maybe-column {
    display: contents;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account_next_views_secondary_addressee_edit__edit-secondary-addressee-fields-display-styles__maybe-column {
    display: contents;
  }

}
.ladder_account_next_views_profile_edit__edit-personal-details-buttons-styles__buttons {
  margin-top: 40px;
  display: flex;
}

@media (max-width: 499px) {

  
  
  .ladder_account_next_views_profile_edit__edit-personal-details-buttons-styles__buttons {
    margin-top: 15.0px;
  }

}

.ladder_account_next_views_profile_edit__edit-personal-details-buttons-styles__buttons>*:first-child {
  margin-right: 20px;
}

.ladder_account_next_views_profile_edit__edit-personal-details-buttons-styles__save-button {
  max-width: 101px;
}

.ladder_account_next_views_profile_edit__edit-personal-details-buttons-styles__cancel-button {
  max-width: 117px;
}
.ladder_app_review__agreement-section-styles__root {
  max-width: 600px;
  width: 100%;
  margin: 0 10.0px;
  padding: 0 10.0px;
}

.ladder_app_review__agreement-section-styles__title {
  margin-top: 10.0px;
  text-align: left;
}

.ladder_app_review__agreement-section-styles__subtext-description {
  text-align: left;
  margin: 20px 0;
  padding-bottom: 30px;
}

.ladder_app_review__agreement-section-styles__subtext {
  text-align: left;
  margin: 10.0px 0;
}

.ladder_app_review__agreement-section-styles__markdown {

}

@media (max-width: 499px) {

  
  
  .ladder_app_review__agreement-section-styles__root {
    width: 91%;
    margin: 0 10.0px;
  }
  
  .ladder_app_review__agreement-section-styles__title {
    margin: 10.0px 8px;
  }
  
  .ladder_app_review__agreement-section-styles__subtext {
    margin: 10.0px 8px;
  }
  
  .ladder_app_review__agreement-section-styles__markdown {
    margin: 10.0px 8px;
  }
  
  .ladder_app_review__agreement-section-styles__expandable-review {
    padding: 0;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_app_review__agreement-section-styles__root {
    width: 90%;
    margin: 0 26px;
  }
  
  .ladder_app_review__agreement-section-styles__title {
    margin: 0;
  }
  
  .ladder_app_review__agreement-section-styles__subtext {
    margin: 0;
  }
  
  .ladder_app_review__agreement-section-styles__markdown {
    margin: 0;
  }

}

.ladder_app_review__agreement-section-styles__expandable-review {
  max-width: 800px;
  width: 90vw;
  margin: auto;
}
.ladder_app_review__section-title-styles__root {
  width: 100%;
  cursor: default;
  scroll-margin-top: 100px;
}

.ladder_app_review__section-title-styles__header-container {
  display: flex;
  flex-grow: 1;
  justify-content: left;
  align-items: center;
}
.ladder_ux_homepage_testimonials_core__rebrand-testimonial-panel-styles__root {
  width: 100%;
  height: 100%;
  background-color: #AFC5C8;
  padding-bottom: 32px;
}

.ladder_ux_homepage_testimonials_core__rebrand-testimonial-panel-styles__root-spring-wood {
  background-color: #F7F2EF;
}

.ladder_ux_homepage_testimonials_core__rebrand-testimonial-panel-styles__root-beige {
  background-color: #EFEAE1;
}
.portrait .ladder_widget_ui_v2__logo-styles__root {
  width: 141px;
}

.landscape .ladder_widget_ui_v2__logo-styles__root {
  width: 141px;
}

.tile-a .ladder_widget_ui_v2__logo-styles__root {
  width: 103px;
}

.tile-b .ladder_widget_ui_v2__logo-styles__root {
  width: 103px;
}

.tile-a .ladder_widget_ui_v2__logo-styles__root {
  margin-bottom: 5.0px;
}

.tile-b .ladder_widget_ui_v2__logo-styles__root {
  margin-bottom: 20px;
}

.portrait .ladder_widget_ui_v2__logo-styles__root {
  flex-grow: 15;
}

.landscape .ladder_widget_ui_v2__logo-styles__root {
  flex-grow: 63;
}

.panel .ladder_widget_ui_v2__logo-styles__root {
  flex-grow: 0;
}
.ladder_agents_agent_assisted_app_views_app_status__view-decision-button-styles__button {
  width: fit-content;
  margin-top: 52px;
}
@keyframes spin {

0% {
    transform: rotate(0deg);
  }
  
100% {
    transform: rotate(360deg);
  }

}

@keyframes move-across {

0% {
    left: -50%;
  }
  
100% {
    left: 150%;
  }

}

@keyframes move-across-narrow {

0% {
    left: 0;
  }
  
100% {
    left: 100%;
  }

}

@keyframes move-up {

0% {
    transform: translateY(10px);
    opacity: 0.07;
  }
  
100% {
    transform: translateY(0);
    opacity: 1.0;
  }

}

@keyframes move-down {

0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  
100% {
    transform: translateY(0);
    opacity: 1.0;
  }

}

@keyframes slide-up {

0% {
    transform: translateY(30px);
    opacity: 0.0;
  }
  
10% {
    transform: translateY(0);
    opacity: 1.0;
  }
  
90% {
    transform: translateY(0);
    opacity: 1.0;
  }
  
100% {
    transform: translateY(-45px);
    opacity: 0;
  }

}

@keyframes slide-up-end {

0% {
    transform: translateY(30px);
    opacity: 0.0;
  }
  
10% {
    transform: translateY(0);
    opacity: 1.0;
  }
  
100% {
    transform: translateY(0);
    opacity: 1.0;
  }

}

@keyframes slide-up-mobile {

0% {
    transform: translateY(10px);
    opacity: 0.0;
  }
  
10% {
    transform: translateY(0);
    opacity: 1.0;
  }
  
90% {
    transform: translateY(0);
    opacity: 1.0;
  }
  
100% {
    transform: translateY(-15px);
    opacity: 0;
  }

}

@keyframes slide-up-end-mobile {

0% {
    transform: translateY(10px);
    opacity: 0.0;
  }
  
10% {
    transform: translateY(0);
    opacity: 1.0;
  }
  
100% {
    transform: translateY(0);
    opacity: 1.0;
  }

}

@keyframes variable-length-spinner {

0% {
    stroke-dashoffset: 265;
    transform: rotate(0deg);
  }
  
50% {
    stroke-dashoffset: 215;
    transform: rotate(150deg);
  }
  
100% {
    stroke-dashoffset: 265;
    transform: rotate(360deg);
  }

}
.ladder_lux_markdown_components__paragraph-styles__root {
  margin-bottom: 16px;
}
.ladder_account_next_cards_your_total_coverage_summary__rungs-coverage-row-styles__coverage-row {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 15.0px 0;
}

.ladder_account_next_cards_your_total_coverage_summary__rungs-coverage-row-styles__bottom-border {
  border-bottom: 1px solid #D0D0D0;
}

.ladder_account_next_cards_your_total_coverage_summary__rungs-coverage-row-styles__coverage-row-header {
  display: flex;
  justify-content: space-between;
}

.ladder_account_next_cards_your_total_coverage_summary__rungs-coverage-row-styles__coverage-row-body {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 25.0px 0 22.5px 0;
}

.ladder_account_next_cards_your_total_coverage_summary__rungs-coverage-row-styles__coverage-row-entry {
  width: 30%;
  padding: 0 10.0px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.ladder_account_next_cards_your_total_coverage_summary__rungs-coverage-row-styles__flex-col {
  display: flex;
  flex-direction: column;
}

.ladder_account_next_cards_your_total_coverage_summary__rungs-coverage-row-styles__entry-icon-details {
  display: flex;
  flex-direction: row;
  gap: 5.0px;
}

.ladder_account_next_cards_your_total_coverage_summary__rungs-coverage-row-styles__flex-icon {
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: 5.0px;
}

.ladder_account_next_cards_your_total_coverage_summary__rungs-coverage-row-styles__price-text {
  display: flex;
  flex-direction: column;
}

.ladder_account_next_cards_your_total_coverage_summary__rungs-coverage-row-styles__savings-caption {
  margin-top: 5.0px;
}

.ladder_account_next_cards_your_total_coverage_summary__rungs-coverage-row-styles__alert-icon {
  margin: 0 5.0px -5.0px 0;
  font-weight: initial;
}

@media (max-width: 499px) {

  
  
  
  
  .ladder_account_next_cards_your_total_coverage_summary__rungs-coverage-row-styles__coverage-row:first-child {
    margin-top: 0;
  }
  
  .ladder_account_next_cards_your_total_coverage_summary__rungs-coverage-row-styles__coverage-row:last-child {
    margin-bottom: 0;
  }
  
  .ladder_account_next_cards_your_total_coverage_summary__rungs-coverage-row-styles__coverage-row-body {
    flex-direction: column;
  }
  
  .ladder_account_next_cards_your_total_coverage_summary__rungs-coverage-row-styles__coverage-row-entry {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
    padding: 20px 0;
  }
  
  .ladder_account_next_cards_your_total_coverage_summary__rungs-coverage-row-styles__policy-link {
    margin-top: 15.0px;
  }

}
.ladder_ny_form_review__new-york-form-id-styles__root {
  width: 80%;
  margin: 24px auto 0 auto;
}
.ladder_ux_expand_card__expand-card-container-styles__root {
  width: 100%;
  max-width: 600px;
}

.ladder_ux_expand_card__expand-card-container-styles__width-l {
  max-width: 800px;
}
.ladder_ux_logos__stride-logo-styles__icon {
  height: 44.8px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_logos__stride-logo-styles__icon {
    height: 38.4px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_logos__stride-logo-styles__icon {
    height: 28.8px;
  }

}
.ladder_cap_and_accel_view__Root-styles__horizontal-container {
  display: flex;
  background-color: #F7F2EF;
  height: 90vh;
  width: 100%;
}

@media (max-width: 499px) {

  
  
  .ladder_cap_and_accel_view__Root-styles__horizontal-container {
    flex-direction: column-reverse;
    justify-content: flex-end;
    height: auto;
  }

}

.ladder_cap_and_accel_view__Root-styles__main-text-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
}

@media (max-width: 499px) {

  
  
  .ladder_cap_and_accel_view__Root-styles__main-text-container {
    padding: 0 20px;
    width: 100%;
  }

}

.ladder_cap_and_accel_view__Root-styles__text-container {
  max-width: 750px;
}

.ladder_cap_and_accel_view__Root-styles__padding {
  padding-top: 5%;
}

.ladder_cap_and_accel_view__Root-styles__padding-large {
  padding-top: 15%;
}

.ladder_cap_and_accel_view__Root-styles__button-container {
  display: flex;
  justify-content: center;
}

.ladder_cap_and_accel_view__Root-styles__image-container {
  display: flex;
  align-self: flex-end;
  width: 25%;
}

@media (max-width: 499px) {

  
  
  .ladder_cap_and_accel_view__Root-styles__image-container {
    align-self: flex-start;
    width: 66.67%;
  }

}

.ladder_cap_and_accel_view__Root-styles__img {
  width: 90%;
}
.ladder_markdoc_common__fence-comp-styles__code {
  background: rgba(175,184,193,0.2);
  padding: 0.2em 0.4em;
  border-radius: 6px;
  overflow: scroll;
}
.ladder_ux_carousel__control-dots-wrapper-styles__control-dots-wrapper {
  position: absolute;
  width: 100%;
  bottom: -15px;
  left: 0;
  right: 0;
}
.ladder_guide_life_insurance_cost__quick-estimate-form-styles__root {
  margin-top: 60px;
  margin-bottom: 60px;
  width: 80%;
  position: relative;
  border: solid;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_guide_life_insurance_cost__quick-estimate-form-styles__root {
    margin: 25.0px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_guide_life_insurance_cost__quick-estimate-form-styles__root {
    width: 100%;
    border: none;
  }

}
.ladder_widget_ui_v1__widget-front-styles__root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  width: 300px;
  height: 350px;
}
.ladder_careers__ui-divider-styles__root {
  border-bottom: 1px solid #707070;
  margin: 0;
}
.ladder_form_uplevel__uplevel-sprout-styles__sprout-animation-control {
  animation: sprout-animation 400ms 1 forwards;
  animation-delay: 400ms;
  opacity: 0;
}

.ladder_form_uplevel__uplevel-sprout-styles__sprout-for-screenshot {
  height: 80px;
  width: 80px;
}

@keyframes sprout-animation {

0% {
    opacity: 1;
    height: 0px;
    width: 0px;
  }
  
70% {
    opacity: 1;
    height: 83px;
    width: 83px;
    animation-timing-function: ease-out;
  }
  
85% {
    opacity: 1;
    height: 77px;
    width: 77px;
    animation-timing-function: ease-in;
  }
  
100% {
    opacity: 1;
    height: 80px;
    width: 80px;
    animation-timing-function: ease;
  }

}
.ladder_views_decision_offer__allianz-rating-modal-styles__text-box {
  margin-top: 15.0px;
}

.ladder_views_decision_offer__allianz-rating-modal-styles__all-img {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}

.ladder_views_decision_offer__allianz-rating-modal-styles__img-row {
  display: flex;
  justify-content: center;
  margin-right: 60px;
}

.ladder_views_decision_offer__allianz-rating-modal-styles__second-logo {
  display: flex;
  justify-content: center;
  margin-top: 5.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_views_decision_offer__allianz-rating-modal-styles__all-img {
    display: inline;
  }
  
  .ladder_views_decision_offer__allianz-rating-modal-styles__img-row {
    margin-top: 20px;
    margin-right: 0px;
  }
  
  .ladder_views_decision_offer__allianz-rating-modal-styles__second-logo {
    margin-top: 20px;
    margin-right: 0px;
  }

}
.ladder_financial_advisors_paid_flow_end__Root-styles__root {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFFFFF;
}

.ladder_financial_advisors_paid_flow_end__Root-styles__inner {
  text-align: center;
  margin-top: 120px;
  max-width: 1100px;
}

.ladder_financial_advisors_paid_flow_end__Root-styles__links-bar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ladder_financial_advisors_paid_flow_end__Root-styles__link-spacer {
  padding: 0 10.0px;
}

.ladder_financial_advisors_paid_flow_end__Root-styles__peace-image {
  width: 264px;
  margin-top: 58px;
  margin-bottom: 80px;
}
.ladder_ux_modal__scroll-cue-view-styles__wrapper {
  scroll-behavior: smooth;
}

.ladder_ux_modal__scroll-cue-view-styles__scrolled {
  scroll-behavior: auto;
}

.ladder_ux_modal__scroll-cue-view-styles__scroll-cue {
  width: 40px;
  justify-content: center;
  height: 40px;
  border: solid 1px#D9D9D9;
  align-items: center;
  position: absolute;
  cursor: pointer;
  background-color: #FFFFFF;
  border-radius: 50%;
  display: flex;
  bottom: 10px;
  transition: opacity 500ms;
  left: calc(50% - 20px);
}

.ladder_ux_modal__scroll-cue-view-styles__hidden {
  opacity: 0;
  pointer-events: none;
  cursor: default;
}
.ladder_account__billing-expand-card-styles__inline-div {
  display: flex;
}

.ladder_account__billing-expand-card-styles__payment-history {
  padding-top: 22.5px;
  border-top: 1px solid #D0D0D0;
  box-sizing: border-box;
}
.ladder_work_home__top-panel-styles__root {
  width: 100%;
}
.ladder_view_schedule__AdvanceButton-styles__root {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 8px;
  width: 100%;
}

.ladder_view_schedule__AdvanceButton-styles__button-container {
  display: flex;
  max-width: 100vw;
  width: 100%;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
}

@media (max-width: 499px) {

  
  
  .ladder_view_schedule__AdvanceButton-styles__button {
    flex-basis: 50%;
    flex-grow: 1;
    margin: 0 8px;
  }
  
  .ladder_view_schedule__AdvanceButton-styles__button-container {
    justify-content: space-between;
  }

}
.ladder_onboarding_spouse_connect_2__mobile-carousel-styles__carousel {
  display: flex;
}

.ladder_onboarding_spouse_connect_2__mobile-carousel-styles__row {
  display: flex;
  flex-direction: row;
  align-content: center;
}

.ladder_onboarding_spouse_connect_2__mobile-carousel-styles__col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ladder_onboarding_spouse_connect_2__mobile-carousel-styles__width-50 {
  width: 50%;
}

.ladder_onboarding_spouse_connect_2__mobile-carousel-styles__other-text-wrapper {

}
.ladder_view_schedule__root-styles__root {
  text-align: center;
}

.ladder_view_schedule__root-styles__container {
  margin: 0 auto;
  padding: 0 20px;
  text-align: left;
  max-width: 600px;
}

.ladder_view_schedule__root-styles__link {
  cursor: pointer;
}

.ladder_view_schedule__root-styles__button {
  margin-top: 20px;
}
.ladder_about__panel-styles__panel {
  display: flex;
  background-color: inherit;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
}

.ladder_about__panel-styles__outer {
  width: 100%;
}

.ladder_about__panel-styles__max-width-1440 {
  max-width: 1440px;
}

@media (min-width: 1025px) {

  
  
  .ladder_about__panel-styles__pad-left {
    padding-left: 112px;
  }
  
  .ladder_about__panel-styles__pad-right {
    padding-right: 112px;
  }
  
  .ladder_about__panel-styles__pad-top {
    padding-top: 85px;
  }
  
  .ladder_about__panel-styles__pad-bottom {
    padding-bottom: 85px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_about__panel-styles__pad-left {
    padding-left: 7%;
  }
  
  .ladder_about__panel-styles__pad-right {
    padding-right: 7%;
  }
  
  .ladder_about__panel-styles__pad-top {
    padding-top: 55px;
  }
  
  .ladder_about__panel-styles__pad-bottom {
    padding-bottom: 55px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_about__panel-styles__pad-left {
    padding-left: 7%;
  }
  
  .ladder_about__panel-styles__pad-right {
    padding-right: 7%;
  }
  
  .ladder_about__panel-styles__pad-top {
    padding-top: 55px;
  }
  
  .ladder_about__panel-styles__pad-bottom {
    padding-bottom: 55px;
  }

}

.ladder_about__panel-styles__alt-bg-color {
  background-color: #F7F2EF;
}
.ladder_header__overlapping-column-styles__root {
  display: flex;
  margin-top: -100px;
  padding-top: 100px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_header__overlapping-column-styles__root {
    margin-top: -60px;
    padding-top: 60px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_header__overlapping-column-styles__root {
    margin-top: -60px;
    padding-top: 60px;
  }

}
.ladder_form_pta_authorizations__root-styles__root {
  display: flex;
  margin-top: 32px;
  align-items: center;
  width: 100%;
  height: 100%;
}

.ladder_form_pta_authorizations__root-styles__image {
  width: 80%;
  height: 80%;
  margin-right: 7%;
}

.ladder_form_pta_authorizations__root-styles__image-container {
  width: 50%;
  height: 50%;
}

.ladder_form_pta_authorizations__root-styles__questions {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 50%;
}

@media (max-width: 499px) {

  
  
  .ladder_form_pta_authorizations__root-styles__root {
    flex-direction: column;
  }
  
  .ladder_form_pta_authorizations__root-styles__image-container {
    width: 90%;
  }
  
  .ladder_form_pta_authorizations__root-styles__image {
    width: 100%;
    margin-right: unset;
  }
  
  .ladder_form_pta_authorizations__root-styles__questions {
    width: 90%;
    margin-top: 32px;
  }

}
.ladder_ux_homepage_core__stability-and-resilience-for-your-family-styles__root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #F7F2EF;
}

.ladder_ux_homepage_core__stability-and-resilience-for-your-family-styles__wrapper {
  display: flex;
  flex-direction: row;
  width: 90%;
  height: 100%;
  max-width: 1400px;
  position: relative;
  background-color: #F7F2EF;
  padding: 128px 0 0 0;
}

.ladder_ux_homepage_core__stability-and-resilience-for-your-family-styles__title-container {
  display: flex;
  flex-direction: column;
  width: 40vw;
  margin-bottom: 128px;
  max-width: 800px;
  z-index: 1;
}

.ladder_ux_homepage_core__stability-and-resilience-for-your-family-styles__title {
  display: flex;
  margin-bottom: 32px;
}

.ladder_ux_homepage_core__stability-and-resilience-for-your-family-styles__text {
  margin: 8.0px 0;
}

.ladder_ux_homepage_core__stability-and-resilience-for-your-family-styles__buttons {
  margin: 32px 0 32px 0;
}

.ladder_ux_homepage_core__stability-and-resilience-for-your-family-styles__image-wrapper {
  position: absolute;
  height: 50vw;
  max-height: 95%;
  width: auto;
  bottom: 0;
  right: 0;
  z-index: 0;
}

.ladder_ux_homepage_core__stability-and-resilience-for-your-family-styles__image {
  display: block;
  width: auto;
  height: 100%;
  position: absolute;
  right: 0;
}

.ladder_ux_homepage_core__stability-and-resilience-for-your-family-styles__subtext {
  width: 192px;
  display: flex;
  justify-content: center;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_homepage_core__stability-and-resilience-for-your-family-styles__wrapper {
    flex-direction: column;
    align-items: center;
  }
  
  .ladder_ux_homepage_core__stability-and-resilience-for-your-family-styles__title-container {
    display: flex;
    width: 90%;
    max-width: 800px;
  }
  
  .ladder_ux_homepage_core__stability-and-resilience-for-your-family-styles__image-wrapper {
    position: initial;
    width: 90%;
    max-width: 800px;
    height: auto;
  }
  
  .ladder_ux_homepage_core__stability-and-resilience-for-your-family-styles__image {
    width: 100%;
    height: auto;
    position: static;
    right: unset;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_homepage_core__stability-and-resilience-for-your-family-styles__wrapper {
    flex-direction: column;
    align-items: center;
  }
  
  .ladder_ux_homepage_core__stability-and-resilience-for-your-family-styles__title-container {
    display: flex;
    width: 90%;
    max-width: 800px;
  }
  
  .ladder_ux_homepage_core__stability-and-resilience-for-your-family-styles__image-wrapper {
    position: initial;
    width: 90%;
    max-width: 800px;
    height: auto;
  }
  
  .ladder_ux_homepage_core__stability-and-resilience-for-your-family-styles__image {
    width: 100%;
    height: auto;
    position: static;
    right: unset;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_homepage_core__stability-and-resilience-for-your-family-styles__wrapper {
    padding: 0;
  }
  
  .ladder_ux_homepage_core__stability-and-resilience-for-your-family-styles__title-container {
    margin: 64px 0 0 0;
  }
  
  .ladder_ux_homepage_core__stability-and-resilience-for-your-family-styles__title {
    margin-bottom: 18px;
  }
  
  .ladder_ux_homepage_core__stability-and-resilience-for-your-family-styles__button {
    max-width: 100%;
  }
  
  .ladder_ux_homepage_core__stability-and-resilience-for-your-family-styles__subtext {
    width: 100%;
    margin-bottom: 16px;
  }

}
.ladder_savings_common__steps-list-styles__list {
  list-style: '∙ ';
  text-align: left;
}
@media (max-width: 499px) {

  
  
  .ladder_onboarding_shared_components__mobile-small-bp__panel-2 {
    padding-bottom: 50.0px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_onboarding_shared_components__mobile-small-bp__panel-2 {
    padding-bottom: 50.0px;
  }

}
.landscape .ladder_widget_ui_v2__gender-field-styles__root {
  width: 32%;
}
.ladder_ux_icon__styles__black {
  stroke: #000000;
  fill: #000000;
}

.ladder_ux_icon__styles__black path[fill]:not([fill=none]) {
  fill: #000000;
}

.ladder_ux_icon__styles__black g[fill]:not([fill=none]) {
  fill: #000000;
}

.ladder_ux_icon__styles__black path[stroke]:not([stroke=none]) {
  stroke: #000000;
}

.ladder_ux_icon__styles__black g[stroke]:not([stroke=none]) {
  stroke: #000000;
}

.ladder_ux_icon__styles__disabled {
  stroke: #BDBDBD;
  fill: #BDBDBD;
}

.ladder_ux_icon__styles__disabled path[fill]:not([fill=none]) {
  fill: #BDBDBD;
}

.ladder_ux_icon__styles__disabled g[fill]:not([fill=none]) {
  fill: #BDBDBD;
}

.ladder_ux_icon__styles__disabled path[stroke]:not([stroke=none]) {
  stroke: #BDBDBD;
}

.ladder_ux_icon__styles__disabled g[stroke]:not([stroke=none]) {
  stroke: #BDBDBD;
}

.ladder_ux_icon__styles__dark-blue {
  stroke: #475665;
  fill: #475665;
}

.ladder_ux_icon__styles__dark-blue path[fill]:not([fill=none]) {
  fill: #475665;
}

.ladder_ux_icon__styles__dark-blue g[fill]:not([fill=none]) {
  fill: #475665;
}

.ladder_ux_icon__styles__dark-blue path[stroke]:not([stroke=none]) {
  stroke: #475665;
}

.ladder_ux_icon__styles__dark-blue g[stroke]:not([stroke=none]) {
  stroke: #475665;
}

.ladder_ux_icon__styles__light-gray {
  stroke: #D0D0D0;
  fill: #D0D0D0;
}

.ladder_ux_icon__styles__light-gray path[fill]:not([fill=none]) {
  fill: #D0D0D0;
}

.ladder_ux_icon__styles__light-gray g[fill]:not([fill=none]) {
  fill: #D0D0D0;
}

.ladder_ux_icon__styles__light-gray path[stroke]:not([stroke=none]) {
  stroke: #D0D0D0;
}

.ladder_ux_icon__styles__light-gray g[stroke]:not([stroke=none]) {
  stroke: #D0D0D0;
}

.ladder_ux_icon__styles__medium-gray {
  stroke: #707070;
  fill: #707070;
}

.ladder_ux_icon__styles__medium-gray path[fill]:not([fill=none]) {
  fill: #707070;
}

.ladder_ux_icon__styles__medium-gray g[fill]:not([fill=none]) {
  fill: #707070;
}

.ladder_ux_icon__styles__medium-gray path[stroke]:not([stroke=none]) {
  stroke: #707070;
}

.ladder_ux_icon__styles__medium-gray g[stroke]:not([stroke=none]) {
  stroke: #707070;
}

.ladder_ux_icon__styles__red {
  stroke: #FE4747;
  fill: #FE4747;
}

.ladder_ux_icon__styles__red path[fill]:not([fill=none]) {
  fill: #FE4747;
}

.ladder_ux_icon__styles__red g[fill]:not([fill=none]) {
  fill: #FE4747;
}

.ladder_ux_icon__styles__red path[stroke]:not([stroke=none]) {
  stroke: #FE4747;
}

.ladder_ux_icon__styles__red g[stroke]:not([stroke=none]) {
  stroke: #FE4747;
}

.ladder_ux_icon__styles__yellow {
  stroke: #FFBD33;
  fill: #FFBD33;
}

.ladder_ux_icon__styles__yellow path[fill]:not([fill=none]) {
  fill: #FFBD33;
}

.ladder_ux_icon__styles__yellow g[fill]:not([fill=none]) {
  fill: #FFBD33;
}

.ladder_ux_icon__styles__yellow path[stroke]:not([stroke=none]) {
  stroke: #FFBD33;
}

.ladder_ux_icon__styles__yellow g[stroke]:not([stroke=none]) {
  stroke: #FFBD33;
}

.ladder_ux_icon__styles__green {
  stroke: #3ACA95;
  fill: #3ACA95;
}

.ladder_ux_icon__styles__green path[fill]:not([fill=none]) {
  fill: #3ACA95;
}

.ladder_ux_icon__styles__green g[fill]:not([fill=none]) {
  fill: #3ACA95;
}

.ladder_ux_icon__styles__green path[stroke]:not([stroke=none]) {
  stroke: #3ACA95;
}

.ladder_ux_icon__styles__green g[stroke]:not([stroke=none]) {
  stroke: #3ACA95;
}

.ladder_ux_icon__styles__white {
  stroke: #FFFFFF;
  fill: #FFFFFF;
}

.ladder_ux_icon__styles__white path[fill]:not([fill=none]) {
  fill: #FFFFFF;
}

.ladder_ux_icon__styles__white g[fill]:not([fill=none]) {
  fill: #FFFFFF;
}

.ladder_ux_icon__styles__white path[stroke]:not([stroke=none]) {
  stroke: #FFFFFF;
}

.ladder_ux_icon__styles__white g[stroke]:not([stroke=none]) {
  stroke: #FFFFFF;
}

.ladder_ux_icon__styles__tan {
  stroke: #D1A98F;
  fill: #D1A98F;
}

.ladder_ux_icon__styles__tan path[fill]:not([fill=none]) {
  fill: #D1A98F;
}

.ladder_ux_icon__styles__tan g[fill]:not([fill=none]) {
  fill: #D1A98F;
}

.ladder_ux_icon__styles__tan path[stroke]:not([stroke=none]) {
  stroke: #D1A98F;
}

.ladder_ux_icon__styles__tan g[stroke]:not([stroke=none]) {
  stroke: #D1A98F;
}

.ladder_ux_icon__styles__cape-cod {
  stroke: #464F50;
  fill: #464F50;
}

.ladder_ux_icon__styles__cape-cod path[fill]:not([fill=none]) {
  fill: #464F50;
}

.ladder_ux_icon__styles__cape-cod g[fill]:not([fill=none]) {
  fill: #464F50;
}

.ladder_ux_icon__styles__cape-cod path[stroke]:not([stroke=none]) {
  stroke: #464F50;
}

.ladder_ux_icon__styles__cape-cod g[stroke]:not([stroke=none]) {
  stroke: #464F50;
}

.ladder_ux_icon__styles__mercury {
  stroke: #D9D9D9;
  fill: #D9D9D9;
}

.ladder_ux_icon__styles__mercury path[fill]:not([fill=none]) {
  fill: #D9D9D9;
}

.ladder_ux_icon__styles__mercury g[fill]:not([fill=none]) {
  fill: #D9D9D9;
}

.ladder_ux_icon__styles__mercury path[stroke]:not([stroke=none]) {
  stroke: #D9D9D9;
}

.ladder_ux_icon__styles__mercury g[stroke]:not([stroke=none]) {
  stroke: #D9D9D9;
}

.ladder_ux_icon__styles__opal {
  stroke: #AFC5C8;
  fill: #AFC5C8;
}

.ladder_ux_icon__styles__opal path[fill]:not([fill=none]) {
  fill: #AFC5C8;
}

.ladder_ux_icon__styles__opal g[fill]:not([fill=none]) {
  fill: #AFC5C8;
}

.ladder_ux_icon__styles__opal path[stroke]:not([stroke=none]) {
  stroke: #AFC5C8;
}

.ladder_ux_icon__styles__opal g[stroke]:not([stroke=none]) {
  stroke: #AFC5C8;
}

.ladder_ux_icon__styles__error {
  stroke: #CB3A3A;
  fill: #CB3A3A;
}

.ladder_ux_icon__styles__error path[fill]:not([fill=none]) {
  fill: #CB3A3A;
}

.ladder_ux_icon__styles__error g[fill]:not([fill=none]) {
  fill: #CB3A3A;
}

.ladder_ux_icon__styles__error path[stroke]:not([stroke=none]) {
  stroke: #CB3A3A;
}

.ladder_ux_icon__styles__error g[stroke]:not([stroke=none]) {
  stroke: #CB3A3A;
}

.ladder_ux_icon__styles__phoenix {
  stroke: #975C46;
  fill: #975C46;
}

.ladder_ux_icon__styles__phoenix path[fill]:not([fill=none]) {
  fill: #975C46;
}

.ladder_ux_icon__styles__phoenix g[fill]:not([fill=none]) {
  fill: #975C46;
}

.ladder_ux_icon__styles__phoenix path[stroke]:not([stroke=none]) {
  stroke: #975C46;
}

.ladder_ux_icon__styles__phoenix g[stroke]:not([stroke=none]) {
  stroke: #975C46;
}

.ladder_ux_icon__styles__scorpion {
  stroke: #5D5D5D;
  fill: #5D5D5D;
}

.ladder_ux_icon__styles__scorpion path[fill]:not([fill=none]) {
  fill: #5D5D5D;
}

.ladder_ux_icon__styles__scorpion g[fill]:not([fill=none]) {
  fill: #5D5D5D;
}

.ladder_ux_icon__styles__scorpion path[stroke]:not([stroke=none]) {
  stroke: #5D5D5D;
}

.ladder_ux_icon__styles__scorpion g[stroke]:not([stroke=none]) {
  stroke: #5D5D5D;
}

.ladder_ux_icon__styles__spring-rain {
  stroke: #AFC8AF;
  fill: #AFC8AF;
}

.ladder_ux_icon__styles__spring-rain path[fill]:not([fill=none]) {
  fill: #AFC8AF;
}

.ladder_ux_icon__styles__spring-rain g[fill]:not([fill=none]) {
  fill: #AFC8AF;
}

.ladder_ux_icon__styles__spring-rain path[stroke]:not([stroke=none]) {
  stroke: #AFC8AF;
}

.ladder_ux_icon__styles__spring-rain g[stroke]:not([stroke=none]) {
  stroke: #AFC8AF;
}

.ladder_ux_icon__styles__my-pink {
  stroke: #CF967E;
  fill: #CF967E;
}

.ladder_ux_icon__styles__my-pink path[fill]:not([fill=none]) {
  fill: #CF967E;
}

.ladder_ux_icon__styles__my-pink g[fill]:not([fill=none]) {
  fill: #CF967E;
}

.ladder_ux_icon__styles__my-pink path[stroke]:not([stroke=none]) {
  stroke: #CF967E;
}

.ladder_ux_icon__styles__my-pink g[stroke]:not([stroke=none]) {
  stroke: #CF967E;
}

.ladder_ux_icon__styles__gray-asparagus {
  stroke: #465046;
  fill: #465046;
}

.ladder_ux_icon__styles__gray-asparagus path[fill]:not([fill=none]) {
  fill: #465046;
}

.ladder_ux_icon__styles__gray-asparagus g[fill]:not([fill=none]) {
  fill: #465046;
}

.ladder_ux_icon__styles__gray-asparagus path[stroke]:not([stroke=none]) {
  stroke: #465046;
}

.ladder_ux_icon__styles__gray-asparagus g[stroke]:not([stroke=none]) {
  stroke: #465046;
}

.ladder_ux_icon__styles__centurybank-dark-gray {
  stroke: #5C5D60;
  fill: #5C5D60;
}

.ladder_ux_icon__styles__centurybank-dark-gray path[fill]:not([fill=none]) {
  fill: #5C5D60;
}

.ladder_ux_icon__styles__centurybank-dark-gray g[fill]:not([fill=none]) {
  fill: #5C5D60;
}

.ladder_ux_icon__styles__centurybank-dark-gray path[stroke]:not([stroke=none]) {
  stroke: #5C5D60;
}

.ladder_ux_icon__styles__centurybank-dark-gray g[stroke]:not([stroke=none]) {
  stroke: #5C5D60;
}

.ladder_ux_icon__styles__twic-brown {
  stroke: #482423;
  fill: #482423;
}

.ladder_ux_icon__styles__twic-brown path[fill]:not([fill=none]) {
  fill: #482423;
}

.ladder_ux_icon__styles__twic-brown g[fill]:not([fill=none]) {
  fill: #482423;
}

.ladder_ux_icon__styles__twic-brown path[stroke]:not([stroke=none]) {
  stroke: #482423;
}

.ladder_ux_icon__styles__twic-brown g[stroke]:not([stroke=none]) {
  stroke: #482423;
}

.ladder_ux_icon__styles__gamboge-brown {
  stroke: #9B6600;
  fill: #9B6600;
}

.ladder_ux_icon__styles__gamboge-brown path[fill]:not([fill=none]) {
  fill: #9B6600;
}

.ladder_ux_icon__styles__gamboge-brown g[fill]:not([fill=none]) {
  fill: #9B6600;
}

.ladder_ux_icon__styles__gamboge-brown path[stroke]:not([stroke=none]) {
  stroke: #9B6600;
}

.ladder_ux_icon__styles__gamboge-brown g[stroke]:not([stroke=none]) {
  stroke: #9B6600;
}

.ladder_ux_icon__styles__inherit {
  stroke: inherit;
  fill: inherit;
}

.ladder_ux_icon__styles__inherit path[fill]:not([fill=none]) {
  fill: inherit;
}

.ladder_ux_icon__styles__inherit g[fill]:not([fill=none]) {
  fill: inherit;
}

.ladder_ux_icon__styles__inherit path[stroke]:not([stroke=none]) {
  stroke: inherit;
}

.ladder_ux_icon__styles__inherit g[stroke]:not([stroke=none]) {
  stroke: inherit;
}

.ladder_ux_icon__styles__black-no-stroke {
  fill: #000000;
}

.ladder_ux_icon__styles__black-no-stroke path[fill]:not([fill=none]) {
  fill: #000000;
}

.ladder_ux_icon__styles__black-no-stroke g[fill]:not([fill=none]) {
  fill: #000000;
}

.ladder_ux_icon__styles__black-no-stroke path[stroke]:not([stroke=none]) {
  stroke: #000000;
}

.ladder_ux_icon__styles__black-no-stroke g[stroke]:not([stroke=none]) {
  stroke: #000000;
}

.ladder_ux_icon__styles__disabled-no-stroke {
  stroke: #BDBDBD;
  fill: #BDBDBD;
}

.ladder_ux_icon__styles__disabled-no-stroke path[fill]:not([fill=none]) {
  fill: #BDBDBD;
}

.ladder_ux_icon__styles__disabled-no-stroke g[fill]:not([fill=none]) {
  fill: #BDBDBD;
}

.ladder_ux_icon__styles__disabled-no-stroke path[stroke]:not([stroke=none]) {
  stroke: #BDBDBD;
}

.ladder_ux_icon__styles__disabled-no-stroke g[stroke]:not([stroke=none]) {
  stroke: #BDBDBD;
}

.ladder_ux_icon__styles__dark-blue-no-stroke {
  fill: #475665;
}

.ladder_ux_icon__styles__dark-blue-no-stroke path[fill]:not([fill=none]) {
  fill: #475665;
}

.ladder_ux_icon__styles__dark-blue-no-stroke g[fill]:not([fill=none]) {
  fill: #475665;
}

.ladder_ux_icon__styles__dark-blue-no-stroke path[stroke]:not([stroke=none]) {
  stroke: #475665;
}

.ladder_ux_icon__styles__dark-blue-no-stroke g[stroke]:not([stroke=none]) {
  stroke: #475665;
}

.ladder_ux_icon__styles__light-gray-no-stroke {
  fill: #D0D0D0;
}

.ladder_ux_icon__styles__light-gray-no-stroke path[fill]:not([fill=none]) {
  fill: #D0D0D0;
}

.ladder_ux_icon__styles__light-gray-no-stroke g[fill]:not([fill=none]) {
  fill: #D0D0D0;
}

.ladder_ux_icon__styles__light-gray-no-stroke path[stroke]:not([stroke=none]) {
  stroke: #D0D0D0;
}

.ladder_ux_icon__styles__light-gray-no-stroke g[stroke]:not([stroke=none]) {
  stroke: #D0D0D0;
}

.ladder_ux_icon__styles__medium-gray-no-stroke {
  fill: #707070;
}

.ladder_ux_icon__styles__medium-gray-no-stroke path[fill]:not([fill=none]) {
  fill: #707070;
}

.ladder_ux_icon__styles__medium-gray-no-stroke g[fill]:not([fill=none]) {
  fill: #707070;
}

.ladder_ux_icon__styles__medium-gray-no-stroke path[stroke]:not([stroke=none]) {
  stroke: #707070;
}

.ladder_ux_icon__styles__medium-gray-no-stroke g[stroke]:not([stroke=none]) {
  stroke: #707070;
}

.ladder_ux_icon__styles__red-no-stroke {
  fill: #FE4747;
}

.ladder_ux_icon__styles__red-no-stroke path[fill]:not([fill=none]) {
  fill: #FE4747;
}

.ladder_ux_icon__styles__red-no-stroke g[fill]:not([fill=none]) {
  fill: #FE4747;
}

.ladder_ux_icon__styles__red-no-stroke path[stroke]:not([stroke=none]) {
  stroke: #FE4747;
}

.ladder_ux_icon__styles__red-no-stroke g[stroke]:not([stroke=none]) {
  stroke: #FE4747;
}

.ladder_ux_icon__styles__white-no-stroke {
  fill: #FFFFFF;
}

.ladder_ux_icon__styles__white-no-stroke path[fill]:not([fill=none]) {
  fill: #FFFFFF;
}

.ladder_ux_icon__styles__white-no-stroke g[fill]:not([fill=none]) {
  fill: #FFFFFF;
}

.ladder_ux_icon__styles__white-no-stroke path[stroke]:not([stroke=none]) {
  stroke: #FFFFFF;
}

.ladder_ux_icon__styles__white-no-stroke g[stroke]:not([stroke=none]) {
  stroke: #FFFFFF;
}

.ladder_ux_icon__styles__tan-no-stroke {
  fill: #D1A98F;
}

.ladder_ux_icon__styles__tan-no-stroke path[fill]:not([fill=none]) {
  fill: #D1A98F;
}

.ladder_ux_icon__styles__tan-no-stroke g[fill]:not([fill=none]) {
  fill: #D1A98F;
}

.ladder_ux_icon__styles__tan-no-stroke path[stroke]:not([stroke=none]) {
  stroke: #D1A98F;
}

.ladder_ux_icon__styles__tan-no-stroke g[stroke]:not([stroke=none]) {
  stroke: #D1A98F;
}

.ladder_ux_icon__styles__mercury-no-stroke {
  fill: #D9D9D9;
}

.ladder_ux_icon__styles__mercury-no-stroke path[fill]:not([fill=none]) {
  fill: #D9D9D9;
}

.ladder_ux_icon__styles__mercury-no-stroke g[fill]:not([fill=none]) {
  fill: #D9D9D9;
}

.ladder_ux_icon__styles__mercury-no-stroke path[stroke]:not([stroke=none]) {
  stroke: #D9D9D9;
}

.ladder_ux_icon__styles__mercury-no-stroke g[stroke]:not([stroke=none]) {
  stroke: #D9D9D9;
}

.ladder_ux_icon__styles__opal-no-stroke {
  fill: #AFC5C8;
}

.ladder_ux_icon__styles__opal-no-stroke path[fill]:not([fill=none]) {
  fill: #AFC5C8;
}

.ladder_ux_icon__styles__opal-no-stroke g[fill]:not([fill=none]) {
  fill: #AFC5C8;
}

.ladder_ux_icon__styles__opal-no-stroke path[stroke]:not([stroke=none]) {
  stroke: #AFC5C8;
}

.ladder_ux_icon__styles__opal-no-stroke g[stroke]:not([stroke=none]) {
  stroke: #AFC5C8;
}

.ladder_ux_icon__styles__error-no-stroke {
  fill: #CB3A3A;
}

.ladder_ux_icon__styles__error-no-stroke path[fill]:not([fill=none]) {
  fill: #CB3A3A;
}

.ladder_ux_icon__styles__error-no-stroke g[fill]:not([fill=none]) {
  fill: #CB3A3A;
}

.ladder_ux_icon__styles__error-no-stroke path[stroke]:not([stroke=none]) {
  stroke: #CB3A3A;
}

.ladder_ux_icon__styles__error-no-stroke g[stroke]:not([stroke=none]) {
  stroke: #CB3A3A;
}

.ladder_ux_icon__styles__phoenix-no-stroke {
  fill: #975C46;
}

.ladder_ux_icon__styles__phoenix-no-stroke path[fill]:not([fill=none]) {
  fill: #975C46;
}

.ladder_ux_icon__styles__phoenix-no-stroke g[fill]:not([fill=none]) {
  fill: #975C46;
}

.ladder_ux_icon__styles__phoenix-no-stroke path[stroke]:not([stroke=none]) {
  stroke: #975C46;
}

.ladder_ux_icon__styles__phoenix-no-stroke g[stroke]:not([stroke=none]) {
  stroke: #975C46;
}

.ladder_ux_icon__styles__scorpion-no-stroke {
  fill: #5D5D5D;
}

.ladder_ux_icon__styles__scorpion-no-stroke path[fill]:not([fill=none]) {
  fill: #5D5D5D;
}

.ladder_ux_icon__styles__scorpion-no-stroke g[fill]:not([fill=none]) {
  fill: #5D5D5D;
}

.ladder_ux_icon__styles__scorpion-no-stroke path[stroke]:not([stroke=none]) {
  stroke: #5D5D5D;
}

.ladder_ux_icon__styles__scorpion-no-stroke g[stroke]:not([stroke=none]) {
  stroke: #5D5D5D;
}

.ladder_ux_icon__styles__spring-rain-no-stroke {
  fill: #AFC8AF;
}

.ladder_ux_icon__styles__spring-rain-no-stroke path[fill]:not([fill=none]) {
  fill: #AFC8AF;
}

.ladder_ux_icon__styles__spring-rain-no-stroke g[fill]:not([fill=none]) {
  fill: #AFC8AF;
}

.ladder_ux_icon__styles__spring-rain-no-stroke path[stroke]:not([stroke=none]) {
  stroke: #AFC8AF;
}

.ladder_ux_icon__styles__spring-rain-no-stroke g[stroke]:not([stroke=none]) {
  stroke: #AFC8AF;
}

.ladder_ux_icon__styles__my-pink-no-stroke {
  fill: #CF967E;
}

.ladder_ux_icon__styles__my-pink-no-stroke path[fill]:not([fill=none]) {
  fill: #CF967E;
}

.ladder_ux_icon__styles__my-pink-no-stroke g[fill]:not([fill=none]) {
  fill: #CF967E;
}

.ladder_ux_icon__styles__my-pink-no-stroke path[stroke]:not([stroke=none]) {
  stroke: #CF967E;
}

.ladder_ux_icon__styles__my-pink-no-stroke g[stroke]:not([stroke=none]) {
  stroke: #CF967E;
}

.ladder_ux_icon__styles__cape-cod-no-stroke {
  fill: #464F50;
}

.ladder_ux_icon__styles__cape-cod-no-stroke path[fill]:not([fill=none]) {
  fill: #464F50;
}

.ladder_ux_icon__styles__cape-cod-no-stroke g[fill]:not([fill=none]) {
  fill: #464F50;
}

.ladder_ux_icon__styles__cape-cod-no-stroke path[stroke]:not([stroke=none]) {
  stroke: #464F50;
}

.ladder_ux_icon__styles__cape-cod-no-stroke g[stroke]:not([stroke=none]) {
  stroke: #464F50;
}

.ladder_ux_icon__styles__gray-asparagus-no-stroke {
  fill: #465046;
}

.ladder_ux_icon__styles__gray-asparagus-no-stroke path[fill]:not([fill=none]) {
  fill: #465046;
}

.ladder_ux_icon__styles__gray-asparagus-no-stroke g[fill]:not([fill=none]) {
  fill: #465046;
}

.ladder_ux_icon__styles__gray-asparagus-no-stroke path[stroke]:not([stroke=none]) {
  stroke: #465046;
}

.ladder_ux_icon__styles__gray-asparagus-no-stroke g[stroke]:not([stroke=none]) {
  stroke: #465046;
}

.ladder_ux_icon__styles__gamboge-brown-no-stroke {
  fill: #9B6600;
}

.ladder_ux_icon__styles__gamboge-brown-no-stroke path[fill]:not([fill=none]) {
  fill: #9B6600;
}

.ladder_ux_icon__styles__gamboge-brown-no-stroke g[fill]:not([fill=none]) {
  fill: #9B6600;
}

.ladder_ux_icon__styles__gamboge-brown-no-stroke path[stroke]:not([stroke=none]) {
  stroke: #9B6600;
}

.ladder_ux_icon__styles__gamboge-brown-no-stroke g[stroke]:not([stroke=none]) {
  stroke: #9B6600;
}

.ladder_ux_icon__styles__inherit-no-stroke {
  fill: inherit;
}

.ladder_ux_icon__styles__inherit-no-stroke path[fill]:not([fill=none]) {
  fill: inherit;
}

.ladder_ux_icon__styles__inherit-no-stroke g[fill]:not([fill=none]) {
  fill: inherit;
}

.ladder_ux_icon__styles__inherit-no-stroke path[stroke]:not([stroke=none]) {
  stroke: inherit;
}

.ladder_ux_icon__styles__inherit-no-stroke g[stroke]:not([stroke=none]) {
  stroke: inherit;
}

.ladder_ux_icon__styles__facebook-blue {
  stroke: #3B5998;
  fill: #3B5998;
}

.ladder_ux_icon__styles__facebook-blue path[fill]:not([fill=none]) {
  fill: #3B5998;
}

.ladder_ux_icon__styles__facebook-blue g[fill]:not([fill=none]) {
  fill: #3B5998;
}

.ladder_ux_icon__styles__facebook-blue path[stroke]:not([stroke=none]) {
  stroke: #3B5998;
}

.ladder_ux_icon__styles__facebook-blue g[stroke]:not([stroke=none]) {
  stroke: #3B5998;
}

.ladder_ux_icon__styles__twitter-blue {
  stroke: #00C6FF;
  fill: #00C6FF;
}

.ladder_ux_icon__styles__twitter-blue path[fill]:not([fill=none]) {
  fill: #00C6FF;
}

.ladder_ux_icon__styles__twitter-blue g[fill]:not([fill=none]) {
  fill: #00C6FF;
}

.ladder_ux_icon__styles__twitter-blue path[stroke]:not([stroke=none]) {
  stroke: #00C6FF;
}

.ladder_ux_icon__styles__twitter-blue g[stroke]:not([stroke=none]) {
  stroke: #00C6FF;
}

.ladder_ux_icon__styles__facebook-messenger-blue {
  stroke: #0084FF;
  fill: #0084FF;
}

.ladder_ux_icon__styles__facebook-messenger-blue path[fill]:not([fill=none]) {
  fill: #0084FF;
}

.ladder_ux_icon__styles__facebook-messenger-blue g[fill]:not([fill=none]) {
  fill: #0084FF;
}

.ladder_ux_icon__styles__facebook-messenger-blue path[stroke]:not([stroke=none]) {
  stroke: #0084FF;
}

.ladder_ux_icon__styles__facebook-messenger-blue g[stroke]:not([stroke=none]) {
  stroke: #0084FF;
}

.ladder_ux_icon__styles__whatsapp-green {
  stroke: #25D366;
  fill: #25D366;
}

.ladder_ux_icon__styles__whatsapp-green path[fill]:not([fill=none]) {
  fill: #25D366;
}

.ladder_ux_icon__styles__whatsapp-green g[fill]:not([fill=none]) {
  fill: #25D366;
}

.ladder_ux_icon__styles__whatsapp-green path[stroke]:not([stroke=none]) {
  stroke: #25D366;
}

.ladder_ux_icon__styles__whatsapp-green g[stroke]:not([stroke=none]) {
  stroke: #25D366;
}

.ladder_ux_icon__styles__geico-blue {
  stroke: #00529A;
  fill: #00529A;
}

.ladder_ux_icon__styles__geico-blue path[fill]:not([fill=none]) {
  fill: #00529A;
}

.ladder_ux_icon__styles__geico-blue g[fill]:not([fill=none]) {
  fill: #00529A;
}

.ladder_ux_icon__styles__geico-blue path[stroke]:not([stroke=none]) {
  stroke: #00529A;
}

.ladder_ux_icon__styles__geico-blue g[stroke]:not([stroke=none]) {
  stroke: #00529A;
}

.ladder_ux_icon__styles__robinhood-dark-green {
  stroke: #002C15;
  fill: #002C15;
}

.ladder_ux_icon__styles__robinhood-dark-green path[fill]:not([fill=none]) {
  fill: #002C15;
}

.ladder_ux_icon__styles__robinhood-dark-green g[fill]:not([fill=none]) {
  fill: #002C15;
}

.ladder_ux_icon__styles__robinhood-dark-green path[stroke]:not([stroke=none]) {
  stroke: #002C15;
}

.ladder_ux_icon__styles__robinhood-dark-green g[stroke]:not([stroke=none]) {
  stroke: #002C15;
}
.ladder_ux_carousel__control-dots-styles__root {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  margin-top: 10px;
}

.ladder_ux_carousel__control-dots-styles__control-dot {
  width: 10px;
  height: 10px;
  margin: 5px;
  border-radius: 50%;
  background-color: #D0D0D0;
  user-select: none;
  z-index: 1;
}

.ladder_ux_carousel__control-dots-styles__control-dot:hover {
  cursor: pointer;
}

.ladder_ux_carousel__control-dots-styles__control-dot-active {
  background-color: #000000;
}
.ladder_financial_advisors_home__satisfied-clients-trustpilot-stars-styles__\%>svg {
  width: 75%;
  max-width: 200px;
}
.ladder_account_next_views_messages__message-entry-styles__body {
  white-space: pre-wrap;
}
.ladder_advisor_only_standalone_quoter__form-group-styles__root {
  display: flex;
  flex-direction: column;
  width: 412px;
  padding: 0 30.0px;
}
.ladder_guide_life_insurance_cost__picture-panel-no-quote-styles__container {
  width: 100%;
  margin-top: 60px;
  position: relative;
  background-color: #BBDAD7;
}

@media (min-width: 1025px) {

  
  
  .ladder_guide_life_insurance_cost__picture-panel-no-quote-styles__image {
    width: 90%;
  }
  
  .ladder_guide_life_insurance_cost__picture-panel-no-quote-styles__container {
    padding-top: 4%;
  }
  
  .ladder_guide_life_insurance_cost__picture-panel-no-quote-styles__image {
    margin-bottom: -3px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_guide_life_insurance_cost__picture-panel-no-quote-styles__container {
    padding-top: 12%;
    min-height: 500px;
  }
  
  .ladder_guide_life_insurance_cost__picture-panel-no-quote-styles__image {
    width: 70%;
    position: absolute;
    bottom: 0;
    left: -26px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_guide_life_insurance_cost__picture-panel-no-quote-styles__container {
    padding-top: 60px;
    padding-bottom: 60%;
  }
  
  .ladder_guide_life_insurance_cost__picture-panel-no-quote-styles__image {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

}
.ladder_guide_life_insurance_guide__TwoColumnPanel-styles__image-container {
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  overflow: visible;
  align-items: flex-end;
  flex-basis: 40%;
}

.ladder_guide_life_insurance_guide__TwoColumnPanel-styles__white-background {
  background-color: #FFFFFF;
}

.ladder_guide_life_insurance_guide__TwoColumnPanel-styles__tan-background {
  background-color: #EFEAE1;
}

.ladder_guide_life_insurance_guide__TwoColumnPanel-styles__container {
  display: flex;
  margin-top: 130.0px;
  overflow: visible;
  justify-content: space-around;
}

.ladder_guide_life_insurance_guide__TwoColumnPanel-styles__align-image {
  align-self: flex-start;
}

.ladder_guide_life_insurance_guide__TwoColumnPanel-styles__overflow-image {
  margin-bottom: -30px;
}

.ladder_guide_life_insurance_guide__TwoColumnPanel-styles__action-box {
  flex-basis: 40%;
  margin-bottom: 30.0px;
}

.ladder_guide_life_insurance_guide__TwoColumnPanel-styles__text {
  margin: 10.0px 0 30.0px 0;
}

.ladder_guide_life_insurance_guide__TwoColumnPanel-styles__small-heading {
  margin-bottom: 20px;
}

@media (min-width: 1025px) {

  
  
  .ladder_guide_life_insurance_guide__TwoColumnPanel-styles__overflow-image {
    margin-top: 50px;
  }
  
  .ladder_guide_life_insurance_guide__TwoColumnPanel-styles__bike-image-top-margin {
    margin-top: 50.0px;
  }
  
  .ladder_guide_life_insurance_guide__TwoColumnPanel-styles__image {
    width: 400px;
  }
  
  .ladder_guide_life_insurance_guide__TwoColumnPanel-styles__image-right-margin {
    margin-right: 15%;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_guide_life_insurance_guide__TwoColumnPanel-styles__read-text-container {
    margin-left: 20px;
    vertical-align: center;
  }
  
  .ladder_guide_life_insurance_guide__TwoColumnPanel-styles__text {
    margin: 20px auto;
  }
  
  .ladder_guide_life_insurance_guide__TwoColumnPanel-styles__summary-container {
    padding-top: 20px;
    padding-bottom: 30.0px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_guide_life_insurance_guide__TwoColumnPanel-styles__read-text-container {
    margin-left: 20px;
    vertical-align: center;
  }
  
  .ladder_guide_life_insurance_guide__TwoColumnPanel-styles__text {
    margin: 20px auto;
  }
  
  .ladder_guide_life_insurance_guide__TwoColumnPanel-styles__summary-container {
    padding-top: 20px;
    padding-bottom: 30.0px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_guide_life_insurance_guide__TwoColumnPanel-styles__container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  
  .ladder_guide_life_insurance_guide__TwoColumnPanel-styles__color-container {
    width: 100%;
    overflow: visible;
  }
  
  .ladder_guide_life_insurance_guide__TwoColumnPanel-styles__image-container {
    flex-basis: unset;
  }
  
  .ladder_guide_life_insurance_guide__TwoColumnPanel-styles__action-box {
    flex-direction: column;
    flex-basis: unset;
    padding-right: 0;
    max-width: 80%;
    margin: 0;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_guide_life_insurance_guide__TwoColumnPanel-styles__container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  
  .ladder_guide_life_insurance_guide__TwoColumnPanel-styles__color-container {
    width: 100%;
    overflow: visible;
  }
  
  .ladder_guide_life_insurance_guide__TwoColumnPanel-styles__image-container {
    flex-basis: unset;
  }
  
  .ladder_guide_life_insurance_guide__TwoColumnPanel-styles__action-box {
    flex-direction: column;
    flex-basis: unset;
    padding-right: 0;
    max-width: 80%;
    margin: 0;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_guide_life_insurance_guide__TwoColumnPanel-styles__align-image {
    align-self: flex-end;
  }
  
  .ladder_guide_life_insurance_guide__TwoColumnPanel-styles__container {
    margin-top: 60px;
  }
  
  .ladder_guide_life_insurance_guide__TwoColumnPanel-styles__image {
    width: 320px;
    margin-right: 130.0px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_guide_life_insurance_guide__TwoColumnPanel-styles__right-side-image {
    position: absolute;
    right: 32px;
    max-height: 64px;
    max-width: 65px;
  }
  
  .ladder_guide_life_insurance_guide__TwoColumnPanel-styles__right-side-image-container {
    position: relative;
    margin-bottom: 60px;
  }
  
  .ladder_guide_life_insurance_guide__TwoColumnPanel-styles__overflow-container {
    margin-bottom: -16px;
  }
  
  .ladder_guide_life_insurance_guide__TwoColumnPanel-styles__color-container {
    width: 100%;
    overflow: visible;
  }
  
  .ladder_guide_life_insurance_guide__TwoColumnPanel-styles__button-container {
    margin-top: 20px;
  }
  
  .ladder_guide_life_insurance_guide__TwoColumnPanel-styles__read-text-container {
    vertical-align: top;
  }
  
  .ladder_guide_life_insurance_guide__TwoColumnPanel-styles__image-container {
    margin-left: 30.0px;
    display: flex;
    justify-content: left;
    margin-top: 30.0px;
    margin-bottom: 30.0px;
  }
  
  .ladder_guide_life_insurance_guide__TwoColumnPanel-styles__action-box {
    width: 100%;
    margin-left: 30.0px;
    margin-right: 30.0px;
  }
  
  .ladder_guide_life_insurance_guide__TwoColumnPanel-styles__heading {
    margin: 10.0px 0;
  }
  
  .ladder_guide_life_insurance_guide__TwoColumnPanel-styles__smaller-heading {
    width: 65%;
  }
  
  .ladder_guide_life_insurance_guide__TwoColumnPanel-styles__text {
    margin-top: 10.0px;
    margin-bottom: 20px;
  }
  
  .ladder_guide_life_insurance_guide__TwoColumnPanel-styles__image {
    width: 35%;
    max-width: unset;
  }

}
.ladder_landing_pages_trustage__main-image-styles__image-container {
  z-index: -1;
}

.ladder_landing_pages_trustage__main-image-styles__image {
  width: 100%;
  right: 0;
  max-width: 1500px;
  height: auto;
  position: absolute;
  object-fit: contain;
  min-height: 570px;
  object-position: right;
  display: block;
  bottom: 0;
  max-height: 690px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_landing_pages_trustage__main-image-styles__image {
    display: none;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_landing_pages_trustage__main-image-styles__image {
    display: none;
  }

}
@media (max-width: 499px) {

  
  
  .ladder_onboarding_referrals__Panel1Updated-styles__header {
    font-size: 30.0px;
    line-height: 40px;
  }
  
  .ladder_onboarding_referrals__Panel1Updated-styles__lrpadding {
    padding: 0 25.0px;
  }

}
.ladder_ux_spinner__rungs-spinner-styles__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.ladder_ux_spinner__rungs-spinner-styles__spinner {
  width: 81%;
  height: 81%;
  max-width: 50px;
  fill: transparent;
  stroke-width: 8;
  stroke-dasharray: 290;
}

.ladder_ux_spinner__rungs-spinner-styles__xs {
  height: 25px;
}

.ladder_ux_spinner__rungs-spinner-styles__xs>.ladder_ux_spinner__rungs-spinner-styles__spinner {
  width: 100%;
  height: 100%;
}

.ladder_ux_spinner__rungs-spinner-styles__small {
  height: 34px;
}

.ladder_ux_spinner__rungs-spinner-styles__medium {
  height: 50px;
}

.ladder_ux_spinner__rungs-spinner-styles__large {
  height: 100px;
}

.ladder_ux_spinner__rungs-spinner-styles__large>.ladder_ux_spinner__rungs-spinner-styles__spinner {
  max-width: 100px;
}

.ladder_ux_spinner__rungs-spinner-styles__dot {
  animation: 1.8s ease-in-out infinite both variable-length-spinner;
  transform-origin: 50% 50%;
}

.ladder_ux_spinner__rungs-spinner-styles__ring {
  stroke-opacity: 0.3;
}
.ladder_advisor_only_standalone_quoter__quote-result-styles__quote-result-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.ladder_advisor_only_standalone_quoter__quote-result-styles__reset-link-container {
  padding-top: 30px;
}

.ladder_advisor_only_standalone_quoter__quote-result-styles__per-month {
  margin-bottom: 10px;
}

.ladder_advisor_only_standalone_quoter__quote-result-styles__quote-price-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.ladder_advisor_only_standalone_quoter__quote-result-styles__dollar-sign {
  align-self: flex-start;
}
.ladder_ux_info_panel__info-panel-styles__root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0;
  width: 100%;
}

.ladder_ux_info_panel__info-panel-styles__position-relative {
  position: relative;
}

.ladder_ux_info_panel__info-panel-styles__root-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 1100px;
}

.ladder_ux_info_panel__info-panel-styles__root-content>* {
  margin-left: auto;
  margin-right: auto;
}

.ladder_ux_info_panel__info-panel-styles__root-content-no-margin {

}

.ladder_ux_info_panel__info-panel-styles__root-content-no-margin>* {
  margin-left: initial;
  margin-right: initial;
}

.ladder_ux_info_panel__info-panel-styles__root-content-align-left {

}

.ladder_ux_info_panel__info-panel-styles__root-content-align-left>* {
  margin-left: initial;
  margin-right: auto;
}

.ladder_ux_info_panel__info-panel-styles__width-full {
  max-width: none;
  width: 100%;
}

.ladder_ux_info_panel__info-panel-styles__width-l {
  max-width: 1380px;
}

.ladder_ux_info_panel__info-panel-styles__width-sm {
  max-width: 960px;
}

.ladder_ux_info_panel__info-panel-styles__width-ssm {
  max-width: 787px;
}

.ladder_ux_info_panel__info-panel-styles__width-s {
  max-width: 700px;
}

.ladder_ux_info_panel__info-panel-styles__width-xs {
  max-width: 600px;
}

.ladder_ux_info_panel__info-panel-styles__bottom-border {
  border-bottom: 1px solid #D0D0D0;
}

.ladder_ux_info_panel__info-panel-styles__top-border {
  border-top: 1px solid #D0D0D0;
}

.ladder_ux_info_panel__info-panel-styles__background-color-geyser {
  background-color: #CFDCDE;
}

.ladder_ux_info_panel__info-panel-styles__background-color-gray {
  background-color: #F4F4F4;
}

.ladder_ux_info_panel__info-panel-styles__background-color-white {
  background-color: #FFFFFF;
}

.ladder_ux_info_panel__info-panel-styles__background-color-mystic {
  background-color: #E7EEEF;
}

.ladder_ux_info_panel__info-panel-styles__background-color-opal {
  background-color: #AFC5C8;
}

.ladder_ux_info_panel__info-panel-styles__background-color-spring-wood {
  background-color: #F7F2EF;
}

.ladder_ux_info_panel__info-panel-styles__background-color-spring-rain {
  background-color: #AFC8AF;
}

.ladder_ux_info_panel__info-panel-styles__background-color-aqua-squeeze {
  background-color: #DBE6DB;
}

.ladder_ux_info_panel__info-panel-styles__background-color-seashell {
  background-color: #F1F1F1;
}

.ladder_ux_info_panel__info-panel-styles__height-none {
  padding: 0;
}

.ladder_ux_info_panel__info-panel-styles__height-s {
  padding: 20px 0;
}

.ladder_ux_info_panel__info-panel-styles__height-m {
  padding: 30.0px 0;
}

.ladder_ux_info_panel__info-panel-styles__height-l {
  padding: 130.0px 0;
}

.ladder_ux_info_panel__info-panel-styles__full-height {
  flex-basis: 100%;
}

.ladder_ux_info_panel__info-panel-styles__align-left {
  text-align: left;
}

.ladder_ux_info_panel__info-panel-styles__align-center {
  text-align: center;
}

.ladder_ux_info_panel__info-panel-styles__grow {
  flex-grow: 1;
}

.ladder_ux_info_panel__info-panel-styles__ninety-percent-left {
  width: 90%;
  align-items: flex-start;
}

@media (min-width: 1025px) {

  
  
  .ladder_ux_info_panel__info-panel-styles__desktop-full-width {
    width: 100%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_info_panel__info-panel-styles__root {
    padding: 30.0px 0;
  }
  
  .ladder_ux_info_panel__info-panel-styles__height-none {
    padding: 0;
  }
  
  .ladder_ux_info_panel__info-panel-styles__tablet-full-width {
    width: 100%;
  }
  
  .ladder_ux_info_panel__info-panel-styles__height-s {
    padding: 20px 0;
  }
  
  .ladder_ux_info_panel__info-panel-styles__height-l {
    padding: 60px 0;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_info_panel__info-panel-styles__root {
    padding: 20px 0;
  }
  
  .ladder_ux_info_panel__info-panel-styles__height-none {
    padding: 0;
  }
  
  .ladder_ux_info_panel__info-panel-styles__mobile-full-width {
    width: 100%;
  }
  
  .ladder_ux_info_panel__info-panel-styles__height-s {
    padding: 10.0px 0;
  }
  
  .ladder_ux_info_panel__info-panel-styles__height-l {
    padding: 60px 0;
  }

}
.ladder_agents_agent_assisted_app_views_review__additional-information-section-styles__assessment-factor-group {
  margin-top: 30.0px;
  width: 100%;
}

.ladder_agents_agent_assisted_app_views_review__additional-information-section-styles__title {
  padding-left: 10.0px;
  text-align: left;
}
.ladder_views_decision_hurdle__diversion-styles__root {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 100%;
}

.ladder_views_decision_hurdle__diversion-styles__horizontal-container {
  display: flex;
  width: 100%;
}

.ladder_views_decision_hurdle__diversion-styles__image-container {
  width: 300px;
}

.ladder_views_decision_hurdle__diversion-styles__image {
  max-width: 400px;
  width: 60%;
}

.ladder_views_decision_hurdle__diversion-styles__sidebar-bg {
  width: 40%;
  background-color: #F7F2EF;
}

.ladder_views_decision_hurdle__diversion-styles__sidebar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 260px 0 130.0px;
}

.ladder_views_decision_hurdle__diversion-styles__diversion-container {
  display: flex;
  padding: 28px 130.0px 20px;
  flex-direction: column;
  align-items: flex-start;
  width: 60%;
  background-color: #FFFFFF;
}

.ladder_views_decision_hurdle__diversion-styles__diversion-container-sub {
  max-width: 600px;
}

@media (max-width: 499px) {

  
  
  .ladder_views_decision_hurdle__diversion-styles__sidebar-bg {
    display: none;
  }
  
  .ladder_views_decision_hurdle__diversion-styles__diversion-container {
    width: 100%;
    padding: 20px 8px 0;
  }

}
.ladder_views_decision_common__poca-sliders-styles__section {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  text-align: center;
}

.ladder_views_decision_common__poca-sliders-styles__no-afford-warning {
  margin-bottom: 20px;
}

.ladder_views_decision_common__poca-sliders-styles__slider-contain {
  margin: 10.0px 0 10.0px;
  width: 100%;
}
.ladder_financial_advisors_home__allianz-body-styles__\% {
  margin-top: 20px;
}

@media (min-width: 1025px) {

  
  
  .ladder_financial_advisors_home__allianz-body-styles__\% {
    margin-top: 0;
  }

}
.ladder_views_decision_tic_offer__tic-offer-styles__agreement-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ladder_views_decision_tic_offer__tic-offer-styles__read-agreement-text {
  text-align: left;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ladder_views_decision_tic_offer__tic-offer-styles__checkbox-container {
  padding: 8.0px 20.0px 8.0px 8.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_views_decision_tic_offer__tic-offer-styles__agreement-container {
    padding-bottom: 32px;
  }

}
.ladder_account_partners__partner-logo-styles__partner-logo {
  height: 60px;
}

.ladder_account_partners__partner-logo-styles__border {
  border: 1px solid #D0D0D0;
  padding: 10.0px;
}
.ladder_form_address__root-styles__root {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
}

.ladder_form_address__root-styles__city {
  width: 8em;
  flex-grow: 1;
}

.ladder_form_address__root-styles__state {
  width: calc(13em - 10px);
  flex-grow: 1;
  margin-right: 10px;
}

.ladder_form_address__root-styles__zip {
  width: 5em;
  flex-grow: 1;
}

.ladder_form_address__root-styles__rungs .ladder_form_address__root-styles__city {
  width: 100%;
}

.ladder_form_address__root-styles__scrollable .ladder_form_address__root-styles__city {
  width: 100%;
}

.ladder_form_address__root-styles__scrollable .ladder_form_address__root-styles__state {
  width: 100%;
}

.ladder_form_address__root-styles__scrollable .ladder_form_address__root-styles__zip {
  width: 100%;
}
.ladder_ux_panels__homepage-cta-styles__root {
  display: block;
}

.ladder_ux_panels__homepage-cta-styles__button {
  display: inline-block;
  margin-left: 16px;
  width: 540px;
}

.ladder_ux_panels__homepage-cta-styles__button:first-child {
  margin-left: 0;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_panels__homepage-cta-styles__button {
    width: 100%;
    margin-left: 0;
    margin-top: 16px;
  }
  
  .ladder_ux_panels__homepage-cta-styles__button:first-child {
    margin-top: 0;
  }

}
.ladder_account_next_views_secondary_addressee_view__ViewSecondaryAddressee-styles__description {
  margin-top: 15.0px;
}

.ladder_account_next_views_secondary_addressee_view__ViewSecondaryAddressee-styles__\% {
  margin-bottom: 60px;
}

.ladder_account_next_views_secondary_addressee_view__ViewSecondaryAddressee-styles__card {
  margin-top: 30.0px;
}
.ladder_view_fluidless__Root-styles__buttons-container {
  display: flex;
  align-items: center;
  padding: 10.0px;
  justify-content: center;
}

.ladder_view_fluidless__Root-styles__horizontal-container {
  display: flex;
  width: 100%;
}

.ladder_view_fluidless__Root-styles__image {
  max-width: 400px;
  width: 60%;
}

.ladder_view_fluidless__Root-styles__image-priority-lane {
  max-width: 400px;
  width: 55%;
}

.ladder_view_fluidless__Root-styles__left-bg {
  width: 40%;
  background-color: #F7F2EF;
}

.ladder_view_fluidless__Root-styles__left-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 28px 0 260px;
}

.ladder_view_fluidless__Root-styles__right-container {
  display: flex;
  padding: 28px 130.0px 260px;
  flex-direction: column;
  align-items: flex-start;
  width: 60%;
  background-color: #FFFFFF;
}

.ladder_view_fluidless__Root-styles__right-container-sub {
  max-width: 600px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_view_fluidless__Root-styles__horizontal-container {
    flex-direction: column;
    width: 100%;
  }
  
  .ladder_view_fluidless__Root-styles__text {
    width: 45%;
  }
  
  .ladder_view_fluidless__Root-styles__image {
    max-width: 200px;
    width: 45%;
  }
  
  .ladder_view_fluidless__Root-styles__image-priority-lane {
    max-width: 180px;
    width: 45%;
  }
  
  .ladder_view_fluidless__Root-styles__left-bg {
    width: 100%;
  }
  
  .ladder_view_fluidless__Root-styles__left-container {
    flex-direction: row;
    justify-content: space-between;
    padding: 5.0px 30.0px 20px;
  }
  
  .ladder_view_fluidless__Root-styles__right-container {
    width: 100%;
    align-items: center;
    padding: 20px 30.0px 60px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_view_fluidless__Root-styles__horizontal-container {
    flex-direction: column;
    width: 100%;
  }
  
  .ladder_view_fluidless__Root-styles__text {
    width: 45%;
  }
  
  .ladder_view_fluidless__Root-styles__image {
    max-width: 200px;
    width: 45%;
  }
  
  .ladder_view_fluidless__Root-styles__image-priority-lane {
    max-width: 180px;
    width: 45%;
  }
  
  .ladder_view_fluidless__Root-styles__left-bg {
    width: 100%;
  }
  
  .ladder_view_fluidless__Root-styles__left-container {
    flex-direction: row;
    justify-content: space-between;
    padding: 5.0px 30.0px 20px;
  }
  
  .ladder_view_fluidless__Root-styles__right-container {
    width: 100%;
    align-items: center;
    padding: 20px 30.0px 60px;
  }

}
.ladder_account_payment_history__rungs-payment-history-row-styles__payment-row {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #D0D0D0;
  padding: 22.5px 0;
}

.ladder_account_payment_history__rungs-payment-history-row-styles__payment-date {
  width: 18%;
  padding: 0 10.0px;
}

.ladder_account_payment_history__rungs-payment-history-row-styles__payment-status {
  width: 12%;
  min-width: 60px;
  padding: 0 10.0px;
}

.ladder_account_payment_history__rungs-payment-history-row-styles__payment-details {
  width: 60%;
  padding: 0 10.0px;
}

.ladder_account_payment_history__rungs-payment-history-row-styles__payment-amount {
  width: 15%;
  padding: 0 10.0px;
  text-align: right;
}

@media (max-width: 499px) {

  
  
  .ladder_account_payment_history__rungs-payment-history-row-styles__payment-row {
    flex-direction: column;
    align-items: space-between;
    border-bottom: 0;
    padding: 10.0px 0;
    border-top: 1px solid #D0D0D0;
  }
  
  .ladder_account_payment_history__rungs-payment-history-row-styles__payment-row >* {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 10.0px 0;
  }

}
.ladder_ux_expand_card__account-expand-card-styles__root {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.ladder_ux_expand_card__account-expand-card-styles__border-bottom:last-child {
  border-bottom: 1px solid #D0D0D0;
}

.ladder_ux_expand_card__account-expand-card-styles__border-top {
  border-top: 1px solid #D0D0D0;
}

.ladder_ux_expand_card__account-expand-card-styles__title-row {
  text-align: left;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding: 12.0px 0;
}

.ladder_ux_expand_card__account-expand-card-styles__no-on-click-expand {
  cursor: default;
}

.ladder_ux_expand_card__account-expand-card-styles__caption-detail {
  margin-left: auto;
}

.ladder_ux_expand_card__account-expand-card-styles__title-icon-wrapper {
  display: flex;
  align-items: center;
}

.ladder_ux_expand_card__account-expand-card-styles__rebranding-title-row {
  display: flex;
  margin-left: 2px;
}

.ladder_ux_expand_card__account-expand-card-styles__rebranding-title-icon-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.ladder_ux_expand_card__account-expand-card-styles__icon {
  width: 20.0px;
  height: 20.0px;
  margin: 16px;
}

.ladder_ux_expand_card__account-expand-card-styles__status-icon {
  margin: 0 8.0px;
}

.ladder_ux_expand_card__account-expand-card-styles__padded {
  margin: 0 64px;
}

.ladder_ux_expand_card__account-expand-card-styles__fully-expanded {

}

.ladder_ux_expand_card__account-expand-card-styles__description {
  padding-bottom: 16px;
}

.ladder_ux_expand_card__account-expand-card-styles__expanded {
  padding: 0 16px 16px 56.0px;
}

.ladder_ux_expand_card__account-expand-card-styles__hide-plus-minus-icon {
  padding-left: 16px;
}

.ladder_ux_expand_card__account-expand-card-styles__expanded-wide {
  padding: 0 8.0px 8.0px 20.0px;
}

.ladder_ux_expand_card__account-expand-card-styles__expanded-centered {
  padding: 0 16px 16px 16px;
}

.ladder_ux_expand_card__account-expand-card-styles__no-padding-bottom {
  padding-bottom: 0;
}

.ladder_ux_expand_card__account-expand-card-styles__info-expander-container {
  margin-left: 8.0px;
}

.ladder_ux_expand_card__account-expand-card-styles__medium-caption-detail {
  font-size: 150%;
  padding-right: 30px;
}

.ladder_ux_expand_card__account-expand-card-styles__large-caption-detail {
  font-size: 175%;
  padding-right: 30px;
}

.ladder_ux_expand_card__account-expand-card-styles__text-wrapper {
  display: flex;
}

.ladder_ux_expand_card__account-expand-card-styles__rebranding-padding {
  padding: 0;
}

.ladder_ux_expand_card__account-expand-card-styles__rebranding-line {
  padding: 16px;
  width: 100%;
  border-bottom: 1px solid #D0D0D0;
}

.ladder_ux_expand_card__account-expand-card-styles__icon-container {
  display: flex;
  align-items: center;
  gap: 5;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_expand_card__account-expand-card-styles__icon {
    margin: 8.0px;
  }
  
  .ladder_ux_expand_card__account-expand-card-styles__title-wrapper {
    padding: 8.0px 0;
  }
  
  .ladder_ux_expand_card__account-expand-card-styles__expanded {
    padding: 0 16px 16px 40.0px;
  }
  
  .ladder_ux_expand_card__account-expand-card-styles__hide-plus-minus-icon {
    padding-left: 0;
  }
  
  .ladder_ux_expand_card__account-expand-card-styles__expanded-wide {
    padding: 0 8.0px 8.0px 20.0px;
  }
  
  .ladder_ux_expand_card__account-expand-card-styles__expanded-centered {
    padding: 0 16px 16px 16px;
  }
  
  .ladder_ux_expand_card__account-expand-card-styles__padded {
    margin: 0 16px;
  }
  
  .ladder_ux_expand_card__account-expand-card-styles__rebranding-padding {
    padding: 0;
  }
  
  .ladder_ux_expand_card__account-expand-card-styles__text-wrapper {
    margin-right: 4px;
  }
  
  .ladder_ux_expand_card__account-expand-card-styles__rebranding-title-row {
    padding: 8.0px 4px;
  }

}
.ladder_markdoc_common__code-comp-styles__code {
  background: rgba(175,184,193,0.2);
  padding: 0.2em 0.4em;
  border-radius: 6px;
  overflow: scroll;
}
.ladder_content__lux-content-container-styles__root {
  text-align: left;
  width: 100%;
}

.ladder_content__lux-content-container-styles__root hr {
  color: #D0D0D0;
  margin-bottom: 30.0px;
  margin-top: 30.0px;
}

.ladder_content__lux-content-container-styles__top {
  text-align: center;
}
.ladder_agents_agent_assisted_app_views_offer__gift-blurb-styles__link-hidden {
  visibility: hidden;
}
.ladder_form_quick_estimate__root-styles__single-page-group {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.box-fields .ladder_form_quick_estimate__root-styles__single-page-group {
  width: 60%;
}

@media (max-width: 499px) {

  
  
  .ladder_form_quick_estimate__root-styles__single-page-group {
    width: 95%;
  }
  
  
  
  .box-fields .ladder_form_quick_estimate__root-styles__single-page-group {
    width: 100%;
  }

}
.ladder_views_decision_offer__rungs-offer-styles__wrapper {
  width: 100%;
  color: black;
  background-color: #F7F2EF;
  display: flex;
  justify-content: center;
}

.ladder_views_decision_offer__rungs-offer-styles__content-wrapper {
  max-width: 1400px;
  width: 70%;
  z-index: 2;
  display: flex;
  justify-content: space-between;
}

.ladder_views_decision_offer__rungs-offer-styles__content {
  padding: 120px 0;
  width: 50%;
  display: flex;
  flex-direction: column;
}

.ladder_views_decision_offer__rungs-offer-styles__card {
  width: 35%;
  margin: 20px 0;
  margin-bottom: 68px;
  z-index: 1;
}

.ladder_views_decision_offer__rungs-offer-styles__best-price-got-it {
  text-align: center;
  margin-top: 2em;
}

.ladder_views_decision_offer__rungs-offer-styles__confetti {
  position: absolute;
  top: 0;
  background-image: url(../img/confetti.png);
  background-size: contain;
  background-repeat: repeat-x;
  height: 200px;
  width: 100%;
}

.ladder_views_decision_offer__rungs-offer-styles__underline {
  text-decoration: underline;
}

.ladder_views_decision_offer__rungs-offer-styles__expiration {
  margin-top: 20px;
}

.ladder_views_decision_offer__rungs-offer-styles__before-image {
  padding-bottom: 20px;
}

.ladder_views_decision_offer__rungs-offer-styles__poca-overlay {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 14;
}

.ladder_views_decision_offer__rungs-offer-styles__modal-z {
  z-index: 14;
}

@media (max-width: 499px) {

  
  
  .ladder_views_decision_offer__rungs-offer-styles__wrapper {
    flex-direction: column;
    background-size: 200%;
  }
  
  .ladder_views_decision_offer__rungs-offer-styles__content-wrapper {
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
    width: 100%;
  }
  
  .ladder_views_decision_offer__rungs-offer-styles__content {
    width: 86%;
    padding-top: 0px;
    padding-bottom: 10px;
  }
  
  .ladder_views_decision_offer__rungs-offer-styles__card {
    width: 86%;
    padding: 10px 0;
  }
  
  .ladder_views_decision_offer__rungs-offer-styles__confetti {
    height: 80px;
    position: relative;
    background-size: cover;
  }
  
  .ladder_views_decision_offer__rungs-offer-styles__confetti-split-offer {
    position: absolute;
    opacity: 0.5;
  }
  
  .ladder_views_decision_offer__rungs-offer-styles__h1-split-offer {
    margin-top: 24px;
    z-index: 1;
  }
  
  .ladder_views_decision_offer__rungs-offer-styles__card-split-offer {
    margin-top: 0px;
    margin-bottom: 20px;
    padding-top: 0px;
  }
  
  .ladder_views_decision_offer__rungs-offer-styles__reach-out-card {
    width: 86%;
    align-self: center;
    margin-bottom: 24px;
  }
  
  .ladder_views_decision_offer__rungs-offer-styles__gift-disclaimer {
    width: 86%;
    bottom: 20px;
  }

}
.ladder_agents_dashboard_pre_screener__question-styles__container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.ladder_agents_dashboard_pre_screener__question-styles__height-weight-answer-row {
  display: flex;
  flex-direction: row;
}

@media (min-width: 1025px) {

  
  
  .ladder_agents_dashboard_pre_screener__question-styles__height-weight-answer-row {
    gap: 24px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard_pre_screener__question-styles__height-weight-answer-row {
    justify-content: space-between;
    gap: 5px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_dashboard_pre_screener__question-styles__height-weight-answer-row {
    justify-content: space-between;
    gap: 5px;
  }

}

.ladder_agents_dashboard_pre_screener__question-styles__question-row {
  display: flex;
}
.ladder_onboarding_referrals__item-container-styles__root {
  display: flex;
  flex-direction: row-reverse;
  flex-shrink: 0;
}
.ladder_claims__notification-form-styles__form-container {
  background-color: #FFFFFF;
  width: 100%;
  padding: 22.5px;
  margin-top: 50.0px;
  border: 1px solid #000000;
}

.ladder_claims__notification-form-styles__form-header {
  margin-bottom: 20px;
}

.ladder_claims__notification-form-styles__form-row {
  display: flex;
  width: 100%;
  gap: 20px;
  margin-bottom: 20px;
}

.ladder_claims__notification-form-styles__form-item {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ladder_claims__notification-form-styles__button-container {
  margin-left: auto;
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: flex-end;
}

.ladder_claims__notification-form-styles__form-button {
  min-width: 0px;
}

@media (max-width: 499px) {

  
  
  .ladder_claims__notification-form-styles__form-row {
    flex-direction: column;
  }
  
  .ladder_claims__notification-form-styles__button-container {
    display: flex;
    flex-direction: column-reverse;
    margin-left: 0;
  }

}
.ladder_account_next_cards_refer_link__ReferLinkCard-styles__\% {
  background-color: #E7EEEF;
  border-radius: 8px;
  box-sizing: border-box;
  min-height: 220px;
}

.ladder_account_next_cards_refer_link__ReferLinkCard-styles__referral-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ladder_account_next_cards_refer_link__ReferLinkCard-styles__more-info-link {
  color: #975C46;
  weight: 400;
  font-size: 15.0px;
}

@media (min-width: 1025px) {

  
  
  .ladder_account_next_cards_refer_link__ReferLinkCard-styles__more-info-link {
    margin-right: 30.0px;
    margin-bottom: 10.0px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account_next_cards_refer_link__ReferLinkCard-styles__more-info-link {
    margin-top: 10.0px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_account_next_cards_refer_link__ReferLinkCard-styles__\% {
    display: flex;
    padding: 0 30.0px;
  }
  
  .ladder_account_next_cards_refer_link__ReferLinkCard-styles__left {
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 25.0px 0;
    flex-grow: 1;
  }
  
  .ladder_account_next_cards_refer_link__ReferLinkCard-styles__right {
    display: flex;
    margin-left: 20px;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
    align-items: flex-end;
  }
  
  .ladder_account_next_cards_refer_link__ReferLinkCard-styles__referral-header {
    margin-top: 10.0px;
  }
  
  .ladder_account_next_cards_refer_link__ReferLinkCard-styles__referral-content {
    margin-top: 20px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account_next_cards_refer_link__ReferLinkCard-styles__\% {
    display: flex;
    padding: 0 30.0px;
  }
  
  .ladder_account_next_cards_refer_link__ReferLinkCard-styles__left {
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 25.0px 0;
    flex-grow: 1;
  }
  
  .ladder_account_next_cards_refer_link__ReferLinkCard-styles__right {
    display: flex;
    margin-left: 20px;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
    align-items: flex-end;
  }
  
  .ladder_account_next_cards_refer_link__ReferLinkCard-styles__referral-header {
    margin-top: 10.0px;
  }
  
  .ladder_account_next_cards_refer_link__ReferLinkCard-styles__referral-content {
    margin-top: 20px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_account_next_cards_refer_link__ReferLinkCard-styles__\% {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 16px 0px;
    padding: 20px 22.5px;
  }
  
  .ladder_account_next_cards_refer_link__ReferLinkCard-styles__referral-header {
    display: none;
  }
  
  .ladder_account_next_cards_refer_link__ReferLinkCard-styles__right {
    display: flex;
    justify-content: center;
  }
  
  .ladder_account_next_cards_refer_link__ReferLinkCard-styles__more-info-link {
    margin: 0 auto;
  }
  
  .ladder_account_next_cards_refer_link__ReferLinkCard-styles__referral-content {
    margin-top: 30px;
  }

}
.ladder_ux_carousel__control-arrow-styles__control-arrow {
  position: absolute;
  top: 50%;
  display: flex;
  z-index: 2;
  width: 30px;
  background-color: #F1F1F1;
  border-radius: 50%;
  padding: 8px;
}

.ladder_ux_carousel__control-arrow-styles__control-arrow:hover {
  cursor: pointer;
}

.ladder_ux_carousel__control-arrow-styles__control-arrow svg {
  fill: #707070;
}

.ladder_ux_carousel__control-arrow-styles__previous {
  left: 0;
  padding-left: 6px;
  padding-right: 10px;
}

.ladder_ux_carousel__control-arrow-styles__next {
  right: 0;
  padding-left: 10px;
  padding-right: 6px;
}
.ladder_agents_dashboard_uxcommon__contact-button-with-icon-styles__quoter-button-text {
  font-size: 15px;
}

.ladder_agents_dashboard_uxcommon__contact-button-with-icon-styles__button {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  align-items: center;
}
.ladder_adjust_offer__quote-loading-styles__root {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60.0px;
}

.ladder_adjust_offer__quote-loading-styles__spinner {
  animation: spin 1s linear infinite;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_adjust_offer__quote-loading-styles__root {
    height: 32px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_adjust_offer__quote-loading-styles__root {
    height: 36.0px;
  }

}
.ladder_licenses__main-styles__title {
  margin-bottom: 30.0px;
}

.ladder_licenses__main-styles__explanatory {
  text-align: left;
  width: 94%;
}

.ladder_licenses__main-styles__subtitle {
  margin-bottom: 30.0px;
}

.ladder_licenses__main-styles__licenses-table table {
  table-layout: fixed;
  text-align: left;
  width: 94%;
}

.ladder_licenses__main-styles__licenses-table th {
  padding-bottom: 20px;
}

.ladder_licenses__main-styles__licenses-table td {
  padding-bottom: 10.0px;
}

.ladder_licenses__main-styles__licenses-table hr {
  margin-top: 20px;
  margin-bottom: 20px;
}
.ladder_account_next_views_messages__status-banner-styles__\% {
  background-color: #B8C5B1;
  bottom: -64px;
  width: 100%;
  padding: 25.0px 30.0px;
  display: flex;
  position: absolute;
}

.ladder_account_next_views_messages__status-banner-styles__exit-icon {
  margin-left: auto;
  margin-top: 15.0px;
}

.ladder_account_next_views_messages__status-banner-styles__error {
  background-color: #D20D0D;
}

@media (max-width: 499px) {

  
  
  .ladder_account_next_views_messages__status-banner-styles__exit-icon {
    margin-top: 0;
  }

}
.ladder_ny_form_review__default-link-styles__root {
  display: flex;
  align-items: center;
}

.ladder_ny_form_review__default-link-styles__display-lines {
  display: flex;
  flex-direction: column;
}

.ladder_ny_form_review__default-link-styles__hidden {
  visibility: hidden;
}
.ladder_account_shared_components__add-your-first-whatever-card-styles__\% {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
}

@media (max-width: 499px) {

  
  
  .ladder_account_shared_components__add-your-first-whatever-card-styles__\% {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

}

.ladder_account_shared_components__add-your-first-whatever-card-styles__description {
  margin-top: 10px;
}

@media (max-width: 499px) {

  
  
  .ladder_account_shared_components__add-your-first-whatever-card-styles__button {
    margin-left: auto;
    margin-right: auto;
  }

}

.ladder_account_shared_components__add-your-first-whatever-card-styles__button {
  max-width: 281px;
  margin-top: 30.0px;
  margin-bottom: 22.5px;
}
.ladder_ny_form_quick_estimate__quick-estimate-parents-styles__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10.0px;
}

.ladder_ny_form_quick_estimate__quick-estimate-parents-styles__parent-text {
  width: 70%;
}
content {

}
.ladder_financial_advisors_home__SatisfiedClientsPanel-styles__\% {
  background-color: #F7F2EF;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 40px 5.5% 0 5.5%;
  width: 100%;
}

@media (min-width: 1025px) {

  
  
  .ladder_financial_advisors_home__SatisfiedClientsPanel-styles__\% {
    padding: 80px 0 0 0;
  }

}
.ladder_app_review__maybe-hide-pii-wrapper-styles__pii-wrapper {
  width: 100%;
}
.ladder_ny_form_review__section-styles__title {
  padding-left: 10.0px;
}

.ladder_ny_form_review__section-styles__body {
  width: 100%;
}

@media (max-width: 499px) {

  
  
  .ladder_ny_form_review__section-styles__title {
    padding-left: 0;
  }
  
  .ladder_ny_form_review__section-styles__body {
    padding: 0 15.0px;
  }

}
.ladder_ux_form_groups__name-input-group-styles__root {
  padding: 12.0px 0;
}

.ladder_ux_form_groups__name-input-group-styles__field {
  padding: 4.0px 0;
}

.ladder_ux_form_groups__name-input-group-styles__first-middle-container {
  display: flex;
  box-sizing: border-box;
}

.ladder_ux_form_groups__name-input-group-styles__first {
  margin-right: 4.0px;
  width: 50%;
}

.ladder_ux_form_groups__name-input-group-styles__middle {
  width: 50%;
}
.ladder_ny_form_height__height-singleselection-styles__horizontal {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.ladder_ny_form_height__height-singleselection-styles__vertical {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.ladder_ny_form_height__height-singleselection-styles__margin-top {
  margin-top: 10.0px;
}

.ladder_ny_form_height__height-singleselection-styles__margin-left {
  margin-left: 10.0px;
}

.ladder_ny_form_height__height-singleselection-styles__margin-right {
  margin-right: 10.0px;
}
.ladder_ux_policy_card__inset-card-internal-container-styles__root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.ladder_careers__ui-open-roles-styles__anchor-skip-header {
  scroll-margin-top: 99px;
}

.ladder_careers__ui-open-roles-styles__all-roles {
  text-align: left;
  width: 100%;
  margin-top: 30.0px;
}

.ladder_careers__ui-open-roles-styles__department {
  margin-bottom: 50.0px;
}

.ladder_careers__ui-open-roles-styles__department-roles {
  display: flex;
  flex-wrap: wrap;
}

.ladder_careers__ui-open-roles-styles__role {
  width: calc(1/2 * 100%);
  margin-bottom: 25.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_careers__ui-open-roles-styles__anchor-skip-header {
    scroll-margin-top: 60px;
    scroll-snap-margin-top: 60px;
  }
  
  .ladder_careers__ui-open-roles-styles__roles-line-break {
    display: none;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_careers__ui-open-roles-styles__all-roles {
    margin-top: 0px;
  }
  
  .ladder_careers__ui-open-roles-styles__role {
    width: 100%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_careers__ui-open-roles-styles__all-roles {
    margin-top: 0px;
  }
  
  .ladder_careers__ui-open-roles-styles__role {
    width: 100%;
  }

}
.ladder_agents_agent_assisted_app_views_decision__fence-conversation-image-styles__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ladder_agents_agent_assisted_app_views_decision__fence-conversation-image-styles__image {
  max-width: 406px;
  width: 100%;
  height: auto;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_agent_assisted_app_views_decision__fence-conversation-image-styles__container {
    display: none;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_agent_assisted_app_views_decision__fence-conversation-image-styles__container {
    display: none;
  }

}
.ladder_markdoc_common__document-comp-styles__root {
  width: 100%;
}
.ladder_landing_pages_so_good_basics__subfact-styles__subfact-container {
  display: flex;
  padding-top: 24px;
}

.ladder_landing_pages_so_good_basics__subfact-styles__bullet-container {
  height: 35px;
  display: flex;
  align-items: center;
}

.ladder_landing_pages_so_good_basics__subfact-styles__bullet {
  color: #FFFFFF;
  height: 7px;
  width: 7px;
  margin: 0px 10px;
}

.ladder_landing_pages_so_good_basics__subfact-styles__subfact {
  font-size: 20px;
  line-height: 35px;
}

@media (max-width: 499px) {

  
  
  .ladder_landing_pages_so_good_basics__subfact-styles__subfact {
    font-size: 16px;
    line-height: 30px;
  }
  
  .ladder_landing_pages_so_good_basics__subfact-styles__bullet-container {
    height: 30px;
  }
  
  .ladder_landing_pages_so_good_basics__subfact-styles__bullet {
    margin: 0px 8px;
  }

}
.ladder_savings_steps__got-steps-styles__wrapper {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.ladder_savings_steps__got-steps-styles__copy {
  width: 50%;
}

.ladder_savings_steps__got-steps-styles__image-wrapper {
  width: 40%;
  display: flex;
  align-items: center;
}

.ladder_savings_steps__got-steps-styles__image {
  max-width: 500px;
  width: 100%;
  height: auto;
  align-self: flex-start;
}
.ladder_agents_signup__signup-form-styles__wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.ladder_agents_signup__signup-form-styles__get-started {
  background-color: #CFDCDE;
  width: 40%;
}

.ladder_agents_signup__signup-form-styles__get-started-image-container {
  max-width: 300px;
  width: 90%;
  margin: 0 auto;
}

.ladder_agents_signup__signup-form-styles__welcome-bonus-image-container {
  max-width: 300px;
  width: 45%;
  margin: 0 auto;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ladder_agents_signup__signup-form-styles__get-started-image {
  width: 100%;
  height: auto;
}

.ladder_agents_signup__signup-form-styles__welcome-bonus-image {
  width: 60%;
  height: auto;
}

.ladder_agents_signup__signup-form-styles__welcome-bonus-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #EFD8B4;
}

.ladder_agents_signup__signup-form-styles__welcome-bonus-text {
  text-alignment-left: true;
}

.ladder_agents_signup__signup-form-styles__form {
  width: 60%;
}

.ladder_agents_signup__signup-form-styles__referral-banner {
  width: 100%;
  padding: 1rem;
  display: flex;
  border-left: 4px solid #D1A98F;
  background-color: #F7F2EF;
}

.ladder_agents_signup__signup-form-styles__which-agency-wrapper {
  display: flex;
  justify-content: space-between;
}

.ladder_agents_signup__signup-form-styles__agency-info-container {
  border: 1px solid #D0D0D0;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.ladder_agents_signup__signup-form-styles__x-button {
  align-self: flex-end;
  cursor: pointer;
}

.ladder_agents_signup__signup-form-styles__existing-agency-wrapper {
  display: flex;
  align-items: center;
  background-color: #CFDCDE;
  justify-content: space-between;
  padding: 11px 20px;
  margin: 10px 0px;
}

.ladder_agents_signup__signup-form-styles__existing-agency-text-wrapper {
  display: flex;
  flex-direction: column;
}

.ladder_agents_signup__signup-form-styles__second-line-wrapper {
  display: flex;
}

.ladder_agents_signup__signup-form-styles__bold-word {
  margin: 0px 5px;
}

.ladder_agents_signup__signup-form-styles__yes-button {
  width: 66px;
  justify-content: center;
  height: 26px;
  align-items: center;
  padding: 5px 12px;
  cursor: pointer;
  background-color: #E7EEEF;
  border-radius: 5px;
  display: flex;
}

.ladder_agents_signup__signup-form-styles__yes-button:hover {
  background-color: #AFC5C8;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_signup__signup-form-styles__wrapper {
    flex-direction: column;
  }
  
  .ladder_agents_signup__signup-form-styles__get-started {
    width: 100%;
    order: 1;
  }
  
  .ladder_agents_signup__signup-form-styles__welcome-bonus-image-container {
    max-width: 300px;
    width: 70%;
    padding: 0px;
    margin: 0 auto;
    order: 0;
  }
  
  .ladder_agents_signup__signup-form-styles__welcome-bonus-image {
    width: 120px;
    height: auto;
  }
  
  .ladder_agents_signup__signup-form-styles__referral-banner {
    flex-direction: column;
  }
  
  .ladder_agents_signup__signup-form-styles__form {
    width: 100%;
    order: 2;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_signup__signup-form-styles__welcome-bonus-image {
    width: 150px;
    height: auto;
  }

}
.tile-a .ladder_widget_ui_v2__start-over-button-styles__root {
  height: 48px;
  width: 48px;
}

.tile-b .ladder_widget_ui_v2__start-over-button-styles__root {
  height: 48px;
  width: 48px;
}

.portrait .ladder_widget_ui_v2__start-over-button-styles__root {
  flex-grow: 7;
  max-width: 70px;
}

.panel .ladder_widget_ui_v2__start-over-button-styles__root {
  flex-grow: 7;
  max-width: 70px;
}

.landscape .ladder_widget_ui_v2__start-over-button-styles__root {
  width: 17%;
  max-width: 117px;
}

.ladder_widget_ui_v2__start-over-button-styles__big {
  flex-grow: 1;
}
.ladder_financial_advisors_dashboard__root-styles__margin-top {
  margin-top: 2em;
}

.ladder_financial_advisors_dashboard__root-styles__padded {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30.0px 0;
  width: 100%;
}

.ladder_financial_advisors_dashboard__root-styles__table {
  width: 100%;
  overflow-x: scroll;
}

.ladder_financial_advisors_dashboard__root-styles__table .ladder_financial_advisors_dashboard__root-styles__table-container {
  width: max-content;
  min-width: 100%;
}

.ladder_financial_advisors_dashboard__root-styles__full-width {
  width: 100%;
}

.ladder_financial_advisors_dashboard__root-styles__caption-label {
  margin-bottom: 10px;
  width: auto;
  float: right;
  clear: right;
}

.ladder_financial_advisors_dashboard__root-styles__caption-item {
  display: inline-block;
  width: 100px;
  margin-right: 45px;
}

.ladder_financial_advisors_dashboard__root-styles__align-expander {
  display: inline-block;
  vertical-align: -15%;
  margin-left: 5px;
}

.ladder_financial_advisors_dashboard__root-styles__inline {
  display: inline;
}

@media (max-width: 499px) {

  
  
  .ladder_financial_advisors_dashboard__root-styles__padded-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30.0px 0;
    width: 100%;
  }
  
  .ladder_financial_advisors_dashboard__root-styles__caption-item {
    width: 50px;
    margin-right: 10px;
  }

}
.ladder_views_decision_carousel__checklist-carousel-styles__root {
  display: flex;
  align-items: stretch;
  flex-grow: 1;
}

.ladder_views_decision_carousel__checklist-carousel-styles__first-col {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.ladder_views_decision_carousel__checklist-carousel-styles__second-col-bg {
  width: 20%;
  min-width: 300px;
}

.ladder_views_decision_carousel__checklist-carousel-styles__second-col-bg-color {
  background-color: #E7EEEF;
}

.ladder_views_decision_carousel__checklist-carousel-styles__second-col {
  display: flex;
  width: 100%;
  padding: 0 0px 64px;
  align-items: center;
  justify-content: center;
}

.ladder_views_decision_carousel__checklist-carousel-styles__first-col {
  transition: opacity 200ms ease-in 1400ms;
}

.ladder_views_decision_carousel__checklist-carousel-styles__second-col {
  transition: opacity 200ms ease-in 1400ms;
}

.ladder_views_decision_carousel__checklist-carousel-styles__finished {
  opacity: 0;
}

.ladder_views_decision_carousel__checklist-carousel-styles__content {
  max-width: 612px;
  width: 100%;
}

.ladder_views_decision_carousel__checklist-carousel-styles__heading-container {
  position: relative;
}

.ladder_views_decision_carousel__checklist-carousel-styles__working {
  position: absolute;
  opacity: 0;
  transition: opacity 200ms ease-in;
  left: 0;
  top: 0;
}

.ladder_views_decision_carousel__checklist-carousel-styles__crunching {
  position: absolute;
  opacity: 0;
  transition: opacity 200ms ease-in;
  left: 0;
  top: 0;
}

.ladder_views_decision_carousel__checklist-carousel-styles__almost {
  position: absolute;
  opacity: 0;
  transition: opacity 200ms ease-in;
  left: 0;
  top: 0;
}

.ladder_views_decision_carousel__checklist-carousel-styles__done {
  position: absolute;
  opacity: 0;
  transition: opacity 200ms ease-in;
  left: 0;
  top: 0;
}

.ladder_views_decision_carousel__checklist-carousel-styles__active-working>.ladder_views_decision_carousel__checklist-carousel-styles__working {
  opacity: 1;
}

.ladder_views_decision_carousel__checklist-carousel-styles__active-crunching>.ladder_views_decision_carousel__checklist-carousel-styles__crunching {
  opacity: 1;
}

.ladder_views_decision_carousel__checklist-carousel-styles__active-almost>.ladder_views_decision_carousel__checklist-carousel-styles__almost {
  opacity: 1;
}

.ladder_views_decision_carousel__checklist-carousel-styles__active-done>.ladder_views_decision_carousel__checklist-carousel-styles__done {
  opacity: 1;
}

.ladder_views_decision_carousel__checklist-carousel-styles__hr {
  margin: 45px 0;
}

.ladder_views_decision_carousel__checklist-carousel-styles__did-you-know-container {
  display: flex;
  align-items: center;
}

.ladder_views_decision_carousel__checklist-carousel-styles__lightning {
  height: 40px;
  width: auto;
  margin-left: -7px;
}

.ladder_views_decision_carousel__checklist-carousel-styles__img-carousel {
  width: 100%;
}

.ladder_views_decision_carousel__checklist-carousel-styles__trustpilot-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.ladder_views_decision_carousel__checklist-carousel-styles__trustpilot-stars {
  width: 120px;
  height: auto;
}

.ladder_views_decision_carousel__checklist-carousel-styles__trustpilot-container {
  display: flex;
  flex-direction: row;
}

.ladder_views_decision_carousel__checklist-carousel-styles__trustpilot-text {
  margin-left: 8.0px;
}

.ladder_views_decision_carousel__checklist-carousel-styles__testimonial-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 110px;
}

.ladder_views_decision_carousel__checklist-carousel-styles__quote {
  width: 100%;
}

@media (min-width: 1025px) {

  
  
  .ladder_views_decision_carousel__checklist-carousel-styles__first-col {
    padding: 64px 0 0;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_views_decision_carousel__checklist-carousel-styles__first-col {
    padding: 32px 16px 0;
  }
  
  .ladder_views_decision_carousel__checklist-carousel-styles__second-col-bg {
    display: none;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_views_decision_carousel__checklist-carousel-styles__first-col {
    padding: 32px 16px 0;
  }
  
  .ladder_views_decision_carousel__checklist-carousel-styles__second-col-bg {
    display: none;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_views_decision_carousel__checklist-carousel-styles__trustpilot-row {
    flex-direction: column;
    justify-content: space-between;
  }
  
  .ladder_views_decision_carousel__checklist-carousel-styles__trustpilot-container {
    flex-direction: column;
  }
  
  .ladder_views_decision_carousel__checklist-carousel-styles__trustpilot-text {
    margin: 0;
  }
  
  .ladder_views_decision_carousel__checklist-carousel-styles__testimonial-container {
    height: 170px;
    margin-bottom: 16px;
  }
  
  .ladder_views_decision_carousel__checklist-carousel-styles__quote {
    margin: 0 0 16px 0;
  }
  
  .ladder_views_decision_carousel__checklist-carousel-styles__trustpilot-stars {
    margin: 16px 0 8.0px 0;
  }

}
.ladder_account_next_views_profile_view__view-personal-details-fields-styles__fields {
  display: flex;
  flex-wrap: wrap;
}
.ladder_hdyhau_lower_funnel__hdyhau-option-styles__root {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  padding: 10.0px 0;
}

.ladder_hdyhau_lower_funnel__hdyhau-option-styles__checkbox-wrapper {
  width: 100%;
  padding-right: 10.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_hdyhau_lower_funnel__hdyhau-option-styles__root {
    padding: 5.0px 0;
    min-height: 35px;
  }

}
.ladder_widget_ui_v2__smoker-checkbox-styles__root {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.ladder_widget_ui_v2__smoker-checkbox-styles__root>*:last-child {
  flex: 0 0 60px;
  margin-left: 8px;
}

.tile-b .ladder_widget_ui_v2__smoker-checkbox-styles__root {
  margin-top: 15.0px;
}

.landscape .ladder_widget_ui_v2__smoker-checkbox-styles__root>*:first-child {
  margin-top: 8px;
}
.ladder_ux_expand_card__expand-card-styles__root {
  border-top: 1px solid #D0D0D0;
  border-bottom: 1px solid #D0D0D0;
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
}

.ladder_ux_expand_card__expand-card-styles__title-wrapper {
  text-align: left;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
}

.ladder_ux_expand_card__expand-card-styles__icon-title-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.ladder_ux_expand_card__expand-card-styles__icon-title {
  margin: 16px;
  width: 32px;
}

.ladder_ux_expand_card__expand-card-styles__icon-arrow {
  margin: 16px;
  width: 20.0px;
}

.ladder_ux_expand_card__expand-card-styles__description {
  margin: 16px;
}
.ladder_form_common__modal-regular-field-styles__root {
  padding-bottom: 5.0px;
}

.ladder_form_common__modal-regular-field-styles__caption-container {
  display: flex;
  align-items: center;
  padding-bottom: 5.0px;
}

.ladder_form_common__modal-regular-field-styles__caption-centered {
  justify-content: space-around;
}

.ladder_form_common__modal-regular-field-styles__info-expander-container {
  padding: 5.0px 0 0 10.0px;
}

.ladder_form_common__modal-regular-field-styles__subcaption {
  padding-bottom: 15.0px;
}

.ladder_form_common__modal-regular-field-styles__top-level-caption {
  justify-content: center;
  text-align: center;
  padding: 20px 0;
}

.ladder_form_common__modal-regular-field-styles__modal-header {
  width: 100%;
  text-align: left;
}

.ladder_form_common__modal-regular-field-styles__ssn-modal-header {
  padding-bottom: 10.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_form_common__modal-regular-field-styles__top-level-caption {
    padding-bottom: 10.0px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_form_common__modal-regular-field-styles__top-level-caption.ladder_form_common__modal-regular-field-styles__helper-subcaption {
    padding-bottom: 10.0px;
  }
  
  .ladder_form_common__modal-regular-field-styles__inline-info-helper {
    display: inline;
    vertical-align: middle;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_form_common__modal-regular-field-styles__top-level-caption.ladder_form_common__modal-regular-field-styles__helper-subcaption {
    padding-bottom: 10.0px;
  }
  
  .ladder_form_common__modal-regular-field-styles__inline-info-helper {
    display: inline;
    vertical-align: middle;
  }

}
.ladder_nearly_perfect_quote_exchange__exchange-carrier-styles__carrier {
  padding: 10.0px 20px;
  border: 1px solid  #5D5D5D;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 78px;
}

.ladder_nearly_perfect_quote_exchange__exchange-carrier-styles__impr-beacon {
  display: none;
}

.ladder_nearly_perfect_quote_exchange__exchange-carrier-styles__img {
  max-height: 60px;
  max-width: 124px;
}
.ladder_ux_panels__rebrand-logo-panel-styles__root {
  display: flex;
  width: 100%;
  background-color: #000000;
  z-index: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ladder_ux_panels__rebrand-logo-panel-styles__text {
  display: flex;
  text-align: center;
  margin-top: 24.0px;
  margin-bottom: -40px;
}

.ladder_ux_panels__rebrand-logo-panel-styles__container {
  width: 81%;
  max-width: 1400px;
  justify-content: space-between;
  height: 160px;
  margin-right: auto;
  align-items: center;
  flex-direction: row;
  z-index: 1;
  margin-left: auto;
  box-sizing: content-box;
  display: flex;
}

.ladder_ux_panels__rebrand-logo-panel-styles__logo {
  padding: 16px 4.0px;
  height: 32px;
  max-width: 12%;
}

.ladder_ux_panels__rebrand-logo-panel-styles__logo {
  fill: #FFFFFF;
}

.ladder_ux_panels__rebrand-logo-panel-styles__logo path[fill]:not([fill=none]) {
  fill: #FFFFFF;
}

.ladder_ux_panels__rebrand-logo-panel-styles__logo g[fill]:not([fill=none]) {
  fill: #FFFFFF;
}

.ladder_ux_panels__rebrand-logo-panel-styles__logo path[stroke]:not([stroke=none]) {
  stroke: #FFFFFF;
}

.ladder_ux_panels__rebrand-logo-panel-styles__logo g[stroke]:not([stroke=none]) {
  stroke: #FFFFFF;
}

.ladder_ux_panels__rebrand-logo-panel-styles__logo-large {
  max-width: 18%;
  padding: 8.0px 4.0px;
  height: 64px;
}

.ladder_ux_panels__rebrand-logo-panel-styles__logo-large {
  fill: #FFFFFF;
}

.ladder_ux_panels__rebrand-logo-panel-styles__logo-large path[fill]:not([fill=none]) {
  fill: #FFFFFF;
}

.ladder_ux_panels__rebrand-logo-panel-styles__logo-large g[fill]:not([fill=none]) {
  fill: #FFFFFF;
}

.ladder_ux_panels__rebrand-logo-panel-styles__logo-large path[stroke]:not([stroke=none]) {
  stroke: #FFFFFF;
}

.ladder_ux_panels__rebrand-logo-panel-styles__logo-large g[stroke]:not([stroke=none]) {
  stroke: #FFFFFF;
}

.ladder_ux_panels__rebrand-logo-panel-styles__desktop {
  display: none;
}

.ladder_ux_panels__rebrand-logo-panel-styles__tablet {
  display: none;
}

.ladder_ux_panels__rebrand-logo-panel-styles__mobile {
  display: none;
}

@media (min-width: 1025px) {

  
  
  .ladder_ux_panels__rebrand-logo-panel-styles__desktop {
    display: flex;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_panels__rebrand-logo-panel-styles__tablet {
    display: flex;
  }
  
  .ladder_ux_panels__rebrand-logo-panel-styles__container {
    height: 140px;
  }
  
  .ladder_ux_panels__rebrand-logo-panel-styles__logo {
    max-width: 18%;
  }
  
  .ladder_ux_panels__rebrand-logo-panel-styles__logo-large {
    max-width: 22%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_panels__rebrand-logo-panel-styles__mobile {
    display: flex;
  }
  
  .ladder_ux_panels__rebrand-logo-panel-styles__text {
    margin-bottom: -16px;
  }
  
  .ladder_ux_panels__rebrand-logo-panel-styles__container {
    height: 100px;
    width: 90%;
  }
  
  .ladder_ux_panels__rebrand-logo-panel-styles__logo {
    max-width: 30%;
    padding-left: 0;
    padding-right: 0;
  }
  
  .ladder_ux_panels__rebrand-logo-panel-styles__logo-large {
    max-width: none;
    padding: 8.0px 4.0px;
    height: 64px;
  }

}
.ladder_ux_logos__logo-full-styles__icon {
  height: 56.0px;
}

.ladder_ux_logos__logo-full-styles__really-responsive {
  height: unset;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
}

.ladder_ux_logos__logo-full-styles__height-32px {
  height: 32px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_logos__logo-full-styles__icon {
    height: 40.0px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_logos__logo-full-styles__icon {
    max-height: 32px;
    height: 32px;
  }
  
  .ladder_ux_logos__logo-full-styles__ladder-small {
    max-height: 24px !important;
    height: 24px !important;
  }
  
  .ladder_ux_logos__logo-full-styles__width-33vw {
    height: 100%;
    max-height: 100%;
    width: 33vw;
  }
  
  .ladder_ux_logos__logo-full-styles__width-25vw {
    height: 100%;
    max-height: 100%;
    width: 25vw;
  }

}

.powered-by .ladder_ux_logos__logo-full-styles__ladder-small {
  height: 16px !important;
}
@media (min-width: 1025px) {

  
  
  .ladder_agents_dashboard__share-page-styles__share-page {
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
    gap: 20px;
    margin-right: 40px;
  }
  
  .ladder_agents_dashboard__share-page-styles__tracking-link-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard__share-page-styles__share-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
    gap: 20px;
  }
  
  .ladder_agents_dashboard__share-page-styles__tracking-link-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_dashboard__share-page-styles__share-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
    gap: 20px;
  }
  
  .ladder_agents_dashboard__share-page-styles__tracking-link-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

}
.ladder_apply_tps_modal__tps-modal-styles__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 32px;
}

.ladder_apply_tps_modal__tps-modal-styles__questions {
  text-align: left;
}

.ladder_apply_tps_modal__tps-modal-styles__spinner {
  margin-bottom: 20px;
}

.ladder_apply_tps_modal__tps-modal-styles__checkmark {
  width: 40px;
  height: 40px;
}

.ladder_apply_tps_modal__tps-modal-styles__form {
  width: 100%;
}

.ladder_apply_tps_modal__tps-modal-styles__button {
  margin: 8px 0;
}

.ladder_apply_tps_modal__tps-modal-styles__button-container {
  margin-top: 20px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_apply_tps_modal__tps-modal-styles__button-container {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .ladder_apply_tps_modal__tps-modal-styles__button {
    width: 200px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_apply_tps_modal__tps-modal-styles__button-container {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .ladder_apply_tps_modal__tps-modal-styles__button {
    width: 200px;
  }

}
.ladder_requestion_post_sign_questions__re-question-explanation-page-styles__root {
  display: flex;
}

.ladder_requestion_post_sign_questions__re-question-explanation-page-styles__left-column {
  width: 40%;
}

.ladder_requestion_post_sign_questions__re-question-explanation-page-styles__right-column {
  width: 60%;
}

@media (max-width: 499px) {

  
  
  .ladder_requestion_post_sign_questions__re-question-explanation-page-styles__root {
    flex-direction: column;
  }
  
  .ladder_requestion_post_sign_questions__re-question-explanation-page-styles__left-column {
    width: 100%;
  }
  
  .ladder_requestion_post_sign_questions__re-question-explanation-page-styles__right-column {
    width: 100%;
  }

}
.ladder_trustage_fallback__trustage-fallback-styles__wrapper {
  position: fixed;
  top: 100px;
  left: 0;
  width: 100%;
  background-color: #CFDCDE;
  display: flex;
  justify-content: center;
}

.ladder_trustage_fallback__trustage-fallback-styles__wrapper.ladder_trustage_fallback__trustage-fallback-styles__themed {
  background-color: white;
}

.ladder_trustage_fallback__trustage-fallback-styles__wrapper.ladder_trustage_fallback__trustage-fallback-styles__relative {
  position: relative;
  top: 0;
}

.ladder_trustage_fallback__trustage-fallback-styles__content-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  overflow-y: scroll;
}

.ladder_trustage_fallback__trustage-fallback-styles__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: fit-content;
  max-width: 600px;
  padding: 30.0px 20px;
}

.ladder_trustage_fallback__trustage-fallback-styles__mother-and-son {
  max-height: 600px;
  max-width: calc((50% - 300px)/.75);
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.ladder_trustage_fallback__trustage-fallback-styles__relative .ladder_trustage_fallback__trustage-fallback-styles__mother-and-son {
  z-index: 0;
}

.ladder_trustage_fallback__trustage-fallback-styles__logo {
  height: 30px;
  margin: 30.0px 0 25.0px 0;
}

@media (min-width: 1025px) {

  
  
  .ladder_trustage_fallback__trustage-fallback-styles__wrapper {
    height: calc(100% - 100px);
    top: 100px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_trustage_fallback__trustage-fallback-styles__wrapper {
    height: calc(100% - 60px);
    top: 60px;
  }
  
  .ladder_trustage_fallback__trustage-fallback-styles__mother-and-son {
    display: none;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_trustage_fallback__trustage-fallback-styles__wrapper {
    height: calc(100% - 60px);
    top: 60px;
  }
  
  .ladder_trustage_fallback__trustage-fallback-styles__mother-and-son {
    display: none;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_trustage_fallback__trustage-fallback-styles__wrapper.ladder_trustage_fallback__trustage-fallback-styles__modal {
    position: relative;
    top: 0;
    height: 100%;
  }

}
.ladder_ux_radio_input__circular-radio-buttons-styles__root {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex-grow: 1;
  margin-top: 10px;
}

.ladder_ux_radio_input__circular-radio-buttons-styles__left-align {
  justify-content: flex-start;
}

.ladder_ux_radio_input__circular-radio-buttons-styles__left-align .ladder_ux_radio_input__circular-radio-buttons-styles__element-container {
  margin-right: 27px;
}

.ladder_ux_radio_input__circular-radio-buttons-styles__element-container {
  width: 60px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ladder_ux_radio_input__circular-radio-buttons-styles__button-container {
  width: 60px;
  justify-content: center;
  height: 60px;
  border: 2px solid #000000;
  align-items: center;
  padding: 5px 5px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
}

.ladder_ux_radio_input__circular-radio-buttons-styles__button-container:hover {
  background-color: #000000;
  color: #FFFFFF;
}

.ladder_ux_radio_input__circular-radio-buttons-styles__selected-button {
  background-color: #000000;
  color: #FFFFFF;
}

.ladder_ux_radio_input__circular-radio-buttons-styles__selected-text {
  min-width: max-content;
}

.ladder_ux_radio_input__circular-radio-buttons-styles__radio {
  display: none;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_radio_input__circular-radio-buttons-styles__button-container {
    height: 40px;
    width: 40px;
  }
  
  .ladder_ux_radio_input__circular-radio-buttons-styles__element-container {
    height: 66px;
    width: 40px;
  }

}
.ladder_user_upload_form__upload-page-styles__root {
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ladder_user_upload_form__upload-page-styles__sxs-panes {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.ladder_user_upload_form__upload-page-styles__sxs-panes >div {
  flex: 1;
  padding: 10px;
}

.ladder_user_upload_form__upload-page-styles__drop-pane {
  border-width: 1px;
  border-color: #707070;
  justify-content: center;
  height: 420px;
  align-items: center;
  flex-direction: column;
  border-style: dashed;
  background-color: #F4F4F4;
  display: flex;
  transition: background-color 0.1s;
}

.ladder_user_upload_form__upload-page-styles__drop-pane.ladder_user_upload_form__upload-page-styles__drag-hover {
  background-color: #D0D0D0;
}

.ladder_user_upload_form__upload-page-styles__big-plus {
  width: 100%;
  justify-content: center;
  height: 55%;
  align-items: flex-end;
  family: lato;
  font-size: 140px;
  display: flex;
  color: #FE4747;
  weight: light;
}

.ladder_user_upload_form__upload-page-styles__rungs-big-plus {
  height: 60px;
  width: 60px;
}

.ladder_user_upload_form__upload-page-styles__instructions {
  height: 45%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.ladder_user_upload_form__upload-page-styles__instructions .ladder_user_upload_form__upload-page-styles__main-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.ladder_user_upload_form__upload-page-styles__instructions .ladder_user_upload_form__upload-page-styles__main-text label {
  color: #D1A98F;
  font-weight: 600;
}

.ladder_user_upload_form__upload-page-styles__instructions .ladder_user_upload_form__upload-page-styles__main-text label:hover {
  text-decoration: underline;
}

.ladder_user_upload_form__upload-page-styles__files-pane {
  height: 100%;
}

.ladder_user_upload_form__upload-page-styles__files-pane .ladder_user_upload_form__upload-page-styles__file-list {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.ladder_user_upload_form__upload-page-styles__files-pane .ladder_user_upload_form__upload-page-styles__file-list-item {
  width: 100%;
}

.ladder_user_upload_form__upload-page-styles__files-pane .ladder_user_upload_form__upload-page-styles__submit {
  margin-top: 8px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_user_upload_form__upload-page-styles__sxs-panes {
    flex-direction: column;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_user_upload_form__upload-page-styles__sxs-panes {
    flex-direction: column;
  }

}
.ladder_markdoc_tags_specs__spec-table-styles__cell {
  overflow: wrap;
  text-overflow: wrap;
  word-wrap: break-word;
}

.ladder_markdoc_tags_specs__spec-table-styles__row {
  max-width: 100%;
  width: 100%;
}

.ladder_markdoc_tags_specs__spec-table-styles__table {
  border-width: 1px;
  width: 100%;
  border-style: solid;
  border-radius: 8px;
  border-color: #E5E5E5;
  border-spacing: 10px;
  border-collapse: separate;
}
.ladder_laddering_page__opportunity-to-ladder-down-styles__root {
  width: 100%;
  justify-content: center;
  height: auto;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 1;
  background-color: #F7F2EF;
  display: flex;
}

.ladder_laddering_page__opportunity-to-ladder-down-styles__top-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 1500px;
  margin-top: 60px;
  width: 90%;
}

.ladder_laddering_page__opportunity-to-ladder-down-styles__top-text {
  display: flex;
}

.ladder_laddering_page__opportunity-to-ladder-down-styles__image-container {
  display: block;
  width: auto;
  height: auto;
  z-index: 2;
}

.ladder_laddering_page__opportunity-to-ladder-down-styles__image {
  display: block;
  margin-top: 50.0px;
  width: auto;
  height: auto;
}

.ladder_laddering_page__opportunity-to-ladder-down-styles__blank-div {
  position: absolute;
  background-color: #FFFFFF;
  width: 100%;
  height: 100px;
  z-index: 1;
  bottom: 0;
}

.ladder_laddering_page__opportunity-to-ladder-down-styles__animation {
  width: 100%;
  max-width: 1400px;
  height: 700px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_laddering_page__opportunity-to-ladder-down-styles__image {
    width: 88vw;
  }
  
  .ladder_laddering_page__opportunity-to-ladder-down-styles__blank-div {
    height: 100px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_laddering_page__opportunity-to-ladder-down-styles__image {
    width: 88vw;
  }
  
  .ladder_laddering_page__opportunity-to-ladder-down-styles__blank-div {
    height: 100px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_laddering_page__opportunity-to-ladder-down-styles__blank-div {
    height: 40px;
  }

}
.ladder_advisor_only_standalone_quoter__quote-input-styles__right-border {
  border-right: 1px solid #D0D0D0;
}
.ladder_apply_current_page__second-column-styles__root {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  width: 30%;
}

.ladder_apply_current_page__second-column-styles__tire-img {
  max-width: 315px;
  max-height: 228px;
  position: relative;
  top: 30%;
  height: auto;
  margin: 10.0px auto;
}

.ladder_apply_current_page__second-column-styles__quick-start {
  width: 45%;
}

.ladder_apply_current_page__second-column-styles__wrapper {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ladder_apply_current_page__second-column-styles__quick-start .ladder_apply_current_page__second-column-styles__wrapper {
  width: 100%;
}

.ladder_apply_current_page__second-column-styles__quick-start .ladder_apply_current_page__second-column-styles__wrapper-no-padding {
  padding: 0;
}

@media (min-width: 1025px) {

  
  
  
  
  .ladder_apply_current_page__second-column-styles__quick-start .ladder_apply_current_page__second-column-styles__wrapper {
    padding: 0 30.0px;
    min-height: 550px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  
  
  .ladder_apply_current_page__second-column-styles__quick-start .ladder_apply_current_page__second-column-styles__wrapper {
    padding: 0 30.0px 60px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_apply_current_page__second-column-styles__quick-start {
    width: 100%;
  }
  
  .ladder_apply_current_page__second-column-styles__quick-start .ladder_apply_current_page__second-column-styles__wrapper {
    padding: 40px 20px 0;
  }

}
.ladder_agents_pass_the_application_header__banner-mobile-styles__agent-impersonation-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #EFEAE1;
  border-radius: 24px;
  padding: 16px;
}

.ladder_agents_pass_the_application_header__banner-mobile-styles__agent-impersonation-link {
  margin-left: 2em;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_pass_the_application_header__banner-mobile-styles__agent-impersonation-container {
    flex-direction: column;
    justify-content: space-beween;
    background: #EFEAE1;
    border-radius: 0px;
  }
  
  .ladder_agents_pass_the_application_header__banner-mobile-styles__agent-impersonation-link {
    margin-left: 0em;
  }

}
.ladder_agents_agent_assisted_app_views_app_status__number-or-checkbox-styles__checkmark {
  color: #BDBDBD;
}

.ladder_agents_agent_assisted_app_views_app_status__number-or-checkbox-styles__circle {
  height: 50px;
  width: 50px;
  flex-shrink: 0;
}

.ladder_agents_agent_assisted_app_views_app_status__number-or-checkbox-styles__number {
  font-size: 30px;
  line-height: 50px;
  font-family: Montserrat, sans-serif;
  color: #FFFFFF;
  font-weight: 700;
  text-align: center;
  border-radius: 50%;
}

.ladder_agents_agent_assisted_app_views_app_status__number-or-checkbox-styles__green-bg {
  background-color: #7A8A8C;
}

.ladder_agents_agent_assisted_app_views_app_status__number-or-checkbox-styles__gray-bg {
  background-color: #BDBDBD;
}
.ladder_landing_pages_so_good_hero__love-them-to-death-styles__root {
  position: relative;
  width: 100%;
  z-index: 1;
  overflow: hidden;
}

.ladder_landing_pages_so_good_hero__love-them-to-death-styles__scroll-down {
  width: 100%;
  display: flex;
  justify-content: center;
}

.ladder_landing_pages_so_good_hero__love-them-to-death-styles__scroll-down-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
}

.ladder_landing_pages_so_good_hero__love-them-to-death-styles__icon {
  width: 40px;
  height: 36px;
}

.ladder_landing_pages_so_good_hero__love-them-to-death-styles__hide {
  display: none;
}
.ladder_app_review__review-styles__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  padding: 10.0px;
}

.ladder_app_review__review-styles__highlighted {
  border-left: 8.0px solid #D1A98F;
  background-color: #F7F2EF;
  width: 100%;
  padding-left: 30.0px;
  padding-right: 30.0px;
}

.ladder_app_review__review-styles__text {
  width: 100%;
  margin: 5.0px 0;
}

.ladder_app_review__review-styles__tooltip-container {
  display: flex;
  flex-direction: row;
  margin: 30.0px;
}

.ladder_app_review__review-styles__expandable-review-container {
  width: 100%;
  max-width: 800px;
}

@media (max-width: 499px) {

  
  
  .ladder_app_review__review-styles__container {
    width: 90%;
  }
  
  .ladder_app_review__review-styles__full-width {
    width: 100%;
    padding: 8px 0;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_app_review__review-styles__container {
    width: 90%;
  }
  
  .ladder_app_review__review-styles__full-width {
    width: 100%;
    padding: 8px 0;
  }

}
.ladder_landing_pages_trustage__main-panel-styles__root {
  display: flex;
  position: relative;
  background: #EFEAE1;
  flex-direction: row;
  min-height: 850px;
  width: 100%;
  z-index: 1;
  overflow: hidden;
}

.ladder_landing_pages_trustage__main-panel-styles__container {
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 1400px;
  margin: 92px auto 192px;
}

.ladder_landing_pages_trustage__main-panel-styles__container-2022-restyle {
  margin: 0 auto 50px;
}

.ladder_landing_pages_trustage__main-panel-styles__mobile-only-container {
  display: none;
}

.ladder_landing_pages_trustage__main-panel-styles__non-mobile-container {
  display: flex;
}

.ladder_landing_pages_trustage__main-panel-styles__text-container {
  width: 50%;
  max-width: 1400px;
}

.ladder_landing_pages_trustage__main-panel-styles__second-text {
  margin: 8.0px 0 32px 0;
}

.ladder_landing_pages_trustage__main-panel-styles__trustage-logo {
  height: 30px;
  margin: 32px 0 24.0px 0;
}

.ladder_landing_pages_trustage__main-panel-styles__trustage-button {
  margin: 32px 0 0 0;
}

.ladder_landing_pages_trustage__main-panel-styles__superscript {
  vertical-align: super;
  font-size: 0.7em;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_landing_pages_trustage__main-panel-styles__root {
    min-height: 540px;
    flex-direction: column;
    align-items: center;
  }
  
  .ladder_landing_pages_trustage__main-panel-styles__container {
    width: 90%;
    margin: 32px 0 0;
  }
  
  .ladder_landing_pages_trustage__main-panel-styles__text-container {
    width: 100%;
  }
  
  .ladder_landing_pages_trustage__main-panel-styles__trustage-button {
    margin: 64px 0 0 0;
  }
  
  .ladder_landing_pages_trustage__main-panel-styles__mobile-only-container {
    display: block;
  }
  
  .ladder_landing_pages_trustage__main-panel-styles__non-mobile-container {
    display: none;
  }
  
  .ladder_landing_pages_trustage__main-panel-styles__text-container {
    max-width: 100%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_landing_pages_trustage__main-panel-styles__root {
    min-height: 540px;
    flex-direction: column;
    align-items: center;
  }
  
  .ladder_landing_pages_trustage__main-panel-styles__container {
    width: 90%;
    margin: 32px 0 0;
  }
  
  .ladder_landing_pages_trustage__main-panel-styles__text-container {
    width: 100%;
  }
  
  .ladder_landing_pages_trustage__main-panel-styles__trustage-button {
    margin: 64px 0 0 0;
  }
  
  .ladder_landing_pages_trustage__main-panel-styles__mobile-only-container {
    display: block;
  }
  
  .ladder_landing_pages_trustage__main-panel-styles__non-mobile-container {
    display: none;
  }
  
  .ladder_landing_pages_trustage__main-panel-styles__text-container {
    max-width: 100%;
  }

}
.ladder_financial_advisors_laddering__customer-quote-text-styles__container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
}

.ladder_financial_advisors_laddering__customer-quote-text-styles__quote-character {
  font-size: 120px;
  font-family: Montserrat, sans-serif;
  color: #D1A98F;
  line-height: 100px;
  margin-bottom: -25px;
  font-weight: 700;
}
.ladder_footer__footer-link-styles__root {
  display: block;
  margin-bottom: 20px;
}
.ladder_apply_ny_current_page__current-page-styles__root {
  flex-grow: 1;
}

.ladder_apply_ny_current_page__current-page-styles__spacer {
  flex-grow: 1;
}

.ladder_apply_ny_current_page__current-page-styles__one-col {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

@media (max-width: 499px) {

  
  
  .ladder_apply_ny_current_page__current-page-styles__root {
    display: flex;
    flex-direction: column;
  }

}

.ladder_apply_ny_current_page__current-page-styles__two-col-wrapper {
  display: flex;
}

.ladder_apply_ny_current_page__current-page-styles__two-col-reversed {
  flex-direction: row-reverse;
}

.ladder_apply_ny_current_page__current-page-styles__two-col-reversed .ladder_apply_ny_current_page__current-page-styles__two-col-0 {
  width: 55%;
}

.ladder_apply_ny_current_page__current-page-styles__two-col-0 {
  width: 70%;
  display: flex;
  flex-direction: column;
}

.ladder_apply_ny_current_page__current-page-styles__second-col-img {
  max-width: 65%;
}

.ladder_apply_ny_current_page__current-page-styles__quick-start-blurb {
  width: 90%;
}

@media (max-width: 499px) {

  
  
  .ladder_apply_ny_current_page__current-page-styles__two-col-reversed {
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
  
  .ladder_apply_ny_current_page__current-page-styles__two-col-reversed .ladder_apply_ny_current_page__current-page-styles__two-col-0 {
    width: auto;
  }
  
  .ladder_apply_ny_current_page__current-page-styles__two-col-0 {
    width: auto;
  }
  
  .ladder_apply_ny_current_page__current-page-styles__quick-start-blurb {
    width: 100%;
  }

}
.ladder_widget_ui_v2__panel-front-styles__panel {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.ladder_widget_ui_v2__panel-front-styles__left-panel {
  width: 50%;
  height: 100%;
  padding: 16px;
}

.ladder_widget_ui_v2__panel-front-styles__header {
  margin-top: 32px;
  margin-bottom: 32px;
  text-align: center;
}

.ladder_widget_ui_v2__panel-front-styles__checkmarks {
  margin-left: 24px;
}

.ladder_widget_ui_v2__panel-front-styles__text {
  margin-top: 8px;
  color: black;
}

.ladder_widget_ui_v2__panel-front-styles__right-panel {
  width: 50%;
  height: 100%;
}

.ladder_widget_ui_v2__panel-front-styles__logo {
  width: 141px;
  flex-grow: 0;
  margin: 18px 0px 0px 18px;
}
.ladder_markdoc_common__paragraph-comp-styles__paragraph {
  margin: 0.5em 0 0.5em 0;
}
.ladder_form_blood_pressure__Root-styles__root {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.ladder_form_blood_pressure__Root-styles__slash-container {
  box-sizing: border-box;
  flex-basis: 10%;
  text-align: center;
}
.ladder_savings_common__steps-saving-reminder-panel-styles__wrapper {
  background-color: #DBE6DB;
  display: flex;
  padding: 10.0px;
  margin-top: 20px;
  text-align: left;
  max-width: 550px;
}

.ladder_savings_common__steps-saving-reminder-panel-styles__v1-8 {
  width: 100%;
  border: solid 1px;
  border-color: #FFFFFF;
  border-radius: 4px;
  box-shadow: 0 4px 20px 0 rgba(27,27,27,0.06);
}
@media (max-width: 499px) {

  
  
  .ladder_views_api__request-access-button-styles__mobile-link {
    width: 100%;
  }

}
.ladder_markdoc_core__toc-row-styles__level-1 {
  margin-left: 1em;
}

.ladder_markdoc_core__toc-row-styles__level-2 {
  margin-left: 2em;
}

.ladder_markdoc_core__toc-row-styles__level-3 {
  margin-left: 3em;
}

.ladder_markdoc_core__toc-row-styles__level-4 {
  margin-left: 4em;
}
.ladder_megaphone_refer__how-it-works-container-styles__info-container {
  display: flex;
}

@media (min-width: 1025px) {

  
  
  .ladder_megaphone_refer__how-it-works-container-styles__info-container {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30.0px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_megaphone_refer__how-it-works-container-styles__info-container {
    flex-direction: column;
    text-align: center;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_megaphone_refer__how-it-works-container-styles__info-container {
    flex-direction: column;
    text-align: center;
  }

}
.ladder_ux_panels__ads-landing-page-shorten-that-to-do-list-panel-styles__root {
  display: flex;
  background: #EFEAE1;
  width: 100%;
  z-index: 1;
  overflow: hidden;
}

@media (min-width: 1025px) {

  
  
  .ladder_ux_panels__ads-landing-page-shorten-that-to-do-list-panel-styles__root {
    height: 500px;
    justify-content: center;
  }
  
  .ladder_ux_panels__ads-landing-page-shorten-that-to-do-list-panel-styles__cta-and-image-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1100px;
    gap: 30px;
    margin: 50px 10% 50px 10%;
  }
  
  .ladder_ux_panels__ads-landing-page-shorten-that-to-do-list-panel-styles__cta-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 330px;
    max-width: 390px;
  }
  
  .ladder_ux_panels__ads-landing-page-shorten-that-to-do-list-panel-styles__cta-button-container {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-top: 30px;
  }
  
  .ladder_ux_panels__ads-landing-page-shorten-that-to-do-list-panel-styles__image-container {
    background-color: #D1A98F;
    border-radius: 24px;
  }
  
  .ladder_ux_panels__ads-landing-page-shorten-that-to-do-list-panel-styles__family-image-cropped-b {
    display: block;
    object-fit: contain;
    width: auto;
    height: 400px;
    border-radius: 0 0 24px 24px;
  }
  
  .ladder_ux_panels__ads-landing-page-shorten-that-to-do-list-panel-styles__family-image-cropped-c {
    display: none;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_panels__ads-landing-page-shorten-that-to-do-list-panel-styles__root {
    flex-direction: column;
  }
  
  .ladder_ux_panels__ads-landing-page-shorten-that-to-do-list-panel-styles__cta-container {
    text-align: center;
    padding: 0 20px 0 20px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_panels__ads-landing-page-shorten-that-to-do-list-panel-styles__root {
    flex-direction: column;
  }
  
  .ladder_ux_panels__ads-landing-page-shorten-that-to-do-list-panel-styles__cta-container {
    text-align: center;
    padding: 0 20px 0 20px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_panels__ads-landing-page-shorten-that-to-do-list-panel-styles__cta-container {
    margin-top: 50px;
  }
  
  .ladder_ux_panels__ads-landing-page-shorten-that-to-do-list-panel-styles__cta-button-container {
    margin: 30px 0 30px 0;
  }
  
  .ladder_ux_panels__ads-landing-page-shorten-that-to-do-list-panel-styles__family-image-cropped-b {
    display: none;
  }
  
  .ladder_ux_panels__ads-landing-page-shorten-that-to-do-list-panel-styles__family-image-cropped-c {
    display: block;
    object-fit: contain;
    object-position: bottom;
    width: 100%;
    height: auto;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_panels__ads-landing-page-shorten-that-to-do-list-panel-styles__cta-container {
    margin: 40px 0 20px 0;
  }
  
  .ladder_ux_panels__ads-landing-page-shorten-that-to-do-list-panel-styles__cta-button-container {
    margin-top: 20px;
  }
  
  .ladder_ux_panels__ads-landing-page-shorten-that-to-do-list-panel-styles__mobile-button-width {
    min-width: 150px;
  }
  
  .ladder_ux_panels__ads-landing-page-shorten-that-to-do-list-panel-styles__family-image-cropped-b {
    display: block;
    object-fit: contain;
    object-position: bottom;
    width: 100%;
    height: auto;
  }
  
  .ladder_ux_panels__ads-landing-page-shorten-that-to-do-list-panel-styles__family-image-cropped-c {
    display: none;
  }

}
.ladder_adjust_offer__quote-slider-styles__root {
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
  margin: 30.0px auto 15.0px auto;
  max-width: 620px;
}

.ladder_adjust_offer__quote-slider-styles__column {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 5px;
}

@media (max-width: 499px) {

  
  
  .ladder_adjust_offer__quote-slider-styles__root {
    margin: 10.0px auto;
  }

}
.ladder_work_home__spacer-styles__root {
  width: 100%;
  padding: 30.0px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ladder_agents_pass_the_application__pass-the-app-styles__message-content {
  display: flex;
  flex-direction: column;
  max-width: 570px;
  text-align: left;
}

.ladder_agents_pass_the_application__pass-the-app-styles__message-content .ladder_agents_pass_the_application__pass-the-app-styles__cta-button {
  align-self: baseline;
  margin-top: 32px;
}

.ladder_agents_pass_the_application__pass-the-app-styles__message-content .ladder_agents_pass_the_application__pass-the-app-styles__message-header {
  margin-bottom: 32px;
}

.ladder_agents_pass_the_application__pass-the-app-styles__answer-container {
  display: flex;
  margin-top: 32px;
  flex-direction: row;
  width: 100%;
}

.ladder_agents_pass_the_application__pass-the-app-styles__wrapper {
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
  padding: 5% 32px 0;
  flex-direction: row;
}

.ladder_agents_pass_the_application__pass-the-app-styles__image {
  width: 50%;
  max-width: 398px;
}

.ladder_agents_pass_the_application__pass-the-app-styles__root {
  display: flex;
  flex-direction: column;
}

.ladder_agents_pass_the_application__pass-the-app-styles__email-sent-root {
  align-items: center;
  margin-top: 150px;
}

.ladder_agents_pass_the_application__pass-the-app-styles__email-sent-root .ladder_agents_pass_the_application__pass-the-app-styles__email-sent-image {
  height: auto;
  width: 300px;
}

.ladder_agents_pass_the_application__pass-the-app-styles__email-sent-root .ladder_agents_pass_the_application__pass-the-app-styles__text {
  max-width: 520px;
  text-align: center;
}

.ladder_agents_pass_the_application__pass-the-app-styles__email-sent-root .ladder_agents_pass_the_application__pass-the-app-styles__header {
  margin-top: 45px;
}

.ladder_agents_pass_the_application__pass-the-app-styles__email-sent-root .ladder_agents_pass_the_application__pass-the-app-styles__body {
  margin-top: 24px;
}

.ladder_agents_pass_the_application__pass-the-app-styles__w-50 {
  width: 50%;
}

@media (min-width: 1025px) {

  
  
  .ladder_agents_pass_the_application__pass-the-app-styles__wrapper {
    max-width: 1140px;
    justify-content: space-between;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_agents_pass_the_application__pass-the-app-styles__email-sent-root {
    margin-top: 50px;
  }
  
  .ladder_agents_pass_the_application__pass-the-app-styles__image {
    height: auto;
    width: 75%;
    margin-bottom: 32px;
  }
  
  .ladder_agents_pass_the_application__pass-the-app-styles__wrapper {
    flex-direction: column;
    padding: 16px;
  }
  
  .ladder_agents_pass_the_application__pass-the-app-styles__w-50 {
    width: 100%;
  }

}
.ladder_savings_steps__panel-styles__wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.ladder_savings_steps__panel-styles__background-color-spring-wood {
  background-color: #F7F2EF;
}

@media (min-width: 1025px) {

  
  
  .ladder_savings_steps__panel-styles__reverse {
    flex-direction: row-reverse;
  }
  
  .ladder_savings_steps__panel-styles__text {
    width: 50%;
    align-self: center;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_savings_steps__panel-styles__reverse {
    flex-direction: row-reverse;
  }
  
  .ladder_savings_steps__panel-styles__text {
    width: 50%;
    align-self: center;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_savings_steps__panel-styles__wrapper {
    flex-direction: column-reverse;
    justify-content: center;
    width: 100%;
    padding: 0 5%;
  }

}
.ladder_beneficiary_form__beneficiary-action-card-styles__action-text {
  display: flex;
  flex-direction: row;
  padding-right: 20px;
}

.ladder_beneficiary_form__beneficiary-action-card-styles__text {
  padding-right: 10.0px;
}

.ladder_beneficiary_form__beneficiary-action-card-styles__action {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ladder_beneficiary_form__beneficiary-action-card-styles__action-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ladder_beneficiary_form__beneficiary-action-card-styles__border-bottom {
  border-bottom: 1px solid #D0D0D0;
}

@media (max-width: 499px) {

  
  
  .ladder_beneficiary_form__beneficiary-action-card-styles__action {
    flex-direction: column;
    align-items: space-between;
  }
  
  .ladder_beneficiary_form__beneficiary-action-card-styles__action-text {
    flex-direction: column;
  }
  
  .ladder_beneficiary_form__beneficiary-action-card-styles__action-content {
    align-items: flex-start;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_beneficiary_form__beneficiary-action-card-styles__action {
    flex-direction: column;
    align-items: space-between;
  }
  
  .ladder_beneficiary_form__beneficiary-action-card-styles__action-text {
    flex-direction: column;
  }
  
  .ladder_beneficiary_form__beneficiary-action-card-styles__action-content {
    align-items: flex-start;
  }

}
.ladder_ux_modal__spacer-styles__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ladder_ux_modal__spacer-styles__s {
  padding: 8.0px 0;
}

.ladder_ux_modal__spacer-styles__m {
  padding: 16px 0;
}

.ladder_ux_modal__spacer-styles__l {
  padding: 32px 0;
}

.ladder_ux_modal__spacer-styles__xl {
  padding: 64px 0;
}

.ladder_ux_modal__spacer-styles__align-items-left {
  align-items: flex-start;
}

.ladder_ux_modal__spacer-styles__align-items-right {
  align-items: end;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_modal__spacer-styles__s {
    padding: 4.0px 0;
  }
  
  .ladder_ux_modal__spacer-styles__m {
    padding: 8.0px 0;
  }
  
  .ladder_ux_modal__spacer-styles__l {
    padding: 16px 0;
  }
  
  .ladder_ux_modal__spacer-styles__xl {
    padding: 32px 0;
  }

}
.ladder_agents_dashboard_learn__textblock-styles__text-container {
  display: flex;
  flex-direction: column;
}
.ladder_account_next_views_profile_view__field-styles__field {
  flex-basis: 100%;
  overflow-wrap: anywhere;
  margin-bottom: 30px;
}

@media (min-width: 1025px) {

  
  
  .ladder_account_next_views_profile_view__field-styles__field {
    flex-basis: calc(50% - 8px);
  }
  
  .ladder_account_next_views_profile_view__field-styles__field:nth-child(odd) {
    margin-right: 16px;
  }

}
.ladder_megaphone_landing__whisper-image-panel-styles__root {
  display: flex;
  align-content: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1500px;
  box-sizing: border-box;
}

.ladder_megaphone_landing__whisper-image-panel-styles__image {
  width: auto;
  height: auto;
  max-width: 90%;
}

.ladder_megaphone_landing__whisper-image-panel-styles__rungs-root {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  width: 100%;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_megaphone_landing__whisper-image-panel-styles__whisper-image {
    width: 200px;
    height: 200px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_megaphone_landing__whisper-image-panel-styles__whisper-image {
    width: 100px;
    height: 100px;
  }

}
.ladder_ux_animate__slide-up-in-and-out-styles__slide-up-enter {
  position: absolute;
  opacity: 0;
  top: 40px;
}

.ladder_ux_animate__slide-up-in-and-out-styles__slide-up-enter.ladder_ux_animate__slide-up-in-and-out-styles__slide-up-enter-active {
  transition: all 400ms ease-in;
  opacity: 1;
  top: 0px;
}

.ladder_ux_animate__slide-up-in-and-out-styles__slide-up-appear {
  position: absolute;
  opacity: 0;
  top: 40px;
}

.ladder_ux_animate__slide-up-in-and-out-styles__slide-up-appear.ladder_ux_animate__slide-up-in-and-out-styles__slide-up-appear-active {
  transition: all 400ms ease-in;
  opacity: 1;
  top: 0px;
}

.ladder_ux_animate__slide-up-in-and-out-styles__slide-up-leave {
  position: absolute;
  opacity: 1;
  top: 0px;
}

.ladder_ux_animate__slide-up-in-and-out-styles__slide-up-leave.ladder_ux_animate__slide-up-in-and-out-styles__slide-up-leave-active {
  transition: all 300ms ease-out;
  opacity: 0;
  top: -45px;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_animate__slide-up-in-and-out-styles__slide-up-enter {
    top: 20px;
  }
  
  .ladder_ux_animate__slide-up-in-and-out-styles__slide-up-enter.ladder_ux_animate__slide-up-in-and-out-styles__slide-up-enter-active {
    transition: all 300ms ease-in;
  }
  
  .ladder_ux_animate__slide-up-in-and-out-styles__slide-up-appear {
    top: 20px;
  }
  
  .ladder_ux_animate__slide-up-in-and-out-styles__slide-up-appear.ladder_ux_animate__slide-up-in-and-out-styles__slide-up-appear-active {
    transition: all 300ms ease-in;
  }
  
  
  
  .ladder_ux_animate__slide-up-in-and-out-styles__slide-up-leave.ladder_ux_animate__slide-up-in-and-out-styles__slide-up-leave-active {
    transition: all 200ms ease-in;
    top: -25px;
  }

}
.ladder_nearly_perfect_quote_common__estimated-price-styles__copy {
  line-height: 30px;
  flex-grow: 0;
  font-weight: 400;
  order: 1;
  overflow: auto;
  font-style: normal;
  flex: none;
  color: black;
  padding-top: 10px;
}

.ladder_nearly_perfect_quote_common__estimated-price-styles__copy .ladder_nearly_perfect_quote_common__estimated-price-styles__bold {
  font-weight: 600;
}

.ladder_nearly_perfect_quote_common__estimated-price-styles__citation {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  color: #5D5D5D;
}

.ladder_nearly_perfect_quote_common__estimated-price-styles__estimated-price-container {
  border-radius: 8px;
  width: 100%;
}

.ladder_nearly_perfect_quote_common__estimated-price-styles__estimated-price {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ladder_nearly_perfect_quote_common__estimated-price-styles__estimated-price.ladder_nearly_perfect_quote_common__estimated-price-styles__monthly {
  padding: 10.0px 25.0px 10.0px 25.0px;
}

.ladder_nearly_perfect_quote_common__estimated-price-styles__estimated-price.ladder_nearly_perfect_quote_common__estimated-price-styles__row {
  flex-direction: row;
}

.ladder_nearly_perfect_quote_common__estimated-price-styles__normal-border {
  border: 2px solid #D1A98F;
  background-color: #F7F2EF;
}

.ladder_nearly_perfect_quote_common__estimated-price-styles__affordability-border {
  border: 2px solid #91AF92;
  background-color: #DBE6DB;
}

.ladder_nearly_perfect_quote_common__estimated-price-styles__neutral-border {
  border: 2px solid #BDBDBD;
}

.ladder_nearly_perfect_quote_common__estimated-price-styles__spinner-max-height {
  max-height: 72px;
}

.ladder_nearly_perfect_quote_common__estimated-price-styles__npq-guide-modal-header {
  border-bottom: #F4F4F4 solid 1px;
  padding-bottom: 22.5px;
  padding-right: 45px;
  padding-top: 22.5px;
}

.ladder_nearly_perfect_quote_common__estimated-price-styles__npq-guide-modal-header .ladder_nearly_perfect_quote_common__estimated-price-styles__npq-guide-modal-header-text {
  line-height: normal;
  color: black;
}

.ladder_nearly_perfect_quote_common__estimated-price-styles__subsection {
  flex: 1;
  padding: 10.0px 25.0px 10.0px 25.0px;
}

.ladder_nearly_perfect_quote_common__estimated-price-styles__subsection.ladder_nearly_perfect_quote_common__estimated-price-styles__left-border {
  border-left: inherit;
}

.ladder_nearly_perfect_quote_common__estimated-price-styles__subsection.ladder_nearly_perfect_quote_common__estimated-price-styles__expand {
  display: flex;
  flex: unset;
  align-self: stretch;
  align-items: center;
}

@media (max-width: 499px) {

  
  
  .ladder_nearly_perfect_quote_common__estimated-price-styles__subsection {
    padding: 10.0px;
  }
  
  .ladder_nearly_perfect_quote_common__estimated-price-styles__subsection.ladder_nearly_perfect_quote_common__estimated-price-styles__expand {
    flex: 1;
  }
  
  .ladder_nearly_perfect_quote_common__estimated-price-styles__estimated-price.ladder_nearly_perfect_quote_common__estimated-price-styles__monthly {
    padding: 10.0px;
  }
  
  .ladder_nearly_perfect_quote_common__estimated-price-styles__estimated-price.ladder_nearly_perfect_quote_common__estimated-price-styles__monthly.ladder_nearly_perfect_quote_common__estimated-price-styles__expand {
    flex: 1;
  }

}
.ladder_agents_dashboard_quote__share-modal-styles__share-modal {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  margin-top: 20px;
}

.ladder_agents_dashboard_quote__share-modal-styles__share-quote-error-text {
  display: flex;
  width: 100%;
  justify-content: center;
}

.ladder_agents_dashboard_quote__share-modal-styles__add-new-contact-link {
  display: flex;
  justify-content: center;
}

.ladder_agents_dashboard_quote__share-modal-styles__quoter-button {
  width: 100%;
  filter: drop-shadow(0 3px 6px rgba(37, 112, 170, 0.25));
}

.ladder_agents_dashboard_quote__share-modal-styles__quoter-button-text {
  font-size: 15px;
}
.ladder_account_next_views_messages__Root-styles__\% {
  height: 100%;
  position: relative;
}

.ladder_account_next_views_messages__Root-styles__title {
  margin-left: 5.0px;
  margin-bottom: 30.0px;
}

.ladder_account_next_views_messages__Root-styles__breadcrumbs-wrapper {
  height: 32px;
}

@media (max-width: 499px) {

  
  
  .ladder_account_next_views_messages__Root-styles__breadcrumbs-wrapper {
    height: unset;
    padding: 10.0px 0;
  }
  
  .ladder_account_next_views_messages__Root-styles__\% {
    min-height: 300px;
  }

}
.ladder_cap_and_accel_post_sign_view__Modal-styles__button-bar {
  padding-top: 32px;
}

.ladder_cap_and_accel_post_sign_view__Modal-styles__hr {
  margin-top: 20px;
  margin-bottom: 20px;
}
.ladder_name_your_price_views__name-your-price-styles__outer-page-container {
  display: flex;
  justify-content: center;
}

.ladder_name_your_price_views__name-your-price-styles__inner-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 36px;
  width: 100%;
  max-width: 1500px;
}

.ladder_name_your_price_views__name-your-price-styles__header-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  gap: 20px;
  max-width: 80%;
  text-align: center;
}

.ladder_name_your_price_views__name-your-price-styles__border-container {
  display: flex;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid #D9D9D9;
  width: 90%;
}

.ladder_name_your_price_views__name-your-price-styles__selection-container {
  display: flex;
  flex-direction: column;
  gap: 26px;
  margin: 26px 0;
  width: 90%;
}

.ladder_name_your_price_views__name-your-price-styles__selection-header-container-wrapper {
  display: flex;
  justify-content: center;
}

.ladder_name_your_price_views__name-your-price-styles__selection-header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  gap: 8px;
  height: 55px;
  background-color: #F1F1F1;
  border-radius: 13.785px;
}

.ladder_name_your_price_views__name-your-price-styles__budget-or-coverage-container {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 30px;
}

.ladder_name_your_price_views__name-your-price-styles__cta-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  max-width: 90%;
}

.ladder_name_your_price_views__name-your-price-styles__values-under-slider {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.ladder_name_your_price_views__name-your-price-styles__slider-class {
  color: #464F50;
}

.ladder_name_your_price_views__name-your-price-styles__spinner-container {
  display: flex;
  justify-content: center;
}

@media (min-width: 0px) and (max-width: 850px) {

  .ladder_name_your_price_views__name-your-price-styles__header-text {
    margin-top: 25px;
    max-width: 90%;
  }
  
  .ladder_name_your_price_views__name-your-price-styles__budget-or-coverage-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    margin-bottom: 10px;
  }
  
  .ladder_name_your_price_views__name-your-price-styles__cta-container {
    width: 80%;
  }
  
  .ladder_name_your_price_views__name-your-price-styles__values-under-slider {
    margin-top: 10px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_name_your_price_views__name-your-price-styles__selection-header-container {
    gap: 2px;
  }

}
.ladder_ny_form_review__agreement-section-styles__copy {
  text-align: left;
}
.ladder_account_policy__modify-coverage-buttons-styles__root {
  display: flex;
}

.ladder_account_policy__modify-coverage-buttons-styles__entry {
  margin-left: 5.0px;
  margin-right: 5.0px;
  margin-top: 20px;
}

@media (max-width: 499px) {

  
  
  .ladder_account_policy__modify-coverage-buttons-styles__root {
    flex-direction: column;
    width: 90%;
  }
  
  .ladder_account_policy__modify-coverage-buttons-styles__entry {
    margin: 20px 0 0;
  }

}
.ladder_ny_form_traffic_diversion__divider-styles__root {
  max-width: 450px;
  width: 80%;
  border-bottom: 1px solid gray;
}
.ladder_guide_common__colored-quote-section-styles__container {
  width: 100%;
  position: relative;
  margin-top: 60px;
  padding-top: 60px;
  padding-bottom: 60px;
}

.ladder_guide_common__colored-quote-section-styles__markdown-container {
  position: relative;
}
.ladder_beneficiary_form_delete_beneficiary_modal__buttons-styles__\% {
  max-width: 384px;
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-self: center;
}

.ladder_beneficiary_form_delete_beneficiary_modal__buttons-styles__\%>*:first-child {
  margin-top: 0px;
}

.ladder_beneficiary_form_delete_beneficiary_modal__buttons-styles__\%>* {
  margin-top: 20px;
}
.ladder_account_next_cards_your_total_coverage_summary__error-banner-row-styles__error-row {
  width: auto;
  gap: 25px;
  justify-content: space-between;
  margin: 0 -50px;
  align-items: center;
  padding: 15.0px 15.0px 15.0px 50.0px;
  flex-direction: row;
  background-color: #EEA4A4;
  display: flex;
}

.ladder_account_next_cards_your_total_coverage_summary__error-banner-row-styles__decrease-bottom-margin {
  margin-bottom: -40px;
}

.ladder_account_next_cards_your_total_coverage_summary__error-banner-row-styles__error-row-entry {
  flex-grow: 1;
}

@media (max-width: 499px) {

  
  
  .ladder_account_next_cards_your_total_coverage_summary__error-banner-row-styles__error-row {
    flex-direction: column;
    margin: 0 -15px;
    padding: 15.0px 15.0px;
  }
  
  .ladder_account_next_cards_your_total_coverage_summary__error-banner-row-styles__increase-bottom-margin {
    margin-bottom: 22.5px;
  }
  
  .ladder_account_next_cards_your_total_coverage_summary__error-banner-row-styles__error-row-entry {
    width: 100%;
    text-align: center;
  }

}
.ladder_beneficiary_form__beneficiary-entry-person-styles__more-actions {
  padding: 0 22.5px;
}

@media (max-width: 499px) {

  
  
  .ladder_beneficiary_form__beneficiary-entry-person-styles__more-actions {
    padding: 0;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_beneficiary_form__beneficiary-entry-person-styles__more-actions {
    padding: 0;
  }

}
.ladder_guide_how_much_life_insurance__quote-picture-panel-styles__container {
  width: 100%;
  background-color: #E9E1D3;
  margin-top: 60px;
  position: relative;
  display: flex;
}

.ladder_guide_how_much_life_insurance__quote-picture-panel-styles__bottom-text-wrapper {
  padding: 16px 0px;
}

.ladder_guide_how_much_life_insurance__quote-picture-panel-styles__image {
  position: absolute;
}

@media (min-width: 1025px) {

  
  
  .ladder_guide_how_much_life_insurance__quote-picture-panel-styles__container {
    height: 528px;
    padding-right: 60px;
  }
  
  .ladder_guide_how_much_life_insurance__quote-picture-panel-styles__flex-markdown-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  
  .ladder_guide_how_much_life_insurance__quote-picture-panel-styles__image {
    height: 528px;
    left: -5%;
    top: 0;
  }
  
  .ladder_guide_how_much_life_insurance__quote-picture-panel-styles__image-container {
    min-width: 511px;
  }
  
  .ladder_guide_how_much_life_insurance__quote-picture-panel-styles__markdown-container {
  
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_guide_how_much_life_insurance__quote-picture-panel-styles__container {
    padding-top: 1%;
    padding-bottom: 45%;
  }
  
  .ladder_guide_how_much_life_insurance__quote-picture-panel-styles__markdown-container {
    margin-right: 60px;
    margin-left: 60px;
    margin-top: 60px;
  }
  
  .ladder_guide_how_much_life_insurance__quote-picture-panel-styles__image {
    width: 78%;
    bottom: 0;
    left: -6%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_guide_how_much_life_insurance__quote-picture-panel-styles__markdown-container {
    position: relative;
  }
  
  .ladder_guide_how_much_life_insurance__quote-picture-panel-styles__image {
    position: absolute;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_guide_how_much_life_insurance__quote-picture-panel-styles__markdown-container {
    position: relative;
  }
  
  .ladder_guide_how_much_life_insurance__quote-picture-panel-styles__image {
    position: absolute;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_guide_how_much_life_insurance__quote-picture-panel-styles__container {
    padding-top: 60px;
    padding-bottom: 70%;
  }
  
  .ladder_guide_how_much_life_insurance__quote-picture-panel-styles__markdown-container {
    width: 90%;
  }
  
  .ladder_guide_how_much_life_insurance__quote-picture-panel-styles__flex-markdown-container {
    display: flex;
    justify-content: center;
  }
  
  .ladder_guide_how_much_life_insurance__quote-picture-panel-styles__image-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  
  .ladder_guide_how_much_life_insurance__quote-picture-panel-styles__image {
    bottom: 0;
    left: -15%;
    width: 100%;
  }

}
.ladder_agents_signup_success__signup-form-styles__wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.ladder_agents_signup_success__signup-form-styles__get-started {
  background-color: #EFEAE1;
  width: 40%;
}

.ladder_agents_signup_success__signup-form-styles__get-started-image-container {
  max-width: 300px;
  width: 90%;
  margin: 0 auto;
}

.ladder_agents_signup_success__signup-form-styles__get-started-image {
  width: 100%;
  height: auto;
}

.ladder_agents_signup_success__signup-form-styles__form {
  width: 60%;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_signup_success__signup-form-styles__wrapper {
    flex-direction: column;
  }
  
  .ladder_agents_signup_success__signup-form-styles__get-started {
    width: 100%;
  }
  
  .ladder_agents_signup_success__signup-form-styles__form {
    width: 100%;
    background-color: #EFEAE1;
  }
  
  .ladder_agents_signup_success__signup-form-styles__form {
    width: 100%;
  }

}
.ladder_ux_logos__sofi-logo-styles__icon {
  height: 72.0px;
  width: auto;
  margin-bottom: 7px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_logos__sofi-logo-styles__icon {
    height: 65.6px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_logos__sofi-logo-styles__icon {
    height: 64px;
  }

}
.ladder_account_policy__add-beneficiary-prompt-styles__root {
  text-align: center;
  margin-top: 30.0px;
}
.ladder_guide_common__BottomNavPanel-styles__container {
  width: 100%;
  background-color: #F7F2EF;
  padding-top: 130.0px;
  padding-bottom: 60px;
  justify-content: center;
  margin-bottom: -100px;
}

.ladder_guide_common__BottomNavPanel-styles__inner-container {
  width: 100%;
  text-align: center;
  margin: auto;
  max-width: 800px;
}

.ladder_guide_common__BottomNavPanel-styles__subtext {
  margin-top: 20px;
  line-height: 30px;
}

.ladder_guide_common__BottomNavPanel-styles__button-container {
  margin: auto;
  margin-top: 20px;
  width: 362px;
}

.ladder_guide_common__BottomNavPanel-styles__buttons-container {
  margin-top: 30.0px;
}

.ladder_guide_common__BottomNavPanel-styles__link-container {
  margin-top: 30.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_guide_common__BottomNavPanel-styles__container {
    padding-top: 60px;
  }
  
  .ladder_guide_common__BottomNavPanel-styles__inner-container {
    width: 90%;
  }
  
  .ladder_guide_common__BottomNavPanel-styles__button-container {
    width: 94%;
  }

}
.ladder_aimcor_transfer__case-details-styles__copy {
  margin: 25.0px 0 30.0px;
}

.ladder_aimcor_transfer__case-details-styles__details {
  display: flex;
  flex-wrap: wrap;
}

.ladder_aimcor_transfer__case-details-styles__detail {
  margin-bottom: 25.0px;
}

.ladder_aimcor_transfer__case-details-styles__case-number {
  background-color: #F7F2EF;
  padding: 5px;
}

.ladder_aimcor_transfer__case-details-styles__edit {
  margin-left: 20px;
}

@media (min-width: 1025px) {

  
  
  .ladder_aimcor_transfer__case-details-styles__detail {
    width: 50%;
  }
  
  .ladder_aimcor_transfer__case-details-styles__back-to-term {
    margin-bottom: 30.0px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_aimcor_transfer__case-details-styles__detail {
    width: 50%;
  }
  
  .ladder_aimcor_transfer__case-details-styles__back-to-term {
    margin-bottom: 30.0px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_aimcor_transfer__case-details-styles__copy {
    margin: 15.0px 0;
  }
  
  .ladder_aimcor_transfer__case-details-styles__details {
    flex-direction: column;
  }
  
  
  
  .ladder_aimcor_transfer__case-details-styles__buttons>*:first-child {
    display: block;
    margin-bottom: 15.0px;
  }

}
.ladder_ux_homepage_returners_core__returners-journey-progress-bar-styles__bars-container {
  position: relative;
  width: 192px;
  height: 0.4vw;
  margin-top: 16px;
}

.ladder_ux_homepage_returners_core__returners-journey-progress-bar-styles__total-bar {
  display: flex;
  position: relative;
  flex-grow: 1;
  height: 100%;
  z-index: 1;
  margin-top: 16px;
  border-radius: 6px;
  background-color: #D9D9D9;
}

.ladder_ux_homepage_returners_core__returners-journey-progress-bar-styles__filled-bar {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  border-radius: 6px;
  background-color: #AFC5C8;
}

.ladder_ux_homepage_returners_core__returners-journey-progress-bar-styles__completion-text {
  color: #464F50;
}

.ladder_ux_homepage_returners_core__returners-journey-progress-bar-styles__not-returner-link {
  margin-top: 64px;
  text-decoration: underline;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_homepage_returners_core__returners-journey-progress-bar-styles__bars-container {
    width: 100%;
    height: 8px;
  }

}
.ladder_agents_dashboard_referral_link__tracking-link-faqs-styles__tracking-horizontal-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.ladder_agents_dashboard_referral_link__tracking-link-faqs-styles__tracking-vertical-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.ladder_agents_dashboard_referral_link__tracking-link-faqs-styles__faq-text {
  width: 80%;
}

.ladder_agents_dashboard_referral_link__tracking-link-faqs-styles__faq-shadow {
  width: 300px;
  justify-content: center;
  height: 84px;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  moz-border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 20px;
  background-color: #FFFFFF;
  border-radius: 30px;
  display: flex;
  webkit-border-radius: 30px;
}

.ladder_agents_dashboard_referral_link__tracking-link-faqs-styles__faq-shadow-expanded {
  width: 300px;
  justify-content: center;
  height: 100%;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  moz-border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 20px;
  background-color: #FFFFFF;
  border-radius: 30px;
  display: flex;
  webkit-border-radius: 30px;
}

.ladder_agents_dashboard_referral_link__tracking-link-faqs-styles__tracking-link-faqs {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-top: 3em;
  justify-content: space-between;
}

.ladder_agents_dashboard_referral_link__tracking-link-faqs-styles__expandable-box-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1em;
}

.ladder_agents_dashboard_referral_link__tracking-link-faqs-styles__tracking-link-text {
  font-size: 18px;
  line-height: 30px;
}

@media (min-width: 1025px) {

  
  
  .ladder_agents_dashboard_referral_link__tracking-link-faqs-styles__container {
    display: flex;
    padding: 2em;
    width: 600px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard_referral_link__tracking-link-faqs-styles__container {
    display: flex;
    padding: 2em;
    width: 350px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_dashboard_referral_link__tracking-link-faqs-styles__container {
    display: flex;
    padding: 2em;
    width: 350px;
  }

}
.ladder_view_schedule__date-picker-styles__calendar-root {
  display: flex;
}

.ladder_view_schedule__date-picker-styles__months {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.ladder_view_schedule__date-picker-styles__button {
  margin-top: 20px;
}

.ladder_view_schedule__date-picker-styles__container {
  text-align: center;
}

.ladder_view_schedule__date-picker-styles__special-instructions {
  margin-bottom: 20px;
  margin: 0 auto;
  width: 80%;
}

@media (min-width: 1025px) {

  
  
  .ladder_view_schedule__date-picker-styles__calendar-root {
    border-top: 1px solid #D0D0D0;
    margin-bottom: 60px;
  }
  
  .ladder_view_schedule__date-picker-styles__months {
    margin-top: 30.0px;
    padding-right: 30.0px;
    border-right: 1px solid #D0D0D0;
  }
  
  .ladder_view_schedule__date-picker-styles__times {
    padding: 30.0px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_view_schedule__date-picker-styles__calendar-root {
    border-top: 1px solid #D0D0D0;
    margin-bottom: 60px;
  }
  
  .ladder_view_schedule__date-picker-styles__months {
    margin-top: 30.0px;
    padding-right: 30.0px;
    border-right: 1px solid #D0D0D0;
  }
  
  .ladder_view_schedule__date-picker-styles__times {
    padding: 30.0px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_view_schedule__date-picker-styles__months {
    justify-content: center;
    border-bottom: 1px solid #D0D0D0;
  }
  
  .ladder_view_schedule__date-picker-styles__calendar-root {
    display: flex;
    flex-direction: column;
  }
  
  .ladder_view_schedule__date-picker-styles__times {
    margin-top: 20px;
  }

}
.ladder_ux_radio_input__radio-input-styles__root {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ladder_ux_radio_input__radio-input-styles__root-transparent-button {
  padding: 0;
}

.ladder_ux_radio_input__radio-input-styles__full-width {
  width: 100%;
  justify-content: space-between;
}

.ladder_ux_radio_input__radio-input-styles__height-button {
  padding: 0;
  height: 48px;
}

.ladder_ux_radio_input__radio-input-styles__height-s {
  padding: 0;
  height: 40px;
}

.ladder_ux_radio_input__radio-input-styles__column-wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
}

.ladder_ux_radio_input__radio-input-styles__row-wrapper {
  display: flex;
  width: 100%;
}

.ladder_ux_radio_input__radio-input-styles__row-wrapper > *:not(:last-child) {
  margin-right: 16px;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_radio_input__radio-input-styles__column-wrapper {
    gap: 8px;
  }

}
.ladder_ny_form_review__question-styles__root {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.ladder_ny_form_review__question-styles__pencil {
  height: 20px;
  width: 20px;
  flex-shrink: 0;
  padding-left: 5.0px;
}
.ladder_ny_form_review__allianz-logo-styles__allianz-logo {
  text-align: left;
  margin-left: auto;
  line-height: 16px;
  font-size: 12px;
}

.ladder_ny_form_review__allianz-logo-styles__allianz-logo img {
  width: 133px;
}

@media (max-width: 499px) {

  
  
  .ladder_ny_form_review__allianz-logo-styles__allianz-logo {
    margin: 10px 0;
  }

}
.ladder_financial_advisors_laddering__customer-quote-image-styles__image {
  width: 100%;
}
.ladder_ux_button__buttons-container-styles__buttons-container {
  display: flex;
  box-sizing: content-box;
  align-items: center;
  padding: 16px;
  justify-content: center;
}

.ladder_ux_button__buttons-container-styles__stacked {
  flex-direction: column;
}

.ladder_ux_button__buttons-container-styles__button-container {
  padding: 8.0px;
}

.ladder_ux_button__buttons-container-styles__full-width {
  width: 100%;
}

.ladder_ux_button__buttons-container-styles__justify-content-start {
  justify-content: flex-start;
}

.ladder_ux_button__buttons-container-styles__justify-content-end {
  justify-content: flex-end;
}

.ladder_ux_button__buttons-container-styles__full-width-container {
  width: 100%;
}

.ladder_ux_button__buttons-container-styles__row-reverse {
  flex-direction: row-reverse;
}

@media (min-width: 1025px) {

  
  
  .ladder_ux_button__buttons-container-styles__stacked-reversed {
    flex-direction: column-reverse;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_button__buttons-container-styles__full-width-container {
    padding-left: 0;
    padding-right: 0;
  }
  
  .ladder_ux_button__buttons-container-styles__full-width-container-mobile-only {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
  
  .ladder_ux_button__buttons-container-styles__full-width-mobile-only {
    width: 100%;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_button__buttons-container-styles__buttons-container {
    padding: 8.0px;
  }
  
  .ladder_ux_button__buttons-container-styles__mobile-stacked {
    flex-direction: column;
  }
  
  .ladder_ux_button__buttons-container-styles__mobile-stacked-reversed {
    flex-direction: column-reverse;
  }
  
  .ladder_ux_button__buttons-container-styles__full-width-container {
    padding-left: 0;
    padding-right: 0;
  }
  
  .ladder_ux_button__buttons-container-styles__full-width-container-mobile-only {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
  
  .ladder_ux_button__buttons-container-styles__full-width-mobile-only {
    width: 100%;
  }
  
  .ladder_ux_button__buttons-container-styles__mobile-justify-content-center {
    justify-content: center;
  }
  
  .ladder_ux_button__buttons-container-styles__mobile-justify-space-between {
    justify-content: space-between;
  }
  
  .ladder_ux_button__buttons-container-styles__side-by-side {
    flex-direction: row;
    width: 100%;
  }
  
  .ladder_ux_button__buttons-container-styles__side-by-side-reverse {
    flex-direction: row-reverse;
    width: 100%;
  }

}

.ladder_ux_button__buttons-container-styles__left-align-in-modal {
  padding-left: 0;
  align-items: flex-start;
}
.ladder_agents_dashboard_inputs__form-select-styles__dash {
  border-radius: 24px;
  border: 1px solid #D0D0D0;
  padding: 20px;
}

.ladder_agents_dashboard_inputs__form-select-styles__quote-modal {
  border-radius: 24px;
  border: 1px solid #D0D0D0;
  padding: 20px;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_dashboard_inputs__form-select-styles__dash {
    width: 70%;
  }
  
  .ladder_agents_dashboard_inputs__form-select-styles__quote-modal {
    width: 100%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_agents_dashboard_inputs__form-select-styles__dash {
    width: 70%;
  }
  
  .ladder_agents_dashboard_inputs__form-select-styles__quote-modal {
    width: 100%;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_agents_dashboard_inputs__form-select-styles__dash {
    width: 500px;
  }
  
  .ladder_agents_dashboard_inputs__form-select-styles__quote-modal {
    width: 650px;
  }

}
.ladder_financial_advisors_home__satisfied-clients-body-styles__\% {
  margin-top: 40px;
}

@media (min-width: 1025px) {

  
  
  .ladder_financial_advisors_home__satisfied-clients-body-styles__\% {
    margin-top: 20px;
    max-width: 1100px;
  }

}
.ladder_ux_logos__quotacy-logo-styles__half-width {
  width: 50%;
}

.ladder_ux_logos__quotacy-logo-styles__quarter-width {
  width: 25%;
}

@media (max-width: 499px) {

  
  
  .ladder_ux_logos__quotacy-logo-styles__quarter-width {
    width: 50%;
  }

}
.ladder_financial_advisors_home__allianz-logo-styles__\% {
  flex: 0 1 250px;
}

.ladder_financial_advisors_home__allianz-logo-styles__\%>img {
  width: 100%;
  height: auto;
  max-width: 100%;
}
.ladder_ux_policy_card__inset-card-internal-structure-styles__root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
}

.ladder_ux_policy_card__inset-card-internal-structure-styles__title {
  margin-bottom: auto;
  margin-top: 12.0px;
}

.ladder_ux_policy_card__inset-card-internal-structure-styles__container {
  display: flex;
  flex-grow: 1;
  margin-bottom: auto;
  flex-direction: column;
  justify-content: center;
}
.ladder_account_shared_components__field-answer-pair-column-styles__root {
  width: 50%;
  box-sizing: border-box;
}

@media (min-width: 1025px) {

  
  
  .ladder_account_shared_components__field-answer-pair-column-styles__root {
    margin: 20px 0;
    padding: 0 22.5px;
  }
  
  .ladder_account_shared_components__field-answer-pair-column-styles__root:not(:last-child) {
    border-right: 1px solid #D0D0D0;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_account_shared_components__field-answer-pair-column-styles__root {
    width: initial;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account_shared_components__field-answer-pair-column-styles__root {
    width: initial;
  }

}
.ladder_form_common__breadcrumbs-builder-styles__align-h {
  display: flex;
  align-items: center;
}

.ladder_form_common__breadcrumbs-builder-styles__padding-top {
  padding-top: 15.0px;
}

.ladder_form_common__breadcrumbs-builder-styles__icon {
  margin: 0 8px;
}
.ladder_ux_logos__social-icon-styles__root {
  height: 50px;
  display: flex;
  justify-content: center;
}

.ladder_ux_logos__social-icon-styles__set-width {
  width: 50px;
}

.ladder_ux_logos__social-icon-styles__icon {
  height: 50%;
}
.ladder_ux_form__end-labels-styles__end-labels-root {
  display: flex;
  height: 14px;
  margin-bottom: 4px;
  color: #BDBDBD;
  justify-content: space-between;
}

@media (min-width: 1025px) {

  
  
  .ladder_ux_form__end-labels-styles__fade {
    opacity: 0;
  }
  
  .ladder_ux_form__end-labels-styles__first-option {
    transform: translateX(-50%);
  }
  
  .ladder_ux_form__end-labels-styles__last-option {
    transform: translateX(50%);
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_form__end-labels-styles__fade {
    opacity: 0;
  }
  
  .ladder_ux_form__end-labels-styles__first-option {
    transform: translateX(-50%);
  }
  
  .ladder_ux_form__end-labels-styles__last-option {
    transform: translateX(50%);
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_form__end-labels-styles__end-labels-root {
    height: 0px;
  }
  
  .ladder_ux_form__end-labels-styles__option-hidden {
    display: none;
  }

}
.ladder_account_next_cards_base__card-title-styles__\% {
  width: 100%;
}

@media (min-width: 1025px) {

  
  
  .ladder_account_next_cards_base__card-title-styles__mobile-only {
    display: none;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account_next_cards_base__card-title-styles__mobile-only {
    display: none;
  }

}
.ladder_ux_logos__covered-logo-styles__icon {
  height: 44.8px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_ux_logos__covered-logo-styles__icon {
    height: 38.4px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_ux_logos__covered-logo-styles__icon {
    height: 28.8px;
  }

}
.ladder_widget_ui_v2__back-button-styles__root {
  flex-grow: 1;
  align-items: flex-end;
  display: flex;
}

.ladder_widget_ui_v2__back-button-styles__button {
  width: 110px;
}
.ladder_views_next__error-boundary-styles__root {
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ladder_views_next__error-boundary-styles__flex {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.ladder_views_next__error-boundary-styles__flex >* {
  flex-grow: 1;
}
.ladder_account_shared_components__rungs-info-card-styles__root {
  border: 1px solid #808080;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 25.0px 50.0px;
}

.ladder_account_shared_components__rungs-info-card-styles__root-margin {
  margin: 10.0px;
}

.ladder_account_shared_components__rungs-info-card-styles__header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: start;
  width: 100%;
  padding-bottom: 15.0px;
}

.ladder_account_shared_components__rungs-info-card-styles__footnote {
  margin-top: auto;
  width: 100%;
}

.ladder_account_shared_components__rungs-info-card-styles__padded {
  margin-left: auto;
}

.ladder_account_shared_components__rungs-info-card-styles__root-content {
  width: 100%;
  padding-bottom: 22.5px;
  max-width: 1100px;
  justify-content: space-between;
  height: 100%;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
  display: flex;
}

.ladder_account_shared_components__rungs-info-card-styles__root-content>* {
  width: 100%;
}

.ladder_account_shared_components__rungs-info-card-styles__height-s {
  padding: 10.0px 0;
}

.ladder_account_shared_components__rungs-info-card-styles__height-m {
  padding: 20px 0;
}

.ladder_account_shared_components__rungs-info-card-styles__height-l {
  padding: 30.0px 0;
}

.ladder_account_shared_components__rungs-info-card-styles__no-interior-padding {
  padding: 0;
}

.ladder_account_shared_components__rungs-info-card-styles__no-exterior-padding {
  padding: 0;
}

.ladder_account_shared_components__rungs-info-card-styles__no-border {
  border: 0;
}

.ladder_account_shared_components__rungs-info-card-styles__justify-content-start {
  justify-content: flex-start;
}

@media (max-width: 499px) {

  
  
  .ladder_account_shared_components__rungs-info-card-styles__root {
    padding: 0;
  }
  
  .ladder_account_shared_components__rungs-info-card-styles__header {
    flex-direction: column;
    padding: 10.0px 15.0px;
  }
  
  
  
  .ladder_account_shared_components__rungs-info-card-styles__root-content >* {
    padding-right: 15.0px;
    padding-left: 15.0px;
  }
  
  .ladder_account_shared_components__rungs-info-card-styles__footnote {
    padding: 10.0px 15.0px;
  }
  
  .ladder_account_shared_components__rungs-info-card-styles__padded {
    margin: 0;
  }

}
.ladder_ny_form_quick_estimate__quick-estimate-group-styles__with-ladder {
  white-space: nowrap;
  display: flex;
  justify-content: center;
}

.ladder_ny_form_quick_estimate__quick-estimate-group-styles__banner {
  text-align: center;
  padding-bottom: 22.5px;
}

@media (max-width: 499px) {

  
  
  .ladder_ny_form_quick_estimate__quick-estimate-group-styles__with-ladder {
    white-space: normal;
  }

}
.ladder_form_uplevel__uplevel-interstitial-styles__container {
  width: 100%;
}

.ladder_form_uplevel__uplevel-interstitial-styles__container-animation-control {
  animation: container-fade 500ms 1 ease forwards;
  animation-delay: 2500ms;
}

.ladder_form_uplevel__uplevel-interstitial-styles__mini-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.ladder_form_uplevel__uplevel-interstitial-styles__icon-container {
  height: 85px;
}

@keyframes container-fade {

  
  
0% {
    opacity: 1;
  }
  
  
  
100% {
    opacity: 0;
  }

}
.ladder_onboarding_spouse_connect_2__two-button-wrapper-styles__container {
  display: flex;
  flex-direction: row;
}

@media (max-width: 499px) {

  
  
  .ladder_onboarding_spouse_connect_2__two-button-wrapper-styles__container {
    display: flex;
    flex-direction: column;
  }

}
.ladder_laddering_page__what-is-laddering-styles__root {
  display: flex;
  width: 100%;
  height: 730px;
  overflow: hidden;
  background-color: #FFFFFF;
}

.ladder_laddering_page__what-is-laddering-styles__top-container {
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  max-width: 1400px;
  width: 90%;
}

.ladder_laddering_page__what-is-laddering-styles__text-container {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 50%;
  margin: 60px;
}

.ladder_laddering_page__what-is-laddering-styles__image {
  display: block;
  margin: 60px;
  width: 400px;
  height: 488px;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_laddering_page__what-is-laddering-styles__root {
    height: 800px;
  }
  
  .ladder_laddering_page__what-is-laddering-styles__top-container {
    flex-direction: column;
    width: 100%;
    margin: 130.0px 0 0 0;
  }
  
  .ladder_laddering_page__what-is-laddering-styles__text-container {
    width: 90%;
    max-width: 550px;
    margin: unset;
  }
  
  .ladder_laddering_page__what-is-laddering-styles__image {
    width: 300px;
    height: auto;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_laddering_page__what-is-laddering-styles__root {
    height: 800px;
  }
  
  .ladder_laddering_page__what-is-laddering-styles__top-container {
    flex-direction: column;
    width: 100%;
    margin: 130.0px 0 0 0;
  }
  
  .ladder_laddering_page__what-is-laddering-styles__text-container {
    width: 90%;
    max-width: 550px;
    margin: unset;
  }
  
  .ladder_laddering_page__what-is-laddering-styles__image {
    width: 300px;
    height: auto;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_laddering_page__what-is-laddering-styles__root {
    height: 600px;
  }
  
  .ladder_laddering_page__what-is-laddering-styles__top-container {
    height: 100%;
    margin: 20px 0 0 0;
  }
  
  .ladder_laddering_page__what-is-laddering-styles__image {
    width: 50%;
    margin: 60px auto 0 auto;
    height: auto;
  }

}
.ladder_hdyhau_lower_funnel__so-good-pictures-styles__root {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ladder_hdyhau_lower_funnel__so-good-pictures-styles__row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.ladder_hdyhau_lower_funnel__so-good-pictures-styles__row > div:not(:last-child) {
  padding-right: 15.0px;
}

.ladder_hdyhau_lower_funnel__so-good-pictures-styles__img-scale {
  max-width: 100%;
  height: auto;
}
.ladder_agents_dashboard__agent-phone-styles__ext {
  flex: 1;
  width: 6em;
}

.ladder_agents_dashboard__agent-phone-styles__phone {
  flex: 2;
}
@media (min-width: 1025px) {

  
  
  .ladder_about__investor-logos-styles__container {
    margin-top: 85px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_about__investor-logos-styles__container {
    margin-top: 67px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_about__investor-logos-styles__container {
    margin-top: 67px;
  }

}

.ladder_about__investor-logos-styles__logos {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 284px;
  align-self: center;
  justify-content: space-around;
}

@media (max-width: 499px) {

  
  
  .ladder_about__investor-logos-styles__logos {
    max-width: 321px;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_about__investor-logos-styles__logos {
    max-width: 867px;
  }

}

.ladder_about__investor-logos-styles__logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  width: 232px;
}

@media (max-width: 499px) {

  
  
  .ladder_about__investor-logos-styles__logo {
    width: 160px;
    margin-top: 50.0px;
  }
  
  .ladder_about__investor-logos-styles__tall-logo {
    margin-top: 30.0px;
  }

}

.ladder_about__investor-logos-styles__canaan {
  width: 139px;
  height: auto;
}

.ladder_about__investor-logos-styles__lightspeed {
  width: 181px;
  height: auto;
}

.ladder_about__investor-logos-styles__rre {
  width: 79px;
  height: auto;
}

.ladder_about__investor-logos-styles__nyca {
  width: 121px;
  height: auto;
}

.ladder_about__investor-logos-styles__brewer-lane {
  width: 188px;
  height: auto;
}

.ladder_about__investor-logos-styles__allianz {
  width: 189px;
  height: auto;
}

.ladder_about__investor-logos-styles__thomvest {
  width: 122px;
  height: auto;
}

.ladder_about__investor-logos-styles__northwestern {
  width: 230px;
  height: auto;
}

.ladder_about__investor-logos-styles__hudson {
  width: 232px;
  height: auto;
}

.ladder_about__investor-logos-styles__ally {
  width: 202px;
  height: auto;
}

.ladder_about__investor-logos-styles__world-innovation-lab {
  width: 259px;
  height: auto;
}

.ladder_about__investor-logos-styles__geodesic {
  width: 169px;
  height: auto;
}

.ladder_about__investor-logos-styles__ping-an {
  width: 84px;
  height: auto;
}

.ladder_about__investor-logos-styles__omers {
  width: 125px;
  height: auto;
}

@media (max-width: 499px) {

  
  
  .ladder_about__investor-logos-styles__canaan {
    width: 97px;
  }
  
  .ladder_about__investor-logos-styles__lightspeed {
    width: 127px;
  }
  
  .ladder_about__investor-logos-styles__rre {
    width: 55px;
  }
  
  .ladder_about__investor-logos-styles__nyca {
    width: 85px;
  }
  
  .ladder_about__investor-logos-styles__brewer-lane {
    width: 116px;
  }
  
  .ladder_about__investor-logos-styles__allianz {
    width: 132px;
  }
  
  .ladder_about__investor-logos-styles__thomvest {
    width: 85px;
  }
  
  .ladder_about__investor-logos-styles__northwestern {
    width: 160px;
  }
  
  .ladder_about__investor-logos-styles__hudson {
    width: 150px;
  }
  
  .ladder_about__investor-logos-styles__ally {
    width: 105px;
  }
  
  .ladder_about__investor-logos-styles__world-innovation-lab {
    width: 150px;
  }
  
  .ladder_about__investor-logos-styles__geodesic {
    width: 93px;
  }
  
  .ladder_about__investor-logos-styles__ping-an {
    width: 67px;
  }
  
  .ladder_about__investor-logos-styles__omers {
    width: 77px;
  }

}
.ladder_agents_background_check_complete__root-styles__image {
  max-width: 600px;
}

@media (max-width: 499px) {

  
  
  .ladder_agents_background_check_complete__root-styles__image {
    max-width: 320px;
  }

}
.ladder_account_policy__additional-actions-card-styles__root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ladder_account_policy__additional-actions-card-styles__entry {
  padding: 10.0px 0;
}
.ladder_views_api__phone-screenshot-styles__container {
  width: 320px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.ladder_views_api__phone-screenshot-styles__screenshot-container {
  position: relative;
}

.ladder_views_api__phone-screenshot-styles__screenshot {
  position: relative;
  top: 3.08%;
  left: 5.7%;
  height: 94.4%;
  max-width: 88.6%;
  border-radius: 5%;
}

.ladder_views_api__phone-screenshot-styles__phone-frame {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  max-width: 100%;
}

.ladder_views_api__phone-screenshot-styles__phone-size-m {
  height: 648px;
  width: 100%;
}

@media (max-width: 800px) and (min-width: 500px) {

  
  
  .ladder_views_api__phone-screenshot-styles__container {
    width: 250px;
  }
  
  .ladder_views_api__phone-screenshot-styles__phone-size-m {
    height: 505px;
  }

}

.ladder_views_api__phone-screenshot-styles__adornment-text {
  padding: 10.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_views_api__phone-screenshot-styles__container {
    margin-bottom: 20px;
  }

}
.ladder_account_shared_components__document-entry-styles__root {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-basis: 100%;
  padding: 20px 0;
  text-align: left;
}

@media (max-width: 499px) {

  
  
  .ladder_account_shared_components__document-entry-styles__root {
    flex-direction: column;
  }
  
  .ladder_account_shared_components__document-entry-styles__root>* {
    margin: 10.0px 0;
  }

}

@media (min-width: 1025px) {

  
  
  .ladder_account_shared_components__document-entry-styles__full-width {
    width: 100%;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_account_shared_components__document-entry-styles__full-width {
    width: 100%;
  }

}
.ladder_gift_promo__partner-logo-styles__partner-logo-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 30.0px;
  justify-content: space-around;
  overflow: hidden;
  align-items: center;
  width: 100%;
}

.ladder_gift_promo__partner-logo-styles__partner-logo-banner {
  width: 95%;
  max-height: 80px;
}

.ladder_gift_promo__partner-logo-styles__partner-logo-icon {
  margin: 20px auto;
  max-height: 50%;
  max-width: 40%;
  min-height: 40px;
  height: auto;
  width: auto;
}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_gift_promo__partner-logo-styles__partner-logo-list {
    justify-content: space-between;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_gift_promo__partner-logo-styles__partner-logo-list {
    justify-content: space-between;
  }

}
.ladder_guide_common__number-div-styles__root {
  margin: 8px 0;
}
.ladder_nearly_perfect_quote_common__price-panel-styles__estimated-price-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 10.0px;
  width: 100%;
}

.ladder_nearly_perfect_quote_common__price-panel-styles__whole-life-price-range-text {
  margin-top: 10.0px;
}
@media (max-width: 499px) {

  
  
  .ladder_header__call-to-action-button-styles__root {
    font-size: 10px;
    letter-spacing: 0.2em;
  }

}
.ladder_ny_form_traffic_diversion__next-steps-copy-styles__root {
  max-width: 780px;
  margin-bottom: 64px;
}

@media (max-width: 499px) {

  
  
  .ladder_ny_form_traffic_diversion__next-steps-copy-styles__root {
    margin-bottom: 32px;
  }

}
.ladder_ux_alert_card__dismiss-button-styles__x-button-n {
  position: absolute;
  right: 0;
  top: 0;
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.ladder_ux_alert_card__dismiss-button-styles__margin-s {
  margin: 8.0px;
}

.ladder_ux_alert_card__dismiss-button-styles__margin-m {
  margin: 16px;
}
.ladder_onboarding_spouse_connections__spouse-coverage-benefits-info-card-styles__image {
  max-height: 190px;
  height: auto;
}

.ladder_onboarding_spouse_connections__spouse-coverage-benefits-info-card-styles__row {
  display: flex;
  flex-direction: horizontal;
  justify-content: flex-start;
  flex-basis: 30%;
  overflow: visible;
}

.ladder_onboarding_spouse_connections__spouse-coverage-benefits-info-card-styles__bullet-container {
  padding-left: 10.0px;
}

.ladder_onboarding_spouse_connections__spouse-coverage-benefits-info-card-styles__bullet-item {
  list-style-type: disc;
  list-style-position: inside;
}

.ladder_onboarding_spouse_connections__spouse-coverage-benefits-info-card-styles__text-container {
  margin-left: 10.0px;
}

@media (max-width: 499px) {

  
  
  .ladder_onboarding_spouse_connections__spouse-coverage-benefits-info-card-styles__row {
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }
  
  .ladder_onboarding_spouse_connections__spouse-coverage-benefits-info-card-styles__header {
    font-size: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
  }

}

.ladder_onboarding_spouse_connections__spouse-coverage-benefits-info-card-styles__text-container {
  margin-left: 0px;
}
@media (min-width: 1025px) {

  
  
  .ladder_about__why-we-do-what-we-do-styles__container {
    margin-top: 58px;
  }

}

@media (max-width: 499px) {

  
  
  .ladder_about__why-we-do-what-we-do-styles__container {
    margin-top: 30px;
  }
  
  .ladder_about__why-we-do-what-we-do-styles__copy {
    margin-top: 42px;
  }

}

@media (max-width: 1024px) and (min-width: 500px) {

  
  
  .ladder_about__why-we-do-what-we-do-styles__container {
    margin-top: 30px;
  }
  
  .ladder_about__why-we-do-what-we-do-styles__copy {
    margin-top: 42px;
  }

}

